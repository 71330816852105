import { AvvirEvent } from "type_aliases";

export type GcpBearerTokenLoadedEvent = AvvirEvent<typeof GCP_BEARER_TOKEN_LOADED, { accessToken: string, expireTime: string, organizationId: string }>

const gcpBearerTokenLoaded = (token: { accessToken: string, expireTime: string, organizationId: string }): GcpBearerTokenLoadedEvent => ({
  type: GCP_BEARER_TOKEN_LOADED,
  payload: token
});

export const GCP_BEARER_TOKEN_LOADED = "gcp_bearer_token_loaded";

export default gcpBearerTokenLoaded;
