import { AssociationIds, AvvirEvent } from "type_aliases";

export type UpdateFloorMasterformatProgressCompletedEvent = AvvirEvent<typeof UPDATE_FLOOR_MASTERFORMAT_PROGRESS_COMPLETED,{
  processId: number
  projectId: string
  floorId: string
  startTime: Date
}>

export const updateFloorMasterformatProgressCompleted = ({ projectId, floorId }: AssociationIds, startTime: Date, processId: number): UpdateFloorMasterformatProgressCompletedEvent => ({
  type: UPDATE_FLOOR_MASTERFORMAT_PROGRESS_COMPLETED,
  payload: {
    processId,
    projectId,
    floorId,
    startTime
  },
});

export const UPDATE_FLOOR_MASTERFORMAT_PROGRESS_COMPLETED = "update_floor_masterformat_progress_completed";
