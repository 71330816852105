import { ApiProject } from "avvir";

import { AvvirEvent } from "type_aliases";

export type ProjectsForOrganizationLoadedEvent = AvvirEvent<typeof PROJECTS_FOR_ORGANIZATION_LOADED, {
  organizationId: string,
  projects: ApiProject[]
}>

const projectsForOrganizationLoaded = (organizationId: string, projects: ApiProject[]): ProjectsForOrganizationLoadedEvent => ({
  type: PROJECTS_FOR_ORGANIZATION_LOADED,
  payload: {
    organizationId,
    projects
  }
});

export const PROJECTS_FOR_ORGANIZATION_LOADED = "projects_for_organization_loaded";

export default projectsForOrganizationLoaded;
