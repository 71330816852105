import Avvir, { ApiPipeline, ApiPipelineArgument } from "avvir";
import pipelineRunningProcessTriggered, { PipelineRunningProcessTriggeredEvent } from "../events/pipeline_running_process_triggered";
import checkPipelineStatus from "./check_pipeline_status";
import { AvvirThunk } from "./make_eventful_action";
import { AvvirThunkEvents } from "type_aliases";

export type RunPipelineEvents = PipelineRunningProcessTriggeredEvent;

const runPipeline = (args: ApiPipelineArgument): AvvirThunk<Promise<ApiPipeline>, RunPipelineEvents | AvvirThunkEvents<typeof checkPipelineStatus>> => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    return Avvir.api.pipelines.triggerPipeline(new ApiPipeline(args), user)
      .then(pipeline => {
        dispatch(pipelineRunningProcessTriggered(pipeline));
        setTimeout(() => dispatch(checkPipelineStatus(pipeline)), 5000);
        return pipeline;
      });
  };
};

export default runPipeline;
