import { AvvirEvent } from "type_aliases";

export type ProjectAreaHighlightedEvent = AvvirEvent<typeof PROJECT_AREA_HIGHLIGHTED, { projectId: string, dbId: number }>

const projectAreaHighlighted = (projectId: string, dbId: number): ProjectAreaHighlightedEvent => ({
  type: PROJECT_AREA_HIGHLIGHTED,
  payload: {
    projectId,
    dbId
  }
});

export const PROJECT_AREA_HIGHLIGHTED = "project_area_highlighted";

export default projectAreaHighlighted;
