import _ from "underscore";

import addDateGetterAndSetterToDomainModel from "../../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import Masterformat from "../masterformat";

import type { ApiMasterformatProgress } from "avvir";
import type { DateLike } from "type_aliases";

export function groupProgressesByMasterformatCodeAndSortByDate(progresses: ApiMasterformatProgress[]): { [k: string]: Array<MasterformatProgress> } {
  const apiProgressesByMasterformatCode = _(progresses).groupBy(projectMasterformatProgress => {
    let code = projectMasterformatProgress.masterformat?.code;
    return code == null ? projectMasterformatProgress.customTradeCode : code;
  });
  return _.mapObject(apiProgressesByMasterformatCode,
    (projectMasterformatProgress, code) => {
      const sortedProgress = _(projectMasterformatProgress).sortBy("scanDate");
      return sortedProgress.map(progress => new MasterformatProgress(
        new Masterformat(progress.masterformat?.version, code as string, progress.masterformat?.description),
        progress.percentComplete,
        progress.scanDate as DateLike,
        progress.createdAt as DateLike,
        null,
        progress.customTradeCode
      ));
    });
}

export default class MasterformatProgress {
  constructor(masterformat: Masterformat, percentComplete: number, scanDate: DateLike, createdAt: DateLike, graphPercentComplete?: number,
    customTradeCode?: string) {
    addDateGetterAndSetterToDomainModel(this, "scanDate", scanDate);
    addDateGetterAndSetterToDomainModel(this, "createdAt", createdAt);
    this.masterformat = masterformat || null;
    this.percentComplete = percentComplete || null;
    if (graphPercentComplete != null) {
      this.graphPercentComplete = graphPercentComplete;
    }
    this.customTradeCode = customTradeCode;
  }

  masterformat: Masterformat | null = null;
  percentComplete: number | null = null;
  graphPercentComplete?: number;
  scanDate: DateLike = null;
  createdAt: DateLike = null;
  customTradeCode: string | null = null
}
