import { createSelector } from "reselect";
import _ from "underscore";

import getGroups from "./get_groups";
import getGroupIdsForFloor from "./get_group_ids_for_floor";

const getGroupsForFloor = createSelector([
  getGroups,
  getGroupIdsForFloor
], (groups, groupIdsForFloor) => {
  return _(groups.byId).pick(groupIdsForFloor);
});

export default getGroupsForFloor;
