import { AbstractRenderer, Texture } from "pixi.js";
import { PixiMinimapApp } from "../pixi_minimap_app";
import { MapMarkerStyle, MapMarkerTexture } from "../layers_shared/map_marker_texture";
import canGetResourceUrl from "../../../../../services/can_get_resource_url";

export class ClashingElementStyles {
  public defaultTexture: Texture;
  private _renderer: AbstractRenderer;

  private defaultStyle: MapMarkerStyle = {
    fillAlpha: 1,
    fillColor: 0xf44336, // --bim-clash: rgba(244, 67, 54, 1); /* #f44336 */
    lineColor: 0xf44336
  };

  public textureFailure: Texture = Texture.WHITE;

  init(app: PixiMinimapApp) {
    this._renderer = app.renderer;
    this.renderMarkersToTextures(app.renderer);
  }

  async renderMarkersToTextures(renderer: AbstractRenderer) {
    let priorityHighIconUrl;
    if (canGetResourceUrl()) {
      // @ts-ignore
      priorityHighIconUrl = (await import("../../../../../../resources/icons/map_layers/priority_high.svg?url")).default;
    }
    this.defaultTexture = (await MapMarkerTexture.create(renderer, this.defaultStyle, false, priorityHighIconUrl)).texture;
  }

}
