import { Dispatch } from "type_aliases";
import { ReduxStore } from "../reducers/root_reducer";
import { ApiPipeline, PipelineName, RunningProcessStatus } from "avvir";
import pipelineRunningProcessTriggered from "../../events/pipeline_running_process_triggered";
import pipelineRunningProcessChecked from "../../events/pipeline_running_process_checked";

export default class ToastHelper {
  constructor(dispatch: Dispatch<any>, getState: () => ReduxStore) {
    this.dispatch = dispatch;
    this.getState = getState;
  }

  private getRandomId() {
    return Math.floor(Math.random() * 1000000);
  }

  private createRunningProcess(id: number, name: string, startTime: Date) {
    this.dispatch(pipelineRunningProcessTriggered(new ApiPipeline({
      id: id,
      name: name as PipelineName,
      status: RunningProcessStatus.RUNNING,
      startTime: startTime,
    })));
  }

  private completeRunningProcess(id: number, name: string, startTime: Date, status: RunningProcessStatus) {
    setTimeout(() => {
      this.dispatch(pipelineRunningProcessChecked(new ApiPipeline({
        id: id,
        name: name as PipelineName,
        status: status,
        startTime: startTime,
        endTime: new Date(),
      })));
    }, 500);
  }

  private createCompletedPipeline(name: string, status: RunningProcessStatus) {
    const id = this.getRandomId();
    const startTime = new Date();
    this.createRunningProcess(id, name, startTime);
    this.completeRunningProcess(id, name, startTime, status);
  }

  createRunningPipeline(name: string) {
    const id = this.getRandomId();
    const startTime = new Date();
    this.createRunningProcess(id, name, startTime);
  }

  createFailedPipeline(name: string) {
    this.createCompletedPipeline(name, RunningProcessStatus.FAILED);
  }

  createSuccessfulPipeline(name: string) {
    this.createCompletedPipeline(name, RunningProcessStatus.COMPLETED);
  }

  dispatch: Dispatch<any>;
  getState: () => ReduxStore;
}
