import { AvvirRoutingEvent } from "type_aliases";
import PageNames from "../../models/domain/enums/page_names";

export type ToSummaryProgress4dReportEvent = AvvirRoutingEvent<typeof TO_SUMMARY_PROGRESS_4D_REPORT, {
  projectId: string,
  floorId?: string,
  embedded?: boolean,
  pageName: PageNames
}>

const toSummaryProgress4dReport = ({
  projectId,
  embedded = false,
  pageName = PageNames.PROJECT_REPORTS,
  floorId
}: {
  projectId: string,
  embedded?: boolean,
  pageName?: PageNames,
  floorId?: string
}): ToSummaryProgress4dReportEvent => ({
  type: TO_SUMMARY_PROGRESS_4D_REPORT,
  payload: {
    projectId,
    floorId,
    embedded,
    pageName
  }
});

export const TO_SUMMARY_PROGRESS_4D_REPORT = "to_summary_project_4d_report";

export default toSummaryProgress4dReport;
