import { ApiPipeline } from "avvir";
import { AvvirEvent } from "type_aliases";

export type PipelineRunningProcessCheckedEvent = AvvirEvent<typeof PIPELINE_RUNNING_PROCESS_CHECKED, ApiPipeline>

const pipelineRunningProcessChecked = (pipeline: ApiPipeline): PipelineRunningProcessCheckedEvent => ({
  type: PIPELINE_RUNNING_PROCESS_CHECKED,
  payload: pipeline
});

export const PIPELINE_RUNNING_PROCESS_CHECKED = "pipeline_running_process_checked";

export default pipelineRunningProcessChecked;
