import { AvvirEvent } from "type_aliases";
import { ApiPipeline } from "avvir";

export type FloorPipelinesLoadedEvent = AvvirEvent<typeof FLOOR_PIPELINES_LOADED, {
  pipelines: ApiPipeline[]
}>

const floorPipelinesLoaded = (projectId: string, floorId: string, pipelines: ApiPipeline[]): FloorPipelinesLoadedEvent => ({
  type: FLOOR_PIPELINES_LOADED,
  payload: {
    pipelines
  }
});

export const FLOOR_PIPELINES_LOADED = "floor_pipelines_loaded";

export default floorPipelinesLoaded;
