import { createSelector } from "reselect";

import getProject from "../project_getters/get_project";

const emptyFloors: string[] = [];

const getFirebaseFloorIdsForProject = createSelector([
  getProject
], (project) => project?.firebaseFloorIds || emptyFloors);

export default getFirebaseFloorIdsForProject;
