import { Container, IPointData } from "pixi.js";
import { PixiUpdatable } from "../../../../../services/utilities/pixi/pixi_updateable";
import { PixiMinimapApp, PixiMinimapMode } from "../pixi_minimap_app";
import { PhotoSprite } from "./photo_sprite";
import { PhotoLocationMovedPayload } from "../../../../../events/viewer/photo_locations_moved";
import { AdjustPointsControl } from "./adjust_points/adjust_points_control";
import { PhotoEditingLines } from "./adjust_points/photo_editing_lines";
import { PhotoSelectionRectangle, RotationEventListener } from "./photo_selection_rectangle";
import { PhotoSpriteContainer } from "./photo_sprite_container";
import { PhotoFenceSelector } from "./adjust_points/photo_fence_selector";
import { PhotoSpriteStyles } from "./photo_sprite_styles";
import PhotoLocation from "../../../../../models/domain/photos/photo_location";

export class PixiPhotosLayer extends Container implements PixiUpdatable, RotationEventListener {
  private readonly adjustPointsControl: AdjustPointsControl;
  private readonly _photoSprites: PhotoSpriteContainer;
  private readonly _lines: PhotoEditingLines;
  private readonly _fenceSelector: PhotoFenceSelector;
  private _zoomScale = 1;
  private _mode: PixiMinimapMode = PixiMinimapMode.VIEW;
  private _needsRender = false;

  public readonly selectionRectangle: PhotoSelectionRectangle;
  public app: PixiMinimapApp;
  public selectPhotoLocation: (id: number, fenceSelectedPhotoLocationIds?: number[]) => any;
  public updatePhotoLocations: (payload: PhotoLocationMovedPayload) => any;

  constructor(styles: PhotoSpriteStyles) {
    super();
    this._fenceSelector = new PhotoFenceSelector(this);
    this._lines = new PhotoEditingLines(this);
    this._photoSprites = new PhotoSpriteContainer(this);
    this.selectionRectangle = new PhotoSelectionRectangle(this, styles);
    this.adjustPointsControl = AdjustPointsControl.create(this._photoSprites, this.selectionRectangle);
    this.addChild(this._lines);
    this.addChild(this._photoSprites);
    this.addChild(this.selectionRectangle);
    this.addChild(this._fenceSelector);
  }

  get isMultiSelect() {
    return this.selectionRectangle.isMultiSelect;
  }

  set photos(photos: PhotoLocation[]) {
    this._photoSprites.updatePhotoLocations(photos);
  }

  get photoSprites() {
    return this._photoSprites.sprites;
  }

  get mode() {
    return this.app.mode;
  }

  set mode(value: PixiMinimapMode) {
    if (value !== this._mode) {
      this.needsRender = true;
    }

    this._mode = value;
    const isEditMode = value !== PixiMinimapMode.VIEW;
    this._lines.enabled = isEditMode;
    this.selectionRectangle.enabled = isEditMode;
  }

  get editMode() {
    return this.app.mode !== PixiMinimapMode.VIEW;
  }

  get needsRender() {
    return this._needsRender;
  }

  set needsRender(value: boolean) {
    this._needsRender = value;
    if (value && this.app) {
      this.app.requestRender();
    }
  }

  get needsControlPointRefresh() {
    return this.adjustPointsControl.needsControlPointRefresh;
  }

  set needsControlPointRefresh(value: boolean) {
    this.adjustPointsControl.needsControlPointRefresh = value;
  }

  get selectedPhoto() {
    return this._photoSprites.selectedPhoto;
  }

  get zoomScale() {
    return this._zoomScale;
  }

  onBimToPhotoAreaUpdate() {
    this._photoSprites.onBimToPhotoAreaUpdate();
    this.updateSelectionRectangle();
  }

  updateSelectionRectangle() {
    if (this.selectionRectangle.enabled) {
      this._photoSprites.selectedPhoto = this.selectionRectangle.updatePhotoLocations(this._photoSprites.sprites);
    }
  }

  isAdjusting() {
    return this.adjustPointsControl.isAdjusting();
  }

  onPhotoPointerDown(sprite: PhotoSprite) {
    this._photoSprites.selectedPhoto = sprite;

    if (!this.editMode) {
      return;
    }

    const startPos = { x: sprite.position.x, y: sprite.position.y };
    const changed = this.selectionRectangle.setCurrentSelectedPhoto(sprite);

    if (changed) {
      this.needsRender = true;
    }

    if (this.isMultiSelect) {
      this.selectionRectangle.startDragging();
      this.adjustPointsControl.onAdjustStart(this.selectionRectangle.selectedPhotos.slice(), startPos);
    } else {
      this.adjustPointsControl.onAdjustStart([sprite], startPos);
    }
  }

  onPhotoPointerUp(photo: PhotoSprite) {
    if (!this.editMode) {
      return;
    }

    if (photo.wasDragged) {
      this.onPointerUpOrOut(this.app);
    } else {
      this.selectionRectangle.stopDragging();
    }
  }

  onRotationStart() {
    if (this.isMultiSelect) {
      this.adjustPointsControl.onAdjustStart(this.selectionRectangle.selectedPhotos.slice(), this.selectionRectangle.centerPoint);
    }
  }

  onRotationEnd() {
    this.onPointerUpOrOut(this.app);
    this.selectionRectangle.stopDragging();
  }

  onSelectionDrag(app: PixiMinimapApp, startPosition: IPointData, position: IPointData) {
    const start = this.toLocal(startPosition, null, null, true);
    const end = this.toLocal(position, null, null, true);
    this._fenceSelector.onDrag(app, start, end);
    this.needsRender = true;
  }

  onMinimapDrag(app: PixiMinimapApp, position: IPointData) {
    this.adjustPointsControl.onMinimapDrag(app, position);
    this.needsRender = true;
  }

  onPointerUpOrOut(application?: PixiMinimapApp) {
    if (!this.editMode) {
      return;
    }

    const app = application || this.app;

    if (this.adjustPointsControl.isAdjusting()) {
      const payload = this.adjustPointsControl.onAdjustEnd(app);
      if (payload) {
        this.updatePhotoLocations({ locations: payload });
      }
    } else if (this._fenceSelector.wasDragged) {
      const selection = this._fenceSelector.onDragEnd();
      this._photoSprites.selectedPhoto = this.selectionRectangle.updateSelection(selection, this._photoSprites.selectedPhoto);
      this.selectPhotoLocation(this.selectedPhotoLocationId, this.selectionRectangle.selectedPhotos.map((photo) => photo.id));
      this.needsRender = true;
    }
  }

  get selectedPhotoLocationId() {
    return this._photoSprites.selectedPhotoLocationId;
  }

  set selectedPhotoLocationId(id: number) {
    this._photoSprites.selectedPhotoLocationId = id;
    this.selectionRectangle.setCurrentSelectedPhoto(this._photoSprites.selectedPhoto);
  }

  rescale() {
    this._photoSprites.rescale(this._zoomScale);
    this._lines.rescale(this._zoomScale);
    this.selectionRectangle.rescale({ x: this._zoomScale, y: this._zoomScale });
  }

  hasPhotos() {
    return this._photoSprites.hasPhotos();
  }

  isControlPoint(photo: PhotoSprite) {
    return this.adjustPointsControl.isControlPoint(photo);
  }

  isSelected(photo: PhotoSprite) {
    return this.selectedPhotoLocationId === photo.id || this.selectionRectangle.isSelected(photo);
  }

  update(app: PixiMinimapApp) {
    this.app = app;
    if (!this.needsRender) {
      return false;
    }

    this.needsRender = false;
    if (this.editMode) {
      this.adjustPointsControl.update();
    }

    this._photoSprites.update(app);
    this._lines.update(app);

    return true;
  }

  zoom(zoom: number) {
    if (this._zoomScale !== zoom) {
      this._zoomScale = zoom;
      this.rescale();
      this.needsRender = true;
    }

    return this.needsRender;
  }

}
