import { ReduxStore } from "../reducers/root_reducer";

const getToastNotification = (state: ReduxStore, props) => {
  const toastNotification = state.toastNotification;
  if (toastNotification) {
    return Object.keys(toastNotification).length > 0 ? toastNotification : null;
  } else {
    return null;
  }
};

export default getToastNotification;
