import { Container } from "pixi.js";
import { PixiUpdatable } from "../../../../../services/utilities/pixi/pixi_updateable";
import { PixiMinimapApp } from "../pixi_minimap_app";
import { PhotoSprite } from "./photo_sprite";
import { PixiPhotosLayer } from "./photos_layer";
import PhotoLocation from "../../../../../models/domain/photos/photo_location";
import { loadPhotoLocationsVisited } from "../../../../../services/utilities/store_in_local_storage";
import MapLocationContainer from "../layers_shared/map_location_container";

export interface PhotoSpriteSupplier {
  sprites: PhotoSprite[];
}

export class PhotoSpriteContainer extends Container implements PixiUpdatable, PhotoSpriteSupplier {
  private readonly _locations: PixiPhotosLayer;
  private _photos: PhotoLocation[];
  private locationsContainer: MapLocationContainer<PhotoSprite>;
  private _needsSort: boolean = false;
  private _needsBimPositionRefresh: boolean = false;
  private _visited: { [id: number]: boolean };

  constructor(locations: PixiPhotosLayer) {
    super();
    this._locations = locations;
    this.locationsContainer = new MapLocationContainer<PhotoSprite>({
      onLocationSelected: (mapMarker) => this.onLocationSelected(mapMarker.id)
    });
  }

  get sprites(): PhotoSprite[] {
    return this.children as PhotoSprite[];
  }

  onLocationSelected(id) {
    this._locations.needsRender = true;
    this._locations.selectPhotoLocation(id);
  }

  updatePhotoLocations(photos: PhotoLocation[]) {
    this._visited = loadPhotoLocationsVisited();
    this._photos = photos;
    this._needsSort = true;
    this._needsBimPositionRefresh = true;
    this._locations.needsRender = true;
  }

  onBimToPhotoAreaUpdate() {
    this._needsBimPositionRefresh = true;
  }

  get selectedPhoto() {
    return this.selectedPhotoLocationId == null ? null : this.locationsContainer.getById(this.selectedPhotoLocationId);
  }

  set selectedPhoto(photo: PhotoSprite) {
    if (photo != null) {
      this.selectedPhotoLocationId = photo.id;
    }
  }

  get selectedPhotoLocationId() {
    return this.locationsContainer.selectedId;
  }

  set selectedPhotoLocationId(id: number) {
    this.locationsContainer.selectedId = id;
  }

  removePhotosById() {
    const photoIds = new Set(this._photos.map(item => {
      return item.id;
    }));
    this.locationsContainer.mapLocationsById.forEach((sprite, id) => {
      if (!photoIds.has(id)) {
        this.removeChild(sprite);
        this.locationsContainer.delete(id);
        sprite.destroy(false);
        this._locations.needsRender = true;

        if (this.selectedPhotoLocationId === id) {
          this.selectedPhotoLocationId = null;
        }
      }
    });

    return this._locations.needsRender;
  }

  rescale(zoom: number) {
    this.locationsContainer.rescale(zoom);
  }

  hasPhotos() {
    return this.children != null && this.children.length > 0;
  }

  update(app: PixiMinimapApp) {
    if (this._photos == null || this._photos.length === 0) {
      return this.removeAllChildren();
    }

    const wasRemoved = this.removePhotosById();
    if (wasRemoved) {
      this._locations.updateSelectionRectangle();
    }

    this.locationsContainer.update();
    this._locations.rescale();

    if (this._needsSort) {
      this._photos.sort((a, b): number => {
        const aTime = (a.originalTimestamp == null ? a.createdAt : a.originalTimestamp);
        const bTime = (b.originalTimestamp == null ? b.createdAt : b.originalTimestamp);
        let diff = aTime.getTime() - bTime.getTime();
        if (diff === 0) {
          diff = a.id - b.id;
        }
        return diff;
      });
    }

    this._photos.forEach((photo, index) => {
      this.updatePhotoSprite(app, photo, index);
    });

    this._needsBimPositionRefresh = false;

    if (this._needsSort) {
      this.sortChildren();
      this._locations.selectionRectangle.refreshSelectionBounds();
      this._needsSort = false;
    }

    return true;
  }

  updatePhotoSprite(app: PixiMinimapApp, photo: PhotoLocation, index: number) {
    const sprite = this.getOrCreatePhotoSprite(app, photo);
    sprite.sortIndex = index;
    sprite.zIndex = this._photos.length - index;
    sprite.selected = this._locations.isSelected(sprite);
    sprite.isControlPoint = this._locations.isControlPoint(sprite);
    sprite.update(app);
  }

  getOrCreatePhotoSprite(app: PixiMinimapApp, photo: PhotoLocation) {
    let sprite = this.locationsContainer.getById(photo.id);
    if (sprite == null) {
      sprite = PhotoSprite.fromDomain(app.photosLayer, app, photo, this._visited[photo.id]);
      sprite.rescale({ x: this._locations.zoomScale, y: this._locations.zoomScale });
      sprite.recalculatePositionFromBim(app);
      this.locationsContainer.addLocation(sprite);
      this.addChild(sprite);
      this._needsSort = true;
      this._locations.needsRender = true;
      this._locations.needsControlPointRefresh = true;
    } else if (this._needsBimPositionRefresh) {
      sprite.updateFromPhoto(app, photo);
    }

    return sprite;
  }

  removeAllChildren() {
    this.locationsContainer.clear();
    const removed = this.removeChildren();
    if (removed != null && removed.length > 0) {
      removed.forEach(item => {
        item.destroy();
      });
      return true;
    }

    return false;
  }

}
