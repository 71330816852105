import getProjectIdFromLocation from "../../../../../services/getters/location_metadata/get_project_id_from_location";
import makeEventfulAction, { AvvirThunk } from "../../../../make_eventful_action";
import floorPipelinesLoaded, { FloorPipelinesLoadedEvent } from "../../../../../events/loaded/floors/floor_pipelines_loaded";
import Avvir, { ApiPipeline } from "avvir";

const loadFloorPipelines = (floorId: string): AvvirThunk<Promise<ApiPipeline[]>, FloorPipelinesLoadedEvent> => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = getProjectIdFromLocation(state, {});
    const user = state.user;
    return Avvir.api.pipelines.getFloorPipelines({ projectId, floorId }, user)
      .then((pipelines) => {
        dispatch(floorPipelinesLoaded(projectId, floorId, pipelines));
        return pipelines;
      });
  };
};

export default makeEventfulAction<Promise<ApiPipeline[]>, FloorPipelinesLoadedEvent, typeof loadFloorPipelines>("loadFloorPipelines", loadFloorPipelines);
