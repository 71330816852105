import { AvvirEvent } from "type_aliases";
import PanelType from "../../models/domain/enums/panel_type";
import PageNames from "../../models/domain/enums/page_names";

export type PanelShownEvent = AvvirEvent<typeof PANEL_SHOWN, { page: PageNames, panel: PanelType }>

const panelShown = (page: PageNames, panel: PanelType): PanelShownEvent => ({
  type: PANEL_SHOWN,
  payload: {
    page,
    panel
  }
});

export const PANEL_SHOWN = "panel_shown";

export default panelShown;
