import { Action } from "redux";

export type Mixpanel4dUpsellDisplayedEvent = Action<typeof MIXPANEL_4D_UPSELL_DISPLAYED>

const mixpanel4dUpsellDisplayed = () => ({
  type: MIXPANEL_4D_UPSELL_DISPLAYED,
});

export const MIXPANEL_4D_UPSELL_DISPLAYED = "4d_upsell_displayed";

export default mixpanel4dUpsellDisplayed;
