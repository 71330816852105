import { ApiOrganization } from "avvir";

import Organization from "../../models/domain/organization";

export default class OrganizationConverter {
  static fromApi(apiOrganization: ApiOrganization): Organization {
    const {
      city: addressCity,
      country: addressCountry,
      state: addressState,
      zip: addressZip,
      ...restOfOrganization
    } = apiOrganization;

    return new Organization({
      ...restOfOrganization,
      addressCity,
      addressCountry,
      addressState,
      addressZip,
    });
  }

  static toApi(organization: Organization): ApiOrganization {
    const {
      addressCity: city,
      addressCountry: country,
      addressState: state,
      addressZip: zip,
      invitation,
      ...restOfOrganization
    } = organization;

    return new ApiOrganization({
      ...restOfOrganization,
      city,
      country,
      state,
      zip
    });
  }
}
