import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";

import "../../css/toast_notification.css";
import toastNotificationExpired from "../events/notifications/toast_notification_expired";
import toastNotificationExpiresSoon from "../events/notifications/toast_notification_expires_soon";

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export class ToastNotification extends Component<Props> {
  timeout1: ReturnType<typeof setTimeout>; // NOTE: These are numbers, but since we have some node code, TypeScript thinks they are a NodeJS.Timeout object. This is just a type workaround.
  timeout2: ReturnType<typeof setTimeout>;

  setAnimationTimers(props: Props) {
    const animationTime = 500;
    if (this.props.expiry) {
      let timeUntilExpiry = props.expiry.getTime() - (new Date()).getTime();
      this.timeout1 = setTimeout(props.toastNotificationExpiresSoon, timeUntilExpiry - animationTime);
      this.timeout2 = setTimeout(props.toastNotificationExpired, timeUntilExpiry);
    }
  }

  clearAnimationTimers() {
    if (this.timeout1) {
      clearTimeout(this.timeout1);
      clearTimeout(this.timeout2);
    }
  }

  componentDidMount() {
    this.setAnimationTimers(this.props);
  }

  componentWillUnmount() {
    this.clearAnimationTimers();
  }

  componentDidUpdate(props) {
    this.clearAnimationTimers();
    this.setAnimationTimers(props);
  }

  render() {
    if (this.props.link) {
      return <div
        className={classNames("ToastNotification",
          { "ToastNotification-closing": this.props.expiresSoon },
          `ToastNotification-${this.props.level}`)}
      >{this.props.message}<a href={this.props.link} rel="noopener noreferrer" target="_blank">Link</a></div>;
    } else {
      return <textarea readOnly
                       aria-label= {this.props.message.trim()}
        rows={this.props.message.trim().split("\n").length}
        className={classNames("ToastNotification",
          { "ToastNotification-closing": this.props.expiresSoon },
          `ToastNotification-${this.props.level}`)}
        value={this.props.message.trim()}
      ></textarea>;
    }
  }
}

const mapStateToProps = (state) => ({
  message: state.toastNotification.message,
  link: state.toastNotification.link,
  level: state.toastNotification.level,
  expiry: state.toastNotification.expiry,
  expiresSoon: state.toastNotification.expiresSoon
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toastNotificationExpired,
  toastNotificationExpiresSoon
}, dispatch);

const ConnectedToastNotification = connect(mapStateToProps, mapDispatchToProps)(ToastNotification);
export default ConnectedToastNotification;
