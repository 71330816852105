import { createSelector } from "reselect";

import getProjectIdFromLocation from "../location_metadata/get_project_id_from_location";
import getProps from "../base_getters/get_props";
import type { GetPropsType } from "type_aliases";

const getProjectId = createSelector<[typeof getProjectIdFromLocation, GetPropsType<{ projectId: string }>], string>([
  getProjectIdFromLocation,
  getProps
], (locationProjectId, props) => {
  if (props.projectId) {
    return props.projectId as string;
  } else if (locationProjectId) {
    return locationProjectId;
  } else {
    return null;
  }
});

export default getProjectId;
