import { AvvirEvent } from "type_aliases";
import { ApiPhotoLocation } from "avvir";

export type PhotoLocationsPayload = {
  photoLocations: ApiPhotoLocation[]
}

export type PhotoLocationsCreatedEvent = AvvirEvent<typeof PHOTO_LOCATIONS_CREATED, PhotoLocationsPayload>

const photoLocationsCreated = (photoLocations: ApiPhotoLocation[]): PhotoLocationsCreatedEvent => ({
  type: PHOTO_LOCATIONS_CREATED,
  payload: {
    photoLocations
  }
});

export const PHOTO_LOCATIONS_CREATED = "photo_locations_created";

export default photoLocationsCreated;
