import { AvvirEvent } from "type_aliases";
import { ApiProjectAreaWorkPackage } from "avvir";

export type ProjectAreaWorkPackagesForProjectLoadedEvent = AvvirEvent<typeof PROJECT_AREA_WORK_PACKAGES_FOR_PROJECT_LOADED, {
  projectId: string,
  projectAreaWorkPackages: ApiProjectAreaWorkPackage[]
}>

const projectAreaWorkPackagesForProjectLoaded = (projectId: string, projectAreaWorkPackages: ApiProjectAreaWorkPackage[]): ProjectAreaWorkPackagesForProjectLoadedEvent => ({
  type: PROJECT_AREA_WORK_PACKAGES_FOR_PROJECT_LOADED,
  payload: {
    projectId,
    projectAreaWorkPackages
  }
});

export const PROJECT_AREA_WORK_PACKAGES_FOR_PROJECT_LOADED = "project_area_work_packages_for_project_loaded";

export default projectAreaWorkPackagesForProjectLoaded;
