import ColorLib from "tinycolor2";
import _ from "underscore";

import annotationHovered from "../../events/viewer/annotation_hovered";
import annotationUnHovered from "../../events/viewer/annotation_unhovered";
import elementColored from "../../events/viewer/element_colored";
import getGroupMemberGlobalIdsForElement from "../getters/group_member_getters/get_group_member_global_ids_for_element";
import getIsElementInGroup from "../getters/group_getters/get_is_element_in_group";
import PlannedBuildingElement from "../../models/domain/planned_building_element";
import setIsEqual from "./general/set_is_equal";
import { AVVIR_CUSTOM_GEOMETRY_EXTENSIONS } from "../autodesk_forge_services/extension_loader";
import { getDbId } from "./get_set_of_db_ids";
import { getGlobalId } from "./get_set_of_global_ids";

import type AsBuiltElementsForgeExtension from "../autodesk_forge_services/extensions/element_decorators/as_built_elements_forge_extension";
import type CustomGeometryForgeExtension from "../autodesk_forge_services/extensions/element_decorators/custom_geometry_forge_extension";
import type { AutodeskViewer } from "../autodesk_forge_services/autodesk_forge_adaptor";
import type { Dispatch, GetState } from "type_aliases";

const getDbIds = (globalIds: string[] | Set<string>) => {
  if (Array.isArray(globalIds)) {
    return globalIds?.map(getDbId);
  } else {
    return Array.from(globalIds).map(getDbId);
  }
};

type SetAggregateSelectionInput = { model: Autodesk.Viewing.Model, ids: number[] }[];

export class ForgeSelectionManager {
  init = (autodeskViewer: AutodeskViewer) => {
    this.autodeskViewer = autodeskViewer;
    this.extensions = AVVIR_CUSTOM_GEOMETRY_EXTENSIONS.map(extensionName => {
      return autodeskViewer.getExtension(extensionName) as CustomGeometryForgeExtension;
    });

    this.asBuiltExtension = autodeskViewer.getExtension("Avvir.AsBuiltElements") as AsBuiltElementsForgeExtension;
  };

  hasAutodeskViewer = () => {
    return !!this.autodeskViewer;
  };

  setElementSelection = (dbIds: number[]) => {
    const selectedDbIdsByModel = this.groupDbIdsByModel(dbIds);

    const hasSelectionChanged = this.willSelectionChange(selectedDbIdsByModel, dbIds);
    if (hasSelectionChanged) {
      // this is an empty array of objects with properties of types Autodesk.Viewing.Model & number
      const selection: SetAggregateSelectionInput = [];

      selectedDbIdsByModel.forEach((dbIds, model) => {
        selection.push({ model, ids: dbIds });
      });

      this.autodeskViewer?.getAllModels().forEach(model => {
        if (!selectedDbIdsByModel.has(model)) {
          selection.push({ model, ids: [] });
        }
      });

      // @ts-ignore
      this.autodeskViewer?.impl.selector.setAggregateSelection(selection);
    }
  };

  highlightElement = (dbId: number) => {
    const hoveredModel = this.getElementModel(dbId);

    // @ts-ignore
    this.autodeskViewer?.impl.renderer().rolloverObjectId(dbId, null, hoveredModel.id);
  };

  unhighlightAllElements = () => {
    // @ts-ignore
    this.autodeskViewer?.impl.renderer().rolloverObjectId(-1);
  };

  hideElement = (dbId: number) => {
    const elementModel = this.getElementModel(dbId);

    this.autodeskViewer?.hide(dbId, elementModel);
  };

  showAll = () => {
    this.autodeskViewer?.showAll();
  };

  shutdown = () => {
    this.autodeskViewer = null;
    this.extensions.length = 0;
  };

  private getElementModel = (dbId: number) => {
    const extensionModel = this.extensions.find(extension => extension?.elements?.has(dbId))?.model;
    return extensionModel || this.autodeskViewer?.model;
  };

  private willSelectionChange = (selection: Map<Autodesk.Viewing.Model, number[]>, dbIds: number[]) => {
    const aggregateSelection = this.autodeskViewer?.getAggregateSelection() as { model: Autodesk.Viewing.Model, selection: number[] }[];
    const currentlySelectedDbIds = _.chain(aggregateSelection).pluck("selection")
      .flatten()
      .value();
    if (dbIds.length !== currentlySelectedDbIds.length) {
      return true;
    } else {
      let isEqual = true;
      selection.forEach((dbIds, model) => {
        if (!setIsEqual(dbIds, _.findWhere(aggregateSelection, { model })?.selection || [])) {
          isEqual = false;
        }
      });

      return !isEqual;
    }
  };

  private groupDbIdsByModel = (dbIds: number[]) => {
    return dbIds.reduce<Map<Autodesk.Viewing.Model, number[]>>((selectionSoFar, dbId) => {
      const elementModel = this.getElementModel(dbId);
      if (selectionSoFar.has(elementModel)) {
        selectionSoFar.get(elementModel).push(dbId);
      } else {
        selectionSoFar.set(elementModel, [dbId]);
      }
      return selectionSoFar;
    }, new Map());
  };

  private extensions: CustomGeometryForgeExtension[] = [];
  asBuiltExtension: AsBuiltElementsForgeExtension;
  private autodeskViewer: AutodeskViewer;
}

export default class ElementSelectionManager {
  constructor(dispatch: Dispatch<any>, getState: GetState) {
    this.dispatch = dispatch;
    this.getState = getState;
    this.private = new ForgeSelectionManager();
  }

  deselectElements = (globalIdsToRemove: string[] | Set<string>) => {
    const newSelection = new Set(this.selectedElements);
    if (this.additiveMode) {
      globalIdsToRemove.forEach(newSelection.delete, newSelection);
    } else {
      globalIdsToRemove.forEach(globalId => {
        this.getGroupedElementGlobalIds(globalId).forEach(newSelection.delete, newSelection);
      });
    }
    this.setSelectedElements(newSelection);
  };

  selectElement = (globalId: string, additiveMode: boolean) => {
    this.additiveMode = additiveMode;
    if (!globalId) {
      this.setSelectedElements([]);
    } else if (additiveMode) {
      this.toggleElementSelection(globalId);
    } else {
      this.setSelectedElements(this.getGroupedElementGlobalIds(globalId));
    }
    this.additiveMode = false;
  };

  setSelectedElementsWithAsBuilt(elements: PlannedBuildingElement[], scanDate: Date) {
    const dbIds = this.setSelectedElements(elements.map(element => element.globalId));
    if (this.private.asBuiltExtension) {
      this.private.asBuiltExtension.setElements(new Set(dbIds));

      dbIds.forEach((dbId: number) => {
        const element = elements.find(element => element.globalId === getGlobalId(dbId));
        const deviation = PlannedBuildingElement.getDeviation(element, scanDate);
        if (deviation) {
          this.private.asBuiltExtension.offsetElementFromOriginalModel(dbId, deviation.deviationVectorMeters);
        }
      });
    }
  }

  setSelectedElements = (globalIds: string[] | Set<string>) => {
    const selection = new Set<string>();
    if (this.additiveMode) {
      globalIds.forEach(selection.add, selection);
    } else {
      globalIds.forEach(globalId => {
        this.getGroupedElementGlobalIds(globalId).forEach(selection.add, selection);
      });
    }
    const dbIds = getDbIds(selection);
    if (this.private.hasAutodeskViewer()) {
      this.private.setElementSelection(dbIds);
    } else {
      this.queuedElements = selection;
    }
    return dbIds;
  };

  selectGroupElements = (globalIds: string[] | Set<string>, additiveMode: boolean) => {
    const selection = new Set<string>();
    if (additiveMode) {
      globalIds.forEach(selection.add, selection);
    } else {
      globalIds.forEach((globalId: string) => {
        this.getGroupedElementGlobalIds(globalId).forEach(selection.add, selection);
      });
    }
    this.private.setElementSelection(getDbIds(selection));
  };

  addSelectedElements = (globalIds: string[] | Set<string>) => {
    const newSelection = new Set(this.selectedElements);
    globalIds.forEach(newSelection.add, newSelection);
    this.setSelectedElements(newSelection);
  };

  get selectedElements() {
    return this.getState().elementSelection.selectedElements;
  }

  elementHovered = (globalId: string, dispatchEvent: boolean = true) => {
    if (dispatchEvent) {
      this.dispatch(annotationHovered(globalId));
    }
    this.private.highlightElement(getDbId(globalId));
  };

  elementUnHovered = (globalId: string, dispatchEvent: boolean = true) => {
    if (dispatchEvent) {
      this.dispatch(annotationUnHovered(globalId));
    }
    this.private.unhighlightAllElements();
  };

  init(autodeskViewer: AutodeskViewer) {
    this.private.init(autodeskViewer);
    if (this.queuedElements) {
      this.setSelectedElements(this.queuedElements);
      this.queuedElements = null;
    }
  }

  shutdown() {
    this.private.shutdown();
  }

  colorElements(color: ColorLib.ColorInput) {
    this.selectedElements.forEach(globalId => {
      this.dispatch(elementColored(globalId, ColorLib(color).toHexString()));
    });
  }

  hideElements = () => {
    this.selectedElements.forEach(globalId => {
      this.private.hideElement(getDbId(globalId));
    });
    this.private.setElementSelection([]);
  };

  showAll = () => {
    this.private.showAll();
  };


  private toggleElementSelection = (globalId: string) => {
    if (this.selectedElements.includes(globalId)) {
      this.deselectElements([globalId]);
    } else {
      this.addSelectedElements([globalId]);
    }
  };

  private isElementInGroup = (globalId: string) => {
    return getIsElementInGroup(this.getState(), { globalId });
  };

  private getGroupedElementGlobalIds = (globalId: string) => {
    if (this.isElementInGroup(globalId)) {
      return getGroupMemberGlobalIdsForElement(this.getState(), { globalId });
    } else {
      return [globalId];
    }
  };

  dispatch: Dispatch<any>;
  getState: GetState;
  private readonly private: ForgeSelectionManager;
  additiveMode: boolean;
  queuedElements: Set<string>;
}
