export function triggerFileDownload(url: string, fileName: string) {
  const a = document.createElement("a", {});
  a.setAttribute("href", url);
  a.setAttribute("download", fileName);
  a.setAttribute("name", fileName);
  a.setAttribute("class", "triggerFileDownloadLink");
  a.setAttribute("target", "_blank");
  const root = document.getElementById("root");
  document.body.insertBefore(a, root);
  a.click();
  return a;
}

export function triggerFileDownloadFromResponse(res, fileName, fileType = "text/tsv") {
  let byteNumbers = new Uint8Array(res.length);
  for (let i = 0; i < res.length; i++)
  {
    byteNumbers[i] = res.charCodeAt(i);
  }
  let blob = new Blob([byteNumbers], {type: fileType});
  const url = window.URL.createObjectURL(blob);
  triggerFileDownload(url, fileName)
}

export function triggerMultiFileDownload(urls: string[]) {
  const a = document.createElement("a");
  urls.forEach(url => {
    a.setAttribute("href", url);
    a.setAttribute("target", "_blank");
    a.click();
  });
  document.removeChild(a);
}

export default triggerFileDownload;
