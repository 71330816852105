import { AvvirEvent } from "type_aliases";
import { ApiInspectReport } from "avvir";

export type InspectReportsLoadedEvent = AvvirEvent<typeof INSPECT_REPORTS_LOADED, { projectId: string, inspectReports: ApiInspectReport[] }>

const inspectReportsLoaded = (projectId: string, inspectReports: ApiInspectReport[]) =>({
  type: INSPECT_REPORTS_LOADED,
  payload: {
    projectId,
    inspectReports
  }
})

export const INSPECT_REPORTS_LOADED = "inspect_reports_loaded";

export default inspectReportsLoaded;
