import { AvvirEvent } from "type_aliases";
import DetailedElement from "../../../models/domain/detailed_element";

export type ElementDetailsLoadedEvent = AvvirEvent<typeof ELEMENT_DETAILS_LOADED, { floorId: string, scanDatasetId: string, elements: DetailedElement[] }>

const elementDetailsLoaded = (floorId: string, scanDatasetId: string, elementDetails: DetailedElement[]): ElementDetailsLoadedEvent => ({
  type: ELEMENT_DETAILS_LOADED,
  payload: {
    floorId,
    scanDatasetId,
    elements: elementDetails
  }
});

export const ELEMENT_DETAILS_LOADED = "element_details_loaded";

export default elementDetailsLoaded;
