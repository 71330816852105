import { Reducer } from "redux";

import { isRoutingEvent } from "../routing_events";
import { PROCORE_PROJECT_SELECTED, ProcoreProjectSelectedEvent } from "../../events/procore_project_selected";
import { PROCORE_PROJECTS_LOADED, ProcoreProjectsLoadedEvent } from "../../events/loaded/procore_projects_loaded";
import { PUSH_TO_PROCORE_DIALOG_DISMISSED, PushToProcoreDialogDismissedEvent } from "../../events/viewer/push_to_procore_dialog_dismissed";
import { PUSH_TO_PROCORE_DIALOG_SHOWN, PushToProcoreDialogShownEvent } from "../../events/viewer/push_to_procore_dialog_shown";

export type PushToProcoreDialogStore = {
  open: boolean
  procoreProjects?: any[]
  selectedProcoreProjectId?: any
  pdfType?: any
  procoreAccessToken?: any
}

type Events =
  | ProcoreProjectsLoadedEvent
  | ProcoreProjectSelectedEvent
  | PushToProcoreDialogDismissedEvent
  | PushToProcoreDialogShownEvent
const reducePushToProcoreDialog: Reducer<PushToProcoreDialogStore, Events> = (pushToProcoreDialog = { open: false }, event) => {
  if (isRoutingEvent(event)) {
    return { open: false };
  }
  switch (event.type) {
    case PROCORE_PROJECTS_LOADED: {
      return {
        ...pushToProcoreDialog,
        open: true,
        procoreProjects: event.payload,
        selectedProcoreProjectId: event.payload[0]?.projectId
      };
    }

    case PUSH_TO_PROCORE_DIALOG_SHOWN: {
      return {
        ...pushToProcoreDialog,
        pdfType: event.payload.pdfType,
        procoreAccessToken: event.payload.procoreAccessToken,
        open: true,
      };
    }
    case PUSH_TO_PROCORE_DIALOG_DISMISSED: {
      return {
        ...pushToProcoreDialog,
        pdfType: null,
        open: false,
      };
    }
    case PROCORE_PROJECT_SELECTED: {
      return {
        ...pushToProcoreDialog,
        selectedProcoreProjectId: event.payload
      };
    }
    default: {
      return pushToProcoreDialog;
    }
  }
};

export default reducePushToProcoreDialog;
