import { AvvirEvent } from "type_aliases";
import ProjectReport from "../../../models/domain/project_report";

export type ProjectReportAddedEvent = AvvirEvent<typeof PROJECT_REPORT_ADDED, {projectId: string, report: ProjectReport}>

const projectReportAdded = (projectId: string, projectReport : ProjectReport): ProjectReportAddedEvent => ({
  type: PROJECT_REPORT_ADDED,
  payload: {
    projectId,
    report: projectReport
  }
});

export const PROJECT_REPORT_ADDED = "project_report_added";

export default projectReportAdded;
