import type { AvvirEvent } from "type_aliases";
import type { ApiCloudFile } from "avvir";

export type ProjectSummaryLoadedEvent = AvvirEvent<typeof PROJECT_SUMMARY_LOADED, { model: ApiCloudFile, projectAreaIds: number[], firebaseProjectId: string }>

const projectSummaryLoaded = (model: ApiCloudFile, projectAreaIds: number[], firebaseProjectId: string): ProjectSummaryLoadedEvent => ({
  type: PROJECT_SUMMARY_LOADED,
  payload: {
    model,
    projectAreaIds,
    firebaseProjectId
  }
});

export const PROJECT_SUMMARY_LOADED = "project_summary_loaded";

export default projectSummaryLoaded;
