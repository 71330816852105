import { ApiUserPermission, UserPermissionAction, UserPermissionType } from "avvir";

export type UserPermissionLike = Readonly<Partial<ApiUserPermission>>;

export function isPermissionImplied(permissionA: UserPermissionLike, permissionB: UserPermissionLike): boolean {
  return isPermissionActionImplied(permissionA.permissionAction, permissionB.permissionAction)
         && isPermissionTypeImplied(permissionA, permissionB);
}

export function isPermissionActionImplied(actionA: UserPermissionAction, actionB: UserPermissionAction): boolean {
  return actionA != null && (actionA === UserPermissionAction.ANY || actionA === actionB);
}

function isProjectIdEqual(permissionA: UserPermissionLike, permissionB: UserPermissionLike): boolean {
  return permissionA.projectFirebaseId != null && permissionA.projectFirebaseId === permissionB.projectFirebaseId;
}

function isOrganizationIdEqual(permissionA: UserPermissionLike, permissionB: UserPermissionLike): boolean {
  return permissionA.organizationFirebaseId != null && permissionA.organizationFirebaseId === permissionB.organizationFirebaseId;
}

function isMasterformatCodeEqual(permissionA: UserPermissionLike, permissionB: UserPermissionLike): boolean {
  return permissionA.masterformatCode != null && permissionA.masterformatCode === permissionB.masterformatCode;
}

export function isPermissionTypeImplied(permissionA: UserPermissionLike, permissionB: UserPermissionLike): boolean {
  const typeB = permissionB.permissionType;

  switch (permissionA.permissionType) {
    case UserPermissionType.ANY:
      return true;
    case UserPermissionType.PROJECT:
      return (typeB === UserPermissionType.PROJECT || typeB === UserPermissionType.MASTERFORMAT_CODE)
             && isProjectIdEqual(permissionA, permissionB);
    case UserPermissionType.ORGANIZATION:
      return (typeB === UserPermissionType.ORGANIZATION
              || typeB === UserPermissionType.PROJECT
              || typeB === UserPermissionType.MASTERFORMAT_CODE)
             && isOrganizationIdEqual(permissionA, permissionB);
    case UserPermissionType.MASTERFORMAT_CODE:
      return typeB === UserPermissionType.MASTERFORMAT_CODE
             && isProjectIdEqual(permissionA, permissionB)
             && isMasterformatCodeEqual(permissionA, permissionB);
  }

  return false;
}

export function isAnyPermissionImplied(permissions: UserPermissionLike[], permissionQuery: UserPermissionLike): boolean {
  if (permissions == null || permissions.length === 0 || permissionQuery == null) {
    return false;
  }

  const query = new ApiUserPermission(permissionQuery);
  for (const p of permissions) {
    if (isPermissionImplied(p, query)) {
      return true;
    }
  }

  return false;
}

export function createIsUserPermitted(permissions: UserPermissionLike[]): (permissionQuery: UserPermissionLike) => boolean {
  return (permissionQuery: UserPermissionLike) => {
    return isAnyPermissionImplied(permissions, permissionQuery);
  };
}
