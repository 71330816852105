import type { AvvirEvent } from "type_aliases";

export type WorkPackageDeletedEvent = AvvirEvent<typeof WORK_PACKAGE_DELETED, { projectId: string, workPackageId: number }>

const workPackageDeleted = (projectId: string, workPackageId: number): WorkPackageDeletedEvent => ({
  type: WORK_PACKAGE_DELETED,
  payload: {
    projectId,
    workPackageId
  }
});

export const WORK_PACKAGE_DELETED = "work_package_deleted";

export default workPackageDeleted;
