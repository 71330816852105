import { immerable } from "immer";
import { PhotoAreaPurposeType, addReadOnlyPropertiesToModel } from "avvir";

import type { AvvirApiFiles, AvvirFileIds, AvvirFiles, Modify } from "type_aliases";

export type PhotoAreaArgs = Partial<Modify<PhotoArea, {
  files?: AvvirFiles<PhotoAreaPurposeType> | AvvirApiFiles<PhotoAreaPurposeType> | AvvirFileIds<PhotoAreaPurposeType>
}>>

export default class PhotoArea {
  constructor({
    id,
    name,
    files,
    firebaseProjectId,
    externalProjectUrl,
    globalOffsetYaw,
    integrationProjectId
  }: PhotoAreaArgs = {}) {
    addReadOnlyPropertiesToModel(this, { id, firebaseProjectId });
    this.name = name;
    this.externalProjectUrl = externalProjectUrl;
    // @ts-ignore
    this.files = files || {};
    this.globalOffsetYaw = globalOffsetYaw;
    this.integrationProjectId = integrationProjectId;
  }

  readonly id: number;
  readonly firebaseProjectId: string;
  name: string;
  externalProjectUrl?: string;
  files?: AvvirFileIds<PhotoAreaPurposeType>;
  globalOffsetYaw: number;
  integrationProjectId?: number;

  static readonly [immerable] = true;
}
