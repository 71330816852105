import { ApiCommentThread } from "avvir";
import { AvvirEvent } from "type_aliases";

export type CommentThreadCreatedEvent = AvvirEvent<typeof COMMENT_THREAD_CREATED, { projectId: string, commentThread: ApiCommentThread}>;

const commentThreadCreated = (projectId: string, commentThread: ApiCommentThread): CommentThreadCreatedEvent => ({
  type: COMMENT_THREAD_CREATED,
  payload: {
    projectId,
    commentThread
  }
});

export const COMMENT_THREAD_CREATED = "comment_thread_created";

export default commentThreadCreated;
