import { AvvirRoutingEvent } from "type_aliases";

export type ToSuperadminFloorEvent = AvvirRoutingEvent<typeof TO_SUPERADMIN_FLOOR, {
  organizationId: string
  projectId: string
  floorId: string
}>

const toSuperadminFloor = (organizationId: string, projectId: string, floorId: string): ToSuperadminFloorEvent => ({
  type: TO_SUPERADMIN_FLOOR,
  payload: {
    organizationId,
    projectId,
    floorId
  },
});

export const TO_SUPERADMIN_FLOOR = "to_superadmin_floor";

export default toSuperadminFloor;
