import { Container, Graphics, IPointData, Point, Rectangle } from "pixi.js";
import { PixiMinimapApp } from "../../pixi_minimap_app";
import { PhotoSprite } from "../photo_sprite";
import { PixiPhotosLayer } from "../photos_layer";

export type PhotoFenceSelection = {
  photos: PhotoSprite[]
  bounds: Rectangle
};

export class PhotoFenceSelector extends Container {
  private readonly _dragSelectionBounds: Rectangle = new Rectangle();
  private readonly _graphics: Graphics;
  private _dragStartPosition: Point = new Point();
  private _locations: PixiPhotosLayer;
  public wasDragged: boolean = false;

  constructor(locations: PixiPhotosLayer) {
    super();
    this._locations = locations;
    this.visible = false;
    this._graphics = new Graphics();
    this.addChild(this._graphics);
  }

  onDragEnd(): PhotoFenceSelection {
    const selection = this.getSelection(this._locations.photoSprites);
    this._dragStartPosition = null;
    this._graphics.clear();
    this.visible = false;
    this.wasDragged = false;
    return selection;
  }

  onDragStart(position: IPointData) {
    this._dragStartPosition.copyFrom(position);
    this._graphics.clear();
    this._locations.needsRender = true;
  }

  onDrag(app: PixiMinimapApp, start: IPointData, end: IPointData) {
    const g = this._graphics;
    const style = app.styles.selectedStyle;
    this.visible = true;
    this.position.set(0,0);
    g.clear();
    g.lineStyle(3, style.lineColor);
    g.beginFill(style.fillColor, style.fillAlpha);
    g.drawRect(start.x, start.y, end.x - start.x, end.y - start.y);
    this._dragSelectionBounds.x = start.x;
    this._dragSelectionBounds.y = start.y;
    this._dragSelectionBounds.width = end.x - start.x;
    this._dragSelectionBounds.height = end.y - start.y;

    this.wasDragged = true;
    app.requestRender();
  }

  getSelection(photos: Iterable<PhotoSprite>): PhotoFenceSelection {
    const selected = [];
    const rect = this._dragSelectionBounds.clone();
    if (rect.width < 0) {
      rect.x += rect.width;
      rect.width = -1 * rect.width;
    }

    if (rect.height < 0) {
      rect.y += rect.height;
      rect.height = -1 * rect.height;
    }

    const minPoint = new Point(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
    const maxPoint = new Point(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);

    for (let photo of photos) {
      const contained = rect.contains(photo.x, photo.y);
      if (contained) {
        PhotoFenceSelector.updateMinMaxPoints(minPoint, maxPoint, photo.position);
        selected.push(photo);
      }
    }

    return {
      bounds: new Rectangle(minPoint.x, minPoint.y, maxPoint.x - minPoint.x, maxPoint.y - minPoint.y),
      photos: selected
    };
  }

  static updateMinMaxPoints(minPoint: IPointData, maxPoint: IPointData, point: IPointData) {
    if (minPoint.x > point.x) {
      minPoint.x = point.x;
    }

    if (maxPoint.x < point.x) {
      maxPoint.x = point.x;
    }

    if (minPoint.y > point.y) {
      minPoint.y = point.y;
    }

    if (maxPoint.y < point.y) {
      maxPoint.y = point.y;
    }
  }

}
