import { Reducer } from "redux";

import { BIM_AND_PHOTO_CAMERA_LINKED, BimAndPhotoCameraLinkedEvent } from "../../events/viewer/bim_and_photo_camera_linked";
import { BIM_AND_PHOTO_CAMERA_UNLINKED, BimAndPhotoCameraUnlinkedEvent } from "../../events/viewer/bim_and_photo_camera_unlinked";
import { PHOTO_VIEWER_DISPOSED, PhotoViewerDisposedEvent } from "../../events/viewer/photo_viewer_disposed";
import { PHOTO_VIEWER_INITIALIZED, PhotoViewerInitializedEvent } from "../../events/viewer/photo_viewer_initialized";
import { VIEWER_DISPOSED, ViewerDisposedEvent } from "../../events/viewer/viewer_disposed";
import { VIEWER_INITIALIZED, ViewerInitializedEvent } from "../../events/viewer/viewer_initialized";
import { BIM_COLORS_DIALOG_SHOWN, BimColorsDialogShownEvent } from "../../events/viewer/bim_colors_dialog_shown";
import { BIM_COLORS_DIALOG_DISMISSED, BimColorsDialogDismissedEvent } from "../../events/viewer/bim_colors_dialog_dismissed";
import { CustomBimColors } from "../utilities/bim_colors";
import { BIM_COLORS_SAVED, BimColorsSavedEvent } from "../../events/viewer/bim_colors_saved";

export type ViewerStore = {
  photoViewerReady?: boolean
  bimAndPhotoCameraLinked?: boolean
  bimCameraReady?: boolean,
  minimapLineOfSightReference?: string
  bimColorsDialogOpen?: boolean
  bimColors?: CustomBimColors
}

type ViewerEvent =
  ViewerInitializedEvent |
  ViewerDisposedEvent |
  PhotoViewerInitializedEvent |
  PhotoViewerDisposedEvent |
  BimAndPhotoCameraLinkedEvent |
  BimAndPhotoCameraUnlinkedEvent |
  BimColorsDialogShownEvent |
  BimColorsDialogDismissedEvent |
  BimColorsSavedEvent

const reduceViewer: Reducer<ViewerStore, ViewerEvent> = (viewerStore: ViewerStore = {}, event) => {
  switch (event.type) {
    case VIEWER_INITIALIZED: {
      return {
        ...viewerStore,
        bimCameraReady: true
      };
    }
    case VIEWER_DISPOSED: {
      return {
        ...viewerStore,
        bimCameraReady: false
      };
    }
    case PHOTO_VIEWER_INITIALIZED: {
      return {
        ...viewerStore,
        photoViewerReady: true
      };
    }
    case PHOTO_VIEWER_DISPOSED: {
      return {
        ...viewerStore,
        photoViewerReady: false
      };
    }
    case BIM_AND_PHOTO_CAMERA_LINKED: {
      return {
        ...viewerStore,
        bimAndPhotoCameraLinked: true
      };
    }
    case BIM_AND_PHOTO_CAMERA_UNLINKED: {
      return {
        ...viewerStore,
        bimAndPhotoCameraLinked: false
      };
    }
    case BIM_COLORS_DIALOG_SHOWN: {
      return {
        ...viewerStore,
        bimColorsDialogOpen: true
      };
    }
    case BIM_COLORS_DIALOG_DISMISSED: {
      return {
        ...viewerStore,
        bimColorsDialogOpen: false
      };
    }
    case BIM_COLORS_SAVED: {
      return {
        ...viewerStore,
        bimColors: event.payload
      };
    }
    default: {
      return viewerStore;
    }
  }
};

export default reduceViewer;

