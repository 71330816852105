import { produce } from "immer";

import makeUpdateModel from "./make_update_model";
import WorkPackage from "../../../models/domain/work_package";
import { WORK_PACKAGE_CREATED, WorkPackageCreatedEvent } from "../../../events/project_settings/work_package_created";
import { WORK_PACKAGE_DELETED, WorkPackageDeletedEvent } from "../../../events/project_settings/work_package_deleted";
import { WORK_PACKAGES_LOADED, WorkPackagesLoadedEvent } from "../../../events/loaded/work_packages_loaded";

import type { ByDatabaseId } from "type_aliases";
import type { Reducer } from "redux";
import { WORK_PACKAGE_UPDATED, WorkPackageUpdatedEvent } from "../../../events/project_settings/work_package_updated";

type WorkPackagesEvents =
  | WorkPackagesLoadedEvent
  | WorkPackageCreatedEvent
  | WorkPackageDeletedEvent
  | WorkPackageUpdatedEvent

export type WorkPackagesStore = {
  byFirebaseProjectId: { [firebaseProjectId: string]: { byId: ByDatabaseId<WorkPackage> } }
}

const updateWorkPackage = makeUpdateModel(new WorkPackage());

const reduceWorkPackages: Reducer<WorkPackagesStore, WorkPackagesEvents> = (workPackages: WorkPackagesStore = { byFirebaseProjectId: {} }, event) => {
  return produce<WorkPackagesStore>(workPackages, (workPackagesDraft) => {
    if (event.payload?.projectId) {
      workPackagesDraft.byFirebaseProjectId[event.payload.projectId] ||= {
        byId: {}
      };
    }
    switch (event.type) {
      case WORK_PACKAGES_LOADED: {
        workPackagesDraft.byFirebaseProjectId[event.payload.projectId] = event.payload.workPackages.reduce((workPackagesSoFar, workPackage) => {
          return updateWorkPackage(workPackagesSoFar, workPackage.id, workPackage);
        }, workPackagesDraft.byFirebaseProjectId[event.payload.projectId]);
        break;
      }
      case WORK_PACKAGE_CREATED: {
        workPackagesDraft.byFirebaseProjectId[event.payload.projectId] = updateWorkPackage(
          workPackagesDraft.byFirebaseProjectId[event.payload.projectId],
          event.payload.workPackage.id,
          event.payload.workPackage
        );
        break;
      }
      case WORK_PACKAGE_DELETED: {
        delete workPackagesDraft.byFirebaseProjectId[event.payload.projectId]?.byId?.[event.payload.workPackageId];
        break;
      }
      case WORK_PACKAGE_UPDATED: {
        workPackagesDraft.byFirebaseProjectId[event.payload.projectId] = updateWorkPackage(
          workPackagesDraft.byFirebaseProjectId[event.payload.projectId],
          event.payload.workPackage.id,
          event.payload.workPackage
          );
        break;
      }
      default: {}
    }
  });
};

export default reduceWorkPackages;

