import type { FunctionComponent, MouseEventHandler, TouchEventHandler } from "react";
import React, { MutableRefObject } from "react";
import { connect } from "react-redux";
import { selectLocationState } from "redux-first-router";

import toUrl, { To } from "./toUrl";
import handlePress from "./handlePress";
import preventDefault from "./preventDefault";


// Unfortunately we can't pass `HTMLAnchorElement` since the `tagName` attribute allows you to use other tags than anchor.
export interface LinkProps extends React.HTMLAttributes<HTMLElement> {
  to: To;
  redirect?: boolean;
  replace?: boolean;
  tagName?: string;
  down?: boolean;
  shouldDispatch?: boolean;
  target?: string;
}

type Props = ReturnType<typeof mapState> & {
  dispatch: (...args: Array<any>) => any
  linkRef: ((instance: unknown | null) => void) | MutableRefObject<unknown | null> | null
} & LinkProps

type LocalProps = {
  href?: string
  onMouseDown?: MouseEventHandler
  onTouchStart?: TouchEventHandler
  target?: string
}

export const Link: FunctionComponent<Props> = function Link({
  to,
  redirect,
  replace,
  tagName = "a",
  children,
  onClick,
  down = false,
  shouldDispatch = true,
  target,
  dispatch,
  routesMap,
  linkRef: ref,
  ...props
}) {
  const url = toUrl(to, routesMap);
  const handler = handlePress.bind(
    null,
    url,
    routesMap,
    onClick,
    shouldDispatch,
    target,
    dispatch,
    to,
    replace || redirect
  );
  const Root = tagName;
  const localProps: LocalProps = {};

  if (tagName === "a" && url) {
    localProps.href = url;
  }

  if (down && handler) {
    localProps.onMouseDown = handler;
    localProps.onTouchStart = handler;
  }

  if (target) {
    localProps.target = target;
  }

  // @ts-ignore
  return <Root
    onClick={!down && (handler || preventDefault)}
    {...localProps}
    {...props}
    {...{ ref }}
  >
    {children}
  </Root>;
};

export const LinkWithRef = React.forwardRef((props: Omit<Props, "linkRef">, ref) => {
  return <Link {...props} linkRef={ref}/>;
});

const mapState = state => ({
  routesMap: selectLocationState(state).routesMap
});

const connector = connect(
  mapState,
  undefined,
  undefined,
  {
    forwardRef: true
  }
);

export default connector(LinkWithRef);
