import { AvvirEvent } from "type_aliases";
import { InspectionMode } from "../../models/domain/enums/inspection_modes";

export type InspectionModeSelectedEvent = AvvirEvent<typeof INSPECTION_MODE_SELECTED, InspectionMode>

const inspectionModeSelected = (inspectionMode: InspectionMode): InspectionModeSelectedEvent => ({
  type: INSPECTION_MODE_SELECTED,
  payload: inspectionMode
});

export const INSPECTION_MODE_SELECTED = "inspection_mode_selected";

export default inspectionModeSelected;
