import { ApiPurposeType, AssociationType } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";
import PurposeTypeConverter from "../../services/converters/purpose_type_converter";
import { DateLike, Modify } from "type_aliases";
import { isPurposeType, PurposeType } from "./enums/purpose_type";

type CloudFileArgument<Type extends PurposeType = PurposeType> = Partial<Modify<CloudFile<Type>, {
  lastModified?: DateLike
  createdAt?: DateLike
  purposeType?: Type | ApiPurposeType,
  associationId: number,
  associationType: AssociationType
}>>

export default class CloudFile<Type extends PurposeType = PurposeType> {
  constructor({ url, id, lastModified, createdAt, purposeType, createdBy, sizeInBytes, originalFileName, associationId, associationType, metadata }: CloudFileArgument<Type> = {}) {
    addDateGetterAndSetterToDomainModel(this, "lastModified", lastModified);
    addDateGetterAndSetterToDomainModel(this, "createdAt", createdAt);
    addReadOnlyPropertiesToModel(this, { id, url });
    if (isPurposeType(purposeType)) {
      addReadOnlyPropertiesToModel(this, { purposeType });
    } else {
      addReadOnlyPropertiesToModel(this, { purposeType: PurposeTypeConverter.toPurposeType(purposeType) });
    }

    this.createdBy = createdBy;
    this.sizeInBytes = sizeInBytes;
    this.originalFileName = originalFileName;
    this.associationId = associationId;
    this.associationType = associationType;
    this.metadata = metadata;
  }

  readonly url: string;
  readonly id?: number | null = null;
  lastModified?: Date | null = null;
  createdAt?: Date | null = null;
  readonly createdBy?: string;
  originalFileName?: string;
  purposeType: Type = null;
  sizeInBytes?: number;
  associationId: number;
  associationType: AssociationType;
  metadata: Record<string, any>;
}
