import { AvvirEvent } from "type_aliases";
import { ApiPhotoArea } from "avvir";

export type PhotoAreasLoadedEvent = AvvirEvent<typeof PHOTO_AREAS_LOADED, { projectId: string, photoAreas: ApiPhotoArea[] }>

const photoAreasLoaded = (projectId: string, photoAreas: ApiPhotoArea[]): PhotoAreasLoadedEvent => ({
  type: PHOTO_AREAS_LOADED,
  payload: {
    projectId,
    photoAreas
  }
});

export const PHOTO_AREAS_LOADED = "photo_areas_loaded";

export default photoAreasLoaded;
