import { AvvirEvent } from "type_aliases";

export type AcceptInvitationStartedEvent = AvvirEvent<typeof ACCEPT_INVITATION_STARTED, { email: string }>

const acceptInvitationStarted = (email: string): AcceptInvitationStartedEvent => ({
  type: ACCEPT_INVITATION_STARTED,
  payload: { email }
});

export const ACCEPT_INVITATION_STARTED = "accept_invitation_started";

export default acceptInvitationStarted;
