import { AvvirRoutingEvent } from "type_aliases";
import PageNames from "../../models/domain/enums/page_names";

export type ToProgress4dReportEvent = AvvirRoutingEvent<typeof TO_PROGRESS_4D_REPORT, {
  projectId: string,
  embedded?: boolean,
  pageName: PageNames
}>

const toProgress4dReport = ({
  projectId,
  embedded = false,
  pageName = PageNames.PROJECT_REPORTS
}: {
  projectId: string,
  embedded?: boolean,
  pageName?: PageNames
}): ToProgress4dReportEvent => ({
  type: TO_PROGRESS_4D_REPORT,
  payload: {
    projectId,
    embedded,
    pageName
  }
});

export const TO_PROGRESS_4D_REPORT = "to_project_4d_report";

export default toProgress4dReport;
