import getProjectIdFromLocation from "../../../services/getters/location_metadata/get_project_id_from_location";
import getUser from "../../../services/getters/base_getters/get_user";
import ProcoreApi from "../../../services/procore_api_services/procore_api";
import pushToProcoreDialogShown from "../../../events/viewer/push_to_procore_dialog_shown";

const getProcoreAccessToken = (pdfType) => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = getProjectIdFromLocation(state, {})
    const user = getUser(state, {});
    const procoreAccessToken = window.localStorage["procore-access-token"];

    const lookForAccessToken = () => {
      const procoreAccessToken = window.localStorage["procore-access-token"];
      if (procoreAccessToken) {
        window.removeEventListener("storage", lookForAccessToken);
        return dispatch(pushToProcoreDialogShown(procoreAccessToken, pdfType))
      }
    };

    if (procoreAccessToken) {
      return ProcoreApi.checkProcoreAccessToken(projectId, procoreAccessToken, user, dispatch).then((procoreTokenInfo) => {
        if(procoreTokenInfo.expiresInSeconds >= 60){
          dispatch(pushToProcoreDialogShown(procoreAccessToken, pdfType))
        } else {
          window.localStorage.removeItem("procore-access-token");
          window.open(ProcoreApi.getLoginUrl({ pdfType }));
          window.addEventListener("storage", lookForAccessToken);
          lookForAccessToken(); // must be after the addEventListener so it can remove it
        }
      });
    } else {
      window.open(ProcoreApi.getLoginUrl({ pdfType }));
      window.addEventListener("storage", lookForAccessToken);
      lookForAccessToken(); // must be after the addEventListener so it can remove it
    }
  }

};
export default getProcoreAccessToken;
