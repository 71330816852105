import React, { FunctionComponent, useState } from "react";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import "../../css/snackbar_notification.css";
import { bindActionCreators } from "redux";
import toastNotificationExpired from "../events/notifications/toast_notification_expired";
import classNames from "classnames";

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

//New Component to start using MUI snackbars rather than our div
const SnackbarNotification: FunctionComponent<Props> = (props) => {
  const { message, link, level, toastNotificationExpired, children } = props;
  const [showToast, setShowToast] = useState(true);

  const handleClose = () => {
    setShowToast(false);
    toastNotificationExpired();
  };

  return <Snackbar
    className={`SnackbarNotification-${level}`}
    anchorOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    open={showToast}
    message={link ?
             <span className="SnackbarNotification">{message} <a href={link} rel="noopener noreferrer" target="_blank">{link}</a></span> :
             <span className="SnackbarNotification">{message}</span>}
    action={
      <IconButton size="small" aria-label="close" onClick={handleClose}>
        {children}
        <CloseIcon fontSize="small"/>
      </IconButton>}
  />;
};

const mapStateToProps = (state) => ({
  level: state.toastNotification.level,
  link: state.toastNotification.link,
  message: state.toastNotification.message
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  toastNotificationExpired,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarNotification);
