import { AvvirEvent } from "type_aliases";
import { ApiUserPermission } from "avvir";

export type UserPermissionsLoadedEvent = AvvirEvent<typeof USER_PERMISSIONS_LOADED, { permissions: ApiUserPermission[] }>

const userPermissionsLoaded = (permissions: ApiUserPermission[]): UserPermissionsLoadedEvent => ({
  type: USER_PERMISSIONS_LOADED,
  payload: {
    permissions
  }
});

export const USER_PERMISSIONS_LOADED = "user_permissions_loaded";

export default userPermissionsLoaded;
