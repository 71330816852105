import { AvvirEvent } from "type_aliases";

export type FirebaseSignedInWithCustomTokenEvent = AvvirEvent<typeof FIREBASE_SIGNED_IN_WITH_CUSTOM_TOKEN, { token: string }>

const firebaseSignedInWithCustomToken = (token: string): FirebaseSignedInWithCustomTokenEvent => ({
  type: FIREBASE_SIGNED_IN_WITH_CUSTOM_TOKEN,
  payload: {
    token
  }
});

export const FIREBASE_SIGNED_IN_WITH_CUSTOM_TOKEN = "firebase_signed_in_custom_token";

export default firebaseSignedInWithCustomToken;
