import { createSelector } from "reselect";
import getDomain from "../base_getters/get_domain";

const getGroupMembers = createSelector([
  getDomain
], (domain) => {
  return domain.groupMembers;
});

export default getGroupMembers
