import Avvir from "avvir";

import floorsForProjectLoaded, { FloorsForProjectLoadedEvent } from "../../../events/loaded/floors/floors_for_project_loaded";
import getFloorsForProjectByFirebaseId from "../../../services/getters/floor_getters/get_floors_for_project_by_firebase_id";
import { AvvirAsyncThunk } from "../../make_eventful_action";

const loadFloorsForProject = (projectId: string): AvvirAsyncThunk<typeof getFloorsForProjectByFirebaseId, FloorsForProjectLoadedEvent> => {
  return (dispatch, getState) => {
    return Avvir.api.floors.listFloorsForProject(projectId, getState().user)
      .then((floors) => {
        dispatch(floorsForProjectLoaded(floors));
        return getFloorsForProjectByFirebaseId(getState(), {});
      });
  };
};

export default loadFloorsForProject;
