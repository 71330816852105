import { AvvirEvent } from "type_aliases";
import { ApiPlannedElement } from "avvir";

export type PlannedBuildingElementsLoadedEvent = AvvirEvent<typeof PLANNED_BUILDING_ELEMENTS_LOADED, { floorId: string, elements: ApiPlannedElement[] }>

const plannedBuildingElementsLoaded = (floorId: string, elements: ApiPlannedElement[]): PlannedBuildingElementsLoadedEvent => ({
  type: PLANNED_BUILDING_ELEMENTS_LOADED,
  payload: {
    floorId,
    elements
  }
});

export const PLANNED_BUILDING_ELEMENTS_LOADED = "planned_building_elements_loaded";

export default plannedBuildingElementsLoaded;
