import { AvvirRoutingEvent } from "type_aliases";

export type ToPhotosFloorEvent = AvvirRoutingEvent<typeof TO_PHOTOS_FLOOR, { projectId: string, floorId: string }>

const toPhotosFloor = (projectId: string, floorId: string): ToPhotosFloorEvent => ({
  type: TO_PHOTOS_FLOOR,
  payload: {
    projectId,
    floorId
  }
});

export const TO_PHOTOS_FLOOR = "to_photos_floor";

export default toPhotosFloor;
