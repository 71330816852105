import type { ApiWorkPackage } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type WorkPackageUpdatedEvent = AvvirEvent<typeof WORK_PACKAGE_UPDATED, { projectId: string, workPackage: ApiWorkPackage }>

const workPackageUpdated = (projectId: string, workPackage: ApiWorkPackage): WorkPackageUpdatedEvent => ({
  type: WORK_PACKAGE_UPDATED,
  payload: {
    projectId,
    workPackage
  }
});

export const WORK_PACKAGE_UPDATED = "work_package_updated";

export default workPackageUpdated;
