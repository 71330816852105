import React, { FunctionComponent } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuItem from "@mui/material/MenuItem";

import signOutUser from "../../../actions/sign_in_page/sign_out_user";

export type Props = ReturnType<typeof mapDispatchToProps>;

export const NavBarKebabItemLogout: FunctionComponent<Props> = (props) => {
  const { signOutUser } = props;

  return <MenuItem className="NavBarKebabItemLogout NavBarKebabMenu-menuItem" onClick={() => signOutUser()}>
    Logout
  </MenuItem>;
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  signOutUser,
}, dispatch);

export default connect(null, mapDispatchToProps)(NavBarKebabItemLogout);
