import { Action } from "redux";

export type NotBuiltElementsShownEvent = Action<typeof NOT_BUILT_ELEMENTS_SHOWN>

const notBuiltElementsShown = (): NotBuiltElementsShownEvent => ({
  type: NOT_BUILT_ELEMENTS_SHOWN,
});

export const NOT_BUILT_ELEMENTS_SHOWN = "not_built_elements_shown";

export default notBuiltElementsShown;