import { AvvirEvent } from "type_aliases";
import { ApiRecipe } from "avvir";

export type ProjectRecipesUpdatedEvent = AvvirEvent<typeof PROJECT_RECIPES_UPDATED, { recipes: ApiRecipe[] }>

const projectRecipesUpdated = ( recipes: ApiRecipe[] ): ProjectRecipesUpdatedEvent => ({
  type: PROJECT_RECIPES_UPDATED,
  payload: {
    recipes
  }
});

export const PROJECT_RECIPES_UPDATED = "project_recipes_updated";

export default projectRecipesUpdated;
