import type { ApiWorkPackage } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type WorkPackagesLoadedEvent = AvvirEvent<typeof WORK_PACKAGES_LOADED, { projectId: string, workPackages: ApiWorkPackage[] }>

const workPackagesLoaded = (projectId: string, workPackages: ApiWorkPackage[]): WorkPackagesLoadedEvent => ({
  type: WORK_PACKAGES_LOADED,
  payload: {
    projectId,
    workPackages
  }
});

export const WORK_PACKAGES_LOADED = "work_packages_loaded";

export default workPackagesLoaded;
