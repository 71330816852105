import { AvvirEvent } from "type_aliases";
import BimtrackHub from "../../models/api/bimtrack_hub";

export type BimtrackHubsLoadedEvent = AvvirEvent<typeof BIMTRACK_HUBS_LOADED, BimtrackHub[]>

const bimtrackHubsLoaded = (hubs: BimtrackHub[]): BimtrackHubsLoadedEvent => ({
  type: BIMTRACK_HUBS_LOADED,
  payload: hubs
});

export const BIMTRACK_HUBS_LOADED = "bimtrack_hubs_loaded";

export default bimtrackHubsLoaded;
