import { createSelector } from "reselect";
import _ from "underscore";

import getPlannedElementsByGlobalId from "../get_planned_elements_by_global_id";
import getSelectedElementGlobalIds from "./get_selected_element_global_ids";
import PlannedBuildingElement from "../../../../models/domain/planned_building_element";

const empty: PlannedBuildingElement[] = [];
const getSelectedElements = createSelector([
  getSelectedElementGlobalIds,
  getPlannedElementsByGlobalId
], (selectedGlobalIds, elementsByGlobalId) => {
  if (selectedGlobalIds.length) {
    return _.compact(selectedGlobalIds.map(globalId => elementsByGlobalId?.[globalId]));
  } else {
    return empty;
  }
});

export default getSelectedElements;
