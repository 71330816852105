import { Action } from "redux";

export type ShowPointCloudToggledEvent = Action<typeof SHOW_POINT_CLOUD_TOGGLED>;

const showPointCloudToggled = (): ShowPointCloudToggledEvent => ({
  type: SHOW_POINT_CLOUD_TOGGLED
});

export const SHOW_POINT_CLOUD_TOGGLED = "show_point_cloud_toggled";

export default showPointCloudToggled;
