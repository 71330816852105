// noinspection ES6UnusedImports

import { MouseEvent, useCallback, useState } from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type Popover from "@mui/material/Popover"

/**
 * Custom react hook for material-ui components that use the {@link Popover} component internally
 *
 * @returns [open, anchorElement, handleOpen, handleClose]
 */
const usePopover = (): [boolean, HTMLElement, (event: MouseEvent) => void, () => void] => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setAnchorEl(null);
    setOpen(false);
  }, []);

  const onOpen = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }, [])
  return [open, anchorEl, onOpen, onClose];
};

export default usePopover;
