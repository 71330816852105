import { ApiIntegrationCredentials } from "avvir";
import { AvvirEvent } from "type_aliases";

export type IntegrationCredentialsSavedEvent = AvvirEvent<typeof INTEGRATION_CREDENTIALS_SAVED, { projectId: string, integrationCredentials: ApiIntegrationCredentials}>;

const integrationCredentialsSaved = (projectId: string, integrationCredentials: ApiIntegrationCredentials): IntegrationCredentialsSavedEvent => ({
  type: INTEGRATION_CREDENTIALS_SAVED,
  payload: {
    projectId,
    integrationCredentials
  }
});

export const INTEGRATION_CREDENTIALS_SAVED = "integration_credentials_saved";

export default integrationCredentialsSaved;
