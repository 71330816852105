import { AvvirEvent } from "type_aliases";

export type FloorCreatedEvent = AvvirEvent<typeof FLOOR_CREATED, { firebaseProjectId: string, firebaseId: string, floorNumber: string }>

const floorCreated = (firebaseProjectId: string, { firebaseId, floorNumber }: { firebaseId: string, floorNumber: string }): FloorCreatedEvent => ({
  type: FLOOR_CREATED,
  payload: {
    firebaseProjectId,
    firebaseId,
    floorNumber
  }
});

export const FLOOR_CREATED = "floor_created";

export default floorCreated;
