import { AvvirEvent } from "type_aliases";
import TeamMember from "../../../models/domain/team_member";

export type ProjectTeamMembersLoadedEvent = AvvirEvent<typeof PROJECT_TEAM_MEMBERS_LOADED, { projectId: string, teamMembers: TeamMember[] }>

const projectTeamMembersLoaded = (projectId: string, teamMembers: TeamMember[]): ProjectTeamMembersLoadedEvent => ({
  type: PROJECT_TEAM_MEMBERS_LOADED,
  payload: {
    projectId,
    teamMembers
  }
});

export const PROJECT_TEAM_MEMBERS_LOADED = "project_team_members_loaded";

export default projectTeamMembersLoaded;
