import View from "../../models/domain/view";
import { AvvirEvent } from "type_aliases";

export type ViewLoadedEvent = AvvirEvent<typeof VIEW_LOADED, View>

const viewLoaded = (view: View): ViewLoadedEvent => ({
  type: VIEW_LOADED,
  payload: view
});

export const VIEW_LOADED = "view_loaded";

export default viewLoaded;
