import React, { FunctionComponent, useMemo, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography,
  Checkbox, Button, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import "../../../css/dialogs/export_deviations_ifc_dialog.css";
import "../../../css/dialogs/batch_ifc_floor_selection_dialog.css";
import useFloorsForProject from "../../queries/floors/use_floors_for_project";
import _ from "underscore";
import Floor from "../../models/domain/floor";

export type Props = {
  title: string
  projectId: string
  open: boolean
  onClose: () => void
  onClickOk: (arr: string[]) => void
}

const BatchIfcFloorSelectionDialog: FunctionComponent<Props> = (props) => {
  const { title, projectId, open, onClose, onClickOk } = props;
  const { data: floorsByFirebaseId } = useFloorsForProject(projectId);

  const floors = useMemo(() => {
    return _.values(floorsByFirebaseId);
  }, [floorsByFirebaseId]);

  const [selectedFloor, setSelectedFloor] = useState<string[]>([]);

  const onSelectFloor = (floorFirebaseId: string) => () => {
    const currentIndex = selectedFloor.indexOf(floorFirebaseId);
    const newSelectedItems = [...selectedFloor];

    if (currentIndex === -1) {
      newSelectedItems.push(floorFirebaseId);
    } else {
      newSelectedItems.splice(currentIndex, 1);
    }

    setSelectedFloor(newSelectedItems);
  };

  const onToggleCheckbox = (floor: Floor) => {
    return selectedFloor.indexOf(floor.firebaseId) !== -1;
  }

  const floorItems = floors?.map((floor) => (
    <ListItemButton className="ListItemCss" key={floor.firebaseId} onClick={onSelectFloor(floor.firebaseId)}>
      <ListItemIcon>
        <Checkbox
          checked={onToggleCheckbox(floor)}
          tabIndex={-1}
          disableRipple
        />
      </ListItemIcon>
      <ListItemText primary={floor.floorNumber} />
    </ListItemButton>
  ));

  const handleClickOk = () => {
    onClickOk(selectedFloor);
  }

  const ifcType = {asBuilt: "As-built", not_built: "Not Built", deviations: "Deviations"};

  const titleString = useMemo(() => {
    let result = "Export";
    switch (title) {
      case "as_built" : {
        result = `${result} ${ifcType.asBuilt}`;
        break;
      }
      case "not_built": {
        result = `${result} ${ifcType.not_built}`;
        break;
      }
      case "all_deviations": {
        result = `${result} ${ifcType.deviations}`;
        break;
      }
      case "current_deviations": {
        result = `${result} ${ifcType.deviations}`;
        break;
      }
    }
    return result;
  }, [title]);

  const selectAll = () => {
    let floorFirebaseIds = floors.map(floor => floor.firebaseId);
    setSelectedFloor(floorFirebaseIds);
  }

  const deselectAll = () => {
    setSelectedFloor([]);
  }

  const dialogListSelectionOptions = (
    <div style={{ display: "inline", padding: "10px 24px 0px 24px" }}>
      <span onClick={selectAll} className="selectionOptionBtnCss">
          Select All
        </span>
      <span> | </span>
      <span onClick={deselectAll} className="selectionOptionBtnCss">
          Deselect All
        </span>
    </div>
  );

  return <Dialog
    open={open}
    aria-label="Export Deviations Dialog"
    PaperProps={{ className: "DialogModelCss" }}>
    <DialogTitle className="DialogTitleCss">
      <Typography variant="body2" component="span" style={{ fontSize: "18px", fontWeight: "bold" }}>{titleString}</Typography>
    </DialogTitle>
    {dialogListSelectionOptions}
    <DialogContent className="DialogContentCss">
      {floorItems}
    </DialogContent>
    <DialogActions className="ExportDeviationsIfc-actions">
      <Button onClick={onClose} color="secondary" className="DialogActionsButtonCss" style={{ border: "1px solid black" }}>
        Cancel
      </Button>
      <Button onClick={handleClickOk}
              className="DialogActionsButtonCss" style={{ background: "#1f788c", color: "white", border: "1px solid #1F788C" }}>
        Export
      </Button>
    </DialogActions>
  </Dialog>;
};

export default BatchIfcFloorSelectionDialog;