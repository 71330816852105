import React from "react";
import ReactDOM from "react-dom";
import "firebaseui/dist/firebaseui.css";

import "./css/main.css";
import App from "./javascript/components/avvir";

// This prevents the ResizeObserver from throwing a benign error: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
window.addEventListener("error", (event) => {
  if (event.message === "ResizeObserver loop completed with undelivered notifications." ||
      event.message === "ResizeObserver loop limit exceeded") {
    event.stopImmediatePropagation();
  }
});

ReactDOM.render(<App/>,
  document.getElementById("root")
);
