import React, { FunctionComponent } from "react";

import "../../../css/nav_bar/nav_bar_controls_explorer.css";
import NavBarExportDropdownMenu from "./nav_bar_export_dropdown_menu";
import NavBarFloorDropdown from "./shared/nav_bar_floor_dropdown";
import NavBarKebabMenu from "./nav_bar_kebab_menu";
import NavBarPanelsMenu from "./nav_bar_panels_menu";
import NavBarScanDropdown from "./shared/nav_bar_scan_dropdown";


export const NavBarControlsExplorer: FunctionComponent<Record<string, never>> = () => {
  return <>
    <NavBarFloorDropdown/>
    <NavBarScanDropdown/>
    <NavBarPanelsMenu/>
    <NavBarExportDropdownMenu/>
    <NavBarKebabMenu/>
  </>;
};

export default React.memo(NavBarControlsExplorer);
