import React, { FunctionComponent, useCallback, useRef, useState } from "react";
import classnames from "classnames";
import useSwitch from "@react-hook/switch";
import type { PopperProps } from "@mui/material/Popper";
import type { TooltipProps } from "@mui/material/Tooltip";
import type { TransitionProps } from "@mui/material/transitions";

import "../../../css/shared/tooltipped_on_overflow.css";
import Tooltipable from "./tooltipable";
import useDebouncedResizer from "../../services/component_helpers/component_effects/use_debounced_resizer";
import useTimeout from "../../services/component_helpers/component_effects/use_timeout";

export type Props = {
  className?: string
  enterDelay?: number
  enterTouchDelay?: number
  id?: string
  interactive?: boolean
  leaveDelay?: number
  leaveTouchDelay?: number
  offsetX?: number
  offsetY?: number
  placement?: TooltipProps["placement"]
  PopperProps?: Partial<PopperProps>
  TransitionComponent?: FunctionComponent<TransitionProps>
  TransitionProps?: TransitionProps
  children: React.ReactNode
}

function doesTextOverflow(textNode: HTMLDivElement) {
  const firstChild = textNode?.firstChild as HTMLElement;
  const firstGrandchild = firstChild?.firstChild as HTMLElement;
  const labelOverflows = textNode?.scrollWidth > textNode?.clientWidth;
  const firstChildOverflows = firstChild?.scrollWidth > firstChild?.clientWidth;
  const firstGrandchildOverflows = firstGrandchild?.scrollWidth > firstGrandchild?.clientWidth;
  return labelOverflows || firstChildOverflows || firstGrandchildOverflows;
}

const TooltippedOnOverflow: FunctionComponent<Props> = function TooltippedOnOverflow(props) {
  const [needsTooltip, setNeedsTooltip] = useState(false);
  const [open, toggleOpen] = useSwitch(false);
  const labelRef = useRef(null);
  const setLabelRef = useCallback((label) => {
    labelRef.current = label;
    setNeedsTooltip(doesTextOverflow(label));
  }, []);
  const onLabelResized = useCallback(event => {
    const label = event.target;

    setNeedsTooltip(doesTextOverflow(label));
  }, []);
  useDebouncedResizer(labelRef, onLabelResized);

  const [setEnterTimeout, clearEnterTimeout] = useTimeout(toggleOpen.on, props.enterDelay);
  const [setLeaveTimeout, clearLeaveTimeout] = useTimeout(toggleOpen.off, props.leaveDelay);
  const handleMouseEnter = useCallback(() => {
    clearLeaveTimeout();
    setEnterTimeout();
  }, [setEnterTimeout, clearLeaveTimeout]);
  const handleMouseLeave = useCallback(() => {
    clearEnterTimeout();
    setLeaveTimeout();
  }, [setLeaveTimeout, clearEnterTimeout]);

  const className = classnames(props.className, "TooltippedOnOverflow");
  let child: React.ReactElement;
  if (typeof props.children === "string") {
    child = <div className={className} id={props.id} ref={setLabelRef}>{props.children}</div>;
  } else if (React.isValidElement(props.children)) {
    child = React.cloneElement(props.children, {
      // @ts-ignore
      className,
      id: props.id,
      ref: setLabelRef,
    });
  } else {
    return null;
  }
  if (needsTooltip) {
    return <Tooltipable
      maxWidth="none"
      enterDelay={props.enterDelay}
      enterTouchDelay={props.enterTouchDelay}
      interactive={props.interactive}
      leaveDelay={props.leaveDelay}
      leaveTouchDelay={props.leaveTouchDelay}
      placement={props.placement}
      PopperProps={props.PopperProps}
      offsetX={props.offsetX}
      offsetY={props.offsetY}
      title={props.children}
      TransitionComponent={props.TransitionComponent}
      TransitionProps={props.TransitionProps}
      open={open}
    >
      {React.cloneElement(child, {
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave
      })}
    </Tooltipable>;
  } else {
    return child;
  }
}

export default React.memo(TooltippedOnOverflow);
