import { createSelector } from "reselect";

import getScanDate from "../../scan_dataset_getters/get_scan_date";
import getSelectedBuiltElements from "./get_selected_built_elements";
import PlannedBuildingElement from "../../../../models/domain/planned_building_element";

const empty: PlannedBuildingElement[] = [];

const getSelectedDeviations = createSelector([
  getScanDate,
  getSelectedBuiltElements
], (scanDate, selectedBuiltElements) => {
  if (selectedBuiltElements?.length) {
    const selectedDeviations = selectedBuiltElements.filter(element => {
      return PlannedBuildingElement.isDeviated(element, scanDate);
    });

    if (selectedDeviations.length) {
      return selectedDeviations;
    } else {
      return empty;
    }
  } else {
    return empty;
  }
});

export default getSelectedDeviations;
