import { Action } from "redux";

export type VerifiedElementsHiddenEvent = Action<typeof VERIFIED_ELEMENTS_HIDDEN>

const verifiedElementsHidden = (): VerifiedElementsHiddenEvent => ({
  type: VERIFIED_ELEMENTS_HIDDEN,
});

export const VERIFIED_ELEMENTS_HIDDEN = "verified_elements_hidden";

export default verifiedElementsHidden;