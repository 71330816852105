import { AvvirEvent } from "type_aliases";

export type PushToProcoreSucceededEvent = AvvirEvent<typeof PUSH_TO_PROCORE_SUCCEEDED, {
  procoreProjectId: number,
  procoreFileId: number
}>

const pushToProcoreSucceeded = (procoreProjectId: number, procoreFileId: number): PushToProcoreSucceededEvent => ({
  type: PUSH_TO_PROCORE_SUCCEEDED,
  payload: {
    procoreProjectId,
    procoreFileId
  }
});

export const PUSH_TO_PROCORE_SUCCEEDED = "push_to_procore_succeeded";

export default pushToProcoreSucceeded;
