import { AvvirEvent } from "type_aliases";

export type AnnotationHoveredEvent = AvvirEvent<typeof ANNOTATION_HOVERED, string>

const annotationHovered = (globalId: string): AnnotationHoveredEvent => ({
  type: ANNOTATION_HOVERED,
  payload: globalId
});

export const ANNOTATION_HOVERED = "annotation_hovered";

export default annotationHovered;
