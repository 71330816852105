import { createSelector } from "reselect";

import getFloorId from "../floor_getters/get_floor_id";
import getPlannedBuildingElementsByFirebaseFloorId from "./get_planned_building_elements_by_firebase_floor_id";
import PlannedBuildingElement from "../../../models/domain/planned_building_element";

const empty: { [globalId: string]: PlannedBuildingElement } = {};

const getPlannedElementsByGlobalId = createSelector([
  getPlannedBuildingElementsByFirebaseFloorId,
  getFloorId
], (plannedBuildingElementsByFirebaseFloorId, floorId) => plannedBuildingElementsByFirebaseFloorId[floorId]?.byGlobalId || empty);

export default getPlannedElementsByGlobalId;
