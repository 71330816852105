import moment from "moment";
import Avvir from "avvir";

import gcpBearerTokenLoaded, { GcpBearerTokenLoadedEvent } from "../events/loaded/gcp_bearer_token_loaded";
import getGcpBearerToken from "../services/getters/user_getters/get_gcp_bearer_token";
import getOrganizationId from "../services/getters/organization_getters/get_organization_id";
import getUser from "../services/getters/base_getters/get_user";

import type { ApiFailureEvent } from "../events/notifications/failures/api_failure";
import type { AvvirThunk } from "./make_eventful_action";

const refreshGcpToken = (projectId: string): AvvirThunk<Promise<{ accessToken: string, expireTime: Date, secondsUntilTokenExpires: number, organizationId: string }>, ApiFailureEvent | GcpBearerTokenLoadedEvent> => {
  return (dispatch, getState) => {
    const user = getUser(getState(), {});
    const token = getGcpBearerToken(getState(), {});
    if (token && moment(token.expireTime).isAfter(moment().add(30, "minutes"))) {
      const secondsUntilTokenExpires = moment(token.expireTime).diff(moment(), "seconds");
      return Promise.resolve({ ...token, secondsUntilTokenExpires });
    } else {
      return Avvir.api.other.getGcpBearerToken({ projectId }, user).then((gcpApiAccessToken) => {
        const expireTime = moment(gcpApiAccessToken.expireTime); // yyyy-MM-dd'T'HH:mm:ss
        const secondsUntilTokenExpires = expireTime.diff(moment(), "seconds");
        const organizationId = getOrganizationId(getState(), { projectId });
        dispatch(gcpBearerTokenLoaded({ ...gcpApiAccessToken, organizationId }));
        const token = getGcpBearerToken(getState(), {});
        return { ...token, accessToken: gcpApiAccessToken.accessToken, secondsUntilTokenExpires };
      });
    }
  };
};

export default refreshGcpToken;
