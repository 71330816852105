import { AvvirRoutingEvent } from "type_aliases";

export type ToIntegrationPageEvent = AvvirRoutingEvent<typeof TO_INTEGRATION_PAGE, { projectId: string, type: string }>

const toIntegrationPage = (type: string, projectId: string): ToIntegrationPageEvent => ({
  type: TO_INTEGRATION_PAGE,
  payload: { projectId, type }
});

export const TO_INTEGRATION_PAGE = "to_integration_page";

export default toIntegrationPage;
