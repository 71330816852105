import { AvvirRoutingEvent } from "type_aliases";

export type ToSuperadminScanDatasetEvent = AvvirRoutingEvent<typeof TO_SUPERADMIN_SCAN_DATASET, {
  organizationId: string,
  projectId: string,
  floorId: string,
  scanDatasetId: string
}>

const toSuperadminScanDataset = (organizationId: string, projectId: string, floorId: string, scanDatasetId: string): ToSuperadminScanDatasetEvent => ({
  type: TO_SUPERADMIN_SCAN_DATASET,
  payload: {
    organizationId,
    projectId,
    floorId,
    scanDatasetId
  }
});

export const TO_SUPERADMIN_SCAN_DATASET = "to_superadmin_scan_dataset";

export default toSuperadminScanDataset;
