import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ToSuperadminScansTableEvent = AvvirPayloadlessRoutingEvent<typeof TO_SUPERADMIN_SCANS_TABLE>

const toSuperadminScansTable = (): ToSuperadminScansTableEvent => ({
  type: TO_SUPERADMIN_SCANS_TABLE,
});

export const TO_SUPERADMIN_SCANS_TABLE = "to_superadmin_scans_table";

export default toSuperadminScansTable;
