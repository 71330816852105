import PlannedBuildingElement from "../../models/domain/planned_building_element";
import { ElementEditedEvent } from "../../models/domain/element_edited_event";

// TODO: Rename this to reflect that it only occurs when adjusting deviations
const elementsEdited = (floorId: string, previousElementStates: PlannedBuildingElement[], nextElementStates: PlannedBuildingElement[]): ElementEditedEvent => ({
  ...new ElementEditedEvent(ELEMENTS_EDITED, floorId, previousElementStates, nextElementStates)
});

export const ELEMENTS_EDITED = "elements_edited";

export default elementsEdited;
