import { createSelector } from "reselect";

import getDefaultFloorId from "../project_getters/get_default_floor_id";
import getFloor from "./get_floor";
import getFloorsByFirebaseId from "./get_floors_by_firebase_id";

const getDefaultScanDatasetId = createSelector([
  getFloor,
  getDefaultFloorId,
  getFloorsByFirebaseId
], (floor, defaultFloorId, floors) => {
  if (floor) {
    return floor.defaultFirebaseScanDatasetId || null;
  } else if (floors[defaultFloorId]) {
    return floors[defaultFloorId].defaultFirebaseScanDatasetId || null;
  } else {
    return null;
  }
});

export default getDefaultScanDatasetId;
