import { useCallback, useEffect, useRef } from "react";

export default function useTimeout(callback: () => void, delay: number): [() => void, () => void] {
  const timeoutRef = useRef<number>(null);
  const callCallbackAfterDelay = useCallback(() => {
    // @ts-ignore
    timeoutRef.current = setTimeout(callback, delay);
  }, [callback, delay]);
  const clearCallbackTimeout = useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }, []);
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return [callCallbackAfterDelay, clearCallbackTimeout];
}
