import Avvir, { ResponseError, UserRole } from "avvir";

import apiFailure from "../../../events/notifications/failures/api_failure";
import getProject from "../../../services/getters/project_getters/get_project";
import projectAccessDenied, { ProjectAccessDeniedEvent } from "../../../events/notifications/failures/project_access_denied";
import projectLoaded, { ProjectLoadedEvent } from "../../../events/loaded/projects/project_loaded";
import projectNotFound, { ProjectNotFoundEvent } from "../../../events/notifications/failures/project_not_found";
import refreshGcpToken from "../../refresh_gcp_token";
import { AvvirAsyncThunk } from "../../make_eventful_action";

type DispatchedEvents = ProjectNotFoundEvent | ProjectLoadedEvent | ProjectAccessDeniedEvent

const loadProject = (projectId: string): AvvirAsyncThunk<typeof getProject, DispatchedEvents> => {
  return (dispatch, getState) => {
    const user = getState().user;
    return Avvir.api.projects.getProject(projectId, user).then((project) => {
      dispatch(projectLoaded(project));
      if (project.archivedAt != null && user.gatewayUser?.role === UserRole.USER) {
        dispatch(projectAccessDenied(user));
      }
      return dispatch(refreshGcpToken(projectId)).then(() => {
        return getProject(getState(), { projectId });
      });
    }).catch((err) => {
      if (err instanceof ResponseError && err.status === 404) {
        dispatch(projectNotFound());
      } else {
        dispatch(apiFailure({ message: "Error loading project from API. Contact prod support." }));

        if (err == null) {
          console.error("ERROR loading project from API (null error)");
        } else {
          if (err["status"] != null) {
            console.error(`ERROR loading project from API with status code ${err.status}`);
          } else {
            console.error("ERROR loading project from API");
          }
          console.error(err);
          throw err;
        }
      }
    });
  };
};

export default loadProject;
