import Project from "../../../models/domain/project";
import { AvvirEvent } from "type_aliases";

export type ProjectUpdatedEvent = AvvirEvent<typeof PROJECT_UPDATED, { project: Partial<Project> }>

const projectUpdated = (project: Partial<Project>): ProjectUpdatedEvent => ({
  type: PROJECT_UPDATED,
  payload: {
    project
  }
});

export const PROJECT_UPDATED = "project_updated";

export default projectUpdated;
