import { actionToPath, getOptions, history } from "redux-first-router";
import type { Action, ReceivedAction, RoutesMap } from "redux-first-router";

export type To = string | string[] | ReceivedAction | Action
const toUrl = (to: To | null | undefined, routesMap: RoutesMap): string => {
  const { querySerializer } = getOptions();

  if (to && typeof to === "string") {
    return history().createHref({
      pathname: to
    });
  } else if (Array.isArray(to)) {
    const path = `/${to.join("/")}`;
    return history().createHref({
      pathname: path
    });
  } else if (typeof to === "object") {
    const action = to;

    try {
      const path = actionToPath(action as ReceivedAction, routesMap, querySerializer);
      return history().createHref({
        pathname: path
      });
    } catch (e) {
      if (process.env.NODE_ENV === "development") {
        console.warn(
          "[redux-first-router-link] could not create path from action:",
          action,
          "For reference, here are your current routes:",
          routesMap
        );
      }

      return "#";
    }
  }

  if (process.env.NODE_ENV === "development") {
    console.warn(
      "[redux-first-router-link] `to` prop must be a string, array or action object. You provided:",
      to
    );
  }

  return "#";
};

export default toUrl;
