import { createSelector } from "reselect";
import getProjectsByFirebaseId from "./get_projects_by_firebase_id";
import getProjectId from "./get_project_id";

const getProject = createSelector([
  getProjectsByFirebaseId,
  getProjectId
], (projectsByFirebaseId, projectId) => projectsByFirebaseId[projectId] || null);

export default getProject;
