import _ from "underscore";
import { RoutesMap } from "redux-first-router";

import { TO_ACCEPT_INVITATION_PAGE } from "./events/routing/to_accept_invitation_page";
import { TO_CONFIRM_ACCOUNT_PAGE } from "./events/routing/to_confirm_account_page";
import { TO_INTEGRATION_PAGE } from "./events/routing/to_integration_page";
import { TO_ROOT_PAGE } from "./services/routing_events";
import { TO_PASSWORD_RESET_CONFIRMATION_PAGE } from "./events/routing/to_password_reset_confirmation_page";
import { TO_PASSWORD_RESET_EMAIL_FORM } from "./events/routing/to_password_reset_email_form";
import { TO_PASSWORD_RESET_PAGE } from "./events/routing/to_password_reset_page";
import { TO_PHOTOS } from "./events/routing/to_photos_page";
import { TO_PHOTOS_FLOOR } from "./events/routing/to_photos_floor";
import { TO_PROJECT } from "./events/routing/to_project";
import { TO_PROJECT_INTEGRATIONS_PAGE } from "./events/routing/to_project_integrations_page";
import { TO_PROJECT_PORTFOLIO_PAGE } from "./events/routing/to_project_portfolio_page";
import { TO_PROGRESS_4D_REPORT } from "./events/routing/to_progress_4d_report";
import { TO_PROGRESS_4D_REPORT_FOR_AREA } from "./events/routing/to_progress_4d_report_for_area";
import { TO_CUSTOM_REPORT } from "./events/routing/to_custom_report";
import { TO_PROJECT_SETTINGS_PAGE } from "./events/routing/to_project_settings_page";
import { TO_PROJECT_SUMMARY_PAGE } from "./events/routing/to_project_summary_page";
import { TO_PROJECT_TEAM_PAGE } from "./events/routing/to_project_team_page";
import { TO_PROJECT_UPLOADS_PAGE } from "./events/routing/to_project_uploads_page";
import { TO_SEARCH_SET_CREATOR_PAGE } from "./events/routing/to_search_set_creator_page";
import { TO_SUPERADMIN_FLOOR } from "./events/routing/superadmin/to_superadmin_floor";
import { TO_SUPERADMIN_NEW_ORGANIZATION } from "./events/routing/superadmin/to_superadmin_new_organization";
import { TO_SUPERADMIN_NEW_PROJECT } from "./events/routing/superadmin/to_superadmin_new_project";
import { TO_SUPERADMIN_ORGANIZATION } from "./events/routing/superadmin/to_superadmin_organization";
import { TO_SUPERADMIN_ORGANIZATION_USERS } from "./events/routing/superadmin/to_superadmin_organization_users";
import { TO_SUPERADMIN_ORGANIZATIONS } from "./events/routing/superadmin/to_superadmin_organizations";
import { TO_SUPERADMIN_PROJECT } from "./events/routing/superadmin/to_superadmin_project";
import { TO_SUPERADMIN_SCAN_DATASET } from "./events/routing/superadmin/to_superadmin_scan_datasets";
import { TO_SUPERADMIN_SCANS_TABLE } from "./events/routing/superadmin/to_superadmin_scans_table";
import { TO_SUPERADMIN_USER } from "./events/routing/superadmin/to_superadmin_user";
import { TO_VIEWER_FLOOR } from "./events/routing/to_viewer_floor";
import { TO_VIEWER_SCAN_DATASET } from "./events/routing/to_viewer_scan_dataset";
import { TO_WBS_WITH_FLOOR } from "./events/routing/to_wbs_with_floor";
import { TO_WBS_WITH_PROJECT } from "./events/routing/to_wbs_with_project";
import { TO_WBS_WITH_WBS_CODE } from "./events/routing/to_wbs_with_wbs_code";
import { TO_INSPECT_REPORT } from "./events/routing/to_inspect_report";
import { TO_INSPECT_REPORTS } from "./events/routing/to_inspect_reports";
import { TO_CUSTOM_REPORTS } from "./events/routing/to_custom_reports";
import PageNames from "./models/domain/enums/page_names";
import { TO_SUMMARY_PROGRESS_4D_REPORT } from "./events/routing/to_summary_progress_4d_report";
import { TO_METRICS_PAGE } from "./events/routing/to_metrics_page";
import { TO_HX_AUTH_LOGIN_PAGE } from "./events/routing/to_hx_auth_login_page";
import { TO_LOGIN_PAGE } from "./events/routing/to_login_page";
import { TO_PHOTO_PAGE } from "./events/routing/to_photo_page";

// WARNING: update static.json for each new route category
const routes: RoutesMap = {
  [TO_PROJECT]: "/projects/:projectId",
  [TO_VIEWER_FLOOR]: "/projects/:projectId/floors/:floorId",
  [TO_VIEWER_SCAN_DATASET]: "/projects/:projectId/floors/:floorId/scan-datasets/:scanDatasetId",
  [TO_PROGRESS_4D_REPORT]: "/projects/:projectId/reports",
  [TO_PROGRESS_4D_REPORT_FOR_AREA]: "/projects/:projectId/areas/:floorId/reports",
  [TO_SUMMARY_PROGRESS_4D_REPORT]: {
    path: "/projects/:projectId/:pageName(summary)/progress/(areas)?/:floorId?",
    toPath(path: string, key?: string): string {
      if (key === "pageName") {
        return "summary"
      }
      return path;
    },
    fromPath(path: string, key?: string): string {
      if (key === "pageName") {
        return PageNames.PROJECT_SUMMARY
      }
      return path;
    }
  },
  [TO_CUSTOM_REPORT]: {
    path: "/projects/:projectId/:pageName/custom-reports/:projectReportId",
    toPath(param: string, key?: string) {
      switch (key) {
        case "pageName": {
          return param === "PROJECT_REPORTS" ? "reports" : "summary"
        }
        default: {
          return param
        }
      }
    },
    fromPath(param: string, key?: string) {
      switch (key) {
        case "pageName": {
          return param === "reports" ? PageNames.PROJECT_REPORTS : PageNames.PROJECT_SUMMARY
        }
        default: {
          return param
        }
      }
    },
  },
  [TO_CUSTOM_REPORTS]: {
    path: "/projects/:projectId/:pageName/custom-reports",
    toPath(param: string, key?: string) {
      switch (key) {
        case "pageName": {
          return param === "PROJECT_REPORTS" ? "reports" : "summary"
        }
        default: {
          return param
        }
      }
    },
    fromPath(param: string, key?: string) {
      switch (key) {
        case "pageName": {
          return param === "reports" ? PageNames.PROJECT_REPORTS : PageNames.PROJECT_SUMMARY
        }
        default: {
          return param
        }
      }
    },
  },
  [TO_PROJECT_UPLOADS_PAGE]: {
    path: "/projects/:projectId/uploads",
    confirmLeave: confirmChangePage
  },
  [TO_SUPERADMIN_NEW_ORGANIZATION]: "/admin/new-organization",
  [TO_SUPERADMIN_SCANS_TABLE]: "/admin/organizations/realitycapturedataset-stats",
  [TO_SUPERADMIN_ORGANIZATION]: "/admin/organizations/:organizationId",
  [TO_SUPERADMIN_ORGANIZATION_USERS]: "/admin/organizations/:organizationId/users",
  [TO_SUPERADMIN_ORGANIZATIONS]: "/admin/organizations",
  [TO_SUPERADMIN_FLOOR]: {
    path: "/admin/organizations/:organizationId/projects/:projectId/floors/:floorId",
    confirmLeave: confirmChangePage
  },
  [TO_SUPERADMIN_SCAN_DATASET]: {
    path: "/admin/organizations/:organizationId/projects/:projectId/floors/:floorId/scan-datasets/:scanDatasetId",
    confirmLeave: confirmChangePage
  },
  [TO_SUPERADMIN_NEW_PROJECT]: "/admin/organizations/:organizationId/new-project",
  [TO_SUPERADMIN_PROJECT]: {
    path: "/admin/organizations/:organizationId/projects/:projectId",
    confirmLeave: confirmChangePage
  },
  [TO_ACCEPT_INVITATION_PAGE]: "/accept-invite",
  [TO_PHOTOS]: "/projects/:projectId/photos",
  [TO_PHOTOS_FLOOR]: "/projects/:projectId/floors/:floorId/photos",
  [TO_LOGIN_PAGE]: "/login",
  [TO_HX_AUTH_LOGIN_PAGE]: "/sign-in/sso",
  [TO_PASSWORD_RESET_EMAIL_FORM]: "/password-reset-email-form",
  [TO_PASSWORD_RESET_PAGE]: "/password-reset",
  [TO_PASSWORD_RESET_CONFIRMATION_PAGE]: "/password-reset-confirmation",
  to_procore_authenticated_page: "/integrations/procore/access-token",
  to_bimtrack_authenticated_page: "/authorize",
  [TO_ROOT_PAGE]: "/",
  [TO_SEARCH_SET_CREATOR_PAGE]: "/admin/search-set-creator",
  [TO_CONFIRM_ACCOUNT_PAGE]: "/confirm-account",
  [TO_PROJECT_TEAM_PAGE]: "/projects/:projectId/team",
  [TO_PROJECT_SETTINGS_PAGE]: "/projects/:projectId/settings",
  [TO_WBS_WITH_FLOOR]: "/projects/:projectId/floors/:floorId/work-breakdown-structure",
  [TO_WBS_WITH_PROJECT]: "/projects/:projectId/work-breakdown-structure",
  [TO_WBS_WITH_WBS_CODE]: "/projects/:projectId/work-breakdown-structure/:wbsCode",
  [TO_PROJECT_INTEGRATIONS_PAGE]: "/projects/:projectId/integrations",
  [TO_INTEGRATION_PAGE]: "/projects/:projectId/integrations/drone-deploy",
  [TO_PHOTO_PAGE]: "/projects/:projectId/integrations/photos",
  [TO_PROJECT_SUMMARY_PAGE]: "/projects/:projectId/summary",
  [TO_PROJECT_PORTFOLIO_PAGE]: "/portfolio",
  [TO_SUPERADMIN_USER]: "/admin/users/:userId",
  [TO_INSPECT_REPORTS]: "/projects/:projectId/summary/inspect-reports",
  [TO_INSPECT_REPORT]: "/projects/:projectId/summary/inspect-reports/:inspectReportId",
  [TO_METRICS_PAGE]: "/projects/:projectId/summary/metrics",
};

function addDeprecatedOrganizationRoutes(routes: RoutesMap) {
  _(routes).forEach((routeConfig, name) => {
    if (typeof routeConfig === "string") {
      if (routeConfig.includes("organization")) {
        routes[name + "_deprecated"] = routeConfig.replace("organization", "client-account");
      }
    } else {
      if (routeConfig.path) {
        if (routeConfig.path.includes("organization")) {
          routes[name + "_deprecated"] = {
            ...routeConfig,
            path: routeConfig.path.replace("organization", "client-account")
          };
        }
      }
    }
  });
  return routes;
}

export default addDeprecatedOrganizationRoutes(routes);

function confirmChangePage(state, action) {
  for (let fileName in state.runningProcesses.uploads) {
    if (state.runningProcesses.uploads.hasOwnProperty(fileName)) {
      const status = state.runningProcesses.uploads[fileName];
      if (status.isUploading) {
        return "Are you sure you want to leave without saving your changes?";
      }
    }
  }
}
