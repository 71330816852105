import type { ApiProjectArea } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ProjectAreaCreatedEvent = AvvirEvent<typeof PROJECT_AREA_CREATED, { projectId: string, projectArea: ApiProjectArea }>

const projectAreaCreated = (projectId: string, projectArea: ApiProjectArea): ProjectAreaCreatedEvent => ({
  type: PROJECT_AREA_CREATED,
  payload: {
    projectId,
    projectArea
  }
});

export const PROJECT_AREA_CREATED = "project_area_created";

export default projectAreaCreated;
