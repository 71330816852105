import withPromise from "../../utilities/general/with_promise";
import { EventWithTarget } from "type_aliases";

const loadImage = (imageUrl: string): Promise<EventWithTarget<HTMLImageElement>> => {
  const [imageLoaded, imageLoadFailed, loadingImage] = withPromise<EventWithTarget<HTMLImageElement>>();
  const image = new Image();
  image.onload = imageLoaded;
  image.onerror = imageLoadFailed;
  image.crossOrigin = "Anonymous";

  image.src = imageUrl;
  return loadingImage;
};

export default loadImage;
