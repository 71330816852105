import camerasController from "./utilities/threejs_utilities/cameras_controller";

export function getPhotoViewerCamera() {
  if (camerasController.photoViewerControls) {
    return camerasController.photoViewerControls.camera;
  }

  return null;
}

export default getPhotoViewerCamera;
