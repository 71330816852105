import { AvvirRoutingEvent } from "type_aliases";

export type ToSuperadminOrganizationUsersEvent = AvvirRoutingEvent<typeof TO_SUPERADMIN_ORGANIZATION_USERS, {organizationId: string }>

const toSuperadminOrganizationUsers = (organizationId: string): ToSuperadminOrganizationUsersEvent => ({
  type: TO_SUPERADMIN_ORGANIZATION_USERS,
  payload: { organizationId: organizationId }
});

export const TO_SUPERADMIN_ORGANIZATION_USERS = "to_superadmin_organization_users";

export default toSuperadminOrganizationUsers;
