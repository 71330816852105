import { Box2, Matrix3, Vector2, Vector3 } from "three";

import CanvasHelper, { CanvasFontStyle } from "./canvas_helper";
import DateConverter from "../converters/date_converter";
import globalStyles from "../../style/global_styles";
import LegendHelper from "./legend_helper";
import MasterformatProgressConverter from "../converters/masterformat_progress_converter";
import PlannedBuildingElement from "../../models/domain/planned_building_element";
import UniformatConverter from "../converters/uniformat_converter";
import { calculatePhotoLocationCoords } from "../../components/viewer/panels/minimap/photos_layer/photo_sprite";
import { DeviationText, deviationToText } from "../converters/element_deviation_formatter";

import type Floor from "../../models/domain/floor";
import type Organization from "../../models/domain/organization";
import type Project from "../../models/domain/project";
import type ScanDataset from "../../models/domain/scan_dataset";

const formatter = DateConverter.getDateFormatter("MMM D, YYYY");

export type CanvasTextLine = {
  isTitle: boolean
  text: string
  fontStyle: CanvasFontStyle
  metrics: TextMetrics
}

export type CanvasTextBlock = {
  title: CanvasTextLine[],
  content: CanvasTextLine[]
}

export default class ScreenshotHelper extends CanvasHelper {
  boundingBox: Vector2 = new Vector2();
  viewerDimensions: {
    width: number,
    height: number
  } = { height: 0, width: 0 };

  constructor(canvas?: HTMLCanvasElement, makeSquare?: boolean) {
    super(canvas);
    this.legendHelper = new LegendHelper();

    if (makeSquare) {
      this.makeSquare = makeSquare;
      this.canvas.width = Math.max(this.canvas.width, this.canvas.height);
      this.canvas.height = Math.max(this.canvas.width, this.canvas.height);
    }
  }

  setCameraPosition(position: Vector3) {
    this.originalCameraPosition = position;
    if (this.minimapTransform) {
      this.cameraPosition = calculatePhotoLocationCoords(position,
        this.refactorFlagEnabled ? this.minimapBoundingBoxForReport : this.minimapBoundingBox,
        this.minimapTransform);
    }
  };

  hasSelectedElements() {
    return this.selectedElements != null && this.selectedElements.length > 0;
  }

  setViewerImage(image: HTMLImageElement | HTMLCanvasElement) {
    this.viewerImage = image;
    let { width, height } = this.viewerImage;
    if (this.viewerImage.tagName === "canvas") {
      width = this.viewerImage.clientWidth;
      height = this.viewerImage.clientHeight;
    }
    this.viewerDimensions.width = width;
    this.viewerDimensions.height = height;
  };

  /**
   * Draws the image in the given corner of the canvas, with a padding of 1/3 of the image's smallest dimension.
   *
   * Scales the image down if it is taller or wider than the canvas (preserves aspect ratio.)
   * @param image the image to draw
   */
  drawAvvirLogo(image: HTMLImageElement) {
    const aspectRatio = image.width / image.height;
    const targetHeight = this.viewerDimensions.height * 0.08;  // 10% of viewer image
    const targetWidth = targetHeight * aspectRatio;

    const logoPadding = globalStyles.space;
    const logoBottomRight = new Vector2(
      this.padding + this.viewerDimensions.width - logoPadding,
      this.padding + this.viewerDimensions.height - logoPadding
    );
    const logoTopLeft = new Vector2(
      logoBottomRight.x - targetWidth,
      logoBottomRight.y - targetHeight
    );

    const logoBox = new Box2(logoTopLeft, logoBottomRight);
    this.drawImageInBox(image, logoBox);
  }

  addBlockOfTextToScreenshot(header: string, content: string | string[]) {
    this.context.save();
    let textBlock: CanvasTextBlock = {} as CanvasTextBlock;
    let mapText = (isTitle: boolean, fontStyle: CanvasFontStyle, wrappedLine: string) => {
      const metrics = this.context.measureText(wrappedLine);
      const line: CanvasTextLine = {
        isTitle,
        text: wrappedLine,
        fontStyle,
        metrics
      };
      return line;
    };
    this.setTextStyle(this.fonts.title);
    const headerLines = this.wrapText(header, this.textBlockSize);
    this.setTextStyle(this.fonts.content);
    const contentLines = Array.isArray(content) ? content : this.wrapText(content, this.textBlockSize);
    textBlock.title = headerLines.map(mapText.bind(null, true, this.fonts.title));
    textBlock.content = contentLines.map(mapText.bind(null, false, this.fonts.content));
    this.textBlocks.push(textBlock);
    this.context.restore();
  }

  addLineToScreenshot(text: string, title: boolean) {
    this.context.save();

    const fontStyle = title ? this.titleFontStyle : this.textFontStyle;
    this.setTextStyle(fontStyle);

    const wrappedLines = this.wrapText(text, this.minimapSize);
    wrappedLines.forEach((wrappedLine) => {
      const metrics = this.context.measureText(wrappedLine);
      const line: CanvasTextLine = {
        isTitle: title,
        text: wrappedLine,
        fontStyle,
        metrics
      };
      this.textLines.push(line);
    });

    this.context.restore();
  };

  get minimapAspectRatio() {
    const { width, height } = this.minimapImage;
    return height / width;
  }

  get inverseMinimapAspectRatio() {
    const { width, height } = this.minimapImage;
    return width / height;
  }

  get minimapBoundingBox() {
    const aspectRatio = this.minimapAspectRatio;
    return {
      width: this.minimapSize,
      height: this.minimapSize * aspectRatio
    };
  }

  get minimapBoundingBoxForReport() {
    const aspectRatio = this.inverseMinimapAspectRatio;
    return {
      width: this.minimapSize * aspectRatio,
      height: this.minimapSize
    };
  }

  get minimapBoundingBoxForInspectReport() {
    return this.minimapBoundingBox;
  }

  drawCameraIndicator(minimapStart: Vector2, radius = 10, circleWidth = 4) {
    if (this.cameraPosition == null) {
      this.log("cameraPosition null, not drawing camera indicator.");
      return;
    }

    const cameraX = minimapStart.x + this.cameraPosition.x;
    const cameraY = minimapStart.y + this.cameraPosition.y;
    this.drawCircle(cameraX, cameraY, radius, null, "#f8a520", circleWidth);
    this.log(`cameraIndicator.x = ${minimapStart.x} + ${this.cameraPosition.x} = ${cameraX}`);
    this.log(`cameraIndicator.y = ${minimapStart.y} + ${this.cameraPosition.y} = ${cameraY}`);
  }

  drawMinimap(isForReport: boolean = this.refactorFlagEnabled) {
    const { width, height } = isForReport ? this.minimapBoundingBoxForReport : this.minimapBoundingBox;
    const start = new Vector2(this.canvas.width - this.padding - width, this.padding);
    const end = new Vector2(start.x + width, start.y + height);
    this.drawImageInBox(this.minimapImage, new Box2(start, end));
    this.drawCameraIndicator(start);
  }

  addScanDatasetText() {
    if (this.scanDataset) {
      if (this.scanDataset.name !== "") {
        this.addLineToScreenshot(`Reality Capture Dataset Name`, true);
        this.addLineToScreenshot(this.scanDataset.name, false);
      }
      const date = formatter.formatUTC(this.scanDataset.scanDate);
      this.addLineToScreenshot(`Reality Capture Date`, true);
      this.addLineToScreenshot(date, false);
    }
  }

  addOrganizationText() {
    if (this.organization) {
      this.addLineToScreenshot(`Customer`, true);
      this.addLineToScreenshot(this.organization.name, false);
    }
  }

  addProjectText() {
    if (this.project) {
      this.addLineToScreenshot(`Project`, true);
      this.addLineToScreenshot(this.project.name, false);
    }
  }

  addFloorText() {
    if (this.floor) {
      this.addLineToScreenshot(`Floor/Area`, true);
      this.addLineToScreenshot(this.floor.floorNumber, false);
    }
  }

  addDeviationText() {
    if (this.selectedElements) {
      if (this.selectedElements.length > 1) {
        // sort the deviations in descending order of deviation magnitude
        const deviationsByMagnitude = this.selectedElements.slice();
        deviationsByMagnitude.sort((a, b) => {
          const deviationB = PlannedBuildingElement.getDeviation(b, this.scanDataset.scanDate)?.deviationMeters || 0;
          const deviationA = PlannedBuildingElement.getDeviation(a, this.scanDataset.scanDate)?.deviationMeters || 0;
          return deviationB - deviationA;
        });
        const elementsToRender = deviationsByMagnitude.slice(0, 3);
        if (elementsToRender.every(element => PlannedBuildingElement.isDeviated(element, this.scanDataset.scanDate))) {
          this.addLineToScreenshot(`Deviated Elements`, true);
        } else {
          this.addLineToScreenshot(`Elements`, true);
        }
        // add the 3 largest deviations from the selected elements to the screenshot
        elementsToRender.forEach((selectedElement) => {
          this.addElementInfoText(selectedElement);
        });
      } else if (this.selectedElements.length === 1) {
        if (PlannedBuildingElement.isDeviated(this.selectedElements[0], this.scanDataset.scanDate)) {
          this.addLineToScreenshot(`Deviated Element`, true);
        } else {
          this.addLineToScreenshot(`Element`, true);
        }
        this.addElementInfoText(this.selectedElements[0]);
      }
    }
  }

  addDeviationBlocks() {
    if (this.selectedElements) {
      const trades = new Set<string>();
      if (this.selectedElements.length > 0) {
        this.selectedElements.forEach((element) => {
          let trade = this.getElementTrade(element);
          if (trade) {
            trades.add(this.getElementTrade(element));
          }
        });
        this.addBlockOfTextToScreenshot("Trade", [...trades].join(", "));
        const deviatedElements = this.getTopDeviatedElements(3);
        if (deviatedElements.length) {
          this.addBlockOfTextToScreenshot("Deviation Magnitude", deviatedElements.map(element => this.getElementDeviationText(element)));
        }
      }
    }
  }

  getTopDeviatedElements(count: number = 3) {
    const deviationsByMagnitude = this.selectedElements
      .filter(element => PlannedBuildingElement.isDeviated(element, this.scanDataset.scanDate))
      .sort(
        (a, b) => {
          const deviationB = PlannedBuildingElement.getDeviation(b, this.scanDataset.scanDate)?.deviationMeters || 0;
          const deviationA = PlannedBuildingElement.getDeviation(a, this.scanDataset.scanDate)?.deviationMeters || 0;
          return deviationB - deviationA;
        });
    return deviationsByMagnitude.slice(0, count);
  }

  getElementTrade(element: PlannedBuildingElement) {
    if (element.masterformat) {
      const levelId = MasterformatProgressConverter.getLevelId(element.masterformat, 1, false);
      const fullLevelOneCode = MasterformatProgressConverter.getFullMasterformatCode(levelId, 1);
      const description = MasterformatProgressConverter.getDescription(fullLevelOneCode, 1);
      return description?.toLowerCase().replace(/(?<!\S)\S/ug, match => match.toUpperCase());
    } else if (element.uniformat) {
      let { level1, level2, level3, level4 } = UniformatConverter.toUniformatBreakdown(element.uniformat);
      return level4 || level3 || level2 || level1 || null;
    }
    return null;
  }

  addElementInfoText(selectedElement: PlannedBuildingElement) {
    const { isDeviation, deviationMagnitude, x, y, z, tradeName } = this.toDeviationText(selectedElement);

    this.addLineToScreenshot(`${selectedElement.name || selectedElement.navisworksGuid || selectedElement.globalId}${tradeName ? ` [${tradeName}]` : ""}`, false);

    if (isDeviation) {
      this.addLineToScreenshot(`  ${deviationMagnitude} (${x}, ${y}, ${z})`, false);
    } else {
      this.addLineToScreenshot(`  Built In-Place`, false);
    }
  }

  getElementDeviationText(selectedElement: PlannedBuildingElement) {
    const { deviationMagnitude, x, y, z, tradeName, isDeviation } = this.toDeviationText(selectedElement);
    const text = `${selectedElement.name || selectedElement.navisworksGuid || selectedElement.globalId}${tradeName ? ` [${tradeName}]` : ""}`;

    if (isDeviation) {
      return `${selectedElement.name || selectedElement.navisworksGuid || selectedElement.globalId}: ${deviationMagnitude} (X: ${x} | Y: ${y} | Z: ${z})`;
    }

    return text + ` Built In-Place`;
  }

  drawLegend() {
    this.legendHelper.drawLegendForScreenshot();
    this.drawImage(this.legendHelper.canvas, this.padding + globalStyles.space, this.padding + globalStyles.space);
  }

  drawViewerImage() {
    let { width, height } = this.viewerDimensions;
    this.drawImageInBox(this.viewerImage,
      new Box2(
        new Vector2(this.padding, this.padding),
        new Vector2(this.padding + width, this.padding + height)
      ));

    this.drawLegend();
  }

  drawViewerImageCropped() {
    let { width, height } = this.viewerDimensions;
    this.drawImageInCroppedBox(this.viewerImage,
      new Vector2(800, 450),
      new Box2(
        new Vector2(this.padding, this.padding),
        new Vector2(this.padding + width, this.padding + height)
      ));

    this.drawLegend();
  }

  /**
   * Draw text lines (or pretend to, see measureOnly below)
   * @param measureOnly Calculate total height of text lines without actually drawing
   */
  drawTextLines(measureOnly: boolean = false) {
    const topLeft = new Vector2(
      this.pad(this.viewerDimensions.width),
      this.pad(this.minimapBoundingBox.height)
    );

    let offset = new Vector2();
    this.textLines.forEach((line) => {
      // Add a bit of extra line spacing before each title
      if (line.isTitle) {
        offset.y += this.textSpacing;
      }
      if (!measureOnly) {
        this.writeText(
          line.text,
          topLeft.x + offset.x,
          topLeft.y + offset.y,
          "top",
          "start",
          line.fontStyle.color,
          line.fontStyle.size,
          line.fontStyle.name,
          line.fontStyle.weight
        );
      }
      offset.y += line.fontStyle.size + this.textSpacing;
    });

    if (!measureOnly) {
      this.textLines = [];
    }
    return offset.y;
  }

  drawTextBlocks(measureOnly: boolean = false) {
    const topLeft = new Vector2(
      this.padding,
      this.pad(this.minimapBoundingBoxForReport.height)
    );
    let maxBlockHeight = 0, height = 0;
    let offset = new Vector2();
    let writeText = (line: CanvasTextLine) => {
      if (!measureOnly) {
        this.writeText(
          line.text,
          topLeft.x + offset.x,
          topLeft.y + offset.y,
          "top",
          "start",
          line.fontStyle.color,
          line.fontStyle.size,
          line.fontStyle.name,
          line.fontStyle.weight
        );
      }
      offset = offset.setY(offset.y + line.fontStyle.size + this.textSpacing);
    };
    this.textBlocks.forEach((block) => {
      let initialY = offset.y;
      block.title.forEach(writeText);
      offset.setY(offset.y + 10);
      block.content.forEach(writeText);
      offset.setX(offset.x + this.textBlockSize + this.padding);
      maxBlockHeight = Math.max(offset.y, maxBlockHeight);
      offset.setY(initialY);
      height = Math.max(maxBlockHeight, height);
      if (offset.x >= this.textBlockSize * 4) {
        offset.setX(0);
        offset.setY(offset.y + maxBlockHeight + this.padding);
        maxBlockHeight = 0;
      }
    });
    return height;
  }

  recalculateCanvasSize(textHeight: number, isForReport: boolean = this.refactorFlagEnabled) {
    let { width, height } = isForReport ? this.minimapBoundingBoxForReport : this.minimapBoundingBox;
    this.canvas.width = this.pad(this.viewerDimensions.width, width);
    this.canvas.height = Math.max(
      this.pad(this.viewerDimensions.height),
      this.pad(height, textHeight)
    );
    this.log("recalculated canvas height", this.canvas.height);
  }

  renderScreenshot() {
    if (this.refactorFlagEnabled) {
      return this.renderScreenshotWithBlockText();
    }

    this.clear();

    this.addOrganizationText();
    this.addProjectText();
    this.addFloorText();
    this.addScanDatasetText();
    this.addDeviationText();

    // Measure, doesn't actually draw yet
    const textHeight = this.drawTextLines(true);

    this.recalculateCanvasSize(textHeight);
    this.drawViewerImage();
    this.drawMinimap();
    this.drawTextLines();
  }

  renderScreenshotWithBlockText() {
    this.clear();
    if (this.project) {
      this.addBlockOfTextToScreenshot("Project", this.project.name);
    }
    if (this.floor) {
      this.addBlockOfTextToScreenshot("Area", this.floor.floorNumber);
    }
    if (this.scanDataset) {
      this.addBlockOfTextToScreenshot("Capture Date", formatter.formatUTC(this.scanDataset.scanDate));
      this.addBlockOfTextToScreenshot("Capture Dataset Name", this.scanDataset.name || "-");
    }
    this.addDeviationBlocks();
    this.recalculateCanvasSize(this.drawTextBlocks(true), true);
    this.drawViewerImageCropped();
    this.drawMinimap(true);
    this.drawTextBlocks();
    this.textBlocks = [];
  }

  renderScreenshotForInspectReport() {
    this.clear();
    this.canvas.width = this.viewerDimensions.width;
    this.canvas.height = this.viewerDimensions.height;
    this.drawViewerImageCropped();
  }

  renderInspectReportMinimap(zoom?: boolean) {
    this.clear();
    const savedSize = this.minimapSize;
    const viewerWidth = this.viewerImage == null ? 1600 : this.viewerDimensions.width;
    this.minimapSize = Math.floor((viewerWidth - this.padding) / 2);
    const { width, height } = this.minimapBoundingBoxForInspectReport;
    this.canvas.width = width;
    this.canvas.height = height;
    this.recalculateCameraPosition(width, height);
    const start = new Vector2(0, 0);
    const end = new Vector2(width, height);
    const drawBox = new Box2(start, end);

    if (zoom) {
      this.drawZoomedImageInBox(this.minimapImage, drawBox, this.cameraPosition);
      this.drawCircle(width / 2, height / 2, width / 30, null, "#f8a520", width / 75);
    } else {
      this.drawImageInBox(this.minimapImage, drawBox);
      this.drawCameraIndicator(start, width / 50, width / 100);
    }

    this.minimapSize = savedSize;
  }

  setCustomBimColors(flag: boolean) {
    this.legendHelper.customBimColors = flag;
  }

  private log(...data: any[]) {
    if (this.loggingEnabled) {
      console.log("[ScreenshotHelper]", ...data);
    }
  }

  private pad(...sizes: number[]): number {
    let sizesSum = 0;
    for (let i = 0; i < sizes.length; i++) {
      sizesSum += sizes[i];
    }

    return sizesSum + (this.padding * (sizes.length + 1));
  }

  private toDeviationText(selectedElement: PlannedBuildingElement): DeviationText & { tradeName: string } {
    const deviation = PlannedBuildingElement.getDeviation(selectedElement, this.scanDataset.scanDate);
    const systemOfMeasurement = this.project.systemOfMeasurement;
    const deviationText = deviationToText(deviation?.deviationVectorMeters, systemOfMeasurement, 1);
    const tradeName = this.getElementTrade(selectedElement);
    return { ...deviationText, tradeName };
  }

  private recalculateCameraPosition(width: number, height: number) {
    if (this.minimapTransform) {
      this.cameraPosition = calculatePhotoLocationCoords(this.originalCameraPosition,
        { width, height },
        this.minimapTransform);
    }
  }

  refactorFlagEnabled = false;
  selectedElements?: PlannedBuildingElement[];
  scanDataset: ScanDataset;
  organization: Organization;
  project: Project;
  floor: Floor;
  textLines: CanvasTextLine[] = [];
  textBlocks: CanvasTextBlock[] = [];
  padding: number = 16;
  minimapSize: number = 450;
  textSpacing: number = 8;
  textBlockSize: number = 250;
  readonly titleFontStyle: CanvasFontStyle = {
    color: "#000",
    size: 18,
    name: "Roboto, sans-serif",
    weight: "bold"
  };
  readonly textFontStyle: CanvasFontStyle = {
    color: "#000",
    size: 16,
    name: "Roboto, sans-serif",
    weight: "normal"
  };
  readonly fonts: {
    title: CanvasFontStyle,
    content: CanvasFontStyle
  } = {
    title: {
      color: "#9b9b9b",
      size: 14,
      name: "Roboto, sans-serif",
      weight: 300
    },
    content: {
      color: "#000",
      size: 20,
      name: "Roboto, sans-serif",
      weight: 300
    }
  };

  originalCameraPosition?: Vector3;
  cameraPosition: Vector2;
  minimapTransform: Matrix3;
  viewerImage: HTMLImageElement | HTMLCanvasElement;
  legendHelper: LegendHelper;
  minimapImage: HTMLImageElement;
  makeSquare: boolean = false;
  loggingEnabled: boolean = true;
}
