import { AvvirEvent } from "type_aliases";

export type GroupMembersDeletedEvent = AvvirEvent<typeof GROUP_MEMBERS_DELETED, {  groupMemberIds: number[]  }>;

const groupMembersDeleted = ( groupMemberIds: number[] ): GroupMembersDeletedEvent => ({
  type: GROUP_MEMBERS_DELETED,
  payload: {
    groupMemberIds
  }
});

export const GROUP_MEMBERS_DELETED = "group_members_deleted";

export default groupMembersDeleted;
