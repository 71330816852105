let nodeEnv = process.env.NODE_ENV;

if (nodeEnv == null) {
  nodeEnv = "production";
}

//@ts-ignore
nodeEnv = nodeEnv.toLowerCase();

export const IS_TEST_ENV = nodeEnv.indexOf("test") >= 0;
export const IS_DEV_ENV = nodeEnv.indexOf("dev") >= 0;
export const IS_PROD_ENV = nodeEnv.indexOf("prod") >= 0;

export function isTestServer() {
  return window.location != null &&
         ((window.location.hostname.indexOf("hexagon.com") < 0
           && window.location.hostname.indexOf("avvir.io") < 0)
          || window.location.protocol.indexOf("https") < 0);
}
