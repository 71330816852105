import { AvvirRoutingEvent } from "type_aliases";

export type ToPhotosEvent = AvvirRoutingEvent<typeof TO_PHOTOS, { projectId: string, type?: string }>

const toPhotos = (projectId: string, type?: string): ToPhotosEvent => ({
  type: TO_PHOTOS, payload: { projectId, type }
});

export const TO_PHOTOS = "to_photos";

export default toPhotos;
