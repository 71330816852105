import { AvvirEvent } from "type_aliases";
import { ApiClassificationCode } from "avvir";

export type ClassificationCodesLoadedEvent = AvvirEvent<typeof CLASSIFICATION_CODES_LOADED, { projectId: string, codes: ApiClassificationCode[] }>

const classificationCodesLoaded = ( projectId: string, codes: ApiClassificationCode[] ): ClassificationCodesLoadedEvent => ({
  type: CLASSIFICATION_CODES_LOADED,
  payload: {
    projectId,
    codes
  }
});

export const CLASSIFICATION_CODES_LOADED = "classification_codes_loaded";

export default classificationCodesLoaded;
