import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ProjectNotFoundEvent = AvvirPayloadlessRoutingEvent<typeof PROJECT_NOT_FOUND>

const projectNotFound = (): ProjectNotFoundEvent => ({
  type: PROJECT_NOT_FOUND
});

export const PROJECT_NOT_FOUND = "project_not_found";

export default projectNotFound;
