import { AvvirEvent } from "type_aliases";
import { ApiProjectArea } from "avvir";

export type ProjectAreasLoadedEvent = AvvirEvent<typeof PROJECT_AREAS_LOADED, { projectId: string, projectAreas: ApiProjectArea[] }>

const projectAreasLoaded = (projectId: string, projectAreas: ApiProjectArea[]): ProjectAreasLoadedEvent => ({
  type: PROJECT_AREAS_LOADED,
  payload: {
    projectId,
    projectAreas
  }
});

export const PROJECT_AREAS_LOADED = "project_areas_loaded";

export default projectAreasLoaded;
