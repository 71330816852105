import { AvvirRoutingEvent } from "type_aliases";

export type ToViewerScanDatasetEvent = AvvirRoutingEvent<typeof TO_VIEWER_SCAN_DATASET, {
  projectId: string
  floorId: string
  scanDatasetId: string,
  preserveElementSelection: true
}>

const toViewerScanDataset = (projectId: string, floorId: string, scanDatasetId: string): ToViewerScanDatasetEvent => ({
  type: TO_VIEWER_SCAN_DATASET,
  payload: {
    projectId,
    floorId,
    scanDatasetId,
    preserveElementSelection: true
  }
});

export const TO_VIEWER_SCAN_DATASET = "to_viewer_scan_dataset";

export default toViewerScanDataset;
