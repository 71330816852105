import { AvvirEvent } from "type_aliases";

export type ToleranceFilterChangedEvent = AvvirEvent<typeof TOLERANCE_FILTER_CHANGED, number>

const toleranceFilterChanged = (filterAmount: number): ToleranceFilterChangedEvent => ({
  type: TOLERANCE_FILTER_CHANGED,
  payload: filterAmount
});

export const TOLERANCE_FILTER_CHANGED = "tolerance_filter_changed";

export default toleranceFilterChanged;
