import { Action } from "redux";

export type SavedSuccessfullyEvent = Action<typeof SAVED_SUCCESSFULLY>

const savedSuccessfully = (): SavedSuccessfullyEvent => ({
  type: SAVED_SUCCESSFULLY
});

export const SAVED_SUCCESSFULLY = "saved_successfully";

export default savedSuccessfully;
