import { AvvirEvent } from "type_aliases";
import { MasterformatFilter } from "../../services/reducers/filter/reduce_filter";

export type MasterformatFilterLoadedEvent = AvvirEvent<typeof MASTERFOMAT_FILTER_LOADED, MasterformatFilter>

const masterformatFilterLoaded = (masterformatFilter: MasterformatFilter): MasterformatFilterLoadedEvent => ({
  type: MASTERFOMAT_FILTER_LOADED,
  payload: masterformatFilter
});

export const MASTERFOMAT_FILTER_LOADED = "masterformat_filter_loaded";

export default masterformatFilterLoaded;
