import _ from "underscore";
import { ApiMasterformat, ApiProjectCostAnalysisProgress, ApiMasterformatProgress } from "avvir";

import DateConverter from "./date_converter";
import ProjectCostAnalysisProgress from "../../models/domain/progress/project_cost_analysis_progress";
import withPromise from "../utilities/general/with_promise";

import { Level1Id as MasterformatLevel1, Level1Id, Level2Id as MasterformatLevel2, Level2Id, Level3Id as MasterformatLevel3, Level3Id, Level4Id as MasterformatLevel4, Level4Id, Level5Id as MasterformatLevel5, Level5Id, MasterformatId, MasterformatLevel } from "masterformat";

import level1 from "../../../resources/masterformat/level1_enum.json";
import level2 from "../../../resources/masterformat/level2_enum.json";
import level3 from "../../../resources/masterformat/level3_enum.json";
import level4 from "../../../resources/masterformat/level4_enum.json";
import { FormatCodeTreeNode, MasterFormatClassLevel1Filter, MasterFormatClassLevel2Filter, MasterFormatClassLevel3Filter, MasterFormatClassLevel4Filter, MasterFormatClassLevel5Filter, MasterformatFilter, toggleChildTrades, toggleParents } from "../reducers/filter/reduce_filter";

import { parse } from "csv-parse";

import type { Selected } from "type_aliases";

const levelIds = [
  level1, level2, level3, level4
];

enum Headers {
  ID = "ID",
  WBS_CODE = "WBS Code",
  SEQ_NUMBER = "Seq #",
  WBS_NAME = "WBS Name",
  COMPONENT_DESCRIPTION = "Component Description",
  UNIT_OF_MEASURE = "Unit of Measure",
  PLANNED_UNIT_COST = "Planned Unit Cost",
  PLANNED_QUANTITY = "Planned Qty",
  PLANNED_TOTAL_COST = "Planned Total Cost",
  REPORTED_QUANTITY = "Reported Qty",
  CAPTURED_QUANTITY = "Captured Qty",
  VENDOR = "Vendor"
}

// @ts-ignore
function columnIsNumeric(header: keyof ProjectCostAnalysisProgress): header is ColumnsWithFloatValues {
  let isNumeric = false;
  columnsWithFloatValues.forEach((column) => {
    if (headerAliasesByHeader[column] === header) {
      isNumeric = true;
    }
  });
  return isNumeric;
}

type ColumnsWithFloatValues =
  Headers.PLANNED_UNIT_COST
  | Headers.PLANNED_QUANTITY
  | Headers.PLANNED_TOTAL_COST
  | Headers.REPORTED_QUANTITY
  | Headers.CAPTURED_QUANTITY
  | Headers.ID
  | Headers.SEQ_NUMBER;

const columnsWithFloatValues = new Set([Headers.PLANNED_UNIT_COST,
  Headers.PLANNED_QUANTITY,
  Headers.PLANNED_UNIT_COST,
  Headers.PLANNED_TOTAL_COST,
  Headers.REPORTED_QUANTITY,
  Headers.CAPTURED_QUANTITY,
  Headers.ID,
  Headers.SEQ_NUMBER]);

const headerAliasesByHeader: { [header in Headers]: keyof ProjectCostAnalysisProgress } = {
  [Headers.ID]: "id",
  [Headers.WBS_CODE]: "masterformatCode",
  [Headers.SEQ_NUMBER]: "sequence",
  [Headers.WBS_NAME]: "name",
  [Headers.COMPONENT_DESCRIPTION]: "componentDescription",
  [Headers.UNIT_OF_MEASURE]: "unitOfMeasure",
  [Headers.PLANNED_UNIT_COST]: "plannedUnitCost",
  [Headers.PLANNED_QUANTITY]: "plannedQuantityProject",
  [Headers.PLANNED_TOTAL_COST]: "plannedTotalCostProject",
  [Headers.REPORTED_QUANTITY]: "reportedQuantityProject",
  [Headers.CAPTURED_QUANTITY]: "scannedQuantityArea",
  [Headers.VENDOR]: "vendor"
};

export default class MasterformatProgressConverter {

  static getMasterformatVersionFromFilename(filename: string): number {
    const withOutExtension = filename.split(".")[0];
    const version = withOutExtension.split("_")[4].slice(0);
    return parseInt(version);
  }

  static tsvToProjectCostAnalysisProgress(tsvContent: string): Promise<ApiProjectCostAnalysisProgress[]> {
    const [readSuccess, readError, readingObject] = withPromise<ApiProjectCostAnalysisProgress[]>();
    const updatedContent = tsvContent.replace(/\r\n|\r|\n/g, "\n");

    parse(updatedContent, {
      bom: true,
      relaxColumnCount: true,
      relaxQuotes: true,
      columns: (header) => header.map(column => headerAliasesByHeader[column]),
      cast: (value, context) => {
        if (context.header) {
          return value;
        } else {
          if (columnIsNumeric(context.column as keyof ProjectCostAnalysisProgress)) {
            if (_.isUndefined(value) || value === "") {
              return 0; //if there is no data for floating point columns default to 0
            } else {
              return parseFloat(value);
            }
          } else {
            return value;
          }
        }
      },
      trim: true,
      delimiter: "\t"
    }, (err, records) => {
      if (err) {
        readError(err);
      } else {
        const apiProjectCostAnalysisProgresses = records.map((record) => {
          // setting analysisDate to null until future story for user setting analysisDate.
          return new ApiProjectCostAnalysisProgress({
            id: record.id,
            masterformatCode: record.masterformatCode,
            sequence: record.sequence,
            name: record.name,
            componentDescription: record.componentDescription,
            unitOfMeasure: record.unitOfMeasure,
            plannedUnitCost: record.plannedUnitCost,
            plannedQuantityProject: record.plannedQuantityProject,
            plannedTotalCostProject: record.plannedTotalCostProject,
            reportedQuantityProject: record.reportedQuantityProject,
            vendor: record.vendor,
            analysisDate: null
          });
        });
        readSuccess(apiProjectCostAnalysisProgresses);
      }
    });
    return readingObject;
  }

  static tsvToProjectMasterformatProgress(tsvContent: string): ApiMasterformatProgress[] {
    const lines = tsvContent.split("\n");
    const header = lines[0].split("\t");
    const versionString = header[0].trim();
    let version;
    if (versionString) {
      version = parseInt(versionString, 10);
    } else {
      version = 2016;
    }
    const dates = header.slice(1)
      .map((dateString) => dateString.trim())
      .map(DateConverter.tsvStringToDate)
      .map(DateConverter.dateToInstant);

    const progress: { [masterformat: string]: string[] } = lines.splice(1, lines.length - 1)
      .map((line) => line.split("\t"))
      .reduce((progressByMasterformat, line) => {
        progressByMasterformat[line[0].trim()] = line.slice(1).map((percent) => percent.trim());
        return progressByMasterformat;
      }, {});

    const progressResults = [];
    for (let masterformat in progress) {
      progress[masterformat].forEach((percentage, i) => {
        if (percentage !== "") {
          progressResults.push(new ApiMasterformatProgress({
            masterformat: new ApiMasterformat(version, masterformat),
            percentComplete: parseFloat(percentage),
            scanDate: dates[i],
          createdAt: dates[i]
        }));
        }
      });
    }

    if (progressResults.length === 0) {
      throw new Error("Unable to parse file");
    }
    return progressResults;
  }


  static isLevel1Id(masterformatId: string) {
    return masterformatId.substr(3, 8) === "00 00" && 1;
  }

  // Level 2 masterformats may have Level1 masterformat format.
  static isLevel2Id(masterformatId: string) {
    return masterformatId.substr(4, 8) === "0 00" && 2;
  }

  static isLevel3Id(masterformatId: string) {
    return (!this.isLevel1Id(masterformatId) &&
           !this.isLevel2Id(masterformatId) &&
           masterformatId.substr(6, 8) === "00") && 3;
  }

  static isLevel4Id(masterformatId: string) {
    return (!this.isLevel3Id(masterformatId) &&
           masterformatId.length === 8) && 4;
  }

  static isLevel5Id(masterformatId: string) {
    if (this.isLevel4Id(masterformatId.substring(0, 8)) === 4 && masterformatId.length > 8) {
      return 5;
    }
  }

  static getMasterformatLevel(masterformatId: string): number {
    return MasterformatProgressConverter.isLevel1Id(masterformatId) ||
           MasterformatProgressConverter.isLevel2Id(masterformatId) ||
           MasterformatProgressConverter.isLevel3Id(masterformatId) ||
           MasterformatProgressConverter.isLevel4Id(masterformatId) ||
           MasterformatProgressConverter.isLevel5Id(masterformatId);
  }

  static getDescription(masterformat: MasterformatId, level?: number): MasterformatId[number] | null {
    if (level == null) {
      // Get deepest level by default
      level = MasterformatProgressConverter.getMasterformatLevel(masterformat);
    }
    return levelIds[level - 1]?.[masterformat] || null;
  }

  static getLevelId(masterFormatId: string, level: MasterformatLevel, full?: boolean): Level1Id | Level2Id | Level3Id | Level4Id | Level5Id | null {
    let level5Default = " - 000";
    let validMasterformatLevel = this.getMasterformatLevel(masterFormatId);
    if (typeof validMasterformatLevel !== "undefined" && validMasterformatLevel >= level) {
      switch (level) {
        case 1: {
          return (masterFormatId as string).substring(0, 2) + (full ? " 00 00" + level5Default : "") as Level1Id;
        }
        case 2: {
          return (masterFormatId as string).substring(0, 4) + (full ? "0 00" + level5Default : "") as Level2Id;
        }
        case 3: {
          return (masterFormatId as string).substring(0, 5) + (full ? " 00" + level5Default : "") as Level3Id;
        }
        case 4: {
          return (masterFormatId as string).substring(0, 8) + (full ? level5Default : "") as Level4Id;
        }
        case 5: {
          return masterFormatId as Level5Id;
        }
        default: {
          return null;
        }
      }
    }
    return null;
  };

  static getFormatedLevelId(masterFormatId: string, level: MasterformatLevel) {
    let code = this.getLevelId(masterFormatId, level);
    if (code) {
      return (level < 5 ? code.substr(code.length - 2) : code.substr(code.length - 3)).trim();
    }
  }

  static convertScanDatesFromInstant(apiMasterformatProgresses: ApiMasterformatProgress[]) {
    return apiMasterformatProgresses.map(projectMasterformatProgress => ({
      ...projectMasterformatProgress,
      scanDate: DateConverter.instantToDate(projectMasterformatProgress.scanDate as number),
      createdAt: DateConverter.instantToDate(projectMasterformatProgress.createdAt as number)
    }));

  }

  static getFullMasterformatCode(shortcode: string, level: number) {
    switch (level) {
      case 1: {
        // @ts-ignore
        return (shortcode + " 00 00") as Level1Id;
      }
      case 2: {
        return (shortcode as string) + "0 00" as Level2Id;
      }
      case 3: {
        return (shortcode as string) + " 00" as Level3Id;
      }
      case 4: {
        return (shortcode as string) as Level4Id;
      }
      case 5: {
        return (shortcode as string) as Level5Id;
      }
    }
  }

  static toggleMasterFormats(codes: MasterformatId[], value: Selected, masterformatFilter: MasterformatFilter): MasterformatFilter {
    if (codes.length > 1) {
      const isLevel5 = codes.reduce((acc, curr) => {
        return !!MasterformatProgressConverter.isLevel5Id(curr);
      }, true);
      if (isLevel5) {
        return codes.reduce((acc, curr) => {
          const newFilter = this.toggleMasterFormat(curr, value, acc);
          return { ...acc, ...newFilter };
        }, masterformatFilter);
      } else {
        // Filtering with lists of masterformat codes is currently only set up level 5 codes
        console.error("Invalid filter state [all codes in filter list must be level 5]");
        return masterformatFilter;
      }
    } else {
      return this.toggleMasterFormat(codes[0], value, masterformatFilter);
    }
  }

  static toggleMasterFormat(code: MasterformatId, value: Selected, masterformatFilter: MasterformatFilter): MasterformatFilter {
    let level1: MasterFormatClassLevel1Filter,
      level2: MasterFormatClassLevel2Filter,
      level3: MasterFormatClassLevel3Filter,
      level4: MasterFormatClassLevel4Filter,
      level5: MasterFormatClassLevel5Filter;
    const masterFormatCodeLevel = MasterformatProgressConverter.getMasterformatLevel(code);

    if (masterFormatCodeLevel === 5) {
      level1 = { ...masterformatFilter.level1 };
      level2 = { ...masterformatFilter.level2 };
      level3 = { ...masterformatFilter.level3 };
      level4 = { ...masterformatFilter.level4 };
      level5 = { ...masterformatFilter.level5 };
      level5[code] = value;
    } else {
      level1 = toggleChildTrades(code as MasterformatLevel1, value, masterformatFilter.level1);
      level2 = toggleChildTrades(code as MasterformatLevel2, value, masterformatFilter.level2);
      level3 = toggleChildTrades(code as MasterformatLevel3, value, masterformatFilter.level3);
      level4 = toggleChildTrades(code as MasterformatLevel4, value, masterformatFilter.level4);
      level5 = toggleChildTrades(code as MasterformatLevel5, value, masterformatFilter.level5);
    }
    return { level1, level2, level3, level4, level5 };
  }

  static toggleMasterformatTrade(codes: MasterformatId[], value: Selected, masterformatFilter: MasterformatFilter): MasterformatFilter {
    let level1, level2, level3, level4, level5, tempFilter, formatTree: Partial<FormatCodeTreeNode>[];
    tempFilter = this.toggleMasterFormats(codes as MasterformatId[], value, masterformatFilter);
    formatTree = this.createMasterFormatTree(tempFilter as MasterformatFilter);
    formatTree.forEach(node => {
      toggleParents(node as FormatCodeTreeNode, "masterformat");
    });

    //@ts-ignore
    level1 = _.object(_.map(formatTree, tree => [tree.code, tree.value]));
    const level2Tree = _.flatten(_.map(formatTree, tree => tree.children));
    level2 = _.object(_.map(level2Tree, tree => [tree.code, tree.value]));
    const level3Tree = _.flatten(_.map(level2Tree, tree => tree.children));
    level3 = _.object(_.map(level3Tree, tree => [tree.code, tree.value]));
    const level4Tree = _.flatten(_.map(level3Tree, tree => tree.children));
    level4 = _.object(_.map(level4Tree, tree => [tree.code, tree.value]));
    const level5Tree = _.flatten(_.map(level4Tree, tree => tree.children));
    level5 = _.object(_.map(level5Tree, tree => [tree.code, tree.value]));

    return { ...masterformatFilter, level1, level2, level3, level4, level5 } as MasterformatFilter;
  };

  static createMasterFormatTree(filter: MasterformatFilter) {
    return _(filter.level1).map((level1value: Selected, level1code: MasterformatLevel1) => {
      return {
        visited: false,
        value: level1value,
        code: level1code,
        children: _.map(_.pick<Selected, MasterFormatClassLevel2Filter>(filter.level2, (level2value: Selected, level2code: MasterformatLevel2) => {
            return (level2code as string).startsWith(level1code);
          }),
          (level2value, level2code: MasterformatLevel2) => {
            return {
              visited: false,
              value: level2value,
              code: level2code,
              children: _.map(_.pick<Selected, MasterFormatClassLevel3Filter>(filter.level3, (level3value, level3code: MasterformatLevel3) => {
                return (level3code as string).startsWith(level2code);
              }), (level3value, level3code: MasterformatLevel3) => {
                return {
                  visited: false,
                  value: level3value,
                  code: level3code,
                  children: _.map(_.pick<Selected, MasterFormatClassLevel4Filter>(filter.level4, (level4value, level4code: MasterformatLevel4) => {
                    return (level4code as string).startsWith(level3code);
                  }), (level4value, level4code: MasterformatLevel4) => {

                    return {
                      visited: false,
                      value: level4value,
                      code: level4code,
                      children: _.map(_.pick<Selected, MasterFormatClassLevel5Filter>(filter.level5, (level5value, level5code: MasterformatLevel5) => {
                        return (level5code as string).startsWith(level4code);
                      }), (level5value, level5code: MasterformatLevel5) => {
                        return {
                          visited: false,
                          value: level5value,
                          code: level5code,
                          children: []
                        };
                      })
                    };
                  })
                };
              })
            };
          })
      };
    });
  };

  static toggleAllMasterformats(value: -1 | 0 | 1 = 0, masterFormatFilter: MasterformatFilter): MasterformatFilter {
    return {
      level1: _.mapObject(masterFormatFilter.level1, () => value) as MasterFormatClassLevel1Filter,
      level2: _.mapObject(masterFormatFilter.level2, () => value) as MasterFormatClassLevel2Filter,
      level3: _.mapObject(masterFormatFilter.level3, () => value) as MasterFormatClassLevel3Filter,
      level4: _.mapObject(masterFormatFilter.level4, () => value) as MasterFormatClassLevel4Filter,
      level5: _.mapObject(masterFormatFilter.level5, () => value) as MasterFormatClassLevel5Filter,
    };
  }

  static convertHierarchyToFilter(wbsHierarchy): MasterformatFilter {
    return {
      level1: this.mapMasterFormatKeyAndValue(Object.keys(wbsHierarchy.level1), (key) => [key.slice(0, 2), 1]) as unknown as MasterFormatClassLevel1Filter,
      level2: this.mapMasterFormatKeyAndValue(Object.keys(wbsHierarchy.level2), (key) => [key.slice(0, 4), 1]) as unknown as MasterFormatClassLevel2Filter,
      level3: this.mapMasterFormatKeyAndValue(Object.keys(wbsHierarchy.level3), (key) => [key.slice(0, 5), 1]) as unknown as MasterFormatClassLevel3Filter,
      level4: this.mapMasterFormatKeyAndValue(Object.keys(wbsHierarchy.level4), (key) => [key.slice(0, 8), 1]) as unknown as MasterFormatClassLevel4Filter,
      level5: this.mapMasterFormatKeyAndValue(Object.keys(wbsHierarchy.level5), (key) => [key, 1]) as unknown as MasterFormatClassLevel5Filter
    };
  }

  static mapMasterFormatKeyAndValue = (
    masterformats: string[],
    mapper: (key: string) => [string, number]
  ): MasterformatFilter => {
    //@ts-ignore
    return _.chain(masterformats).map(mapper).object().value();
  };

  static fromApi = (
    apiProjectCostAnalysisProgress: ApiProjectCostAnalysisProgress
  ): ProjectCostAnalysisProgress => {
    return new ProjectCostAnalysisProgress(
      {
        id: apiProjectCostAnalysisProgress.id,
        masterformatCode: apiProjectCostAnalysisProgress.masterformatCode,
        sequence: apiProjectCostAnalysisProgress.sequence,
        name: apiProjectCostAnalysisProgress.name,
        componentDescription: apiProjectCostAnalysisProgress.componentDescription,

        unitOfMeasure: apiProjectCostAnalysisProgress.unitOfMeasure,
        plannedUnitCost: apiProjectCostAnalysisProgress.plannedUnitCost,

        plannedQuantityProject: apiProjectCostAnalysisProgress.plannedQuantityProject,
        reportedQuantityProject: apiProjectCostAnalysisProgress.reportedQuantityProject,
        modeledQuantityProject: apiProjectCostAnalysisProgress.modeledQuantityProject,
        scannedQuantityProject: apiProjectCostAnalysisProgress.scannedQuantityProject,

        plannedTotalCostProject: apiProjectCostAnalysisProgress.plannedTotalCostProject,
        reportedTotalCostProject: apiProjectCostAnalysisProgress.reportedTotalCostProject,
        modeledTotalCostProject: apiProjectCostAnalysisProgress.modeledTotalCostProject,
        scannedTotalCostProject: apiProjectCostAnalysisProgress.scannedTotalCostProject,

        modeledQuantityArea: apiProjectCostAnalysisProgress.modeledQuantityArea,
        scannedQuantityArea: apiProjectCostAnalysisProgress.scannedQuantityArea,

        modeledTotalCostArea: apiProjectCostAnalysisProgress.modeledTotalCostArea,
        scannedTotalCostArea: apiProjectCostAnalysisProgress.scannedTotalCostArea,

        analysisDate: apiProjectCostAnalysisProgress.analysisDate
      }
    );
  };

}


