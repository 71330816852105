import { ApiProject } from "avvir";

import { AvvirEvent } from "type_aliases";

export type ProjectLoadedEvent = AvvirEvent<typeof PROJECT_LOADED, ApiProject>

const projectLoaded = (apiProject: ApiProject): ProjectLoadedEvent => ({
  type: PROJECT_LOADED,
  payload: apiProject
});

export const PROJECT_LOADED = "project_loaded";

export default projectLoaded;
