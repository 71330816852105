import { ApiMasterformat } from "avvir";

import { AvvirEvent } from "type_aliases";

export type MasterformatLoadedEvent = AvvirEvent<typeof MASTERFORMAT_LOADED, ApiMasterformat[]>

const masterformatLoaded = (masterformat: ApiMasterformat[]): MasterformatLoadedEvent => ({
  type: MASTERFORMAT_LOADED,
  payload: masterformat
});

export const MASTERFORMAT_LOADED = "masterformat_loaded";

export default masterformatLoaded;
