import { Reducer } from "redux";

import makeUpdateModel from "./make_update_model";
import { INTEGRATION_CREDENTIALS_LOADED, IntegrationCredentialsLoadedEvent } from "../../../events/integration_credentials/integration_credentials_loaded";
import { INTEGRATION_CREDENTIALS_SAVED, IntegrationCredentialsSavedEvent } from "../../../events/integration_credentials/integration_credentials_saved";
import IntegrationCredentials from "../../../models/domain/integration_credentials";

export type IntegrationCredentialsEvent =
  IntegrationCredentialsLoadedEvent
  | IntegrationCredentialsSavedEvent;

export type IntegrationCredentialsStore = {
  byId: {
    [id: number]: IntegrationCredentials
  },
  idsByProjectId: {
    [projectId: string]: number[]
  }
}

const updateIntegrationCredentials = makeUpdateModel<IntegrationCredentials>(new IntegrationCredentials({}));

function addToProjectIds(credentialsStore: IntegrationCredentialsStore, projectId: string, credentialsId: number) {
  let credentialIdsForProject = [...(credentialsStore.idsByProjectId[projectId] || [])];

  if (credentialIdsForProject.indexOf(credentialsId) < 0) {
    credentialIdsForProject.push(credentialsId);
  }
  return {
    ...credentialsStore,
    idsByProjectId: {
      ...credentialsStore.idsByProjectId,
      [projectId]: credentialIdsForProject
    }
  };
}

const reduceIntegrationCredentials: Reducer<IntegrationCredentialsStore, IntegrationCredentialsEvent> = (
  integrationCredentialsStore: IntegrationCredentialsStore = { byId: {}, idsByProjectId: {} },
  event
) => {
  let integrationCredentials = integrationCredentialsStore?.byId ? integrationCredentialsStore : { byId: {}, idsByProjectId: {} };


  switch (event.type) {
    case INTEGRATION_CREDENTIALS_SAVED: {
      integrationCredentials = updateIntegrationCredentials(integrationCredentials, event.payload.integrationCredentials.id, event.payload.integrationCredentials);
      return addToProjectIds(integrationCredentials, event.payload.projectId, event.payload.integrationCredentials.id);
    }
    case INTEGRATION_CREDENTIALS_LOADED: {
      if (event.payload
          && event.payload.integrationCredentials) {
        let store = integrationCredentials;

        if (event.payload.integrationCredentials.length === 0) {
          return {
            byId: {},
            idsByProjectId: {}
          };
        } else {
          event.payload.integrationCredentials.forEach((credentials) => {
            store = updateIntegrationCredentials(store, credentials.id, credentials);
            store = addToProjectIds(store, event.payload.projectId, credentials.id);
          });
        }
        return store;
      }
      return integrationCredentials;
    }
    default: {
      return integrationCredentials;
    }
  }
};

export default reduceIntegrationCredentials;
