import { createSelector } from "reselect";

import getOrganizationName from "./organization_getters/get_organization_name";
import getPageName from "./location_metadata/get_page_name";
import getProjectName from "./project_getters/get_project_name";
import { BUDGET, INTEGRATIONS, ORGANIZATION, ORGANIZATION_LIST, PHOTOS, PROGRESS_REPORT, PROJECT, PROJECT_PORTFOLIO, PROJECT_REPORTS, PROJECT_SUMMARY, PROJECT_UPLOADS, PROJECT_VIEWER, QUALITY_CONTROL_REPORT } from "../../models/domain/enums/page_names";

const getPageTitle = createSelector([
  getPageName,
  getOrganizationName,
  getProjectName
], (pageName, organizationName, projectName) => {
  switch (pageName) {
    case INTEGRATIONS:
    case PROJECT:
    case PROJECT_UPLOADS:
    case PROJECT_REPORTS:
    case PROJECT_SUMMARY:
    case PHOTOS:
    case PROJECT_VIEWER:
    case BUDGET: {
      return projectName || "";
    }
    case ORGANIZATION: {
      return organizationName || "";
    }
    case ORGANIZATION_LIST: {
      return "Organizations";
    }
    case QUALITY_CONTROL_REPORT: {
      return "Quality Control Report";
    }
    case PROGRESS_REPORT: {
      return "Progress Report";
    }
    case PROJECT_PORTFOLIO: {
      return "Project Portfolio";
    }
    default: {
      return null;
    }
  }
});


export default getPageTitle;
