import CameraControls, { CameraControlsView } from "../../camera_controls/camera_controls";
import { Matrix3, Quaternion, Vector2, Vector3 } from "three";
import View from "../../../models/domain/view";

export class CamerasController {

  removeBimControls() {
    this.bimViewerControls = null;
  }

  setCameraViewsOnLoad(view: View) {
    const camera = view.viewAttributes?.camera;
    if (camera && camera.position && camera.target) {
      this.setBimCameraViewWhenExists(camera.position, camera.target);
      this.setPhotoCameraViewWhenExists(camera.position, camera.target);
    }
  }

  setBimCameraViewWhenExists(position: Vector3, target: Vector3) {
    this.bimView = { position, target };
    this.syncBimCameraViewIfExists();
  }

  setPhotoCameraViewWhenExists(position: Vector3, target: Vector3) {
    this.photoView = { position, target };
    this.syncPhotoCameraViewIfExists();
  }

  syncBimCameraViewIfExists() {
    if (this.bimViewerControls && this.bimView) {
      this.bimViewerControls.updateFromView(this.bimView);
      this.bimView = null;
    }
  }

  syncPhotoCameraViewIfExists() {
    if (this.photoViewerControls && this.photoView) {
      this.photoViewerControls.updateFromView(this.photoView);
      this.photoView = null;
    }
  }

  addBimViewerCamera = (cameraControls: CameraControls) => {
    this.bimViewerControls?.handlers.dispose();
    this.bimViewerControls = cameraControls;
    this.syncBimCameraViewIfExists();
  };

  addPhotoViewerCamera = (cameraControls: CameraControls) => {
    this.photoViewerControls = cameraControls;
    this.syncPhotoCameraViewIfExists();
  };

  copyBimCameraToPhotoCamera = () => {
    if (this.bimViewerControls) {
      this.bimViewerControls.camera.getWorldPosition(this.photoViewerControls.camera.position);
      this.photoViewerControls.target.copy(this.bimViewerControls.target);
      this.photoViewerControls.update();
    }
  };

  copyPhotoCameraToBimCamera = () => {
    if (this.photoViewerControls && this.bimViewerControls) {
      this.photoViewerControls.camera.getWorldPosition(this.bimViewerControls.camera.position);
      this.bimViewerControls.target.copy(this.photoViewerControls.target);
      this.bimViewerControls.update();
    }
  };

  enableBimFollowsPhotoCamera = () => {
    this.copyPhotoCameraToBimCamera();
    this.photoViewerControls.settings.onOrbitHook = this.copyPhotoCameraToBimCamera;
    this.areCamerasSynced = true;
  };

  disableBimFollowsPhotoCamera = () => {
    this.photoViewerControls.settings.onOrbitHook = null;
    this.areCamerasSynced = false;
  };

  getBimViewerControls = () => {
    return this.bimViewerControls
  }

  private bimView: CameraControlsView;
  private photoView: CameraControlsView;

  private areCamerasSynced: boolean = false;
  private bimViewerControls: CameraControls;
  public photoViewerControls: CameraControls;
  public photoAreaMinimapToViewer: Matrix3;
  public updatePhotoCameraCallback: (position: Vector3 | Vector2, orientation: Quaternion) => any;
}


const camerasController = new CamerasController();

export default camerasController;
