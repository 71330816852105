import { AssociationIds, AvvirEvent } from "type_aliases";

export type PipelineTriggerFailedEvent = AvvirEvent<typeof PIPELINE_TRIGGER_FAILED, { floorId: string, scanDatasetId: string }>

const pipelineTriggerFailed = ({ floorId, scanDatasetId }: AssociationIds): PipelineTriggerFailedEvent => ({
  type: PIPELINE_TRIGGER_FAILED,
  payload: {
    floorId,
    scanDatasetId
  }
});

export const PIPELINE_TRIGGER_FAILED = "pipeline_trigger_failed";

export default pipelineTriggerFailed;
