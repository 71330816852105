import { Action } from "redux";

export type IncludeInAnalysisElementsHiddenEvent = Action<typeof INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN>

const includeInAnalysisElementsHidden = (): IncludeInAnalysisElementsHiddenEvent => ({
  type: INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN,
});

export const INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN = "include_in_analysis_elements_hidden";

export default includeInAnalysisElementsHidden;
