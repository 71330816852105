import Floor from "../../../models/domain/floor";
import { AvvirEvent } from "type_aliases";

export type FloorUpdatedEvent = AvvirEvent<typeof FLOOR_UPDATED, { floor: Partial<Floor> }>

const floorUpdated = (floor: Partial<Floor>): FloorUpdatedEvent => ({
  type: FLOOR_UPDATED,
  payload: {
    floor
  }
});

export const FLOOR_UPDATED = "floor_updated";

export default floorUpdated;
