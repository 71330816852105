import { immerable } from "immer";
import { ApiProjectArea, ApiProjectAreaProgress, ApiProjectAreaWorkPackage } from "avvir";

import addReadOnlyPropertiesToModel from "../../../services/utilities/mixins/add_read_only_properties_to_model";
import ProjectAreaProgress from "./project_area_progress";
import ProjectAreaWorkPackage from "./project_area_work_package";

import type { ModifyPartial } from "type_aliases";

export type ProjectAreaArgument = ModifyPartial<ApiProjectArea, {
  progress: ProjectAreaProgress[] | ApiProjectAreaProgress[]
  workPackages: ProjectAreaWorkPackage[] | ApiProjectAreaWorkPackage[]
}>

function isProgressList(list: ApiProjectAreaProgress[] | ProjectAreaProgress[]): list is ProjectAreaProgress[] {
  return list?.length && list[0] instanceof ProjectAreaProgress;
}

function isWorkPackageList(list: ApiProjectAreaWorkPackage[] | ProjectAreaWorkPackage[]): list is ProjectAreaWorkPackage[] {
  return list?.length && list[0] instanceof ProjectAreaWorkPackage;
}

export default class ProjectArea {
  constructor({
    id,
    progress,
    workPackages,
    floorId,
    firebaseFloorId,
    modelElementId
  }: ProjectAreaArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id, floorId, firebaseFloorId, modelElementId });
    if (isProgressList(progress)) {
      this.progress = progress;
    } else {
      this.progress = progress?.map((apiProjectAreaProgress) => {
        return new ProjectAreaProgress(apiProjectAreaProgress);
      }) || [];
    }
    if (isWorkPackageList(workPackages)) {
      this.workPackages = workPackages;
    } else {
      this.workPackages = workPackages?.map(apiProjectAreaWorkPackage => {
        return new ProjectAreaWorkPackage(apiProjectAreaWorkPackage);
      }) || [];
    }
  }

  readonly id: number | null;
  progress: ProjectAreaProgress[];
  workPackages: ProjectAreaWorkPackage[];
  modelElementId: number;
  floorId: number;
  firebaseFloorId: string;

  static readonly [immerable] = true;
}

