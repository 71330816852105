import { Matrix3 } from "three";
import { AvvirEvent } from "type_aliases";
import Floor from "../../models/domain/floor";

export type PhotoAreaMinimapToBimMinimapTransformSavedEvent = AvvirEvent<typeof PHOTO_AREA_MINIMAP_TO_BIM_MINIMAP_TRANSFORM_SAVED, {
  floor: Partial<Floor>
}>

const photoAreaMinimapToBimMinimapTransformSaved = (floorId: string, transform: Matrix3): PhotoAreaMinimapToBimMinimapTransformSavedEvent => ({
  type: PHOTO_AREA_MINIMAP_TO_BIM_MINIMAP_TRANSFORM_SAVED,
  payload: {
    floor: {
      firebaseId: floorId,
      photoAreaMinimapPixelToBimMinimapPixel: transform
    }
  }
});

export const PHOTO_AREA_MINIMAP_TO_BIM_MINIMAP_TRANSFORM_SAVED = "photo_area_minimap_to_bim_minimap_transform_saved";

export default photoAreaMinimapToBimMinimapTransformSaved;
