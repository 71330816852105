import { AvvirEvent, Vector2Like } from "type_aliases";

export type PhotoAreaMinimapLoadedEvent = AvvirEvent<typeof PHOTO_AREA_MINIMAP_LOADED, {
  size: Vector2Like
}>

const photoAreaMinimapLoaded = (size: Vector2Like): PhotoAreaMinimapLoadedEvent => ({
  type: PHOTO_AREA_MINIMAP_LOADED,
  payload: {
    size
  }
});

export const PHOTO_AREA_MINIMAP_LOADED = "photo_area_minimap_loaded";

export default photoAreaMinimapLoaded;
