import { AbstractRenderer, Graphics, MSAA_QUALITY, RenderTexture, SCALE_MODES, Sprite, Point, IPointData, Texture } from "pixi.js";

export interface MapMarkerStyle {
  fillAlpha?: number;
  fillColor: number;
  lineColor: number;
}

export const defaultDotStyle: MapMarkerStyle = {
  fillAlpha: 0.6,
  fillColor: 0x7ed321,
  lineColor: 0x7ed321
};

export function createStarPoints(center: IPointData, outerSize: number, innerSize: number): Point[] {
  const rad36 = 0.628319;
  const outer = outerSize;
  const inner = innerSize;
  const cos18 = Math.cos(rad36 / 2);
  const sin18 = Math.sin(rad36 / 2);
  const sin36 = Math.sin(rad36);
  const cos36 = Math.cos(rad36);
  const sin72 = Math.sin(rad36 * 2);
  const cos72 = Math.cos(rad36 * 2);

  return [
    new Point(center.x, center.y - outer),
    new Point(center.x + sin36 * inner, center.y - cos36 * inner),
    new Point(center.x + sin72 * outer, center.y - cos72 * outer),
    new Point(center.x + cos18 * inner, center.y + sin18 * inner),
    new Point(center.x + sin36 * outer, center.y + cos36 * outer),
    new Point(center.x, center.y + inner),
    new Point(center.x - sin36 * outer, center.y + cos36 * outer),
    new Point(center.x - cos18 * inner, center.y + sin18 * inner),
    new Point(center.x - sin72 * outer, center.y - cos72 * outer),
    new Point(center.x - sin36 * inner, center.y - cos36 * inner),
  ];
}

export class MapMarkerTexture extends Sprite {
  private readonly graphics?: Graphics;
  private renderTexture?: RenderTexture;

  public fillAlpha?: number = 0.6;
  public fillColor?: number = 0x7ed321;
  public lineColor?: number = 0x7ed321;
  public radius?: number = 18.5;
  public lineWidth?: number = 1.5;
  public showOrientation?: boolean = false;
  public hasStar?: boolean = false;
  public iconPath?: string = null;

  constructor(graphics: Graphics, styleOptions: MapMarkerStyle, showOrientation?: boolean, icon?: string) {
    super();
    this.graphics = graphics;
    this.addChild(graphics);

    this.fillColor = styleOptions.fillColor;
    this.lineColor = styleOptions.lineColor;

    if (styleOptions.fillAlpha) {
      this.fillAlpha = styleOptions.fillAlpha;
    }

    if (showOrientation) {
      this.showOrientation = true;
    }

    if (icon === "star") {
      this.hasStar = true;
    } else {
      this.iconPath = icon;
    }
  }

  async update(renderer: AbstractRenderer) {
    const g = this.graphics;
    g.clear();

    g.beginFill(this.fillColor, this.fillAlpha == null ? defaultDotStyle.fillAlpha : this.fillAlpha);
    g.drawCircle(this.radius, this.radius, this.radius - this.lineWidth);
    g.endFill();
    g.lineStyle(this.lineWidth, this.lineColor);
    g.drawCircle(this.radius, this.radius, this.radius - this.lineWidth);
    g.endFill();

    if (this.hasStar) {
      g.beginFill(this.lineColor, this.fillAlpha);
      g.lineStyle(0);
      g.drawPolygon(createStarPoints({ x: this.radius, y: this.radius }, this.radius * 0.8 - this.lineWidth, this.radius * 0.4 - this.lineWidth));
      g.endFill();
    }

    if (this.showOrientation) {
      g.moveTo(this.radius, this.radius);
      g.lineTo(this.radius, 2);
    }
    if(this.iconPath){
      let resolution = 2;
      let iconTexture = await Texture.fromURL(this.iconPath, {resolution});
      let icon = Sprite.from(iconTexture);
      icon.scale.set(2*resolution);
      this.addChild(icon);
      icon.position.set(this.radius-icon.width/2, this.radius-icon.height/2);
    }

    if (this.renderTexture == null) {
      this.renderTexture = renderer.generateTexture(this, {
        scaleMode: SCALE_MODES.LINEAR,
        resolution: window.devicePixelRatio,
        multisample: MSAA_QUALITY.HIGH
      });

    } else {
      renderer.render(g, {
        renderTexture: this.renderTexture
      });
    }

    this.texture = this.renderTexture;
  }

  static async create(renderer: AbstractRenderer, styleOptions: MapMarkerStyle, showOrientation: boolean = true, icon?: string): Promise<MapMarkerTexture> {
    const dot = new MapMarkerTexture(new Graphics(), styleOptions, showOrientation, icon);
    await dot.update(renderer);
    return dot;
  }

}
