import { ApiMasterformatProgress } from "avvir";

import { AvvirEvent } from "type_aliases";

export type ProjectMasterformatProgressLoadedEvent = AvvirEvent<typeof PROJECT_MASTERFORMAT_PROGRESS_LOADED, {
  id: string,
  scanned: ApiMasterformatProgress[],
  baseline: ApiMasterformatProgress[],
  current: ApiMasterformatProgress[]
}>

const projectMasterformatProgressLoaded = (projectId: string, scannedMasterformatProgress: ApiMasterformatProgress[], baselineMasterformatProgress: ApiMasterformatProgress[], currentMasterformatProgress: ApiMasterformatProgress[]): ProjectMasterformatProgressLoadedEvent => ({
  type: PROJECT_MASTERFORMAT_PROGRESS_LOADED,
  payload: {
    id: projectId,
    scanned: scannedMasterformatProgress,
    baseline: baselineMasterformatProgress,
    current: currentMasterformatProgress,
  }
});

export const PROJECT_MASTERFORMAT_PROGRESS_LOADED = "project_masterformat_progress_loaded";

export default projectMasterformatProgressLoaded;
