import { AvvirErrorEvent } from "type_aliases";

export type CouldNotSignInEvent = AvvirErrorEvent<typeof COULD_NOT_SIGN_IN, Error>

const couldNotSignIn = (error: Error): CouldNotSignInEvent => ({
  type: COULD_NOT_SIGN_IN,
  payload: {
    error
  }
});

export const COULD_NOT_SIGN_IN = "could_not_sign_in";

export default couldNotSignIn;
