import { createSelector } from "reselect";
import _ from "underscore";

import getFloorId from "../floor_getters/get_floor_id";
import getGroups from "./get_groups";
import getProps from "../base_getters/get_props";
import { GetPropsType } from "type_aliases";

type Selectors = [
  typeof getGroups,
  typeof getFloorId,
  GetPropsType<{ groupIds?: number[], groupId?: number }>]
const getGroupIdsForFloor = createSelector<Selectors, number[]>([
  getGroups,
  getFloorId,
  getProps
], (groups, floorId, props) => {
  if (props.groupIds) {
    return props.groupIds;
  } else if (props.groupId) {
    return [props.groupId];
  } else {
    return _.chain(groups.byId)
      .where({ floorId })
      .pluck("id")
      .value();
  }
});

export default getGroupIdsForFloor;
