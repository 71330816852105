import { Action } from "redux";

export type InPlaceElementsHiddenEvent = Action<typeof IN_PLACE_ELEMENTS_HIDDEN>

const inPlaceElementsHidden = (): InPlaceElementsHiddenEvent => ({
  type: IN_PLACE_ELEMENTS_HIDDEN,
});

export const IN_PLACE_ELEMENTS_HIDDEN = "in_place_elements_hidden";

export default inPlaceElementsHidden;