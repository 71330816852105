import { AssociationIds, AvvirEvent } from "type_aliases";

export type PushedAllToBimEvent = AvvirEvent<typeof PUSHED_ALL_TO_BIM, {
  floorId: string
  scanDatasetId: string
  deviations: { globalId: string }[]
}>

const pushedAllToBim = ({ floorId, scanDatasetId }: AssociationIds, deviations: { globalId: string } []): PushedAllToBimEvent => ({
  type: PUSHED_ALL_TO_BIM,
  payload: {
    floorId,
    scanDatasetId,
    deviations
  }
});

export const PUSHED_ALL_TO_BIM = "push_all_to_bim";

export default pushedAllToBim;
