import { AssociationIds, AvvirEvent } from "type_aliases";

export type PushedToBimEvent = AvvirEvent<typeof PUSHED_TO_BIM, {
  floorId: string
  scanDatasetId: string
  globalId: string
  isFixedDeviation: boolean
}>

const pushedToBim = ({ floorId, scanDatasetId, globalId }: AssociationIds, isFixedDeviation): PushedToBimEvent => ({
  type: PUSHED_TO_BIM,
  payload: {
    floorId,
    scanDatasetId,
    globalId,
    isFixedDeviation
  }
});

export const PUSHED_TO_BIM = "push_to_bim";

export default pushedToBim;
