import type { Action } from "redux";

export type BimColorsDialogShownEvent = Action<typeof BIM_COLORS_DIALOG_SHOWN>

const bimColorsDialogShown = (): BimColorsDialogShownEvent => ({
  type: BIM_COLORS_DIALOG_SHOWN
});

export const BIM_COLORS_DIALOG_SHOWN = "bim_colors_dialog_shown";

export default bimColorsDialogShown;
