import _ from "underscore";

import { ApiFloorPurposeType, ApiPhotoAreaPurposeType, ApiProjectPurposeType, ApiPurposeType, ApiScanDatasetPurposeType } from "avvir";
import PurposeTypeMap, { FloorPurposeType, PhotoAreaPurposeType, ProjectPurposeType, PurposeType, ScanDatasetPurposeType } from "../../models/domain/enums/purpose_type";

const apiPurposeTypeByPurposeType = _.invert(PurposeTypeMap);

const purposeTypeByApiPurposeType = PurposeTypeMap;

export default class PurposeTypeConverter {
  static toApiPurposeType(fileKey: ProjectPurposeType): ApiProjectPurposeType;
  static toApiPurposeType(fileKey: FloorPurposeType): ApiFloorPurposeType;
  static toApiPurposeType(fileKey: ScanDatasetPurposeType): ApiScanDatasetPurposeType;
  static toApiPurposeType(fileKey: PhotoAreaPurposeType): ApiPhotoAreaPurposeType;
  static toApiPurposeType(fileKey: PurposeType): ApiPurposeType;
  static toApiPurposeType(fileKey: PurposeType): ApiPurposeType {
    return apiPurposeTypeByPurposeType[fileKey];
  }

  static toPurposeType(apiPurposeType: ApiProjectPurposeType): ProjectPurposeType;
  static toPurposeType(apiPurposeType: ApiFloorPurposeType): FloorPurposeType;
  static toPurposeType(apiPurposeType: ApiScanDatasetPurposeType): ScanDatasetPurposeType;
  static toPurposeType(apiPurposeType: ApiPhotoAreaPurposeType): PhotoAreaPurposeType;
  static toPurposeType(apiPurposeType: ApiPurposeType): PurposeType;
  static toPurposeType(apiPurposeType: ApiPurposeType): PurposeType {
    return purposeTypeByApiPurposeType[apiPurposeType];
  }
}
