import { AvvirEvent, ByFirebaseId } from "type_aliases";

export interface TradeMetric {
  built: number
  deviated: number
}

export interface AreaMetric {
  clashes: number
  deviations: number
}

export type TradeMetricMap = {[key: string]: TradeMetric};

export interface QualityMetrics {
  byArea: ByFirebaseId<AreaMetric>
  byTrade: TradeMetricMap
}

export type QualityMetricsLoaded = AvvirEvent<typeof QUALITY_METRICS_LOADED, {
  projectId: string,
  metrics: QualityMetrics
}>

const qualityMetricsLoaded = (projectId: string, metrics: QualityMetrics): QualityMetricsLoaded => ({
  type: QUALITY_METRICS_LOADED,
  payload: { projectId, metrics }
});

export const QUALITY_METRICS_LOADED = "quality-metrics-loaded";

export default qualityMetricsLoaded;
