import { Action } from "redux";

export type BimColorsDialogDismissedEvent = Action<typeof BIM_COLORS_DIALOG_DISMISSED>

const bimColorsDialogDismissed = (): BimColorsDialogDismissedEvent => ({
  type: BIM_COLORS_DIALOG_DISMISSED
});

export const BIM_COLORS_DIALOG_DISMISSED = "bim_colors_dialog_dismissed";

export default bimColorsDialogDismissed;
