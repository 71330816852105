import { createSelector } from "reselect";

import getOrganizationIdForCurrentProject from "../project_getters/get_organization_id_for_current_project";
import getOrganizationIdFromLocation from "../location_metadata/get_organization_id_from_location";
import getProps from "../base_getters/get_props";
import type { GetPropsType } from "type_aliases";

type Selectors = [typeof getOrganizationIdFromLocation, typeof getOrganizationIdForCurrentProject, GetPropsType<{ organizationId: string }>]
const getOrganizationId = createSelector<Selectors, string>([
  getOrganizationIdFromLocation,
  getOrganizationIdForCurrentProject,
  getProps
], (locationOrganizationId, projectOrganizationId, props) => {
  if (props.organizationId) {
    return props.organizationId || null;
  } else if (locationOrganizationId) {
    return locationOrganizationId || null;
  } else {
    return projectOrganizationId || null;
  }
});

export default getOrganizationId;
