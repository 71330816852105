import React, { FunctionComponent, useCallback, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Typography from "@mui/material/Typography";


import "../../../css/dialogs/export_deviations_ifc_dialog.css";
import useHandleFieldChange from "../../services/component_helpers/component_effects/form_helpers/use_handle_field_change";

export type DeviationsIfcType = "all_deviations" | "current_deviations"

export type Props = {
  open: boolean
  onClose: () => void
  onClickOk: (deviationsIfcType: DeviationsIfcType) => void
}

const ExportDeviationsIfcDialog: FunctionComponent<Props> = (props) => {
  const { open, onClose, onClickOk } = props;
  const [deviationsIfcType, setDeviationsIfcType] = useState<DeviationsIfcType>("all_deviations");

  const handleDeviationsIfcTypeChange = useHandleFieldChange(setDeviationsIfcType);

  const handleClickOk = useCallback(() => {
    onClickOk(deviationsIfcType);
  }, [deviationsIfcType, onClickOk]);

  return <Dialog open={open} onClose={onClose} aria-label="Export Deviations Dialog" className="ExportIfcDialog">
    <DialogTitle className="ExportIfcDialog-title"><Typography variant="body2" component="span">Export Deviations IFC</Typography></DialogTitle>
    <DialogContent className="ExportIfcDialog-content">
      <DialogContentText className="ExportIfcDialog-description">
        Deviations over 3 inches will be in this file. Would you like to export deviations for all scans or just the current scan?
      </DialogContentText>
      <FormControl component="fieldset" variant="standard">
        <RadioGroup aria-label="deviationsIfcType" name="deviationsIfcType" value={deviationsIfcType} onChange={handleDeviationsIfcTypeChange}>
          <FormControlLabel
            classes={{
              label: "ExportDeviationsIfcDialog-optionLabel"
            }}
            className="ExportDeviationsIfc-option"
            value="all_deviations"
            control={<Radio color="primary"/>}
            label="Export deviations for all scans"
          />
          <FormControlLabel
            classes={{
              label: "ExportDeviationsIfcDialog-optionLabel"
            }}
            className="ExportDeviationsIfc-option"
            value="current_deviations"
            control={<Radio color="primary"/>}
            label="Only export deviations for current scan"
          />
        </RadioGroup>
      </FormControl>
    </DialogContent>
    <DialogActions className="ExportDeviationsIfc-actions">
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleClickOk} className="beginExportDeviationsIfc" color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>;
};

export default ExportDeviationsIfcDialog;
