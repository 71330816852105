import { AvvirEvent } from "type_aliases";

export type ProjectCostAnalysisProgressReportedQuantityChangedEvent = AvvirEvent<typeof PROJECT_COST_ANALYSIS_PROGRESS_REPORTED_QUANTITY_CHANGED, { projectId: string, costAnalysisProgressId: number, value: number }>

const projectCostAnalysisProgressReportedQuantityChanged = (projectId: string, costAnalysisProgressId: number, value: number): ProjectCostAnalysisProgressReportedQuantityChangedEvent => ({
  type: PROJECT_COST_ANALYSIS_PROGRESS_REPORTED_QUANTITY_CHANGED,
  payload: {
    projectId,
    costAnalysisProgressId,
    value
  }
});

export const PROJECT_COST_ANALYSIS_PROGRESS_REPORTED_QUANTITY_CHANGED = "project_cost_analysis_progress_reported_quantity_changed";

export default projectCostAnalysisProgressReportedQuantityChanged;
