import PageNames from "../../models/domain/enums/page_names";
import toProgress4dReport from "../../events/routing/to_progress_4d_report";
import toProgress4dReportForArea from "../../events/routing/to_progress_4d_report_for_area";
import _ from "underscore";

export default function makeToProgress4dReportEvent(projectId: string, floorId: string, embedded?: boolean, pageNames?: Array<PageNames>) {
  const pageName = pageNames &&
                   _(pageNames).includes(PageNames.PROJECT_SUMMARY) ? PageNames.PROJECT_SUMMARY : PageNames.PROJECT_REPORTS
  if (floorId) {
    return toProgress4dReportForArea({
      projectId,
      floorId,
      embedded: embedded || false,
      pageName
    })
  }
  return toProgress4dReport({
    projectId,
    embedded: embedded || false,
    pageName
  })
}