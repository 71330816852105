import { AvvirEvent } from "type_aliases";

export type ShowUnclassifiedElementsUpdatedEvent = AvvirEvent<typeof SHOW_UNCLASSIFIED_ELEMENTS_UPDATED, boolean>

const showUnclassifiedElementsUpdated = (selectedState: boolean): ShowUnclassifiedElementsUpdatedEvent => ({
  type: SHOW_UNCLASSIFIED_ELEMENTS_UPDATED,
    payload: selectedState
})

export const SHOW_UNCLASSIFIED_ELEMENTS_UPDATED = "show_unclassified_elements_updated";

export default showUnclassifiedElementsUpdated;