import { createSelector } from "reselect";

import getQueryParams from "./get_query_params";
import { convertToInt } from "../../utilities/general";

const getPhotoLocationIdFromLocation = createSelector([
  getQueryParams
], (queryParams) => convertToInt(queryParams?.photoLocationId));

export default getPhotoLocationIdFromLocation;
