import _ from "underscore";

import makeUpdateModel, { ModelFactory } from "./make_update_model";
import ProjectAreaWorkPackage, { ProjectAreaWorkPackageArgument } from "../../../models/domain/project_summary/project_area_work_package";
import { PROJECT_AREA_LOADED, ProjectAreaLoadedEvent } from "../../../events/loaded/project_summary/project_area_loaded";
import { PROJECT_AREA_WORK_PACKAGES_FOR_PROJECT_LOADED, ProjectAreaWorkPackagesForProjectLoadedEvent } from "../../../events/loaded/project_summary/project_area_work_packages_for_project_loaded";
import { PROJECT_AREA_WORK_PACKAGES_LOADED, ProjectAreaWorkPackagesLoadedEvent } from "../../../events/loaded/project_summary/project_area_work_packages_loaded";
import { PROJECT_AREA_WORK_PACKAGES_UPDATED, ProjectAreaWorkPackagesUpdatedEvent } from "../../../events/project_summary/project_area_work_packages_updated";
import { PROJECT_AREAS_LOADED, ProjectAreasLoadedEvent } from "../../../events/loaded/project_summary/project_areas_loaded";

import type { ByDatabaseId } from "type_aliases";
import type { Reducer } from "redux";

type ProjectAreaWorkPackagesEvents =
  | ProjectAreaWorkPackagesLoadedEvent
  | ProjectAreaWorkPackagesUpdatedEvent
  | ProjectAreaWorkPackagesForProjectLoadedEvent
  | ProjectAreasLoadedEvent
  | ProjectAreaLoadedEvent;

export type ProjectAreaWorkPackagesStore = {
  byProjectAreaId: ByDatabaseId<{ byId: ByDatabaseId<ProjectAreaWorkPackage> }>
}

const updateProjectAreaWorkPackage = makeUpdateModel(new ProjectAreaWorkPackage());

const updateProjectAreaWorkPackageForProjectArea = (projectAreaWorkPackagesStore: ProjectAreaWorkPackagesStore, projectAreaId: number, projectAreaWorkPackageId: number, projectAreaWorkPackage: ModelFactory<ProjectAreaWorkPackage> | Partial<ProjectAreaWorkPackage>) => {
  return {
    ...projectAreaWorkPackagesStore,
    byProjectAreaId: {
      ...projectAreaWorkPackagesStore.byProjectAreaId,
      [projectAreaId]: updateProjectAreaWorkPackage(projectAreaWorkPackagesStore.byProjectAreaId[projectAreaId] || { byId: {} }, projectAreaWorkPackageId, projectAreaWorkPackage)
    }
  };
};

const updateProjectAreaWorkPackagesForProjectArea = (projectAreaWorkPackagesStore: ProjectAreaWorkPackagesStore, projectAreaId: number, projectAreaWorkPackages: (ProjectAreaWorkPackageArgument & { id: number })[]) => {
  return _(projectAreaWorkPackages).reduce((projectAreaWorkPackagesSoFar, projectAreaWorkPackage) => {
    return updateProjectAreaWorkPackageForProjectArea(projectAreaWorkPackagesSoFar, projectAreaId, projectAreaWorkPackage.id, new ProjectAreaWorkPackage(projectAreaWorkPackage));
  }, projectAreaWorkPackagesStore);
};

const reduceProjectAreaWorkPackages: Reducer<ProjectAreaWorkPackagesStore, ProjectAreaWorkPackagesEvents> = (projectAreaWorkPackages: ProjectAreaWorkPackagesStore = { byProjectAreaId: {} }, event) => {
  if (!projectAreaWorkPackages.byProjectAreaId) {
    projectAreaWorkPackages.byProjectAreaId = {};
  }
  switch (event.type) {
    case PROJECT_AREA_WORK_PACKAGES_LOADED: {
      return updateProjectAreaWorkPackagesForProjectArea(projectAreaWorkPackages, event.payload.projectAreaId, event.payload.projectAreaWorkPackages);
    }
    case PROJECT_AREA_WORK_PACKAGES_UPDATED: {
      return updateProjectAreaWorkPackagesForProjectArea(projectAreaWorkPackages, event.payload.projectAreaId, event.payload.projectAreaWorkPackages);
    }
    case PROJECT_AREA_WORK_PACKAGES_FOR_PROJECT_LOADED: {
      return _(event.payload.projectAreaWorkPackages).chain()
        .groupBy("projectAreaId")
        .reduce((projectAreaWorkPackagesSoFar, apiProjectAreaWorkPackages, projectAreaId) => {
          return updateProjectAreaWorkPackagesForProjectArea(projectAreaWorkPackagesSoFar, projectAreaId, apiProjectAreaWorkPackages)
        }, projectAreaWorkPackages)
        .value();
    }
    case PROJECT_AREAS_LOADED: {
      return _(event.payload.projectAreas).reduce((projectAreaWorkPackagesSoFar, projectArea) => {
        return updateProjectAreaWorkPackagesForProjectArea(projectAreaWorkPackagesSoFar, projectArea.id, projectArea.workPackages);
      }, projectAreaWorkPackages);
    }
    case PROJECT_AREA_LOADED: {
      return updateProjectAreaWorkPackagesForProjectArea(projectAreaWorkPackages, event.payload.projectArea.id, event.payload.projectArea.workPackages);
    }
    default: {
      return projectAreaWorkPackages;
    }
  }
};

export default reduceProjectAreaWorkPackages;

