import { AvvirEvent } from "type_aliases";
import { ApiGroup } from "avvir";

export type GroupCreatedEvent = AvvirEvent<typeof GROUP_CREATED, { groupId: number, group: ApiGroup }>;

const groupCreated = (groupId: number, group: ApiGroup): GroupCreatedEvent => ({
  type: GROUP_CREATED,
  payload: {
    groupId,
    group
  }
});

export const GROUP_CREATED = "group_created";

export default groupCreated;
