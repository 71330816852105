import type ApiPlannedTradeCost from "../../../models/api/api_planned_trade_cost";
import type { AvvirEvent } from "type_aliases";

export type PlannedTradeCostsLoadedEvent = AvvirEvent<typeof PLANNED_TRADE_COSTS_LOADED, { projectId: string, tradeCosts: ApiPlannedTradeCost[] }>

const plannedTradeCostsLoaded = (projectId: string, tradeCosts: ApiPlannedTradeCost[]): PlannedTradeCostsLoadedEvent => ({
  type: PLANNED_TRADE_COSTS_LOADED,
  payload: {
    projectId,
    tradeCosts
  }
});

export const PLANNED_TRADE_COSTS_LOADED = "planned_trade_costs_loaded";

export default plannedTradeCostsLoaded;
