import { ApiCloudFile } from "avvir";
import { AvvirEvent } from "type_aliases";
import { FileAssociationsPayload } from "./file_associations_updated";

export type FileAssociationsUpdatedRetainedFloorFilesEvent = AvvirEvent<typeof FILE_ASSOCIATIONS_UPDATED_RETAIN_FLOOR_FILES, FileAssociationsPayload>

const fileAssociationsUpdatedRetainFloorFiles = (projectId: string, previousFiles: ApiCloudFile[], files: ApiCloudFile[]): FileAssociationsUpdatedRetainedFloorFilesEvent => ({
  type: FILE_ASSOCIATIONS_UPDATED_RETAIN_FLOOR_FILES,
  payload: {
    projectId,
    previousFiles,
    files
  }
});

export const FILE_ASSOCIATIONS_UPDATED_RETAIN_FLOOR_FILES = "file_associations_updated_retain_floor_files";

export default fileAssociationsUpdatedRetainFloorFiles;
