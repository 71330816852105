import { Action } from "redux";

export type PhotoViewerInitializedEvent = Action<typeof PHOTO_VIEWER_INITIALIZED>

const photoViewerInitialized = (): PhotoViewerInitializedEvent => ({
  type: PHOTO_VIEWER_INITIALIZED,
});

export const PHOTO_VIEWER_INITIALIZED = "photo_viewer_initialized";

export default photoViewerInitialized;
