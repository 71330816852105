import { createSelector } from "reselect";
import _ from "underscore";

import getGroupMembersByGroupId from "./get_group_members_by_group_id";

const getGroupMemberGlobalIdsByGroupId = createSelector([
  getGroupMembersByGroupId
], (groupMembersByGroupId) => {
  return _.mapObject(groupMembersByGroupId, groupMembers => {
      return _.pluck(groupMembers, "elementId");
    });
});

export default getGroupMemberGlobalIdsByGroupId;
