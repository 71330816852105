import { AvvirEvent } from "type_aliases";
import ProjectWorkPackage from "../../../models/domain/project_work_package";

export type ProjectWorkPackagesLoadedEvent = AvvirEvent<typeof PROJECT_WORK_PACKAGES_LOADED, {workPackages: ProjectWorkPackage[], projectId: string}>

const projectWorkPackagesLoaded = (workPackages, projectId): ProjectWorkPackagesLoadedEvent => ({
  type: PROJECT_WORK_PACKAGES_LOADED,
  payload: {
    workPackages,
    projectId
  }
});

export const PROJECT_WORK_PACKAGES_LOADED = "project_work_packages_loaded";

export default projectWorkPackagesLoaded;
