import { AvvirRoutingEvent } from "type_aliases";
import PageNames from "../../models/domain/enums/page_names";

export type ToCustomReportEvent = AvvirRoutingEvent<typeof TO_CUSTOM_REPORT, {
  projectId: string
  projectReportId: number
  embedded?: boolean,
  pageName: PageNames
}>

const toCustomReport = (
  projectId: string, projectReportId: number, embedded?: boolean, pageName?: PageNames
): ToCustomReportEvent => ({
  type: TO_CUSTOM_REPORT,
  payload: {
    projectId,
    pageName: pageName || PageNames.PROJECT_REPORTS,
    projectReportId,
    embedded,
  }
});

export const TO_CUSTOM_REPORT = "to_custom_report";

export default toCustomReport;
