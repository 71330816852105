import { Action } from "redux";

export type DetectedDeviationsHiddenEvent = Action<typeof DETECTED_DEVIATIONS_HIDDEN>

const detectedDeviationsHidden = (): DetectedDeviationsHiddenEvent => ({
  type: DETECTED_DEVIATIONS_HIDDEN,
});

export const DETECTED_DEVIATIONS_HIDDEN = "detected_deviations_hidden";

export default detectedDeviationsHidden;