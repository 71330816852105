import getFloorsForProjectByFirebaseId from "../../services/getters/floor_getters/get_floors_for_project_by_firebase_id";
import loadFloorsForProject from "../../actions/react_query/floors/load_floors_for_project";
import useQueryWithState from "../use_query_with_state";

const useFloorsForProject = (projectId: string) => {
  return useQueryWithState(
    ["useFloorsForProject", projectId],
    loadFloorsForProject,
    getFloorsForProjectByFirebaseId,
    { enabled: !!projectId },
    { projectId },
    projectId);
};

export default useFloorsForProject;
