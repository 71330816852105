import { ApiCloudFile } from "avvir";
import { AvvirEvent } from "type_aliases";

export type FloorFilesForProjectLoadedEvent = AvvirEvent<typeof FLOOR_FILES_FOR_PROJECT_LOADED, { floorId: string, files: ApiCloudFile[] }[]>

const floorFilesForProjectLoaded = (files: { floorId: string, files: ApiCloudFile[] }[]): FloorFilesForProjectLoadedEvent => ({
  type: FLOOR_FILES_FOR_PROJECT_LOADED,
  payload: files
});

export const FLOOR_FILES_FOR_PROJECT_LOADED = "floor_files_for_project_loaded";

export default floorFilesForProjectLoaded;
