import type { AvvirEvent } from "type_aliases";
import type { CustomBimColors } from "../../services/utilities/bim_colors";

export type BimColorsSavedEvent = AvvirEvent<typeof BIM_COLORS_SAVED, CustomBimColors>

const bimColorsSaved = (colors: CustomBimColors): BimColorsSavedEvent => ({
  type: BIM_COLORS_SAVED,
  payload: colors
});

export const BIM_COLORS_SAVED = "bim_colors_saved";

export default bimColorsSaved;
