import { AssociationIds, AvvirEvent } from "type_aliases";
import PlannedBuildingElement from "../../models/domain/planned_building_element";

export type PushedToBimtrackEvent = AvvirEvent<typeof PUSHED_TO_BIMTRACK, { detailedElement: PlannedBuildingElement, scanDatasetId: string, floorId, globalId: string }>

const pushedToBimtrack = (detailedElement: PlannedBuildingElement, { floorId, scanDatasetId, globalId }: AssociationIds): PushedToBimtrackEvent => ({
  type: PUSHED_TO_BIMTRACK,
  payload: {
    detailedElement,
    scanDatasetId,
    floorId,
    globalId
  }
});

export const PUSHED_TO_BIMTRACK = "pushed_to_bimtrack";

export default pushedToBimtrack;
