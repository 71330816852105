import Avvir, { ApiActionPayload, UserActionType } from "avvir";

import CameraControls from "../services/camera_controls/camera_controls";
import elementActionRecorded from "../events/viewer/element_action_recorded";
import getFloorId from "../services/getters/floor_getters/get_floor_id";
import getOrganizationIdForCurrentProject from "../services/getters/project_getters/get_organization_id_for_current_project";
import getPhotoAreaId from "../services/getters/photo_area_getters/get_photo_area_id";
import getPhotoLocationIdFromLocation from "../services/getters/location_metadata/get_photo_location_id_from_location";
import getProjectId from "../services/getters/project_getters/get_project_id";
import getScanDatasetId from "../services/getters/scan_dataset_getters/get_scan_dataset_id";
import getScanDate from "../services/getters/scan_dataset_getters/get_scan_date";
import getSelectedPhotoSessionId from "../services/getters/photo_area_getters/get_selected_photo_session_id";
import getUser from "../services/getters/base_getters/get_user";
import Matrix4Converter from "../services/converters/matrix_4_converter";
import PlannedBuildingElement from "../models/domain/planned_building_element";
import { AvvirThunk } from "./make_eventful_action";

const recordUserActions = (
  type: UserActionType,
  updatedElements: PlannedBuildingElement[],
  cameraControls: CameraControls
): AvvirThunk<Promise<void>, never> => {
  return (dispatch, getState) => {

    const state = getState();
    const organizationId = getOrganizationIdForCurrentProject(state, {});
    const projectId = getProjectId(state, {});
    const floorId = getFloorId(state, {});
    const scanDatasetId = getScanDatasetId(state, {});
    const photoAreaId = getPhotoAreaId(state, {});
    const photoSessionId = getSelectedPhotoSessionId(state, {});
    const photoLocationId = getPhotoLocationIdFromLocation(state, {});
    const scanDate = getScanDate(state, {})
    const cameraOrientation = photoLocationId ? Matrix4Converter.fromMatrix4ToString(cameraControls?.camera.matrixWorld.clone()) : null;
    const user = getUser(state, {});
    const userActions: ApiActionPayload[] = updatedElements.map((element) => {
      const action: ApiActionPayload = {
        globalId: element.globalId,
        firebaseClientAccountId: organizationId,
        firebaseProjectId: projectId,
        firebaseFloorId: floorId,
        firebaseScanDatasetId: scanDatasetId,
        photoAreaId,
        photoSessionId,
        photoLocationId,
        scannedBuildingElementId: null,
        behavioralData: { cameraOrientation }
      };

      if (PlannedBuildingElement.isDeviated(element, scanDate)) {
        action.behavioralData.deviation = PlannedBuildingElement.getDeviation(element, scanDate)?.deviationVectorMeters;
      }
      return action;
    });

    return Avvir.api.other.recordUserActions(type, userActions, user).then(actions => {
      if (actions != null && actions.length === 1) {
        dispatch(elementActionRecorded(floorId, userActions[0].globalId, actions[0]));
      }
    });
  };
};

export default recordUserActions;
