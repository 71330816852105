import { ApiCloudFile } from "avvir";
import { AvvirEvent } from "type_aliases";

export type ProjectFileUploadedEvent = AvvirEvent<typeof PROJECT_FILE_UPLOADED, { projectId: string, file: ApiCloudFile }>

const projectFileUploaded = (projectId: string, apiFile: ApiCloudFile): ProjectFileUploadedEvent => ({
  type: PROJECT_FILE_UPLOADED,
  payload: {
    projectId,
    file: apiFile
  }
});

export const PROJECT_FILE_UPLOADED = "project_file_uploaded";

export default projectFileUploaded;
