import {Quaternion} from "three";
import {AvvirEvent, Vector3Like} from "type_aliases";
import {ApiPhotoLocation} from "avvir";

export type BimAndPhotoCameraLinkedEventPayload = {
  bimCameraPosition: Vector3Like,
  bimCameraQuaternion: Quaternion,
  photoCameraQuaternion: Quaternion,
  photoLocationId: number,
  isControlPoint: boolean,
  updatedLocation?: ApiPhotoLocation
}

export type BimAndPhotoCameraLinkedEvent = AvvirEvent<typeof BIM_AND_PHOTO_CAMERA_LINKED, BimAndPhotoCameraLinkedEventPayload>

const bimAndPhotoCameraLinked = (payload?: BimAndPhotoCameraLinkedEventPayload): BimAndPhotoCameraLinkedEvent => ({
  type: BIM_AND_PHOTO_CAMERA_LINKED,
  payload
});

export const BIM_AND_PHOTO_CAMERA_LINKED = "bim_and_photo_camera_linked";

export default bimAndPhotoCameraLinked;
