import { AvvirEvent } from "type_aliases";
import PanelType from "../../../models/domain/enums/panel_type";

export type CustomFailureMessageDisplayedEvent = AvvirEvent<typeof CUSTOM_FAILURE_MESSAGE_DISPLAYED, {message: string, manualClose: boolean, link?: string , panel?: PanelType}>

const customFailureMessageDisplayed = (message: string, manualClose: boolean = false, link: string = null, panel: PanelType = null): CustomFailureMessageDisplayedEvent => ({
  type: CUSTOM_FAILURE_MESSAGE_DISPLAYED,
  payload: {
    message,
    link,
    manualClose,
    panel
  }
});

export const CUSTOM_FAILURE_MESSAGE_DISPLAYED = "display_custom_failure_message";

export default customFailureMessageDisplayed;
