import { AvvirEvent, Vector3Like } from "type_aliases";
import { ApiPhotoLocation } from "avvir";
import { Quaternion } from "three";

export type MovedPhotoLocation = {
  bimLocationId: number,
  photoLocationId: number,
  position: Vector3Like,
  orientation: Quaternion,
  isControlPoint: boolean,
  updatedLocation?: ApiPhotoLocation
}

export type PhotoLocationMovedPayload = {
  locations: MovedPhotoLocation[]
}

export type PhotoLocationMovedEvent = AvvirEvent<typeof PHOTO_LOCATIONS_MOVED, PhotoLocationMovedPayload>

const photoLocationsMoved = (payload?: PhotoLocationMovedPayload): PhotoLocationMovedEvent => ({
  type: PHOTO_LOCATIONS_MOVED,
  payload
});

export const PHOTO_LOCATIONS_MOVED = "photo_locations_moved";

export default photoLocationsMoved;
