import type ProjectArea from "../../../models/domain/project_summary/project_area";
import type { AvvirEvent } from "type_aliases";

export const PROJECT_AREA_LOADED = "project_area_loaded";

export type ProjectAreaLoadedEvent = AvvirEvent<typeof PROJECT_AREA_LOADED, {
  projectArea: ProjectArea, firebaseProjectId: string, modelElementId: number
}>

const projectAreaLoaded = (projectArea: ProjectArea, firebaseProjectId: string, modelElementId: number): ProjectAreaLoadedEvent => ({
  type: PROJECT_AREA_LOADED,
  payload: { projectArea, firebaseProjectId, modelElementId }
});

export default projectAreaLoaded;
