import _ from "underscore";

import makeUpdateModel, { ModelFactory } from "./make_update_model";
import ProjectArea, { ProjectAreaArgument } from "../../../models/domain/project_summary/project_area";
import { PROJECT_AREA_LOADED, ProjectAreaLoadedEvent } from "../../../events/loaded/project_summary/project_area_loaded";
import { PROJECT_AREAS_LOADED, ProjectAreasLoadedEvent } from "../../../events/loaded/project_summary/project_areas_loaded";

import type { Reducer } from "redux";
import type { ByDatabaseId, ByFirebaseId } from "type_aliases";
import { PROJECT_AREA_CREATED, ProjectAreaCreatedEvent } from "../../../events/project_summary/project_area_created";
import { PROJECT_AREA_UPDATED, ProjectAreaUpdatedEvent } from "../../../events/project_summary/project_area_updated";

type ProjectAreasEvents =
  | ProjectAreasLoadedEvent
  | ProjectAreaLoadedEvent
  | ProjectAreaCreatedEvent
  | ProjectAreaUpdatedEvent;

const updateProjectArea = makeUpdateModel(new ProjectArea());


export type ProjectAreasStore = {
  byFirebaseProjectId: ByFirebaseId<{ byId: ByDatabaseId<ProjectArea> }>
}

const updateProjectAreaForProject = (projectAreas: ProjectAreasStore, projectId: string, projectAreaId: number, projectArea: ModelFactory<ProjectArea> | Partial<ProjectArea>) => {
  return {
    ...projectAreas,
    byFirebaseProjectId: {
      ...projectAreas.byFirebaseProjectId,
      [projectId]: updateProjectArea(projectAreas.byFirebaseProjectId[projectId] || { byId: {} }, projectAreaId, projectArea)
    }
  };
};

const updateProjectAreasForProject = (store: ProjectAreasStore, projectId: string, projectAreas: (ProjectAreaArgument & { id: number })[]) => {
  return _.reduce(projectAreas, (projectAreasSoFar, projectArea) => {
    return updateProjectAreaForProject(projectAreasSoFar, projectId, projectArea.id, new ProjectArea(projectArea));
  }, store);
};

const reduceProjectAreas: Reducer<ProjectAreasStore, ProjectAreasEvents> = (projectAreas: ProjectAreasStore = { byFirebaseProjectId: {} }, event) => {
  if (!projectAreas.byFirebaseProjectId) {
    projectAreas.byFirebaseProjectId = {};
  }
  switch (event.type) {
    case PROJECT_AREA_CREATED: {
      return updateProjectAreaForProject(projectAreas,
        event.payload.projectId,
        event.payload.projectArea.id,
        new ProjectArea(event.payload.projectArea));
    }
    case PROJECT_AREA_UPDATED: {
      return updateProjectAreaForProject(projectAreas,
        event.payload.projectId,
        event.payload.projectArea.id,
        new ProjectArea(event.payload.projectArea));
    }
    case PROJECT_AREAS_LOADED: {
      return updateProjectAreasForProject(projectAreas, event.payload.projectId, event.payload.projectAreas);
    }
    case PROJECT_AREA_LOADED: {
      return updateProjectAreaForProject(projectAreas,
        event.payload.firebaseProjectId,
        event.payload.projectArea.id,
        new ProjectArea({ ...event.payload.projectArea, modelElementId: event.payload.modelElementId }));
    }
    default: {
      return projectAreas;
    }
  }
};

export default reduceProjectAreas;

