import { immerable } from "immer";

import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import { DateLike, ModifyPartial } from "type_aliases";

type IntegrationProjectArgs = ModifyPartial<IntegrationProject, {
  createdAt: DateLike,
  modifiedAt: DateLike
}>
export default class IntegrationProject {
  constructor({
    id,
    createdAt,
    modifiedAt,
    integrationCredentialsId,
    externalId,
    externalName
  }: IntegrationProjectArgs) {
    this.id = id;
    this.integrationCredentialsId = integrationCredentialsId;
    this.externalId = externalId;
    this.externalName = externalName;
    addDateGetterAndSetterToDomainModel(this, "createdAt", createdAt);
    addDateGetterAndSetterToDomainModel(this, "modifiedAt", modifiedAt);
  }

  id?: number;
  createdAt: Date;
  modifiedAt?: Date;
  integrationCredentialsId: number;
  externalId: string;
  externalName: string;
  static [immerable] = true;
}
