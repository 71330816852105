import Avvir, { ApiPipelineArgument } from "avvir";

import checkExportedIfc from "./check_exported_ifc";
import DateConverter from "../../services/converters/date_converter";
import getProjectIdFromLocation from "../../services/getters/location_metadata/get_project_id_from_location";
import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import _ from "underscore";
import { createSelector } from "reselect";
import getFloorsByFirebaseId from "../../services/getters/floor_getters/get_floors_by_firebase_id";
import exportBatchIfcTriggered, { ExportBatchIfcTriggeredEvent } from "../../events/export_batch_ifc_triggered";
import { ExportBatchIfcProcessCheckedEvent } from "../../events/export_batch_ifc_process_checked";

type Selectors = [
  typeof getFloorsByFirebaseId
]

const getFloorValuesForProject = createSelector<Selectors, any>([getFloorsByFirebaseId], (floors) => {
  return floors;
})

const getAllSelectedFloorsForCurrentProject = (state) => {
  let floorsByFirebaseId = getFloorValuesForProject(state, {});
  return _.values(floorsByFirebaseId);
}

const exportBatchIfc = (floorIds: any[], ifcType?: string): AvvirThunk<Promise<void>, ExportBatchIfcTriggeredEvent | ExportBatchIfcProcessCheckedEvent> => {
  return (dispatch, getState) => {
    const state = getState();
    const firebaseProjectId = getProjectIdFromLocation(state, {});

    let floors = getAllSelectedFloorsForCurrentProject(state);
    let obj: any = [];
    floors.forEach(floor => {
      if(floorIds.includes(floor.firebaseId)) {
        let instance: {firebaseFloorId: string, firebaseScanDatasetId: string} = {firebaseFloorId: floor.firebaseId, firebaseScanDatasetId: floor.defaultFirebaseScanDatasetId};
        obj.push(instance);
      }
    })

    const associationIds = { projectId: firebaseProjectId, floorScanDatasetIds: obj };
    const user = state.user;

    let payload: ApiPipelineArgument = {
      name: "generate-batch-ifc",
      firebaseProjectId: firebaseProjectId,
      options: {
        type: ifcType,
        pipelineBatchFloors: JSON.stringify(obj)
      }
    }

    return Avvir.api.pipelines.triggerPipeline(payload, user)
      .then((pipeline) => {
        dispatch(exportBatchIfcTriggered(associationIds, ifcType, pipeline.externalId, DateConverter.instantToDate(pipeline.startTime)));
        setTimeout(() => {
          dispatch(checkExportedIfc(associationIds, pipeline.id, ifcType, "batch_download"));
        }, 5000);
      });
  };
};

export default makeEventfulAction("exportBatchIfc", exportBatchIfc);