import type React from "react";
import type { Action, RoutesMap } from "redux-first-router";
import { getOptions, pathToAction, redirect } from "redux-first-router";

import type { To } from "./toUrl";

export type OnClick =
  | false
  | ((arg0: React.MouseEvent) => boolean | null | undefined)
const handlePress = (
  url: string,
  routesMap: RoutesMap,
  onClick: OnClick | null | undefined,
  shouldDispatch: boolean,
  target: string | null | undefined,
  dispatch: (...args: Array<any>) => any,
  to: To | null | undefined,
  dispatchRedirect: boolean,
  e: React.MouseEvent
) => {
  let shouldGo = true;

  if (onClick) {
    shouldGo = onClick(e); // onClick can return false to prevent dispatch

    shouldGo = typeof shouldGo === "undefined" ? true : shouldGo;
  }

  const prevented = e.defaultPrevented;

  if (e.button !== 1 && !target && e && e.preventDefault && !isModified(e)) {
    e.preventDefault();
  }

  if (
    shouldGo &&
    shouldDispatch &&
    !target &&
    !prevented &&
    e.button === 0 &&
    !isModified(e)
  ) {
    const { querySerializer: serializer } = getOptions();
    let action: Action;

    if (isAction(to)) {
      action = dispatchRedirect ? redirect(to) : to;
    } else {
      url =
        url.indexOf("#") > -1
        ? url.substring(url.indexOf("#") + 1, url.length)
        : url;
      action = pathToAction(url, routesMap, serializer) as unknown as Action;
      action = dispatchRedirect ? redirect(action) : action;
    }

    dispatch(action);
  }
}

const isAction = (to: To | null | undefined): to is Action =>
  typeof to === "object" && !Array.isArray(to);

const isModified = (e: Record<string, any>) =>
  !!(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey);

export default handlePress;
