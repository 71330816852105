import { immerable } from "immer";

import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";

export default class Organization {
  constructor({
    id,
    addressLine1,
    addressLine2,
    addressCity,
    firebaseId,
    contactEmail,
    contactFirstName,
    contactLastName,
    contactPhoneNumber,
    addressCountry,
    firebaseProjectIds,
    name,
    notes,
    addressState,
    addressZip,
    invitation,
    multivistaEmail,
    multivistaPassword
  }: Partial<Organization> = {}) {
    addReadOnlyPropertiesToModel(this, { id, firebaseId });

    this.addressCity = addressCity || "";
    this.addressCountry = addressCountry || "United States of America (USA)";
    this.addressLine1 = addressLine1 || "";
    this.addressLine2 = addressLine2 || "";
    this.addressState = addressState || "";
    this.addressZip = addressZip || "";
    this.contactEmail = contactEmail || "";
    this.contactFirstName = contactFirstName || "";
    this.contactLastName = contactLastName || "";
    this.contactPhoneNumber = contactPhoneNumber || "";
    this.name = name || "";
    this.notes = notes || "";
    this.firebaseProjectIds = firebaseProjectIds || [];
    if (invitation?.link) {
      this.invitation = { link: invitation.link };
    }
    this.multivistaEmail = multivistaEmail || "";
    this.multivistaPassword = multivistaPassword || "";
  }

  readonly firebaseId: string;
  readonly id?: number;
  addressCity: string = "";
  addressCountry: string = "United States of America (USA)";
  addressLine1: string = "";
  addressLine2: string = "";
  addressState: string = "";
  addressZip: string = "";
  contactEmail: string = "";
  contactFirstName: string = "";
  contactLastName: string = "";
  contactPhoneNumber: string = "";
  name: string = "";
  notes: string = "";
  firebaseProjectIds: string[];
  invitation?: { link?: string };
  multivistaEmail: string = "";
  multivistaPassword: string = "";

  static readonly [immerable] = true;
}
