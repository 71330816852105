import { combineReducers } from "redux";

import reduceBimtrack, { BimtrackStore } from "./reduce_bimtrack";
import reduceCommentThreads, { CommentThreadsStore } from "./reduce_comment_threads";
import reduceFiles, { FilesStore } from "./reduce_files";
import reduceFloors, { FloorsStore } from "./reduce_floors";
import reduceGroupMembers, { GroupMembersStore } from "./reduce_group_members";
import reduceGroups, { GroupsStore } from "./reduce_groups";
import reduceInspectReports, { InspectReportsStore } from "./reduce_inspect_reports";
import reduceIntegrationCredentials, { IntegrationCredentialsStore } from "./reduce_integration_credentials";
import reduceIntegrationProjects, { IntegrationProjectStore } from "./reduce_integration_projects";
import reduceMasterformats, { MasterformatsStore } from "./reduce_masterformats";
import reduceMasterformatTree, { MasterformatTreeStore } from "./reduce_masterformat_classes";
import reduceOrganizations, { OrganizationsStore } from "./reduce_organizations";
import reduceOrganizationUsers, { OrganizationUserStore } from "./reduce_organization_users";
import reducePhotoAreas, { PhotoAreasStore } from "./reduce_photo_areas";
import reducePhotoLocations, { PhotoLocationsStore } from "./reduce_photo_locations";
import reducePhotoSessions, { PhotoSessionsStore } from "./reduce_photo_sessions";
import reduceProjectAreas, { ProjectAreasStore } from "./reduce_project_areas";
import reduceProjectAreaWorkPackages, { ProjectAreaWorkPackagesStore } from "./reduce_project_area_work_packages";
import reduceProjects, { ProjectsStore } from "./reduce_projects";
import reduceProjectSummary, { ProjectSummaryStore } from "./reduce_project_summary";
import reduceRecipes, { RecipesStore } from "./reduce_recipes";
import reduceScanDatasets, { ScanDatasetsStore } from "./reduce_scan_datasets";
import reduceTradeCosts, { TradeCostsStore } from "./reduce_trade_costs";
import reduceTrades, { TradesStore } from "./reduce_trades";
import reduceWorkPackages, { WorkPackagesStore } from "./reduce_work_packages";
import reducePlannedBuildingElements, { PlannedBuildingElementsStore } from "./reduce_planned_building_elements";

export interface DomainStore {
  commentThreads: CommentThreadsStore
  organizations: OrganizationsStore
  projects: ProjectsStore
  floors: FloorsStore
  integrationCredentials: IntegrationCredentialsStore
  integrationProjects: IntegrationProjectStore
  scanDatasets: ScanDatasetsStore
  photoAreas: PhotoAreasStore
  photoSessions: PhotoSessionsStore
  photoLocations: PhotoLocationsStore
  plannedBuildingElements: PlannedBuildingElementsStore
  files: FilesStore
  masterformats: MasterformatsStore
  bimtrack: BimtrackStore
  masterformatTree: MasterformatTreeStore
  projectSummary: ProjectSummaryStore
  projectAreas: ProjectAreasStore
  projectAreaWorkPackages: ProjectAreaWorkPackagesStore
  organizationUsers: OrganizationUserStore
  groups: GroupsStore
  groupMembers: GroupMembersStore
  recipes: RecipesStore
  inspectReports: InspectReportsStore
  trades: TradesStore
  tradeCosts: TradeCostsStore
  workPackages: WorkPackagesStore
}

const domainReducer = combineReducers({
  commentThreads: reduceCommentThreads,
  organizations: reduceOrganizations,
  projects: reduceProjects,
  floors: reduceFloors,
  integrationCredentials: reduceIntegrationCredentials,
  integrationProjects: reduceIntegrationProjects,
  scanDatasets: reduceScanDatasets,
  photoAreas: reducePhotoAreas,
  photoSessions: reducePhotoSessions,
  photoLocations: reducePhotoLocations,
  plannedBuildingElements: reducePlannedBuildingElements,
  files: reduceFiles,
  masterformats: reduceMasterformats,
  bimtrack: reduceBimtrack,
  masterformatTree: reduceMasterformatTree,
  projectSummary: reduceProjectSummary,
  projectAreas: reduceProjectAreas,
  projectAreaWorkPackages: reduceProjectAreaWorkPackages,
  organizationUsers: reduceOrganizationUsers,
  groups: reduceGroups,
  groupMembers: reduceGroupMembers,
  recipes: reduceRecipes,
  inspectReports: reduceInspectReports,
  trades: reduceTrades,
  tradeCosts: reduceTradeCosts,
  workPackages: reduceWorkPackages,
});

export default domainReducer;
