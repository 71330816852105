import Mousetrap from "mousetrap";

import createPlannedBuildingElementGroup from "../../actions/groups/create_planned_building_element_group";
import CreatePlannedBuildingElementGroup from "../../actions/groups/create_planned_building_element_group";
import detectedDeviationsHidden, { DetectedDeviationsHiddenEvent } from "../../events/viewer/status_filter_events/detected_deviations_hidden";
import detectedDeviationsShown, { DetectedDeviationsShownEvent } from "../../events/viewer/status_filter_events/detected_deviations_shown";
import detectedObstructingElementsHidden, { DetectedObstructingElementsHiddenEvent } from "../../events/viewer/status_filter_events/detected_obstructing_elements_hidden";
import detectedObstructingElementsShown, { DetectedObstructingElementsShownEvent } from "../../events/viewer/status_filter_events/detected_obstructing_elements_shown";
import deviationEditingModeToggled, { DeviationEditingModeToggledEvent } from "../../events/selection/deviation_editing_mode_toggled";
import type { CustomFailureMessageDisplayedEvent } from "../../events/notifications/failures/custom_failure_message_displayed";
import customFailureMessageDisplayed from "../../events/notifications/failures/custom_failure_message_displayed";
import elementDeselected, { ElementDeselectedEvent } from "../../events/viewer/element_deselected";
import getDetectedClashesShown from "../getters/filter_getters/get_detected_obstructing_elements_shown";
import getDetectedDeviationsShown from "../getters/filter_getters/get_detected_deviations_shown";
import getInPlaceElementsShown from "../getters/filter_getters/get_in_place_elements_shown";
import getNotBuiltElementsShown from "../getters/filter_getters/get_not_built_elements_shown";
import getSelectedElements from "../getters/planned_building_element_getters/element_selection_getters/get_selected_elements";
import getSelectedUnIncludedElements from "../getters/planned_building_element_getters/element_selection_getters/get_selected_unincluded_elements";
import inPlaceElementsHidden, { InPlaceElementsHiddenEvent } from "../../events/viewer/status_filter_events/in_place_elements_hidden";
import inPlaceElementsShown, { InPlaceElementsShownEvent } from "../../events/viewer/status_filter_events/in_place_elements_shown";
import isGroupingAllowed from "../../actions/groups/is_grouping_allowed";
import notBuiltElementsHidden, { NotBuiltElementsHiddenEvent } from "../../events/viewer/status_filter_events/not_built_elements_hidden";
import notBuiltElementsShown, { NotBuiltElementsShownEvent } from "../../events/viewer/status_filter_events/not_built_elements_shown";
import obstructionMarkingStarted, { ObstructionMarkingStartedEvent } from "../../events/selection/obstruction_marking_started";
import PlannedBuildingElement from "../../models/domain/planned_building_element";
import pushAllToBim from "../../actions/viewer_page/annotations/annotation/push_all_to_bim";
import redoRequested, { RedoRequestedEvent } from "../../events/undo/redo_requested";
import setElementsVerified from "../../actions/viewer_page/viewer/set_elements_verified";
import undoRequested, { UndoRequestedEvent } from "../../events/undo/undo_requested";
import updateElementsStatus from "../../actions/viewer_page/update_elements_status";
import { DEVIATED, IN_PLACE, NOT_BUILT } from "../../models/domain/enums/scan_label";

import type { Dispatch, GetState } from "type_aliases";
import type { GroupCreatedEvent } from "../../events/groups/group_created";
import getScanDate from "../getters/scan_dataset_getters/get_scan_date";
import updateSelectedElementsExclude from "../../actions/viewer_page/update_selected_elements_exclude";
import getUserRole from "../getters/user_getters/get_user_role";
import { UserRole } from "avvir";

type DispatchedEvents =
  | ReturnType<typeof updateElementsStatus>
  | ReturnType<typeof deviationEditingModeToggled>
  | ElementDeselectedEvent
  | UndoRequestedEvent
  | RedoRequestedEvent
  | ReturnType<typeof setElementsVerified>
  | InPlaceElementsHiddenEvent
  | InPlaceElementsShownEvent
  | NotBuiltElementsHiddenEvent
  | NotBuiltElementsShownEvent
  | DetectedDeviationsHiddenEvent
  | DetectedDeviationsShownEvent
  | DetectedObstructingElementsHiddenEvent
  | DetectedObstructingElementsShownEvent
  | ObstructionMarkingStartedEvent
  | DeviationEditingModeToggledEvent
  | CustomFailureMessageDisplayedEvent
  | GroupCreatedEvent;

export default class KeyboardShortcutManager {
  constructor(dispatch, getState, featureFlags: any) {
    this.dispatch = dispatch;
    this.getState = getState;
    this.updateElementsStatus = updateElementsStatus;
    this.deviationEditingModeToggled = deviationEditingModeToggled;
    this.updateSelectedElementsExclude = updateSelectedElementsExclude;
    this.setElementsVerified = setElementsVerified;
    this.featureFlags = featureFlags;
    this.createPlannedBuildingElementGroup = createPlannedBuildingElementGroup;
  }

  initializeForgeViewerShortcuts = () => {
    if (typeof window.Avvir.elementSelectionManager === "undefined") {
      console.log("waiting to initialize ForgeViewerShortcuts");
      setTimeout(this.initializeForgeViewerShortcuts, 200);
      return;
    }

    console.log("initializing ForgeViewerShortcuts");
    // Mark selected element(s) as a particular status
    Mousetrap.bind("b", () => { this.dispatch(this.updateElementsStatus(IN_PLACE, window.Avvir.elementSelectionManager.selectedElements)); });
    Mousetrap.bind("v", () => {
      if (this.featureFlags.editDeviationVectorButton) {
        this.dispatch(this.deviationEditingModeToggled());
      } else {
        this.dispatch(this.updateElementsStatus(DEVIATED, window.Avvir.elementSelectionManager.selectedElements));
      }
    });
    Mousetrap.bind("n", () => { this.dispatch(this.updateElementsStatus(NOT_BUILT, window.Avvir.elementSelectionManager.selectedElements)); });
    Mousetrap.bind("c", () => {
      const selectedElements = getSelectedElements(this.getState(), {});
      const scanLabel = PlannedBuildingElement.getScanLabel(selectedElements[0], getScanDate(this.getState(), {}));
      if (selectedElements.length === 1 && scanLabel !== NOT_BUILT) {
        this.dispatch(this.updateElementsStatus(DEVIATED, window.Avvir.elementSelectionManager.selectedElements)).then(() => {
          this.dispatch(obstructionMarkingStarted(selectedElements[0].globalId));
        });
      }
    });
    // Create group of selected elements
    Mousetrap.bind("g", () => {
      if (this.featureFlags.groupsPanel) {
        this.dispatch(isGroupingAllowed()).then(() => {
          this.dispatch(this.createPlannedBuildingElementGroup(window.Avvir.elementSelectionManager.selectedElements));
        }).catch((error) => {
          this.dispatch(customFailureMessageDisplayed(error.message));
          return;
        });
      }
    });
    Mousetrap.bind("i", this.toggleElementsExcluded);
    // Toggle visibility of elements by status (same as clicking filter chips)
    Mousetrap.bind("shift+b", this.toggleInPlaceElements);
    Mousetrap.bind("shift+v", this.toggleDetectedDeviations);
    Mousetrap.bind("shift+n", this.toggleNotBuiltElements);
    Mousetrap.bind("shift+c", this.toggleDetectedClashes);
    // Mark selected element(s) as verified
    Mousetrap.bind("q", this.toggleElementsVerified);
    // Undo
    Mousetrap.bind("mod+z", () => { this.dispatch(undoRequested()); });
    Mousetrap.bind("mod+shift+z", () => { this.dispatch(redoRequested()); });
    // Clear selected elements
    Mousetrap.bind("esc", () => { this.dispatch(elementDeselected()); });
    // Mark selected element(s) as "Pushed to BIM"
    Mousetrap.bind("p", this.pushAllElementsToBim);
    // Hide selected element(s) (independent of filter chips)
    Mousetrap.bind("x", window.Avvir.elementSelectionManager.hideElements);
    // Un-hide any currently hidden elements (filter chip filtering still applies)
    Mousetrap.bind("shift+x", window.Avvir.elementSelectionManager.showAll);
  };

  removeForgeViewerShortcuts = () => {
    console.log("removing ForgeViewerShortcuts");
    Mousetrap.unbind("b");
    Mousetrap.unbind("v");
    Mousetrap.unbind("n");
    Mousetrap.unbind("c");
    Mousetrap.unbind("i");
    Mousetrap.unbind("shift+b");
    Mousetrap.unbind("shift+v");
    Mousetrap.unbind("shift+n");
    Mousetrap.unbind("shift+c");
    Mousetrap.unbind("q");
    Mousetrap.unbind(["z", "mod+z"]);
    Mousetrap.unbind(["z", "mod+shift+z"]);
    Mousetrap.unbind("esc");
    Mousetrap.unbind("p");
    Mousetrap.unbind("x");
    Mousetrap.unbind("shift+x");
    Mousetrap.unbind("g");
  };

  initializeProjectSummaryViewerShortcuts = () => {
    Mousetrap.bind("esc", () => { this.dispatch(elementDeselected()); });
  };

  removeProjectSummaryViewerShortcuts = () => {
    Mousetrap.unbind("esc");
  };

  initializeViewerMinimapShortcuts = (deleteSelectedPhotosCallback: () => void) => {
    Mousetrap.bind(["backspace", "del"], deleteSelectedPhotosCallback);
  };

  removeViewerMinimapShortcuts = () => {
    Mousetrap.unbind(["backspace", "del"]);
  };

  pushAllElementsToBim = () => {
    this.dispatch(pushAllToBim(getSelectedUnIncludedElements(this.getState(), {})));
  };

  toggleElementsExcluded = () => {
    const selectedElements = getSelectedElements(this.getState(), {});
    if (selectedElements.length === 0) {
      return;
    }

    const role = getUserRole(this.getState(), {});
    if (role !== UserRole.SUPERADMIN) {
      return;
    }

    const areExcluded = selectedElements.every(element => element.excludeFromAnalysis);
    this.dispatch(this.updateSelectedElementsExclude(!areExcluded));
  };

  toggleElementsVerified = () => {
    const selectedElements = getSelectedElements(this.getState(), {});
    if (selectedElements.every(element => element.verified)) {
      this.dispatch(this.setElementsVerified(selectedElements, false));
    } else {
      this.dispatch(this.setElementsVerified(selectedElements, true));
    }
  };

  toggleInPlaceElements = () => {
    if (getInPlaceElementsShown(this.getState(), {})) {
      this.dispatch(inPlaceElementsHidden());
    } else {
      this.dispatch(inPlaceElementsShown());
    }
  };

  toggleNotBuiltElements = () => {
    if (getNotBuiltElementsShown(this.getState(), {})) {
      this.dispatch(notBuiltElementsHidden());
    } else {
      this.dispatch(notBuiltElementsShown());
    }
  };

  toggleDetectedDeviations = () => {
    if (getDetectedDeviationsShown(this.getState(), {})) {
      this.dispatch(detectedDeviationsHidden());
    } else {
      this.dispatch(detectedDeviationsShown());
    }
  };

  toggleDetectedClashes = () => {
    if (getDetectedClashesShown(this.getState(), {})) {
      this.dispatch(detectedObstructingElementsHidden());
    } else {
      this.dispatch(detectedObstructingElementsShown());
    }
  };

  dispatch: Dispatch<DispatchedEvents>;
  getState: GetState;
  updateElementsStatus: typeof updateElementsStatus;
  deviationEditingModeToggled: typeof deviationEditingModeToggled;
  setElementsVerified: typeof setElementsVerified;
  updateSelectedElementsExclude: typeof updateSelectedElementsExclude;
  createPlannedBuildingElementGroup: typeof CreatePlannedBuildingElementGroup;
  featureFlags: any;
}
