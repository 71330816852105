import { AvvirEvent } from "type_aliases";
import { ApiGroup } from "avvir";

export type GroupsLoadedEvent = AvvirEvent<typeof GROUPS_LOADED, {  groups: ApiGroup[]  }>;

const groupsLoaded = ( groups: ApiGroup[] ): GroupsLoadedEvent => ({
  type: GROUPS_LOADED,
  payload: {
    groups
  }
});

export const GROUPS_LOADED = "groups_loaded";

export default groupsLoaded;
