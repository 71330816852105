import {ApiBuiltStatus} from "avvir";

export const IN_PLACE = ApiBuiltStatus.IN_PLACE;
export const NOT_BUILT = ApiBuiltStatus.NOT_BUILT;
export const DEVIATED = ApiBuiltStatus.DEVIATED;

export enum ScanLabel {
  INSUFFICIENT_DATA = "INSUFFICIENT_DATA",
  CLASH = "CLASH",
  CLASH_WITHIN_TOLERANCE = "CLASH_WITHIN_TOLERANCE",
  DEVIATED_WITHIN_TOLERANCE = "DEVIATED_WITHIN_TOLERANCE"
}

export const DEVIATED_WITHIN_TOLERANCE = ScanLabel.DEVIATED_WITHIN_TOLERANCE;
export const CLASH = ScanLabel.CLASH;
export const CLASH_WITHIN_TOLERANCE = ScanLabel.CLASH_WITHIN_TOLERANCE;
export const INSUFFICIENT_DATA = ScanLabel.INSUFFICIENT_DATA;

export type ScanLabelValues = ApiBuiltStatus | ScanLabel;

export default ScanLabel;
