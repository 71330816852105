import _ from "underscore";
import { ApiGroup } from "avvir";

import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";
import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";

export default class Group {
  constructor(apiGroup: Partial<ApiGroup>) {
    addReadOnlyPropertiesToModel(this, {
      id: apiGroup.id,
      floorId: apiGroup.floorId
    });
    addDateGetterAndSetterToDomainModel(this, "createdAt", apiGroup.createdAt);

    this.name = apiGroup.name;
    this.createdBy = apiGroup.createdBy;
    this.members = _.pluck(apiGroup.members, "id");
  }

  readonly id: number;
  name: string;
  createdAt: Date;
  createdBy: number;
  members: number[];
  readonly floorId: string;
}
