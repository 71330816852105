import { Action } from "redux";

export type BimtrackIssueCreationDialogDismissedEvent = Action<typeof BIMTRACK_ISSUE_CREATION_DIALOG_DISMISSED>

const bimtrackIssueCreationDialogDismissed = (): BimtrackIssueCreationDialogDismissedEvent => ({
  type: BIMTRACK_ISSUE_CREATION_DIALOG_DISMISSED
});

export const BIMTRACK_ISSUE_CREATION_DIALOG_DISMISSED = "bimtrack_issue_creation_dialog_dismissed";

export default bimtrackIssueCreationDialogDismissed;
