import { ChangeEvent, useCallback } from "react";

type ChangeEventHandler<Field extends string | number | boolean> = (event: ChangeEvent<HTMLInputElement>, value?: Field) => void
type FieldSetter<Field extends string | number | boolean> = (value: Field | ((prevState: Field) => Field)) => void

/**
 * Creates an `onChange` event handler using the given setter. Optionally, passes the updated value into the `callback` every time the field changes.
 * Makes sure to read the field value from the appropriate field property based on the type of field.
 * @param setFieldValue
 * @param callback
 */
const useHandleFieldChange = <Field extends string | number | boolean>(setFieldValue: FieldSetter<Field>, callback?: (value: Field) => void): ChangeEventHandler<Field> => {
  return useCallback((event: ChangeEvent<HTMLInputElement>, fieldValue?: Field) => {
    let value: Field;
    if (fieldValue) {
      value = fieldValue;
    } else {
      switch (event.target.type) {
        case "number": {
          value = event.target.valueAsNumber as Field;
          break;
        }
        case "checkbox": {
          value = event.target.checked as Field;
          break;
        }
        default: {
          value = event.target.value as Field;
          break;
        }
      }
    }
    setFieldValue(value);
    if (callback) {
      callback(value);
    }
  }, [setFieldValue, callback]);
};

export default useHandleFieldChange;
