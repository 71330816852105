import { AvvirEvent } from "type_aliases";
import { ApiPhotoLocation } from "avvir";

export type PhotoLocationsLoadedEvent = AvvirEvent<typeof PHOTO_LOCATIONS_LOADED, ApiPhotoLocation[]>

const photoLocationsLoaded = (apiPhotoLocations: ApiPhotoLocation[]): PhotoLocationsLoadedEvent => ({
  type: PHOTO_LOCATIONS_LOADED,
  payload: apiPhotoLocations
});

export const PHOTO_LOCATIONS_LOADED = "photo_locations_loaded";

export default photoLocationsLoaded;
