import { createSelector } from "reselect";

import getUser from "../base_getters/get_user";

const getUserIsLoggedIn = createSelector([
  getUser
], (user) => {
  return user != null;
});

export default getUserIsLoggedIn;
