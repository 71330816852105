import { Reducer } from "redux";
import { NOT_BUILT_ELEMENTS_SHOWN, NotBuiltElementsShownEvent } from "../../../events/viewer/status_filter_events/not_built_elements_shown";
import { IN_PLACE_ELEMENTS_SHOWN, InPlaceElementsShownEvent } from "../../../events/viewer/status_filter_events/in_place_elements_shown";
import { DETECTED_DEVIATIONS_SHOWN, DetectedDeviationsShownEvent } from "../../../events/viewer/status_filter_events/detected_deviations_shown";
import { DETECTED_OBSTRUCTING_ELEMENTS_SHOWN, DetectedObstructingElementsShownEvent } from "../../../events/viewer/status_filter_events/detected_obstructing_elements_shown";
import { INCLUDED_ELEMENTS_SHOWN, IncludedElementsShownEvent } from "../../../events/viewer/status_filter_events/included_elements_shown";
import { VERIFIED_ELEMENTS_SHOWN, VerifiedElementsShownEvent } from "../../../events/viewer/status_filter_events/verified_elements_shown";
import { NOT_BUILT_ELEMENTS_HIDDEN, NotBuiltElementsHiddenEvent } from "../../../events/viewer/status_filter_events/not_built_elements_hidden";
import { IN_PLACE_ELEMENTS_HIDDEN, InPlaceElementsHiddenEvent } from "../../../events/viewer/status_filter_events/in_place_elements_hidden";
import { DETECTED_DEVIATIONS_HIDDEN, DetectedDeviationsHiddenEvent } from "../../../events/viewer/status_filter_events/detected_deviations_hidden";
import { DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN, DetectedObstructingElementsHiddenEvent } from "../../../events/viewer/status_filter_events/detected_obstructing_elements_hidden";
import { INCLUDED_ELEMENTS_HIDDEN, IncludedElementsHiddenEvent } from "../../../events/viewer/status_filter_events/included_elements_hidden";
import { VERIFIED_ELEMENTS_HIDDEN, VerifiedElementsHiddenEvent } from "../../../events/viewer/status_filter_events/verified_elements_hidden";
import { EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN, ExcludeFromAnalysisElementsShownEvent } from "../../../events/viewer/status_filter_events/exclude_from_analysis_elements_shown";
import { EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN, ExcludeFromAnalysisElementsHiddenEvent } from "../../../events/viewer/status_filter_events/exclude_from_analysis_elements_hidden";
import { INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN, IncludeInAnalysisElementsShownEvent } from "../../../events/viewer/status_filter_events/include_in_analysis_elements_shown";
import { INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN, IncludeInAnalysisElementsHiddenEvent } from "../../../events/viewer/status_filter_events/include_in_analysis_elements_hidden";

export type ElementStatusFilterEvents =
  | NotBuiltElementsShownEvent
  | InPlaceElementsShownEvent
  | DetectedDeviationsShownEvent
  | DetectedObstructingElementsShownEvent
  | IncludedElementsShownEvent
  | VerifiedElementsShownEvent
  | ExcludeFromAnalysisElementsShownEvent
  | IncludeInAnalysisElementsShownEvent
  | NotBuiltElementsHiddenEvent
  | InPlaceElementsHiddenEvent
  | DetectedDeviationsHiddenEvent
  | DetectedObstructingElementsHiddenEvent
  | IncludedElementsHiddenEvent
  | VerifiedElementsHiddenEvent
  | ExcludeFromAnalysisElementsHiddenEvent
  | IncludeInAnalysisElementsHiddenEvent;

export type ElementStatusFilterStore = {
  notBuiltElementsShown: boolean,
  inPlaceElementsShown: boolean,
  detectedDeviationsShown: boolean,
  detectedObstructingElementsShown: boolean,
  includedElementsShown: boolean,
  verifiedElementsShown: boolean,
  excludeFromAnalysisElementsShown: boolean,
  includeInAnalysisElementsShown: boolean,
}

export const defaultElementStatusFilter = {
  notBuiltElementsShown: false,
  inPlaceElementsShown: true,
  detectedDeviationsShown: true,
  detectedObstructingElementsShown: true,
  includedElementsShown: true,
  verifiedElementsShown: true,
  excludeFromAnalysisElementsShown: false,
  includeInAnalysisElementsShown: true,
};

const reduceElementStatusFilter: Reducer<ElementStatusFilterStore, ElementStatusFilterEvents> = (elementStatusFilter: ElementStatusFilterStore = defaultElementStatusFilter, event = { type: null }) => {
  switch (event.type) {
    case NOT_BUILT_ELEMENTS_SHOWN: {
      return {
        ...elementStatusFilter,
        notBuiltElementsShown: true
      };
    }
    case IN_PLACE_ELEMENTS_SHOWN: {
      return {
        ...elementStatusFilter,
        inPlaceElementsShown: true
      };
    }
    case DETECTED_DEVIATIONS_SHOWN: {
      return {
        ...elementStatusFilter,
        detectedDeviationsShown: true
      };
    }
    case DETECTED_OBSTRUCTING_ELEMENTS_SHOWN: {
      return {
        ...elementStatusFilter,
        detectedObstructingElementsShown: true
      };
    }
    case INCLUDED_ELEMENTS_SHOWN: {
      return {
        ...elementStatusFilter,
        includedElementsShown: true
      };
    }
    case VERIFIED_ELEMENTS_SHOWN: {
      return {
        ...elementStatusFilter,
        verifiedElementsShown: true
      };
    }
    case NOT_BUILT_ELEMENTS_HIDDEN: {
      return {
        ...elementStatusFilter,
        notBuiltElementsShown: false
      };
    }
    case IN_PLACE_ELEMENTS_HIDDEN: {
      return {
        ...elementStatusFilter,
        inPlaceElementsShown: false
      };
    }
    case DETECTED_DEVIATIONS_HIDDEN: {
      return {
        ...elementStatusFilter,
        detectedDeviationsShown: false
      };
    }
    case DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN: {
      return {
        ...elementStatusFilter,
        detectedObstructingElementsShown: false,
      };
    }
    case INCLUDED_ELEMENTS_HIDDEN: {
      return {
        ...elementStatusFilter,
        includedElementsShown: false
      };
    }
    case VERIFIED_ELEMENTS_HIDDEN: {
      return {
        ...elementStatusFilter,
        verifiedElementsShown: false
      };
    }
    case EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN: {
      return {
        ...elementStatusFilter,
        excludeFromAnalysisElementsShown: true,
      };
    }
    case EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN: {
      return {
        ...elementStatusFilter,
        excludeFromAnalysisElementsShown: false,
      };
    }
    case INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN: {
      return {
        ...elementStatusFilter,
        includeInAnalysisElementsShown: true,
      };
    }
    case INCLUDE_IN_ANALYSIS_ELEMENTS_HIDDEN: {
      return {
        ...elementStatusFilter,
        includeInAnalysisElementsShown: false,
      };
    }
    default: {
      return elementStatusFilter;
    }
  }
};

export default reduceElementStatusFilter;

