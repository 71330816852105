import { Action } from "redux";

export type NotBuiltElementsHiddenEvent = Action<typeof NOT_BUILT_ELEMENTS_HIDDEN>

const notBuiltElementsHidden = (): NotBuiltElementsHiddenEvent => ({
  type: NOT_BUILT_ELEMENTS_HIDDEN,
});

export const NOT_BUILT_ELEMENTS_HIDDEN = "not_built_elements_hidden";

export default notBuiltElementsHidden;