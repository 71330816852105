import { Draft, produce } from "immer";
import _ from "underscore";

import DetailedElement from "../../../models/domain/detailed_element";
import PlannedBuildingElement, { PlannedBuildingElementArg } from "../../../models/domain/planned_building_element";
import PlannedBuildingElementConverter from "../../converters/planned_building_element_converter";
import { DETAILED_ELEMENTS_LOADED, DetailedElementsLoadedEvent } from "../../../events/loaded/building_elements/detailed_elements_loaded";
import { DETECTED, DISMISSED, INCLUDED } from "../../../models/domain/enums/deviation_status";
import { DEVIATION_MARKED_AS_NOT_RESOLVED, DeviationMarkedAsNotResolvedEvent } from "../../../events/viewer/deviation_marked_as_not_resolved";
import { DEVIATION_MARKED_AS_RESOLVED, DeviationMarkedAsResolvedEvent } from "../../../events/viewer/deviation_marked_as_resolved";
import { ELEMENT_DETAILS_LOADED, ElementDetailsLoadedEvent } from "../../../events/loaded/building_elements/element_details_loaded";
import { ELEMENTS_EDITED } from "../../../events/viewer/elements_edited";
import { ELEMENTS_PARTIAL_PROGRESS_UPDATED } from "../../../events/viewer/elements_partial_progress_updated";
import { ELEMENTS_STATUSES_UPDATED } from "../../../events/viewer/elements_statuses_updated";
import { ELEMENTS_VERIFIED_UPDATED, ElementsVerifiedUpdatedEvent } from "../../../events/viewer/elements_verified_updated";
import { EXPORTED_TO_BCF, ExportedToBcfEvent } from "../../../events/viewer/exported_to_bcf";
import { PLANNED_BUILDING_ELEMENTS_LOADED, PlannedBuildingElementsLoadedEvent } from "../../../events/loaded/building_elements/planned_building_elements_loaded";
import { PUSH_TO_BIM_UNDONE, PushToBimUndoneEvent } from "../../../events/viewer/push_to_bim_undone";
import { PUSHED_ALL_TO_BIM, PushedAllToBimEvent } from "../../../events/viewer/pushed_all_to_bim";
import { PUSHED_TO_BIM, PushedToBimEvent } from "../../../events/viewer/pushed_to_bim";
import { PUSHED_TO_BIMTRACK, PushedToBimtrackEvent } from "../../../events/viewer/pushed_to_bimtrack";
import { stripUndefinedsFromObject } from "../../utilities/general";

import type { ApiElementDeviation } from "avvir";
import type { ByFirebaseId } from "type_aliases";
import type { ElementEditedEvent, ElementEditedEventPayload } from "../../../models/domain/element_edited_event";
import type { Reducer } from "redux";
import { ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED } from "../../../events/viewer/elements_exclude_from_analysis_updated";

type PlannedBuildingElementsEvents =
  | DetailedElementsLoadedEvent
  | PushedToBimEvent
  | PushToBimUndoneEvent
  | DeviationMarkedAsResolvedEvent
  | DeviationMarkedAsNotResolvedEvent
  | ElementDetailsLoadedEvent
  | ElementEditedEvent
  | PlannedBuildingElementsLoadedEvent
  | ElementsVerifiedUpdatedEvent
  | ExportedToBcfEvent
  | PushedAllToBimEvent
  | PushedToBimtrackEvent;

type ElementsForFloor = { byGlobalId: { [globalId: string]: PlannedBuildingElement } }
export type PlannedBuildingElementsStore = {
  byFirebaseFloorId: ByFirebaseId<ElementsForFloor>
}

type PartialElementsForFloor = { [globalId: string]: PlannedBuildingElementArg } | PlannedBuildingElementArg[];

const getElementsForFloor = (plannedBuildingElements: Draft<PlannedBuildingElementsStore>, floorId: string) => {
  if (!plannedBuildingElements.byFirebaseFloorId[floorId]) {
    plannedBuildingElements.byFirebaseFloorId[floorId] = {
      byGlobalId: {}
    };
  }
  return plannedBuildingElements.byFirebaseFloorId[floorId].byGlobalId;
};

const updateElementsForFloor = (plannedBuildingElements: Draft<PlannedBuildingElementsStore>, floorId: string, plannedElementsForFloor: (PartialElementsForFloor | ((plannedElementsForFloor: Record<string, PlannedBuildingElement>) => {
  [globalId: string]: PlannedBuildingElementArg
}))) => {
  let elementsForFloor: { [globalId: string]: PlannedBuildingElementArg };
  if (typeof plannedElementsForFloor === "function") {
    elementsForFloor = plannedElementsForFloor(plannedBuildingElements?.byFirebaseFloorId?.[floorId]?.byGlobalId || {});
  } else if (Array.isArray(plannedElementsForFloor)) {
    elementsForFloor = _.indexBy(plannedElementsForFloor, "globalId");
  } else {
    elementsForFloor = plannedElementsForFloor;
  }

  _.forEach(elementsForFloor, (element, globalId) => {
    updateElement(getElementsForFloor(plannedBuildingElements, floorId), globalId, element);
  });

  return plannedBuildingElements;
};

const updateLoadedElementIfChanged = (plannedBuildingElements: Draft<PlannedBuildingElementsStore>, floorId: string, globalId: string, element: DetailedElement) => {
  return updateFloorElement(plannedBuildingElements, floorId, globalId, { ...element, loaded: true });
};

const updateFloorElement = (plannedBuildingElements: Draft<PlannedBuildingElementsStore>, floorId: string, globalId: string, element: DetailedElement) => {
  if (_.isEqual(element, plannedBuildingElements.byFirebaseFloorId[floorId]?.byGlobalId?.[element.globalId])) {
    console.log(element, "already exists in the store. Skipping store update.");
    return plannedBuildingElements;
  } else {
    return updateElementsForFloor(plannedBuildingElements, floorId, elementsForFloor => {
      // @ts-ignore
      return updateElement(elementsForFloor, globalId, oldElement => {
        const fromDetails = PlannedBuildingElementConverter.detailedElementToPlannedBuildingElement(element);
        const updatedElement = new PlannedBuildingElement({
          ...oldElement,
          ...fromDetails
        });
        const result = stripUndefinedsFromObject(updatedElement);
        return result;
      });
    });
  }
};

const performEditAction = (plannedBuildingElements: Draft<PlannedBuildingElementsStore>, payload: ElementEditedEventPayload) => {
  const floorId = payload.floorId;
  const elementsForFloor = getElementsForFloor(plannedBuildingElements, floorId);
  payload.nextElementStates.forEach((element) => {
    return updateElement(elementsForFloor, element.globalId, () => element);
  });
};

function updateDeviatedElement(plannedBuildingElements: Draft<PlannedBuildingElementsStore>, floorId: string, deviationGlobalId: string, deviation: Partial<ApiElementDeviation>) {
  return updateElement(getElementsForFloor(plannedBuildingElements, floorId), deviationGlobalId, (element) => {
    return {
      ...element,
      deviation: {
        ...element.deviation,
        ...deviation
      }
    };
  });
}

function updateFixedDeviatedElement(plannedBuildingElements: PlannedBuildingElementsStore, floorId: string, deviationGlobalId: string, deviation: Partial<ApiElementDeviation>) {
  return updateElement(getElementsForFloor(plannedBuildingElements, floorId), deviationGlobalId, (element) => {
    return {
      ...element,
      fixedDeviation: {
        ...element.fixedDeviation,
        ...deviation
      }
    };
  });
}

type PlannedBuildingElementFactory = (element: PlannedBuildingElement) => PlannedBuildingElementArg

function updateElement(elementsForFloor: Draft<Record<string, PlannedBuildingElement>>, globalId: string, element: PlannedBuildingElementArg | PlannedBuildingElementFactory) {
  if (elementsForFloor[globalId]) {
    if (typeof element === "function") {
      elementsForFloor[globalId] = new PlannedBuildingElement({ ...element(elementsForFloor[globalId]), globalId });
    } else {
      elementsForFloor[globalId] = new PlannedBuildingElement({ ...elementsForFloor[globalId], ...element, globalId });
    }
  } else {
    if (typeof element === "function") {
      elementsForFloor[globalId] = new PlannedBuildingElement(element(new PlannedBuildingElement({ globalId })));
    } else {
      elementsForFloor[globalId] = new PlannedBuildingElement({ ...element, globalId });
    }
  }

  return elementsForFloor;
}


const reducePlannedBuildingElements: Reducer<PlannedBuildingElementsStore, PlannedBuildingElementsEvents> = (plannedBuildingElements: PlannedBuildingElementsStore = { byFirebaseFloorId: {} }, event) => {
  if (!plannedBuildingElements?.byFirebaseFloorId) {
    plannedBuildingElements.byFirebaseFloorId = {};
  }
  return produce(plannedBuildingElements, (plannedBuildingElementsDraft) => {
    switch (event.type) {
      case DETAILED_ELEMENTS_LOADED: {
        updateElementsForFloor(plannedBuildingElementsDraft, event.payload.floorId, event.payload.elements.reduce((elementsSoFar, element) => {
          elementsSoFar[element.globalId] = PlannedBuildingElementConverter.detailedElementToPlannedBuildingElement(element);
          return elementsSoFar;
        }, {}));
        break;
      }
      case PUSHED_TO_BIM: {
        if (event.payload.isFixedDeviation) {
          updateFixedDeviatedElement(plannedBuildingElementsDraft, event.payload.floorId, event.payload.globalId, { status: INCLUDED });
        } else {
          updateDeviatedElement(plannedBuildingElementsDraft, event.payload.floorId, event.payload.globalId, { status: INCLUDED });
        }
        break;
      }
      case PUSH_TO_BIM_UNDONE: {
        if (event.payload.isFixedDeviation) {
          updateFixedDeviatedElement(plannedBuildingElementsDraft, event.payload.floorId, event.payload.deviationGlobalId, { status: DETECTED });
        } else {
          updateDeviatedElement(plannedBuildingElementsDraft, event.payload.floorId, event.payload.deviationGlobalId, { status: DETECTED });
        }
        break;
      }
      case DEVIATION_MARKED_AS_RESOLVED: {
        if (event.payload.isFixedDeviation) {
          updateFixedDeviatedElement(plannedBuildingElementsDraft, event.payload.floorId, event.payload.deviationGlobalId, { status: DISMISSED });
        } else {
          updateDeviatedElement(plannedBuildingElementsDraft, event.payload.floorId, event.payload.deviationGlobalId, { status: DISMISSED });
        }
        break;
      }
      case DEVIATION_MARKED_AS_NOT_RESOLVED: {
        if (event.payload.isFixedDeviation) {
          updateFixedDeviatedElement(plannedBuildingElementsDraft, event.payload.floorId, event.payload.deviationGlobalId, { status: DETECTED });
        } else {
          updateDeviatedElement(plannedBuildingElementsDraft, event.payload.floorId, event.payload.deviationGlobalId, { status: DETECTED });
        }
        break;
      }
      case ELEMENT_DETAILS_LOADED: {
        event.payload.elements.forEach((element) => {
          return updateLoadedElementIfChanged(plannedBuildingElementsDraft, event.payload.floorId, element.globalId, new DetailedElement(element));
        });
        break;
      }
      case PLANNED_BUILDING_ELEMENTS_LOADED: {
        updateElementsForFloor(plannedBuildingElementsDraft, event.payload.floorId, event.payload.elements);
        break;
      }
      case ELEMENTS_EDITED:
      case ELEMENTS_STATUSES_UPDATED:
      case ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED:
      case ELEMENTS_PARTIAL_PROGRESS_UPDATED: {
        performEditAction(plannedBuildingElementsDraft, event.payload);
        break;
      }
      case ELEMENTS_VERIFIED_UPDATED: {
        updateElementsForFloor(plannedBuildingElementsDraft, event.payload.firebaseFloorId, event.payload.elementsGlobalIds.reduce((elementsSoFar, globalId) => {
          elementsSoFar[globalId] = { verified: event.payload.verifiedStatus };

          return elementsSoFar;
        }, {}));
        break;
      }
      case EXPORTED_TO_BCF: {
        updateElementsForFloor(plannedBuildingElementsDraft, event.payload.floorId, event.payload.globalIds.reduce((elementsSoFar, globalId) => {
          elementsSoFar[globalId] = { exportedToBcf: true };
          return elementsSoFar;
        }, {}));
        break;
      }
      case PUSHED_ALL_TO_BIM: {
        event.payload.deviations.forEach(deviation => {
          updateDeviatedElement(plannedBuildingElements, event.payload.floorId, deviation.globalId, { status: INCLUDED });
        });
        break;
      }
      case PUSHED_TO_BIMTRACK: {
        updateElement(getElementsForFloor(plannedBuildingElements, event.payload.floorId), event.payload.globalId, event.payload.detailedElement);
        break;
      }
      default: {
        return plannedBuildingElementsDraft;
      }
    }
  });
};

export default reducePlannedBuildingElements;

