import type { ApiProjectAreaWorkPackage } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ProjectAreaWorkPackagesLoadedEvent = AvvirEvent<typeof PROJECT_AREA_WORK_PACKAGES_LOADED, {
  projectId: string,
  projectAreaId: number,
  projectAreaWorkPackages: ApiProjectAreaWorkPackage[]
}>

const projectAreaWorkPackagesLoaded = (projectId: string, projectAreaId: number, projectAreaWorkPackages: ApiProjectAreaWorkPackage[]): ProjectAreaWorkPackagesLoadedEvent => ({
  type: PROJECT_AREA_WORK_PACKAGES_LOADED,
  payload: {
    projectId,
    projectAreaId,
    projectAreaWorkPackages
  }
});

export const PROJECT_AREA_WORK_PACKAGES_LOADED = "project_area_work_packages_loaded";

export default projectAreaWorkPackagesLoaded;
