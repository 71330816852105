import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ToPasswordResetPageEvent = AvvirPayloadlessRoutingEvent<typeof TO_PASSWORD_RESET_PAGE>

const toPasswordResetPage = (token: string, email: string): ToPasswordResetPageEvent => ({
  type: TO_PASSWORD_RESET_PAGE,
  payload: {
    queryParams: {
      token,
      email
    }
  }
});

export const TO_PASSWORD_RESET_PAGE = "to_password_reset_page";

export default toPasswordResetPage;
