import {AvvirPayloadlessRoutingEvent} from "type_aliases";

export type ToLoginPageEvent = AvvirPayloadlessRoutingEvent<typeof TO_LOGIN_PAGE>

const toLoginPage = (): ToLoginPageEvent => ({
  type: TO_LOGIN_PAGE
});

export const TO_LOGIN_PAGE = "to_login_page";

export default toLoginPage;
