import _ from "underscore";
import { Masterformat } from "masterformat";
import { Uniformat } from "uniformat";

// @ts-ignore
export type LevelKey = `level1` | `level2` | `level3` | `level4` | `level5`;
export type TreeLevels<Type> = {
  [Key in LevelKey]: Type
}

export type TreeNode = {
  visited?: boolean
  value: any
  key: any
  children: TreeNode[]
}

export type IsChild<T, U> = (thisKey: T, thatKey: U, depth: number) => boolean;
export type Mapper<T> = (value: T, key: string, depth: number) => any;

type TreeType = Masterformat | Uniformat; //Keys that are used to roll up the tree. required to use #createTree
export function createTree<Type extends TreeType>(
  levels: TreeLevels<Type>,
  level: number = 1,
  isChild: IsChild<string, string> = (thisKey, thatKey) => thisKey?.toString().startsWith(thatKey + "") || false,
  mapper: Mapper<string> = (value) => value
): TreeNode[] {
  return _(levels[`level${level}` as LevelKey]).chain()
    .map((value, key) => {
      const children = levels[`level${level + 1}`];
      if (children) {
        return {
          visited: false,
          value: mapper(value, key as string, level),
          key,
          children: createTree({
            ...levels,
            [`level${level + 1}`]: _.pick(children, (__, levelKey) => {
              return isChild(levelKey as string, key as string, level);
            })
          }, level + 1, isChild, mapper)
        };
      } else {
        return {
          visited: false,
          value: mapper(value, key as string, level),
          key,
          children: []
        };
      }
    })
    .filter((node) => node.value !== null)
    .sortBy("key")
    .value();
}



