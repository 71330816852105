import moment from "moment";

import DateConverter from "../../services/converters/date_converter";
import firebaseUserSignedIn, {FirebaseUserSignedInEvent} from "../../events/auth_events/firebase_user_signed_in";
import getFirebaseIdToken from "../../services/getters/user_getters/get_firebase_id_token";
import {UserSignedOutEvent} from "../../events/auth_events/user_signed_out";
import {AvvirThunk} from "../make_eventful_action";
import firebase from "firebase";
import signOutUser from "./sign_out_user";
import {UserRole} from "avvir";
import getUserRole from "../../services/getters/user_getters/get_user_role";
import {AvvirCustomFirebaseToken, decodeAvvirCustomFirebaseToken} from "../../services/firebase_services/firebase_tokens";

const REQUIRE_SIGN_IN_HOURS = 336; // 2 weeks = 24 * 7 * 2 = 336 hours
const TOKEN_EXPIRATION_MINUTES = 30;
const TOKEN_REFRESH_INTERVAL = 300000; // 5 minutes = 5 * 60 * 1000 = 300000 ms

function refreshExpiredToken(firebaseUser: firebase.User, role: UserRole, dispatch) {
  return firebaseUser.getIdToken(true).then((idToken) => {
    dispatch(firebaseUserSignedIn(firebaseUser, idToken, role));
  });
}

function isReSignInRequired(decodedToken: AvvirCustomFirebaseToken) {
  const authDate = moment(DateConverter.instantToDate(decodedToken.auth_time));
  const now = moment();

  const hoursSinceAuthDate = moment.duration(now.diff(authDate)).asHours();
  return hoursSinceAuthDate > REQUIRE_SIGN_IN_HOURS;
}

type DispatchedEvents = FirebaseUserSignedInEvent | UserSignedOutEvent;


const refreshFirebaseToken = (firebaseUser: firebase.User): AvvirThunk<Promise<void>, DispatchedEvents> => {
  return (dispatch, getState) => {
    const jwtToken = getFirebaseIdToken(getState(), {});
    let currentRole = getUserRole(getState, {});

    if (jwtToken) {
      const avvirToken = decodeAvvirCustomFirebaseToken(jwtToken);
      if (isReSignInRequired(avvirToken)) {
        return dispatch(signOutUser());
      }

      const expDate = moment(DateConverter.instantToDate(avvirToken.exp));
      const now = moment();
      const minutesUntilTokenExpires = moment.duration(expDate.diff(now)).asMinutes();
      setTimeout(() => {
        return dispatch(refreshFirebaseToken(firebaseUser));
      }, TOKEN_REFRESH_INTERVAL);

      if (minutesUntilTokenExpires <= TOKEN_EXPIRATION_MINUTES) {
        return refreshExpiredToken(firebaseUser, currentRole, dispatch);
      }
    }

    return Promise.resolve();
  };
};

export default refreshFirebaseToken;
