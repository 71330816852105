import { Quaternion, Vector3 } from "three";

export default class Location3d {
  constructor(
    id: number,
    position: Vector3,
    orientation: Quaternion,
  ) {
    this.id = id;
    this.position = position;
    this.orientation = orientation;
  }

  static createEmpty() {
    return new Location3d(null, new Vector3(), new Quaternion());
  }

  public id: number;
  public position: Vector3;
  public orientation: Quaternion;
}
