import ColorLib from "tinycolor2";

import CanvasHelper from "./canvas_helper";
import globalStyles from "../../style/global_styles";
import { loadCustomBimColors } from "./store_in_local_storage";

export type LegendItem = {
  color: ColorLib.ColorInput,
  text: string
};

const fontSize = globalStyles.mediumFontSize;
const radius = fontSize / 2;
const space = globalStyles.space;

export default class LegendHelper extends CanvasHelper {
  reset() {
    this.clear();
    this.setTransform();

    this.legendItems.length = 0;
  }

  addLegendItem(color: ColorLib.ColorInput, text: string) {
    this.legendItems.push({ color, text });
  }

  drawLegendBackground(width: number, height: number) {
    this.drawRect(0, 0, width, height, 4, { r: 255, g: 255, b: 255, a: 0.75 });
  }

  drawLegendItem(legendItem: LegendItem, x: number, y: number) {
    this.drawCircle(x + radius, y + radius, radius, legendItem.color);
    this.writeText(legendItem.text,
      x + radius * 2 + space,
      y + radius,
      "middle");
  }

  render = () => {
    this.context.save();
    this.setTextStyle(globalStyles.mediumGray, fontSize, "Roboto, sans-serif", 400);
    const { width, height } = this.getSize();

    this.drawLegendBackground(width, height);

    let offsetX = space;
    let offsetY = space;
    this.legendItems.forEach((legendItem) => {
      this.drawLegendItem(legendItem, offsetX, offsetY);
      offsetY += (fontSize + space);
    });
    this.context.restore();
  };

  getSize = () => {
    this.context.save();
    this.setTextStyle(globalStyles.mediumGray, fontSize, "Roboto, sans-serif", 400);
    let maxLineWidth = 0;
    this.legendItems.forEach((legendItem) => {
      const lineWidth = space + radius * 2 + space + this.context.measureText(legendItem.text).width + space;
      maxLineWidth = Math.max(maxLineWidth, lineWidth);
    });

    const height = space + this.legendItems.length * (fontSize + space);
    this.context.restore();
    return { width: maxLineWidth, height };
  };

  drawLegendForScreenshot() {
    this.reset();
    if (this.customBimColors) {
      const colors = loadCustomBimColors();
      this.addLegendItem(globalStyles.selectionBlue, "As-Designed");
      this.addLegendItem(colors.AsBuilt, "As-Built");
      this.addLegendItem(colors.NotBuilt, "Not-Built");
      this.addLegendItem(colors.InPlace, "Built");
      this.addLegendItem(colors.DeviationAsDesigned, "Deviated");
      this.addLegendItem(colors.ClashingAsDesigned, "Clashing");
    } else {
      this.addLegendItem(globalStyles.selectionBlue, "As-Designed");
      this.addLegendItem(globalStyles["BIM/As Built"], "As-Built");
      this.addLegendItem(globalStyles["BIM/Not Built"], "Not-Built");
      this.addLegendItem(globalStyles["BIM/Built"], "Built");
      this.addLegendItem(globalStyles["BIM/Deviation"], "Deviated");
      this.addLegendItem(globalStyles["BIM/Clash"], "Clashing");
    }
    const { width, height } = this.getSize();
    this.resize(width + globalStyles.space, height + globalStyles.space);
    this.render();
  }

  customBimColors: boolean = false;
  legendItems: LegendItem[] = [];
}
