import _ from "underscore";
import { Draft, Immutable, produce } from "immer";

import type { ByDatabaseId, ByFirebaseId } from "type_aliases";

type FirebaseModel = { firebaseId: string } & Record<string | symbol, any>
type DatabaseModel = { id?: number } & Record<string | symbol, any>
type DomainModel = FirebaseModel | DatabaseModel

export type ModelFactory<Model extends DomainModel> = (currentModel?: Model) => Partial<Model>

export type NormalizedFirebaseStore<Model extends FirebaseModel> = {
  byFirebaseId: ByFirebaseId<Model>
};
export type NormalizedDatabaseStore<Model extends DatabaseModel> = {
  byId: ByDatabaseId<Model>
};

const isFirebaseModel = (model: any): model is FirebaseModel => {
  return model.hasOwnProperty("firebaseId");
};

const isDatbaseModel = (model: any): model is DatabaseModel => {
  return model.hasOwnProperty("id");
};

function updateModelDraft<Model extends DomainModel>(existingModel: Draft<Model>, modelDraft: Draft<Immutable<Model>>, modelFactory: ModelFactory<Draft<Immutable<Model>>> | Partial<Draft<Immutable<Model>>>) {
  _.forEach(existingModel, (value, key) => {
    // @ts-ignore
    modelDraft[key] = value;
  });

  let result: Partial<Draft<Immutable<Model>>>;
  if (typeof modelFactory === "function") {
    result = modelFactory(modelDraft);
  } else {
    result = modelFactory;
  }

  _.forEach(result, (value, key) => {
    modelDraft[key] = value;
  });
  _.forEach(modelDraft, (value, key) => {
    if (value == null) {
      delete modelDraft[key];
    }
  });
}

function makeUpdateModel<Model extends FirebaseModel>(emptyModel: Model): <Store extends NormalizedFirebaseStore<Model>>(models: Store, modelId: string, modelFactory: (ModelFactory<Model> | Partial<Model>)) => Store
function makeUpdateModel<Model extends DatabaseModel>(emptyModel: Model): <Store extends NormalizedDatabaseStore<Model>>(models: Store, modelId: number, modelFactory: (ModelFactory<Model> | Partial<Model>)) => Store
function makeUpdateModel<Model extends DomainModel>(emptyModel: Model) {
  if (isFirebaseModel(emptyModel)) {
    return (models: NormalizedFirebaseStore<typeof emptyModel>, modelId: string, modelFactory: ModelFactory<Draft<Immutable<Model>>> | Partial<Draft<Immutable<Model>>>) => {
      return produce(models, (modelsDraft) => {
        const model = modelsDraft.byFirebaseId?.[modelId];
        modelsDraft.byFirebaseId[modelId] = produce(emptyModel as Immutable<typeof emptyModel>, (modelDraft) => {
          updateModelDraft(model, modelDraft, modelFactory);
          modelDraft.firebaseId = modelId;
        }) as typeof model;
      });
    };
  } else if (isDatbaseModel(emptyModel)) {
    return (models: NormalizedDatabaseStore<typeof emptyModel>, modelId: number, modelFactory: ModelFactory<Draft<Immutable<Model>>> | Partial<Draft<Immutable<Model>>>) => {
      return produce(models, (modelsDraft) => {
        const existingModel = modelsDraft.byId[modelId];
        modelsDraft.byId[modelId] = produce(emptyModel as Immutable<typeof emptyModel>, (modelDraft) => {
          updateModelDraft(existingModel, modelDraft, modelFactory);
          modelDraft.id = modelId;
        }) as typeof existingModel;
      });
    };
  }
}

export default makeUpdateModel;
