import PlannedBuildingElement from "../../models/domain/planned_building_element";
import { AvvirEvent } from "type_aliases";

export type BimtrackIssueCreationDialogShownEvent = AvvirEvent<typeof BIMTRACK_ISSUE_CREATION_DIALOG_SHOWN, { globalId: string, selectedElement: PlannedBuildingElement }>

const bimtrackIssueCreationDialogShown = (globalId: string, selectedElement: PlannedBuildingElement): BimtrackIssueCreationDialogShownEvent => ({
  type: BIMTRACK_ISSUE_CREATION_DIALOG_SHOWN,
  payload: {
    globalId,
    selectedElement,
  }
});

export const BIMTRACK_ISSUE_CREATION_DIALOG_SHOWN = "bimtrack_issue_creation_dialog_shown";

export default bimtrackIssueCreationDialogShown;
