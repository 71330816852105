import { Action } from "redux";

export type SectionPointCloudToggledOnEvent = Action<typeof SECTION_POINT_CLOUD_TOGGLED_ON>

const sectionPointCloudToggledOn = (): SectionPointCloudToggledOnEvent => ({
  type: SECTION_POINT_CLOUD_TOGGLED_ON,
});

export const SECTION_POINT_CLOUD_TOGGLED_ON = "section_point_cloud_toggled_on";

export default sectionPointCloudToggledOn;
