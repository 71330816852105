import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ToProjectPortfolioPageEvent = AvvirPayloadlessRoutingEvent<typeof TO_PROJECT_PORTFOLIO_PAGE>

const toProjectPortfolioPage = (): ToProjectPortfolioPageEvent => ({
  type: TO_PROJECT_PORTFOLIO_PAGE,
});

export const TO_PROJECT_PORTFOLIO_PAGE = "to_project_portfolio_page";

export default toProjectPortfolioPage;
