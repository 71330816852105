import { AvvirRoutingEvent } from "type_aliases";

export type ToInspectReportEvent = AvvirRoutingEvent<typeof TO_INSPECT_REPORT, {
  projectId: string
  inspectReportId: string
}>

const toInspectReport = (projectId: string, inspectReportId:number): ToInspectReportEvent => ({
  type: TO_INSPECT_REPORT,
  payload: {
    projectId,
    inspectReportId: inspectReportId.toString()
  }
});

export const TO_INSPECT_REPORT = "to_inspect_report";

export default toInspectReport;
