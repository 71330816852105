import { Reducer } from "redux";

import BimtrackAssociation from "../../../models/domain/bimtrack/bimtrack_association";
import { BIMTRACK_ASSOCIATION_CREATED, BimtrackAssociationCreatedEvent } from "../../../events/viewer/bimtrack_association_created";
import { BIMTRACK_ASSOCIATION_LOADED, BimtrackAssociationLoadedEvent } from "../../../events/loaded/bimtrack_association_loaded";

type EventTypes = BimtrackAssociationLoadedEvent | BimtrackAssociationCreatedEvent

export type BimtrackStore = {
  bimtrackAssociations: BimtrackAssociation[];
}

const reduceBimtrack: Reducer<BimtrackStore, EventTypes> = (bimtrackStore: BimtrackStore = { bimtrackAssociations: [] }, event: EventTypes) => {
  switch (event.type) {
    case BIMTRACK_ASSOCIATION_LOADED : {
      return {
        ...bimtrackStore,
        bimtrackAssociations: [event.payload]
      };
    }
    case BIMTRACK_ASSOCIATION_CREATED : {
      return {
        ...bimtrackStore,
        bimtrackAssociations: [event.payload]
      };
    }
    default: {
      return bimtrackStore;
    }
  }
};

export default reduceBimtrack;

