import Avvir from "avvir";

import wbsAvailabilityLoaded, { WbsAvailabilityLoadedEvent } from "../../events/loaded/wbs/wbs_availability_loaded";
import { ApiFailureEvent } from "../../events/notifications/failures/api_failure";
import { AvvirThunk } from "../make_eventful_action";

const loadWbsAvailability = (projectId: string): AvvirThunk<Promise<boolean>, WbsAvailabilityLoadedEvent | ApiFailureEvent> => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    if (user) {
      return Avvir.api.projects.checkWbsStatus({ projectId }, user)
        .then(result => {
          const isWbsAvailable = result.hasCostAnalysisProgress;
          dispatch(wbsAvailabilityLoaded(projectId, isWbsAvailable));
          return isWbsAvailable;
        })
        .catch(dispatch(wbsAvailabilityLoaded(projectId, false)));
    } else {
      dispatch(wbsAvailabilityLoaded(projectId, false));
      return Promise.resolve(false);
    }
  };
};

export default loadWbsAvailability;
