import { immerable } from "immer";
import { ApiPhotoSessionArgument } from "avvir";
import addReadOnlyPropertiesToModel from "../../../services/utilities/mixins/add_read_only_properties_to_model";
import addDateGetterAndSetterToDomainModel from "../../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";


export default class PhotoSession {
  constructor({ id, photoAreaId, sessionDate, deletedAt }: ApiPhotoSessionArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id, photoAreaId });
    addDateGetterAndSetterToDomainModel(this, "sessionDate", sessionDate);
    addDateGetterAndSetterToDomainModel(this, "deletedAt", deletedAt);
  }

  readonly id: number;
  readonly photoAreaId: number;
  readonly sessionDate: Date;
  deletedAt?: Date;

  static readonly [immerable] = true;
}
