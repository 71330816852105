import { Reducer } from "redux";

import makeUpdateModel from "./make_update_model";
import { PHOTO_SESSIONS_LOADED, PhotoSessionsLoadedEvent } from "../../../events/loaded/photos/photo_sessions_loaded";
import PhotoSession from "../../../models/domain/photos/photo_session";
import { PHOTO_SESSIONS_DELETED, PhotoSessionsDeletedEvent } from "../../../events/viewer/photo_sessions_deleted";

type PhotoSessionsEvents = PhotoSessionsLoadedEvent | PhotoSessionsDeletedEvent;

export type PhotoSessionsStore = {
  byId: {
    [id: number]: PhotoSession
  }
}

const updatePhotoSession = makeUpdateModel<PhotoSession>(new PhotoSession());

const reducePhotoSessions: Reducer<PhotoSessionsStore, PhotoSessionsEvents> = (photoSessions: PhotoSessionsStore = { byId: {} }, event) => {
  switch (event?.type) {
    case PHOTO_SESSIONS_DELETED: {
      return Array.from(event.payload).reduce((photoSessionsSoFar, photoSessionId) => {
        const updated = new PhotoSession(photoSessionsSoFar.byId[photoSessionId]);
        updated.deletedAt = new Date();
        return updatePhotoSession(photoSessionsSoFar, photoSessionId, updated);
      }, photoSessions);
    }
    case PHOTO_SESSIONS_LOADED: {
      return event.payload.photoSessions.reduce((photoSessionsSoFar, photoSession) => {
        return updatePhotoSession(photoSessionsSoFar, photoSession.id, new PhotoSession(photoSession));
      }, photoSessions);
    }
    default: {
      return photoSessions;
    }
  }
};

export default reducePhotoSessions;
