import ApiTradeCost from "../api/api_trade_cost";

export default class TradeCost {
  constructor({
    tradeCode,
    code,
    unitOfMeasure,
    plannedUnitCost,
    plannedTotalCost,
    plannedQuantity,
    capturedQuantity,
    modeledQuantity,
    reportedQuantity
  }: Partial<TradeCost & ApiTradeCost> = {}) {
    this.code = code || tradeCode;
    this.unitOfMeasure = unitOfMeasure;
    this.plannedUnitCost = plannedUnitCost;
    this.plannedTotalCost = plannedTotalCost;
    this.plannedQuantity = plannedQuantity;
    this.capturedQuantity = capturedQuantity;
    this.modeledQuantity = modeledQuantity;
    this.reportedQuantity = reportedQuantity;
  }

  code: string;
  unitOfMeasure: string;
  plannedUnitCost: number;
  plannedTotalCost: number;
  plannedQuantity: number;
  capturedQuantity: number;
  capturedTotalCost: number;
  modeledQuantity: number;
  modeledTotalCost: number;
  reportedQuantity: number;
}
