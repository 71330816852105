import { AvvirEvent } from "type_aliases";

export type ProcoreProjectsLoadedEvent = AvvirEvent<typeof PROCORE_PROJECTS_LOADED, any>

const procoreProjectsLoaded = (procoreProjects): ProcoreProjectsLoadedEvent => ({
  type: PROCORE_PROJECTS_LOADED,
  payload: procoreProjects
});

export const PROCORE_PROJECTS_LOADED = "procore_projects_loaded";

export default procoreProjectsLoaded;
