import { AvvirEvent } from "type_aliases";
import { ApiScanDatasetStats } from "avvir";

export type ScanDatasetStatsLoadedEvent = AvvirEvent<typeof SCAN_DATASET_STATS_LOADED, ApiScanDatasetStats[]>

const scanDatasetStatsLoaded = (apiScanDatasetStats: ApiScanDatasetStats[]): ScanDatasetStatsLoadedEvent => ({
  type: SCAN_DATASET_STATS_LOADED,
  payload: [...apiScanDatasetStats]
});

export const SCAN_DATASET_STATS_LOADED = "scan_dataset_stats_loaded";

export default scanDatasetStatsLoaded;
