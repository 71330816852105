import { Reducer } from "redux";
import _ from "underscore";
import { ApiGroupMember } from "avvir";

import { ByDatabaseId } from "type_aliases";
import { GROUPS_LOADED, GroupsLoadedEvent } from "../../../events/groups/groups_loaded";
import { GROUP_CREATED, GroupCreatedEvent } from "../../../events/groups/group_created";
import { GROUP_DELETED, GroupsDeletedEvent } from "../../../events/groups/group_deleted";
import { GROUP_MEMBERS_DELETED, GroupMembersDeletedEvent } from "../../../events/groups/group_members_deleted";

type GroupMemberEvents =
  | GroupsLoadedEvent
  | GroupCreatedEvent
  | GroupsDeletedEvent
| GroupMembersDeletedEvent

export type GroupMembersStore = {
  byId: ByDatabaseId<ApiGroupMember>
}

const reduceGroupMembers: Reducer<GroupMembersStore, GroupMemberEvents> = (groupMembers = { byId: {} }, event) => {
  if (!groupMembers.byId) {
    groupMembers.byId = {};
  }

  switch (event.type) {
    case GROUP_CREATED: {
      return {
        ...groupMembers,
        byId: {
          ...groupMembers.byId,
          ..._.indexBy(event.payload.group.members, "id")
        }
      };
    }
    case GROUPS_LOADED: {
      return {
        ...groupMembers,
        byId: {
          ...groupMembers.byId,
          ..._.chain(event.payload.groups)
            .pluck("members")
            .flatten()
            .indexBy("id")
            .value()
        }
      };
    }
    case GROUP_DELETED: {
      return {
        ...groupMembers,
        byId: {
          ..._(groupMembers.byId).omit((member) => member.groupId === event.payload.groupId),
        }
      };
    }
    case GROUP_MEMBERS_DELETED: {
      return {
        ...groupMembers,
        byId: {
          ..._(groupMembers.byId).omit(event.payload.groupMemberIds)
        }
      }
    }
    default: {
      return groupMembers;
    }
  }
};

export default reduceGroupMembers;

