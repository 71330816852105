import {AvvirPayloadlessRoutingEvent} from "type_aliases";

export type ToPasswordResetEmailEvent = AvvirPayloadlessRoutingEvent<typeof TO_PASSWORD_RESET_EMAIL_FORM>

const toPasswordResetEmailForm = (): ToPasswordResetEmailEvent => ({
  type: TO_PASSWORD_RESET_EMAIL_FORM
});

export const TO_PASSWORD_RESET_EMAIL_FORM = "to_password_reset_email_form";

export default toPasswordResetEmailForm
