import { AvvirEvent } from "type_aliases";
import { UserSessionStore } from "../../../services/reducers/reduce_user_session";

export type ProjectAccessDeniedEvent = AvvirEvent<typeof PROJECT_ACCESS_DENIED, UserSessionStore>

const projectAccessDenied = (user: UserSessionStore): ProjectAccessDeniedEvent => ({
  type: PROJECT_ACCESS_DENIED,
  payload: user
});

export const PROJECT_ACCESS_DENIED = "project_access_denied";

export default projectAccessDenied;
