import { Action } from "redux";

export type RotationUnlockedEvent = Action<typeof ROTATION_UNLOCKED>

const rotationUnlocked = (): RotationUnlockedEvent => ({
  type: ROTATION_UNLOCKED
});

export const ROTATION_UNLOCKED = "rotation_unlocked";

export default rotationUnlocked;
