import { createSelector } from "reselect";

import getElementSelection from "../../base_getters/get_element_selection";

const empty: string[] = [];

const getSelectedElementGlobalIds = createSelector([
  getElementSelection
], (elementSelection) => elementSelection.selectedElements || empty);

export default getSelectedElementGlobalIds;
