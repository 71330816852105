import { AvvirEvent } from "type_aliases";

export type ProcoreProjectSelectedEvent = AvvirEvent<typeof PROCORE_PROJECT_SELECTED, any>

const procoreProjectSelected = (selectedProcoreProjectId): ProcoreProjectSelectedEvent => ({
  type: PROCORE_PROJECT_SELECTED,
  payload: selectedProcoreProjectId
});

export const PROCORE_PROJECT_SELECTED = "procore_project_selected";

export default procoreProjectSelected;
