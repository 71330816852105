import { ActionHistoryEntry } from "../../services/reducers/reduce_action_history";
import { AvvirEvent } from "type_aliases";

export type UndoableActionOccurredEvent = AvvirEvent<typeof UNDOABLE_ACTION_OCCURRED, ActionHistoryEntry>

const undoableActionOccurred = (payload: ActionHistoryEntry): UndoableActionOccurredEvent => ({
  type: UNDOABLE_ACTION_OCCURRED,
  payload
});

export const UNDOABLE_ACTION_OCCURRED = "undoable_action_occurred";

export default undoableActionOccurred;
