import { Matrix4 } from "three";
import { immerable } from "immer";
import { ApiScanDatasetQaState, ApiView } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";
import Matrix4Converter from "../../services/converters/matrix_4_converter";
import { AvvirApiFiles, AvvirFileIds, AvvirFiles, DateLike, Matrix4Like, Modify } from "type_aliases";
import { ScanDatasetPurposeType } from "./enums/purpose_type";

type ScanDatasetArgument = Partial<Modify<ScanDataset, {
  coarseAlignmentMatrix?: Matrix4Like
  fineAlignmentMatrix?: Matrix4Like
  scanDate?: DateLike
  files?: AvvirFiles<ScanDatasetPurposeType> | AvvirApiFiles<ScanDatasetPurposeType> | AvvirFileIds<ScanDatasetPurposeType>
}>>

export default class ScanDataset {
  constructor({
    id,
    firebaseId,
    firebaseFloorId,
    scanNumber,
    scanAnalysisPresent,
    analysisCompleted,
    manualQcPresent,
    notes,
    name,
    coarseAlignmentMatrix,
    fineAlignmentMatrix,
    scanDate,
    files,
    qaStarted,
    qaComplete,
    qaState,
    views,
    hasScanFile
  }: ScanDatasetArgument = {}) {
    let coarseAlignmentMatrixVal, fineAlignmentMatrixVal;
    addDateGetterAndSetterToDomainModel(this, "scanDate", scanDate);
    addReadOnlyPropertiesToModel(this, { id, firebaseId, firebaseFloorId });
    Object.defineProperties(this, {
      coarseAlignmentMatrix: {
        get() {
          return coarseAlignmentMatrixVal;
        },
        set(val) {
          if (typeof val === "string") {
            coarseAlignmentMatrixVal = Matrix4Converter.fromStringToMatrix4(val);
          } else if (val instanceof Matrix4) {
            coarseAlignmentMatrixVal = val;
          } else {
            coarseAlignmentMatrixVal = Matrix4Converter.fromApiMatrixToMatrix4(val);
          }
        },
        enumerable: true
      },
      fineAlignmentMatrix: {
        get() {
          return fineAlignmentMatrixVal;
        },
        set(val) {
          if (typeof val === "string") {
            fineAlignmentMatrixVal = Matrix4Converter.fromStringToMatrix4(val);
          } else if (val instanceof Matrix4) {
            fineAlignmentMatrixVal = val;
          } else {
            fineAlignmentMatrixVal = Matrix4Converter.fromApiMatrixToMatrix4(val);
          }
        },
        enumerable: true
      }
    });
    this.scanNumber = scanNumber || 1;
    this.scanAnalysisPresent = scanAnalysisPresent || false;
    this.analysisCompleted = analysisCompleted;
    this.manualQcPresent = manualQcPresent;
    this.notes = notes || "";
    this.name = name || "";
    // @ts-ignore
    this.coarseAlignmentMatrix = coarseAlignmentMatrix;
    // @ts-ignore
    this.fineAlignmentMatrix = fineAlignmentMatrix;
    // @ts-ignore
    this.files = files || {};
    this.qaStarted = qaStarted;
    this.qaComplete = qaComplete;
    this.qaState = qaState;
    if (views) {
      this.views = views;
    }
    this.hasScanFile = hasScanFile;
  }

  readonly id: number;
  readonly firebaseId: string;
  readonly firebaseFloorId: string;
  readonly scanNumber: number;
  readonly scanAnalysisPresent: boolean = false;
  analysisCompleted: number | null;
  manualQcPresent: number | null;
  notes?: string = "";
  name?: string = "";
  coarseAlignmentMatrix?: Matrix4 | null = null;
  fineAlignmentMatrix?: Matrix4 | null = null;
  scanDate: Date | null = null;
  files?: AvvirFileIds<ScanDatasetPurposeType>;
  views?: ApiView[];

  qaStarted: Date | null = null;
  qaComplete: Date | null = null;
  qaState: ApiScanDatasetQaState | null = null;
  hasScanFile?: boolean = false;

  static readonly [immerable] = true;

  static getPreviousQaState = (qaState: ApiScanDatasetQaState): ApiScanDatasetQaState => {
    switch (qaState) {
      case ApiScanDatasetQaState.NO_DATA:
      case ApiScanDatasetQaState.NOT_STARTED: {
        return ApiScanDatasetQaState.COMPLETED;
      }
      case ApiScanDatasetQaState.STARTED: {
        return ApiScanDatasetQaState.NOT_STARTED;
      }
      case ApiScanDatasetQaState.COMPLETED: {
        return ApiScanDatasetQaState.STARTED;
      }
    }
  };

  static getNextQaState = (qaState: ApiScanDatasetQaState): ApiScanDatasetQaState => {
    switch (qaState) {
      case ApiScanDatasetQaState.NOT_STARTED: {
        return ApiScanDatasetQaState.STARTED;
      }
      case ApiScanDatasetQaState.STARTED: {
        return ApiScanDatasetQaState.COMPLETED;
      }
      case ApiScanDatasetQaState.NO_DATA:
      case ApiScanDatasetQaState.COMPLETED: {
        return ApiScanDatasetQaState.NOT_STARTED;
      }
    }
  };
}
