import { createSelector } from "reselect";
import _ from "underscore";

import getScanDatasetsForFloorByFirebaseId from "./get_scan_datasets_for_floor_by_firebase_id";

const getAllScanDatasetScanDates = createSelector([
  getScanDatasetsForFloorByFirebaseId,
], (scanDatasets) => {
  return _.chain(scanDatasets)
    .pluck("scanDate")
    .sortBy(_.identity) // .sort compares by the string unicode code points
    .value();
});

export default getAllScanDatasetScanDates;
