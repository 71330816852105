import { useEffect, useRef } from "react";

export default function usePrevious<Value>(value: Value): Value {
  const ref = useRef<Value>();

  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
