import type { AvvirEvent } from "type_aliases";

export type WorkPackageShownEvent = AvvirEvent<typeof WORK_PACKAGE_SHOWN, { workPackageId: number }>

const workPackageShown = (workPackageId: number): WorkPackageShownEvent => ({
  type: WORK_PACKAGE_SHOWN,
  payload: {
    workPackageId
  }
});

export const WORK_PACKAGE_SHOWN = "work_package_shown";

export default workPackageShown;
