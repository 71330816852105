export enum PanelFlag {
  GROUPS_PANEL = "groupsPanel",
  ACTION_HISTORY_PANEL = "actionHistoryPanel",
  INSPECT_REPORTS_PANEL = "customReportsPanel",
  PROJECT_AREA_SUMMARY_PANEL = "projectAreaSummaryPanel",
  PROJECT_AREAS_MODEL_VIEWER_PANEL = "projectAreasModelViewerPanel",
  PHOTOS_ONLY = "photosOnly",
  DESKTOP_ONLY = "desktopOnly",
  PROGRESS_REPORT_TAB = "progressReportTab",
  SHOW_PROGRESS_ON_SUMMARY = "showProgressOnSummary",
  SHOW_CUSTOM_REPORTS_ON_SUMMARY = "showCustomReportsOnSummary",
  SHOW_ALL_REPORTS_ON_SUMMARY = "showAllReportsOnSummary",
  PROJECT_METRICS = "projectMetrics"
}

const ORDERED_PANEL_FLAGS: PanelFlag[] = [
  PanelFlag.GROUPS_PANEL,
  PanelFlag.ACTION_HISTORY_PANEL,
  PanelFlag.INSPECT_REPORTS_PANEL,
  PanelFlag.PROJECT_AREAS_MODEL_VIEWER_PANEL,
  PanelFlag.PROJECT_AREA_SUMMARY_PANEL,
  PanelFlag.PHOTOS_ONLY,
  PanelFlag.DESKTOP_ONLY,
  PanelFlag.PROGRESS_REPORT_TAB,
  PanelFlag.SHOW_PROGRESS_ON_SUMMARY,
  PanelFlag.SHOW_CUSTOM_REPORTS_ON_SUMMARY,
  PanelFlag.SHOW_ALL_REPORTS_ON_SUMMARY,
  PanelFlag.PROJECT_METRICS
];

export type PanelFlags = {
  [flag in PanelFlag]?: boolean;
};

export function getAllPanelFlags(enabled: boolean = true): PanelFlags {
  const flags: PanelFlags = {};

  ORDERED_PANEL_FLAGS.forEach(flag => {
    flags[flag] = enabled;
  });

  return flags;
}

export function doPanelFlagsMatch(flagQuery: PanelFlags, flags: PanelFlags): boolean {
  for (let flagKey in flagQuery) {
    const value = flagQuery[flagKey];
    if (value !== flags[flagKey]) {
      return false;
    }
  }

  return true;
}
