import { ApiView } from "avvir";

import { calculatePhotoLocationCoords } from "../photos_layer/photo_sprite";
import { FixedSizeSprite } from "../layers_shared/fixed_size_sprite";
import { MapLocation } from "../layers_shared/map_location_container";
import { PixiMinimapTransforms } from "../pixi_minimap_app";
import { SharedViewStyles } from "./shared_view_styles";

export default class SharedViewMarker extends FixedSizeSprite implements MapLocation {
  public id: number;
  public visited: boolean;
  public sharedView: ApiView;
  public onSelect: (view: SharedViewMarker) => void;

  static spriteStyles: SharedViewStyles = new SharedViewStyles(); // beware, textures need rendering
  constructor(view: ApiView, transforms: PixiMinimapTransforms, onSelect: (view: SharedViewMarker) => void) {
    super();
    this.texture = SharedViewMarker.spriteStyles.defaultTexture || SharedViewMarker.spriteStyles.textureFailure;
    this.onSelect = onSelect;

    this.updateFromView(view, transforms);
    this.interactive = true;
    this.buttonMode = true;

    this.on("pointerup", (event) => {
      this.onSelect(this);
    });
  }

  updateFromView(view: ApiView, transforms: PixiMinimapTransforms) {
    this.id = view.id;
    this.sharedView = view;
    this.visited = false;
    const target = view.viewAttributes.camera.position;
    if (transforms) {
      const coords = calculatePhotoLocationCoords(target, transforms.mapBounds, transforms.viewerToPhotoAreaTransform);
      this.position.set(coords.x, coords.y);
    }
  }
}
