import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ToSearchSetCreatorEvent = AvvirPayloadlessRoutingEvent<typeof TO_SEARCH_SET_CREATOR_PAGE>

const toSearchSetCreator = (): ToSearchSetCreatorEvent => ({
  type: TO_SEARCH_SET_CREATOR_PAGE
});

export const TO_SEARCH_SET_CREATOR_PAGE = "to_search_set_creator";

export default toSearchSetCreator;
