import { Reducer } from "redux";
import _ from "underscore";
import { ApiGroup } from "avvir";

import Group from "../../../models/domain/group";
import { GROUP_CREATED, GroupCreatedEvent } from "../../../events/groups/group_created";
import { GROUP_DELETED, GroupsDeletedEvent } from "../../../events/groups/group_deleted";
import { GROUP_MEMBERS_DELETED, GroupMembersDeletedEvent } from "../../../events/groups/group_members_deleted";
import { GROUPS_LOADED, GroupsLoadedEvent } from "../../../events/groups/groups_loaded";
import { without } from "../../utilities/general";
import { GROUP_UPDATED, GroupUpdatedEvent } from "../../../events/groups/group_updated";

type GroupEvents =
  GroupCreatedEvent
  | GroupsLoadedEvent
  | GroupsDeletedEvent
  | GroupMembersDeletedEvent
  | GroupUpdatedEvent

export type GroupsStore = {
  byId: {
    [id: number]: Group
  }
}

const addGroup = (groupsStore: GroupsStore, groupId: number, group: ApiGroup) => {
  return {
    ...groupsStore,
    byId: {
      ...groupsStore.byId,
      [groupId]: new Group(group)
    }
  };
};

const reduceGroups: Reducer<GroupsStore, GroupEvents> = (groupsStore = { byId: {} }, action) => {
  switch (action.type) {
    case GROUP_CREATED: {
      let { group, groupId } = action.payload;
      return addGroup(groupsStore, groupId, group);
    }
    case GROUPS_LOADED: {
      let { groups } = action.payload;
      return groups.reduce((groupsSoFar, group) => {
        return addGroup(groupsSoFar, group.id, group);
      }, groupsStore);
    }
    case GROUP_UPDATED: {
      let { group } = action.payload;
      return {
        ...groupsStore,
        byId: {
          ...groupsStore.byId,
          [group.id]: new Group(group)
        }
      }
    }
    case GROUP_DELETED: {
      let { groupId } = action.payload;

      return {
        ...groupsStore,
        byId: _.omit(groupsStore.byId, groupId.toString())
      };
    }
    case GROUP_MEMBERS_DELETED: {
      const { groupMemberIds } = action.payload;

      return groupMemberIds.reduce((groupsStoreSoFar, memberId) => {
        const group = _(groupsStoreSoFar.byId).find(group => group.members.includes(memberId));
        return {
          ...groupsStoreSoFar,
          byId: {
            ...groupsStoreSoFar.byId,
            [group.id]: {
              ...group,
              members: without(group.members, memberId)
            }
          }
        };
      }, groupsStore);
    }
    default: {
      return groupsStore;
    }
  }
};

export default reduceGroups;
