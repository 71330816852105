import { Vector3 } from "three";
import { DeviationStatus } from "avvir";

import { ModifyPartial, Vector3Like } from "type_aliases";

export type DeviationArgs = ModifyPartial<Deviation, {
  deviationVectorMeters: Vector3Like
}>
export default class Deviation {
  constructor(deviation: DeviationArgs = {}) {
    this.deviationVectorMeters = new Vector3(deviation.deviationVectorMeters?.x || 0, deviation.deviationVectorMeters?.y || 0, deviation.deviationVectorMeters?.z || 0);
    this.deviationMeters = deviation.deviationMeters || this.deviationVectorMeters.length();
    this.status = deviation.status;
    this.clashing = deviation.clashing;
  };

  deviationVectorMeters: Vector3;
  deviationMeters: number;
  status: DeviationStatus;
  clashing: boolean;

  static isEmpty = (deviation: Deviation) => {
    return !deviation || Deviation.is0Deviation(deviation);
  };

  private static is0Deviation = (deviation: Deviation) => {
    return deviation.deviationMeters === 0 || !deviation.deviationVectorMeters || (deviation.deviationVectorMeters.x === 0 && deviation.deviationVectorMeters.y === 0 && deviation.deviationVectorMeters.z === 0);
  };
}
