import { AvvirRoutingEvent } from "type_aliases";

export type ToProjectUploadsPageEvent = AvvirRoutingEvent<typeof TO_PROJECT_UPLOADS_PAGE, { projectId: string }>

const toProjectUploadsPage = (projectId: string): ToProjectUploadsPageEvent => ({
  type: TO_PROJECT_UPLOADS_PAGE,
  payload: { projectId }
});

export const TO_PROJECT_UPLOADS_PAGE = "to_project_uploads_page";

export default toProjectUploadsPage;
