import addDateGetterAndSetterToDomainModel from "../../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import WorkPackageBuiltStatus from "../enums/work_package_built_status";

import type { DateLike, ModifyPartial } from "type_aliases";

type ProjectAreaProgressParameters = ModifyPartial<ProjectAreaProgress, {
  status: string,
  expectedStart: DateLike,
  expectedCompletion: DateLike,
  start: DateLike,
  completion: DateLike
}>

/**
 * @deprecated Use {@link ProjectAreaWorkPackage} instead
 */
export default class ProjectAreaProgress {
  constructor({
    id,
    name,
    status,
    start,
    completion,
    expectedStart,
    expectedCompletion
  }: ProjectAreaProgressParameters = {}) {
    addDateGetterAndSetterToDomainModel(this, "expectedStart", expectedStart);
    addDateGetterAndSetterToDomainModel(this, "expectedCompletion", expectedCompletion);
    addDateGetterAndSetterToDomainModel(this, "start", start);
    addDateGetterAndSetterToDomainModel(this, "completion", completion);
    this.id = id || null;
    this.name = name || null;
    this.status = status as WorkPackageBuiltStatus;
  }

  id: number;
  readonly name: string;
  status: WorkPackageBuiltStatus;
  expectedStart: Date;
  expectedCompletion: Date;
  start: Date;
  completion: Date;
}
