import { AvvirEvent } from "type_aliases";

export type PhotoSessionSelectedEvent = AvvirEvent<typeof PHOTO_SESSION_SELECTED, { photoSessionId: number | number[] }>

const photoSessionSelected = (photoSessionId: number | number[]): PhotoSessionSelectedEvent => ({
  type: PHOTO_SESSION_SELECTED,
  payload: {
    photoSessionId
  }
});

export const PHOTO_SESSION_SELECTED = "photo_session_selected";

export default photoSessionSelected;
