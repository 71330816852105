import { AvvirEvent } from "type_aliases";
import { ApiUserAction } from "avvir";

export type ElementActionRecordedEvent = AvvirEvent<typeof ELEMENT_ACTION_RECORDED, {
  action: ApiUserAction,
  floorId: string
  globalId: string
}>

const elementActionRecorded = (floorId: string, globalId: string, action: ApiUserAction): ElementActionRecordedEvent => ({
  type: ELEMENT_ACTION_RECORDED,
  payload: {
    action,
    floorId,
    globalId
  }
});

export const ELEMENT_ACTION_RECORDED = "element_action_recorded";

export default elementActionRecorded;