import PlannedBuildingElement from "./planned_building_element";
import { ElementEditedEventType } from "./element_edit_action_event_types";

export type ElementEditedEventPayload = {
  floorId: string;
  previousElementStates: PlannedBuildingElement[];
  nextElementStates: PlannedBuildingElement[];
}

export class ElementEditedEvent {
  constructor(type: ElementEditedEventType, floorId: string, previousElementStates: PlannedBuildingElement[], nextElementStates: PlannedBuildingElement[]) {
    this.type = type;
    this.payload = {
      floorId,
      previousElementStates,
      nextElementStates
    };
  }

  type: ElementEditedEventType;
  payload: {
    floorId: string;
    previousElementStates: PlannedBuildingElement[];
    nextElementStates: PlannedBuildingElement[];
  };
}