import { AvvirEvent } from "type_aliases";

export type ElementSelectedEvent = AvvirEvent<typeof ELEMENT_SELECTED, { globalId: string, additiveMode: boolean }>

const elementSelected = (globalId: string, additiveMode: boolean): ElementSelectedEvent => ({
  type: ELEMENT_SELECTED,
  payload: { globalId, additiveMode }
});

export const ELEMENT_SELECTED = "element_selected";

export default elementSelected;
