import Avvir, { ApiPipeline, ApiProjectPurposeType, ApiScanDatasetPurposeType, RunningProcessStatus } from "avvir";

import { AvvirThunk } from "./make_eventful_action";
import pipelineRunningProcessChecked, { PipelineRunningProcessCheckedEvent } from "../events/pipeline_running_process_checked";
import triggerFileDownload from "../services/utilities/general/trigger_file_download";

const checkPipelineStatus = (pipeline: ApiPipeline): AvvirThunk<Promise<ApiPipeline>, PipelineRunningProcessCheckedEvent> => {
  return (dispatch, getState) => {
    const associationIds = { projectId: pipeline?.firebaseProjectId, floorId: pipeline?.firebaseFloorId, scanDatasetId: pipeline?.firebaseScanDatasetId };
    const user = getState().user;
    const checkAgainIfPending = (pipeline: ApiPipeline) => {
      if (pipeline.status === "RUNNING") {
        setTimeout(() => {
          Avvir.api.pipelines.checkPipelineStatus(associationIds, pipeline.id, user)
            .then(checkAgainIfPending)
            .catch(() => {
              dispatch(pipelineRunningProcessChecked({ ...pipeline, status: RunningProcessStatus.FAILED }));
              return pipeline;
            });
        }, 5000);
      } else if (pipeline.status === "COMPLETED" && pipeline.name === "generate-bcf") {
        Avvir.api.files.getScanDatasetFiles(associationIds, ApiScanDatasetPurposeType.DEVIATIONS_SCAN_BCF, user).then(files => {
          triggerFileDownload(files.files[0].url, "deviatedElements.bcf");
          return files.files;
        });
      } else if (pipeline.status === "COMPLETED" && pipeline.name === "create-project-report") {
        Avvir.api.files.listProjectFiles(associationIds, user, ApiProjectPurposeType.PROJECT_EXPORT_REPORT).then(files => {
          triggerFileDownload(files.at(-1).url, "projectExportReport.tsv");
          return files;
        });
      }
      dispatch(pipelineRunningProcessChecked(pipeline));
      return pipeline;
    };

    return Avvir.api.pipelines.checkPipelineStatus(associationIds, pipeline?.id, user)
      .then(checkAgainIfPending)
      .catch(() => dispatch(pipelineRunningProcessChecked({ ...pipeline, status: RunningProcessStatus.FAILED })));
  };
};

export default checkPipelineStatus;
