import { Action } from "redux";

export type UndoRequestedEvent = Action<typeof UNDO_REQUESTED>

const undoRequested = (): UndoRequestedEvent => ({
  type: UNDO_REQUESTED
});

export const UNDO_REQUESTED = "undo_requested";

export default undoRequested;
