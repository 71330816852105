import { AvvirRoutingEvent } from "type_aliases";

export type ToProjectEvent = AvvirRoutingEvent<typeof TO_PROJECT, { projectId: string }>

const toProject = (projectId: string): ToProjectEvent => ({
  type: TO_PROJECT, payload: { projectId }
});

export const TO_PROJECT = "to_project";

export default toProject;
