import { AvvirEvent } from "type_aliases";

export type FloorDeletedEvent = AvvirEvent<typeof FLOOR_DELETED, { firebaseProjectId: string, floorId: string, scanDatasetId: string }>

const floorDeleted = (firebaseProjectId, floorId, scanDatasetId): FloorDeletedEvent => ({
  type: FLOOR_DELETED,
  payload: {
    firebaseProjectId,
    floorId,
    scanDatasetId,
  }
});

export const FLOOR_DELETED = "floor_deleted";

export default floorDeleted;
