import Avvir, { ApiBuiltStatus, ApiScannedElement, UserActionType } from "avvir";

import camerasController from "../../services/utilities/threejs_utilities/cameras_controller";
import ElementEditAction from "../../models/domain/element_edit_action";
import elementsStatusesUpdated, { ELEMENTS_STATUSES_UPDATED } from "../../events/viewer/elements_statuses_updated";
import getFloorId from "../../services/getters/floor_getters/get_floor_id";
import getInspectionMode from "../../services/getters/selection_getters/get_inspection_mode";
import getPlannedElementsByGlobalId from "../../services/getters/planned_building_element_getters/get_planned_elements_by_global_id";
import getProjectIdFromLocation from "../../services/getters/location_metadata/get_project_id_from_location";
import getScanDataset from "../../services/getters/scan_dataset_getters/get_scan_dataset";
import getScanDatasetId from "../../services/getters/scan_dataset_getters/get_scan_dataset_id";
import getUser from "../../services/getters/base_getters/get_user";
import PlannedBuildingElement from "../../models/domain/planned_building_element";
import recordUserActions from "../record_user_actions";
import UndoableAction from "../../models/domain/undoable_action";
import { AvvirThunk } from "../make_eventful_action";
import { DETECTED } from "../../models/domain/enums/deviation_status";
import { DEVIATED, IN_PLACE, NOT_BUILT } from "../../models/domain/enums/scan_label";
import { InspectionMode } from "../../models/domain/enums/inspection_modes";

function updateElements(originalElements: PlannedBuildingElement[], scanDate: Date, newScanLabel: ApiBuiltStatus): ApiScannedElement<ApiBuiltStatus>[] {
  return originalElements.map(element => {
    if (newScanLabel === PlannedBuildingElement.getScanLabel(element, scanDate)) { // Scan label didn't change. DEVIATED -> DEVIATED
      const deviation = { ...PlannedBuildingElement.getDeviation(element, scanDate), clashing: false };
      return {
        globalId: element.globalId,
        scanLabel: newScanLabel,
        deviation: deviation
      };
    } else { // Scan label changed.
      if (newScanLabel === NOT_BUILT || newScanLabel === IN_PLACE) {
        return {
          globalId: element.globalId,
          scanLabel: newScanLabel
        };
      } else {
        return {
          globalId: element.globalId,
          scanLabel: DEVIATED,
          deviation: {
            deviationVectorMeters: new THREE.Vector3(0, 0, 0),
            deviationMeters: 0,
            status: DETECTED,
            clashing: false
          }
        };
      }
    }
  });
}

const updateElementsStatus = (newScanLabel: ApiBuiltStatus, elementGlobalIds: readonly string[]): AvvirThunk<UndoableAction, ElementEditAction> => {
  return (dispatch, getState) => {
    const state = getState();
    const allPlannedElements = getPlannedElementsByGlobalId(state, {});
    const floorId = getFloorId(state, {});
    const plannedElements = elementGlobalIds?.map(globalId => allPlannedElements[globalId]);
    return dispatch(new UpdateElementsStatusAction(floorId, plannedElements, newScanLabel));
  };
};

export class UpdateElementsStatusAction extends ElementEditAction {
  elements: PlannedBuildingElement[];
  newStatus: ApiBuiltStatus;

  constructor(floorId: string, plannedBuildingElements: PlannedBuildingElement[], newStatus: ApiBuiltStatus) {
    super("Built Status", ELEMENTS_STATUSES_UPDATED, floorId);
    this.elements = plannedBuildingElements;
    this.payload.previousElementStates = this.elements.map(element => new PlannedBuildingElement(element));
    this.newStatus = newStatus;
  }

  perform(dispatch, getState) {
    const state = getState();
    const inspectionMode = getInspectionMode(state, {});
    const scanDataset = getScanDataset(state, {});
    const scanLabels = this.elements.map(element => {
      return PlannedBuildingElement.getScanLabel(element, scanDataset.scanDate);
    });
    if (scanLabels.every(label => label === this.newStatus)) {
      return Promise.resolve();
    } else {
      const projectId = getProjectIdFromLocation(state, {});
      const currentScanDatasetId = getScanDatasetId(state, {});
      const user = getUser(state, {});
      const updatedElements = updateElements(this.elements, scanDataset.scanDate, this.newStatus);

      let userActionType;
      switch (this.newStatus) {
        case IN_PLACE:
          userActionType = UserActionType.ELEMENT_CHANGED_TO_IN_PLACE;
          break;
        case NOT_BUILT:
          userActionType = UserActionType.ELEMENT_CHANGED_TO_NOT_BUILT;
          break;
        case DEVIATED:
          userActionType = UserActionType.ELEMENT_CHANGED_TO_DEVIATED;
          break;
        default:
          userActionType = UserActionType.ELEMENTS_STATUSES_UPDATED;
      }

      dispatch(recordUserActions(userActionType, this.elements, camerasController.photoViewerControls));

      return Avvir.api.elements.updatePlannedBuildingElementsForViewer({
            projectId,
            floorId: this.payload.floorId,
            scanDatasetId: currentScanDatasetId
          },
          updatedElements,
          inspectionMode === InspectionMode.progress,
          user)
        .then((nextElementsStates) => {
          this.payload.nextElementStates = nextElementsStates.map(element => new PlannedBuildingElement(element));
          dispatch(elementsStatusesUpdated(this.payload.floorId, this.payload.previousElementStates, this.payload.nextElementStates));
        });
    }
  }
}

export default updateElementsStatus;
