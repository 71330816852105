import { useEffect } from "react";
import { useLDClient } from "launchdarkly-react-client-sdk";

import getUserAccount from "../services/getters/user_getters/get_user_account";
import getUserRole from "../services/getters/user_getters/get_user_role";
import useAvvirSelector from "../services/component_helpers/component_effects/use_avvir_selector";
import getProjectName from "../services/getters/project_getters/get_project_name";
import getProjectId from "../services/getters/project_getters/get_project_id";


export const LaunchDarklyClient = () => {
  //convenience wrapper for launch darkly hooks for use in classical components
  const userAccount = useAvvirSelector(getUserAccount);
  const userRole = useAvvirSelector(getUserRole);
  const firebaseProjectId = useAvvirSelector(getProjectId);
  const projectName = useAvvirSelector(getProjectName);
  const client = useLDClient();

  const userName = userAccount ? userAccount.name : null;
  const userEmail = userAccount ? userAccount.email : null;
  let launchDarklyKey;

  if (process.env.NODE_ENV === "test") {
    launchDarklyKey = "testUser";
  } else {
    launchDarklyKey = userEmail;
  }

  useEffect(() => {
    let context: any = {
      kind: "multi",
    };
    if (firebaseProjectId != null) {
      context.project = {
        name: projectName,
        key: firebaseProjectId,
      };
    }
    if (client && userEmail) {
      context.user = {
        name: userName == null ? userEmail : userName,
        key: launchDarklyKey,
        email: userEmail,
        role: userRole,
      };
      client.identify(context);
    }
  }, [userEmail, userName, userRole, firebaseProjectId, projectName, client, launchDarklyKey]);

  return null;
};
