import React from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { ResizeObserver } from "@juggle/resize-observer/lib/ResizeObserver";
import { ResizeObserverEntry } from "@juggle/resize-observer";
import { useDebounceCallback } from "@react-hook/debounce";

type RefObjectOrElement = React.RefObject<HTMLElement> | HTMLElement;

/**
 * Calls the resize callback whenever the containing element has changed size. The callback will be called immediately the first time and then throttled to be called
 * repeatedly at the specified debounce time interval.
 * @param container HTMLElement or Ref to containing element
 * @param resizeCallback Called when a resize is needed
 * @param debounceWaitMs Time to wait between resize calls
 */
const useDebouncedResizer = (container: RefObjectOrElement, resizeCallback: (entry: ResizeObserverEntry, observer: ResizeObserver) => void, debounceWaitMs: number = 500) => {
  useResizeObserver(container, useDebounceCallback(resizeCallback, debounceWaitMs, true));
};

export default useDebouncedResizer;
