import { AvvirRoutingEvent } from "type_aliases";

export type ToPhotosEvent = AvvirRoutingEvent<typeof TO_PHOTO_PAGE, { projectId: string, type?: string, category?: string }>

const toPhoto = (projectId: string, type?: string, category?: string): ToPhotosEvent => ({
  type: TO_PHOTO_PAGE, payload: { projectId, type, category }
});

export const TO_PHOTO_PAGE = "to_photo_page";

export default toPhoto;