import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import List from "@mui/material/List";
import Paper from "@mui/material/Paper";
import _ from "underscore";
import { PipelineName, RunningProcessStatus } from "avvir";

import "../../css/running_processes_overlay.css";
import checkPipelineStatus from "../actions/check_pipeline_status";
import getPipelineRunningProcessesById from "../services/getters/running_processes_getters/get_pipeline_running_processes_by_id";
import getRunningExportProcesses from "../services/getters/running_processes_getters/get_running_export_processes";
import getUserAccount from "../services/getters/user_getters/get_user_account";
import Pipeline from "../models/domain/pipeline";
import RunningProcessOverlayItem from "./running_process_overlay_item";
import { Dispatch } from "type_aliases";
import { isProcessWithId } from "../services/utilities/custom_type_guards";
import { loadDismissedPipelineIds, loadPipelines, saveDismissedPipelineId } from "../services/utilities/store_in_local_storage";
import { Process } from "../models/domain/running_process";

export type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export type PipelineProcess = Exclude<(Pipeline | Process), "name"> & { key?: string, name: any, status: RunningProcessStatus, endTime?: Date };

export const RunningProcessesOverlay: FunctionComponent<Props> = ({ exportProcesses, pipelinesById, userAccount, checkPipeline }) => {
  const [pipelines, setPipelines] = useState([]);
  const [terminatedPipelines, setTerminatedPipelines] = useState([]);
  const email = userAccount == null ? null : userAccount.email;
  const [dismissedPipelineIds, setDismissedPipelineIds] = useState({});

  const updateDismissedPipelineIds = useCallback(() => {
    setDismissedPipelineIds(loadDismissedPipelineIds());
  }, [loadDismissedPipelineIds]);

  useEffect(updateDismissedPipelineIds, [updateDismissedPipelineIds]);

  useEffect(() => {
    if (email != null) {
      const stored = loadPipelines();
      stored.forEach(checkPipeline);
    }
  }, [checkPipeline, email]);

  useEffect(() => {
    //set up the pipelines as a normalized controlled set of arrays
    const newPipelines: PipelineProcess[] = [...exportProcesses.map(process => {
      if (isProcessWithId(process)) {
        return {
          ...process,
          key: `${process.processId}`,
          name: process.title,
          status: RunningProcessStatus.RUNNING
        };
      } else {
        return {
          ...process,
          key: process.processName,
          name: process.title,
          status: RunningProcessStatus.RUNNING
        };
      }
    })];
    const [running, terminated] = _.partition(_(pipelinesById).pairs(), ([id, pipeline], _) => {
      return pipeline.status !== "COMPLETED" && pipeline.status !== "FAILED";
    });
    newPipelines.push(...running.map(pipeline => ({
      ...pipeline[1],
      key: `pipeline_process_${pipeline[1].id}`,
      name: pipeline[1].name.split("-").join(" ") as PipelineName
    })));
    const newTerminated = terminated.map(pipeline => ({
      ...pipeline[1],
      key: `pipeline_process_${pipeline[1].id}`,
      name: pipeline[1].name.split("-").join(" ") as PipelineName
    })).filter(pipeline => (pipeline.endTime > new Date(new Date().getMilliseconds() - 10000)));
    setPipelines(newPipelines);
    setTerminatedPipelines(newTerminated);
  }, [pipelinesById, exportProcesses]);

  return (terminatedPipelines.length > 0 || pipelines.length > 0) ?
         <Paper className="RunningProcessesOverlay">
           <List dense disablePadding>
             {pipelines
               .filter((pipeline: PipelineProcess) => {
                 if (typeof (pipeline as any).id === "undefined") {
                   return true;
                 } else {
                   return !dismissedPipelineIds[(pipeline as any).id];
                 }
               })
               .map((pipeline: PipelineProcess) => {
               return <RunningProcessOverlayItem
                 key={pipeline.key}
                 startTime={pipeline.startTime}
                 endTime={pipeline?.endTime || new Date()}
                 title={pipeline.name}
                 status={pipeline.status}
                 onDismiss={() => {
                   if (typeof (pipeline as any).id !== "undefined") {
                     saveDismissedPipelineId((pipeline as any)?.id);
                     updateDismissedPipelineIds();
                   }
                 }}
               />;
             })}
             {terminatedPipelines.map((pipeline: PipelineProcess) => {
               return <RunningProcessOverlayItem
                 key={pipeline.key}
                 startTime={pipeline.startTime}
                 endTime={pipeline?.endTime || new Date()}
                 title={pipeline.name}
                 status={pipeline.status}
                 onDismiss={() => {}}
               />;
             })}
           </List>
         </Paper> : null;
};

const mapStateToProps = (state, props) => ({
  userAccount: getUserAccount(state, props),
  pipelinesById: getPipelineRunningProcessesById(state, props),
  exportProcesses: getRunningExportProcesses(state, props)
});

const mapDispatchToProps = (dispatch: Dispatch<ReturnType<typeof checkPipelineStatus>>) => bindActionCreators({
  checkPipeline: checkPipelineStatus
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RunningProcessesOverlay);
