import { Reducer } from "redux";
import { TreeNode } from "../../utilities/create_tree";
import { WBS_HIERARCHY_LOADED, WbsHierarchyLoadedEvent } from "../../../events/loaded/wbs/masterformat_classes_loaded";
import { createMasterformatDivisionTree } from "../../utilities/create_masterformat_division_tree";

export type MasterformatTreeStore = TreeNode[];

const noMasterformatProvided = [{
  value: {
    level: null
  },
  key: "",
  children: []
}];

const reduceWbsHierarchy: Reducer<MasterformatTreeStore, WbsHierarchyLoadedEvent> = (masterformatTree = noMasterformatProvided as MasterformatTreeStore, event) => {
  switch (event.type) {
    case WBS_HIERARCHY_LOADED: {
      return createMasterformatDivisionTree(event.payload)
    }
    default: {
      return masterformatTree;
    }
  }
};

export default reduceWbsHierarchy;
