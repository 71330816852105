import { AvvirRoutingEvent } from "type_aliases";
import PageNames from "../../models/domain/enums/page_names";

export type ToProgress4dReportForAreaEvent = AvvirRoutingEvent<typeof TO_PROGRESS_4D_REPORT_FOR_AREA, { projectId: string, floorId: string, embedded?: boolean, pageName: PageNames }>

const toProgress4dReportForArea = ({
  projectId,
  floorId,
  embedded = false,
  pageName = PageNames.PROJECT_REPORTS
}:{
  projectId: string,
  floorId: string,
  embedded?: boolean,
  pageName?: PageNames
}): ToProgress4dReportForAreaEvent => ({
  type: TO_PROGRESS_4D_REPORT_FOR_AREA,
  payload: {
    embedded,
    projectId,
    floorId,
    pageName
  }
});

export const TO_PROGRESS_4D_REPORT_FOR_AREA = "to_project_4d_report_for_area";

export default toProgress4dReportForArea;
