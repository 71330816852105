import { FirebaseUser, ResponseError } from "avvir";

import getUser from "../getters/base_getters/get_user";
import loadImage from "../component_helpers/canvas_helpers/load_image";
import { GetState } from "type_aliases";

class ImageFetcher {
  getState: GetState;

  constructor(getState) {
    this.getState = getState;
  }

  getUrlAsImage = async (url: string): Promise<HTMLImageElement> => {
    return this.getBlobUrl(url)
      .then(loadImage)
      .then(event => event.currentTarget);
  };

  getBlobUrl = async (url) => {
    if (url.startsWith("https://firebasestorage.googleapis")) {
      return await this.getBlobUrlWithAuth(url);

    } else {
      return await this.getBlobUrlNoAuth(url);
    }
  };

  getBlobUrlWithAuth = async (url: string): Promise<string> => {
    const authToken = (getUser(this.getState(), {}) as FirebaseUser).firebaseUser.idToken;
    const response = await fetch(url, {
      headers: {
        authorization: `Firebase ${authToken}`
      }
    });

    if (!response.ok) {
      throw new ResponseError("Failed to fetch image from Avvir", "", response, {});
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

  getBlobUrlNoAuth = async (url: string): Promise<string> => {
    const response = await fetch(url);
    if (!response.ok) {
      throw new ResponseError("Failed to fetch image from 3rd party", "", response, {});
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };
}

export default ImageFetcher;
