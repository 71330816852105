import type { ActionHistoryEntry } from "../../services/reducers/reduce_action_history";
import type { AvvirEvent } from "type_aliases";

export type UndoableActionRedoneEvent = AvvirEvent<typeof UNDOABLE_ACTION_REDONE, ActionHistoryEntry>

const undoableActionRedone = (action: ActionHistoryEntry): UndoableActionRedoneEvent => ({
  type: UNDOABLE_ACTION_REDONE,
  payload: action
});

export const UNDOABLE_ACTION_REDONE = "undoable_action_redone";

export default undoableActionRedone;
