import { immerable } from "immer";
import { ApiIntegrationCredentialsType } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";

import type { DateLike, ModifyPartial } from "type_aliases";

export type IntegrationCredentialsArgs = ModifyPartial<IntegrationCredentials, {
  createdAt?: DateLike,
  modifiedAt?: DateLike
}>;

export default class IntegrationCredentials {
  constructor({
    id,
    username,
    password,
    createdAt,
    modifiedAt,
    credentialsType,
    firebaseOrganizationId,
    firebaseProjectId,
    authorId,
    encryptedCredentials
  }: IntegrationCredentialsArgs) {
    addReadOnlyPropertiesToModel(this, { id, firebaseOrganizationId, firebaseProjectId });
    this.username = username;
    this.password = password;
    this.credentialsType = credentialsType;
    this.authorId = authorId;
    this.encryptedCredentials = encryptedCredentials;
    addDateGetterAndSetterToDomainModel(this, "createdAt", createdAt);
    addDateGetterAndSetterToDomainModel(this, "modifiedAt", modifiedAt);
  }

  readonly id?: number;
  username?: string;
  password?: string;
  authorId?: number;
  createdAt: number;
  modifiedAt?: number;
  credentialsType: ApiIntegrationCredentialsType;
  readonly firebaseOrganizationId?: string;
  readonly firebaseProjectId?: string;
  encryptedCredentials?: string;

  static readonly [immerable] = true;
}
