import Avvir, {ApiPipeline} from "avvir";

import checkExportedIfc from "./check_exported_ifc";
import DateConverter from "../../services/converters/date_converter";
import exportIfcTriggered, { ExportIfcTriggeredEvent } from "../../events/export_ifc_triggered";
import getFloorId from "../../services/getters/floor_getters/get_floor_id";
import getProjectIdFromLocation from "../../services/getters/location_metadata/get_project_id_from_location";
import getScanDatasetId from "../../services/getters/scan_dataset_getters/get_scan_dataset_id";
import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import { ExportIfcProcessCheckedEvent } from "../../events/export_ifc_process_checked";

const exportIfc = (ifcType: string): AvvirThunk<Promise<void>, ExportIfcTriggeredEvent | ExportIfcProcessCheckedEvent> => {
  return (dispatch, getState) => {
    const state = getState();
    const firebaseProjectId = getProjectIdFromLocation(state, {});
    const firebaseFloorId = getFloorId(state, {});
    const firebaseScanDatasetId = getScanDatasetId(state, {});
    const associationIds = { projectId: firebaseProjectId, floorId: firebaseFloorId, scanDatasetId: firebaseScanDatasetId };
    const user = state.user;
    const pipeline = new ApiPipeline({
      name: "generate-ifc",
      firebaseProjectId,
      firebaseFloorId,
      firebaseScanDatasetId,
      options: {
        type: ifcType
      }
    });
    return Avvir.api.pipelines.triggerPipeline(pipeline, user)
      .then((pipeline) => {
        dispatch(exportIfcTriggered(associationIds, ifcType, pipeline.externalId, DateConverter.instantToDate(pipeline.startTime)));
        setTimeout(() => {
          dispatch(checkExportedIfc(associationIds, pipeline.id, ifcType));
        }, 5000);
      });
  };
};

export default makeEventfulAction("exportIfc", exportIfc);