import { Reducer } from "redux";

import makeUpdateModel from "./make_update_model";
import ProjectSummary from "../../../models/domain/project_summary/project_summary";
import { PROJECT_AREA_HIGHLIGHTED, ProjectAreaHighlightedEvent } from "../../../events/project_summary/project_area_highlighted";
import { PROJECT_AREA_SELECTION_CHANGED, SelectedProjectAreaChangedEvent } from "../../../events/project_summary/project_area_selection_changed";
import { PROJECT_SUMMARY_LOADED, ProjectSummaryLoadedEvent } from "../../../events/loaded/project_summary/project_summary_loaded";
import { QUALITY_METRICS_LOADED, QualityMetricsLoaded } from "../../../events/loaded/metrics/quality_metrics_loaded";
import { COST_METRICS_LOADED, CostMetricsLoadedEvent } from "../../../events/loaded/metrics/cost_metrics_loaded";

export interface ProjectSummaryStore {
  byFirebaseId: {
    [firebaseId: string]: ProjectSummary
  };
}

type ProjectSummaryEvents =
  | ProjectSummaryLoadedEvent
  | SelectedProjectAreaChangedEvent
  | ProjectAreaHighlightedEvent
  | QualityMetricsLoaded
  | CostMetricsLoadedEvent

const emptyProjectSummary = new ProjectSummary();
const updateProjectSummary = makeUpdateModel<ProjectSummary>(emptyProjectSummary);

const reduceProjectSummary: Reducer<ProjectSummaryStore, ProjectSummaryEvents> =
  (projectSummaries: ProjectSummaryStore = { byFirebaseId: {} }, event: ProjectSummaryEvents) => {
    switch (event.type) {
      case PROJECT_SUMMARY_LOADED: {
        const { model, projectAreaIds, firebaseProjectId: firebaseId } = event.payload;
        const projectSummaryFromApi = new ProjectSummary({ firebaseId, model, projectAreaIds });
        return updateProjectSummary(projectSummaries, firebaseId, (currentProjectSummary) => {
          return {
            ...currentProjectSummary,
            ...projectSummaryFromApi,
            metrics: {
              ...currentProjectSummary.metrics
            }
          };
        });
      }
      case PROJECT_AREA_SELECTION_CHANGED: {
        return updateProjectSummary(projectSummaries, event.payload.firebaseProjectId, (currentProjectSummary => {
          return {
            ...currentProjectSummary,
            selectedModelElementId: event.payload.dbId
          };
        }));
      }
      case PROJECT_AREA_HIGHLIGHTED: {
        const currentProject = projectSummaries.byFirebaseId[event.payload.projectId];
        if (event.payload.dbId === currentProject?.highlightedModelElementId) {
          return projectSummaries;
        } else {
          return updateProjectSummary(projectSummaries, event.payload.projectId, (currentProjectSummary => {
            return {
              ...currentProjectSummary,
              highlightedModelElementId: event.payload.dbId
            };
          }));
        }
      }
      case QUALITY_METRICS_LOADED: {
        let { metrics, projectId } = event.payload;
        return updateProjectSummary(projectSummaries, projectId, (current) => ({
          ...current,
          metrics: {
            ...current.metrics,
            quality: metrics
          }
        }))
      }
      case COST_METRICS_LOADED: {
        let { costMetrics, projectId } = event.payload;
        return updateProjectSummary(projectSummaries, projectId, (current) => ({
          ...current,
          metrics: {
            ...current.metrics,
            cost: {
              ...costMetrics
            }
          }
        }))
      }
      default: {
        return projectSummaries;
      }
    }
  };

export default reduceProjectSummary;
