import React, { Component } from "react";

import { ScrollableContext } from "./scrollable_container";

export type Props = {
  name: string
  children: React.ReactElement
}

export default class ScrollElement extends Component<Props> {
  constructor(props) {
    super(props);
    this.element = React.createRef();
  }

  static contextType = ScrollableContext;

  context: React.ContextType<typeof ScrollableContext>;

  element: React.RefObject<Element> = null;

  componentDidMount() {
    this.context.register(this.props.name, this.element);
  }

  componentWillUnmount() {
    this.context.unregister(this.props.name);
  }

  render() {
    return React.cloneElement(this.props.children, { ref: this.element });
  }
}
