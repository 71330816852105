import { createSelector } from "reselect";

import getFloor from "../floor_getters/get_floor";

const getFloorGlobalOffsetYaw = createSelector([
  getFloor
], (floor) => {
  return floor?.globalOffsetYaw == null ? 0 : floor.globalOffsetYaw;
});

export default getFloorGlobalOffsetYaw;
