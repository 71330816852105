import ProcessStatus from "../models/domain/enums/process_status";
import runningProcessSucceeded from "../events/running_process/running_process_succeeded";
import runningProcessFailed from "../events/running_process/running_process_failed";
import runningProcessChecked, { RunningProcessCheckedEvent } from "../events/running_process/running_process_checked";
import { AvvirThunk } from "./make_eventful_action";
import Avvir, { ApiRunningProcess } from "avvir";
import withPromise from "../services/utilities/general/with_promise";

type Events = RunningProcessCheckedEvent;

const checkRunningGatewayProcess = (runningProcess: ApiRunningProcess, timeoutMillis: number = 1000): AvvirThunk<Promise<ApiRunningProcess>, Events> => {
  return (dispatch, getState) => {
    const user = getState().user;

    const [resolveCheckingRunningProcess, rejectCheckingRunningProcess, checkingRunningProcess] = withPromise<ApiRunningProcess>();

    const checkIfStillRunning = (processToCheck: ApiRunningProcess) => {
      switch (processToCheck.status) {
        case ProcessStatus.RUNNING: {
          dispatch(runningProcessChecked({ runningProcess: processToCheck }));
          setTimeout(() => {
            Avvir.api.other.checkRunningProcess(processToCheck.id, user)
              .then(checkIfStillRunning);
          }, timeoutMillis);
          break;
        }
        case ProcessStatus.SUCCEEDED: {
          dispatch(runningProcessSucceeded({ runningProcess: processToCheck }));
          resolveCheckingRunningProcess(processToCheck);
          break;
        }
        case ProcessStatus.FAILED: {
          dispatch(runningProcessFailed({ runningProcess: processToCheck }));
          rejectCheckingRunningProcess(processToCheck);
          break;
        }
      }
    };

    checkIfStillRunning(runningProcess);
    return checkingRunningProcess;
  };
};

export default checkRunningGatewayProcess;
