import { AvvirEvent } from "type_aliases";
import BimtrackProject from "../../models/api/bimtrack_project";

export type BimtrackProjectLoadedEvent = AvvirEvent<typeof BIMTRACK_PROJECT_LOADED, BimtrackProject>

const bimtrackProjectLoaded = (project: BimtrackProject): BimtrackProjectLoadedEvent => ({
  type: BIMTRACK_PROJECT_LOADED,
  payload: project
});

export const BIMTRACK_PROJECT_LOADED = "bimtrack_project_loaded";

export default bimtrackProjectLoaded;
