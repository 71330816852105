import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import MenuList from "@mui/material/MenuList";
import Popover, { PopoverProps } from "@mui/material/Popover";
import _ from "underscore";
import classNames from "classnames";

import "../../../css/shared/scrollable_menu.css";
import ScrollableContainer from "./scrollable_container";
import ScrollElement from "./scroll_element";

export type Props = { selectedIndex: number, children: React.ReactElement[], onEntered?: (node: HTMLElement, isAppearing: boolean) => void } & PopoverProps

/** Menu component that automatically scrolls to its selected menu item when opened */
const ScrollableMenu: FunctionComponent<Props> = function ScrollableMenu(props) {
  const { selectedIndex, children, onEntered, PaperProps, ...popoverProps } = props;
  const uniqueId = useMemo(() => _.uniqueId(), []);
  const scrollable = useRef<ScrollableContainer>();
  const [selectedName, setSelectedName] = useState("");

  useEffect(() => {
    setSelectedName(`${uniqueId}-${selectedIndex}`);
  }, [uniqueId, selectedIndex]);

  const scrollToSelected = useCallback(() => {
    scrollable.current.scrollTo(selectedName);
  }, [selectedName]);

  const menuItems = children.map((child, index) => {
    return <ScrollElement
      key={`${uniqueId}-${index}`}
      name={`${uniqueId}-${index}`}
    >
      {child}
    </ScrollElement>;
  });

  const handleEntered = useCallback((node: HTMLElement, isAppearing: boolean) => {
    if (onEntered) {
      onEntered(node, isAppearing);
    }
    scrollToSelected();
  }, [onEntered, scrollToSelected]);

  return <Popover
    {...popoverProps}
    TransitionProps={{
      onEntered: handleEntered
    }}
    PaperProps={{
      ...PaperProps,
      className: classNames("ScrollableMenu", PaperProps?.className)
    }}
  >
    <ScrollableContainer ref={scrollable}>
      <MenuList autoFocus autoFocusItem className="ScrollableMenu-scrollable">
        {menuItems}
      </MenuList>
    </ScrollableContainer>
  </Popover>;
};

export default React.memo(ScrollableMenu);
