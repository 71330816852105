import { AvvirEvent } from "type_aliases";
import { ApiInvitation } from "avvir";

export type InvitationLoadedEvent = AvvirEvent<typeof INVITATION_LOADED, { userEmail: string, resourceName: string, expiry: number }>

const invitationLoaded = ({ userEmail, resourceName, expiry }: ApiInvitation): InvitationLoadedEvent => ({
  type: INVITATION_LOADED,
  payload: {
    userEmail,
    resourceName,
    expiry
  }
});

export const INVITATION_LOADED = "invitation_loaded";

export default invitationLoaded;
