import { AvvirEvent } from "type_aliases";

export type InvitationSentEvent = AvvirEvent<typeof INVITATION_SENT, {
  count: number
}>

const invitationSent = (count: number): InvitationSentEvent => ({
  type: INVITATION_SENT,
  payload: { count }
});

export const INVITATION_SENT = "invitation_sent";

export default invitationSent;
