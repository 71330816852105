import { AvvirErrorEvent } from "type_aliases";

export type PushToProcoreFailedEvent = AvvirErrorEvent<typeof PUSH_TO_PROCORE_FAILED>

const pushToProcoreFailed = (error?: Error): PushToProcoreFailedEvent => ({
  type: PUSH_TO_PROCORE_FAILED,
  payload: { error }
});

export const PUSH_TO_PROCORE_FAILED = "push_to_procore_failed";

export default pushToProcoreFailed;
