import { DeepPartial } from "type_aliases";

export function stripUndefinedsFromObject<T extends object>(obj: T): DeepPartial<T> {
  if (Object.keys(obj).length) { // This keeps us from replacing date objects with {};
    // @ts-ignore
    const returnObj: DeepPartial<T> = { ...obj };
    for (let key in returnObj) {
      if (returnObj.hasOwnProperty(key)) {
        if (returnObj[key] && typeof returnObj[key] === "object") {
          // @ts-ignore
          returnObj[key] = stripUndefinedsFromObject(returnObj[key]);
        } else if (typeof returnObj[key] === "undefined") {
          delete returnObj[key];
        }
      }
    }
    return returnObj;
  }
  // @ts-ignore
  return obj;
}

export default stripUndefinedsFromObject;
