import { Vector2Like } from "type_aliases";

/** Return the value if it is between the min and max. Otherwise, return the min if below, or max if above. */
export function clamp(min: number, value: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

export function clampXY<T extends Vector2Like>(min: Vector2Like, value: T, max: Vector2Like): T {
  value.x = clamp(min.x, value.x, max.x);
  value.y = clamp(min.y, value.y, max.y);
  return value;
}

export default clamp;
