import { createSelector } from "reselect";

import getPhotoAreaId from "./get_photo_area_id";
import getPhotoSessionsById from "./get_photo_sessions_by_id";
import PhotoSession from "../../../models/domain/photos/photo_session";

const getPhotoSessionsForPhotoAreaById = createSelector([
  getPhotoAreaId,
  getPhotoSessionsById
], (photoAreaId, sessionsById) => {
  const byId: { [id: number]: PhotoSession } = {};
  for (const sessionId in sessionsById) {
    const session = sessionsById[sessionId];
    if (session.photoAreaId === photoAreaId && session.deletedAt == null) {
      byId[sessionId] = session;
    }
  }
  return byId;
});

export default getPhotoSessionsForPhotoAreaById;
