import type { AvvirEvent } from "type_aliases";

export type TradeSelectedEvent = AvvirEvent<typeof TRADE_SELECTED, { code: string }>

const tradeSelected = (code: string): TradeSelectedEvent => ({
  type: TRADE_SELECTED,
  payload: {
    code
  }
});

export const TRADE_SELECTED = "trade_selected";

export default tradeSelected;
