import type { AvvirEvent } from "type_aliases";

export type TradeShownOnlyEvent = AvvirEvent<typeof TRADE_SHOWN_ONLY, { code: string }>

const tradeShownOnly = (code: string): TradeShownOnlyEvent => ({
  type: TRADE_SHOWN_ONLY,
  payload: {
    code
  }
});

export const TRADE_SHOWN_ONLY = "trade_shown_only";

export default tradeShownOnly;
