import { ScanDatasetStats } from "../../models/domain/scan_dataset_stats";
import { ApiScanDatasetStats } from "avvir";

export class ScanDatasetStatsConverter {
  static toApi( scanDatasetStat ) {
    return new ApiScanDatasetStats(scanDatasetStat);
  }

  static fromApi(apiScanDatasetStat) {
    return new ScanDatasetStats(apiScanDatasetStat);
  }
}