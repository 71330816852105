import { Action } from "redux";

export type ToastNotificationExpiresSoonEvent = Action<typeof TOAST_NOTIFICATION_EXPIRES_SOON>

const toastNotificationExpiresSoon = (): ToastNotificationExpiresSoonEvent => ({
  type: TOAST_NOTIFICATION_EXPIRES_SOON
});

export const TOAST_NOTIFICATION_EXPIRES_SOON = "toast_notification_expires_soon";

export default toastNotificationExpiresSoon;
