import { AvvirEvent } from "type_aliases";

export type GatewayUserSignedInEvent = AvvirEvent<typeof GATEWAY_USER_SIGNED_IN, { jwt: string, projectId: string }>

const gatewayUserSignedIn = (jwt: string, projectId: string): GatewayUserSignedInEvent => ({
  type: GATEWAY_USER_SIGNED_IN,
  payload: {
    jwt,
    projectId
  }
});

export const GATEWAY_USER_SIGNED_IN = "gateway_user_signed_in";

export default gatewayUserSignedIn;
