import Avvir from "avvir";

import getFloorId from "../../services/getters/floor_getters/get_floor_id";
import getFloorIdFromLocation from "../../services/getters/location_metadata/get_floor_id_from_location";
import getProjectIdFromLocation from "../../services/getters/location_metadata/get_project_id_from_location";
import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import progressReportPdfAllDates, { ProgressReportPdfAllDatesEvent } from "../../events/progress_report_pdf_all_dates";
import progressReportPdfCurrentDate, { ProgressReportPdfCurrentDateEvent } from "../../events/progress_report_pdf_current_date";
import progressReportPdfDateRange, { ProgressReportPdfDateRangeEvent } from "../../events/progress_report_pdf_date_range";
import triggerFileDownload from "../../services/utilities/general/trigger_file_download";

const exportProgressReport = (startDate: Date, endDate: Date, progressReportDateOption: "all_dates" | "current_date" | "date_range"): AvvirThunk<void, ProgressReportPdfAllDatesEvent | ProgressReportPdfCurrentDateEvent | ProgressReportPdfDateRangeEvent> => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = getProjectIdFromLocation(state, {});
    const floorId = getFloorIdFromLocation(state, {}) || getFloorId(state, {});
    const user = state.user;
    let url = Avvir.api.other.getProgressReportPdfUrl(projectId, floorId, user);
    if (startDate != null) {
      url = `${url}&startDate=${startDate.toISOString()}`
    }
    if (endDate != null) {
      url = `${url}&endDate=${endDate.toISOString()}`;
    }
    const fileName = "ProjectProgressReportPdf";
    triggerFileDownload(url, fileName);

    if (progressReportDateOption === "all_dates") {
      dispatch(progressReportPdfAllDates())
    }
    else if (progressReportDateOption === "current_date") {
      dispatch(progressReportPdfCurrentDate())
    }
    else if (progressReportDateOption === "date_range") {
      dispatch(progressReportPdfDateRange())
    }
  };
};

export default makeEventfulAction("exportProgressReport", exportProgressReport);
