import { AvvirEvent } from "type_aliases";

export type ScanDatasetCreatedEvent = AvvirEvent<typeof SCAN_DATASET_CREATED, {
  firebaseFloorId: string,
  firebaseId: string,
  scanNumber: number,
  scanDate: number
}>

const scanDatasetCreated = ({ firebaseFloorId, firebaseId, scanNumber, scanDate }: { firebaseFloorId: string, firebaseId: string, scanNumber: number, scanDate?: number }): ScanDatasetCreatedEvent => ({
  type: SCAN_DATASET_CREATED,
  payload: { firebaseFloorId, firebaseId, scanNumber, scanDate }
});

export const SCAN_DATASET_CREATED = "scan_dataset_created";

export default scanDatasetCreated;
