import { Reducer } from "redux";
import _ from "underscore";

import InspectionModeConverter from "../converters/inspection_mode_converter";
import PageNames from "../../models/domain/enums/page_names";
import PanelType from "../../models/domain/enums/panel_type";
import { BIM_MINIMAP_POINT_SELECTED, BimMinimapPointSelectedEvent } from "../../events/photos/bim_minimap_point_selected";
import { COLOR_MODE_SELECTED, ColorModeSelectedEvent } from "../../events/selection/color_mode_selected";
import { ColorMode, inspectionModeToColorMode } from "../../models/domain/enums/color_modes";
import { DEVIATION_EDITING_MODE_TOGGLED, DeviationEditingModeToggledEvent } from "../../events/selection/deviation_editing_mode_toggled";
import { INSPECTION_MODE_SELECTED, InspectionModeSelectedEvent } from "../../events/selection/inspection_mode_selected";
import { InspectionMode } from "../../models/domain/enums/inspection_modes";
import { isRoutingEvent } from "../routing_events";
import { Layer } from "../../components/viewer/panels/minimap/viewer_minimap";
import { MAP_LAYER_HIDDEN, MapLayerHiddenEvent } from "../../events/selection/map_panel/map_layer_hidden";
import { MAP_LAYER_SHOWN, MapLayerShownEvent } from "../../events/selection/map_panel/map_layer_shown";
import { MASTERFORMAT_FILTER_SELECTED, MasterformatFilterSelectedEvent, MasterformatProgressFilter } from "../../events/selection/masterformat_filter_selected";
import { MASTERFORMAT_SELECTED, MasterformatSelectedEvent } from "../../events/selection/masterformat_selected";
import { OBSTRUCTION_MARKING_FINISHED, ObstructionMarkingFinishedEvent } from "../../events/selection/obstruction_marking_finished";
import { OBSTRUCTION_MARKING_STARTED, ObstructionMarkingStartedEvent } from "../../events/selection/obstruction_marking_started";
import { PANEL_HIDDEN, PanelHiddenEvent } from "../../events/selection/panel_hidden";
import { PANEL_NODES_CHANGED, PanelNodesChangedEvent } from "../../events/selection/panel_nodes_changed";
import { PANEL_SHOWN, PanelShownEvent } from "../../events/selection/panel_shown";
import { PANELS_RESET, PanelsResetEvent } from "../../events/selection/panels_reset";
import { PHOTO_AREA_MINIMAP_LOADED, PhotoAreaMinimapLoadedEvent } from "../../events/loaded/photo_area_minimap_loaded";
import { PHOTO_LOCATION_SELECTED, PhotoLocationSelectedEvent } from "../../events/selection/photos/photo_location_selected";
import { PHOTO_LOCATIONS_DELETED, PhotoLocationsDeletedEvent } from "../../events/viewer/photo_locations_deleted";
import { PHOTO_SESSION_SELECTED, PhotoSessionSelectedEvent } from "../../events/selection/photos/photo_session_selected";
import { REVIEW_MODE_TOGGLED_OFF, ReviewModeToggledOffEvent } from "../../events/viewer/review_mode_toggled_off";
import { REVIEW_MODE_TOGGLED_ON, ReviewModeToggledOnEvent } from "../../events/viewer/review_mode_toggled_on";
import { ROTATION_LOCKED_AROUND_POINT, RotationLockedAroundPointEvent } from "../../events/viewer/rotation_locked_around_point";
import { ROTATION_UNLOCKED, RotationUnlockedEvent } from "../../events/viewer/rotation_unlocked";
import { SECTION_POINT_CLOUD_TOGGLED_OFF, SectionPointCloudToggledOffEvent } from "../../events/viewer/section_point_cloud_toggled_off";
import { SECTION_POINT_CLOUD_TOGGLED_ON, SectionPointCloudToggledOnEvent } from "../../events/viewer/section_point_cloud_toggled_on";
import { SHOW_POINT_CLOUD_TOGGLED, ShowPointCloudToggledEvent } from "../../events/selection/show_point_cloud_toggled";
import { TO_VIEWER_FLOOR, ToViewerFloorEvent } from "../../events/routing/to_viewer_floor";
import { TO_VIEWER_SCAN_DATASET, ToViewerScanDatasetEvent } from "../../events/routing/to_viewer_scan_dataset";
import { TRADE_SELECTED, TradeSelectedEvent } from "../../events/selection/trades/trade_selected";
import { VIEW_LOADED, ViewLoadedEvent } from "../../events/loaded/view_loaded";
import { WBS_AVAILABILITY_LOADED, WbsAvailabilityLoadedEvent } from "../../events/loaded/wbs/wbs_availability_loaded";
import { without } from "../utilities/general";

import type { Vector2Like } from "type_aliases";

export type SelectionStore = {
  inspectionMode?: InspectionMode
  colorMode?: ColorMode
  deviationEditingMode?: boolean
  showPointCloud?: boolean
  sectionPointCloud?: boolean
  masterformat?: string
  masterformatFilter: MasterformatProgressFilter
  recipeId?: number
  recipeStepId?: number
  photoSessions: number[]
  isRotationLocked: boolean
  bimMinimapPoints?: { floorId: string, coordinates: Vector2Like }[]
  photoAreaMinimapPoints?: { floorId: string, coordinates: Vector2Like }[]
  photoAreaMinimapSize?: { size: Vector2Like }
  fenceSelectedPhotoLocationIds?: number[]
  wbsAvailableProjects: string[]
  panelsByPage?: { [page in PageNames]?: PanelType[] }
  layers: { [key: string]: Layer }
  showReviewColors?: boolean
  obstructingGlobalId?: string,
  trade: string
}

type SelectionEvents =
  | ToViewerFloorEvent
  | ToViewerScanDatasetEvent
  | BimMinimapPointSelectedEvent
  | DeviationEditingModeToggledEvent
  | InspectionModeSelectedEvent
  | ColorModeSelectedEvent
  | MasterformatSelectedEvent
  | MasterformatFilterSelectedEvent
  | PhotoAreaMinimapLoadedEvent
  | RotationLockedAroundPointEvent
  | RotationUnlockedEvent
  | ShowPointCloudToggledEvent
  | SectionPointCloudToggledOffEvent
  | SectionPointCloudToggledOnEvent
  | ViewLoadedEvent
  | PhotoLocationSelectedEvent
  | WbsAvailabilityLoadedEvent
  | PhotoLocationsDeletedEvent
  | PanelNodesChangedEvent
  | PanelHiddenEvent
  | PanelShownEvent
  | ReviewModeToggledOnEvent
  | ReviewModeToggledOffEvent
  | ObstructionMarkingStartedEvent
  | ObstructionMarkingFinishedEvent
  | MapLayerHiddenEvent
  | MapLayerShownEvent
  | PanelsResetEvent
  | PhotoSessionSelectedEvent
  | TradeSelectedEvent

export const defaultSelection: SelectionStore = {
  showPointCloud: false,
  sectionPointCloud: false,
  isRotationLocked: false,
  wbsAvailableProjects: [],
  photoSessions: [],
  inspectionMode: InspectionMode.progress,
  masterformatFilter: {
    issuesOnly: false,
    inProgress: true,
    notStarted: true,
    completed: true
  },
  layers: {
    photos: { name: "Photos", enabled: true },
    sharedViews: { name: "Shared Views", enabled: true },
    clashes: { name: "Clashes", enabled: false },
    floorPlan: { name: "Floor Plan", enabled: true },
  },
  trade: null
};

const reduceSelection: Reducer<SelectionStore, SelectionEvents> = (selection: SelectionStore = defaultSelection, event) => {
  if (isRoutingEvent(event)) {
    if (event.payload?.query?.photoSessionId) {
      if (event.payload.query.photoSessionId.includes(",")) {
        const photoSessionIds = event.payload.query.photoSessionId.split(",").map(parseFloat);
        selection = {
          ...selection,
          photoSessions: photoSessionIds
        };
      } else {
        const photoSessionId = parseInt(event.payload.query.photoSessionId);
        if (selection.photoSessions.length !== 1 || selection.photoSessions[0] !== photoSessionId) {
          selection = {
            ...selection,
            photoSessions: [photoSessionId]
          };
        }
      }
    }
  }
  switch (event?.type) {
    case TO_VIEWER_FLOOR:
    case TO_VIEWER_SCAN_DATASET: {
      if (selection.showPointCloud) {
        return {
          ...selection,
          showPointCloud: false
        };
      } else {
        return selection;
      }
    }
    case INSPECTION_MODE_SELECTED: {
      return {
        ...selection,
        inspectionMode: event.payload,
        colorMode: inspectionModeToColorMode(event.payload)
      };
    }
    case COLOR_MODE_SELECTED: {
      return {
        ...selection,
        colorMode: event.payload,
      };
    }
    case PHOTO_SESSION_SELECTED: {
      if (Array.isArray(event.payload.photoSessionId)) {
        return {
          ...selection,
          photoSessions: event.payload.photoSessionId
        };
      } else {
        return {
          ...selection,
          photoSessions: [event.payload.photoSessionId]
        };
      }
    }
    case VIEW_LOADED: {
      return {
        ...selection,
        inspectionMode: InspectionModeConverter.toInspectionMode(event.payload.viewAttributes.inspectionMode),
        showPointCloud: event.payload.viewAttributes.filters.pointCloudVisible,
        sectionPointCloud: event.payload.viewAttributes.filters.sectionPointCloud
      };
    }
    case SHOW_POINT_CLOUD_TOGGLED: {
      return {
        ...selection,
        showPointCloud: !selection.showPointCloud
      };
    }
    case SECTION_POINT_CLOUD_TOGGLED_OFF: {
      return {
        ...selection,
        sectionPointCloud: false
      };
    }
    case SECTION_POINT_CLOUD_TOGGLED_ON: {
      return {
        ...selection,
        sectionPointCloud: true
      };
    }
    case DEVIATION_EDITING_MODE_TOGGLED: {
      return {
        ...selection,
        deviationEditingMode: !selection.deviationEditingMode
      };
    }
    case MASTERFORMAT_SELECTED: {
      return {
        ...selection,
        ...event.payload
      };
    }
    case MASTERFORMAT_FILTER_SELECTED: {
      return {
        ...selection,
        masterformatFilter: { ...selection.masterformatFilter, ...event.payload }
      };
    }
    case ROTATION_LOCKED_AROUND_POINT: {
      return {
        ...selection,
        isRotationLocked: true
      };
    }
    case ROTATION_UNLOCKED: {
      return {
        ...selection,
        isRotationLocked: false
      };
    }
    case BIM_MINIMAP_POINT_SELECTED: {
      return {
        ...selection,
        bimMinimapPoints: [...(selection.bimMinimapPoints || []), event.payload]
      };
    }
    case PHOTO_AREA_MINIMAP_LOADED: {
      return {
        ...selection,
        photoAreaMinimapSize: event.payload
      };
    }
    case PHOTO_LOCATIONS_DELETED: {
      if (event.payload) {
        return { ...selection, fenceSelectedPhotoLocationIds: [] };
      }
      return selection;
    }
    case PHOTO_LOCATION_SELECTED: {
      if (event.payload.fenceSelectedPhotoLocationIds == null) {
        // Clear fence selected ids if primary selected photo isn't in the fence selectionStore
        if (selection.fenceSelectedPhotoLocationIds != null
            && selection.fenceSelectedPhotoLocationIds.length > 0
            && !selection.fenceSelectedPhotoLocationIds.includes(event.payload.photoLocationId)) {
          return { ...selection, fenceSelectedPhotoLocationIds: [] };
        }
      } else {
        return {
          ...selection,
          fenceSelectedPhotoLocationIds: [...event.payload.fenceSelectedPhotoLocationIds]
        };
      }

      return selection;
    }
    case WBS_AVAILABILITY_LOADED: {
      if (event.payload.isWbsAvailable) {
        return {
          ...selection,
          wbsAvailableProjects: _.union(selection.wbsAvailableProjects, [event.payload.projectId])
        };
      } else {
        return selection;
      }
    }
    case PANEL_HIDDEN: {
      let panelsForPage = selection.panelsByPage[event.payload.page];
      return {
        ...selection,
        panelsByPage: {
          ...selection.panelsByPage,
          [event.payload.page]: without(panelsForPage, [event.payload.panel])
        }
      };
    }
    case PANEL_SHOWN: {
      return {
        ...selection,
        panelsByPage: {
          ...selection.panelsByPage,
          [event.payload.page]: [...(selection.panelsByPage?.[event.payload.page] || []), event.payload.panel]
        }
      };
    }
    case PANELS_RESET: {
      return {
        ...selection,
        panelsByPage: {
          ...selection.panelsByPage,
          [event.payload.page]: []
        }
      };
    }
    case PANEL_NODES_CHANGED: {
      const sameNumberOfPanels = selection.panelsByPage?.[event.payload.page]?.length === event.payload.panels.length;
      const allPanelsAlreadyVisible = _.every(selection.panelsByPage?.[event.payload.page], (panel) => event.payload.panels.includes(panel));
      if (sameNumberOfPanels && allPanelsAlreadyVisible) {
        return selection;
      } else {
        return {
          ...selection,
          panelsByPage: {
            ...selection.panelsByPage,
            [event.payload.page]: event.payload.panels
          }
        };
      }
    }
    case MAP_LAYER_HIDDEN: {
      let layerId = event.payload.layerId;
      let layers = { ...selection.layers };
      layers[layerId] = { ...layers[layerId], enabled: false };
      return {
        ...selection,
        layers
      };
    }
    case MAP_LAYER_SHOWN: {
      let layerId = event.payload.layerId;
      let layers = { ...selection.layers };
      layers[layerId] = { ...layers[layerId], enabled: true };
      return {
        ...selection,
        layers
      };
    }
    case REVIEW_MODE_TOGGLED_ON: {
      return {
        ...selection,
        showReviewColors: true
      };
    }
    case REVIEW_MODE_TOGGLED_OFF: {
      return {
        ...selection,
        showReviewColors: false
      };
    }
    case OBSTRUCTION_MARKING_STARTED: {
      return {
        ...selection,
        obstructingGlobalId: event.payload.obstructingElementGlobalId
      };
    }
    case OBSTRUCTION_MARKING_FINISHED: {
      return {
        ...selection,
        obstructingGlobalId: null
      };
    }
    case TRADE_SELECTED: {
      return {
        ...selection,
        trade: event.payload.code
      };
    }
    default: {
      return selection;
    }
  }
};

export function getSelectionToSave(store: SelectionStore) {
  return {
    ...defaultSelection,
    inspectionMode: store.inspectionMode,
  };
}

export default reduceSelection;
