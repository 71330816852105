import { Matrix3, Matrix4 } from "three";

const computePhotoSphereToViewerWorldRotation = (photoSphereToPhotoAreaRotation: Matrix4, photoAreaMinimapToViewer: Matrix3, viewerCorrectionYaw: number) : Matrix4 => {
  let cosT = photoAreaMinimapToViewer.elements[0]
  let sinT = photoAreaMinimapToViewer.elements[1]
  let magnitude = Math.sqrt(cosT**2 + sinT**2)
  cosT /= magnitude
  sinT /= magnitude
  const viewerCorrectionRotation = new Matrix4().makeRotationZ(viewerCorrectionYaw)
  const photoAreaToViewerRotation = new Matrix4().set(cosT, -sinT, 0.0, 0.0,
                                                      sinT, cosT, 0.0, 0.0,
                                                      0.0, 0.0, 1.0, 0.0,
                                                      0.0, 0.0, 0.0, 1.0
                                                     );
  const photoSphereToViewerRotation = new Matrix4().multiplyMatrices(photoAreaToViewerRotation, photoSphereToPhotoAreaRotation)
  return new Matrix4().multiplyMatrices(viewerCorrectionRotation, photoSphereToViewerRotation)
}

export default computePhotoSphereToViewerWorldRotation;