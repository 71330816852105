import getGroupMemberGlobalIdsForFloor from "../../services/getters/group_member_getters/get_group_member_global_ids_for_floor";
import getSelectedElementGlobalIds from "../../services/getters/planned_building_element_getters/element_selection_getters/get_selected_element_global_ids";
import getSelectedElements from "../../services/getters/planned_building_element_getters/element_selection_getters/get_selected_elements";

import type { AvvirThunk } from "../make_eventful_action";

const isGroupingAllowed = (): AvvirThunk<Promise<boolean | Error>, null> => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedGlobalIds = getSelectedElementGlobalIds(state, {});
    const groupMemberGlobalIds = getGroupMemberGlobalIdsForFloor(state, {});
    const selectedElements = getSelectedElements(state, {});
    const noElementsSelected = selectedElements == null || selectedElements.length === 0;

    const selectedGlobalIdsInGroups = selectedGlobalIds.filter(groupMemberGlobalIds.has, groupMemberGlobalIds);
    const groupingNotAllowed = !!selectedGlobalIdsInGroups.length || noElementsSelected;
    if (groupingNotAllowed) {
      let errorMessage = noElementsSelected ? "No elements selected" : "Element(s) already in group";
      return Promise.reject(new Error(errorMessage));

    }
    return Promise.resolve(true);
  };
};

export default isGroupingAllowed;
