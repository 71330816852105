import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ToAcceptInvitationPageEvent = AvvirPayloadlessRoutingEvent<typeof TO_ACCEPT_INVITATION_PAGE>

const toAcceptInvitationPage = (token: string, email: string): ToAcceptInvitationPageEvent => ({
  type: TO_ACCEPT_INVITATION_PAGE,
  payload: {
    query: {
      token,
      email
    }
  }
});

export const TO_ACCEPT_INVITATION_PAGE = "to_accept_invitation_page";

export default toAcceptInvitationPage;
