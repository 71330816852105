import { AvvirEvent } from "type_aliases";

export type ProjectReportRemovedEvent = AvvirEvent<typeof PROJECT_REPORT_REMOVED, {projectId: string, projectReportId: number}>

const projectReportRemoved = (projectId: string, projectReportId: number): ProjectReportRemovedEvent => ({
  type: PROJECT_REPORT_REMOVED,
  payload: {
    projectId,
    projectReportId
  }
});

export const PROJECT_REPORT_REMOVED = "project_report_removed";

export default projectReportRemoved;
