import React, { FunctionComponent, ReactElement, useMemo } from "react";
import CSS from "csstype";
import Grow from "@mui/material/Grow";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import withStyles, { CSSProperties } from "@mui/styles/withStyles";
import { TransitionProps } from "@mui/material/transitions";

export type Props = {
  className?: string
  interactive?: boolean
  offsetX?: number
  offsetY?: number
  backgroundColor?: string
  maxWidth?: CSS.Property.MaxWidth
} & Omit<TooltipProps, "disableInteractive" | "className" >

const Tooltipable: FunctionComponent<Props> = (props) => {
  const {
    backgroundColor,
    maxWidth,
    offsetX,
    offsetY: offsetYProps,
    placement,
    className,
    interactive,
    children,
    PopperProps,
    ...otherProps
  } = props;
  const TooltipComponent = useMemo(() => {
    let styles: CSSProperties = { maxWidth };
    if (backgroundColor) {
      styles.backgroundColor = backgroundColor;
    }
    return withStyles(() => ({
      tooltip: styles,
    }))(Tooltip);
  }, [backgroundColor, maxWidth]);

  const offsetY = offsetYProps - 14;
  let popperModifiers: typeof PopperProps.modifiers = PopperProps?.modifiers || [];

  const offset: [number, number] = useMemo(() => {
    if (placement.startsWith("top") || placement.startsWith("bottom")) {
      return [offsetX || 0, offsetY || 0];
    } else {
      return [offsetY || 0, offsetX || 0];
    }
  }, [offsetY, offsetX, placement]);
  return <TooltipComponent
    className={className}
    disableInteractive={!interactive}
    placement={placement}
    PopperProps={{
      ...PopperProps,
      modifiers: [
        ...popperModifiers,
        {
          name: "offset",
          options: {
            offset
          },
        },
      ]
    }}
    {...otherProps}
  >
    {children}
  </TooltipComponent>;
};

Tooltipable.defaultProps = {
  maxWidth: "300px",
  enterDelay: 500,
  enterTouchDelay: 1000,
  interactive: false,
  leaveDelay: 0,
  leaveTouchDelay: 1500,
  offsetX: 0,
  offsetY: 4,
  placement: "bottom-start",
  PopperProps: { style: { pointerEvents: "none" } },
  TransitionComponent: Grow as (props: TransitionProps) => ReactElement
};

export default React.memo(Tooltipable);
