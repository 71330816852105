import { immerable } from "immer";
import { ProjectWorkPackageType } from "avvir";

import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";

export default class WorkPackage {
  constructor({ id, name, firebaseProjectId, type }: Partial<WorkPackage> = {}) {
    addReadOnlyPropertiesToModel(this, { id, firebaseProjectId, type });
    this.name = name;
  }

  readonly id: number;
  name: string;
  readonly firebaseProjectId: string;
  readonly type: ProjectWorkPackageType;

  static readonly [immerable] = true;
}
