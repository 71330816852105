import { createTree } from "./create_tree";
import MasterformatProgressConverter from "../converters/masterformat_progress_converter";
import { MasterformatLevel } from "masterformat";

let levelSlice = [
  2,
  4,
  5,
  8,
  13
];
export const createMasterformatDivisionTree = (masterformats: any) => {
  return createTree(masterformats, 1, (thisKey: string, thatKey: string, depth) => {
    let slice = thisKey.slice(0, levelSlice[depth - 1]);
    return thatKey.toString().startsWith(slice);
  }, (value, key, depth) => {
    let formattedCode = MasterformatProgressConverter.getFormatedLevelId(key, depth as MasterformatLevel);
    if (formattedCode == null) {
      return null;
    } else {
      return {
        level: depth,
        code: formattedCode.length < 2 ? formattedCode + "0" : formattedCode,
        description: value
      };
    }
  },);
};
