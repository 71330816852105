import { parse } from "csv-parse";
import { ApiElementDeviation, isDeviationScanResult } from "avvir";

import DetailedElement from "../../models/domain/detailed_element";
import PlannedBuildingElement from "../../models/domain/planned_building_element";
import withPromise from "../utilities/general/with_promise";
import { DEVIATED, IN_PLACE, NOT_BUILT } from "../../models/domain/enums/scan_label";

const floatColumns = new Set(["primaryMeasurement", "unitCost"]);
const integerColumns = new Set(["floorSpan", "subcontractorId"]);

class PlannedBuildingElementConverter {
  static tsvToPlannedBuildingElement(tsvContent: string): Promise<PlannedBuildingElement[]> {
    const [readSuccess, readError, readingContent] = withPromise<PlannedBuildingElement[]>();

    parse(tsvContent, {
      bom: true,
      delimiter: "\t",
      trim: true,
      relaxColumnCount: true,
      fromLine: 2,
      relaxQuotes: true,
      columns: ["globalId",
        "name",
        "uniformat",
        "masterformat",
        "ifcType",
        "discipline",
        "navisworksGuid",
        "primaryMeasurement",
        "primaryUnitOfMeasurement",
        "floorSpan",
        "subcontractorId",
        "revitCategory",
        "revitFamily",
        "revitType",
        "unitCost",
        "excludeFromAnalysis",
      ],
      cast: (value, context) => {
        if (context.header) {
          return value;
        } else if (context.column) {
          if (floatColumns.has(context.column as string)) {
            return parseFloat(value);
          } else if (integerColumns.has(context.column as string)) {
            return parseInt(value, 10);
          } else if (context.column === "uniformat") {
            return value || null;
          } else if (context.column === "excludeFromAnalysis") {
            return value === "EXCLUDE";
          } else {
            return value;
          }
        }
      },
      onRecord: (record, context) => new PlannedBuildingElement(record)
    }, (err, records: PlannedBuildingElement[]) => {
      if (err) {
        readError(err);
      } else {
        readSuccess(records);
      }
    });

    return readingContent;
  };

  static detailedElementToPlannedBuildingElement(detailedElement: DetailedElement) {
    let deviation: ApiElementDeviation = null;
    if (isDeviationScanResult((detailedElement.scanResult))) {
      deviation = detailedElement.scanResult.deviation;
    }
    return new PlannedBuildingElement({
      globalId: detailedElement.globalId,
      name: detailedElement.name,
      ifcType: detailedElement.ifcType,
      uniformat: detailedElement.uniformat,
      masterformat: detailedElement.masterformat,
      revitCategory: detailedElement.revitCategory,
      revitFamily: detailedElement.revitFamily,
      revitType: detailedElement.revitType,
      itemId: detailedElement.itemId,
      discipline: detailedElement.discipline,
      primaryUnitOfMeasurement: detailedElement.primaryUnitOfMeasurement,
      primaryMeasurement: detailedElement.primaryMeasurement,
      navisworksGuid: detailedElement.navisworksGuid,
      issueId: detailedElement.issueId,
      excludeFromAnalysis: detailedElement.excludeFromAnalysis,
      builtAt: detailedElement.builtAt,
      deviation,
      fixedAt: detailedElement.fixedAt,
      fixedDeviation: detailedElement.fixedDeviation,
      isNew: detailedElement.isNew,
      builtStatus: detailedElement.builtAt == null ? NOT_BUILT : deviation == null ? IN_PLACE : DEVIATED,
      loaded: detailedElement.loaded,
      verified: detailedElement.verified,
      exportedToBcf: detailedElement.exportedToBcf,
      partialProgressPercent: detailedElement.partialProgressPercent,
      progressHistory: detailedElement.progressHistory
    });
  }
}

export default PlannedBuildingElementConverter;
