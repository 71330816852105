import { Reducer } from "redux";
import { Draft, produce } from "immer";
import _ from "underscore";

import TradeCost from "../../../models/domain/trade_cost";
import { CAPTURED_TRADE_COSTS_LOADED, CapturedTradeCostsLoadedEvent } from "../../../events/loaded/trade/captured_trade_costs_loaded";
import { PLANNED_TRADE_COSTS_LOADED, PlannedTradeCostsLoadedEvent } from "../../../events/loaded/trade/planned_trade_costs_loaded";
import { REPORTED_QUANTITY_UPDATED, ReportedQuantityUpdatedEvent } from "../../../events/reported_quantity_updated";
import { TRADE_COSTS_LOADED, TradeCostsLoadedEvent } from "../../../events/loaded/trade/trade_costs_loaded";

import type ApiCapturedTradeCost from "../../../models/api/api_captured_trade_cost";
import type ApiPlannedTradeCost from "../../../models/api/api_planned_trade_cost";
import type ApiTradeCost from "../../../models/api/api_trade_cost";

type TradeCostsEvents =
  | PlannedTradeCostsLoadedEvent
  | CapturedTradeCostsLoadedEvent
  | TradeCostsLoadedEvent
  | ReportedQuantityUpdatedEvent;

export type TradeCostsStore = {
  byFirebaseProjectId: { [projectId: string]: { byCode: { [code: string]: TradeCost } } }
}

const addOrUpdateTradeCosts = (tradeCosts: Draft<TradeCostsStore>, projectId: string, apiTradeCosts: ApiPlannedTradeCost[] | ApiCapturedTradeCost[] | ApiTradeCost[]) => {
  const costsByCode = _(apiTradeCosts).indexBy("tradeCode") as { [code: string]: ApiPlannedTradeCost | ApiCapturedTradeCost | ApiTradeCost };

  if (!tradeCosts.byFirebaseProjectId[projectId]) {
    tradeCosts.byFirebaseProjectId[projectId] = { byCode: {} };
  }

  _(costsByCode).forEach((cost, code) => {
    const currentTradeCost = tradeCosts.byFirebaseProjectId[projectId].byCode[code];
    tradeCosts.byFirebaseProjectId[projectId].byCode[code] = new TradeCost({
      ...currentTradeCost,
      ...cost
    });
  });

  return tradeCosts;
};

const reduceTradeCosts: Reducer<TradeCostsStore, TradeCostsEvents> = (tradeCosts: TradeCostsStore = { byFirebaseProjectId: {} }, event) => {
  return produce<TradeCostsStore>(tradeCosts, (tradeCostsDraft) => {
    if (!tradeCostsDraft.byFirebaseProjectId) {
      tradeCostsDraft.byFirebaseProjectId = {};
    }
    switch (event.type) {
      case PLANNED_TRADE_COSTS_LOADED: {
        return addOrUpdateTradeCosts(tradeCostsDraft, event.payload.projectId, event.payload.tradeCosts);
      }
      case CAPTURED_TRADE_COSTS_LOADED: {
        return addOrUpdateTradeCosts(tradeCostsDraft, event.payload.projectId, event.payload.tradeCosts);
      }
      case TRADE_COSTS_LOADED: {
        return addOrUpdateTradeCosts(tradeCostsDraft, event.payload.projectId, event.payload.tradeCosts);
      }
      case REPORTED_QUANTITY_UPDATED: {
        return addOrUpdateTradeCosts(tradeCostsDraft, event.payload.projectId, [event.payload.tradeCost]);
      }
      default: {
        return tradeCostsDraft;
      }
    }
  });
};

export default reduceTradeCosts;

