import { AvvirEvent } from "type_aliases";
import { ApiInspectReport } from "avvir";

export type InspectReportCreatedEvent = AvvirEvent<typeof INSPECT_REPORT_CREATED, { projectId: string, inspectReport: ApiInspectReport }>

const inspectReportCreated = (projectId: string, inspectReport: ApiInspectReport) =>({
  type: INSPECT_REPORT_CREATED,
  payload: {
    projectId,
    inspectReport
  }
})

export const INSPECT_REPORT_CREATED = "inspect_report_created";

export default inspectReportCreated;
