import apiFailure from "../../events/notifications/failures/api_failure";
import { Dispatch, ErrorLike, EventCreator } from "type_aliases";
import { ResponseError, CustomError } from "avvir";
import signOutUser from "../../actions/sign_in_page/sign_out_user";

type DefaultErrorCallbackEvent<Type extends string, Payload> = EventCreator<Type, Payload> | typeof apiFailure

const getErrorCallback = <Type extends string, Payload>(dispatch: Dispatch<ReturnType<DefaultErrorCallbackEvent<Type, Payload>>>, throwError: boolean = true, event: DefaultErrorCallbackEvent<Type, Payload> = apiFailure): (error: ErrorLike, action?: string) => never => {
  return ((error, action?: string) => {
    if (error instanceof ResponseError && error.status === 401) {
      // TODO: There's probably some bigger issue if we're logging out a user based on a response status
      dispatch(signOutUser());
    }

    if (error instanceof CustomError) {
      if (error.displayMessageToUI) {
        dispatch(event(error, action));
      }
    }

    if (throwError) {
      throw error;
    }
  }) as (error: Error) => never;
};

export default getErrorCallback;
