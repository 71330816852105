import { Action } from "redux";

export type VerifiedElementsShownEvent = Action<typeof VERIFIED_ELEMENTS_SHOWN>

const verifiedElementsShown = (): VerifiedElementsShownEvent => ({
  type: VERIFIED_ELEMENTS_SHOWN,
});

export const VERIFIED_ELEMENTS_SHOWN = "verified_elements_shown";

export default verifiedElementsShown;