import { AvvirEvent } from "type_aliases";
import { ApiPhotoLocation } from "avvir";

export type PhotoLocationsDeletedEvent = AvvirEvent<typeof PHOTO_LOCATIONS_DELETED, ApiPhotoLocation[]>

const photoLocationsDeleted = (payload: ApiPhotoLocation[]): PhotoLocationsDeletedEvent => ({
  type: PHOTO_LOCATIONS_DELETED,
  payload
});

export const PHOTO_LOCATIONS_DELETED = "photo_locations_deleted";

export default photoLocationsDeleted;
