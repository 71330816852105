import { AnyAction, Dispatch, Middleware } from "redux";

import getFloorId from "../getters/floor_getters/get_floor_id";
import getFloorNumber from "../getters/floor_getters/get_floor_number";
import getPlannedElementsByGlobalId from "../getters/planned_building_element_getters/get_planned_elements_by_global_id";
import getProject from "../getters/project_getters/get_project";
import getProjectId from "../getters/project_getters/get_project_id";
import getScanDatasetId from "../getters/scan_dataset_getters/get_scan_dataset_id";
import PlannedBuildingElement from "../../models/domain/planned_building_element";
import realMixpanel, { Mixpanel } from "./init_mixpanel";
import { ACCEPT_INVITATION_STARTED } from "../../events/auth_events/accept_invitation_started";
import { AvvirEvent } from "type_aliases";
import { DETAILED_ELEMENTS_LOADED } from "../../events/loaded/building_elements/detailed_elements_loaded";
import { ELEMENT_FOCUSED } from "../../events/viewer/element_focused";
import { ELEMENT_SELECTED } from "../../events/viewer/element_selected";
import { ELEMENTS_EDITED } from "../../events/viewer/elements_edited";
import { EXPORT_IFC_TRIGGERED } from "../../events/export_ifc_triggered";
import { EXPORT_BATCH_IFC_TRIGGERED } from "../../events/export_batch_ifc_triggered";
import { FILE_UPLOAD_PROGRESSED } from "../../events/files/upload/file_upload_progressed";
import { INSPECTION_MODE_SELECTED } from "../../events/selection/inspection_mode_selected";
import { isRoutingEvent } from "../routing_events";
import { MIXPANEL_4D_UPSELL_DISPLAYED } from "../../events/mixpanel_4d_upsell_displayed";
import { MIXPANEL_4D_UPSELL_SALES_CONTACTED } from "../../events/mixpanel_4d_upsell_sales_contacted";
import { PHOTO_VIEWER_DISPOSED } from "../../events/viewer/photo_viewer_disposed";
import { PHOTO_VIEWER_INITIALIZED } from "../../events/viewer/photo_viewer_initialized";
import { PROGRESS_REPORT_PDF_ALL_DATES } from "../../events/progress_report_pdf_all_dates";
import { PROGRESS_REPORT_PDF_CURRENT_DATE } from "../../events/progress_report_pdf_current_date";
import { PROGRESS_REPORT_PDF_DATE_RANGE } from "../../events/progress_report_pdf_date_range";
import { ReduxStore } from "../reducers/root_reducer";
import { SIGN_IN_USER_STARTED } from "../../events/auth_events/sign_in_user_started";
import { TO_PROJECT } from "../../events/routing/to_project";
import { TO_VIEWER_SCAN_DATASET } from "../../events/routing/to_viewer_scan_dataset";
import { TOAST_NOTIFICATION_EXPIRED } from "../../events/notifications/toast_notification_expired";
import { TOAST_NOTIFICATION_EXPIRES_SOON } from "../../events/notifications/toast_notification_expires_soon";
import { VIEWER_DISPOSED } from "../../events/viewer/viewer_disposed";
import { VIEWER_INITIALIZED } from "../../events/viewer/viewer_initialized";

let mixpanel = realMixpanel;

export function mockMixpanel(mockedMixpanel: Mixpanel) {
  mixpanel = mockedMixpanel;
}

export function unmockMixpanel() {
  mixpanel = realMixpanel;
}

export const eventAliases = {
  "export_pushed_to_bim_ifc_started": "IFC Exported",
  [ELEMENT_FOCUSED]: "Element Zoomed To",
  [TO_PROJECT]: "Viewer Page Visited",
  "scan_path_marker_selected": "PhotoViewer Marker Selected",
  "push_pdf_to_procore_started": "Pdf Pushed To Procore",
  [MIXPANEL_4D_UPSELL_DISPLAYED]: "4D/5D Upsell Displayed",
  [MIXPANEL_4D_UPSELL_SALES_CONTACTED]: "4D/5D Upsell Sales Contacted",
  [ELEMENTS_EDITED]: "Deviation Edited",
  [SIGN_IN_USER_STARTED]: "Attempted Sign In",
  [ACCEPT_INVITATION_STARTED]: "Attempted New User Creation",
  [PROGRESS_REPORT_PDF_ALL_DATES]: "Progress Report PDF Viewed - All Scans",
  [PROGRESS_REPORT_PDF_DATE_RANGE]: "Progress Report PDF Viewed - Date Range",
  [PROGRESS_REPORT_PDF_CURRENT_DATE]: "Progress Report PDF Viewed - Current Scan"
};

function titleCaseWord(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

function friendlyName(eventType: string): string {
  if (eventAliases[eventType]) {
    return eventAliases[eventType];
  } else {
    return eventType.split("_").map(titleCaseWord).join(" ");
  }
}

const untrackedEvents = new Set([
  DETAILED_ELEMENTS_LOADED,
  TOAST_NOTIFICATION_EXPIRES_SOON,
  TOAST_NOTIFICATION_EXPIRED,
  FILE_UPLOAD_PROGRESSED,
  VIEWER_INITIALIZED,
  VIEWER_DISPOSED,
  PHOTO_VIEWER_INITIALIZED,
  PHOTO_VIEWER_DISPOSED
]);

function shouldTrackEvent(event: AnyAction) {
  return !!event.type && !untrackedEvents.has(event.type);
}

function getProjectIdFromEvent(event: AvvirEvent<any, any>, state: ReduxStore): string | null {
  if (event.payload?.projectId) {
    return event.payload.projectId;
  } else {
    return getProjectId(state, {});
  }
}

function getFloorIdFromEvent(event: AvvirEvent<any, any>, state: ReduxStore): string | null {
  if (event.payload?.floorId) {
    return event.payload.floorId;
  } else {
    return getFloorId(state, {});
  }
}

function getScanDatasetIdFromEvent(event: AvvirEvent<any, any>, state: ReduxStore): string | null {
  if (event.payload?.scanDatasetId) {
    return event.payload.scanDatasetId;
  } else {
    return getScanDatasetId(state, {});
  }
}

function getFloorNumberFromEvent(event: AvvirEvent<any, any>, state: ReduxStore): string | null {
  if (event.payload?.floorNumber) {
    return event.payload.floorNumber;
  } else if (event.payload?.floorId) {
    return getFloorNumber(state, { floorId: event.payload.floorId });
  } else {
    return getFloorNumber(state, {});
  }
}

function getScanNumberFromEvent(event: AvvirEvent<any, any>, state: ReduxStore): number {
  if (event.payload?.scanNumber) {
    return event.payload.scanNumber;
  } else {
    return state.domain.scanDatasets?.byFirebaseId?.[getScanDatasetIdFromEvent(event, state)]?.scanNumber;
  }
}

function getScanDateFromEvent(event: AvvirEvent<any, any>, state: ReduxStore): Date {
  if (event.payload?.scanDate) {
    return event.payload.scanDate;
  } else {
    return state.domain.scanDatasets?.byFirebaseId?.[getScanDatasetIdFromEvent(event, state)]?.scanDate;
  }
}

const eventTrackingMiddleware: Middleware<object, ReduxStore> = store => (next: Dispatch) => event => {
  const result = next(event);
  if (shouldTrackEvent(event)) {
    let eventName = friendlyName(event.type);
    try {
      const state = store.getState();
      const projectId = getProjectIdFromEvent(event, state);
      const projectName = getProject(state, {})?.name;

      let floorId, floorNumber, scanDatasetId, scanNumber, scanDate;
      if (!isRoutingEvent(event)) {
        floorId = getFloorIdFromEvent(event, state);
        floorNumber = getFloorNumberFromEvent(event, state);
        scanDatasetId = getScanDatasetIdFromEvent(event, state);
        scanNumber = getScanNumberFromEvent(event, state);
        scanDate = getScanDateFromEvent(event, state);
      }

      if (event.type === TO_VIEWER_SCAN_DATASET && event.payload?.query?.photoLocationId) {
        eventName = "To Photo Location";
      }

      const defaultEventProperties = {
        floorId,
        floorNumber,
        locationProjectId: state.locationMetadata.projectId,
        pageName: state.locationMetadata.pageName,
        projectId,
        projectName,
        reduxEventType: event.type,
        scanDatasetId,
        scanNumber
      };

      let eventProperties;
      if (event.type === ELEMENT_SELECTED || event.type === ELEMENT_FOCUSED) {
        const elementsByGlobalId = getPlannedElementsByGlobalId(state, {}) || {};
        const selectedElement = elementsByGlobalId[event.payload] as PlannedBuildingElement || {} as PlannedBuildingElement;
        const selectedElementName = selectedElement.name;
        const selectedElementDiscipline = selectedElement.discipline;
        const selectedElementUniformat = selectedElement.uniformat;
        const selectedElementIfcType = selectedElement.ifcType;
        const built = PlannedBuildingElement.isBuilt(selectedElement, scanDate);
        let deviationInfo;
        if (PlannedBuildingElement.isDeviated(selectedElement, scanDate)) {
          deviationInfo = {
            deviationMagnitude: PlannedBuildingElement.getDeviation(selectedElement, scanDate).deviationMeters,
            status: PlannedBuildingElement.getDeviation(selectedElement, scanDate).status
          }
        }

        eventProperties = {
          ...deviationInfo,
          built,
          selectedElementGlobalId: event.payload,
          selectedElementName,
          selectedElementDiscipline,
          selectedElementUniformat,
          selectedElementIfcType
        };
      } else if (event.type === ACCEPT_INVITATION_STARTED || event.type === SIGN_IN_USER_STARTED) {
        event.properties = event.payload;
      } else if (event.type === INSPECTION_MODE_SELECTED) {
        eventProperties = { inspectionMode: event.payload };
      } else if (typeof event.payload === "string") {
        eventProperties = { payload: event.payload };
      } else if ((event.type.endsWith("_failed") || event.type.endsWith("_failure")) && event.payload) {
        eventProperties = {
          errorType: event.payload.name,
          errorMessage: event.payload.message,
          errorStacktrace: event.payload.stack,
        };

      } else if (event.type === EXPORT_IFC_TRIGGERED){
        eventProperties = {
          ifcType: event.payload.ifcType,
          startTime: event.payload.startTime,
          processId: event.payload.processId
        }
      } else if (event.type === EXPORT_BATCH_IFC_TRIGGERED) {
        eventProperties = {
          ifcType: event.payload.ifcType,
          startTime: event.payload.startTime,
          processId: event.payload.processId
        }
      }
      // WARNING: be mindful of how large the payload you send to mixpanel is.
      // It can slow down user experience if you e.g. accidentally send up a pointcloud,
      // or fail altogether if the object has cycles and can't serialize

      mixpanel.track(eventName, {
        ...eventProperties,
        ...defaultEventProperties
      });
    } catch (err) {
      console.error(err);
      mixpanel.track(`tracking failed for ${eventName}`);
    }
  }
  return result;
};

export default eventTrackingMiddleware;
