import getProjectListings from "../../services/getters/project_getters/get_project_listings";
import getUser from "../../services/getters/base_getters/get_user";
import loadProjectListings from "../../actions/react_query/load_project_listings";
import useAvvirSelector from "../../services/component_helpers/component_effects/use_avvir_selector";
import useQueryWithState from "../use_query_with_state";

const props = {};
export const useProjectListings = () => {
  const enabled = !!useAvvirSelector(getUser);
  return useQueryWithState(
    ["useProjectListings"],
    loadProjectListings,
    getProjectListings,
    { enabled },
    props
  );
};

export default useProjectListings;
