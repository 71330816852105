export type InstanceTree = Autodesk.Viewing.InstanceTree;

// TODO: What's a reasonable way to test this?
export function getAllForgeInstanceTreeIds(tree: InstanceTree): number[] {
  const allDbIds = [];
  if (!tree) {
    console.warn("No instance tree found on the model");
    return allDbIds;
  }

  enumAllNodes(tree, (dbId) => {
    if (tree.getChildCount(dbId) === 0) {
      allDbIds.push(dbId);
    }
  });

  return allDbIds;
}

export function enumAllNodes(tree: InstanceTree, callback: (dbId: number) => void) {
  const rootId = tree.getRootId();
  tree.enumNodeChildren(
    rootId,
    callback,
    true
  );
}
