import { AvvirRoutingEvent } from "type_aliases";

export type ToInspectReportsEvent = AvvirRoutingEvent<typeof TO_INSPECT_REPORTS, { projectId: string }>

const toInspectReports = (projectId: string): ToInspectReportsEvent => ({
  type: TO_INSPECT_REPORTS,
  payload: {
    projectId
  }
});

export const TO_INSPECT_REPORTS = "to_inspect_reports";

export default toInspectReports;
