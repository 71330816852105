import { AvvirEvent } from "type_aliases";
import PanelType from "../../models/domain/enums/panel_type";
import PageNames from "../../models/domain/enums/page_names";

export type PanelHiddenEvent = AvvirEvent<typeof PANEL_HIDDEN, { page: PageNames, panel: PanelType }>

const panelHidden = (page: PageNames, panel: PanelType): PanelHiddenEvent => ({
  type: PANEL_HIDDEN,
  payload: {
    page,
    panel
  }
});

export const PANEL_HIDDEN = "panel_hidden";

export default panelHidden;
