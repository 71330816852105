import makeUpdateModel from "./make_update_model";
import { Reducer } from "redux";
import { INTEGRATION_PROJECTS_LOADED, IntegrationProjectsLoadedEvent } from "../../../events/integration_credentials/integration_projects_loaded";
import IntegrationProject from "../../../models/domain/integration_project";

export type IntegrationProjectEvent = IntegrationProjectsLoadedEvent

export type IntegrationProjectStore = {
  byId: {
    [id: number]: IntegrationProject
  },
  idsByCredentialsId: {
    [credentialsId: number]: number[]
  }
}

const updateIntegrationProject = makeUpdateModel<IntegrationProject>(new IntegrationProject({}));

function addToCredentialsIds(projectStore: IntegrationProjectStore, credentialsId: number, integrationProjectId: number) {
  let projectsByCredentialsId = projectStore.idsByCredentialsId[credentialsId];
  if (projectsByCredentialsId == null) {
    projectsByCredentialsId = [];
  } else {
    projectsByCredentialsId = [...projectsByCredentialsId];
  }
  if (projectsByCredentialsId.indexOf(integrationProjectId) < 0) {
    projectsByCredentialsId.push(integrationProjectId);
  }
  return {...projectStore, idsByCredentialsId: {...projectStore.idsByCredentialsId, [credentialsId]:projectsByCredentialsId}}
}

const reduceIntegrationProjects: Reducer<IntegrationProjectStore, IntegrationProjectEvent> = (
  integrationProjects: IntegrationProjectStore = { byId: {}, idsByCredentialsId: {} },
  event
) => {
  integrationProjects = integrationProjects?.byId ? integrationProjects : { byId: {}, idsByCredentialsId: {} };

  switch (event.type) {
    case INTEGRATION_PROJECTS_LOADED: {
      if (event.payload
          && event.payload.integrationProjects) {
        let store = integrationProjects;

        if (event.payload.integrationProjects.length !== 0) {
          event.payload.integrationProjects.forEach((project) => {
            store = updateIntegrationProject(store, project.id, new IntegrationProject(project));
            store = addToCredentialsIds(store, project.integrationCredentialsId, project.id);
          });
        }
        return store;
      }
      return integrationProjects;
    }
    default: {
      return integrationProjects;
    }
  }
};

export default reduceIntegrationProjects;
