import { createSelector } from "reselect";

import getFloorId from "./get_floor_id";
import getFloorsByFirebaseId from "./get_floors_by_firebase_id";

const getFloor = createSelector([
  getFloorsByFirebaseId,
  getFloorId
], (floorsByFirebaseId, floorId) => floorsByFirebaseId[floorId] || null);

export default getFloor;
