import _ from "underscore";
import { Reducer } from "redux";

import ElementEditAction, { isElementEditActionPayload } from "../../models/domain/element_edit_action";
import RoutingEvent, { isRoutingEvent } from "../routing_events";
import { ANNOTATION_HOVERED, AnnotationHoveredEvent } from "../../events/viewer/annotation_hovered";
import { ANNOTATION_UNHOVERED, AnnotationUnhoveredEvent } from "../../events/viewer/annotation_unhovered";
import { ELEMENT_COLORED, ElementColoredEvent } from "../../events/viewer/element_colored";
import { ELEMENT_DESELECTED, ElementDeselectedEvent } from "../../events/viewer/element_deselected";
import { ELEMENT_FOCUSED, ElementFocusedEvent } from "../../events/viewer/element_focused";
import { ELEMENT_SELECTED, ElementSelectedEvent } from "../../events/viewer/element_selected";
import { ELEMENT_SELECTION_CHANGED, ElementSelectionChangedEvent } from "../../events/viewer/element_selection_changed";
import { UNDOABLE_ACTION_REDONE, UndoableActionRedoneEvent } from "../../events/undo/undoable_action_redone";
import { UNDOABLE_ACTION_UNDONE, UndoableActionUndoneEvent } from "../../events/undo/undoable_action_undone";
import { VIEWER_DISPOSED, ViewerDisposedEvent } from "../../events/viewer/viewer_disposed";

type ElementSelectionEvents =
  | AnnotationHoveredEvent
  | AnnotationUnhoveredEvent
  | ElementSelectedEvent
  | ElementDeselectedEvent
  | ElementFocusedEvent
  | ElementEditAction
  | ElementSelectionChangedEvent
  | ViewerDisposedEvent
  | ElementColoredEvent
  | UndoableActionRedoneEvent
  | UndoableActionUndoneEvent
  | RoutingEvent

export class ElementSelection {
  constructor({
    focusedElementUpdates = 0,
    selectedElements = [],
    focusedElementGlobalIds = null,
    outlinedElements = new Set(),
    coloredElements = {}
  }: Partial<ElementSelection> = {}) {
    this.focusedElementUpdates = focusedElementUpdates;
    this.selectedElements = selectedElements;
    this.focusedElementGlobalIds = focusedElementGlobalIds;
    this.outlinedElements = outlinedElements;
    this.coloredElements = coloredElements;
  }

  selectedElements: string[];
  focusedElementGlobalIds: string[] | null;
  focusedElementUpdates: number;
  outlinedElements: Set<string>;
  coloredElements: { [globalId: string]: string };
}

const reduceElementSelection: Reducer<ElementSelection, ElementSelectionEvents> = (elementSelection = new ElementSelection(), event) => {
  if (isRoutingEvent(event) && !event.payload?.preserveElementSelection) {
    return new ElementSelection();
  } else {
    switch (event?.type) {
      case VIEWER_DISPOSED:
      case ELEMENT_DESELECTED: {
        return outlineSelectedElement(elementSelection, null);
      }
      case ELEMENT_SELECTED: {
        return outlineSelectedElement(elementSelection, event.payload.globalId, event.payload.additiveMode);
      }
      case ELEMENT_SELECTION_CHANGED: {
        return event.payload.globalIds.reduce((selectionSoFar, globalId) => {
          return addToSelectedElements(selectionSoFar, globalId);
        }, clearSelectedElements(elementSelection));
      }
      case ELEMENT_FOCUSED: {
        return new ElementSelection({
          ...addToOutlinedElements(elementSelection, event.payload.globalIds),
          focusedElementGlobalIds: event.payload.globalIds,
          focusedElementUpdates: elementSelection.focusedElementUpdates + 1
        });
      }
      case ANNOTATION_HOVERED: {
        return addToOutlinedElements(elementSelection, event.payload);
      }
      case ANNOTATION_UNHOVERED: {
        return removeFromOutlinedElements(elementSelection, event.payload);
      }
      case UNDOABLE_ACTION_UNDONE: {
        if (isElementEditActionPayload(event.payload)) {
          let globalIds: string[];
          globalIds = _.pluck(event.payload.action.payload.previousElementStates, "globalId");
          return {
            ...elementSelection,
            selectedElements: globalIds,
            outlinedElements: new Set(globalIds)
          };
        } else {
          return elementSelection;
        }
      }
      case UNDOABLE_ACTION_REDONE: {
        if (isElementEditActionPayload(event.payload)) {
          let globalIds: string[];
          globalIds = _.pluck(event.payload.action.payload.nextElementStates, "globalId");
          return {
            ...elementSelection,
            selectedElements: globalIds,
            outlinedElements: new Set(globalIds)
          };
        } else {
          return elementSelection;
        }
      }
      case ELEMENT_COLORED: {
        return {
          ...elementSelection,
          coloredElements: {
            ...elementSelection.coloredElements,
            [event.payload.globalId]: event.payload.color
          }
        };
      }
      default: {
        return elementSelection;
      }
    }
  }
};

function addToOutlinedElements(elementSelection: ElementSelection, newElement: string | string[]): ElementSelection {
  if (newElement) {
    if (Array.isArray(newElement)) {
      const newElements = newElement.filter(globalId => !elementSelection.outlinedElements.has(globalId));
      if (newElements.length) {
        return new ElementSelection({
          ...elementSelection,
          outlinedElements: new Set([...elementSelection.outlinedElements, ...newElements])
        });
      } else {
        return elementSelection;
      }
    } else {
      if (!elementSelection.outlinedElements.has(newElement)) {
        return new ElementSelection({
          ...elementSelection,
          outlinedElements: new Set([...elementSelection.outlinedElements, newElement])
        });
      } else {
        return elementSelection;
      }
    }
  } else {
    return elementSelection;
  }
}

function addToSelectedElements(elementSelection: ElementSelection, newElement: string): ElementSelection {
  if (newElement && !elementSelection.selectedElements.includes(newElement)) {
    return new ElementSelection({
      ...addToOutlinedElements(elementSelection, newElement),
      selectedElements: [
        ...elementSelection.selectedElements,
        newElement
      ]
    });
  } else {
    return elementSelection;
  }
}

function removeFromOutlinedElements(elementSelection: ElementSelection, elementToRemove: string): ElementSelection {
  if (elementSelection.selectedElements.includes(elementToRemove)) {
    return elementSelection;
  } else {
    const outlinedElements = elementSelection.outlinedElements;
    if (outlinedElements.delete(elementToRemove)) {
      return new ElementSelection({
        ...elementSelection,
        outlinedElements: new Set(outlinedElements)
      });
    } else {
      return elementSelection;
    }
  }
}

function removeFromSelectedElements(elementSelection: ElementSelection, elementToRemove: string): ElementSelection {
  if (elementSelection.selectedElements.includes(elementToRemove)) {
    return removeFromOutlinedElements(new ElementSelection({
      ...elementSelection,
      selectedElements: _(elementSelection.selectedElements).without(elementToRemove)
    }), elementToRemove);
  } else {
    return elementSelection;
  }
}

function clearSelectedElements(elementSelection: ElementSelection) {
  const selectedElements = elementSelection.selectedElements;
  return selectedElements.reduce<ElementSelection>((selection, element) => {
    return removeFromSelectedElements(selection, element);
  }, elementSelection);
}

function toggleSelectedElement(newElement: string, elementSelection: ElementSelection) {
  const selectedElements = elementSelection.selectedElements;
  if (selectedElements.includes(newElement)) {
    return removeFromSelectedElements(elementSelection, newElement);
  } else {
    return addToSelectedElements(elementSelection, newElement);
  }
}

function outlineSelectedElement(elementSelection: ElementSelection, newElement: string, additiveSelection?: boolean): ElementSelection {
  const selectedElements = elementSelection.selectedElements;
  if (newElement == null) {
    if (selectedElements.length) {
      return clearSelectedElements(elementSelection);
    } else {
      return elementSelection;
    }
  } else {
    if (additiveSelection) {
      return toggleSelectedElement(newElement, elementSelection);
    } else {
      if (selectedElements.includes(newElement) && selectedElements.length === 1) {
        return elementSelection;
      } else {
        return addToSelectedElements(clearSelectedElements(elementSelection), newElement);
      }
    }
  }
}

export default reduceElementSelection;
