import { Action } from "redux";

export type DetectedObstructingElementsShownEvent = Action<typeof DETECTED_OBSTRUCTING_ELEMENTS_SHOWN>

const detectedObstructingElementsShown = (): DetectedObstructingElementsShownEvent => ({
  type: DETECTED_OBSTRUCTING_ELEMENTS_SHOWN,
});

export const DETECTED_OBSTRUCTING_ELEMENTS_SHOWN = "detected_clashes_shown";

export default detectedObstructingElementsShown;
