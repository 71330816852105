import { AvvirEvent } from "type_aliases";
import { ApiInspectReportEntry } from "avvir";

export type InspectReportEntriesCreatedEvent = AvvirEvent<typeof INSPECT_REPORT_ENTRIES_CREATED, {
  projectId: string,
  inspectReportId: number,
  inspectReportEntries: ApiInspectReportEntry[]
}>

const inspectReportEntriesCreated = (projectId: string, inspectReportId: number, inspectReportEntries: ApiInspectReportEntry[]) =>({
  type: INSPECT_REPORT_ENTRIES_CREATED,
  payload: {
    projectId,
    inspectReportId,
    inspectReportEntries
  }
})

export const INSPECT_REPORT_ENTRIES_CREATED = "inspect_reports_entries_created";

export default inspectReportEntriesCreated;
