import { AvvirEvent } from "type_aliases";
import { CostMetrics } from "../../../models/domain/project_summary/project_summary";

export type CostMetricsLoadedEvent = AvvirEvent<typeof COST_METRICS_LOADED, {
  projectId: string,
  costMetrics: CostMetrics
}>

const costMetricsLoaded = (projectId:string, costMetrics: CostMetrics ): CostMetricsLoadedEvent => ({
  type: COST_METRICS_LOADED,
  payload: { projectId, costMetrics }
});

export const COST_METRICS_LOADED = "cost_metrics_loaded";

export default costMetricsLoaded;
