import { AvvirEvent } from "type_aliases";

export type ObstructionsCreatedEvent = AvvirEvent<typeof OBSTRUCTIONS_CREATED, { floorId: string, obstructions: { [globalId: string]: string[] } }>

const obstructionsCreated = (floorId: string, obstructions: { [globalId: string]: string[] }): ObstructionsCreatedEvent => ({
  type: OBSTRUCTIONS_CREATED,
  payload: {
    floorId,
    obstructions
  }
});

export const OBSTRUCTIONS_CREATED = "obstructions_created";

export default obstructionsCreated;
