import { AvvirEvent } from "type_aliases";

export type DeviationsFromAllScansShownEvent = AvvirEvent<typeof DEVIATIONS_FROM_ALL_SCANS_SHOWN, null>

const deviationsFromAllScansShown = (): DeviationsFromAllScansShownEvent => ({
  type: DEVIATIONS_FROM_ALL_SCANS_SHOWN,
  payload: null
});

export const DEVIATIONS_FROM_ALL_SCANS_SHOWN = "deviations_from_all_scans_shown";

export default deviationsFromAllScansShown;
