import type { AvvirEvent } from "type_aliases";

export type WorkPackageHiddenEvent = AvvirEvent<typeof WORK_PACKAGE_HIDDEN, { workPackageId: number }>

const workPackageHidden = (workPackageId: number): WorkPackageHiddenEvent => ({
  type: WORK_PACKAGE_HIDDEN,
  payload: {
    workPackageId
  }
});

export const WORK_PACKAGE_HIDDEN = "work_package_hidden";

export default workPackageHidden;
