import { createSelector } from "reselect";

import getPageName from "../location_metadata/get_page_name";
import getSelection from "../base_getters/get_selection";
import PanelType from "../../../models/domain/enums/panel_type";

const empty: PanelType[] = []

const getVisiblePanels = createSelector([
  getSelection,
  getPageName
], (selection, pageName) => {
  return selection.panelsByPage?.[pageName] || empty;
});

export default getVisiblePanels;
