import React, { FunctionComponent, MouseEventHandler, useMemo } from "react";
import { Button, Menu, MenuProps } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import classNames from "classnames";
import _ from "underscore";

import "../../../css/nav_bar/nav_bar_dropdown.css";
import usePopover from "../../services/component_helpers/component_effects/use_popover";
import { SlotComponentProps } from "@mui/base";
import { ButtonProps } from "@mui/material/Button";

interface DropdownSlotPropOverrides {
  children: never;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DropdownSlotState {

}

export type Props = {
  label: ButtonProps["children"]
  className: string
  children: MenuProps["children"]
  popoverProps?: {
    open: boolean
    anchorEl: HTMLElement
    handleOpen: MouseEventHandler<HTMLElement>
    onClose: () => void
  }
  slots?: {
    button?: React.ElementType
    menu?: React.ElementType
  }
  slotProps?: {
    button?: Omit<SlotComponentProps<typeof Button, DropdownSlotPropOverrides, DropdownSlotState>, "onClick" | "aria-haspopup" | "aria-controls" | "aria-expanded" | "className">
    menu?: Omit<SlotComponentProps<typeof Menu, DropdownSlotPropOverrides, DropdownSlotState>, "id" | "open" | "anchorEl" | "onClose">
  }
};

const NavBarDropdown: FunctionComponent<Props> = function NavBarDropdown(props) {
  const { label, className, slots, slotProps, popoverProps } = props;
  const ButtonComponent = slots?.button || Button;
  const MenuComponent = slots?.menu || Menu;
  let [open, anchorEl, handleOpen, onClose] = usePopover();
  const id = useMemo(() => _.uniqueId("NavBarDropdown"), []);
  if (popoverProps) {
    open = popoverProps.open;
    anchorEl = popoverProps.anchorEl;
    handleOpen = popoverProps.handleOpen;
    onClose = popoverProps.onClose;
  }
  return <>
    <ButtonComponent
      className={classNames("NavBarDropdown-button", className)}
      variant="contained"
      color="secondary"
      onClick={handleOpen}
      endIcon={<ArrowDropDown/>}
      classes={{
        startIcon: "NavBarDropdown-buttonIcon"
      }}
      aria-haspopup="menu"
      size="medium"
      disableElevation
      {...open && {
        "aria-controls": id,
        "aria-expanded": "true"
      }}
      {...slotProps?.button}
    >
      {label}
    </ButtonComponent>
    <MenuComponent
      id={id}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      onClose={onClose}
      {...slotProps?.menu}
    >
      {React.Children.map(props.children, (child) => {
        if (React.isValidElement(child)) {
          // @ts-ignore
          return React.cloneElement(child, { onClose });
        } else {
          return child;
        }
      })}
    </MenuComponent>
  </>;
};

export default NavBarDropdown;
