import { AvvirRoutingEvent } from "type_aliases";

export type ToProjectSettingsPageEvent = AvvirRoutingEvent<typeof TO_PROJECT_SETTINGS_PAGE, { projectId: string }>

const toProjectSettingsPage = (projectId: string): ToProjectSettingsPageEvent => ({
  type: TO_PROJECT_SETTINGS_PAGE,
  payload: { projectId }
});

export const TO_PROJECT_SETTINGS_PAGE = "to_project_settings_page";

export default toProjectSettingsPage;
