import { Reducer } from "redux";
import { produce } from "immer";

import UndoableAction from "../../models/domain/undoable_action";
import { UNDOABLE_ACTION_OCCURRED, UndoableActionOccurredEvent } from "../../events/undo/undoable_action_occurred";
import { UNDOABLE_ACTION_REDONE, UndoableActionRedoneEvent } from "../../events/undo/undoable_action_redone";
import { UNDOABLE_ACTION_UNDONE, UndoableActionUndoneEvent } from "../../events/undo/undoable_action_undone";
import RoutingEvent, { isRoutingEvent, isViewerRoutingEvent } from "../routing_events";

export type ActionHistoryEntry = {
  action: UndoableAction,
  previousState: any,
  nextState: any
}

export interface ActionHistoryStore {
  pastEdits: ActionHistoryEntry[];
  futureEdits: ActionHistoryEntry[];
}


type ActionHistoryEvents =
  | UndoableActionOccurredEvent
  | UndoableActionUndoneEvent
  | UndoableActionRedoneEvent
  | RoutingEvent

const reduceActionHistory: Reducer<ActionHistoryStore, ActionHistoryEvents> = (actionHistory: ActionHistoryStore = { pastEdits: [], futureEdits: [] }, event) => {
  return produce<ActionHistoryStore>(actionHistory, (draftActionHistory) => {
    if (isRoutingEvent(event) && !isViewerRoutingEvent(event)) { // clear store on any routing events that leaves the viewer page
      draftActionHistory.pastEdits = [];
      draftActionHistory.futureEdits = [];
    } else {
      switch (event.type) {
        case UNDOABLE_ACTION_OCCURRED: {
          draftActionHistory.pastEdits.push(event.payload);
          draftActionHistory.futureEdits = [];
          break;
        }
        case UNDOABLE_ACTION_UNDONE: {
          draftActionHistory.futureEdits.unshift(draftActionHistory.pastEdits.pop());
          break;
        }
        case UNDOABLE_ACTION_REDONE: {
          draftActionHistory.pastEdits.push(draftActionHistory.futureEdits.shift());
          break;
        }
      }
    }
  });
};

export default reduceActionHistory;

