import { AvvirEvent } from "type_aliases";
import { ApiScanDataset } from "avvir";

export type ScanDatasetLoadedEvent = AvvirEvent<typeof SCAN_DATASET_LOADED, ApiScanDataset>

const scanDatasetLoaded = (apiScanDataset: ApiScanDataset): ScanDatasetLoadedEvent => ({
  type: SCAN_DATASET_LOADED,
  payload: { ...apiScanDataset }
});

export const SCAN_DATASET_LOADED = "scan_dataset_loaded";

export default scanDatasetLoaded;
