import { createSelector } from "reselect";
import _ from "underscore";
import { ApiGroupMember } from "avvir";

import getGroupMembers from "./get_group_members";

const getGroupMembersByGroupId = createSelector([
  getGroupMembers
], (groupMembers) => {
  // return _(groupMembers.byId).indexBy("groupId");
  return _.chain(groupMembers.byId)
    .groupBy("groupId")
    .reduce<{ [groupId: number]: ApiGroupMember[] }>((groupMembersByGroupIdSoFar, groupMembers) => {
      if (groupMembers[0]?.groupId != null) {
        groupMembersByGroupIdSoFar[groupMembers[0].groupId] = groupMembers;
      }
      return groupMembersByGroupIdSoFar;
    }, {})
    .value();
});

export default getGroupMembersByGroupId;
