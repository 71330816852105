import { Reducer } from "redux";
import Masterformat from "../../../models/domain/masterformat";
import { MASTERFORMAT_LOADED, MasterformatLoadedEvent } from "../../../events/loaded/masterformat_loaded";

export interface MasterformatsStore {
  [section: string]: Masterformat
}

const noMasterformatProvided = {
  "undefined": {
    version: 2016,
    code: "undefined",
    description: "Unknown Material",
    masterformatId: {
      version: 2016,
      code: "undefined"
    }
  }
};

const reduceMasterformats: Reducer<MasterformatsStore, MasterformatLoadedEvent> = (masterformat = noMasterformatProvided as MasterformatsStore, event) => {
  switch (event.type) {
    case MASTERFORMAT_LOADED: {
      return event.payload.reduce((masterformatSoFar, masterformatDivision) => {
        return {
          ...masterformatSoFar,
          [masterformatDivision.code.slice(0, 2)]: masterformatDivision
        }
      }, masterformat );
    }
    default: {
      return masterformat;
    }
  }
};

export default reduceMasterformats;
