import { createSelector } from "reselect";
import _ from "underscore";

import getScanDatasetId from "./get_scan_dataset_id";
import getScanDatasetsForFloorByFirebaseId from "./get_scan_datasets_for_floor_by_firebase_id";
import ScanDataset from "../../../models/domain/scan_dataset";

const getPriorScanDatesSortedByDate = createSelector([
  getScanDatasetId,
  getScanDatasetsForFloorByFirebaseId,
], (currentScanDatasetId, scanDatasets) => {
  const currentScanDate = scanDatasets[currentScanDatasetId]?.scanDate;
  return _.chain(scanDatasets)
    .filter((scanDataset: ScanDataset) => scanDataset?.scanDate < currentScanDate)
    .sortBy("scanDate")
    .reverse()
    .pluck("scanDate")
    .value();
});

export default getPriorScanDatesSortedByDate;
