import { AssociationIds, AvvirEvent } from "type_aliases";
import {RunningProcessStatus} from "avvir";

export type PushPdfToProcoreFinishedEvent = AvvirEvent<typeof PUSH_PDF_TO_PROCORE_FINISHED, {
  processId: string
  scanDatasetId: string
  floorId: string
  projectId: string
  status: RunningProcessStatus
}>

const pushPdfToProcoreFinished = ({ projectId, floorId, scanDatasetId }: AssociationIds, processId: string, status: RunningProcessStatus): PushPdfToProcoreFinishedEvent => ({
  type: PUSH_PDF_TO_PROCORE_FINISHED,
  payload: {
    processId,
    scanDatasetId,
    floorId,
    projectId,
    status
  }
});

export const PUSH_PDF_TO_PROCORE_FINISHED = "push_pdf_to_procore_process_finished";

export default pushPdfToProcoreFinished;
