import { AvvirEvent } from "type_aliases";

export type BimtrackHubSelectedEvent = AvvirEvent<typeof BIMTRACK_HUB_SELECTED, string>

const bimtrackHubSelected = (hubId: string): BimtrackHubSelectedEvent => ({
  type: BIMTRACK_HUB_SELECTED,
  payload: hubId
});

export const BIMTRACK_HUB_SELECTED = "bimtrack_hub_selected";

export default bimtrackHubSelected;
