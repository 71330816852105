import React, { Component } from "react";
import * as ReactDOM from "react-dom";

export type Props = {
  children: React.ReactElement
}

export const ScrollableContext = React.createContext({ register: (name: string, element: React.RefObject<Element>) => {}, unregister: (name: string) => {} });

export default class ScrollableContainer extends Component<Props> {
  elements: {[name: string]: React.RefObject<Element>} = {};
  scrollable: Element;

  registerScrollable = (ref) => {
    if (ref && ref.getBoundingClientRect) {
      this.scrollable = ref;
    } else {
      // @ts-ignore
      this.scrollable = ReactDOM.findDOMNode(ref);
    }
  };

  register = (name: string, element: React.RefObject<Element>) => {
    this.elements[name] = element;
  };

  unregister = (name: string) => {
    delete this.elements[name];
  };

  scrollTo = (name) => {
    const ref = this.elements[name];
    const scrollable = this.scrollable;
    if (ref && scrollable) {
      const elementCount = Object.keys(this.elements).length;
      const node = ref.current;

      const nodeRect = node.getBoundingClientRect();
      const scrollableRect = scrollable.getBoundingClientRect();

      if (nodeRect.bottom > scrollableRect.bottom) {
        const scrollBottom = nodeRect.bottom - (scrollableRect.bottom - scrollable.scrollTop);
        if (elementCount > 100) {
          scrollable.scrollTo({ left: 0, top: scrollBottom, behavior: "auto" });
        } else {
          scrollable.scrollTo({ left: 0, top: scrollBottom, behavior: "smooth" });
        }
      } else if (nodeRect.top < scrollableRect.top) {
        const scrollTop = nodeRect.top - (scrollableRect.top - scrollable.scrollTop);
        if (elementCount > 100) {
          scrollable.scrollTo({ left: 0, top: scrollTop, behavior: "auto" });
        } else {
          scrollable.scrollTo({ left: 0, top: scrollTop, behavior: "smooth" });
        }
      }
    }
  };

  render() {
    return <ScrollableContext.Provider value={{ register: this.register, unregister: this.unregister }}>
      {React.cloneElement(this.props.children, { ref: this.registerScrollable })}
    </ScrollableContext.Provider>;
  }
}
