import type ApiCapturedTradeCost from "../../../models/api/api_captured_trade_cost";
import type { AvvirEvent } from "type_aliases";

export type CapturedTradeCostsLoadedEvent = AvvirEvent<typeof CAPTURED_TRADE_COSTS_LOADED, { projectId: string, tradeCosts: ApiCapturedTradeCost[] }>

const capturedTradeCostsLoaded = (projectId: string, tradeCosts: ApiCapturedTradeCost[]): CapturedTradeCostsLoadedEvent => ({
  type: CAPTURED_TRADE_COSTS_LOADED,
  payload: {
    projectId,
    tradeCosts
  }
});

export const CAPTURED_TRADE_COSTS_LOADED = "captured_trade_costs_loaded";

export default capturedTradeCostsLoaded;
