import type { AvvirEvent } from "type_aliases";

export type TradeHiddenEvent = AvvirEvent<typeof TRADE_HIDDEN, { code: string }>

const tradeHidden = (code: string): TradeHiddenEvent => ({
  type: TRADE_HIDDEN,
  payload: {
    code
  }
});

export const TRADE_HIDDEN = "trade_hidden";

export default tradeHidden;
