import Avvir from "avvir";

import floorLoaded, { FloorLoadedEvent } from "../../../events/loaded/floors/floor_loaded";
import getFloor from "../../../services/getters/floor_getters/get_floor";
import { ApiFailureEvent } from "../../../events/notifications/failures/api_failure";
import { AvvirAsyncThunk } from "../../make_eventful_action";

type DispatchedEvents = FloorLoadedEvent | ApiFailureEvent;

const loadFloor = (projectId: string, floorId: string): AvvirAsyncThunk<typeof getFloor, DispatchedEvents> => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;
    return Avvir.api.floors.getFloor({ projectId, floorId }, user)
      .then((floorMetadata) => {
        dispatch(floorLoaded(floorMetadata));
        return getFloor(getState(), { floorId });
      });
  };
};

export default loadFloor;
