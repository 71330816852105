import { AvvirEvent, Vector2Like } from "type_aliases";

export type BimMinimapPointSelectedEvent = AvvirEvent<typeof BIM_MINIMAP_POINT_SELECTED, {
  floorId: string,
  coordinates: Vector2Like
}>

const bimMinimapPointSelected = (floorId: string, coordinates: Vector2Like): BimMinimapPointSelectedEvent => ({
  type: BIM_MINIMAP_POINT_SELECTED,
  payload: {
    floorId,
    coordinates
  }
});

export const BIM_MINIMAP_POINT_SELECTED = "bim_minimap_point_selected";

export default bimMinimapPointSelected;
