import { useCallback } from "react";
import { useSelector } from "react-redux";

import { AvvirSelector } from "type_aliases";
import { ReduxStore } from "../../reducers/root_reducer";

const defaultProps = {};

const useAvvirSelector = <Return, Props = object>(selector: AvvirSelector<Return, any, Props>, props?: Props) => {
  const selectorWithProps = useCallback((store: ReduxStore) => {
    // @ts-ignore
    return selector(store, props || defaultProps);
  }, [selector, props]);
  return useSelector<ReduxStore, Return>(selectorWithProps)
};

export default useAvvirSelector;
