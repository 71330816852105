import { createSelector } from "reselect";

import getPhotoLocation from "./get_photo_location";
import getPhotoSessionIds from "./get_photo_session_ids";

const getSelectedPhotoSessionId = createSelector([
  getPhotoSessionIds,
  getPhotoLocation
], (photoSessionIds, photoLocation) => {
  if (photoLocation && photoLocation.photoSessionId) {
    return photoLocation.photoSessionId;
  } else if (photoSessionIds?.length) {
    return photoSessionIds[0];
  } else {
    return null;
  }
});

export default getSelectedPhotoSessionId;
