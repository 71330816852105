import { immerable } from "immer";
import { ApiInspectReport, ApiInspectReportEntry, ApiUser } from "avvir";

import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";
import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";

import type { DateLike, ModifyPartial } from "type_aliases";

export type InspectReportArgs = ModifyPartial<ApiInspectReport, {
  createdAt: DateLike
}>

export default class InspectReport {
  constructor({ id, firebaseProjectId, createdAt, name, entries, createdBy }: InspectReportArgs = {}) {
    addReadOnlyPropertiesToModel(this, { id, firebaseProjectId });
    addDateGetterAndSetterToDomainModel(this, "createdAt", createdAt);
    this.name = name;
    this.entries = entries;
    this.createdBy = createdBy;
  }

  readonly id: number;
  readonly firebaseProjectId: string;
  name: string;
  entries: ApiInspectReportEntry[];
  createdAt?: Date;
  createdBy?: ApiUser;

  static readonly [immerable] = true;
}
