import { PhotoSprite } from "./photo_sprite";
import { Vector2, Vector3 } from "three";
import { isVector2, isVector3 } from "../../../../../services/utilities/custom_type_guards";

const CLOSE_DELTA = 0.1;

export function arePointsClose<Vector extends Vector2 | Vector3>(p1: Vector, p2: Vector): boolean {
  if (isVector2(p1) && isVector2(p2)) {
    return Math.abs(p2.x - p1.x) < CLOSE_DELTA && Math.abs(p2.y - p1.y) < CLOSE_DELTA;
  } else if (isVector3(p1) && isVector3(p2)) {
    return Math.abs(p2.x - p1.x) < CLOSE_DELTA && Math.abs(p2.y - p1.y) < CLOSE_DELTA && Math.abs(p2.z - p1.z) < CLOSE_DELTA;
  }
}

export function areAllPointsWithinDistanceToLine<Vector extends Vector2 | Vector3>(linePointA: Vector, linePointB: Vector, points: Vector[], distance = 0.5): boolean {
  return points.every((point) => {
    if (arePointsClose(linePointA, point) || arePointsClose(linePointB, point)) {
      return false;
    }
    return pointToLineSegmentDistance(linePointA, linePointB, point) <= distance;
  });
}

export function pointToLineSegmentDistance<Vector extends Vector2 | Vector3>(lineSegmentPointA: Vector, lineSegmentPointB: Vector, point: Vector): number
export function pointToLineSegmentDistance(lineSegmentPointA: Vector2 | Vector3, lineSegmentPointB: Vector2 | Vector3, point: Vector2 | Vector3) {
  // // find the height of the triangle formed by the 3 points where the line segment is the base
  // // A = ‖sideA ⨯ sideB‖/2         (i.e. half the magnitude of the cross product of 2 of the sides)
  // const vertex1 = isVector2(lineSegmentPointA) ? new Vector3(lineSegmentPointA.x, lineSegmentPointA.y, 0) : lineSegmentPointA;
  // const vertex2 = isVector2(lineSegmentPointB) ? new Vector3(lineSegmentPointB.x, lineSegmentPointB.y, 0) : lineSegmentPointB;
  // const vertex3 = isVector2(point) ? new Vector3(point.x, point.y, 0) : point;
  // const sideA = vertex1.clone().sub(vertex2);
  // const sideB = vertex1.clone().sub(vertex3);
  // // const sideA = vertex1.clone().sub(vertex2).length();
  // // const sideB = vertex1.clone().sub(vertex3).length();
  // // const sideC = vertex2.clone().sub(vertex3).length();
  // const area = sideA.cross(sideB).length() / 2;
  // // const area = Math.sqrt((2 * sideA * sideB) ** 2 - (sideA * sideA + sideB * sideB - sideC * sideC) ** 2) / 4;
  // // h = 2A / b                     (where b is the length of the line segment i.e. the base of the triangle)
  // const height = 2 * area / sideA.length();
  // return height;
  const x = point.x;
  const y = point.y;
  const x1 = lineSegmentPointA.x;
  const y1 = lineSegmentPointA.y;
  const x2 = lineSegmentPointB.x;
  const y2 = lineSegmentPointB.y;

  const A = x - x1;
  const B = y - y1;
  const C = x2 - x1;
  const D = y2 - y1;

  const dot = A * C + B * D;
  const len_sq = C * C + D * D;
  let param = -1;
  if (len_sq !== 0) {
    //in case of 0 length line
    param = dot / len_sq;
  }

  let xx, yy;

  if (param < 0) {
    xx = x1;
    yy = y1;
  } else if (param > 1) {
    xx = x2;
    yy = y2;
  } else {
    xx = x1 + param * C;
    yy = y1 + param * D;
  }

  const dx = x - xx;
  const dy = y - yy;
  return Math.sqrt(dx * dx + dy * dy);
}

export class ControlPointNode {
  previous?: ControlPointNode;
  previousPoints: PhotoSprite[];
  point: PhotoSprite;
  next?: ControlPointNode;

  constructor(args: Partial<ControlPointNode>) {
    this.previousPoints = args.previousPoints;
    this.previous = args.previous;
    this.point = args.point;
    this.next = args.next;
  }

  insertControlPoint(sprite: PhotoSprite): ControlPointNode {
    const photoId = sprite.id;
    const photoIndex = sprite.zIndex;
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let node: ControlPointNode = this;

    while (node != null) {
      const nodeId = node.point.id;
      const nodeIndex = node.point.zIndex;
      const lessThan = photoIndex < nodeIndex;
      if (photoId === nodeId) {
        return node;
      } else if (lessThan) {
        const index = node.previousPoints.indexOf(sprite);
        const newNode = new ControlPointNode({
          previous: node.previous,
          point: sprite,
          previousPoints: node.previousPoints.slice(0, index),
          next: node
        });

        node.previous.next = newNode;
        node.previous = newNode;
        node.previousPoints = node.previousPoints.slice(index + 1);
        return newNode;
      }

      node = node.next;
    }

    return node;
  }

}

export type ControlPointSearchResult = {
  point: PhotoSprite
  index: number
  inBetweenPoints: PhotoSprite[]
}

export class LinearControlPointLocator {
  controlPointDistance = 0.5;

  constructor(distance?: number) {
    if (distance) {
      this.controlPointDistance = distance;
    }
  }

  findNodes(allPoints: PhotoSprite[], startingPoint: PhotoSprite): ControlPointNode {
    let rootNode = new ControlPointNode({
      previousPoints: [],
      point: startingPoint
    });

    let points = allPoints;
    let previousNode = rootNode;
    while (points.length > 0) {
      const searchResult = this.findControlPoint(points, previousNode.point);
      let node = new ControlPointNode({
        previous: previousNode,
        previousPoints: searchResult.inBetweenPoints,
        point: searchResult.point
      });
      previousNode.next = node;
      previousNode = node;
      points = points.slice(searchResult.index + 1);
    }

    return rootNode;
  }

  findControlPoint(points: PhotoSprite[], startingPoint: PhotoSprite): ControlPointSearchResult {
    for (let i = points.length - 1; i > 0; i--) {
      const currentPoint = points[i];
      const inBetweenPoints = points.slice(0, i);
      if (areAllPointsWithinDistanceToLine(startingPoint.bimPosition,
        currentPoint.bimPosition,
        inBetweenPoints.map((p) => { return p.bimPosition;}), this.controlPointDistance)) {
        return { point: currentPoint, inBetweenPoints, index: i };
      }
    }
    return { point: points[0], inBetweenPoints: [], index: 0 };
  }
}
