import { AvvirEvent } from "type_aliases";
import ProjectWorkPackage from "../../../models/domain/project_work_package";

export type ProjectWorkPackagesSavedEvent = AvvirEvent<typeof PROJECT_WORK_PACKAGES_SAVED, { workPackages: ProjectWorkPackage[], projectId: string }>

export const projectWorkPackagesSaved = (workPackages, projectId): ProjectWorkPackagesSavedEvent => ({
  type: PROJECT_WORK_PACKAGES_SAVED,
  payload: {
    workPackages,
    projectId
  }
});


export const PROJECT_WORK_PACKAGES_SAVED = "project_work_packages_saved";

export default projectWorkPackagesSaved;
