import { AssociationIds, AvvirEvent } from "type_aliases";
import ProjectReportVersion from "../../../models/domain/project_report_version";

export type ProjectReportVersionAddedEvent = AvvirEvent<typeof PROJECT_REPORT_VERSION_ADDED, {projectId: string, projectReportId: number, reportVersion: ProjectReportVersion}>

const projectReportVersionAdded = ({projectId, projectReportId}: AssociationIds, reportVersion: ProjectReportVersion): ProjectReportVersionAddedEvent => ({
  type: PROJECT_REPORT_VERSION_ADDED,
  payload: {
    projectId,
    projectReportId,
    reportVersion
  }
});

export const PROJECT_REPORT_VERSION_ADDED = "project_report_version_added";

export default projectReportVersionAdded;
