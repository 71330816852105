import React, { FunctionComponent, useCallback, useState } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import useSwitch from "@react-hook/switch";
import moment, { Moment } from "moment";

import "../../../css/dialogs/export_progress_report_pdf_dialog.css";
import DateConverter from "../../services/converters/date_converter";
import DateRangePicker from "../form_fields/date_range_picker";
import getPriorScanDatesSortedByDate from "../../services/getters/scan_dataset_getters/get_prior_scan_dates_sorted_by_date";
import getScanDate from "../../services/getters/scan_dataset_getters/get_scan_date";
import useHandleFieldChange from "../../services/component_helpers/component_effects/form_helpers/use_handle_field_change";
import { ReduxStore } from "../../services/reducers/root_reducer";
import Typography from "@mui/material/Typography";

export type Props = {
                      open: boolean
                      onClose: () => void
                      onClickOk: (startDate, endDate) => void
                      allScanDates?: Array<Date>
                      progressReportDateOption: "all_dates" | "current_date" | "date_range",
                      setProgressReportDateOption: (progressReportDateOption) => void
                    } & ReturnType<typeof mapStateToProps>;

const dateFormatter = DateConverter.getDateFormatter("MMM D");

function DateRangeLabel(props: { startDate: Moment, endDate: Moment }) {
  const { startDate, endDate } = props;

  return (<span>
    Date Range (
    <span className="ExportProgressReportPdfDialog-activeDates">{`${dateFormatter.formatUTC(startDate.toDate())} - ${dateFormatter.formatUTC(endDate.toDate())}`}</span>
    )
    </span>
  );
}

export const ExportProgressReportPdfDialog: FunctionComponent<Props> = (props) => {
  const { open, onClose, onClickOk, currentScanDate, priorScanDates, allScanDates, progressReportDateOption, setProgressReportDateOption } = props;

  const handleDateOptionChange = useHandleFieldChange(setProgressReportDateOption);
  const [datePickerOpen, toggleDatePicker] = useSwitch(false);
  const [startDate, setStartDate] = useState<Moment>(null);
  const [endDate, setEndDate] = useState<Moment>(null);

  const handleClickOk = useCallback(() => {
    let startDatePdf, endDatePdf;
    switch (progressReportDateOption) {
      case "all_dates":
        startDatePdf = null;
        endDatePdf = null;
        break;
      case "current_date":
        startDatePdf = priorScanDates[0] || null;
        endDatePdf = currentScanDate || null;
        break;
      case "date_range":
        startDatePdf = startDate || null;
        endDatePdf = endDate || null;
        break;
    }
    onClickOk(startDatePdf, endDatePdf);
  }, [onClickOk, progressReportDateOption, currentScanDate, priorScanDates, startDate, endDate]);

  const handleClickClear = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
  }, []);

  const handleOpenDateRangeDialog = useCallback(() => {
    if (startDate == null && endDate == null && allScanDates != null && allScanDates.length > 0) {
      setStartDate(moment(allScanDates[0]));
      setEndDate(moment(allScanDates[allScanDates.length - 1]));
    }
    toggleDatePicker.on();
  }, [startDate, endDate, allScanDates, toggleDatePicker]);


  const handleChangeStartEnd = useCallback((inputDate: Moment) => {
    const hasData = allScanDates != null && allScanDates.some((d) => {
      return moment(d).isSame(inputDate, "day");
    });

    if (!hasData) {
      return;
    }

    if (startDate == null) {
      setStartDate(inputDate);
    } else if (startDate > inputDate) {
      setEndDate(null);
      setStartDate(inputDate);
    } else if (moment(inputDate).isSame(startDate, "day")) {
      setStartDate(null);
      setEndDate(null);
    } else {
      setEndDate(inputDate);
    }
  }, [allScanDates, startDate]);

  const dateFormatter = DateConverter.getDateFormatter("MMM D, YYYY");
  let defaultScanDate = dateFormatter.formatUTC(moment(allScanDates[allScanDates.length - 1]).toDate());
  return <Dialog open={open} onClose={onClose} aria-label="Export Progress Report" className="ExportProgressReportPdfDialog">
    <DialogTitle className="ExportProgressReportPdfDialog-title"><Typography variant="body2" component="span">Export Progress Report</Typography></DialogTitle>
    <DialogContent className="ExportProgressReportPdfDialog-content">
      <FormControl component="fieldset" variant="standard">
        <RadioGroup aria-label="exportPdfType" name="exportPdfType" value={progressReportDateOption} onChange={handleDateOptionChange}>
          <FormControlLabel
            classes={{
              label: "ExportProgressReportPdfDialog-optionLabel"
            }}
            className="ExportProgressReportPdfDialog-option"
            value="all_dates"
            key="all_dates"
            control={<Radio color="primary"/>}
            label={`All capture dates (all progress up to ${defaultScanDate})`}
          />
          <span className="ExportProgressReportPdfDialog-supportingLabelText">
          This report shows the total completion up to and including the latest reality capture date on {defaultScanDate}.
        </span>
          <FormControlLabel
            classes={{
              label: "ExportProgressReportPdfDialog-optionLabel"
            }}
            className="ExportProgressReportPdfDialog-option"
            value="current_date"
            key="current_date"
            control={<Radio color="primary"/>}
            label={`Current capture date (${dateFormatter.formatUTC(moment(currentScanDate).toDate())})`}
          />
          <span className="ExportProgressReportPdfDialog-supportingLabelText">
          This report shows only the progress detected in the current reality capture date.
        </span>
          <FormControlLabel
            classes={{
              label: "ExportProgressReportPdfDialog-optionLabel"
            }}
            className="ExportProgressReportPdfDialog-option"
            value="date_range"
            key="date_range"
            control={<Radio color="primary"/>}
            label={startDate && endDate && progressReportDateOption === "date_range" ?
                   <DateRangeLabel startDate={startDate} endDate={endDate}/> : "Choose date range"}
            onClick={handleOpenDateRangeDialog}
          />
          <span className="ExportProgressReportPdfDialog-supportingLabelText">
          This report shows the progress detected between two reality capture dates that you choose.
        </span>
        </RadioGroup>
      </FormControl>
    </DialogContent>
    <DialogActions className="ExportProgressReportPdfDialog-actions">
      <Button onClick={onClose} color="primary">
        Cancel
      </Button>
      <Button onClick={handleClickOk} color="primary" className="ExportProgressReportPdfDialog-okButton">
        OK
      </Button>
    </DialogActions>
    <DateRangePicker
      open={datePickerOpen}
      onClose={toggleDatePicker.off}
      onClear={handleClickClear}
      startDate={startDate}
      endDate={endDate}
      validDates={allScanDates}
      handleChangeStartEnd={handleChangeStartEnd}
    />
  </Dialog>;
};

const mapStateToProps = (state: ReduxStore, props) => ({
  currentScanDate: getScanDate(state, props),
  priorScanDates: getPriorScanDatesSortedByDate(state, props),
});


export default connect(mapStateToProps)(ExportProgressReportPdfDialog);
