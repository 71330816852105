import { AssociationIds, AvvirEvent } from "type_aliases";
import ProjectReportVersion from "../../../models/domain/project_report_version";

export type ProjectReportVersionRemovedEvent = AvvirEvent<typeof PROJECT_REPORT_VERSION_REMOVED, {projectId: string, projectReportId: number, reportVersion: ProjectReportVersion}>

const projectReportVersionRemoved = ({projectId, projectReportId}: AssociationIds, reportVersion: ProjectReportVersion): ProjectReportVersionRemovedEvent => ({
  type: PROJECT_REPORT_VERSION_REMOVED,
  payload: {
    projectId,
    projectReportId,
    reportVersion
  }
});

export const PROJECT_REPORT_VERSION_REMOVED = "project_report_version_removed";

export default projectReportVersionRemoved;
