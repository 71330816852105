import { AvvirEvent } from "type_aliases";

export type ElementSelectionChangedEvent = AvvirEvent<typeof ELEMENT_SELECTION_CHANGED, { globalIds: string[] }>

const elementSelectionChanged = (globalIds: string[]): ElementSelectionChangedEvent => ({
  type: ELEMENT_SELECTION_CHANGED,
  payload: {
    globalIds
  }
});

export const ELEMENT_SELECTION_CHANGED = "element_selection_changed";

export default elementSelectionChanged;
