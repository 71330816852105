import { Vector3Like } from "type_aliases";
import { SystemOfMeasurement } from "avvir";
import { IMPERIAL } from "../../models/domain/enums/system_of_measurement";
import UnitConverter from "./unit_converter";
import { Vector3 } from "three";
import { isVector3 } from "../utilities/custom_type_guards";

export interface DeviationText {
  isDeviation: boolean
  x: string
  y: string
  z: string
  deviationMagnitude: string
  vectorLength: number
  name?: string
}

export function toDeviationDisplay(deviationVectorMeters: Vector3Like, systemOfMeasurement: SystemOfMeasurement): string {
  return toDeviationText(deviationVectorMeters, systemOfMeasurement).join(", ");
}

export function toDeviationText(deviationVectorMeters: Vector3Like, systemOfMeasurement: SystemOfMeasurement): string[] {
  const vector = deviationVectorMeters == null ? { x: 0, y: 0, z: 0 } : deviationVectorMeters;
  if (systemOfMeasurement === IMPERIAL) {
    return [UnitConverter.displayMetersAsImperial(vector.x),
      UnitConverter.displayMetersAsImperial(vector.y),
      UnitConverter.displayMetersAsImperial(vector.z)];
  } else {
    return [UnitConverter.displayMetersAsCentimeters(vector.x),
      UnitConverter.displayMetersAsCentimeters(vector.y),
      UnitConverter.displayMetersAsCentimeters(vector.z)];
  }
}

export function deviationToText(vector: Vector3Like | null, systemOfMeasurement: SystemOfMeasurement, digits: number = 2, unicodeChars?: boolean): DeviationText {
  const result: DeviationText = {
    isDeviation: false,
    x: "",
    y: "",
    z: "",
    deviationMagnitude: "",
    vectorLength: 0
  }

  if (vector == null) {
    return result;
  }

  result.isDeviation = true;

  if (vector.x != null) {
    result.x = UnitConverter.displayLength(vector.x, systemOfMeasurement, digits, unicodeChars);
  }

  if (vector.y != null) {
    result.y = UnitConverter.displayLength(vector.y, systemOfMeasurement, digits, unicodeChars);
  }

  if (vector.z != null) {
    result.z = UnitConverter.displayLength(vector.z, systemOfMeasurement, digits, unicodeChars);
  }

  const threeJsVector = isVector3(vector) ? vector : new Vector3(vector.x, vector.y, vector.z);
  const vectorLength = threeJsVector.length();
  result.vectorLength = vectorLength;
  result.deviationMagnitude = UnitConverter.displayLength(vectorLength, systemOfMeasurement, digits, unicodeChars);

  return result;
}
