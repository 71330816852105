import { Action } from "redux";

export type TradeShownAllEvent = Action<typeof TRADE_SHOWN_ALL>;

const tradeShownAll = (): TradeShownAllEvent => ({
  type: TRADE_SHOWN_ALL,
});

export const TRADE_SHOWN_ALL = "trade_shown_all";

export default tradeShownAll;
