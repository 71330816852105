import { AvvirEvent } from "type_aliases";

export type OrganizationNameLoadedEvent = AvvirEvent<typeof ORGANIZATION_NAME_LOADED, { organizationId: string, name: string }>

const organizationNameLoaded = (organizationId: string, name: string): OrganizationNameLoadedEvent => ({
  type: ORGANIZATION_NAME_LOADED,
  payload: {
    organizationId,
    name
  }
});

export const ORGANIZATION_NAME_LOADED = "organization_name_loaded";

export default organizationNameLoaded;
