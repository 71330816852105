import { IPointData, Rectangle } from "pixi.js";
import { clampToRectangle } from "../../../../../services/utilities/pixi";
import { PixiMinimapApp, PixiMinimapMode } from "../pixi_minimap_app";
import { PixiZoomContainer } from "../../../../../services/utilities/pixi/pixi_zoom_container";
import { MapLayer } from "./map_layer";

export class FloorPlanLayer extends MapLayer {
  private zoomContainer: PixiZoomContainer;
  public lastPos: IPointData;
  public startPos: IPointData;
  public startDragTime: Date;

  startDragging(pos: IPointData) {
    this.startPos = pos;
    this.lastPos = pos;
    this.startDragTime = new Date();
  }

  stopDragging() {
    this.lastPos = null;
    this.startPos = null;
    this.startDragTime = null;
  }

  init(app: PixiMinimapApp) {
    this.zoomContainer = app.zoomContainer;
  }

  updateDragPosition(app: PixiMinimapApp, screenPos: IPointData) {
    const locations = app.photosLayer;
    if (locations?.mode === PixiMinimapMode.ADJUST) {
      if (locations.isAdjusting()) {
        const newPos = this.toLocal(screenPos, null, null, true);

        locations.onMinimapDrag(app, newPos);
        const selected = locations.selectedPhoto;
        app.moveCamerasToPhotoLocation(selected);
        app.requestRender();
      } else if (this.startPos) {
        locations.onSelectionDrag(app, this.startPos, screenPos);
      }
    } else if (this.lastPos) {
      this.cursor = "move";
      const newPos = this.toLocal(screenPos, null, null, true);
      const oldPos = this.toLocal(this.lastPos, null, null, true);

      this.updatePosition(app.screen, {
        x: this.x + (newPos.x - oldPos.x),
        y: this.y + (newPos.y - oldPos.y)
      });

      this.lastPos = screenPos;
      app.requestRender();
    }

    return this.position;
  }

  getMinimapPositionBounds() {
    let bounds = this.textureSize();
    if (bounds == null) {
      bounds = this.fallbackSize;
    }
    return bounds == null ? null : bounds.clone();
  }

  updatePosition(screen: Rectangle, input?: IPointData) {
    const bounds = this.getMinimapPositionBounds();
    if (bounds == null) {
      return;
    }

    bounds.x -= (bounds.width / this.zoomContainer.minZoom - bounds.width) / 2
    bounds.y -= (bounds.height / this.zoomContainer.minZoom - bounds.height) / 2

    const newPosition = clampToRectangle(screen,
        this,
        bounds,
        input == null ? this.position : input);
    this.x = newPosition.x;
    this.y = newPosition.y;
  }

  textureValid() {
    return this.texture != null && this.texture.valid;
  }

}
