import { ELEMENTS_EDITED } from "../../events/viewer/elements_edited";
import { ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED } from "../../events/viewer/elements_exclude_from_analysis_updated";
import { ELEMENTS_STATUSES_UPDATED } from "../../events/viewer/elements_statuses_updated";
import { ELEMENTS_PARTIAL_PROGRESS_UPDATED } from "../../events/viewer/elements_partial_progress_updated";

export type ElementEditedEventType =
  | typeof ELEMENTS_EDITED
  | typeof ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED
  | typeof ELEMENTS_STATUSES_UPDATED
  | typeof ELEMENTS_PARTIAL_PROGRESS_UPDATED;

let elementEditActionTypes: Set<ElementEditedEventType>;

export const getEditElementEventTypes = () => {
  if (!elementEditActionTypes) {
    elementEditActionTypes = new Set([ELEMENTS_EDITED, ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED, ELEMENTS_STATUSES_UPDATED, ELEMENTS_PARTIAL_PROGRESS_UPDATED]);
  }
  return elementEditActionTypes;
};

