import Avvir from "avvir";

import getOrganization from "../../../services/getters/organization_getters/get_organization";
import organizationLoaded, { OrganizationLoadedEvent } from "../../../events/loaded/organizations/organization_loaded";
import { AvvirAsyncThunk } from "../../make_eventful_action";

const loadOrganization = (organizationId: string): AvvirAsyncThunk<typeof getOrganization, OrganizationLoadedEvent> => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;
    return Avvir.api.organizations.getOrganization(organizationId, user)
      .then((organization) => {
        dispatch(organizationLoaded(organization));
        return getOrganization(getState(), { organizationId });
      });
  };
};

export default loadOrganization;
