import { AvvirEvent } from "type_aliases";

export type MapLayerShownEvent = AvvirEvent<typeof MAP_LAYER_SHOWN, { layerId: string }>

const mapLayerShown = (layerId: string): MapLayerShownEvent => ({
  type: MAP_LAYER_SHOWN,
  payload: {
    layerId
  }
});

export const MAP_LAYER_SHOWN = "map_layer_shown";

export default mapLayerShown;
