import { AvvirEvent } from "type_aliases";
import { MasterformatLevel1, MasterformatLevel2, MasterformatLevel3, MasterformatLevel4, MasterformatLevel5 } from "masterformat";

export type WbsHierarchyLoadedEvent = AvvirEvent<typeof WBS_HIERARCHY_LOADED, WbsHierarchy>

export type WbsHierarchy = {
  level1: MasterformatLevel1,
  level2: MasterformatLevel2,
  level3: MasterformatLevel3,
  level4: MasterformatLevel4,
  level5: MasterformatLevel5,
  hasUnclassified: boolean
};

const wbsHierarchyLoaded = (wbsHierarchy: WbsHierarchy): WbsHierarchyLoadedEvent => ({
  type: WBS_HIERARCHY_LOADED,
  payload: wbsHierarchy
});

export const WBS_HIERARCHY_LOADED = "wbs_hierarchy_loaded";

export default wbsHierarchyLoaded;
