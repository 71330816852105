import { FunctionComponent, useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { LDClient } from "launchdarkly-js-client-sdk";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import Avvir, { UserRole } from "avvir";

import AutodeskForgeAdaptor from "../services/autodesk_forge_services/autodesk_forge_adaptor";
import backfillBimLocations from "../services/backfill_photo_bim_locations";
import ElementSelectionManager from "../services/utilities/element_selection_manager";
import getErrorCallback from "../services/deprecated_gateway_api/get_error_callback";
import getFloorId from "../services/getters/floor_getters/get_floor_id";
import getOrganizationId from "../services/getters/organization_getters/get_organization_id";
import getProjectId from "../services/getters/project_getters/get_project_id";
import getScanDatasetId from "../services/getters/scan_dataset_getters/get_scan_dataset_id";
import getSelectedElements from "../services/getters/planned_building_element_getters/element_selection_getters/get_selected_elements";
import getUser from "../services/getters/base_getters/get_user";
import ImageFetcher from "../services/utilities/image_fetcher";
import KeyboardShortcutManager from "../services/utilities/keyboard_shortcut_manager";
import PlannedBuildingElement from "../models/domain/planned_building_element";
import saveUpdatedDeviations from "../actions/viewer_page/viewer/save_updated_deviations";
import ScreenshotHelper from "../services/utilities/screenshot_helper";
import ToastHelper from "../services/utilities/toast_helper";
import useFloor from "../queries/floors/use_floor";
import useOrganization from "../queries/organizations/use_organization";
import useProject from "../queries/projects/use_project";
import useScanDataset from "../queries/scan_datasets/use_scan_dataset";

import type { ApiFailureEvent } from "../events/notifications/failures/api_failure";
import type { Dispatch, ErrorLike, GetState } from "type_aliases";
import type { ReduxStore } from "../services/reducers/root_reducer";
import getFloorsByFirebaseId from "../services/getters/floor_getters/get_floors_by_firebase_id";

type Props = ReturnType<typeof mapStateToProps>
             & ReturnType<typeof mapDispatchToProps>

const initializeAvvir = (featureFlags: Record<string, boolean>, ldClient: LDClient) => {
  return (dispatch: Dispatch<any>, getState: GetState) => {
    const screenshotHelper = new ScreenshotHelper();
    screenshotHelper.setCustomBimColors(featureFlags.customBimColors);

    window.Avvir = {
      screenshotHelper,
      imageFetcher: new ImageFetcher(getState),
      elementSelectionManager: new ElementSelectionManager(dispatch, getState),
      keyboardShortcutManager: new KeyboardShortcutManager(dispatch, getState, featureFlags),
      ForgeViewer: new AutodeskForgeAdaptor(),
      saveUpdatedDeviations: (pbes: PlannedBuildingElement[], newDeviation: THREE.Vector3) => dispatch(saveUpdatedDeviations(pbes, newDeviation)),
      featureFlags: {
        flagsReadOnly: featureFlags,
        ldClient,
      },
      toastHelper: new ToastHelper(dispatch, getState),
    };
  };
};


const UtilityContext: FunctionComponent<Props> = function UtilityContext(props) {
  const { selectedElements, projectId, floorId, floorsByFirebaseId, scanDatasetId, organizationId, sharedErrorHandler, user } = props;
  const { data: scanDataset, isLoading: isScanDatasetLoading } = useScanDataset(projectId, floorId, user ? scanDatasetId : null);
  const { data: floor, isLoading: isFloorLoading } = useFloor(projectId, user ? floorId : null);
  const { data: project, isLoading: isProjectLoading } = useProject(user ? projectId : null);
  const featureFlags = useFlags();
  const isSuperadmin = user?.userRole === UserRole.SUPERADMIN;
  const { data: organization, isLoading: isOrganizationLoading } = useOrganization(isSuperadmin ? organizationId : null);
  const dispatch = useDispatch() as Dispatch<ReturnType<typeof initializeAvvir>>;
  useEffect(() => {
    Avvir.api.config.sharedErrorHandler = sharedErrorHandler;
  }, [sharedErrorHandler]);

  const backfillFloorBimLocations = useCallback(async () => {
    if (floor == null) {
      console.warn("floor could not be determined. Navigate to a specific floor in the explorer before executing again.");
      return;
    }
    await backfillBimLocations({ projectId: project.firebaseId, floorId: floor.firebaseId }, floor, user);
  }, [project, floor, user]);

  // The photoAreaIds argument is not a list of photo areas to run this on. It's the list of photo area IDs that still need migrating after the first run
  // Get list of photo area IDs:
  // select distinct photo_area_id from photo_locations where photo_area_id in (select photo_area_id from floors where project_id=(select id from projects where firebase_id='REPLACEME') and photo_area_id is not null) and bim_location_id is null;
  const backfillProjectBimLocations = useCallback(async (photoAreaIds: null | number[]) => {
    if (project == null) {
      console.warn("project could not be determined. Navigate to a specific project in the explorer before executing again.");
      return;
    }

    for (const floorId of project.firebaseFloorIds) {
      const floor = floorsByFirebaseId[floorId];
      if (floor.photoAreaId && (!photoAreaIds || photoAreaIds.includes(floor.photoAreaId))) {
        await backfillBimLocations({ projectId: project.firebaseId, floorId: floor.firebaseId }, floor, user);
      }
    }
  }, [project, user]);

  const ldClient = useLDClient();
  // @ts-ignore
  window.DEBUG_SHADERS = true;

  useEffect(() => {
    // only on initialization
    dispatch(initializeAvvir(featureFlags, ldClient));
  }, [dispatch, featureFlags, ldClient]);

  const avvirEnvironmentExists = window.Avvir != null;
  useEffect(() => {
    if (avvirEnvironmentExists) {
      window.Avvir.backfillFloorBimLocations = backfillFloorBimLocations;
      window.Avvir.backfillProjectBimLocations = backfillProjectBimLocations;
    }
  }, [backfillFloorBimLocations, backfillProjectBimLocations, avvirEnvironmentExists]);

  const screenshotHelperExists = window.Avvir?.screenshotHelper != null;
  useEffect(() => {
    if (screenshotHelperExists && !isFloorLoading) {
      window.Avvir.screenshotHelper.floor = floor;
    }
  }, [floor, isFloorLoading, screenshotHelperExists]);

  useEffect(() => {
    if (screenshotHelperExists && !isProjectLoading) {
      window.Avvir.screenshotHelper.project = project;
    }
  }, [project, isProjectLoading, screenshotHelperExists]);

  useEffect(() => {
    if (screenshotHelperExists && !isOrganizationLoading) {
      window.Avvir.screenshotHelper.organization = organization;
    }
  }, [organization, isOrganizationLoading, screenshotHelperExists]);

  useEffect(() => {
    if (screenshotHelperExists && !isScanDatasetLoading) {
      window.Avvir.screenshotHelper.scanDataset = scanDataset;
    }
  }, [scanDataset, isScanDatasetLoading, screenshotHelperExists]);

  useEffect(() => {
    if (screenshotHelperExists) {
      window.Avvir.screenshotHelper.selectedElements = selectedElements;
    }
  }, [selectedElements, screenshotHelperExists]);

  const customBimColors = featureFlags.customBimColors;
  useEffect(() => {
    if (screenshotHelperExists) {
      window.Avvir.screenshotHelper.setCustomBimColors(customBimColors);
    }
  }, [screenshotHelperExists, customBimColors]);

  return null;
};

const mapStateToProps = (state: ReduxStore, props) => ({
  selectedElements: getSelectedElements(state, props),
  organizationId: getOrganizationId(state, props),
  projectId: getProjectId(state, props),
  floorId: getFloorId(state, props),
  floorsByFirebaseId: getFloorsByFirebaseId(state, props),
  scanDatasetId: getScanDatasetId(state, props),
  user: getUser(state, props),
});

const mapDispatchToProps = (dispatch: Dispatch<ApiFailureEvent>) => ({
  sharedErrorHandler: (error: { error: ErrorLike, arguments: any[] }) => {
    return getErrorCallback(dispatch)(error.error);
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(UtilityContext);
