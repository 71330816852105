import SystemOfMeasurement, { IMPERIAL } from "../../models/domain/enums/system_of_measurement";

const fractionByQuarterInch = {
  0: "",
  1: "¼",
  2: "½",
  3: "¾",
};

export type ImperialLength = {
  feet: number
  inches: number
  quarterInches: number
}

const FEET_PER_METER = 3.280839895;
const INCHES_PER_FOOT = 12;
const CENTIMETERS_PER_INCH = 2.54;
const CENTIMETERS_PER_METER = 100;
const DEGREES_PER_RADIAN = 57.296;

export default class UnitConverter {
  static displayMetersAsImperial(meters: number): string {
    if (!meters) {
      return `0"`;
    }

    const imperialParts = UnitConverter.fromMetersToImperial(meters);

    let imperialString = "";

    // Negative sign (only if negative *and* non-zero to avoid `-0"`)
    if (meters < 0 && (imperialParts.feet || imperialParts.inches || imperialParts.quarterInches)) {
      imperialString += "-";
    }

    // Feet
    imperialString += imperialParts.feet ? `${imperialParts.feet}'` : "";

    // Inches
    if (imperialParts.inches || imperialParts.quarterInches) {
      imperialString += imperialParts.inches ? `${imperialParts.inches}` : "";
      imperialString += imperialParts.quarterInches ? `${fractionByQuarterInch[imperialParts.quarterInches]}` : "";
    } else {
      imperialString += "0";
    }
    imperialString += `"`;

    return imperialString;
  }

  static displayMetersAsImperialRounded(meters: number, digits: number = 2): string {
    const negativeSign = meters < 0 ? "-" : "";
    const length = UnitConverter.fromMetersToImperial(meters);
    let inches = Math.round((UnitConverter.fromMetersToInches(Math.abs(meters)) - length.feet * INCHES_PER_FOOT) * 10 ** digits) / 10 ** digits;
    if (inches < 0) {
      length.feet -= 1;
      inches = INCHES_PER_FOOT + inches;
    }

    if (length.feet) {
      return `${negativeSign}${length.feet}' ${inches}"`;
    } else {
      return inches + `"`;
    }
  }

  static displayMetersAsCentimeters(meters: number, digits: number = 1, unicodeChars: boolean = true): string {
    if (Math.abs(meters) >= 1) {
      digits += 1; // round more when lengths are larger
      const roundedMeters = Math.round(meters * 10 ** digits) / 10 ** digits;
      return `${roundedMeters}m`;
    } else {
      const centimeters = UnitConverter.fromMetersToCmRounded(meters, digits);
      return unicodeChars ? `${centimeters}㎝` : `${centimeters}cm`
    }
  }

  static displayMetersAsFixed(meters: number, digits: number = 2): string {
    return meters.toFixed(digits) + "m";
  }

  static fromMetersToImperial(meters: number): ImperialLength {
    const feet = Math.abs(meters * FEET_PER_METER);
    let feetPart = Math.floor(feet);
    const feetRemainder = (feet % 1);
    let inchesPart = Math.floor(feetRemainder * INCHES_PER_FOOT);
    const inchRemainder = ((feetRemainder * INCHES_PER_FOOT) % 1);
    let numberOfQuarterInches = Math.round(inchRemainder * 4);

    if (numberOfQuarterInches === 4) {
      inchesPart += 1;
      numberOfQuarterInches = 0;
    }
    if (inchesPart === INCHES_PER_FOOT) {
      feetPart += 1;
      inchesPart = 0;
    }

    return { feet: feetPart, inches: inchesPart, quarterInches: numberOfQuarterInches };
  }

  static fromInchesToMeters(inches: number): number {
    return inches / INCHES_PER_FOOT / FEET_PER_METER;
  }

  static fromInchesToCm(inches: number): number {
    return inches * CENTIMETERS_PER_INCH;
  };

  static fromCmToInches(centimeters: number): number {
    return centimeters / CENTIMETERS_PER_INCH;
  };

  static fromInchesToCmRounded(inches: number, digits: number = 1): number {
    return Math.round(UnitConverter.fromInchesToCm(inches) * 10 ** digits) / 10 ** digits;
  };

  static fromMetersToCmRounded(meters: number, digits: number = 1): number {
    const centimeters = meters * CENTIMETERS_PER_METER;
    return Math.round(centimeters * 10 ** digits) / 10 ** digits;
  };

  static fromMetersToInches(meters: number): number {
    return meters * CENTIMETERS_PER_METER / CENTIMETERS_PER_INCH;
  };

  static fromRadiansToDegrees(radians: number): number {
    return radians * DEGREES_PER_RADIAN;
  }

  static fromDegreesToRadians(degrees: number): number {
    return degrees / DEGREES_PER_RADIAN;
  }

  static displayLength(meters: number, systemOfMeasurement: SystemOfMeasurement, digits: number = 2, unicodeChars?: boolean) {
    return systemOfMeasurement === IMPERIAL ?
           UnitConverter.displayMetersAsImperialRounded(meters, digits) :
           UnitConverter.displayMetersAsCentimeters(meters, digits, unicodeChars);
  }

}
