import RoutingEvent from "../../services/routing_events";
import { AvvirThunk } from "../make_eventful_action";
import photoSessionSelected, { PhotoSessionSelectedEvent } from "../../events/selection/photos/photo_session_selected";
import getPhotoSessionsForPhotoAreaById from "../../services/getters/photo_area_getters/get_photo_sessions_for_photo_area_by_id";
import PhotoSession from "../../models/domain/photos/photo_session";
import DateConverter from "../../services/converters/date_converter";

function getSessionsForDate(sessionsById: { [id: number]: PhotoSession }, photoSessionDate: Date): PhotoSession[] {
  const sessions = Object.values(sessionsById);
  return sessions.filter(session => DateConverter.isOnDay(session.sessionDate, photoSessionDate));
}

const selectPhotoSession = (photoSessionDate: Date): AvvirThunk<void, RoutingEvent | PhotoSessionSelectedEvent> => {
  return (dispatch, getState) => {
    const sessionsById = getPhotoSessionsForPhotoAreaById(getState(), {});
    const { type, payload, query } = getState().location;
    const { photoLocationId, photoSessionId, ...rest } = query || {};

    const sessions = getSessionsForDate(sessionsById, photoSessionDate);
    const sessionIds = sessions.map(session => session.id);
    if (sessionIds.length === 0 && photoSessionId != null) {
      dispatch({
        type,
        payload: {
          ...payload,
          query: {
            ...rest,
            photoLocationId
          }
        }
      });
    } else if (photoSessionId) {
      const newPhotoSessionId = sessionIds.join(",");
      if (photoSessionId !== newPhotoSessionId) {
        dispatch({
          type,
          payload: {
            ...payload,
            query: {
              ...rest,
            }
          }
        });
      }
    }

    dispatch(photoSessionSelected(sessionIds));
  };
};

/** NOT an eventful action! */
export default selectPhotoSession;
