import { AvvirEvent } from "type_aliases";

export type ObstructionsLoadedEvent = AvvirEvent<typeof OBSTRUCTIONS_LOADED, {
  firebaseFloorId: string,
  obstructingElementGlobalIds: string[],
  obstructions: { [globalId: string]: string[] }
}>

const obstructionsLoaded = (firebaseFloorId: string, obstructingElementGlobalIds: string[], obstructions: { [globalId: string]: string[] }): ObstructionsLoadedEvent => ({
  type: OBSTRUCTIONS_LOADED,
  payload: {
    firebaseFloorId,
    obstructingElementGlobalIds,
    obstructions
  }
});

export const OBSTRUCTIONS_LOADED = "obstructions_loaded";

export default obstructionsLoaded;
