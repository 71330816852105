import { AvvirEvent } from "type_aliases";
import { ApiCloudFile } from "avvir";

export type ProjectFilesLoadedEvent = AvvirEvent<typeof PROJECT_FILES_LOADED, { projectId: string, files: ApiCloudFile[] }>

const projectFilesLoaded = (projectId: string, files: ApiCloudFile[]): ProjectFilesLoadedEvent => ({
  type: PROJECT_FILES_LOADED,
  payload: {
    projectId,
    files
  }
});

export const PROJECT_FILES_LOADED = "project_files_loaded";

export default projectFilesLoaded;
