import { createSelector } from "reselect";
import _ from "underscore";

import getFloorId from "./floor_getters/get_floor_id";
import getProjectIdFromLocation from "./location_metadata/get_project_id_from_location";
import getRunningExportProcesses from "./running_processes_getters/get_running_export_processes";

const getExportStarted = createSelector([
  getProjectIdFromLocation,
  getFloorId,
  getRunningExportProcesses
], (projectId, floorId, exports) => {
  if (exports && projectId && floorId) {
    const process = _(exports).findWhere({ projectId, floorId });
    return !!process;
  } else {
    return false;
  }
});

export default getExportStarted;
