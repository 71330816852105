import { AvvirEvent } from "type_aliases";

export type GroupsDeletedEvent = AvvirEvent<typeof GROUP_DELETED, {  groupId: number  }>;

const groupDeleted = ( groupId: number ): GroupsDeletedEvent => ({
  type: GROUP_DELETED,
  payload: {
    groupId
  }
});

export const GROUP_DELETED = "group_deleted";

export default groupDeleted;
