import { AvvirEvent } from "type_aliases";
import { IssueStatusFilter } from "../../services/reducers/filter/reduce_filter";

export type IssueStatusFilterChangedEvent = AvvirEvent<typeof ISSUE_STATUS_FILTER_CHANGED, IssueStatusFilter>

const issueStatusFilterChanged = (statusFilter: IssueStatusFilter): IssueStatusFilterChangedEvent => ({
  type: ISSUE_STATUS_FILTER_CHANGED,
  payload: statusFilter,
});

export const ISSUE_STATUS_FILTER_CHANGED = "issue_status_filter_changed";

export default issueStatusFilterChanged;
