import { AvvirEvent } from "type_aliases";
import { AvvirApiFiles, ApiFloorPurposeType } from "avvir";

export type FloorFilesLoadedEvent = AvvirEvent<typeof FLOOR_FILES_LOADED, {
  floorId: string,
  files: AvvirApiFiles<ApiFloorPurposeType>
}>

const floorFilesLoaded = (floorId: string, files: AvvirApiFiles<ApiFloorPurposeType>): FloorFilesLoadedEvent => ({
  type: FLOOR_FILES_LOADED,
  payload: {
    floorId,
    files
  }
});

export const FLOOR_FILES_LOADED = "floor_files_loaded";

export default floorFilesLoaded;
