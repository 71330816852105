import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { AvvirActionCreator } from "../actions/make_eventful_action";
import { Dispatch } from "type_aliases";

type ActionCreatorReturnType<ActionCreator> = ActionCreator extends AvvirActionCreator<infer Return, any> ? Return : never

const useWithDispatch = <ActionCreator extends AvvirActionCreator>(action: ActionCreator, ...params: Parameters<ActionCreator>) => {
  const dispatch = useDispatch<Dispatch<ReturnType<typeof action>>>();
  return useCallback(() => {
    return dispatch(action(...params)) as unknown as ActionCreatorReturnType<ActionCreator>;
  }, [dispatch, action, params]);
};

export default useWithDispatch;
