import { AbstractRenderer, Texture } from "pixi.js";
import { PixiMinimapApp } from "../pixi_minimap_app";
import { MapMarkerStyle, MapMarkerTexture } from "../layers_shared/map_marker_texture";
import canGetResourceUrl from "../../../../../services/can_get_resource_url";

export class SharedViewStyles {
  //todo refactor this field to just be a PIXI.Texture
  public defaultTexture: Texture;
  private _renderer: AbstractRenderer;

  private defaultStyle: MapMarkerStyle = {
    fillAlpha: 1,
    fillColor: 0x2196F3,
    lineColor: 0x2196F3
  };

  public textureFailure: Texture = Texture.WHITE;


  init(app: PixiMinimapApp) {
    this._renderer = app.renderer;
    this.renderMarkersToTextures(app.renderer);
  }

  async renderMarkersToTextures(renderer: AbstractRenderer) {
    let iconUrl;
    if (canGetResourceUrl()) {
      // @ts-ignore
      iconUrl = (await import("../../../../../../resources/icons/map_layers/location.svg?url")).default;
    }
    this.defaultTexture = (await MapMarkerTexture.create(renderer, this.defaultStyle, false, iconUrl)).texture;
  }

}
