import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ToSuperadminNewOrganizationEvent = AvvirPayloadlessRoutingEvent<typeof TO_SUPERADMIN_NEW_ORGANIZATION>

const toSuperadminNewOrganization = (): ToSuperadminNewOrganizationEvent => ({
  type: TO_SUPERADMIN_NEW_ORGANIZATION
});

export const TO_SUPERADMIN_NEW_ORGANIZATION = "to_superadmin_new_organization";

export default toSuperadminNewOrganization;
