import { AvvirEvent } from "type_aliases";

export type FloorOrderUpdatedEvent = AvvirEvent<typeof FLOOR_ORDER_UPDATED, { floorIds: string[] }>

const floorOrderUpdated = (floorIds: string[]) => ({
  type: FLOOR_ORDER_UPDATED,
  payload: {
    floorIds
  }
});

export const FLOOR_ORDER_UPDATED = "floor_order_updated";

export default floorOrderUpdated;
