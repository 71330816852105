import type ApiTrade from "../../models/api/api_trade";
import type { AvvirEvent } from "type_aliases";

export type TradesUpdatedEvent = AvvirEvent<typeof TRADES_UPDATED, { projectId: string, trades: ApiTrade[] }>

const tradesUpdated = (projectId: string, trades: ApiTrade[]): TradesUpdatedEvent => ({
  type: TRADES_UPDATED,
  payload: {
    projectId,
    trades
  }
});

export const TRADES_UPDATED = "trades_updated";

export default tradesUpdated;
