import { enumMeshIndices, enumMeshVertices, ExtendedGeometry } from "../utilities/threejs_utilities/enum_mesh_vertices";
import { Vector3 } from "three";

export default class ModelExplorer {
  constructor(viewer: Autodesk.Viewing.GuiViewer3D) {
    this.viewer = viewer;
  }

  cloneFragmentGeometry(fragmentId: number, logAsWarning: boolean = true): THREE.BufferGeometry {
    // TODO: Figure out why geometry.computeFaceNormals() throws an exception sometimes. [#183395145]
    try {
      const bufferGeom = new THREE.BufferGeometry();
      const geometry = this.getFragmentGeometry(fragmentId);
      geometry.computeFaceNormals();
      return bufferGeom.fromGeometry(geometry);
    } catch (e) {
      if (logAsWarning) {
        console.warn("Unable to clone fragment geometry", e);
      }
      return null;
    }
  }

  private cloneGeometry(geometry: ExtendedGeometry): THREE.Geometry {
    const geom = new THREE.Geometry();
    enumMeshVertices(geometry, (v) => {
      geom.vertices.push(new THREE.Vector3(v.x, v.y, v.z));
    });

    enumMeshIndices(geometry, (a, b, c) => {
      geom.faces.push(new THREE.Face3(a, b, c));
    });

    return geom;
  }

  getMeshFragmentsPositionForModel(dbIds: number | number[], model: Autodesk.Viewing.Model): THREE.Vector3 {
    const overallBoundingBox = this.getBoundingBoxForMeshes(model, dbIds);
    const center = new THREE.Vector3();
    overallBoundingBox.getCenter(center);
    return center;
  }

  getFragmentGeometry(fragmentId: number, model: Autodesk.Viewing.Model = this.viewer.model): THREE.Geometry {
    const renderProxy = this.viewer.impl.getRenderProxy(model, fragmentId);
    const geometry = this.cloneGeometry(renderProxy.geometry);
    geometry.applyMatrix(renderProxy.matrixWorld);
    return geometry;
  }

  getObjectFragmentIds(dbId: number, model: Autodesk.Viewing.Model = this.viewer.model): number[] {
    const instanceTree = model.getInstanceTree();
    const fragIds: number[] = [];
    instanceTree.enumNodeFragments(dbId, (fragId) => fragIds.push(fragId));
    return fragIds;
  }

  getBoundingBoxForMesh(model: Autodesk.Viewing.Model, dbId: number): THREE.Box3 {
    const meshBoundingBox = new THREE.Box3();

    model.getInstanceTree().enumNodeFragments(dbId, (fragmentId) => {
      const fragmentBoundingBox = new THREE.Box3();
      model.getFragmentList().getWorldBounds(fragmentId, fragmentBoundingBox);
      meshBoundingBox.union(fragmentBoundingBox);
    });

    return meshBoundingBox;
  }

  getBoundingBoxForMeshes(model: Autodesk.Viewing.Model, dbIds: number[] | Set<number> | number): THREE.Box3 {
    const overallBoundingBox = new THREE.Box3();

    if (typeof dbIds === "number") {
      dbIds = [dbIds];
    }

    dbIds.forEach((dbId) => {
      let meshBoundingBox = this.getBoundingBoxForMesh(model, dbId);
      overallBoundingBox.union(meshBoundingBox);
    });

    return overallBoundingBox;
  }

  getBoundingBoxForMeshesWithOffsets(model: Autodesk.Viewing.Model, vectorOffsetsByDbId: Map<number, Vector3>): THREE.Box3 {
    const overallBoundingBox = new THREE.Box3();

    for (const [dbId, offset] of vectorOffsetsByDbId) {
      let meshBoundingBox = this.getBoundingBoxForMesh(model, dbId);
      meshBoundingBox.translate(offset);
      overallBoundingBox.union(meshBoundingBox);
    };

    return overallBoundingBox;
  }

  private readonly viewer: Autodesk.Viewing.GuiViewer3D;
}
