import {Quaternion} from "three";

// This magic quaternion is the same as first rotating the X axis by Math.PI/-2, then the Y axis by Math.PI/-2. This
// changes the default orientation of the sphere to match the BIM and photo cameras where the Z axis is up.
// noinspection MagicNumberJS
const photoSphereToCameraOrientation = new Quaternion(-0.5, 0.5, -0.5, 0.5);

export function getPhotoSphereToCameraQuaternion() {
  return photoSphereToCameraOrientation.clone();
}

/**
 * This will orient the photo sphere so that the identity rotation quaternion (0,0,0,1) will point at the center point
 * of the image, upright.
 *
 * The X axis is pitch where positive rotation moves the center point of the view towards the bottom of the sphere.
 * The Y axis is roll where positive rotation moves clockwise around the center point.
 * The Z axis is yaw where positive rotation moves the center point to the left.
 *
 * See #180666465.
 */
export function computeBimToPhotoSphereOrientation(bimOrientation: Quaternion): Quaternion {
  return bimOrientation.clone().multiply(photoSphereToCameraOrientation);
}
