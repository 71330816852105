import { AvvirEvent } from "type_aliases";

export type ElementFocusedEvent = AvvirEvent<typeof ELEMENT_FOCUSED, { globalIds: string[] }>

const elementFocused = (globalIds: string | string[]): ElementFocusedEvent => {
  if (Array.isArray(globalIds)) {
    return {
      type: ELEMENT_FOCUSED,
      payload: { globalIds }
    };
  } else {
    return {
      type: ELEMENT_FOCUSED,
      payload: { globalIds: [globalIds] }
    };
  }
};

export const ELEMENT_FOCUSED = "element_focused";

export default elementFocused;
