import { AvvirRoutingEvent } from "type_aliases";

export type ToWbsWithWbsCode = AvvirRoutingEvent<typeof TO_WBS_WITH_WBS_CODE, { projectId: string, wbsCode: string }>

const toWbsWithWbsCode = (projectId: string, wbsCode: string): ToWbsWithWbsCode => ({
  type: TO_WBS_WITH_WBS_CODE,
  payload: { projectId, wbsCode }
});

export const TO_WBS_WITH_WBS_CODE = "to_wbs_with_wbs_code";

export default toWbsWithWbsCode;
