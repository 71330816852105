import _ from "underscore";

import type { Selected } from "type_aliases";
import type { Filter } from "./index";

const calculateSelectionBasedOnChildren = <Type extends Filter>(filter: Type, id: string | number) => {
  const node = filter[id];
  if (node) {
    if (node.childIds.every(childCode => filter[childCode].selected === 1)) {
      return 1;
    } else {
      return -1;
    }
  } else {
    return 1;
  }
};

const calculateSelectionBasedOnChildrenCascade = <Type extends Filter>(filter: Type, id: string | number) => {
  const node = filter[id];
  if (node) {
    if (node.childIds.every(childCode => filter[childCode].selected === 1)) {
      return 1;
    } else if (node.childIds.every(childCode => filter[childCode].selected === 0)) {
      return 0;
    } else {
      return -1;
    }
  } else {
    return 1;
  }
};

const toggleParents = <Type extends Filter>(filter: Type, id: string | number) => {
  const node = filter[id];
  if (node.parentId) {
    const parentNode = filter[node.parentId];
    return toggleParents({
      ...filter,
      [node.parentId]: {
        ...parentNode,
        id: node.parentId,
        selected: calculateSelectionBasedOnChildren(filter, node.parentId)
      }
    } as Type, node.parentId);
  } else {
    return filter;
  }
};

const toggleParentsCascade = <Type extends Filter>(filter: Type, id: string | number) => {
  const node = filter[id];
  if (node.parentId) {
    const parentNode = filter[node.parentId];
    return toggleParentsCascade({
      ...filter,
      [node.parentId]: {
        ...parentNode,
        id: node.parentId,
        selected: calculateSelectionBasedOnChildrenCascade(filter, node.parentId)
      }
    } as Type, node.parentId);
  } else {
    return filter;
  }
};

function toggleChildren<Type extends Filter>(id: string | number, newValue: Selected, filter: Type): Type {
  if (_.size(filter[id]?.childIds)) {
    return filter[id].childIds.reduce<Type>((filterSoFar, childId) => {
      return toggleChildren(childId, newValue, {
        ...filterSoFar,
        [childId]: {
          ...filterSoFar[childId],
          id: childId,
          selected: newValue
        }
      });
    }, filter);
  } else {
    return filter;
  }
}

export function toggleElement<Type extends Filter>(id: string | number, value: Selected, filter: Type): Type {
  return toggleParents({
    ...toggleChildren(id, value, filter),
    [id]: {
      ...filter[id],
      id,
      selected: value
    }
  }, id);
}

export function toggleElementCascade<Type extends Filter>(id: string | number, value: Selected, filter: Type): Type {
  return toggleParentsCascade({
    ...toggleChildren(id, value, filter),
    [id]: {
      ...filter[id],
      id,
      selected: value
    }
  }, id);
}

export default toggleElement;
