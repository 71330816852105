import { ApiComment } from "avvir";
import { AvvirEvent } from "type_aliases";

export type CommentCreatedEvent = AvvirEvent<typeof COMMENT_CREATED, { commentThreadId: number, comment: ApiComment}>;

const commentCreated = (commentThreadId: number, comment: ApiComment): CommentCreatedEvent => ({
  type: COMMENT_CREATED,
  payload: {
    commentThreadId,
    comment
  }
});

export const COMMENT_CREATED = "comment_created";

export default commentCreated;
