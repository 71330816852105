import { ApiScanDataset } from "avvir";
import { AvvirEvent } from "type_aliases";

export type ScanDatasetsCreatedFromTsvEvent = AvvirEvent<typeof SCAN_DATASETS_CREATED_FROM_TSV, ApiScanDataset[]>

const scanDatasetsCreatedFromTsv = (scanDatasets: ApiScanDataset[]): ScanDatasetsCreatedFromTsvEvent => ({
  type: SCAN_DATASETS_CREATED_FROM_TSV,
  payload: scanDatasets
});

export const SCAN_DATASETS_CREATED_FROM_TSV = "scan_datasets_created_from_tsv";

export default scanDatasetsCreatedFromTsv;
