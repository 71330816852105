import { AvvirEvent } from "type_aliases";
import { ApiCloudFile } from "avvir";

export type ScanDatasetFilesLoadedEvent = AvvirEvent<typeof SCAN_DATASET_FILES_LOADED, { scanDatasetId: string, files: ApiCloudFile[] }>

const scanDatasetFilesLoaded = (scanDatasetId: string, { files }: { files: ApiCloudFile[] }): ScanDatasetFilesLoadedEvent => ({
  type: SCAN_DATASET_FILES_LOADED,
  payload: {
    scanDatasetId,
    files
  }
});

export const SCAN_DATASET_FILES_LOADED = "scan_dataset_files_loaded";

export default scanDatasetFilesLoaded;
