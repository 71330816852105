import { Rectangle, Sprite, Texture } from "pixi.js";

import { PixiMinimapApp } from "../pixi_minimap_app";

export class MapLayer extends Sprite {
  private loading: boolean = false;

  // this is here for testing or a fallback if the texture isn't loaded
  public fallbackSize: Rectangle = new Rectangle();

  textureSize(): Rectangle {
    return this.isTextureValid() ? this.texture.orig : null;
  }

  isTextureValid(): boolean {
    return this.texture != null && this.texture.valid;
  }

  update(app: PixiMinimapApp) {
    if (this.loading && this.isTextureValid()) {
      this.loading = false;
      app.onTextureSizeChange(this.textureSize());
      app.requestRender();
    }
  }

  loadFromImageElement(element: HTMLImageElement) {
    if (element == null) {
      return false;
    }

    element.crossOrigin = "";
    this.texture = Texture.from(element);
    this.loading = true;

    return true;
  }
}
