import { AvvirRoutingEvent } from "type_aliases";

export const TO_METRICS_PAGE = "to_metrics_page";

export type ToMetricsPageEvent = AvvirRoutingEvent<typeof TO_METRICS_PAGE, {
  projectId: string
}>

export const toMetricsPage = (projectId: string): ToMetricsPageEvent => ({
  type: TO_METRICS_PAGE,
  payload: {
    projectId
  }
});
