import { AvvirEvent } from "type_aliases";
import { ApiRunningProcess } from "avvir";

export type RunningProcessSucceededEvent = AvvirEvent<typeof RUNNING_PROCESS_SUCCEEDED, {
  runningProcess: ApiRunningProcess
}>

const runningProcessSucceeded = ({ runningProcess }: { runningProcess: ApiRunningProcess }): RunningProcessSucceededEvent => ({
  type: RUNNING_PROCESS_SUCCEEDED,
  payload: {
    runningProcess
  }
});

export const RUNNING_PROCESS_SUCCEEDED = "running_process_succeeded";

export default runningProcessSucceeded;
