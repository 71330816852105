import jwt from "jsonwebtoken";
import {UserRole} from "avvir";

export interface AvvirStorageToken {
  aud: string
  claims: {
    clientAccountFirebaseIds: string[],
    projectIds: string[],
    role: UserRole
  },
  exp: number
  iat: number
  iss: string
  sub: string
  uid: string
}

export interface AvvirGatewayToken {
  exp: number,
  role: UserRole,
  sub: string
}

export interface AvvirCustomFirebaseToken {
  aud: string
  auth_time: number
  exp: number
  iat: number
  iss: string
  sub: string
  user_id: string
  clientAccountFirebaseIds: string[]
  projectIds: string[]
  role: UserRole
}

export function decodeJwtToPayload(token: string): any {
  const decodedToken = jwt.decode(token, {complete: true}) as { payload: any };
  return decodedToken.payload;
}

export function decodeAvvirStorageToken(token: string): AvvirStorageToken {
  return decodeJwtToPayload(token) as AvvirStorageToken;
}

export function decodeAvvirGatewayToken(token: string): AvvirGatewayToken {
  return decodeJwtToPayload(token) as AvvirGatewayToken;
}

export function decodeAvvirCustomFirebaseToken(token: string): AvvirCustomFirebaseToken {
  return decodeJwtToPayload(token) as AvvirCustomFirebaseToken;
}
