import { createSelector } from "reselect";

import getUserIsLoggedIn from "../user_getters/get_user_is_logged_in";
import getProjectId from "../project_getters/get_project_id";
import getSelection from "../base_getters/get_selection";

const getWbsIsAvailable = createSelector([
  getUserIsLoggedIn,
  getProjectId,
  getSelection
], (userIsLoggedIn, projectId, selection): boolean => {
  const projectHasWbs = projectId && selection.wbsAvailableProjects.includes(projectId);
  return userIsLoggedIn && projectHasWbs;
});

export default getWbsIsAvailable;
