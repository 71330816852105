import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";

export default class ProjectReportVersion {
  id: number;
  projectReportId: number;
  reportDate: Date;
  url: string;
  note: string;

  constructor({ id, projectReportId, reportDate, url, note}: Partial<ProjectReportVersion> = {}) {
    addDateGetterAndSetterToDomainModel(this, "reportDate");

    this.id = id;
    this.reportDate = reportDate;
    this.projectReportId = projectReportId;
    this.url = url || "";
    this.note = note || "";
  }
}
