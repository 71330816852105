import { createSelector } from "reselect";
import { Euler, Matrix3, Matrix4, Quaternion, Vector3 } from "three";
import { PhotoRotationType } from "avvir";

import computePhotoSphereToViewerRotation from "../../utilities/threejs_utilities/transform_utilities/compute_photo_sphere_to_viewer_world_rotation";
import getFloorGlobalOffsetYaw from "./get_floor_global_offset_yaw";
import getPhotoAreaMinimapToViewer from "./get_photo_area_minimap_to_viewer";
import getPhotoLocation from "../photo_area_getters/get_photo_location";
import PhotoLocation from "../../../models/domain/photos/photo_location";
import { computeBimToPhotoSphereOrientation } from "../../utilities/threejs_utilities/transform_utilities/compute_bim_to_photo_sphere_orientation";

function photoSphereRotationFromPoseMatrix(photoLocation: PhotoLocation, photoAreaMinimapToViewer: Matrix3, viewerCorrectionYaw: number): Quaternion {
  const cameraWorldRotation = new Matrix4().extractRotation(photoLocation.cameraWorldMatrix);
  const matrix = computePhotoSphereToViewerRotation(cameraWorldRotation, photoAreaMinimapToViewer, viewerCorrectionYaw);
  const rotation = new Quaternion();
  matrix.decompose(new Vector3(), rotation, new Vector3());
  return rotation;
}

export function photoSphereYawFromBearing(photoLocation: PhotoLocation, globalOffsetYaw: number): number {
  const offset = photoLocation.rotationType === PhotoRotationType.ABSOLUTE_MINIMAP_BEARING ? 0 : globalOffsetYaw;
  const locationOffset = photoLocation.yawOffset || 0;
  return photoLocation.minimapBearing + offset + locationOffset;
}

export function photoSphereRotationFromBearing(photoLocation: PhotoLocation, globalOffsetYaw: number): Quaternion {
  const cameraWorldEuler = new Euler(-Math.PI / 2,
    photoSphereYawFromBearing(photoLocation, globalOffsetYaw) + Math.PI,
    0);

  return new Quaternion().setFromEuler(cameraWorldEuler);
}

export function photoSphereToViewerRotation(photoLocation: PhotoLocation, photoAreaMinimapToViewer: Matrix3, floorGlobalOffsetYaw: number): Quaternion {
  if (!photoLocation) {
    return new Quaternion();
  }
  if (photoLocation.bimLocation) {
    const orientation = PhotoLocation.toDomainQuaternion(photoLocation.bimLocation.orientation);
    return computeBimToPhotoSphereOrientation(orientation);
  }

  const bearingEnabled = photoLocation.rotationType === PhotoRotationType.RELATIVE_MINIMAP_BEARING
                         || photoLocation.rotationType === PhotoRotationType.ABSOLUTE_MINIMAP_BEARING;
  const useBearing = bearingEnabled && photoLocation.minimapBearing != null && Math.abs(photoLocation.minimapBearing) > 0.001;

  const globalOffsetYaw = floorGlobalOffsetYaw || 0;
  const photoLocationOffsetYaw = photoLocation.yawOffset || 0;

  return useBearing ?
         photoSphereRotationFromBearing(photoLocation, globalOffsetYaw) :
         photoSphereRotationFromPoseMatrix(photoLocation, photoAreaMinimapToViewer, globalOffsetYaw + photoLocationOffsetYaw);
}

const getPhotoSphereToViewerRotation = createSelector([
  getPhotoLocation, getPhotoAreaMinimapToViewer, getFloorGlobalOffsetYaw
], photoSphereToViewerRotation);

export default getPhotoSphereToViewerRotation;
