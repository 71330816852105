import { Reducer } from "redux";

import { INVITATION_LOADED, InvitationLoadedEvent } from "../../events/loaded/invitation_loaded";
import { isRoutingEvent } from "../routing_events";
import Invitation from "../../models/domain/invitation";

export type InvitationStore = Invitation

const emptyInvitation = {
  ...new Invitation()
};

const reduceInvitation: Reducer<InvitationStore, InvitationLoadedEvent> = (invitation = emptyInvitation, event) => {
  if (isRoutingEvent(event)) {
    return emptyInvitation;
  }
  switch (event.type) {
    case INVITATION_LOADED: {
      return {
        ...new Invitation(event.payload)
      };
    }
    default: {
      return invitation;
    }
  }
};

export default reduceInvitation;
