import { Action } from "redux";

export type ProgressReportPdfAllDatesEvent = Action<typeof PROGRESS_REPORT_PDF_ALL_DATES>

const progressReportPdfAllDates = (): ProgressReportPdfAllDatesEvent => ({
  type: PROGRESS_REPORT_PDF_ALL_DATES,
});

export const PROGRESS_REPORT_PDF_ALL_DATES = "progress_report_pdf_all_dates";

export default progressReportPdfAllDates;
