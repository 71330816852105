import { IPointData, Point, Texture } from "pixi.js";
import { Euler, Matrix3, Quaternion, Vector2, Vector3 } from "three";
import { ApiPhotoLocation, ApiPhotoLocation3d } from "avvir";

import PhotoLocation from "../../../../../models/domain/photos/photo_location";
import { FixedSizeSprite } from "../layers_shared/fixed_size_sprite";
import { getPhotoSphereToCameraQuaternion } from "../../../../../services/utilities/threejs_utilities/transform_utilities/compute_bim_to_photo_sphere_orientation";
import { MovedPhotoLocation } from "../../../../../events/viewer/photo_locations_moved";
import { photoSphereToViewerRotation } from "../../../../../services/getters/viewer_getters/get_photo_sphere_to_viewer_rotation";
import { PhotoSpriteStyles } from "./photo_sprite_styles";
import { PixiApp, PixiUpdatable } from "../../../../../services/utilities/pixi/pixi_updateable";
import { PixiMinimapApp, PixiMinimapBimBounds, PixiMinimapMode, PixiMinimapTransforms } from "../pixi_minimap_app";
import { transformVector, transformVectorAs2d } from "../../../../../services/utilities/threejs_utilities/transform_utilities";
import { Vector2Like } from "type_aliases";
import { vectorToEulerYaw } from "./photo_sprite_interpolator";
import { MapLocation } from "../layers_shared/map_location_container";

export const fromMinimapToBim = (coordinate: Vector2Like, transform: Matrix3) => {
  return transformVector(new Vector2(coordinate.x, coordinate.y), transform);
};

export const calculatePhotoLocationCoords = (coordinates: Vector3, bounds: {width: number, height: number}, transform: Matrix3): Vector2 => {
  const coords = transformVectorAs2d(coordinates, transform)
  coords.x *= bounds.width;
  coords.y *= bounds.height;
  return coords;
}

export interface PhotoEventListener {
  onPhotoPointerDown(photo: PhotoSprite);
  onPhotoPointerUp(photo: PhotoSprite);
}

export interface PhotoSpriteApp extends PixiApp, PixiMinimapBimBounds, PixiMinimapTransforms {
  photoAreaToViewerTransform: Matrix3;
}

export class PhotoSprite extends FixedSizeSprite implements PixiUpdatable, MapLocation {
  private _dragStartPosition: Point;
  private _startOrientation: Quaternion;
  private spriteTexture: Texture;
  public selected: boolean = false;
  public wasDragged: boolean = false;
  public sortIndex: number;

  public originalTimestamp: Date;
  public createdAt: Date;
  public updatedAt: Date;
  public id: number;
  public visited: boolean;
  public isControlPoint: boolean;
  public bimLocationId: number;
  public bimPosition: Vector3;
  public orientation: Quaternion;

  constructor(photoEventListener: PhotoEventListener, args: Partial<PhotoSprite>) {
    super();

    this.id = args.id;
    this.visited = args.visited;
    this.isControlPoint = args.isControlPoint;
    this.bimLocationId = args.bimLocationId;
    this.bimPosition = args.bimPosition;
    this.orientation = args.orientation;
    this.originalTimestamp = args.originalTimestamp;
    this.createdAt = args.createdAt;
    this.updatedAt = args.updatedAt;

    this.on("pointerdown", () => {
      photoEventListener.onPhotoPointerDown(this);
    });

    this.on("pointerup", () => {
      photoEventListener.onPhotoPointerUp(this);
      this.wasDragged = false;
    });
  }

  get isStart(): boolean {
    return this.sortIndex === 0;
  }

  get screenSize(): number {
    return this.isStart ? 20 : 14;
  }

  startDragging() {
    this._dragStartPosition = this.position.clone();
    this._startOrientation = this.orientation.clone();
  }

  stopDragging() {
    this._dragStartPosition = null;
    this._startOrientation = null;
  }

  addToOrientation(angle: Quaternion) {
    if (this._startOrientation == null) {
      this._startOrientation = this.orientation.clone();
    }

    this.orientation.multiplyQuaternions(angle, this._startOrientation);
  }

  setOrientationFromVector(vector: Vector2) {
    this.orientation.setFromEuler(vectorToEulerYaw(vector));
  }

  calculateDotTexture(styles: PhotoSpriteStyles, mode: PixiMinimapMode) {
    const isAdjusting = mode !== PixiMinimapMode.VIEW;

    if (this.isStart) {
      if (this.selected) {
        return styles.startSelectedTexture;
      }

      if (isAdjusting) {
        return this.isControlPoint ? styles.startMovedInAdjustModeTexture : styles.startAdjustingTexture;
      }

      return this.visited ? styles.startVisitedTexture : styles.startTexture;
    }

    if (this.selected) {
      return styles.selectedTexture;
    }

    if (isAdjusting) {
      return this.isControlPoint ? styles.movedInAdjustModeTexture : styles.adjustTexture;
    }

    return this.visited ? styles.visitedTexture : styles.defaultTexture;
  }

  toPointUpdatePayload(transforms: PixiMinimapTransforms): MovedPhotoLocation {
    this.recalculateBimFromPosition(transforms);
    this.wasDragged = false;

    return {
      isControlPoint: this.isControlPoint,
      photoLocationId: this.id,
      bimLocationId: this.bimLocationId,
      position: this.bimPosition.clone(),
      orientation: this.orientation.clone(),
      updatedLocation: new ApiPhotoLocation({
        id: this.id,
        bimLocation: new ApiPhotoLocation3d({
          id: this.bimLocationId,
          position: this.bimPosition.clone(),
          orientation: this.orientation.clone()
        })
      })
    };
  }

  clampBimPosition(): Vector3 {
    return this.bimPosition;
    // Disabled clamping here to fix https://www.pivotaltracker.com/n/projects/2537140/stories/184689552
    // return clampXY(app.minBimPosition, this.bimPosition, app.maxBimPosition);
  }

  updateFromPhoto(app: PhotoSpriteApp, photo: PhotoLocation) {
    if (photo.bimLocation == null || photo.bimLocation.position == null) {
      this.bimPosition = PhotoLocation.bimPositionOrTransformedMinimapCoordinates(photo, new Matrix3().getInverse(app.bimToPhotoAreaTransform), app.floorElevation);
      this.orientation = photoSphereToViewerRotation(photo, app.photoAreaToViewerTransform, app.offsetYaw)
        .multiply(getPhotoSphereToCameraQuaternion().inverse());
    } else {
      const bimPos = photo.bimLocation.position;
      this.bimPosition = bimPos.clone();
      this.bimLocationId = photo.bimLocation.id;
      this.orientation = photo.bimLocation.orientation.clone();
    }

    this.updatedAt = photo.updatedAt;
    this.clampBimPosition();
    this.recalculatePositionFromBim(app);
  }

  recalculateBimFromPosition(transforms: PixiMinimapTransforms): Vector3 {
    const mapPos = fromMinimapToBim(this.position, transforms.photoAreaToBimTransform);
    this.bimPosition.x = mapPos.x;
    this.bimPosition.y = mapPos.y;
    return this.bimPosition;
  }

  recalculatePositionFromBim(transforms: PixiMinimapTransforms) {
    if (!this.wasDragged) {
      const coords = calculatePhotoLocationCoords(this.bimPosition, transforms.mapBounds, transforms.bimToPhotoAreaTransform);
      if (this.x !== coords.x && this.y !== coords.y) {
        this.position.set(coords.x, coords.y);
        return true;
      }
    }

    return false;
  }

  rotateFromSelectionCenter(transforms: PixiMinimapTransforms, centerPoint: IPointData, angle: number) {
    if (this._dragStartPosition == null) {
      return;
    }

    const rotated = new Vector2(this._dragStartPosition.x, this._dragStartPosition.y);
    rotated.rotateAround(new Vector2(centerPoint.x, centerPoint.y), angle);
    this.orientation = this._startOrientation.clone().multiply(new Quaternion().setFromEuler(new Euler(0, 0, angle)));
    return this.updateFromDragPosition(transforms, {
      x: rotated.x,
      y: rotated.y
    });
  }

  translateFromDragStartPosition(transforms: PixiMinimapTransforms, position: IPointData, startPosition: IPointData) {
    if (this._dragStartPosition == null) {
      return this.updateFromDragPosition(transforms, position);
    }

    const offsetX = this._dragStartPosition.x - startPosition.x;
    const offsetY = this._dragStartPosition.y - startPosition.y;
    return this.updateFromDragPosition(transforms, {
      x: position.x + offsetX,
      y: position.y + offsetY
    });
  }

  updateFromDragPosition(transforms: PixiMinimapTransforms, position: IPointData) {
    this.position.x = position.x;
    this.position.y = position.y;
    this.recalculateBimFromPosition(transforms)
    this.wasDragged = true;
    return this.position;
  }

  update(app: PixiMinimapApp) {
    const currentDotTexture = this.calculateDotTexture(app.styles, app.mode);
    if (currentDotTexture !== this.spriteTexture) {
      this.spriteTexture = currentDotTexture;
      this.texture = currentDotTexture;
      app.requestRender();
    }

    const changed = this.recalculatePositionFromBim(app);

    if (changed) {
      app.requestRender();
    }

    this.interactive = true;
    if (app.showOrientation) {
      const euler = new Euler().setFromQuaternion(this.orientation);
      this.rotation = euler.z;
    }

    if (app.mode === PixiMinimapMode.VIEW) {
      this.buttonMode = true;
    } else {
      this.buttonMode = false;
      this.cursor = "move";
    }
  }

  static fromDomain(eventListener: PhotoEventListener, app: PhotoSpriteApp, photo: PhotoLocation, visited?: boolean): PhotoSprite {
    const sprite = new PhotoSprite(eventListener, {
      id: photo.id,
      visited,
      isControlPoint: photo.isControlPoint,
      originalTimestamp: photo.originalTimestamp,
      createdAt: photo.createdAt,
      updatedAt: photo.updatedAt
    });

    sprite.updateFromPhoto(app, photo);
    return sprite;
  }

}
