import { Reducer } from "redux";
import Organization from "../../../models/domain/organization";
import OrganizationConverter from "../../converters/organization_converter";
import makeUpdateModel from "./make_update_model";
import { ORGANIZATION_LOADED, OrganizationLoadedEvent } from "../../../events/loaded/organizations/organization_loaded";
import { ORGANIZATION_NAME_LOADED, OrganizationNameLoadedEvent } from "../../../events/loaded/organizations/organization_name_loaded";
import { ORGANIZATION_UPDATED, OrganizationUpdatedEvent } from "../../../events/superadmin/organization_updated";
import { ORGANIZATIONS_LOADED, OrganizationsLoadedEvent } from "../../../events/loaded/organizations/organizations_loaded";
import { FIREBASE_USER_SIGNED_IN, FirebaseUserSignedInEvent } from "../../../events/auth_events/firebase_user_signed_in";
import {decodeAvvirCustomFirebaseToken} from "../../firebase_services/firebase_tokens";

export interface OrganizationsStore {
  byFirebaseId: { [firebaseId: string]: Organization }
  firebaseIds?: Array<string>
  loading?: boolean
}

export type OrganizationEvents =
  | OrganizationLoadedEvent
  | OrganizationNameLoadedEvent
  | OrganizationsLoadedEvent
  | OrganizationUpdatedEvent
  | FirebaseUserSignedInEvent

const emptyOrganization = new Organization();

const updateOrganization = makeUpdateModel<Organization>(emptyOrganization);

// reducers inherently use complex switch statements
// noinspection OverlyComplexFunctionJS
const reduceOrganizations: Reducer<OrganizationsStore> = (organizations = { byFirebaseId: {} }, event) => {
  if (!organizations.byFirebaseId) {
    organizations.byFirebaseId = {};
  }
  switch (event?.type) {
    case ORGANIZATIONS_LOADED: {
      return event.payload.reduce((organizationsSoFar, organization) => {
        return updateOrganization(organizationsSoFar, organization.firebaseId, OrganizationConverter.fromApi(organization));
      }, organizations);
    }
    case ORGANIZATION_LOADED: {
      return updateOrganization(organizations,
        event.payload.firebaseId,
        OrganizationConverter.fromApi(event.payload));
    }
    case ORGANIZATION_UPDATED: {
      return updateOrganization(organizations,
        event.payload.organizationId,
        OrganizationConverter.fromApi(event.payload.organization));
    }
    case ORGANIZATION_NAME_LOADED: {
      return updateOrganization(organizations, event.payload.organizationId, {
        firebaseId: event.payload.organizationId,
        name: event.payload.name
      });
    }
    case "user_invitation_generated": {
      return updateOrganization(organizations, event.payload.organizationId, {
        invitation: { link: event.payload.link }
      });
    }
    case FIREBASE_USER_SIGNED_IN: {
      const decoded = decodeAvvirCustomFirebaseToken(event.payload.idToken);
      return {
        ...organizations,
        firebaseIds: decoded.clientAccountFirebaseIds
      };
    }
    case "load_organizations_started": // TODO: Store this status on a per-organization basis
    case "load_organization_started": {
      return {
        ...organizations,
        loading: true
      };
    }
    case "load_organizations_done":
    case "load_organizations_failed":
    case "load_organization_done":
    case "load_organization_failed": {
      return {
        ...organizations,
        loading: false
      };
    }
    default: {
      return organizations;
    }
  }
};

export default reduceOrganizations;
