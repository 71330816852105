import { createSelector } from "reselect";
import _ from "underscore";

import getFloor from "../floor_getters/get_floor";
import getScanDatasetsByFirebaseId from "./get_scan_datasets_by_firebase_id";
import ScanDataset from "../../../models/domain/scan_dataset";
import { ByFirebaseId } from "type_aliases";

const empty: ByFirebaseId<ScanDataset> = {};

const getScanDatasetsForFloorByFirebaseId = createSelector([
  getFloor, getScanDatasetsByFirebaseId
], (floor, scanDatasetsByFirebaseId) => {
  if (floor?.firebaseScanDatasetIds?.length) {
    return _(scanDatasetsByFirebaseId).pick(floor.firebaseScanDatasetIds as string[]) as ByFirebaseId<ScanDataset>;
  } else if (floor?.defaultFirebaseScanDatasetId && scanDatasetsByFirebaseId[floor.defaultFirebaseScanDatasetId]) {
    return {
      [floor.defaultFirebaseScanDatasetId]: scanDatasetsByFirebaseId[floor.defaultFirebaseScanDatasetId]
    } as ByFirebaseId<ScanDataset>;
  } else {
    return empty;
  }
});

export default getScanDatasetsForFloorByFirebaseId;
