import { AvvirRoutingEvent } from "type_aliases";

export type ToProjectSummaryPageEvent = AvvirRoutingEvent<typeof TO_PROJECT_SUMMARY_PAGE, {projectId: string }>

const toProjectSummaryPage = (projectId: string): ToProjectSummaryPageEvent => ({
  type: TO_PROJECT_SUMMARY_PAGE,
  payload: { projectId }
});

export const TO_PROJECT_SUMMARY_PAGE = "to_project_summary_page";

export default toProjectSummaryPage;
