import Avvir, { ApiPipeline } from "avvir";

import DateConverter from "../../services/converters/date_converter";
import downloadExportedIfc from "./download_exported_ifc";
import exportIfcProcessChecked, { ExportIfcProcessCheckedEvent } from "../../events/export_ifc_process_checked";
import { AvvirThunk } from "../make_eventful_action";
import RunningProcessStatus from "../../models/domain/enums/running_process_status";
import { withPromise } from "../../services/utilities/general";
import exportBatchIfcProcessChecked, { ExportBatchIfcProcessCheckedEvent } from "../../events/export_batch_ifc_process_checked";

const checkExportedIfc = (associationIds, pipelineId, ifcType, type?: string): AvvirThunk<Promise<void>, ExportIfcProcessCheckedEvent>
                                                                               | AvvirThunk<Promise<void>, ExportBatchIfcProcessCheckedEvent> => {
  return (dispatch, getState) => {
    const user = getState().user;
    const checkAgainIfPending = (pipeline: ApiPipeline) => {
      const [succeeded, failure, checking] = withPromise<void>();
      if (pipeline.status === RunningProcessStatus.RUNNING) {
        dispatchEvent(dispatch, associationIds, pipeline, ifcType, "running", type);
        setTimeout(() => {
          Avvir.api.pipelines.checkPipelineStatus(associationIds, pipelineId, user)
            .then(checkAgainIfPending).then(succeeded).catch(failure);
        }, 5000);
      } else {
        dispatchEvent(dispatch, associationIds, pipeline, ifcType, "complete", type);
        // @ts-ignore
        dispatch(downloadExportedIfc(associationIds, ifcType, type));
        succeeded();
      }
      return checking;
    };
    return Avvir.api.pipelines.checkPipelineStatus(associationIds, pipelineId, user)
      .then(checkAgainIfPending);
  };
};

const dispatchEvent = (dispatch, associationIds, pipeline, ifcType, status, type?: string) => {
  if (type && type === "batch_export") {
    dispatch(exportBatchIfcProcessChecked(associationIds, ifcType, pipeline.externalId, status, DateConverter.instantToDate(pipeline.startTime)))
  } else {
    dispatch(exportIfcProcessChecked(associationIds, ifcType, pipeline.externalId, status, DateConverter.instantToDate(pipeline.startTime)));
  }
}

export default checkExportedIfc;