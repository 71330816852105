import type { MouseEventHandler } from "react";
import React, { forwardRef } from "react";
import type { DispatchProp } from "react-redux";
import { connect } from "react-redux";
import matchPath from "rudy-match-path";
import { getOptions, selectLocationState } from "redux-first-router";
import { stripBasename } from "rudy-history/PathUtils";
import { Link } from "./Link";
import type { To } from "./toUrl";
import toUrl from "./toUrl";

type OwnProps = {
  to: To
  redirect?: boolean
  replace?: boolean
  children?: any
  onClick?: MouseEventHandler
  down?: boolean
  shouldDispatch?: boolean
  target?: string
  className?: string
  style?: Record<string, any>
  activeClassName?: string
  activeStyle?: Record<string, any>
  ariaCurrent?: boolean | "true" | "false" | "location" | "page" | "step" | "date" | "time"
  exact?: boolean
  strict?: boolean
  isActive?: (
    arg0: Record<string, any> | null | undefined,
    arg1: Record<string, any>
  ) => boolean
}
type Props = ReturnType<typeof mapState> & DispatchProp & OwnProps
const NavLink = forwardRef(
  (
    {
      to,
      location,
      className,
      style,
      activeClassName = "active",
      activeStyle,
      ariaCurrent = "true",
      exact,
      strict,
      isActive,
      ...props
    }: Props,
    ref
  ) => {
    const options = getOptions();
    const basename = options.basename ? options.basename : "";
    const path = toUrl(to, location.routesMap).split("?")[0];
    const match = matchPath(location.pathname, {
      path: stripBasename(path, basename),
      exact,
      strict
    });
    const active = !!(isActive ? isActive(match, location) : match);
    const combinedClassName = active
                              ? [className, activeClassName].filter(i => i).join(" ")
                              : className;
    const combinedStyle = active ? { ...style, ...activeStyle } : style;
    return (
      <Link
        className={combinedClassName}
        style={combinedStyle}
        {...(active && ariaCurrent && { "aria-current": ariaCurrent })}
        routesMap={location.routesMap}
        linkRef={ref}
        {...{
          to
        }}
        {...props}
      />
    );
  }
);

const mapState = state => ({
  location: selectLocationState(state)
});

const connector = connect(
  mapState,
  undefined,
  undefined,
  { forwardRef: true }
);

export default connector(NavLink);
