import { AvvirEvent } from "type_aliases";
import { ApiRunningProcess } from "avvir";

export type RunningProcessCheckedEvent = AvvirEvent<typeof RUNNING_PROCESS_CHECKED, {
  runningProcess: ApiRunningProcess
}>

const runningProcessChecked = ({ runningProcess }: { runningProcess: ApiRunningProcess }): RunningProcessCheckedEvent => ({
  type: RUNNING_PROCESS_CHECKED,
  payload: {
    runningProcess
  }
});

export const RUNNING_PROCESS_CHECKED = "running_process_checked";

export default runningProcessChecked;
