import { immerable } from "immer";
import { RunningProcessStatus, addReadOnlyPropertiesToModel, addDateGetterAndSetterToDomainModel } from "avvir";

import type { DateLike, Modify } from "type_aliases";

type PipelineArgument = Partial<Modify<Pipeline, {
  startTime?: DateLike
  endTime?: DateLike
}>>

export default class Pipeline {
  constructor({
    id,
    name,
    externalId,
    externalUrl,
    startTime,
    endTime,
    firebaseProjectId,
    firebaseFloorId,
    firebaseScanDatasetId,
    updates,
    status,
    userEmail,
  }: PipelineArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id });
    addDateGetterAndSetterToDomainModel(this, "startTime", startTime);
    addDateGetterAndSetterToDomainModel(this, "endTime", endTime);
    this.name = name || null;
    this.externalId = externalId || "";
    this.externalUrl = externalUrl || null;
    this.firebaseProjectId = firebaseProjectId || null;
    this.firebaseFloorId = firebaseFloorId || null;
    this.firebaseScanDatasetId = firebaseScanDatasetId || null;
    this.updates = updates || 0;
    this.status = status || null;
    this.userEmail = userEmail || null;
  }

  readonly id: number;
  name: string;
  externalId: string;
  externalUrl: string;
  startTime: Date;
  endTime: Date;

  firebaseProjectId: string;
  firebaseFloorId: string;
  firebaseScanDatasetId: string;
  status: RunningProcessStatus;
  updates?: number;
  userEmail: string;

  static readonly [immerable] = true;
}
