import addDateGetterAndSetterToDomainModel from "../../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import { DateLike, Modify } from "type_aliases";
import addReadOnlyPropertiesToModel from "../../../services/utilities/mixins/add_read_only_properties_to_model";

type ProjectCostAnalysisProgressArgument = Partial<Modify<ProjectCostAnalysisProgress, {
  analysisDate?: DateLike,

  plannedQuantityProject?: number;  // alias for plannedQuantity
  reportedQuantityProject?: number; // alias for reportedQuantity
  modeledQuantityProject?: number;
  scannedQuantityProject?: number;
  capturedPercentInstalledProject?: number;

  plannedTotalCostProject?: number; // alias for plannedTotalCost
  reportedTotalCostProject?: number;
  modeledTotalCostProject?: number;
  scannedTotalCostProject?: number;
  capturedPercentInstalledArea?: number;

  modeledQuantityArea?: number;     // alias for modeledQuantity
  scannedQuantityArea?: number;     // alias for scannedQuantity

  modeledTotalCostArea?: number;    // alias for modeledTotalCost
  scannedTotalCostArea?: number;    // alias for scannedTotalCost
}>>

export default class ProjectCostAnalysisProgress {
  constructor({
    id,
    masterformatCode,
    sequence,
    name,
    componentDescription,
    unitOfMeasure,
    plannedUnitCost,
    modeledQuantityProject,
    scannedQuantityProject,
    capturedPercentInstalledProject,
    analysisDate,
    plannedQuantityProject,
    plannedTotalCostProject,
    modeledQuantityArea,
    modeledTotalCostArea,
    reportedQuantityProject,
    reportedTotalCostProject,
    scannedQuantityArea,
    capturedPercentInstalledArea,
    scannedTotalCostArea,
    modeledTotalCostProject,
    scannedTotalCostProject,
    vendor
  }: ProjectCostAnalysisProgressArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id });
    addDateGetterAndSetterToDomainModel(this, "analysisDate");
    this.masterformatCode = masterformatCode;
    this.sequence = sequence;
    this.name = name;
    this.componentDescription = componentDescription;
    // @ts-ignore
    this.analysisDate = analysisDate;

    this.unitOfMeasure = unitOfMeasure;
    this.plannedUnitCost = plannedUnitCost;

    this.plannedQuantityProject = plannedQuantityProject;
    this.reportedQuantityProject = reportedQuantityProject;
    this.modeledQuantityProject = modeledQuantityProject;
    this.scannedQuantityProject = scannedQuantityProject;
    this.capturedPercentInstalledProject = capturedPercentInstalledProject;

    this.plannedTotalCostProject = plannedTotalCostProject;
    this.reportedTotalCostProject = reportedTotalCostProject;
    this.modeledTotalCostProject = modeledTotalCostProject;
    this.scannedTotalCostProject = scannedTotalCostProject;

    this.modeledQuantityArea = modeledQuantityArea;
    this.scannedQuantityArea = scannedQuantityArea;
    this.capturedPercentInstalledArea = capturedPercentInstalledArea;

    this.modeledTotalCostArea = modeledTotalCostArea;
    this.scannedTotalCostArea = scannedTotalCostArea;
    this.vendor = vendor;
  }

  id: number | null = null;
  masterformatCode: string | null = null;
  sequence: number | null = null;
  name: string | null = null;
  componentDescription: string | null = null;
  analysisDate: Date | null = null;

  unitOfMeasure: string | null = null;
  plannedUnitCost: number | null = null;

  // Project and Area attribute names
  plannedQuantityProject: number | null = null; // alias for plannedQuantity
  reportedQuantityProject: number | null = null;  // alias for reportedQuantity
  modeledQuantityProject: number | null = null;
  scannedQuantityProject: number | null = null;
  capturedPercentInstalledProject: number | null = null;

  plannedTotalCostProject: number | null = null; // alias for plannedTotalCost
  reportedTotalCostProject: number | null = null;
  modeledTotalCostProject: number | null = null;
  scannedTotalCostProject: number | null = null;

  modeledQuantityArea: number | null = null; // alias for modeledQuantity
  scannedQuantityArea: number | null = null; // alias for scannedQuantity
  capturedPercentInstalledArea: number | null = null;

  modeledTotalCostArea: number | null = null; // alias for modeledTotalCost
  scannedTotalCostArea: number | null = null; // alias for scannedTotalCost
  vendor?: string
}
