import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ToConfirmAccountPageEvent = AvvirPayloadlessRoutingEvent<typeof TO_CONFIRM_ACCOUNT_PAGE>

const toConfirmAccountPage = (): ToConfirmAccountPageEvent => ({
  type: TO_CONFIRM_ACCOUNT_PAGE,
});

export const TO_CONFIRM_ACCOUNT_PAGE = "to_confirm_account_page";

export default toConfirmAccountPage;
