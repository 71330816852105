import { AvvirEvent } from "type_aliases";

export type InspectReportEntryDeletedEvent = AvvirEvent<typeof INSPECT_REPORT_ENTRY_DELETED, { inspectReportId: number, inspectReportEntryId: number }>

const inspectReportEntryDeleted = (inspectReportId: number, inspectReportEntryId: number) =>({
  type: INSPECT_REPORT_ENTRY_DELETED,
  payload: {
    inspectReportId,
    inspectReportEntryId
  }
})

export const INSPECT_REPORT_ENTRY_DELETED = "inspect_report_entry_deleted";

export default inspectReportEntryDeleted;
