import { AvvirEvent } from "type_aliases";
import { ApiProject } from "avvir";

export type UserProjectsLoadedEvent = AvvirEvent<typeof USER_PROJECTS_LOADED, ApiProject[]>

const userProjectsLoaded = (projects: ApiProject[]): UserProjectsLoadedEvent => ({
  type: USER_PROJECTS_LOADED,
  payload: projects
});

export const USER_PROJECTS_LOADED = "user_projects_loaded";

export default userProjectsLoaded;
