import { ApiCloudFile } from "avvir";
import { AvvirEvent } from "type_aliases";

export type ScanDatasetFilesForProjectLoadedEvent = AvvirEvent<typeof SCAN_DATASET_FILES_FOR_PROJECT_LOADED, { [scanDatasetId: string]: ApiCloudFile[] }>

const scanDatasetFilesForProjectLoaded = (scanDatasetFilesByScanDatasetId: { [scanDatasetId: string]: ApiCloudFile[] }): ScanDatasetFilesForProjectLoadedEvent => ({
  type: SCAN_DATASET_FILES_FOR_PROJECT_LOADED,
  payload: scanDatasetFilesByScanDatasetId
});

export const SCAN_DATASET_FILES_FOR_PROJECT_LOADED = "scan_dataset_files_for_project_loaded";

export default scanDatasetFilesForProjectLoaded;
