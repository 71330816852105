import Avvir from "avvir";

import getPhotoSessionsForPhotoAreaById from "../../../services/getters/photo_area_getters/get_photo_sessions_for_photo_area_by_id";
import photoSessionsLoaded, { PhotoSessionsLoadedEvent } from "../../../events/loaded/photos/photo_sessions_loaded";
import { ApiFailureEvent } from "../../../events/notifications/failures/api_failure";
import { AvvirAsyncThunk } from "../../make_eventful_action";

const loadPhotoSessionsForPhotoArea = (firebaseProjectId: string, photoAreaId: number): AvvirAsyncThunk<typeof getPhotoSessionsForPhotoAreaById, PhotoSessionsLoadedEvent | ApiFailureEvent> => {
  return (dispatch, getState) => {
    const user = getState().user;
    return Avvir.api.photos.listPhotoSessionsForPhotoArea({ projectId: firebaseProjectId, photoAreaId }, user).then((photoSessions) => {
      dispatch(photoSessionsLoaded(photoSessions));
      return getPhotoSessionsForPhotoAreaById(getState(), {});
    });
  };
};

export default loadPhotoSessionsForPhotoArea;
