export const camelCaseArrayWords = (word, index) => {
  if (index === 0) {
    return word.toLowerCase();
  } else {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
};

export function camelCase(text: string): string {
  const stringArray = text.split(/[^a-zA-Z0-9]+|(?<=[a-z])(?=[A-Z][a-z])/);
  return stringArray.map(camelCaseArrayWords).join("");
}
