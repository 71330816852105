import _ from "underscore";

import type { Filter } from "./index";

export function toggleAllElements<Type extends Filter>(filter: Type, value: 0 | 1): Type {
  return _(filter).mapObject((element) => {
    return {
      ...element,
      selected: value
    };
  }) as { [key in keyof Type]: Type[key] };
}

export default toggleAllElements;
