import { AvvirRoutingEvent } from "type_aliases";

export type ToWbsWithProjectEvent = AvvirRoutingEvent<typeof TO_WBS_WITH_PROJECT, { projectId: string }>

const toWbsWithProject = (projectId: string): ToWbsWithProjectEvent => ({
  type: TO_WBS_WITH_PROJECT,
  payload: { projectId }
});

export const TO_WBS_WITH_PROJECT = "to_wbs_with_project";

export default toWbsWithProject;
