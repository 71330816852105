import { AvvirEvent } from "type_aliases";
import { ApiUser } from "avvir";

export type UserAccountLoadedEvent = AvvirEvent<typeof USER_ACCOUNT_LOADED, { user: ApiUser }>

const userAccountLoaded = (user: ApiUser): UserAccountLoadedEvent => ({
  type: USER_ACCOUNT_LOADED,
  payload: {
    user
  }
});

export const USER_ACCOUNT_LOADED = "user_account_loaded";

export default userAccountLoaded;
