import type { AvvirEvent } from "type_aliases";

export type TradeShownEvent = AvvirEvent<typeof TRADE_SHOWN, { code: string }>

const tradeShown = (code: string): TradeShownEvent => ({
  type: TRADE_SHOWN,
  payload: {
    code
  }
});

export const TRADE_SHOWN = "trade_shown";

export default tradeShown;
