import type { ApiScanDataset } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ScanDatasetsForProjectLoadedEvent = AvvirEvent<typeof SCAN_DATASETS_FOR_PROJECT_LOADED, { projectId: string, scanDatasets: ApiScanDataset[] }>

const scanDatasetsForProjectLoaded = (projectId: string, scanDatasets: ApiScanDataset[]): ScanDatasetsForProjectLoadedEvent => ({
  type: SCAN_DATASETS_FOR_PROJECT_LOADED,
  payload: {
    projectId,
    scanDatasets
  }
});

export const SCAN_DATASETS_FOR_PROJECT_LOADED = "scan_datasets_for_project_loaded";

export default scanDatasetsForProjectLoaded;
