import { createSelector } from "reselect";

import getDefaultFloorId from "../project_getters/get_default_floor_id";
import getFloorIdFromLocation from "../location_metadata/get_floor_id_from_location";
import getProject from "../project_getters/get_project";
import getProps from "../base_getters/get_props";
import type { GetPropsType } from "type_aliases";

type Selectors = [
  typeof getFloorIdFromLocation,
  typeof getDefaultFloorId,
  typeof getProject,
  GetPropsType<{ floorId: string }>]

const getFloorId = createSelector<Selectors, string>([
  getFloorIdFromLocation,
  getDefaultFloorId,
  getProject,
  getProps
], (locationFloorId, defaultFloorId, project, props) => {
  if (props.floorId) {
    return props.floorId;
  } else if (locationFloorId && project?.firebaseFloorIds?.includes(locationFloorId)) {
    return locationFloorId;
  } else if (defaultFloorId && project?.firebaseFloorIds?.includes(defaultFloorId)) {
    return defaultFloorId;
  } else {
    return null;
  }
});

export default getFloorId;
