import { Action } from "redux";

export type ElementDeselectedEvent = Action<typeof ELEMENT_DESELECTED>

const elementDeselected = (): ElementDeselectedEvent => ({
  type: ELEMENT_DESELECTED,
});

export const ELEMENT_DESELECTED = "element_deselected";

export default elementDeselected;
