import { InspectionMode } from "./inspection_modes";

export enum ColorMode {
  basicBim = "basicBim",
  bimPrep = "bimPrep",
  inspect = "qualityControlBim",
  progress = "monitorBim",
}

export const inspectionModeToColorMode = (inspectionMode: InspectionMode): ColorMode => {
  switch(inspectionMode) {
    case (InspectionMode.inspect):
      return ColorMode.inspect;
    case (InspectionMode.progress):
      return ColorMode.progress;
    case (InspectionMode.basicBim):
      return ColorMode.basicBim;
    case (InspectionMode.bimPrep):
      return ColorMode.bimPrep;
    default:
      return null;
  }
}
