// noinspection ES6UnusedImports

import type { GetState, NestedKeyOf, UndoableReduxStore } from "type_aliases";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type undoableActionUndone from "../../events/undo/undoable_action_undone";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type undoMiddleware from "../../services/undo_middleware";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { ActionHistoryEntry, ActionHistoryStore } from "../../services/reducers/reduce_action_history";

export default class UndoableAction {
  constructor(name: string, location: NestedKeyOf<UndoableReduxStore>) {
    this.name = name;
    this.location = location;
  }

  perform(dispatch, getState: GetState): Promise<void> {
    return Promise.resolve();
  }

  /**
   * This should not dispatch any events. The {@link undoMiddleware middleware} will dispatch an {@link undoableActionUndone `undoableActionUndone`} event for you.
   *
   * If undo fails, we still remove the {@link ActionHistoryEntry} from the {@link ActionHistoryStore.pastEdits stack} so the user isn't prevented from undoing previous work.
   * Unfortunately, this means if it fails, you can't retry the undo
   */
  undo(dispatch, getState: GetState) {}

  /** The human-readable name of the action which is displayed in the action history panel */
  name: string;

  readonly location: NestedKeyOf<UndoableReduxStore>;
}
