import { Circle, IPointData, Sprite } from "pixi.js";
import { PixiScalable } from "../../../../../services/utilities/pixi/pixi_scale";

const DEFAULT_SCREEN_SIZE = 14;
const DEFAULT_HIT_AREA_RATIO = 0.66;

export class FixedSizeSprite extends Sprite implements PixiScalable {
  protected hitAreaRatio: number = DEFAULT_HIT_AREA_RATIO;

  get screenSize(): number {
    return DEFAULT_SCREEN_SIZE;
  }

  get originalWidth() {
    return this.texture ? this.texture.orig.width : this.screenSize;
  }

  get originalHeight() {
    return this.texture ? this.texture.orig.height : this.screenSize;
  }

  rescale(newScale: IPointData) {
    const width = this.originalWidth;
    const height = this.originalHeight;
    this.width = width;
    this.height = height;
    this.pivot.set(width / 2, height / 2);
    this.hitArea = new Circle(width / 2, height / 2, width * this.hitAreaRatio);
    this.width = this.screenSize * newScale.x;
    this.height = this.screenSize * newScale.y;
  }

}
