import { QueryKey, useQuery, UseQueryResult } from "react-query";

import useAvvirSelector from "../services/component_helpers/component_effects/use_avvir_selector";
import useWithDispatch from "./use_with_dispatch";
import { AvvirActionCreator } from "../actions/make_eventful_action";
import { AvvirSelector, StandardQueryOptions, ThenArgRecursive } from "type_aliases";

type ActionCreatorReturnType<ActionCreator extends AvvirActionCreator> = ActionCreator extends AvvirActionCreator<infer Return, any> ? Return : never;
export type UseQueryWithIsInitialLoading<TData, TError> = UseQueryResult<TData, TError> & {isInitialLoading: boolean}
const useQueryWithState = <ActionCreator extends AvvirActionCreator, Return extends ThenArgRecursive<ActionCreatorReturnType<ActionCreator>>, Key extends QueryKey, TError, TData = Return>(queryKey: Key, action: ActionCreator, selector: AvvirSelector<Return, any>, options: StandardQueryOptions<ActionCreatorReturnType<ActionCreator>, unknown, Return, Key>, props: any, ...actionParams: Parameters<ActionCreator>): UseQueryWithIsInitialLoading<TData, TError> => {
  const queryAction = useWithDispatch(action, ...actionParams);
  const state = useAvvirSelector(selector, props);

  const useQueryReturn = useQuery<ActionCreatorReturnType<ActionCreator>, TError, TData, Key>(queryKey, queryAction, { ...options }) as UseQueryWithIsInitialLoading<TData, TError>;
  useQueryReturn.data = state;
  useQueryReturn.isInitialLoading = useQueryReturn.isFetching && useQueryReturn.isLoading;
  return useQueryReturn;
};

export default useQueryWithState;
