import { ApiOrganization } from "avvir";

import { AvvirEvent } from "type_aliases";

export type OrganizationsLoadedEvent = AvvirEvent<typeof ORGANIZATIONS_LOADED, ApiOrganization[]>;

const organizationsLoaded = (organizations: ApiOrganization[]): OrganizationsLoadedEvent => ({
  type: ORGANIZATIONS_LOADED,
  payload: organizations
});

export const ORGANIZATIONS_LOADED = "organizations_loaded";

export default organizationsLoaded;
