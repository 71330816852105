import type { ApiProjectAreaWorkPackage } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type ProjectAreaWorkPackagesUpdatedEvent = AvvirEvent<typeof PROJECT_AREA_WORK_PACKAGES_UPDATED, {
  projectId: string,
  projectAreaId: number,
  projectAreaWorkPackages: ApiProjectAreaWorkPackage[]
}>

const projectAreaWorkPackagesUpdated = (projectId: string, projectAreaId: number, projectAreaWorkPackages: ApiProjectAreaWorkPackage[]): ProjectAreaWorkPackagesUpdatedEvent => ({
  type: PROJECT_AREA_WORK_PACKAGES_UPDATED,
  payload: {
    projectId,
    projectAreaId,
    projectAreaWorkPackages
  }
});

export const PROJECT_AREA_WORK_PACKAGES_UPDATED = "project_area_work_packages_updated";

export default projectAreaWorkPackagesUpdated;
