import mixpanel, {Mixpanel} from "mixpanel-browser";
import { IS_TEST_ENV, IS_DEV_ENV, isTestServer } from "../utilities/current_environment";
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

// It has to be initialized with a valid token before we can call any other method to disable it during tests.
mixpanel.init(MIXPANEL_TOKEN, {
  // Opt out as soon as possible to avoid creating extra users
  loaded() {
    if (IS_TEST_ENV || IS_DEV_ENV || isTestServer()) {
      mixpanel.opt_out_tracking();
      mixpanel.disable();
    }
  }
});

export type { Mixpanel };
export default mixpanel as Mixpanel;
