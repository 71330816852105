import React, { FunctionComponent, useCallback } from "react";
import { Checkbox, MenuItem } from "@mui/material";

import "../../../css/general/checkbox_menu_item.css";

export type Props<Type extends string> = {
  value: Type
  enabled: boolean
  onItemEnable: (value: Type, isEnabled?: boolean) => void
  onItemDisable: (value: Type, isEnabled?: boolean) => void
  label: string
}

const CheckboxMenuItem = function CheckboxMenuItem<Type extends string>(props: Props<Type>) {
  const { value, onItemEnable, enabled, onItemDisable, label } = props;
  const onClickHandler = useCallback((event) => {
    if (enabled) {
      onItemDisable(value, !enabled);
    } else {
      onItemEnable(value, !enabled);
    }
  }, [enabled, onItemDisable, onItemEnable, value]);

  return <MenuItem
    className="CheckboxMenuItem"
    onClick={onClickHandler}
  >
    <div className="CheckboxMenuItem-iconLabel">{label}</div>
    <Checkbox style={{ padding: 0 }} checked={enabled} color="secondary"/>
  </MenuItem>;
};

export default CheckboxMenuItem;
