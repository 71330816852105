import { AvvirEvent } from "type_aliases";

export type PushToProcoreDialogShownEvent = AvvirEvent<typeof PUSH_TO_PROCORE_DIALOG_SHOWN, { procoreAccessToken: any, pdfType: any }>

const pushToProcoreDialogShown = (procoreAccessToken, pdfType): PushToProcoreDialogShownEvent => ({
  type: PUSH_TO_PROCORE_DIALOG_SHOWN,
  payload: {
    procoreAccessToken,
    pdfType
  }
});

export const PUSH_TO_PROCORE_DIALOG_SHOWN = "push_to_procore_dialog_shown";

export default pushToProcoreDialogShown;
