import getPhotoSessionsForPhotoAreaById from "../../services/getters/photo_area_getters/get_photo_sessions_for_photo_area_by_id";
import loadPhotoSessionsForPhotoArea from "../../actions/react_query/photos/load_photo_sessions_for_photo_area";
import useQueryWithState from "../use_query_with_state";

const usePhotoSessionsForPhotoArea = (projectId: string, photoAreaId: number) => {
  return useQueryWithState(["usePhotoSessionsForPhotoArea", [projectId, photoAreaId]],
    loadPhotoSessionsForPhotoArea,
    getPhotoSessionsForPhotoAreaById,
    { enabled: !!projectId && !!photoAreaId },
    { projectId, photoAreaId },
    projectId, photoAreaId);
};

export default usePhotoSessionsForPhotoArea;
