import { Action } from "redux";

export type RedoRequestedEvent = Action<typeof REDO_REQUESTED>

const redoRequested = (): RedoRequestedEvent => ({
  type: REDO_REQUESTED,
});

export const REDO_REQUESTED = "redo_requested";

export default redoRequested;
