import { Container } from "pixi.js";
import MapLocationContainer from "../layers_shared/map_location_container";
import { PixiMinimapTransforms } from "../pixi_minimap_app";
import PlannedBuildingElement from "../../../../../models/domain/planned_building_element";
import ClashingElementSprite from "./clashing_element_sprite";

function toDbId(element: PlannedBuildingElement) {
  return Number.parseInt(element.globalId.replace("Obj", ""));
}

export default class ClashingElementsContainer extends Container {
  private locationsContainer: MapLocationContainer<ClashingElementSprite>;
  private _elements: PlannedBuildingElement[];
  private transforms: PixiMinimapTransforms;
  private onClashSelected: (element: PlannedBuildingElement) => void;

  constructor(transforms: PixiMinimapTransforms, onClashSelected: (element: PlannedBuildingElement) => void) {
    super();
    this.locationsContainer = new MapLocationContainer<ClashingElementSprite>({
      onLocationSelected: this.onLocationSelected.bind(this)
    });
    // this.transforms = transforms; //todo refactor, but PixiMinimapApp doesn't exist yet -_-
    this.onClashSelected = onClashSelected;
  }

  onLocationSelected() {
    throw new Error("not implemented");
  }

  update(transforms: PixiMinimapTransforms) {
    this.transforms = transforms;
    this._elements.forEach((element) => {
      const dbId = toDbId(element);
      const sprite = this.locationsContainer.getById(dbId);
      if (sprite) {
        sprite.updateFromElement(element, transforms);
      }
    });
  }

  updateClashingElements(elements: PlannedBuildingElement[], transforms: PixiMinimapTransforms) {
    this.transforms = transforms;
    this._elements = elements;
    elements?.forEach((element) => {
      this.getOrCreateSprite(element);
    });
  }

  rescaleSprites(scale) {
    this.locationsContainer.rescale(scale);
  }

  getOrCreateSprite(element: PlannedBuildingElement) {
    const dbId = Number.parseInt(element.globalId.replace("Obj", ""));
    let sprite = this.locationsContainer.getById(dbId);
    if (sprite == null) {
      sprite = new ClashingElementSprite(element, this.transforms, this.onClashSelected);
      this.locationsContainer.addLocation(sprite);
      this.addChild(sprite);
    } else {
      // in case something changed about transforms, mainly.
      sprite.updateFromElement(element, this.transforms);
    }

    return sprite;
  }
}
