import _ from "underscore";
import { createSelector } from "reselect";

import Floor from "../../../models/domain/floor";
import getFirebaseFloorIdsForProject from "./get_firebase_floor_ids_for_project";
import getFloorsByFirebaseId from "./get_floors_by_firebase_id";
import { ByFirebaseId } from "type_aliases";

const getFloorsForProjectByFirebaseId = createSelector([
  getFirebaseFloorIdsForProject,
  getFloorsByFirebaseId
], (firebaseIds, floorsByFirebaseId) => _(floorsByFirebaseId).pick(...firebaseIds) as ByFirebaseId<Floor>);

export default getFloorsForProjectByFirebaseId;
