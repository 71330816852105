import { ApiIntegrationCredentials } from "avvir";
import { AvvirEvent } from "type_aliases";

export type IntegrationCredentialsLoadedEvent = AvvirEvent<typeof INTEGRATION_CREDENTIALS_LOADED, { projectId: string, integrationCredentials: ApiIntegrationCredentials[]}>;

const integrationCredentialsLoaded = (projectId: string, integrationCredentials: ApiIntegrationCredentials[]): IntegrationCredentialsLoadedEvent => ({
  type: INTEGRATION_CREDENTIALS_LOADED,
  payload: {
    projectId,
    integrationCredentials
  }
});

export const INTEGRATION_CREDENTIALS_LOADED = "integration_credentials_loaded";

export default integrationCredentialsLoaded;
