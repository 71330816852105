import _ from "underscore";
import { ApiCloudFile, ApiPurposeType, AvvirApiFiles } from "avvir";

import PurposeTypeConverter from "./purpose_type_converter";
import { AvvirFileIds, DateLike } from "type_aliases";
import { PurposeType } from "../../models/domain/enums/purpose_type";

type ApiFileCollection = { files: ApiCloudFile[] }
const isApiFileCollection = (files: any): files is ApiFileCollection => {
  return files?.hasOwnProperty("files");
};


export default class FileInformationConverter {
  static fileIdsFromApi<ApiType extends ApiPurposeType = ApiPurposeType, Type extends PurposeType = PurposeType>(files: AvvirApiFiles<ApiType> | ApiFileCollection): AvvirFileIds<Type> {
    if (isApiFileCollection(files)) {
      return files.files.reduce((filesSoFar: AvvirFileIds, file) => {
        const purposeType = PurposeTypeConverter.toPurposeType(file.purposeType);
        filesSoFar[purposeType] = filesSoFar[purposeType] || [];
        filesSoFar[purposeType].push(file.id);
        return filesSoFar;
      }, {});
    } else {
      return _.reduce<AvvirFileIds<Type>>(files, (filesSoFar, file) => {
        if (Array.isArray(file)) {
          const purposeType = PurposeTypeConverter.toPurposeType(file[0].purposeType);
          filesSoFar[purposeType] = file.map(fileObject => fileObject.id);
        } else {
          const purposeType = PurposeTypeConverter.toPurposeType(file.purposeType);
          filesSoFar[purposeType] = [(file as ApiCloudFile).id];
        }
        return filesSoFar;
      }, {});
    }
  }

  static toApiFile(purposeType: PurposeType, url: string, lastModified: DateLike, fileSize: number, originalFileName: string) {
    return _.omit(new ApiCloudFile({
      purposeType: PurposeTypeConverter.toApiPurposeType(purposeType),
      url,
      lastModified,
      fileSize,
      originalFileName
    }), (value) => value == null);
  }
}
