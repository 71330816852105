import { AvvirEvent } from "type_aliases";
import { ApiGroup } from "avvir";

export type GroupUpdatedEvent = AvvirEvent<typeof GROUP_UPDATED, {  group: ApiGroup  }>;

const groupUpdated = ( group: ApiGroup ): GroupUpdatedEvent => ({
  type: GROUP_UPDATED,
  payload: {
    group
  }
});

export const GROUP_UPDATED = "group_updated";

export default groupUpdated;
