import { Matrix3, Vector2 } from "three";
import Avvir, { ApiFloor, ApiPhotoAreaPurposeType, ApiPhotoLocation, ApiPhotoLocation3d, AssociationIds, GatewayUser, Matrix3Converter, User } from "avvir";
import PhotoLocation from "../models/domain/photos/photo_location";
import { photoSphereToViewerRotation } from "./getters/viewer_getters/get_photo_sphere_to_viewer_rotation";
import { getPhotoSphereToCameraQuaternion } from "./utilities/threejs_utilities/transform_utilities/compute_bim_to_photo_sphere_orientation";
import { computePhotoAreaMinimapToOriginalBim } from "./utilities/threejs_utilities/transform_utilities/photo_area_minimap_to_viewer_transforms";

import type Floor from "../models/domain/floor";
import type { UserSessionStore } from "./reducers/reduce_user_session";

function toBimLocation(photoAreaToBim: Matrix3, floor: ApiFloor | Floor, location: PhotoLocation, size: Vector2): ApiPhotoLocation3d {
  location.minimapCoordinates.multiply(size)
  const floorElevation = (floor.floorElevation == null ? 0 : floor.floorElevation) + 2;
  const position = PhotoLocation.transformMinimapCoordinates(location, photoAreaToBim, floorElevation);
  const orientation = photoSphereToViewerRotation(location, photoAreaToBim, floor.globalOffsetYaw)
    .multiply(getPhotoSphereToCameraQuaternion().inverse());

  return new ApiPhotoLocation3d({
    id: null,
    position,
    orientation
  });
}

async function getImageSizeWithAuth(url: string): Promise<Vector2> {
  const blobUrl = await window.Avvir.imageFetcher.getBlobUrl(url);
  return await new Promise((resolve) => {
    const img = new Image();
    img.src = blobUrl;
    img.onload = (() => {
      URL.revokeObjectURL(blobUrl);
      resolve(new Vector2(img.width, img.height));
    });
  });
}

async function backfillPhotoBimLocations({ projectId, photoAreaId }: AssociationIds, floor: ApiFloor | Floor, locations: ApiPhotoLocation[], size: Vector2, user: User) {
  let bimToWorld: Matrix3, photoAreaPixelToBimPixel: Matrix3;
  if (floor instanceof ApiFloor) {
    bimToWorld = Matrix3Converter.fromApiMatrix3ToMatrix3(floor.bimMinimapToWorld);
    photoAreaPixelToBimPixel = Matrix3Converter.fromApiMatrix3ToMatrix3(floor.photoAreaMinimapPixelToBimMinimapPixel);
  } else {
    bimToWorld = floor.bimMinimapToWorld;
    photoAreaPixelToBimPixel = floor.photoAreaMinimapPixelToBimMinimapPixel;
  }
  const photoAreaToBim = computePhotoAreaMinimapToOriginalBim(photoAreaPixelToBimPixel, bimToWorld);

  const toBackfill = locations.filter((location) => location.bimLocation == null).map((apiLocation) => {
    const location = PhotoLocation.fromApi(apiLocation);
    apiLocation.bimLocation = toBimLocation(photoAreaToBim, floor, location, size);
    return apiLocation;
  });

  if (toBackfill.length === 0) {
    console.log("All photo locations have BIM locations. Backfill skipped.");
    return;
  }

  console.log(`${toBackfill.length} locations converted. Sending to gateway ...`);
  await batchUpdatePhotoLocations({ projectId, photoAreaId }, toBackfill, user);
}

async function backfillBimLocations(ids: AssociationIds, floor: ApiFloor | Floor, user: UserSessionStore | GatewayUser) {
  if (ids.photoAreaId == null) {
    if (floor.photoAreaId == null) {
      console.error(`No photo area id specified and floor ${ids.floorId} does not have a photo area.`);
    } else {
      ids.photoAreaId = floor.photoAreaId;
    }
  }

  console.log(`Loading minimap for photo area ${ids.photoAreaId} ...`);
  const minimapFiles = await Avvir.api.files.listPhotoAreaFiles(ids, [ApiPhotoAreaPurposeType.MINIMAP], user);

  const size = await getImageSizeWithAuth(minimapFiles[0].url);
  console.log(`Minimap image size: ${size.x} x ${size.y}`);

  const photoSessions = await Avvir.api.photos.listPhotoSessionsForPhotoArea(ids, user);
  for (const photoSession of photoSessions) {
    console.log(`Querying photo locations for photo session id ${photoSession.id}`);
    ids.photoSessionId = photoSession.id;
    const locations = await Avvir.api.photos.listPhotoLocations(ids, user);
    await backfillPhotoBimLocations(ids, floor, locations, size, user);
  }
}

export async function batchUpdatePhotoLocations({ projectId, photoAreaId }, locations, user) {
  const batchSize = 5000;
  const batchCount = Math.ceil(locations.length / batchSize);
  for (let i = 0; i < batchCount; i++) {
    const locationsChunk = locations.slice(i*batchSize, (i+1)*batchSize);
    await Avvir.api.photos.updatePhotoLocations({ projectId, photoAreaId }, locationsChunk, user);
    console.log(`${i * batchSize} of ${locations.length} locations saved.`);
  }
}

export default backfillBimLocations;
