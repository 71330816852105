import { createSelector } from "reselect";

import getOrganizationId from "../organization_getters/get_organization_id";
import getGcpBearerTokensByOrganization from "./get_gcp_bearer_tokens_by_organization";

const getGcpBearerToken = createSelector([
  getGcpBearerTokensByOrganization, getOrganizationId
], (tokens, organizationId) => {
  if (tokens?.[organizationId]) {
    return {
      ...tokens?.[organizationId],
      secondsUntilTokenExpires: 0
    }
  } else {
    return null;
  }
});

export default getGcpBearerToken;
