import { ApiOrganization } from "avvir";

import { AvvirEvent } from "type_aliases";

export type OrganizationUpdatedEvent = AvvirEvent<typeof ORGANIZATION_UPDATED, { organizationId: string, organization: ApiOrganization }>

const organizationUpdated = (organizationId: string, organization: ApiOrganization): OrganizationUpdatedEvent => ({
  type: ORGANIZATION_UPDATED,
  payload: {
    organizationId,
    organization
  }
});

export const ORGANIZATION_UPDATED = "organization_updated";

export default organizationUpdated;
