import type ApiTradeCost from "../../../models/api/api_trade_cost";
import type { AvvirEvent } from "type_aliases";

export type TradeCostsLoadedEvent = AvvirEvent<typeof TRADE_COSTS_LOADED, { projectId: string, tradeCosts: ApiTradeCost[] }>

const tradeCostsLoaded = (projectId: string, tradeCosts: ApiTradeCost[]): TradeCostsLoadedEvent => ({
  type: TRADE_COSTS_LOADED,
  payload: {
    projectId,
    tradeCosts
  }
});

export const TRADE_COSTS_LOADED = "trade_costs_loaded";

export default tradeCostsLoaded;
