import { AvvirEvent } from "type_aliases";
import BimtrackAssociation from "../../models/domain/bimtrack/bimtrack_association";

export type BimtrackAssociationLoadedEvent = AvvirEvent<typeof BIMTRACK_ASSOCIATION_LOADED, BimtrackAssociation>

const bimtrackAssociationLoaded = (bimtrackAssociation: BimtrackAssociation): BimtrackAssociationLoadedEvent => ({
  type: BIMTRACK_ASSOCIATION_LOADED,
  payload: bimtrackAssociation
});

export const BIMTRACK_ASSOCIATION_LOADED = "bimtrack_association_loaded";

export default bimtrackAssociationLoaded;
