import { Container, Rectangle, IPointData, Point } from "pixi.js";
import clamp from "../general/clamp";

export function clampToRectangle(screen: Rectangle,
                                 container: Container,
                                 bounds: Rectangle,
                                 newPosition: IPointData): Point {
  const localPoint = container.parent.toLocal({x: screen.width, y: screen.height});
  localPoint.x -= bounds.width*2;

  // making 0 the limit will move the constrained rectangle to the top of the screen instead of the bottom
  localPoint.y = Math.min(localPoint.y - bounds.height * 2, 0);
  //localPoint.y -= bounds.height*2;
  return new Point(clamp(localPoint.x , newPosition.x,bounds.width), clamp(localPoint.y, newPosition.y, bounds.height));
}
