import { AvvirRoutingEvent } from "type_aliases";
import PageNames from "../../models/domain/enums/page_names";

export type ToCustomReportsEvent = AvvirRoutingEvent<typeof TO_CUSTOM_REPORTS, {
  projectId: string
  embedded?: boolean,
  pageName: PageNames
}>

const toCustomReports = (
  projectId: string, embedded?: boolean, pageName?: PageNames
): ToCustomReportsEvent => ({
  type: TO_CUSTOM_REPORTS,
  payload: {
    projectId,
    pageName: pageName || PageNames.PROJECT_REPORTS,
    embedded,
  }
});

export const TO_CUSTOM_REPORTS = "to_custom_reports";

export default toCustomReports;
