import _ from "underscore";
import { ApiPipeline } from "avvir";

import { ReduxStore } from "../reducers/root_reducer";
import { InspectionMode } from "../../models/domain/enums/inspection_modes";
import { defaultSelection } from "../reducers/reduce_selection";
import { defaultFilter } from "../reducers/filter/reduce_filter";
import { CustomBimColors, defaultBimColors } from "./bim_colors";

const PROCESSES_KEY = "runningProcesses";
const PHOTO_VISITED_KEY = "photoLocationsVisited";
const DISMISSED_PIPELINE_IDS_KEY = "dismissedPipelineIds";
const CUSTOM_BIM_COLORS_KEY = "customBimColors";

export function loadStore(): ReduxStore {
  try {
    const serializedState = localStorage.getItem("store");
    if (serializedState == null) {
      return;
    } else {
      const store = JSON.parse(serializedState);
      if (!Object.values(InspectionMode).includes(store?.selection?.inspectionMode)) {
        store.selection = { ...defaultSelection };
      }
      store.filter = {
        ...defaultFilter,
        onlyShowDeviationsFromCurrentScan: store.selection.inspectionMode === InspectionMode.inspect,
      };
      return store;
    }
  } catch (error) {
    return;
  }
}

export function saveStore(state: Partial<ReduxStore>) {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("store", serializedState);
  } catch {
    // ignore write errors
  }
}

export function storePipelines(pipelines: ApiPipeline[]): void {
  window.localStorage.setItem(PROCESSES_KEY, JSON.stringify(pipelines));
}

export function removePipelineFromStorage(pipelineId: number): void {
  const pipelines = loadPipelines();
  storePipelines(pipelines.filter((existingPipeline) => {
    const validPipeline = existingPipeline.name as string !== "ResponseError" && existingPipeline.id;
    return validPipeline && existingPipeline.id !== pipelineId;
  }));
}

export function addPipelineToStorage(pipeline: ApiPipeline): void {
  const pipelines = loadPipelines();
  const pipelineIndex = _(pipelines).findIndex({ id: pipeline.id });
  if (pipelineIndex !== -1) {
    pipelines[pipelineIndex] = pipeline;
    storePipelines(pipelines);
  } else {
    pipelines.push(pipeline);
    storePipelines(pipelines);
  }
}

export function loadPipelines(): ApiPipeline[] {
  const fromStore = window.localStorage.getItem(PROCESSES_KEY);
  return ((fromStore == null || fromStore === "") ? [] : JSON.parse(fromStore))
    .map((stored) => { return new ApiPipeline(stored); });
}

function loadBooleanMap(key: string): {
  [id: number]: boolean
} {
  const fromStore = window.localStorage.getItem(key);
  return ((fromStore == null || fromStore === "") ? {} : JSON.parse(fromStore));
}

function saveInBooleanMapById(key: string, id: number, value: boolean): void {
  const fromStore = loadBooleanMap(key);
  fromStore[id] = value;
  window.localStorage.setItem(key, JSON.stringify(fromStore));
}

export function savePhotoLocationVisited(photoLocationId: number): void {
  saveInBooleanMapById(PHOTO_VISITED_KEY, photoLocationId, true);
}

export function loadPhotoLocationsVisited(): {
  [id: number]: boolean
} {
  return loadBooleanMap(PHOTO_VISITED_KEY);
}

export function loadDismissedPipelineIds(): {
  [id: number]: boolean,
} {
  return loadBooleanMap(DISMISSED_PIPELINE_IDS_KEY);
}

export function saveDismissedPipelineId(pipelineId): void {
  saveInBooleanMapById(DISMISSED_PIPELINE_IDS_KEY, pipelineId, true);
}

export function loadCustomBimColors(): CustomBimColors {
  const fromStore = window.localStorage.getItem(CUSTOM_BIM_COLORS_KEY);
  if (fromStore == null) {
    return defaultBimColors();
  }

  return JSON.parse(fromStore) as CustomBimColors;
}

export function storeCustomBimColors(customBimColors: CustomBimColors) {
  window.localStorage.setItem(CUSTOM_BIM_COLORS_KEY, JSON.stringify(customBimColors));
}
