import { AvvirEvent } from "type_aliases";

export type ObstructionsDeletedEvent = AvvirEvent<typeof OBSTRUCTIONS_DELETED, { floorId: string, obstructions: { [globalId: string]: string[] } }>

const obstructionsDeleted = (floorId: string, obstructions: { [globalId: string]: string[] }): ObstructionsDeletedEvent => ({
  type: OBSTRUCTIONS_DELETED,
  payload: {
    floorId,
    obstructions
  }
});

export const OBSTRUCTIONS_DELETED = "obstructions_deleted";

export default obstructionsDeleted;
