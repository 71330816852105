import { ApiIntegrationProject } from "avvir";
import { AvvirEvent } from "type_aliases";

export type IntegrationProjectsLoadedEvent = AvvirEvent<typeof INTEGRATION_PROJECTS_LOADED, { projectId: string, integrationProjects: ApiIntegrationProject[]}>;

const integrationProjectsLoaded = (projectId: string, integrationProjects: ApiIntegrationProject[]): IntegrationProjectsLoadedEvent => ({
  type: INTEGRATION_PROJECTS_LOADED,
  payload: {
    projectId,
    integrationProjects
  }
});

export const INTEGRATION_PROJECTS_LOADED = "integration_projects_loaded";

export default integrationProjectsLoaded;
