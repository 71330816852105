import { createSelector } from "reselect";
import _ from "underscore";

import getGroupMembersForFloor from "./get_group_members_for_floor";

const empty = new Set<string>();

const getGroupMemberGlobalIdsForFloor = createSelector([
  getGroupMembersForFloor
], (groupMembersForFloor) => {
  if (_.size(groupMembersForFloor)) {
    return new Set(_(groupMembersForFloor).pluck("elementId"));
  } else {
    return empty;
  }
});

export default getGroupMemberGlobalIdsForFloor;
