import { AvvirEvent } from "type_aliases";

export type ProjectArchivedEvent = AvvirEvent<typeof PROJECT_ARCHIVED, { projectId: string }>

const projectArchived = (projectId: string): ProjectArchivedEvent => ({
  type: PROJECT_ARCHIVED,
  payload: {
    projectId
  }
});

export const PROJECT_ARCHIVED = "project_archived";

export default projectArchived;
