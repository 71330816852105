import { createSelector } from "reselect";
import getDomain from "../base_getters/get_domain";

const getPlannedBuildingElementsByFirebaseFloorId = createSelector([
  getDomain
], (domain) => {
  return domain.plannedBuildingElements.byFirebaseFloorId;
});

export default getPlannedBuildingElementsByFirebaseFloorId
