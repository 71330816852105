import { AvvirRoutingEvent } from "type_aliases";

export type ToViewerFloorEvent = AvvirRoutingEvent<typeof TO_VIEWER_FLOOR, {
  projectId: string
  floorId: string
}>

const toViewerFloor = (projectId: string, floorId: string): ToViewerFloorEvent => ({
  type: TO_VIEWER_FLOOR,
  payload: {
    projectId,
    floorId
  }
});

export const TO_VIEWER_FLOOR = "to_viewer_floor";

export default toViewerFloor;
