import { createSelector } from "reselect";
import { Vector2 } from "three";

import getFloor from "../floor_getters/get_floor";
import { computeOriginalBimToAvvirViewer } from "../../utilities/threejs_utilities/transform_utilities/photo_area_minimap_to_viewer_transforms";

const identityTransform = computeOriginalBimToAvvirViewer(new Vector2(0, 0));

const getOriginalBimToViewer = createSelector([
  getFloor
], (floor) => {

  //We have: bimMinimap to AvvirBimWorld <- (Legacy viewer coordinates)
  //We need: bimMinimap to ForgeBimworld
  //What is the relationship between AvvirBimWorld and ForgeBimWorld?
  //We also have:
  //AvvirBimWorld to OriginalBimWorld (floor offset)
  //ForgeBimWorld to OriginalBimWorld (forge global offset)
  //We can combine this to get AvvirBimWorld to ForgeBimWorld
  //We compose
  //bimMinimapTo(Avvir)BimWorld @ AvvirBimWorldToForgeBimWorld = bimMinimapToForgeBimWorld
  if (floor?.offset) {
    return computeOriginalBimToAvvirViewer(floor.offset);
  } else {
    return identityTransform;
  }
});

export default getOriginalBimToViewer;
