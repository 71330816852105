export enum InspectionMode {
  basicBim = "fullBim",
  bimPrep = "bimPrep",
  inspect = "qualityControlBim",
  progress = "monitorBim",
}

// NOTE(dlb): This affects the order that the inspection modes are displayed in the UI.
const inspectionModes: Array<{ displayName: string, value: InspectionMode }> = [
  { displayName: "Basic BIM", value: InspectionMode.basicBim },
  { displayName: "BIM Prep", value: InspectionMode.bimPrep },
  { displayName: "Inspect", value: InspectionMode.inspect },
  { displayName: "Progress", value: InspectionMode.progress },
];

export default inspectionModes;
