import { createSelector } from "reselect";

import getGroupMemberGlobalIdsByGroupId from "./get_group_member_global_ids_by_group_id";
import getGroupMembersForFloorByGlobalId from "./get_group_members_for_floor_by_global_id";
import getProps from "../base_getters/get_props";
import { GetPropsType } from "type_aliases";

type Selectors = [
  typeof getGroupMembersForFloorByGlobalId,
  typeof getGroupMemberGlobalIdsByGroupId,
  GetPropsType<{ globalId: string }>
];

const empty: string[] = [];

const getGroupMemberGlobalIdsForElement = createSelector<Selectors, string[]>([
  getGroupMembersForFloorByGlobalId, getGroupMemberGlobalIdsByGroupId, getProps
], (groupMembersByGlobalId, globalIdsByGroupId, props) => {
  if (props?.globalId) {
    const groupId = groupMembersByGlobalId[props.globalId].groupId;
    if (groupId) {
      return globalIdsByGroupId[groupId] || empty;
    } else {
      return empty
    }
  } else {
    return empty;
  }
});

export default getGroupMemberGlobalIdsForElement
