import React, { FunctionComponent, lazy, Suspense, useEffect, useMemo } from "react";
import { ACCEPT_INVITATION, BIMTRACK_AUTHENTICATED, CONFIRM_ACCOUNT, HX_AUTH_LOGIN, LOGIN, PROCORE_AUTHENTICATED, RESET_PASSWORD, RESET_PASSWORD_EMAIL_FORM } from "../../models/domain/enums/page_names";
import LoadingOverlaySuspense from "./loading_overlay_suspense";
import { UserRole } from "avvir";
import { useDispatch } from "react-redux";
import toSuperadminOrganizations from "../../events/routing/superadmin/to_superadmin_organizations";
import useLocalStorage from "../../services/component_helpers/component_effects/use_local_storage";
import toProjectSummaryPage from "../../events/routing/to_project_summary_page";
import toWbsWithProject from "../../events/routing/to_wbs_with_project";
import useProjectListings from "../../queries/projects/use_project_listings";

const NotFoundPage = lazy(() => import("../other_pages/error_404_page"));
const AcceptInvitePage = lazy(() => import("../other_pages/accept_invitation_page"));
const ResetPasswordPage = lazy(() => import("../other_pages/reset_password_page"));
const ResetPasswordEmailForm = lazy(() => import ("../other_pages/reset_password_email_form_page"));
const SignInPage = lazy(() => import("../other_pages/sign_in_page"));
const HxAuthLoginPage = lazy(() => import("../other_pages/hx_auth_login_page"))
const ProcoreAuthenticatedPage = lazy(() => import("../other_pages/procore_authenticated_page"));
const BimtrackAuthenticatedPage = lazy(() => import("../other_pages/bimtrack_authenticated_page"));
const ClientUserPage = lazy(() => import("./client_user_router"));
const SuperadminPage = lazy(() => import("./superadmin_router"));
const ConfirmAccountPage = lazy(() => import("../other_pages/confirm_account_page"));

export type Props = {
  pageName: string,
  notFound: boolean,
  isClientUserPage: boolean,
  isSuperadminPage: boolean,
  noUser: boolean,
  userRole?: UserRole,
  defaultProject?: string
};

const ApplicationContentUnderNavBar: FunctionComponent<Props> = ({ pageName, notFound, isClientUserPage, isSuperadminPage, noUser, userRole, defaultProject }) => {
  const dispatch = useDispatch();
  const { data: projectListings } = useProjectListings();
  const [lastProjectId] = useLocalStorage("lastProjectId", null);
  const projectId = lastProjectId || defaultProject || projectListings[0]?.firebaseId;
  useEffect(()=>{
    if(pageName === LOGIN) {
      if (!noUser && userRole === UserRole.SUPERADMIN) {
        dispatch(toSuperadminOrganizations());
      }
      if (!noUser && userRole !== UserRole.SUPERADMIN && projectId) {
        const avvirRoutingEvent = userRole === UserRole.SUBCONTRACTOR ?
                                  toWbsWithProject((projectId)) : toProjectSummaryPage(projectId);
        dispatch(avvirRoutingEvent);
      }
    }
  }, [pageName, userRole, noUser, projectId, dispatch]);

  const contentUnderNavBar = useMemo(() => {
    switch (true) {
      case notFound: {
        return <NotFoundPage/>;
      }
      case pageName === ACCEPT_INVITATION: {
        return <AcceptInvitePage/>;
      }
      case pageName === RESET_PASSWORD: {
        return <ResetPasswordPage/>;
      }
      case pageName === RESET_PASSWORD_EMAIL_FORM: {
        return <ResetPasswordEmailForm/>;
      }
      case pageName === HX_AUTH_LOGIN: {
        return <HxAuthLoginPage/>;
      }
      case noUser:  // everything under here is behind a login wall
      case pageName === LOGIN: {
        return <SignInPage/>;
      }
      case pageName === PROCORE_AUTHENTICATED: {
        return <ProcoreAuthenticatedPage/>;
      }
      case pageName === BIMTRACK_AUTHENTICATED: {
        return <BimtrackAuthenticatedPage/>;
      }
      case isClientUserPage: {
        return <ClientUserPage/>;
      }
      case isSuperadminPage: {
        return <SuperadminPage/>;
      }
      case pageName === CONFIRM_ACCOUNT: {
        return <ConfirmAccountPage/>;
      }
      default: {
        return <NotFoundPage/>;
      }
    }
  }, [isClientUserPage, isSuperadminPage, noUser, notFound, pageName]);

  return <Suspense fallback={<LoadingOverlaySuspense/>}>
    {contentUnderNavBar}
  </Suspense>;
};

export default ApplicationContentUnderNavBar;
