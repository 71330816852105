import { createSelector } from "reselect";
import getDomain from "../base_getters/get_domain";

const getProjectListings = createSelector(
  [getDomain],
  (domain) => {
    return domain.projects?.listings || [];
  }
);
export default getProjectListings;
