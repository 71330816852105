import { AvvirEvent } from "type_aliases";

export type BimtrackAssociationDialogShownEvent = AvvirEvent<typeof BIMTRACK_ASSOCIATION_DIALOG_SHOWN, {issueTitle: string, globalId: string }>

const bimtrackAssociationDialogShown = (issueTitle: string, globalId: string): BimtrackAssociationDialogShownEvent => ({
  type: BIMTRACK_ASSOCIATION_DIALOG_SHOWN,
  payload: { issueTitle, globalId }
});

export const BIMTRACK_ASSOCIATION_DIALOG_SHOWN = "bimtrack_association_dialog_shown";

export default bimtrackAssociationDialogShown;
