import { Action } from "redux";

export type ToastNotificationExpiredEvent = Action<typeof TOAST_NOTIFICATION_EXPIRED>

const toastNotificationExpired = (): ToastNotificationExpiredEvent => ({
  type: TOAST_NOTIFICATION_EXPIRED
});

export const TOAST_NOTIFICATION_EXPIRED = "toast_notification_expired";

export default toastNotificationExpired;
