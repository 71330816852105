import firebaseApp from "firebase/app";

import { AvvirThunk } from "../make_eventful_action";
import couldNotSignIn, { CouldNotSignInEvent } from "../../events/notifications/failures/could_not_sign_in";
import firebase from "../../services/firebase_services/firebase";
import firebaseSignedInWithCustomToken, { FirebaseSignedInWithCustomTokenEvent } from "../../events/auth_events/firebase_signed_in_with_custom_token";
import Avvir from "avvir";
import getUser from "../../services/getters/base_getters/get_user";

type DispatchedEvents = FirebaseSignedInWithCustomTokenEvent | CouldNotSignInEvent;

const authorizeFirebaseUser = (): AvvirThunk<Promise<void>, DispatchedEvents> => {
  return (dispatch, getState) => {
    const user = getUser(getState(), {});
    return Avvir.api.auth.getCustomFirebaseToken(user).then((response) => {
      const token = response.body.storageToken;
      if (token) {
        return firebase.auth().setPersistence(firebaseApp.auth.Auth.Persistence.LOCAL)
          .then(() => {
            return firebase.auth().signInWithCustomToken(token).then(() => {
              dispatch(firebaseSignedInWithCustomToken(token));
              return Promise.resolve();
            });
          })
          .catch((error) => {
            dispatch(couldNotSignIn(error));
            return Promise.reject(error);
          });
      } else {
        const error = new Error("Unable to sign in");
        error.name = "SignInFailedError";
        dispatch(couldNotSignIn(error));
        return Promise.reject(error);
      }
    });
  };
};

export default authorizeFirebaseUser;
