import { createSelector } from "reselect";
import _ from "underscore";
import { ApiGroupMember } from "avvir";

import getGroupMembersForFloor from "./get_group_members_for_floor";

const empty: { [globalId: string]: ApiGroupMember } = {};

const getGroupMembersForFloorByGlobalId = createSelector([
  getGroupMembersForFloor,
], (groupsMembersForFloor) => {
  if (_.size(groupsMembersForFloor)) {
    return _(groupsMembersForFloor).indexBy("elementId");
  } else {
    return empty;
  }
});

export default getGroupMembersForFloorByGlobalId;
