import { Action } from "redux";

export type ProgressReportPdfDateRangeEvent = Action<typeof PROGRESS_REPORT_PDF_DATE_RANGE>

const progressReportPdfDateRange = (): ProgressReportPdfDateRangeEvent => ({
  type: PROGRESS_REPORT_PDF_DATE_RANGE,
});

export const PROGRESS_REPORT_PDF_DATE_RANGE = "progress_report_pdf_date_range";

export default progressReportPdfDateRange;
