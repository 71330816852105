import { Action } from "redux";

export type ViewerDisposedEvent = Action<typeof VIEWER_DISPOSED>

const viewerDisposed = (): ViewerDisposedEvent => ({
  type: VIEWER_DISPOSED
});

export const VIEWER_DISPOSED = "forge_viewer_disposed";

export default viewerDisposed;
