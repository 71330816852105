import getScanDataset from "../../services/getters/scan_dataset_getters/get_scan_dataset";
import loadScanDataset from "../../actions/react_query/scan_datasets/load_scan_dataset";
import useQueryWithState from "../use_query_with_state";

const useScanDataset = (projectId: string, floorId: string, scanDatasetId: string) => {
  return useQueryWithState(["useScanDataset", [projectId, floorId, scanDatasetId]],
    loadScanDataset,
    getScanDataset,
    { enabled: !!scanDatasetId && !!floorId && !!projectId },
    { projectId, floorId, scanDatasetId },
    { projectId, floorId, scanDatasetId });
};

export default useScanDataset;
