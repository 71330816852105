import { AssociationIds, AvvirEvent, Vector3Like } from "type_aliases";

export type PushToBimUndoneEvent = AvvirEvent<typeof PUSH_TO_BIM_UNDONE, {
  floorId: string
  scanDatasetId: string
  deviationVectorMeters: Vector3Like
  deviationGlobalId: string
  isFixedDeviation: boolean
}>

const pushToBimUndone = ({ floorId, scanDatasetId, globalId }: AssociationIds, deviationVectorMeters: Vector3Like, isFixedDeviation: boolean): PushToBimUndoneEvent => ({
  type: PUSH_TO_BIM_UNDONE,
  payload: {
    floorId,
    scanDatasetId,
    deviationVectorMeters,
    deviationGlobalId: globalId,
    isFixedDeviation
  }
});

export const PUSH_TO_BIM_UNDONE = "undo_push_to_bim";

export default pushToBimUndone;
