import React, { FunctionComponent, useCallback, useState } from "react";
import { Button, createTheme, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputLabel } from "@mui/material";
import { ThemeProvider } from "@mui/styles";
import { PickersDayProps, StaticDatePicker } from "@mui/x-date-pickers";
import moment, { Moment, } from "moment";
import classNames from "classnames";

import "../../../css/form_fields/date_range_picker.css";

export type Props = {
  label?: string,
  startDate?: Moment,
  endDate?: Moment,
  handleChangeStartEnd: (date: Moment) => void,
  open: boolean,
  onClose: () => void,
  onClear: () => void,
  validDates?: Date[]
}


const datePickerTheme = createTheme({
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: ".5rem 2rem .5rem 2rem"
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          minWidth: "42px"
        }
      }
    },
  },
});

const AvvirDay: FunctionComponent<PickersDayProps<Moment> & { validDates: Date[], startDate: Moment, endDate: Moment }> = function AvvirDay(props) {
  const { day, outsideCurrentMonth, validDates, startDate, endDate } = props;
  let highlighted = false;
  let hasData = false;

  if (day.isValid()) {
    if (validDates != null && validDates.length > 0) {
      hasData = validDates.some((d) => {
        return moment(d).isSame(day, "day");
      });
    }

    if (startDate && endDate) {
      if (day.isSameOrAfter(startDate, "day") && day.isSameOrBefore(endDate, "day")) {
        highlighted = true;
      }
    }
  }

  let disabled = outsideCurrentMonth || !hasData;
  return <div
    className={classNames({
      "DateRangePicker-highlighted": !outsideCurrentMonth && highlighted,
      "DateRangePicker-disabled": disabled
    })}
  >
    <IconButton
      className={classNames("DateRangePicker-button", { "DateRangePicker-hasData": !outsideCurrentMonth && hasData })}
      disabled={disabled}
    >
      <span>{outsideCurrentMonth ? " " : day.format("D")}</span>
    </IconButton>
  </div>;
};

const DateRangePicker: FunctionComponent<Props> = props => {
  const { label, open, startDate, endDate, handleChangeStartEnd, onClose, onClear, validDates } = props;
  const [selectedDate, setSelectedDate] = useState<Moment>(null);
  const minDate = validDates == null || validDates.length < 1 ? null : moment(validDates[0]);
  const maxDate = validDates == null || validDates.length < 1 ? null : moment(validDates[validDates.length - 1]);

  const renderStartDate = startDate?.clone().hours(0).minutes(0).seconds(0).milliseconds(0);
  const renderEndDate = endDate?.clone().hours(0).minutes(0).seconds(0).milliseconds(0);

  const handleChangeSelectDate = useCallback((date: Moment) => {
    handleChangeStartEnd(date);
    setSelectedDate(date);
  }, [handleChangeStartEnd]);

  const isValidRange = startDate?.isValid() && endDate?.isValid();

  return <ThemeProvider theme={datePickerTheme}>
    <Dialog open={open} onClose={onClose} className="DateRangePicker">
      <DialogTitle className="DateRangePicker-title">
        <div className="DateRangePicker-legend">
          <span className="DateRangePicker-hasData">&nbsp;</span><span className="DateRangePicker-legendDescription">denotes scan date</span>
        </div>
        <div className="DateRangePicker-titleLabel">select scan dates</div>
        <div className="DateRangePicker-titleDates">
          {
            startDate || endDate ?
              [startDate ? moment(startDate).format("ll") : "",
                " - ",
                endDate ? moment(endDate).format("ll") : ""] : <span className="DateRangePicker-emptyDates">none selected</span>
          }
        </div>
      </DialogTitle>
      <DialogContent className="DateRangePicker-content">
        <FormControl>
          <InputLabel>{label}</InputLabel>
          <StaticDatePicker<Moment>
            slots={{
              day: AvvirDay
            }}
            slotProps={{
              day: {
                startDate: renderStartDate,
                endDate: renderEndDate,
                validDates
              } as any
            }}
            value={selectedDate}
            defaultCalendarMonth={minDate}
            onChange={handleChangeSelectDate}
            minDate={minDate}
            maxDate={maxDate}
          />
          <div>&nbsp;</div>
        </FormControl>
      </DialogContent>
      <DialogActions className="DateRangePicker-actions">
        <Button onClick={onClear} color="primary" className="DateRangePicker-clear">Clear</Button>
        <Button onClick={onClose} color="primary" className="DateRangePicker-ok" disabled={!isValidRange}>Ok</Button>
      </DialogActions>
    </Dialog>
  </ThemeProvider>;
};

export default React.memo(DateRangePicker);
