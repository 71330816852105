import kebabCase from "../converters/kebab_case";

export type FileNameParts = {
  projectName: string,
  filenamePrefix?: string,
  fileDate?: string,
  fileExtension?: string
}

export default function buildProjectWbsFileName(parts: FileNameParts) {
  const { projectName, filenamePrefix, fileDate, fileExtension } = parts;
  let validFileNamePrefix: string;
  if (filenamePrefix) {
    validFileNamePrefix = filenamePrefix.endsWith("-") ? filenamePrefix : `${filenamePrefix}-`;
  } else {
    validFileNamePrefix = "project-wbs-";
  }
  // eslint-disable-next-line no-useless-escape
  const validProjectName = kebabCase(projectName.replace(/[\/~+|,]/g, " ").replace(/[[\]'"?^$.#!*<>\\{}`:;%]/g, ""));
  const validFileDate = fileDate;
  const validFileExtension = fileExtension ? fileExtension : "tsv";
  return `${validFileNamePrefix}${validProjectName}_${validFileDate}.${validFileExtension}`;
}
