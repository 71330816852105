import { createSelector } from "reselect";

import getPhotoLocationIdFromLocation from "../location_metadata/get_photo_location_id_from_location";
import getPhotoLocationsById from "./get_photo_locations_by_id";

const getPhotoLocation = createSelector([
  getPhotoLocationIdFromLocation,
  getPhotoLocationsById
], (photoLocationId, locationsById) => locationsById[photoLocationId] || null);

export default getPhotoLocation;
