import { AvvirEvent } from "type_aliases";

export type ElementColoredEvent = AvvirEvent<typeof ELEMENT_COLORED, { globalId: string, color: string }>

const elementColored = (globalId: string, color: string): ElementColoredEvent => ({
  type: ELEMENT_COLORED,
  payload: {
    globalId,
    color
  }
});

export const ELEMENT_COLORED = "element_colored";

export default elementColored;
