import { Graphics, LINE_CAP, LINE_JOIN } from "pixi.js";
import { PixiUpdatable } from "../../../../../../services/utilities/pixi/pixi_updateable";
import { PixiMinimapApp } from "../../pixi_minimap_app";
import { PixiPhotosLayer } from "../photos_layer";

export class PhotoEditingLines extends Graphics implements PixiUpdatable {
  private _zoomScale: number = 1;
  private _enabled: boolean = false;
  private _locations: PixiPhotosLayer;

  public lineWidth = 3;

  constructor(locations: PixiPhotosLayer) {
    super();
    this._locations = locations;
    this.zIndex = 0;
    this.x = 0;
    this.y = 0;
    this.visible = false;
  }

  get enabled() {
    return this._enabled;
  }

  set enabled(value: boolean) {
    this._enabled = value;
    this.visible = value;
    if (!value) {
      this.clear();
    }
  }

  rescale(zoom: number) {
    this._zoomScale = zoom;
  }

  update(app: PixiMinimapApp) {
    if (!this._enabled) {
      return;
    }

    const style = app.styles.adjustStyle;

    this.clear();
    this.lineStyle({
      width: this.lineWidth * this._zoomScale,
      color: style.lineColor,
      join: LINE_JOIN.BEVEL,
      cap: LINE_CAP.SQUARE
    });

    const photos = this._locations.photoSprites;
    if (photos.length > 0) {
      this.moveTo(photos[0].x, photos[0].y);

      for (let i = 0; i < photos.length; i++) {
        const photo = photos[i];
        this.lineTo(photo.x, photo.y);
      }
    }
  }

}
