import Avvir, { ApiElementDeviation, ApiPlannedElement } from "avvir";

import UndoableAction from "./undoable_action";
import getProjectId from "../../services/getters/project_getters/get_project_id";
import getUser from "../../services/getters/base_getters/get_user";
import { ActionHistoryEntry } from "../../services/reducers/reduce_action_history";
import { ElementEditedEventType, getEditElementEventTypes } from "./element_edit_action_event_types";

import type { ElementEditedEventPayload } from "./element_edited_event";
import type { Dispatch, GetState } from "type_aliases";

export const isElementEditActionPayload = (edit: ActionHistoryEntry): edit is ElementEditActionEntry => {
  return getEditElementEventTypes().has((edit.action as ElementEditAction).type);
};

export interface ElementEditActionEntry extends ActionHistoryEntry {
  action: ElementEditAction;
}

export default class ElementEditAction extends UndoableAction {
  constructor(name: string, type: ElementEditedEventType, floorId: string) {
    super(name, ["domain", "plannedBuildingElements", "byFirebaseFloorId", floorId, "byGlobalId"]);
    this.type = type;
    this.payload = {
      floorId,
      previousElementStates: null, // This will get filled in by the child class
      nextElementStates: null, // This will get filled in by the child class
    };
  }

  type: ElementEditedEventType;
  payload: ElementEditedEventPayload;

  undo(dispatch: Dispatch, getState: GetState) {
    const projectId = getProjectId(getState(), {});
    const user = getUser(getState(), {});
    const floorId = this.payload.floorId;
    const updatedElements = this.payload.previousElementStates.map(element => {
      let pbe = new ApiPlannedElement({
        ...element,
      });
      // The gateway treats deviation being unset as "don't change this" so we
      // need to provide an empty deviation object.
      if (element.deviation == null) {
        pbe.deviation = new ApiElementDeviation();
      }
      if (element.fixedDeviation == null && pbe.fixedAt != null) {
        pbe.fixedDeviation = new ApiElementDeviation();
      }
      // TODO: This should be unnecessary once we remove the clashing boolean from the api object
      if (pbe.deviation?.deviationVectorMeters != null) {
        pbe.deviation.clashing = false;
      }
      if (pbe.fixedDeviation?.deviationVectorMeters != null) {
        pbe.fixedDeviation.clashing = false;
      }
      return pbe;
    });
    return Avvir.api.elements.updatePlannedBuildingElementsForViewerUndo({ projectId, floorId }, updatedElements, user);
  }
}
