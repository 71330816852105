import type { ApiWorkPackage } from "avvir";
import type { AvvirEvent } from "type_aliases";

export type WorkPackageCreatedEvent = AvvirEvent<typeof WORK_PACKAGE_CREATED, { projectId: string, workPackage: ApiWorkPackage }>

const workPackageCreated = (projectId: string, workPackage: ApiWorkPackage): WorkPackageCreatedEvent => ({
  type: WORK_PACKAGE_CREATED,
  payload: {
    projectId,
    workPackage
  }
});

export const WORK_PACKAGE_CREATED = "work_package_created";

export default workPackageCreated;
