import { AvvirEvent } from "type_aliases";

export type OnlyDeviationsFromCurrentScanShownEvent = AvvirEvent<typeof ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN, null>

const onlyDeviationsFromCurrentScanShown = (): OnlyDeviationsFromCurrentScanShownEvent => ({
  type: ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN,
  payload: null
});

export const ONLY_DEVIATIONS_FROM_CURRENT_SCAN_SHOWN = "only_show_deviations_from_current_scan";

export default onlyDeviationsFromCurrentScanShown;
