import { AvvirRoutingEvent } from "type_aliases";

export type ToSuperadminNewProjectEvent = AvvirRoutingEvent<typeof TO_SUPERADMIN_NEW_PROJECT, { organizationId: string }>

const toSuperadminNewProject = (organizationId: string): ToSuperadminNewProjectEvent => ({
  type: TO_SUPERADMIN_NEW_PROJECT,
  payload: { organizationId }
});

export const TO_SUPERADMIN_NEW_PROJECT = "to_superadmin_new_project";

export default toSuperadminNewProject;
