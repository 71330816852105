import React, { FunctionComponent, MouseEventHandler, useEffect } from "react";
import moment from "moment";
import { IconButton, LinearProgress, ListItem, ListItemText } from "@mui/material";
import { Close } from "@mui/icons-material";
import { CheckboxMarkedCircleOutline } from "mdi-material-ui";
import useSwitch from "@react-hook/switch";
import { RunningProcessStatus } from "avvir";

import "../../css/running_process_overlay_item.css";

export type Props = {
  title: string
  startTime: Date,
  endTime?: Date,
  status?: RunningProcessStatus,
  onDismiss: MouseEventHandler,
}

const RunningProcessOverlayItem: FunctionComponent<Props> = (props) => {
  let [dismiss, toggleDismiss] = useSwitch(false);
  let running = <>
    <div className="text-and-close-button">
      <div>
        <IconButton onClick={props.onDismiss}><Close/></IconButton>
      </div>
      <ListItemText
        primary={`${props.title}`}
        secondary={`started ${moment(props.startTime).fromNow()}`}
      />
    </div>
    <LinearProgress
      className="RunningProcessOverlayItem-progressBar"
      variant="indeterminate"
    />
  </>;
  let completed = <>
    <ListItemText
      primary={<><CheckboxMarkedCircleOutline htmlColor={"var(--success-green)"}/>{props.title}</>}
      secondary={`started ${moment(props.startTime).fromNow()}`}
    />
  </>;
  let terminated = <>
    <ListItemText
      primary={<><Close htmlColor={"var(--deviation-error-red)"}/>{props.title}</>}
      secondary={<>
        {`started ${moment(props.startTime).fromNow()}`}
        <br/>
        {`${moment(props.startTime).format("ddd, MMM Do YYYY, h:mm:ss A")} - ${moment(props.endTime).format("h:mm:ss A")}`}
      </>}
    />
  </>;
  useEffect(() => {
    const tDelta = new Date().getMilliseconds() - new Date(props.endTime).getMilliseconds();
    let timeout = props.status === RunningProcessStatus.RUNNING ? null : 10000 - tDelta;
    let t;
    if (timeout && timeout > 0) {
      t = setTimeout(toggleDismiss.on, timeout);
    }


    return () => clearTimeout(t);
  }, [toggleDismiss, props.status, props.endTime,]); //ts lint fix. actually wont be of consequence.
  return <ListItem
    style={{ display: dismiss ? "none" : "inherit" }}
    className="RunningProcessOverlayItem"
  >
    {props.status === RunningProcessStatus.COMPLETED ? completed : props.status === RunningProcessStatus.RUNNING ? running : terminated}
  </ListItem>;
};

export default React.memo(RunningProcessOverlayItem);
