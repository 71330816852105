import { AvvirThunk } from "./make_eventful_action";
import mixpanel from "../services/mixpanel_services/init_mixpanel";
import { IS_DEV_ENV, IS_TEST_ENV, isTestServer } from "../services/utilities/current_environment";

export default function setMixPanelUser(user: { email: string, role: string, projectName: string }): AvvirThunk<void, never> {
  return (dispatch, getState) => {
    if (IS_TEST_ENV || IS_DEV_ENV || isTestServer()) {
      mixpanel.opt_out_tracking();
      mixpanel.disable();
      return;
    }

    const uid = mixpanel.get_distinct_id() || "";
    if (user) {
      const { email, role, projectName } = user;
      mixpanel.identify(email);
      mixpanel.people.set({
        "$name": email,
        "$email": email,
        "$last_login": new Date(),
        "Role": role,
        "Project": projectName,
      });
      if (uid.indexOf("@") === -1 && uid.length > 0) {
        mixpanel.alias(mixpanel.get_distinct_id(), uid);
      }
    }
  };
}
