// import { triggerFileDownloadFromResponse } from "../../services/utilities/trigger_file_download";
import apiFailure from "../../events/notifications/failures/api_failure";
import Avvir from "avvir";
import getProjectName from "../../services/getters/project_getters/get_project_name";
import getUser from "../../services/getters/base_getters/get_user";
import buildProjectWbsFileName from "../../services/file_name_helpers/build_project_wbs_file_name";
import { MasterformatFilter } from "../../services/reducers/filter/reduce_filter";
import getProjectId from "../../services/getters/project_getters/get_project_id";
import { triggerFileDownloadFromResponse } from "../../services/utilities/general/trigger_file_download";
import DateConverter from "../../services/converters/date_converter";

const downloadFiltered5dTsv = (filter: MasterformatFilter) => {
  return (dispatch, getState) => {
    const state = getState();
    const projectName = getProjectName(state, {});
    const user = getUser(state, {});
    const projectId = getProjectId(state, {});
    const fileName = buildProjectWbsFileName({
      projectName,
      filenamePrefix: "CAP-5D-Report-",
      fileDate: DateConverter.getTsvFileDate(), fileExtension: "tsv"
      })
    Avvir.api.projects.getFiltered5dTsv({projectId},projectName, fileName, filter, user)
      .then((res) => {
        triggerFileDownloadFromResponse(res, fileName)
        dispatch({ type: "download_filtered_5d_tsv", payload: null });
      })
      .catch(() => dispatch(apiFailure({ message: "Unable to download 5d tsv" })));
  };
};
export default downloadFiltered5dTsv;
