import { ApiInspectionMode, ApiInspectionModes } from "avvir";
import { InspectionMode } from "../../models/domain/enums/inspection_modes";

export default class InspectionModeConverter {
  static toInspectionMode = (mode: ApiInspectionModes): InspectionMode => {
    switch (mode) {
      case ApiInspectionMode.inspectLegacy: // falls-through
      case ApiInspectionMode.inspect: {
        return InspectionMode.inspect;
      }
      case ApiInspectionMode.progress: {
        return InspectionMode.progress;
      }
      case ApiInspectionMode.basicBim: {
        return InspectionMode.basicBim;
      }
      case ApiInspectionMode.bimPrep: {
        return InspectionMode.bimPrep;
      }
    }
  };

  static toApiInspectionMode = (mode: InspectionMode): ApiInspectionModes => {
    switch (mode) {
      case InspectionMode.inspect: {
        return ApiInspectionMode.inspect;
      }
      case InspectionMode.progress: {
        return ApiInspectionMode.progress;
      }
      case InspectionMode.basicBim: {
        return ApiInspectionMode.basicBim;
      }
      case InspectionMode.bimPrep: {
        return ApiInspectionMode.bimPrep;
      }
    }
  };
}
