import { Action } from "redux";

export type ProgressReportPdfCurrentDateEvent = Action<typeof PROGRESS_REPORT_PDF_CURRENT_DATE>

const progressReportPdfCurrentDate = (): ProgressReportPdfCurrentDateEvent => ({
  type: PROGRESS_REPORT_PDF_CURRENT_DATE,
});

export const PROGRESS_REPORT_PDF_CURRENT_DATE = "progress_report_pdf_current_date";

export default progressReportPdfCurrentDate;
