import Avvir, { ApiRunningProcess, isFirebaseUser, isGatewayUser } from "avvir";

import checkFetchStatus from "./check_fetch_status";
import getAuthorizationHeaders, { User } from "./get_authorization_headers";

import type { AssociationIds } from "type_aliases";

/** @deprecated Use equivalent from "avvir" */
export default class WebGatewayApi {
  static baseUrl: string = process.env.REACT_APP_WEB_API_GATEWAY_DOMAIN;

  static addAuthToDownloadUrl(baseUrl: string, user: User): string {
    if (user) {
      if (isGatewayUser(user)) {
        return `${baseUrl}?auth=${user.gatewayUser.idToken}`;
      } else if (isFirebaseUser(user)) {
        return `${baseUrl}?firebaseAuth=${user.firebaseUser.idToken}`;
      }
    } else {
      return baseUrl;
    }
  }

  static saveTradeCostsTsv({ projectId }: AssociationIds, tsvContent: string, user: User) {
    const multipartFormData = new FormData();
    const file = new Blob([tsvContent], { type: "text/tab-separated-values" });
    multipartFormData.append("file", file, "file.tsv");

    const url = `${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown/planned-costs`;
    return (fetch(url, {
      method: "POST",
      headers: {
        ...getAuthorizationHeaders(user)
      },
      body: multipartFormData
    }).then(checkFetchStatus) as Promise<ApiRunningProcess>)
      .catch(error => {
        Avvir.api.config.sharedErrorHandler(error);
      });
  }

  static getTradeCostsTsvUrl({ projectId }: AssociationIds, fileName: string, user: User): string {
    const baseUrl = `${WebGatewayApi.baseUrl}/projects/${projectId}/trade-breakdown/${fileName}_planned-costs.tsv`;
    return WebGatewayApi.addAuthToDownloadUrl(baseUrl, user);
  }
}
