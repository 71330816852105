import { Container } from "pixi.js";
import { ApiView } from "avvir";

import MapLocationContainer from "../layers_shared/map_location_container";
import SharedViewMarker from "./shared_view_marker";
import { PixiMinimapTransforms } from "../pixi_minimap_app";

export default class SharedViewsContainer extends Container {
  private locationsContainer: MapLocationContainer<SharedViewMarker>;
  private transforms: PixiMinimapTransforms;
  private readonly onLocationSelected: (view: SharedViewMarker) => void;

  constructor(transforms: PixiMinimapTransforms, onLocationSelected: (view: SharedViewMarker) => void) {
    super();
    this.locationsContainer = new MapLocationContainer<SharedViewMarker>({
      // onLocationSelected: this.onLocationSelected.bind(this)
    });
    // this.transforms = transforms; //todo refactor, but PixiMinimapApp doesn't exist yet -_-
    this.onLocationSelected = onLocationSelected;
  }

  updateSharedViews(views: ApiView[], transforms: PixiMinimapTransforms) {
    this.transforms = transforms;
    this.locationsContainer.clear();
    this.removeChildren();
    views?.forEach((view) => {
      this.getOrCreateSprite(view);
    });
    this.rescaleSprites(this.transforms.mapScale);
  }

  rescaleSprites(scale) {
    this.locationsContainer.rescale(scale);
  }

  getOrCreateSprite(sharedView: ApiView) {
    let sprite = this.locationsContainer.getById(sharedView.id);
    if (sprite == null) {
      sprite = new SharedViewMarker(sharedView, this.transforms, this.onLocationSelected);

      this.locationsContainer.addLocation(sprite);
      this.addChild(sprite);
    } else {
      // in case something changed about transforms, mainly.
      sprite.updateFromView(sharedView, this.transforms);
    }

    return sprite;
  }
}
