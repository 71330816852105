import { Action } from "redux";

export type IncludedElementsHiddenEvent = Action<typeof INCLUDED_ELEMENTS_HIDDEN>

const includedElementsHidden = (): IncludedElementsHiddenEvent => ({
  type: INCLUDED_ELEMENTS_HIDDEN,
});

export const INCLUDED_ELEMENTS_HIDDEN = "included_elements_hidden";

export default includedElementsHidden;