import { Vector2Like } from "type_aliases";
import VisibilityManager from "./visibility_manager";
import { getAllForgeInstanceTreeIds } from "../utilities/forge_utilities";
import ColorLib from "tinycolor2";
import globalStyles from "../../style/global_styles";
import ForgeCameraControlsExtension from "./extensions/forge_camera_controls_extension";

interface AutodeskNavigation extends Autodesk.Viewing.Navigation {
  setWorldUpVector(upVector: THREE.Vector3, flag: boolean): any;

  orientCameraUp(force?: boolean): void;
}

export interface AutodeskViewer extends Autodesk.Viewing.GuiViewer3D {
  navigation: AutodeskNavigation;
}

export type SelectionChangedEvent = {
  selections: {
    fragIdsArray: number[]
    dbIdArray: number[],
    nodeArray: number[],
    model: Autodesk.Viewing.Model
  }[],
  target: Autodesk.Viewing.GuiViewer3D
};


export default class AutodeskForgeAdaptor {
  private visibleIds: Set<number>;

  constructor(viewer?: AutodeskViewer) {
    this.autodeskViewer = viewer;
    this.visibilityManager = new VisibilityManager(viewer);
    this.visibleIds = new Set();
  }

  flagForRerenderWithMovedObjects() {
    this.autodeskViewer.impl.sceneUpdated(true);
  }

  flagForRerenderWithNoObjectsMoved() {
    this.autodeskViewer.impl.sceneUpdated(false);
  }

  flagOverlayForRerender() {
    this.autodeskViewer.impl.invalidate(true, true, true);
  }

  loadExtension(extensionName, options = {}) {
    return this.autodeskViewer.loadExtension(extensionName, options);
  }

  getVisibleIds(): Set<number> {
    return this.visibleIds;
  }

  isVisible(id: number): boolean {
    return this.getVisibleIds().has(id);
  }

  makeVisible(filteredIds: Set<number>) {
    this.visibleIds = filteredIds;

    const allDbIds = this.getAllDbIds();
    const visibilityManager = this.visibilityManager;
    allDbIds.forEach(dbId => {
      const visible = filteredIds.has(dbId);
      if (visible) {
        visibilityManager.showMesh(dbId, this.getModel());
      } else {
        visibilityManager.hideMesh(dbId, this.getModel());
      }
    });
  }

  getAllDbIds(): number[] {
    let model = this.getModel();
    let instanceTree = model.getInstanceTree();
    return getAllForgeInstanceTreeIds(instanceTree);
  }

  getExtension(extensionName) {
    return this.autodeskViewer.getExtension(extensionName);
  }

  addSelectionChangedEventListener(callback: (event: SelectionChangedEvent) => void) {
    this.autodeskViewer.addEventListener(Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT, callback);
  }

  fitToView(dbIds: number[]) {
    return this.autodeskViewer.fitToView(dbIds, this.getModel());
  }

  waitForLoadDone(include) {
    // @ts-ignore
    return this.autodeskViewer.waitForLoadDone(include);
  }

  setView(position: THREE.Vector3, target: THREE.Vector3) {
    this.autodeskViewer.navigation.setView(position, target);
  }

  getModel() {
    return this.autodeskViewer.model;
  }

  getCamera(): THREE.Camera {
    return this.autodeskViewer && this.autodeskViewer.navigation ? this.autodeskViewer.navigation.getCamera() : null;
  }

  shutdown() {
    this.autodeskViewer.unloadModel(this.getModel());
    this.autodeskViewer.finish();
    Autodesk.Viewing.shutdown();
  }

  resize = () => {
    this.autodeskViewer.resize();
  }

  showCameraHelper() {
    (this.getExtension("Avvir.ForgeCameraControls") as ForgeCameraControlsExtension).tool.cameraControls.showHelper();
  }

  hideCameraHelper() {
    (this.getExtension("Avvir.ForgeCameraControls") as ForgeCameraControlsExtension).tool.cameraControls.hideHelper();
  }

  disableReflectionMap = () => {
    this.autodeskViewer?.impl.matman().setReflectionMap(null);
  };

  setLightPreset = (preset: number) => {
    this.autodeskViewer.setLightPreset(preset);
  };

  autodeskViewer: AutodeskViewer;
  visibilityManager: VisibilityManager;
}


export const initializeViewer = (url: string, floorOffset: Vector2Like, getGcpToken: (projectId: string) => Promise<{ accessToken: string, secondsUntilTokenExpires: number }>, containerElement: HTMLElement, onInitialized, projectId: string) => {
  const options: Autodesk.Viewing.InitializerOptions = {
    env: "AutodeskProduction",
    document: url,
    getAccessToken: (onTokenReady) => {
      getGcpToken(projectId).then((response) => {
        onTokenReady(response.accessToken, response.secondsUntilTokenExpires);
      });
    }
  };
  Autodesk.Viewing.Initializer(options, () => {
    const autodeskViewer = new Autodesk.Viewing.GuiViewer3D(containerElement) as AutodeskViewer;
    const adaptor = new AutodeskForgeAdaptor(autodeskViewer);
    let loadOptions = {
      applyScaling: "m",
      globalOffset: { x: floorOffset?.x || 0, y: floorOffset?.y || 0, z: 0 }
    };
    // The types in @types/forge-viewer and the documentation don't match on this...
    // @ts-ignore
    const startedCode = autodeskViewer.start(url, loadOptions, () => onInitialized(adaptor), null, { useIdBufferSelection: true });
    autodeskViewer.setSelectionColor(new THREE.Color(new ColorLib(globalStyles.selectionBlue).toHexString()), Autodesk.Viewing.SelectionMode.MIXED);
    autodeskViewer.setReverseZoomDirection(true);
    if (startedCode > 0) {
      console.error("Failed to create a Viewer: WebGL not supported.");
    }
  });
};

