import Avvir from "avvir";

import getProjectListings from "../../services/getters/project_getters/get_project_listings";
import projectListingsLoaded, { ProjectListingsLoadedEvent } from "../../events/loaded/projects/project_listings_loaded";
import { ApiFailureEvent } from "../../events/notifications/failures/api_failure";
import { AvvirAsyncThunk } from "../make_eventful_action";

type DispatchedTypes =
  | ApiFailureEvent
  | ProjectListingsLoadedEvent;

const loadProjectListings = (): AvvirAsyncThunk<typeof getProjectListings, DispatchedTypes> => {
  return (dispatch, getState) =>
    Avvir.api.projects.listProjectsForUser(getState().user)
      .then(projectListings => {
        dispatch(projectListingsLoaded(projectListings));
        return getProjectListings(getState(), {});
      });
};

export default loadProjectListings;
