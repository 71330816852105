import _ from "underscore";

const empty = new Set<number>();

function getSetOfDbIds<Element extends { globalId: string }>(elements: Element[] | { [globalId: string]: Element }): Set<number> {
  if ((Array.isArray(elements) && elements.length) || Object.keys(elements).length) {
    return new Set(_(elements).pluck("globalId").map(getDbId));
  } else {
    return empty;
  }
}

export function getDbId(globalId: string) {
  if (globalId) {
    return parseInt(globalId.substr("Obj".length), 10);
  } else {
    return null;
  }
}

export default getSetOfDbIds;
