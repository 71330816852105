import { Reducer } from "redux";

import makeUpdateModel, { NormalizedDatabaseStore } from "./make_update_model";
import { PHOTO_LOCATIONS_LOADED, PhotoLocationsLoadedEvent } from "../../../events/loaded/photos/photo_locations_loaded";
import { BIM_AND_PHOTO_CAMERA_LINKED, BimAndPhotoCameraLinkedEvent } from "../../../events/viewer/bim_and_photo_camera_linked";
import { Quaternion, Vector3 } from "three";
import { PHOTO_LOCATIONS_CREATED, PhotoLocationsCreatedEvent } from "../../../events/photos/photo_locations_created";
import { PHOTO_LOCATION_SELECTED, PhotoLocationSelectedEvent } from "../../../events/selection/photos/photo_location_selected";
import { PHOTO_LOCATIONS_MOVED, PhotoLocationMovedEvent } from "../../../events/viewer/photo_locations_moved";
import { ApiPhotoLocation, DateConverter } from "avvir";
import PhotoLocation from "../../../models/domain/photos/photo_location";
import { PHOTO_LOCATIONS_DELETED, PhotoLocationsDeletedEvent } from "../../../events/viewer/photo_locations_deleted";

type PhotoLocationsEvents =
  | PhotoLocationsLoadedEvent
  | PhotoLocationsCreatedEvent
  | BimAndPhotoCameraLinkedEvent
  | PhotoLocationMovedEvent
  | PhotoLocationSelectedEvent
  | PhotoLocationsDeletedEvent;

export type PhotoLocationsStore = NormalizedDatabaseStore<PhotoLocation>

const updatePhotoLocation = makeUpdateModel<PhotoLocation>(new PhotoLocation());

function photoLocationMoved(
  photoLocations: PhotoLocationsStore,
  location: ApiPhotoLocation,
  isControlPoint: boolean
) {
  const bimLocation = location.bimLocation;
  const orientation = bimLocation.orientation;
  const locationId = location.id;
  const pos = bimLocation.position;

  return updatePhotoLocation(
    photoLocations,
    locationId,
    (photoLocation) => {
      return new PhotoLocation({
        ...photoLocation,
        updatedAt: DateConverter.dateToInstant(new Date()),
        isControlPoint,
        bimLocation: {
          id: bimLocation.id,
          position: new Vector3(pos.x, pos.y, pos.z),
          orientation: new Quaternion(orientation.a, orientation.b, orientation.c, orientation.d)
        }
      });
    }
  );
}

const reducePhotoLocations: Reducer<PhotoLocationsStore, PhotoLocationsEvents> = (photoLocations: PhotoLocationsStore = { byId: {} }, event) => {
  switch (event?.type) {
    case PHOTO_LOCATIONS_DELETED:
    case PHOTO_LOCATIONS_LOADED: {
      return event.payload.reduce((photoLocationsSoFar, photoLocation) => {
        return updatePhotoLocation(photoLocationsSoFar, photoLocation.id, { ...PhotoLocation.fromApi(photoLocation) });
      }, photoLocations);
    }
    case PHOTO_LOCATIONS_CREATED: {
      return event.payload.photoLocations.reduce((photoLocationsSoFar, photoLocation) => {
        return updatePhotoLocation(photoLocationsSoFar, photoLocation.id, { ...PhotoLocation.fromApi(photoLocation) });
      }, photoLocations);
    }
    case PHOTO_LOCATION_SELECTED: {
      return updatePhotoLocation(photoLocations, event.payload.photoLocationId, { visited: true });
    }
    case PHOTO_LOCATIONS_MOVED: {
      if (event.payload && event.payload.locations) {
        return event.payload.locations.reduce((locationsSoFar, location) => {
          return photoLocationMoved(locationsSoFar, location.updatedLocation, location.isControlPoint);
        }, photoLocations);
      } else {
        return photoLocations;
      }
    }
    case BIM_AND_PHOTO_CAMERA_LINKED: {
      if (event.payload) {
        return photoLocationMoved(photoLocations, event.payload.updatedLocation, true);
      } else {
        return photoLocations;
      }
    }
    default: {
      return photoLocations;
    }

  }
};

export default reducePhotoLocations;

