import { ApiCommentThread } from "avvir";
import { AvvirEvent } from "type_aliases";

export type CommentThreadsLoadedEvent = AvvirEvent<typeof COMMENT_THREADS_LOADED, { projectId: string, commentThreads: ApiCommentThread[]}>;

const commentThreadsLoaded = (projectId: string, commentThreads: ApiCommentThread[]): CommentThreadsLoadedEvent => ({
  type: COMMENT_THREADS_LOADED,
  payload: {
    projectId,
    commentThreads
  }
});

export const COMMENT_THREADS_LOADED = "comment_threads_loaded";

export default commentThreadsLoaded;
