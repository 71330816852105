enum PageNames {
  ACCEPT_INVITATION = "ACCEPT_INVITATION",
  BIMTRACK_AUTHENTICATED = "BIMTRACK_AUTHENTICATED",
  CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT",
  INTEGRATION = "INTEGRATION",
  INTEGRATIONS = "INTEGRATIONS",
  PHOTO="PHOTO",
  LOGIN = "LOGIN",
  NOT_FOUND = "NOT_FOUND",
  ORGANIZATION = "ORGANIZATION",
  ORGANIZATION_LIST = "ORGANIZATION_LIST",
  PHOTOS = "PHOTOS",
  PROCORE_AUTHENTICATED = "PROCORE_AUTHENTICATED",
  PROGRESS_REPORT = "PROGRESS_REPORT",
  PROJECT = "PROJECT",
  PROJECT_PORTFOLIO = "PROJECT_PORTFOLIO",
  PROJECT_REPORTS = "PROJECT_REPORTS",
  PROJECT_SETTINGS = "PROJECT_SETTINGS",
  PROJECT_SUMMARY = "PROJECT_SUMMARY",
  PROJECT_TEAM = "PROJECT_TEAM",
  PROJECT_UPLOADS = "PROJECT_UPLOADS",
  PROJECT_VIEWER = "PROJECT_VIEWER",
  QUALITY_CONTROL_REPORT = "QUALITY_CONTROL_REPORT",
  RESET_PASSWORD= "RESET_PASSWORD",
  RESET_PASSWORD_CONFIRMATION_PAGE = "RESET_PASSWORD_CONFIRMATION_PAGE",
  RESET_PASSWORD_EMAIL_FORM = "RESET_PASSWORD_EMAIL_FORM",
  SEARCH_SET_CREATOR = "SEARCH_SET_CREATOR",
  SUPERADMIN_ORGANIZATION_USERS = "SUPERADMIN_ORGANIZATION_USERS",
  SUPERADMIN_SCANS_TABLE = "SUPERADMIN_SCANS_TABLE",
  SUPERADMIN_USER = "SUPERADMIN_USER",
  BUDGET = "WORK_BREAKDOWN_STRUCTURE",
  HX_AUTH_LOGIN = "HX_AUTH_LOGIN",
}

export const ACCEPT_INVITATION = PageNames.ACCEPT_INVITATION;
export const BIMTRACK_AUTHENTICATED = PageNames.BIMTRACK_AUTHENTICATED;
export const CONFIRM_ACCOUNT = PageNames.CONFIRM_ACCOUNT;
export const INTEGRATION = PageNames.INTEGRATION;
export const PHOTO = PageNames.PHOTO;
export const INTEGRATIONS = PageNames.INTEGRATIONS;
export const LOGIN = PageNames.LOGIN;
export const NOT_FOUND = PageNames.NOT_FOUND;
export const ORGANIZATION = PageNames.ORGANIZATION;
export const ORGANIZATION_LIST = PageNames.ORGANIZATION_LIST;
export const PHOTOS = PageNames.PHOTOS;
export const PROCORE_AUTHENTICATED = PageNames.PROCORE_AUTHENTICATED;
export const PROGRESS_REPORT = PageNames.PROGRESS_REPORT;
export const PROJECT = PageNames.PROJECT;
export const PROJECT_PORTFOLIO = PageNames.PROJECT_PORTFOLIO;
export const PROJECT_REPORTS = PageNames.PROJECT_REPORTS;
export const PROJECT_SETTINGS = PageNames.PROJECT_SETTINGS
export const PROJECT_SUMMARY = PageNames.PROJECT_SUMMARY;
export const PROJECT_TEAM = PageNames.PROJECT_TEAM;
export const PROJECT_UPLOADS = PageNames.PROJECT_UPLOADS;
export const PROJECT_VIEWER = PageNames.PROJECT_VIEWER;
export const QUALITY_CONTROL_REPORT = PageNames.QUALITY_CONTROL_REPORT;
export const RESET_PASSWORD = PageNames.RESET_PASSWORD;
export const RESET_PASSWORD_CONFIRMATION_PAGE = PageNames.RESET_PASSWORD_CONFIRMATION_PAGE;
export const RESET_PASSWORD_EMAIL_FORM = PageNames.RESET_PASSWORD_EMAIL_FORM;
export const SEARCH_SET_CREATOR = PageNames.SEARCH_SET_CREATOR;
export const SUPERADMIN_ORGANIZATION_USERS = PageNames.SUPERADMIN_ORGANIZATION_USERS;
export const SUPERADMIN_SCANS_TABLE = PageNames.SUPERADMIN_SCANS_TABLE;
export const SUPERADMIN_USER = PageNames.SUPERADMIN_USER;
export const BUDGET = PageNames.BUDGET;
export const HX_AUTH_LOGIN = PageNames.HX_AUTH_LOGIN;
export default PageNames;
