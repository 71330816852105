import { ApiFloor } from "avvir";

import { AvvirEvent } from "type_aliases";


export type FloorLoadedEvent = AvvirEvent<typeof FLOOR_LOADED, { floor: ApiFloor }>

const floorLoaded = (floor: ApiFloor): FloorLoadedEvent => ({
  type: FLOOR_LOADED,
  payload: {
    floor
  }
});

export const FLOOR_LOADED = "floor_loaded";

export default floorLoaded;
