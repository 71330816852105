import { datadogLogs } from "@datadog/browser-logs";
import Avvir, { HxAuthUser, isHxAuthUser } from "avvir";

import getUser from "../../../services/getters/base_getters/get_user";
import getUserAccount from "../../../services/getters/user_getters/get_user_account";
import signOutUser from "../../sign_in_page/sign_out_user";
import toHxAuthLoginPage from "../../../events/routing/to_hx_auth_login_page";
import userAccountLoaded, { UserAccountLoadedEvent } from "../../../events/auth_events/user_account_loaded";
import { ApiFailureEvent } from "../../../events/notifications/failures/api_failure";
import { AvvirAsyncThunk } from "../../make_eventful_action";
import { decodeAvvirGatewayToken } from "../../../services/firebase_services/firebase_tokens";

function loadUserAccount(): AvvirAsyncThunk<typeof getUserAccount, UserAccountLoadedEvent | ApiFailureEvent> {
  return (dispatch, getState) => {
    const user = getUser(getState(), {});
    return Avvir.api.user.getUserAccount(user)
      .then((account) => {
        datadogLogs.addLoggerGlobalContext("user_id", account.userId);
        dispatch(userAccountLoaded(account));
        return getUserAccount(getState(), {});
      })
      .catch(() => {
        if (isHxAuthUser(user)) {
          const accessToken = (user as HxAuthUser).hxAuthUser.accessToken;
          const jwtContents = decodeAvvirGatewayToken(accessToken);
          return signOutUser(toHxAuthLoginPage({
            error: "no-matching-user",
            uuid: jwtContents.sub,
          }))(dispatch, getState).then(() => null);
        } else {
          return signOutUser()(dispatch, getState).then(() => null);
        }
      });
  };
}

export default loadUserAccount;
