import { immerable } from "immer";
import { ApiComment, ApiUser } from "avvir";

import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";

export default class CommentThread {
  constructor({id, author, viewId, comments, projectId, floorId, scanDatasetId, wbsCode, title}: Partial<CommentThread> = {}) {
    addReadOnlyPropertiesToModel(this, {
      id, projectId, floorId, scanDatasetId, viewId
    })
    this.author = author;
    this.comments = comments;
    this.wbsCode = wbsCode;
    this.title = title;
  }

  id: number;
  author: ApiUser;
  viewId: number;
  comments: ApiComment[];
  projectId: string;
  floorId?: string;
  scanDatasetId?: string;
  wbsCode?: string;
  title?: string;

  static readonly [immerable] = true;
}
