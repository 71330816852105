import toViewerScanDataset from "../../events/routing/to_viewer_scan_dataset";
import toViewerFloor from "../../events/routing/to_viewer_floor";
import toProject from "../../events/routing/to_project";

export default function makeToViewerEvent(projectId: string, floorId: string, scanDatasetId: string) {
  if (floorId) {
    if (scanDatasetId) {
      return toViewerScanDataset(projectId, floorId, scanDatasetId);
    } else {
      return toViewerFloor(projectId, floorId);
    }
  } else {
    return toProject(projectId);
  }
}
