import { Reducer } from "redux";
import _ from "underscore";

import InspectReport from "../../../models/domain/inspect_report";
import makeUpdateModel from "./make_update_model";
import { ByDatabaseId } from "type_aliases";
import { INSPECT_REPORT_CREATED, InspectReportCreatedEvent } from "../../../events/loaded/projects/inspect_report_created";
import { INSPECT_REPORT_DELETED, InspectReportDeletedEvent } from "../../../events/loaded/projects/inspect_report_deleted";
import { INSPECT_REPORT_ENTRIES_CREATED, InspectReportEntriesCreatedEvent } from "../../../events/loaded/projects/inspect_report_entries_created";
import { INSPECT_REPORT_ENTRIES_LOADED, InspectReportEntriesLoadedEvent } from "../../../events/loaded/projects/inspect_report_entries_loaded";
import { INSPECT_REPORT_ENTRY_DELETED, InspectReportEntryDeletedEvent } from "../../../events/loaded/projects/inspect_report_entry_deleted";
import { INSPECT_REPORT_ENTRY_UPDATED, InspectReportEntryUpdatedEvent } from "../../../events/loaded/projects/inspect_report_entry_updated";
import { INSPECT_REPORT_UPDATED, InspectReportUpdatedEvent } from "../../../events/loaded/projects/inspect_report_updated";
import { INSPECT_REPORTS_LOADED, InspectReportsLoadedEvent } from "../../../events/loaded/projects/inspect_reports_loaded";

type InspectReportsEvents =
  | InspectReportsLoadedEvent
  | InspectReportCreatedEvent
  | InspectReportEntriesLoadedEvent
  | InspectReportEntriesCreatedEvent
  | InspectReportEntryUpdatedEvent
  | InspectReportEntryDeletedEvent
  | InspectReportDeletedEvent
  | InspectReportUpdatedEvent;


export type InspectReportsStore = { byId: ByDatabaseId<InspectReport> };
const emptyInspectReport = new InspectReport({});
const updateInspectReport = makeUpdateModel<InspectReport>(emptyInspectReport);

const reduceInspectReports: Reducer<InspectReportsStore, InspectReportsEvents> = (inspectReports: InspectReportsStore = { byId: {} }, event: InspectReportsEvents) => {
  switch (event?.type) {
    case INSPECT_REPORTS_LOADED: {
      return event.payload.inspectReports.reduce((inspectReportsSoFar, inspectReport) => {
        return updateInspectReport(inspectReportsSoFar, inspectReport.id, new InspectReport({
          ...inspectReport,
          firebaseProjectId: event.payload.projectId
        }));
      }, inspectReports);
    }
    case INSPECT_REPORT_CREATED: {
      const inspectReport = event.payload.inspectReport;
      return updateInspectReport(inspectReports, inspectReport.id, new InspectReport({
        ...inspectReport,
        firebaseProjectId: event.payload.projectId
      }));
    }
    case INSPECT_REPORT_ENTRIES_LOADED: {
      const inspectReportEntries = event.payload.inspectReportEntries;
      return updateInspectReport(inspectReports, event.payload.inspectReportId, (inspectReport) => {
        return {
          ...inspectReport,
          entries: inspectReportEntries
        };
      });
    }
    case INSPECT_REPORT_ENTRIES_CREATED: {
      return updateInspectReport(inspectReports, event.payload.inspectReportId, (inspectReport) => {
        const entries = (inspectReport.hasOwnProperty("entries") && inspectReport.entries != null) ? [...inspectReport.entries, ...event.payload.inspectReportEntries] : [...event.payload.inspectReportEntries];
        return {
          ...inspectReport,
          entries
        };
      });
    }
    case INSPECT_REPORT_ENTRY_UPDATED: {
      return updateInspectReport(inspectReports, event.payload.inspectReportId, (inspectReport) => {
        const updatedEntries = inspectReport.entries.map(entry => entry.id !== event.payload.inspectReportEntryId ? entry : event.payload.inspectReportEntry);
        return {
          ...inspectReport,
          entries: updatedEntries
        };
      });
    }
    case INSPECT_REPORT_ENTRY_DELETED: {
      const inspectReport = _.find(inspectReports.byId, r => r.id === event.payload.inspectReportId);
      if (inspectReport) {
        const entries = inspectReport?.entries || [];
        return updateInspectReport(inspectReports, event.payload.inspectReportId, {
          ...inspectReport,
          entries: entries.filter(e => e.id !== event.payload.inspectReportEntryId)
        });
      }
      return inspectReports;
    }
    case INSPECT_REPORT_DELETED: {
      return {
        ...inspectReports,
        byId: _.omit(inspectReports.byId, event.payload.inspectReportId.toString())
      };
    }
    case INSPECT_REPORT_UPDATED: {
      let entries = _.find(inspectReports.byId, (report) => report.id === event.payload.inspectReport.id)?.entries || [];
      return updateInspectReport(inspectReports, event.payload.inspectReport.id, new InspectReport({
        ...event.payload.inspectReport,
        entries
      }));
    }
    default: {
      return inspectReports;
    }
  }
};

export default reduceInspectReports;
