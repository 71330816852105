import { AbstractRenderer, Texture } from "pixi.js";
import { PixiMinimapApp } from "../pixi_minimap_app";
import { MapMarkerStyle, MapMarkerTexture } from "../layers_shared/map_marker_texture";

export class PhotoSpriteStyles {
  public defaultTexture: Texture;
  public selectedTexture: Texture;
  public handleTexture: Texture;
  public visitedTexture: Texture;
  public adjustTexture: Texture;
  public movedInAdjustModeTexture: Texture;
  public startTexture: Texture;
  public startVisitedTexture: Texture;
  public startAdjustingTexture: Texture;
  public startMovedInAdjustModeTexture: Texture;
  public startSelectedTexture: Texture;
  private _renderer: AbstractRenderer;
  private _showOrientation: boolean = false;

  constructor(debugOrientation) {
    this._showOrientation = debugOrientation;
  }
  public defaultStyle: MapMarkerStyle = {
    fillAlpha: 0.8,
    fillColor: 0x71d175,
    lineColor: 0x000000
  };

  public visitedStyle: MapMarkerStyle = {
    fillAlpha: 0.8,
    fillColor: 0x2d5a3c,
    lineColor: 0xffffff
  };

  public fenceStyle: MapMarkerStyle = {
    fillAlpha: 0.2,
    fillColor: 0x5c8edc,
    lineColor: 0x5c8edc
  };

  public selectedStyle: MapMarkerStyle = {
    fillAlpha: 0.8,
    fillColor: 0x5c8edc,
    lineColor: 0x5c8edc
  };

  public adjustStyle: MapMarkerStyle = {
    fillAlpha: 1,
    fillColor: 0x71D175,
    lineColor: 0x000000
  };

  public movedInAdjustModeStyle: MapMarkerStyle = {
    fillAlpha: 1,
    fillColor: 0x63531A,
    lineColor: 0xffffff
  };

  init(app: PixiMinimapApp) {
    this._renderer = app.renderer;
    this.renderMarkersToTextures(app.renderer);
  }

  get showOrientation() {
    return this._showOrientation;
  }

  set showOrientation(value: boolean) {
    if (this._showOrientation !== value) {
      this._showOrientation = value;
      if (this._renderer) {
        this.renderMarkersToTextures(this._renderer);
      }
    }
  }

  async renderMarkersToTextures(renderer: AbstractRenderer) {
    this.defaultTexture = (await MapMarkerTexture.create(renderer, this.defaultStyle, this.showOrientation)).texture;
    this.selectedTexture = (await MapMarkerTexture.create(renderer, this.selectedStyle, this.showOrientation)).texture;
    this.visitedTexture = (await MapMarkerTexture.create(renderer, this.visitedStyle, this.showOrientation)).texture;
    this.adjustTexture = (await MapMarkerTexture.create(renderer, this.adjustStyle, this.showOrientation)).texture;
    this.handleTexture = (await MapMarkerTexture.create(renderer, this.fenceStyle, false)).texture;
    this.movedInAdjustModeTexture = (await MapMarkerTexture.create(renderer, this.movedInAdjustModeStyle, this.showOrientation)).texture;
    this.startTexture = (await MapMarkerTexture.create(renderer, this.defaultStyle, false, "star")).texture;
    this.startVisitedTexture = (await MapMarkerTexture.create(renderer, this.visitedStyle, false, "star")).texture;
    this.startAdjustingTexture = (await MapMarkerTexture.create(renderer, this.adjustStyle, false, "star")).texture;
    this.startMovedInAdjustModeTexture = (await MapMarkerTexture.create(renderer, this.movedInAdjustModeStyle, false, "star")).texture;
    this.startSelectedTexture = (await MapMarkerTexture.create(renderer, this.selectedStyle, false, "star")).texture;
  }

}
