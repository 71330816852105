import { AvvirEvent } from "type_aliases";
import { ApiRecipe } from "avvir";

export type ProjectRecipeCreatedEvent = AvvirEvent<typeof PROJECT_RECIPE_CREATED, { recipe: ApiRecipe }>

const projectRecipeCreated = ( recipe: ApiRecipe ): ProjectRecipeCreatedEvent => ({
  type: PROJECT_RECIPE_CREATED,
  payload: {
    recipe
  }
});

export const PROJECT_RECIPE_CREATED = "project_recipe_created";

export default projectRecipeCreated;
