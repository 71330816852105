import { IJsonModel, IJsonRowNode, IJsonTabSetNode } from "flexlayout-react";
import PanelType, { getPanelNodesByPanelType } from "../models/domain/enums/panel_type";
import { PanelFlags } from "../models/domain/enums/panel_flag";

export function getPanelTypesForProjectViewer(flags: PanelFlags) {
  if (flags.photosOnly) {
    return [PanelType.PHOTO_VIEWER, PanelType.MINIMAP];
  } else {
    return getEnabledPanelTypesForProjectViewer(flags);
  }
}

export function getEnabledPanelTypesForProjectViewer(flags: PanelFlags) {
  const types = [
    PanelType.COMMENTS,
    PanelType.ELEMENT_INSPECTOR,
    PanelType.INSPECT_PANEL,
    PanelType.MINIMAP,
    PanelType.VIEWER,
    PanelType.PHOTO_VIEWER
  ];

  if (flags.groupsPanel) {
    types.push(PanelType.ELEMENT_GROUPS);
  }

  if (flags.actionHistoryPanel) {
    types.push(PanelType.ACTION_HISTORY);
  }

  return types;
}

export default function getPanelsLayout(flags: PanelFlags, tabEnableClose: boolean = true): IJsonModel {
  if (flags.photosOnly) {
    return {
      global: { tabEnableClose },
      borders: [],
      layout: {
        type: "row",
        weight: 100,
        children: [
          {
            type: "tabset",
            weight: 50,
            children: [getPanelNodesByPanelType(PanelType.PHOTO_VIEWER)],
            tabLocation: "top"
          },
          {
            type: "tabset",
            weight: 50,
            children: [getPanelNodesByPanelType(PanelType.MINIMAP)],
            tabLocation: "top"
          }
        ]
      }
    };
  }

  let additionalNodes = [];
  if (flags.groupsPanel) {
    additionalNodes.push(getPanelNodesByPanelType(PanelType.ELEMENT_GROUPS));
  }

  let leftPanels: IJsonRowNode | IJsonTabSetNode = {
    type: "tabset",
    weight: 30,
    children: [getPanelNodesByPanelType(PanelType.INSPECT_PANEL), ...additionalNodes],
    tabLocation: "top"
  };

  return {
    global: { tabEnableClose },
    borders: [],
    layout: {
      type: "row",
      weight: 100,
      children: [
        leftPanels,
        {
          type: "tabset",
          weight: 70,
          children: [getPanelNodesByPanelType(PanelType.VIEWER)],
        },
        {
          type: "row",
          weight: 30,
          children: [
            {
              type: "tabset",
              weight: 40,
              children: [getPanelNodesByPanelType(PanelType.ELEMENT_INSPECTOR), getPanelNodesByPanelType(PanelType.COMMENTS)],
              tabLocation: "top"
            },
            {
              type: "tabset",
              weight: 50,
              children: [getPanelNodesByPanelType(PanelType.MINIMAP), ...((flags.actionHistoryPanel && [getPanelNodesByPanelType(PanelType.ACTION_HISTORY)]) || [])],
              tabLocation: "top"
            }
          ]
        }
      ]
    }
  };
}
