import firebase from "../../services/firebase_services/firebase";
import NavigationService from "../../services/utilities/navigation_service";
import userSignedOut, { UserSignedOutEvent } from "../../events/auth_events/user_signed_out";
import { AvvirThunk } from "../make_eventful_action";
import RoutingEvent from "../../services/routing_events";

const signOutUser = (redirect: RoutingEvent = null): AvvirThunk<Promise<void>, UserSignedOutEvent> => {
  return (dispatch) => {
    window.localStorage.removeItem("gatewayJwt");
    window.localStorage.removeItem("hxauth");
    return firebase.auth().signOut().then(() => {
      dispatch(userSignedOut());
      if (redirect === null) {
        NavigationService.reload();
      } else {
        dispatch(redirect);
      }
    });
  };
};

export default signOutUser;
