import { Action } from "redux";

export type UserSignedOutEvent = Action<typeof USER_SIGNED_OUT>

const userSignedOut = (): UserSignedOutEvent => ({
  type: USER_SIGNED_OUT
});

export const USER_SIGNED_OUT = "user_signed_out";

export default userSignedOut;
