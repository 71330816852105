import { createSelector } from "reselect";

import getDefaultScanDatasetId from "../floor_getters/get_default_scan_dataset_id";
import getFloorsByFirebaseId from "../floor_getters/get_floors_by_firebase_id";
import getProps from "../base_getters/get_props";
import getScanDatasetIdFromLocation from "../location_metadata/get_scan_dataset_id_from_location";
import type { GetPropsType } from "type_aliases";

type Selectors = [
  typeof getScanDatasetIdFromLocation,
  typeof getDefaultScanDatasetId,
  typeof getFloorsByFirebaseId,
  GetPropsType<{ scanDatasetId: string }>
]

const getScanDatasetId = createSelector<Selectors, string>([
  getScanDatasetIdFromLocation,
  getDefaultScanDatasetId,
  getFloorsByFirebaseId,
  getProps
], (locationScanDatasetId, defaultScanDatasetId, floors, props) => {
  if (props.scanDatasetId) {
    return props.scanDatasetId;
  } else if (locationScanDatasetId) {
    return locationScanDatasetId;
  } else if (defaultScanDatasetId) {
    return defaultScanDatasetId;
  } else {
    return null;
  }
});

export default getScanDatasetId;
