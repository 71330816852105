import { Action } from "redux";

export type DetectedDeviationsShownEvent = Action<typeof DETECTED_DEVIATIONS_SHOWN>

const detectedDeviationsShown = (): DetectedDeviationsShownEvent => ({
  type: DETECTED_DEVIATIONS_SHOWN,
});

export const DETECTED_DEVIATIONS_SHOWN = "detected_deviations_shown";

export default detectedDeviationsShown;