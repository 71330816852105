import { createSelector } from "reselect";

import getScanDate from "../../scan_dataset_getters/get_scan_date";
import getSelectedDeviations from "./get_selected_deviations";
import PlannedBuildingElement from "../../../../models/domain/planned_building_element";
import { DISMISSED, INCLUDED } from "../../../../models/domain/enums/deviation_status";

const getSelectedUnIncludedElements = createSelector([
  getScanDate,
  getSelectedDeviations
], (scanDate, selectedDeviations) => {
  return selectedDeviations.filter((el) => {
    const deviation = PlannedBuildingElement.getDeviation(el, scanDate);
    return deviation.deviationVectorMeters != null && !([DISMISSED, INCLUDED].includes(deviation.status));
  });
});

export default getSelectedUnIncludedElements;
