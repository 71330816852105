import ProjectReportVersion from "./project_report_version";

export default class ProjectReport {
  id: number;
  name: string;
  url: string;
  subcontractorId: number;
  reportVersions: ProjectReportVersion[];
  type: string;

  constructor({ id, name, url, subcontractorId, reportVersions, type }: Partial<ProjectReport> = {}) {
    this.id = id;
    this.name = name;
    this.url = url;
    this.subcontractorId = subcontractorId;
    this.reportVersions = (reportVersions || []).map((version) => {
      let reportVersion = new ProjectReportVersion(version);
      reportVersion.projectReportId = id;
      return reportVersion;
    });
    this.type = type;
  }
}
