import toSuperadminScanDataset from "../../events/routing/superadmin/to_superadmin_scan_datasets";
import toSuperadminFloor from "../../events/routing/superadmin/to_superadmin_floor";
import toSuperadminProject from "../../events/routing/superadmin/to_superadmin_project";
import toSuperadminOrganization from "../../events/routing/superadmin/to_superadmin_organization";
import toSuperadminOrganizations from "../../events/routing/superadmin/to_superadmin_organizations";

export default function makeToSuperadminEvent(organizationId: string, projectId: string, floorId: string, scanDatasetId: string) {
  if (organizationId && projectId && floorId && scanDatasetId) {
    return toSuperadminScanDataset(organizationId, projectId, floorId, scanDatasetId);
  } else if (organizationId && projectId && floorId) {
    return toSuperadminFloor(organizationId, projectId, floorId);
  } else if (projectId && organizationId) {
    return toSuperadminProject(organizationId, projectId);
  } else if (organizationId) {
    return toSuperadminOrganization(organizationId);
  } else {
    return toSuperadminOrganizations();
  }
}
