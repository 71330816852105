import { ApiCloudFile } from "avvir";

import { AvvirEvent } from "type_aliases";

export type FloorFileUploadedEvent = AvvirEvent<typeof FLOOR_FILE_UPLOADED, {
  floorId: string,
  file: ApiCloudFile,
}>

const floorFileUploaded = (floorId: string, file: ApiCloudFile): FloorFileUploadedEvent => ({
  type: FLOOR_FILE_UPLOADED,
  payload: {
    floorId,
    file,
  }
});

export const FLOOR_FILE_UPLOADED = "floor_file_uploaded";

export default floorFileUploaded;
