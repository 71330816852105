import { AvvirEvent } from "type_aliases";

export type SelectedProjectAreaChangedEvent = AvvirEvent<typeof PROJECT_AREA_SELECTION_CHANGED, { firebaseProjectId: string, dbId: number }>

const selectedProjectAreaChanged = (firebaseProjectId: string, dbId: number): SelectedProjectAreaChangedEvent => ({
  type: PROJECT_AREA_SELECTION_CHANGED,
  payload: {
    firebaseProjectId,
    dbId
  }
});

export const PROJECT_AREA_SELECTION_CHANGED = "project_area_selection_changed";

export default selectedProjectAreaChanged;
