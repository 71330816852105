import type ApiTrade from "../../../models/api/api_trade";
import type { AvvirEvent } from "type_aliases";

export type TradesLoadedEvent = AvvirEvent<typeof TRADES_LOADED, { projectId: string, trades: ApiTrade[] }>

const tradesLoaded = (projectId: string, trades: ApiTrade[]): TradesLoadedEvent => ({
  type: TRADES_LOADED,
  payload: {
    projectId,
    trades
  }
});

export const TRADES_LOADED = "trades_loaded";

export default tradesLoaded;
