import { createSelector } from "reselect";

import getScanDatasetId from "./get_scan_dataset_id";
import getScanDatasetsByFirebaseId from "./get_scan_datasets_by_firebase_id";

const getScanDataset = createSelector([
  getScanDatasetsByFirebaseId,
  getScanDatasetId
], (scanDatasetsByFirebaseId, scanDatasetId) => scanDatasetsByFirebaseId[scanDatasetId] || null);

export default getScanDataset;
