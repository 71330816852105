import { AvvirEvent } from "type_aliases";
import { ApiUser } from "avvir";

export type UserAccountUpdatedEvent = AvvirEvent<typeof USER_ACCOUNT_UPDATED, {user: ApiUser}>

const userAccountUpdated = (user: ApiUser): UserAccountUpdatedEvent => ({
  type: USER_ACCOUNT_UPDATED,
  payload: {
    user
  }
});

export const USER_ACCOUNT_UPDATED = "user_account_updated";

export default userAccountUpdated;
