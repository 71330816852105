import { immerable } from "immer";
import { ApiProjectAreaWorkPackage } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import addReadOnlyPropertiesToModel from "../../../services/utilities/mixins/add_read_only_properties_to_model";
import DateConverter from "../../../services/converters/date_converter";

import type WorkPackageBuiltStatus from "../enums/work_package_built_status";
import type { DateLike, ModifyPartial } from "type_aliases";

export type ProjectAreaWorkPackageArgument = ModifyPartial<ApiProjectAreaWorkPackage, {
  start: DateLike
  expectedStart: DateLike
  completion: DateLike
  expectedCompletion: DateLike
}>

export default class ProjectAreaWorkPackage {
  constructor({ id, projectAreaId, projectWorkPackageId, workPackageId, status, start, expectedStart, completion, expectedCompletion }: ProjectAreaWorkPackageArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id, projectAreaId, projectWorkPackageId, workPackageId });
    addDateGetterAndSetterToDomainModel(this, "start", start);
    addDateGetterAndSetterToDomainModel(this, "expectedStart", expectedStart);
    addDateGetterAndSetterToDomainModel(this, "completion", completion);
    addDateGetterAndSetterToDomainModel(this, "expectedCompletion", expectedCompletion);

    this.status = status as WorkPackageBuiltStatus;
  }

  readonly id: number;
  readonly projectWorkPackageId: number;
  readonly workPackageId: number;
  readonly projectAreaId: number;
  status: WorkPackageBuiltStatus;
  start: Date;
  expectedStart: Date;
  completion: Date;
  expectedCompletion: Date;

  static toApi(projectAreaWorkPackage: ProjectAreaWorkPackage): ApiProjectAreaWorkPackage {
    const start = DateConverter.dateToLocalDate(projectAreaWorkPackage.start);
    const completion = DateConverter.dateToLocalDate(projectAreaWorkPackage.completion);
    const expectedStart = DateConverter.dateToLocalDate(projectAreaWorkPackage.expectedStart);
    const expectedCompletion = DateConverter.dateToLocalDate(projectAreaWorkPackage.expectedCompletion);
    return new ApiProjectAreaWorkPackage({
      ...projectAreaWorkPackage,
      start,
      expectedStart,
      completion,
      expectedCompletion
    });
  }

  private static readonly [immerable] = true;
}
