import { Container, Graphics, IPointData, Point, Rectangle } from "pixi.js";
import { PhotoSprite } from "./photo_sprite";
import { PhotoFenceSelection, PhotoFenceSelector } from "./adjust_points/photo_fence_selector";
import { PhotoSpriteStyles } from "./photo_sprite_styles";
import { Vector2 } from "three";
import { PhotoRotationHandle } from "./adjust_points/photo_rotation_handle";
import { PixiScalable } from "../../../../../services/utilities/pixi/pixi_scale";

export interface RotationEventListener {
  onRotationStart()
  onRotationEnd()
}

export class PhotoSelectionRectangle extends Container implements RotationEventListener, PixiScalable {
  private readonly _rectGraphics: Graphics;
  private readonly _rotationHandle: PhotoRotationHandle;
  private _rotateStartAngle: number = 0;
  private _dragStartPosition: Point;
  private _selectedBounds: Rectangle = new Rectangle();
  private rotationEventListener: RotationEventListener;
  private _styles: PhotoSpriteStyles;
  private _currentSelectedPhoto: PhotoSprite;
  private _selectedPhotos: PhotoSprite[] = [];
  private _wasDragged: boolean = false;
  private _zoom: number = 1;
  public lineWidth: number = 2;
  public isRotating: boolean = false;

  constructor(rotationEventListener: RotationEventListener, styles: PhotoSpriteStyles) {
    super();
    this._rectGraphics = new Graphics();
    this._rotationHandle = new PhotoRotationHandle(this, styles);
    this.rotationEventListener = rotationEventListener;
    this._styles = styles;
    this.visible = false;
    this.addChild(this._rectGraphics);
    this.addChild(this._rotationHandle);
  }

  get wasDragged() {
    return this._wasDragged;
  }

  get isMultiSelect() {
    return this._selectedPhotos.length > 1;
  }

  get selectedPhotos() {
    return this._selectedPhotos;
  }

  get enabled() {
    return this.visible;
  }

  set enabled(value: boolean) {
    this.visible = value;
    if (!value) {
      this._selectedPhotos = [];
    }
  }

  get centerPoint(): Point {
    return new Point(this.x, this.y);
  }

  rescale(newScale: IPointData) {
    this._zoom = newScale.x;
    this.drawRectangle();
    this._rotationHandle.rescale(newScale);
  }

  onRotationStart() {
    this.isRotating = true;
    this.rotationEventListener.onRotationStart();
  }

  onRotationEnd() {
    this.isRotating = false;
    this.rotationEventListener.onRotationStart();
  }

  setCurrentSelectedPhoto(photo: PhotoSprite): boolean {
    if (this.isSelected(photo)) {
      this._currentSelectedPhoto = photo;
    } else {
      this.enabled = false;
      this._wasDragged = false;
      this._rectGraphics.clear();
      return true;
    }
    return false;
  }

  startDragging() {
    this._dragStartPosition = this.position.clone();
    this._rotateStartAngle = this.rotation;
  }

  stopDragging() {
    this._dragStartPosition = null;
    this.isRotating = false;
  }

  isSelected(photo?: PhotoSprite): boolean {
    if (photo == null) {
      return false;
    }

    return this._selectedPhotos.some((selectedPhoto) => {
      return photo.id === selectedPhoto.id;
    });
  }

  refreshSelectionBounds() {
    this._selectedBounds = this.calculateSelectionBounds();
    this.renderSelectionBounds();
  }

  renderSelectionBounds() {
    this.rotation = 0;
    this.drawRectangle();
    this.drawHandle();
    this.pivot.copyFrom(this.localCenterPoint);
    this.position.set(this._selectedBounds.x + this.pivot.x, this._selectedBounds.y + this.pivot.y);
  }

  calculateSelectionBounds() {
    const minMax = this.calculateMinMaxPoints();
    return new Rectangle(minMax.minPoint.x, minMax.minPoint.y,
      (minMax.maxPoint.x - minMax.minPoint.x),
      (minMax.maxPoint.y - minMax.minPoint.y));
  }

  calculateMinMaxPoints(): {minPoint: IPointData, maxPoint: IPointData} {
    let minPoint = new Point(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
    let maxPoint = new Point(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);

    for (let photo of this._selectedPhotos) {
      PhotoFenceSelector.updateMinMaxPoints(minPoint, maxPoint, photo.position);
    }

    return {minPoint, maxPoint};
  }

  get localCenterPoint(): Point {
    return new Point((this._selectedBounds.width + this.lineWidth) / 2, (this._selectedBounds.height + this.lineWidth) / 2);
  }

  updatePhotoLocations(sprites: PhotoSprite[]) {
    const selectedPhotos = this._selectedPhotos;
    const updatedPhotos = [];
    const byId = {};
    for (let sprite of sprites) {
      byId[sprite.id] = sprite;
    }

    for (let existing of selectedPhotos) {
      const current = byId[existing.id];
      if (current) {
        updatedPhotos.push(current);
      }
    }

    this._selectedPhotos = updatedPhotos;
    return this.refreshSelection();
  }

  updateSelection(selection: PhotoFenceSelection, currentlySelectedPhoto?: PhotoSprite) {
    this._selectedPhotos = selection.photos;
    this._selectedBounds = selection.bounds;
    this._currentSelectedPhoto = currentlySelectedPhoto;
    this._wasDragged = false;
    return this.refreshSelection();
  }

  refreshSelection() {
    if (this._selectedPhotos.length > 0) {
      if (this._currentSelectedPhoto == null
          || this._selectedPhotos.every((photo) => {
          return photo.id !== this._currentSelectedPhoto.id;
        })) {
        this._currentSelectedPhoto = this._selectedPhotos[0];
      }
    }

    if (this.isMultiSelect) {
      this.visible = true;
      this.renderSelectionBounds();
    } else {
      this.rotation = 0;
      this._rotateStartAngle = 0;
      this.isRotating = false;
      this.visible = false;
    }

    return this._currentSelectedPhoto;
  }

  drawRectangle() {
    const g = this._rectGraphics;
    g.clear();
    g.position.set(0,0);
    const style = this._styles.fenceStyle;
    g.lineStyle(this.lineWidth * this._zoom, style.lineColor);
    g.beginFill(style.fillColor, style.fillAlpha);
    g.drawRect(0, 0, this._selectedBounds.width, this._selectedBounds.height);
  }

  drawHandle() {
    this._rotationHandle.redraw();
    this._rotationHandle.position.x = this._selectedBounds.width / 2;
  }

  rotateFromDragStartPosition(position: IPointData) {
    const centerPoint = this.centerPoint;
    const center = new Vector2(centerPoint.x, centerPoint.y);
    const lookAt = new Vector2(position.x, position.y);
    lookAt.sub(center);
    this.rotation = lookAt.angle() + Math.PI / 2;
    return this.rotation - this._rotateStartAngle;
  }

  translateFromDragStartPosition(position: IPointData, startPosition: IPointData) {
    if (this._dragStartPosition == null) {
      this.position.copyFrom(position);
    }

    const offsetX = this._dragStartPosition.x - startPosition.x;
    const offsetY = this._dragStartPosition.y - startPosition.y;
    this.position.set(position.x + offsetX, position.y + offsetY);
  }

}
