import { AvvirEvent } from "type_aliases";
import { ApiScanDataset } from "avvir";

export type ScanDatasetsForFloorLoadedEvent = AvvirEvent<typeof SCAN_DATASETS_FOR_FLOOR_LOADED, {
  floorId: string,
  scanDatasets: ApiScanDataset[]
}>

const scanDatasetsForFloorLoaded = (floorId: string, scanDatasets: ApiScanDataset[]): ScanDatasetsForFloorLoadedEvent => ({
  type: SCAN_DATASETS_FOR_FLOOR_LOADED,
  payload: {
    floorId,
    scanDatasets
  }
});

export const SCAN_DATASETS_FOR_FLOOR_LOADED = "scan_datasets_for_floor_loaded";

export default scanDatasetsForFloorLoaded;
