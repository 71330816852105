import _ from "underscore";
import Avvir, { ApiPlannedElement, UserActionType } from "avvir";

import camerasController from "../../services/utilities/threejs_utilities/cameras_controller";
import ElementEditAction from "../../models/domain/element_edit_action";
import elementsExcludeFromAnalysisUpdated, { ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED } from "../../events/viewer/elements_exclude_from_analysis_updated";
import getFloorId from "../../services/getters/floor_getters/get_floor_id";
import getProjectIdFromLocation from "../../services/getters/location_metadata/get_project_id_from_location";
import getSelectedElements from "../../services/getters/planned_building_element_getters/element_selection_getters/get_selected_elements";
import getUser from "../../services/getters/base_getters/get_user";
import makeEventfulAction, { AvvirThunk } from "../make_eventful_action";
import PlannedBuildingElement from "../../models/domain/planned_building_element";
import recordUserActions from "../record_user_actions";

import type { Dispatch, GetState } from "type_aliases";
import type { ElementEditedEvent } from "../../models/domain/element_edited_event";

const updateSelectedElementsExclude = (newExcludeValue: boolean): AvvirThunk<Promise<any>, any> => {
  return (dispatch, getState: GetState) => {
    const state = getState();
    const floorId = getFloorId(state, {});
    const elements = getSelectedElements(state, {});
    return dispatch(new UpdateSelectedElementsExcludeAction(floorId, elements, newExcludeValue));
  };
};

export default makeEventfulAction("updateSelectedElementsExclude", updateSelectedElementsExclude);

class UpdateSelectedElementsExcludeAction extends ElementEditAction {
  selectedElements: PlannedBuildingElement[];
  newExcludeValue: boolean;

  constructor(floorId: string, selectedElements: PlannedBuildingElement[], newExcludeValue: boolean) {
    super("Exclude", ELEMENTS_EXCLUDE_FROM_ANALYSIS_UPDATED, floorId);
    this.selectedElements = selectedElements;
    this.newExcludeValue = newExcludeValue;
    this.payload.previousElementStates = this.selectedElements.map(element => new PlannedBuildingElement(element));
  }

  perform(dispatch: Dispatch<ElementEditedEvent>, getState: GetState): Promise<void> {
    const state = getState();
    const excludeValues = _.pluck(this.selectedElements, "excludeFromAnalysis");

    if (excludeValues.every(value => value === this.newExcludeValue)) {
      return Promise.resolve();
    } else {
      const projectId = getProjectIdFromLocation(state, {});
      const user = getUser(state, {});
      this.payload.nextElementStates = getUpdatedElements(this.selectedElements, this.newExcludeValue);

      if (this.newExcludeValue) {
        dispatch(recordUserActions(UserActionType.ELEMENT_CHANGED_TO_EXCLUDE_FROM_ANALYSIS, this.payload.nextElementStates, camerasController.photoViewerControls));
      } else {
        dispatch(recordUserActions(UserActionType.ELEMENT_CHANGED_TO_INCLUDE_IN_ANALYSIS, this.payload.nextElementStates, camerasController.photoViewerControls));
      }

      dispatch(elementsExcludeFromAnalysisUpdated(this.payload.floorId, this.payload.previousElementStates, this.payload.nextElementStates));

      const updatedApiElements = this.payload.nextElementStates.map(element => new ApiPlannedElement({
        ...element,
        ...(element.deviation && { deviation: { ...element.deviation, clashing: false } }),
        ...(element.fixedDeviation && { fixedDeviation: { ...element.fixedDeviation, clashing: false } }),
      }));
      return Avvir.api.elements.updatePlannedBuildingElementsForViewerUndo(
        { projectId, floorId: this.payload.floorId },
        updatedApiElements,
        user
      ).then(() => {});
    }
  }
}

let getUpdatedElements = function (originalElements: PlannedBuildingElement[], newExcludeValue: boolean) {
  return originalElements.map(element => {
    return {
      ...element,
      excludeFromAnalysis: newExcludeValue,
    };
  });
};
