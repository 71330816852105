import getFloorId from "../../../../services/getters/floor_getters/get_floor_id";
import getProjectId from "../../../../services/getters/project_getters/get_project_id";
import getScanDatasetId from "../../../../services/getters/scan_dataset_getters/get_scan_dataset_id";
import makeEventfulAction, { AvvirThunk } from "../../../make_eventful_action";
import { ApiFailureEvent } from "../../../../events/notifications/failures/api_failure";
import { DETECTED, INCLUDED } from "../../../../models/domain/enums/deviation_status";
import Avvir from "avvir";
import pushedAllToBim, { PushedAllToBimEvent } from "../../../../events/viewer/pushed_all_to_bim";

const pushAllToBim = (deviations: {globalId: string}[], remove?: boolean ): AvvirThunk<Promise<void>, PushedAllToBimEvent | ApiFailureEvent> => {
  return (dispatch, getState) => {
    let state = getState();
    const projectId = getProjectId(state, {});
    const floorId = getFloorId(state, {});
    const scanDatasetId = getScanDatasetId(state, {});
    const user = state.user;
    let updates = deviations.map(deviation => {
      return Avvir.api.elements.updateDeviationStatus({ projectId, floorId, scanDatasetId }, deviation.globalId, remove? DETECTED : INCLUDED, user).then(() => {
        return { ...deviation }
      });
    })
    return Promise.all(updates).then((newDeviations) => {
      dispatch(pushedAllToBim({ floorId, scanDatasetId }, newDeviations ))
    })
  };
};

export default makeEventfulAction<Promise<void>, PushedAllToBimEvent | ApiFailureEvent, typeof pushAllToBim>("pushAllToBim", pushAllToBim);
