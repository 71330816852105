import { Matrix3, Vector2 } from "three";
import { Vector2Like } from "type_aliases";

export const normalizedImageToFullImage = (imageWidth: number, imageHeight: number): Matrix3 => {
  return new Matrix3().scale(imageWidth, imageHeight);
};

export const computeOriginalBimToAvvirViewer = (floorOffset: Vector2): Matrix3 => {
  return new Matrix3().set(1.0, 0.0, -floorOffset.x, 0.0, 1.0, -floorOffset.y, 0.0, 0.0, 1.0);
};

export const computePhotoAreaMinimapToViewer = (photoAreaMinimapToBimMinimap: Matrix3, bimMinimapToOriginalBim: Matrix3, originalBimToViewer: Matrix3): Matrix3 => {
  if (!photoAreaMinimapToBimMinimap) {
    // noinspection AssignmentToFunctionParameterJS
    photoAreaMinimapToBimMinimap = new Matrix3().identity();
  }
  if (!bimMinimapToOriginalBim) {
    bimMinimapToOriginalBim = new Matrix3().identity();
  }
  return new Matrix3().multiplyMatrices(new Matrix3().multiplyMatrices(originalBimToViewer, bimMinimapToOriginalBim), photoAreaMinimapToBimMinimap);
};

export const computePhotoAreaMinimapToOriginalBim = (photoAreaMinimapToBimMinimap: Matrix3, bimMinimapToOriginalBim: Matrix3): Matrix3 => {
  if (!photoAreaMinimapToBimMinimap) {
    // noinspection AssignmentToFunctionParameterJS
    photoAreaMinimapToBimMinimap = new Matrix3().identity();
  }
  if (!bimMinimapToOriginalBim) {
    bimMinimapToOriginalBim = new Matrix3().identity();
  }
  return new Matrix3().multiplyMatrices(bimMinimapToOriginalBim, photoAreaMinimapToBimMinimap);
};

export const computeNormalizedPhotoAreaMinimapToViewer = (minimapSize: Vector2Like, photoAreaMinimapToViewer: Matrix3): Matrix3 => {
  const normalizedToFullImage = normalizedImageToFullImage(minimapSize.x, minimapSize.y);
  return new Matrix3().multiplyMatrices(photoAreaMinimapToViewer, normalizedToFullImage);
};

