import { Action } from "redux";

export type DeviationEditingModeToggledEvent = Action<typeof DEVIATION_EDITING_MODE_TOGGLED>

const deviationEditingModeToggled = (): DeviationEditingModeToggledEvent => ({
  type: DEVIATION_EDITING_MODE_TOGGLED
});

export const DEVIATION_EDITING_MODE_TOGGLED = "deviation_editing_mode_toggled";

export default deviationEditingModeToggled;
