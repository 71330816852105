import { AvvirRoutingEvent } from "type_aliases";

export type ToSuperadminOrganizationEvent = AvvirRoutingEvent<typeof TO_SUPERADMIN_ORGANIZATION, {organizationId: string}>

const toSuperadminOrganization = (organizationId: string): ToSuperadminOrganizationEvent => ({
  type: TO_SUPERADMIN_ORGANIZATION,
  payload: { organizationId }
});

export const TO_SUPERADMIN_ORGANIZATION = "to_superadmin_organization";

export default toSuperadminOrganization;
