import { AssociationIds, AvvirEvent } from "type_aliases";

export type ScanDatasetDeletedEvent = AvvirEvent<typeof SCAN_DATASET_DELETED, { projectId: string, floorId: string, scanDatasetId: string, newDefaultScanDatasetId?: string }>

const scanDatasetDeleted = ({ projectId, floorId, scanDatasetId }: AssociationIds, newDefaultScanDatasetId?: string): ScanDatasetDeletedEvent => ({
  type: SCAN_DATASET_DELETED,
  payload: {
    projectId,
    floorId,
    scanDatasetId,
    newDefaultScanDatasetId
  }
});

export const SCAN_DATASET_DELETED = "scan_dataset_deleted";

export default scanDatasetDeleted;
