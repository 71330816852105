import { AvvirEvent } from "type_aliases";

export type ObstructionMarkingStartedEvent = AvvirEvent<typeof OBSTRUCTION_MARKING_STARTED, { obstructingElementGlobalId: string }>

const obstructionMarkingStarted = (globalId: string): ObstructionMarkingStartedEvent => ({
  type: OBSTRUCTION_MARKING_STARTED,
  payload: {
    obstructingElementGlobalId: globalId
  }
});

export const OBSTRUCTION_MARKING_STARTED = "obstruction_marking_started";

export default obstructionMarkingStarted;
