import { AvvirEvent } from "type_aliases";
import { ColorMode } from "../../models/domain/enums/color_modes";

export type ColorModeSelectedEvent = AvvirEvent<typeof COLOR_MODE_SELECTED, ColorMode>

const colorModeSelected = (colorMode: ColorMode): ColorModeSelectedEvent => ({
  type: COLOR_MODE_SELECTED,
  payload: colorMode
});



export const COLOR_MODE_SELECTED = "color_mode_selected";

export default colorModeSelected;
