type Collection<Type = any> = Set<Type> | Map<Type, any> | Type[];

export function without<Type>(collection: Set<Type>, toRemove: Collection<Type> | Type): Set<Type>
export function without<Type, MapType>(collection: Map<Type, MapType>, toRemove: Collection<Type> | Type): Map<Type, MapType>
export function without<Type>(collection: Type[], toRemove: Collection<Type> | Type): Type[]
export function without<Type>(collection: Collection<Type>, toRemove: Collection<Type> | Type): Collection<Type> {
  if (collection instanceof Map) {
    const members = [...collection.keys()];
    const filteredKeys = members.filter((member) => !contains(toRemove, member));
    if (filteredKeys.length === collection.size) {
      return collection;
    } else {
      return new Map(filteredKeys.map(((member) => [member, collection.get(member)])));
    }
  } else {
    if (Array.isArray(collection)) {
      const filteredMembers = collection.filter((member) => !contains(toRemove, member));
      if (filteredMembers.length === collection.length) {
        return collection;
      } else {
        return filteredMembers;
      }
    } else {
      const members = [...collection.values()];
      const filteredMembers = members.filter((member) => !contains(toRemove, member));
      if (filteredMembers.length === collection.size) {
        return collection;
      } else {
        return new Set(filteredMembers);
      }
    }
  }
}

function contains<Type>(collection: Collection<Type> | Type, member: Type): boolean {
  if (collection instanceof Set || collection instanceof Map) {
    return collection.has(member);
  } else if (Array.isArray(collection)) {
    return collection.includes(member);
  } else {
    return collection === member;
  }
}

export default without;
