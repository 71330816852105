import { Mesh, Object3D, Vector2, Vector3 } from "three";
import React from "react";

import { DefaultComponentProps, OverridableTypeMap, OverrideProps, Vector2Like, Vector3Like } from "type_aliases";
import { Node, TabNode } from "flexlayout-react";
import RoutingEvent, { RoutingEventWithFloorId } from "../routing_events";
import { Process, RunningProcessWithId } from "../../models/domain/running_process";
import { CanvasFontStyle } from "./canvas_helper";


export function isMesh(object: Object3D): object is Mesh {
  return !!(object as Mesh)?.isMesh;
}

export function isVector2(object: any): object is Vector2 {
  return !!(object as Vector2)?.isVector2;
}

export function isVector2Like(object: any): object is Vector2Like {
  return typeof (object as Vector2Like)?.x === "number" && typeof (object as Vector2Like)?.y === "number";
}

export function isVector3(object: any): object is Vector3 {
  return !!(object as Vector3)?.isVector3;
}

export function isVector3Like(object: any): object is Vector3Like {
  return typeof (object as Vector3Like)?.x === "number" && typeof (object as Vector3Like)?.y === "number" && typeof (object as Vector3Like)?.z === "number";
}

export function hasBoundingClientRect(object: any): object is { getBoundingClientRect: () => ClientRect } {
  return object && object.getBoundingClientRect;
}

export function isTabNode(node: Node): node is TabNode {
  return node.getType() === "tab";
}

export function hasFloorId(event: RoutingEvent): event is RoutingEventWithFloorId {
  return !!event.payload?.floorId;
}

export function isOverrideProps<M extends OverridableTypeMap, C extends React.ElementType>(props: DefaultComponentProps<M> | OverrideProps<M, C>): props is OverrideProps<M, C> {
  return !!(props as OverrideProps<M, C>)?.component;
}

export function isProcessWithId(process: Process): process is RunningProcessWithId {
  return (process as RunningProcessWithId).processId != null;
}

export function isCanvasFontStyle(object: any): object is CanvasFontStyle {
  return !!(object as CanvasFontStyle)?.name || !!(object as CanvasFontStyle)?.color || !!(object as CanvasFontStyle)?.size || !!(object as CanvasFontStyle)?.weight;
}
