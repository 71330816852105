import { Action } from "redux";

export type ExcludeFromAnalysisElementsHiddenEvent = Action<typeof EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN>

const excludeFromAnalysisElementsHidden = (): ExcludeFromAnalysisElementsHiddenEvent => ({
  type: EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN,
});

export const EXCLUDE_FROM_ANALYSIS_ELEMENTS_HIDDEN = "exclude_from_analysis_elements_hidden";

export default excludeFromAnalysisElementsHidden;
