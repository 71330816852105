import { Action } from "redux";

export type TextCopiedEvent = Action<typeof TEXT_COPIED>

const textCopied = () => ({
  type: TEXT_COPIED,
});

export const TEXT_COPIED = "text_copied";

export default textCopied;
