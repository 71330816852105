import { Action } from "redux";

export type PushToProcoreDialogDismissedEvent = Action<typeof PUSH_TO_PROCORE_DIALOG_DISMISSED>

const pushToProcoreDialogDismissed = (): PushToProcoreDialogDismissedEvent => ({
  type: PUSH_TO_PROCORE_DIALOG_DISMISSED
});

export const PUSH_TO_PROCORE_DIALOG_DISMISSED = "push_to_procore_dialog_dismissed";

export default pushToProcoreDialogDismissed;
