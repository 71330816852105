import { Action } from "redux";

export type TradeHiddenAllEvent = Action<typeof TRADE_HIDDEN_ALL>

const tradeHiddenAll = (): TradeHiddenAllEvent => ({
  type: TRADE_HIDDEN_ALL,
});

export const TRADE_HIDDEN_ALL = "trade_hidden_all";

export default tradeHiddenAll;
