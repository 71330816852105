export type ExtendedGeometry = THREE.BufferGeometry & { [key: string]: any }

const _p = new THREE.Vector3(), _n = new THREE.Vector3(), _normalsMatrix = new THREE.Matrix3();

export function enumMeshVertices(geometry: ExtendedGeometry, callback: (position: THREE.Vector3, normal: THREE.Vector3, uv: null, index: number) => void, matrix?: THREE.Matrix4) {
  const attributes = geometry.attributes;

  if (matrix) {
    _normalsMatrix.getNormalMatrix(matrix);
  }

  const positions: number[] = geometry.vb || attributes.position.array;
  let normals: number[] = geometry.vb || attributes.normal?.array;
  const stride: number = geometry.vb ? geometry.vbstride : 3;
  // Get the offset to positions in the buffer. Be careful, 2D buffers
  // don't use the 'position' attribute for positions. Reject those.
  let positionsOffset: number;
  if (geometry.vblayout) {
    if (geometry.vblayout.position) {
      positionsOffset = geometry.vblayout.position.offset;
    } else {
      return; // No positions, what to do??
    }
  } else if (!attributes.position) {
    return; // No positions, what to do??
  } else {
    // @ts-ignore
    positionsOffset = attributes.position.itemOffset || 0;
  }

  let normalOffset = 0;
  let normalAttribute = geometry.vblayout ? geometry.vblayout.normal : attributes.normal || null;
  if (normalAttribute) {
    normalOffset = normalAttribute.offset || normalAttribute.itemOffset || 0;
  } else {
    normals = null;
  }

  //TODO: UV channel

  if (normalAttribute && (normalAttribute.itemSize !== 3 || normalAttribute.bytesPerItem !== 4)) {
    //console.log("Normals are packed, will be skipped from enumMeshTriangles. Use packNormals=false load option.");
    normals = null;
  }

  const vertexCount: number = geometry.vb ? geometry.vb.length / geometry.vbstride : positions.length / stride;

  let positionI = positionsOffset;
  let normalI = normalOffset;
  for (let i = 0; i < vertexCount; i++, positionI += stride, normalI += stride) {

    _p.set(positions[positionI], positions[positionI + 1], positions[positionI + 2]);

    if (matrix) {
      _p.applyMatrix4(matrix);
    }

    if (normals) {
      _n.set(normals[normalI], normals[normalI + 1], normals[normalI + 2]);
      if (matrix) {
        _n.applyMatrix3(_normalsMatrix);
      }
    }

    //TODO: UV channel

    callback(_p.clone(), normals ? _n.clone() : null, null /*, _uv*/, i);
  }
}

export function enumMeshIndices(geometry: ExtendedGeometry, callback: (a: number, b: number, c: number) => void) {
  const indices: number[] = geometry.ib || geometry.indices || geometry.attributes.index?.array || null;

  if (indices) {
    let offsets: { start: number, count: number, index?: number }[] = geometry.offsets;

    if (!offsets || offsets.length === 0) {
      offsets = [{ start: 0, count: indices.length, index: 0 }];
    }

    offsets.forEach(({ start, count, index }) => {
      for (let i = start, il = start + count; i < il; i += 3) {

        const a = index + indices[i];
        const b = index + indices[i + 1];
        const c = index + indices[i + 2];

        callback(a, b, c);
      }
    });
  } else {
    const positions: number[] = geometry.vb || geometry.attributes.position.array;
    const vertexCount: number = geometry.vb ? geometry.vb.length / geometry.vbstride : positions.length / 3;

    for (let _i = 0; _i < vertexCount; _i++) {

      const _a = 3 * _i;
      const _b = 3 * _i + 1;
      const _c = 3 * _i + 2;

      callback(_a, _b, _c);
    }
  }
}
