import { AvvirEvent } from "type_aliases";
import { ApiUserAction } from "avvir";

export type ElementActionsLoadedEvent = AvvirEvent<typeof ELEMENT_ACTIONS_LOADED, {
  actionHistory: ApiUserAction[],
  floorId: string
  globalId: string
}>

const elementActionsLoaded = (floorId: string, globalId: string, actionHistory: ApiUserAction[]): ElementActionsLoadedEvent => ({
  type: ELEMENT_ACTIONS_LOADED,
  payload: {
    actionHistory,
    floorId,
    globalId
  },
});

export const ELEMENT_ACTIONS_LOADED = "element_actions_loaded";

export default elementActionsLoaded;