import { AvvirRoutingEvent } from "type_aliases";

export type ToWbsWithFloor = AvvirRoutingEvent<typeof TO_WBS_WITH_FLOOR, { projectId: string, floorId: string }>

const toWbsWithFloor = (projectId: string, floorId: string): ToWbsWithFloor => ({
  type: TO_WBS_WITH_FLOOR,
  payload: { projectId, floorId }
});

export const TO_WBS_WITH_FLOOR = "to_wbs_with_floor";

export default toWbsWithFloor;
