import { Action } from "redux";

export type ViewerInitializedEvent = Action<typeof VIEWER_INITIALIZED>

const viewerInitialized = (): ViewerInitializedEvent => ({
  type: VIEWER_INITIALIZED
});

export const VIEWER_INITIALIZED = "forge_viewer_initialized";

export default viewerInitialized;
