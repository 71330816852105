import { createSelector } from "reselect";
import { Matrix3 } from "three";

import getFloor from "../floor_getters/get_floor";
import getOriginalBimToViewer from "./get_original_bim_to_viewer";
import { computePhotoAreaMinimapToViewer } from "../../utilities/threejs_utilities/transform_utilities/photo_area_minimap_to_viewer_transforms";

const getPhotoAreaMinimapToViewer = createSelector([
  getFloor,
  getOriginalBimToViewer
], (floor, originalBimToViewer) => {
  // These assignments are solely to be explicit about the transform names (maybe the RHS should be renamed)
  const bimMinimapToOriginalBim = floor?.bimMinimapToWorld
  const photoAreaMinimapToBimMinimap = floor?.photoAreaMinimapPixelToBimMinimapPixel
  return computePhotoAreaMinimapToViewer(photoAreaMinimapToBimMinimap as Matrix3, bimMinimapToOriginalBim as Matrix3, originalBimToViewer);
});

export default getPhotoAreaMinimapToViewer
