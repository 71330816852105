import React, { ForwardRefRenderFunction } from "react";
import { connect } from "react-redux";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";

import getFloorIdFromLocation from "../../services/getters/location_metadata/get_floor_id_from_location";
import getOrganizationId from "../../services/getters/organization_getters/get_organization_id";
import getPageName from "../../services/getters/location_metadata/get_page_name";
import getProjectId from "../../services/getters/project_getters/get_project_id";
import getScanDatasetIdFromLocation from "../../services/getters/location_metadata/get_scan_dataset_id_from_location";
import Link from "../../vendor/redux-first-router-link";
import PageNames from "../../models/domain/enums/page_names";
import { PageOption } from "./nav_bar_pages_dropdown";


export type Props = {
                      pageOption: PageOption
                      onClose: () => void
                    } & ReturnType<typeof mapStateToProps>;

export const NavBarPageOption: ForwardRefRenderFunction<HTMLLIElement, Props> = function NavBarPageOption(props, ref) {
  const { pageOption, organizationId, projectId, floorId, scanDatasetId, pageName, onClose } = props;
  const routingEvent = pageOption.pageNames.has(PageNames.PROJECT) ?
             pageOption.routingEvent(organizationId, projectId, floorId, scanDatasetId, pageOption.pageNames) :
             pageOption.routingEvent && pageOption.routingEvent(projectId, floorId, scanDatasetId, pageOption.pageNames);
  return <MenuItem
    className="NavBarPagesDropdown-menuItem"
    selected={pageOption.pageNames.has(pageName)}
    onClick={onClose}
    data-page-name={pageOption.pageNames}
    disabled={!pageOption.routingEvent}
    component={Link}
    to={routingEvent}
    ref={ref}
  >
    <ListItemIcon>{pageOption.icon}</ListItemIcon>
    <ListItemText className="NavBarPagesDropdown-buttonText">{pageOption.displayName}</ListItemText>
  </MenuItem>;
};

const mapStateToProps = (state, props) => ({
  pageName: getPageName(state, props),
  organizationId: getOrganizationId(state, props),
  projectId: getProjectId(state, props),
  floorId: getFloorIdFromLocation(state, props),
  scanDatasetId: getScanDatasetIdFromLocation(state, props)
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(React.forwardRef(NavBarPageOption));
