import { AvvirEvent } from "type_aliases";
import PageNames from "../../models/domain/enums/page_names";

export type PanelsResetEvent = AvvirEvent<typeof PANELS_RESET, { page: PageNames }>

const panelsReset = (page: PageNames): PanelsResetEvent => ({
  type: PANELS_RESET,
  payload: {
    page
  }
});

export const PANELS_RESET = "panels_reset";

export default panelsReset
