import _ from "underscore";

import { AVVIR_CUSTOM_GEOMETRY_EXTENSIONS } from "./extension_loader";

import type CustomGeometryForgeExtension from "./extensions/element_decorators/custom_geometry_forge_extension";

export default class VisibilityManager {
  constructor(viewer: Autodesk.Viewing.GuiViewer3D, model?: Autodesk.Viewing.Model) {
    this.viewer = viewer;
    this.extensions = _(AVVIR_CUSTOM_GEOMETRY_EXTENSIONS).chain()
      .map(extensionName => {
        return viewer?.getExtension(extensionName) as CustomGeometryForgeExtension;
      })
      .compact()
      .value();
    this.model = model || null;
    this.hiddenNodes = new Set();
  }

  hideMesh(dbId: number): void
  hideMesh(dbId: number, model: Autodesk.Viewing.Model): void
  hideMesh(dbId: number, modelId: number): void
  hideMesh(dbId: number, modelId?: number | Autodesk.Viewing.Model): void {
    this.hiddenNodes.add(dbId);
    const model = this.getModel(modelId);
    if (model) {
      model.visibilityManager.setNodeOff(dbId, true);
    } else {
      this.getElementModel(dbId)?.visibilityManager?.setNodeOff(dbId, true);
    }
  }

  showMesh(dbId: number): void
  showMesh(dbId: number, model: Autodesk.Viewing.Model): void
  showMesh(dbId: number, modelId: number): void
  showMesh(dbId: number, modelId?: number | Autodesk.Viewing.Model): void {
    this.hiddenNodes.delete(dbId);
    const model = this.getModel(modelId);
    if (model) {
      model.visibilityManager.setNodeOff(dbId, false);
    } else {
      this.getElementModel(dbId)?.visibilityManager?.setNodeOff(dbId, false);
    }
  }

  private getModel = (modelId?: Autodesk.Viewing.Model | number) => {
    if (this.model && modelId == null) {
      return this.model;
    } else if (this.model) {
      throw new Error("Can't affect mesh visibility in models not belonging to this visibility manager");
    } else {
      if (typeof modelId === "number") {
        return this.viewer.getAllModels().find((model) => model.id === modelId);
      } else {
        return modelId;
      }
    }
  };

  private getElementModel = (dbId: number) => {
    const extensionModel = this.extensions.find(extension => extension?.elements?.has(dbId))?.model;
    return extensionModel || this.viewer.model;
  };

  private readonly model: Autodesk.Viewing.Model | null;
  private readonly viewer: Autodesk.Viewing.GuiViewer3D;
  private readonly hiddenNodes: Set<number>;
  private readonly extensions: CustomGeometryForgeExtension[];
}
