import { AvvirEvent } from "type_aliases";

export type WbsAvailabilityLoadedEvent =
    AvvirEvent<typeof WBS_AVAILABILITY_LOADED, { projectId: string, isWbsAvailable: boolean }>

const wbsAvailabilityLoaded = (projectId: string, isWbsAvailable: boolean) => {
  return ({
    type: WBS_AVAILABILITY_LOADED,
    payload: {
      projectId,
      isWbsAvailable
    }
  });
};

export const WBS_AVAILABILITY_LOADED = "wbs_availability_loaded";
export default wbsAvailabilityLoaded;
