import { Matrix3, Matrix4, Quaternion, Vector2, Vector3 } from "three";
import _ from "underscore";
import { immerable } from "immer";
import { ApiMatrix4, ApiPhotoLocation, ApiPhotoLocationProperties, isApiQuaternion, PhotoProjectionType, PhotoRotationType } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import addReadOnlyPropertiesToModel from "../../../services/utilities/mixins/add_read_only_properties_to_model";
import Location3d from "./location_3d";
import Matrix4Converter from "../../../services/converters/matrix_4_converter";
import { transformVectorAs2d } from "../../../services/utilities/threejs_utilities/transform_utilities";

import type { DateLike, Matrix4Like, Modify, QuaternionLike, Vector2Like, Vector3Like } from "type_aliases";

export type PhotoLocationArgument = Partial<Modify<PhotoLocation, {
  cameraWorldMatrix?: Matrix4Like,
  minimapCoordinates?: Vector2Like,
  bimLocation?: Partial<Modify<ApiPhotoLocationProperties, {
    position: Vector3Like,
    orientation: QuaternionLike
  }>>,
  createdAt?: DateLike,
  updatedAt?: DateLike,
  originalTimestamp?: DateLike,
  deletedAt?: DateLike
}>>

export default class PhotoLocation {
  constructor({
    id,
    isControlPoint,
    photoAreaId,
    photoSessionId,
    fileId,
    minimapCoordinates,
    minimapBearing,
    projectionType,
    rotationType,
    cameraWorldMatrix,
    yawOffset,
    bimLocation,
    createdAt,
    updatedAt,
    originalTimestamp,
    deletedAt,
    visited
  }: PhotoLocationArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id, photoAreaId, photoSessionId, fileId });
    if (minimapCoordinates) {
      this.minimapCoordinates = new Vector2(minimapCoordinates.x, minimapCoordinates.y);
    }
    this.minimapBearing = minimapBearing;
    let cameraWorldMatrixVal;
    Object.defineProperties(this, {
      cameraWorldMatrix: {
        get() {
          return cameraWorldMatrixVal;
        },
        set(val: Matrix4 | string | ApiMatrix4) {
          if (typeof val === "string") {
            cameraWorldMatrixVal = Matrix4Converter.fromStringToMatrix4(val);
          } else if (val instanceof Matrix4) {
            cameraWorldMatrixVal = val;
          } else {
            cameraWorldMatrixVal = Matrix4Converter.fromApiMatrixToMatrix4(val);
          }
        },
        enumerable: true
      }
    });
    // @ts-ignore
    this.cameraWorldMatrix = cameraWorldMatrix;
    this.projectionType = projectionType;
    this.yawOffset = yawOffset;
    this.rotationType = rotationType;
    this.isControlPoint = isControlPoint;

    if (bimLocation) {
      let position = new Vector3(bimLocation.position.x, bimLocation.position.y, bimLocation.position.z);
      let orientation: Quaternion;
      orientation = PhotoLocation.toDomainQuaternion(bimLocation.orientation);
      this.bimLocation = new Location3d(bimLocation.id, position, orientation);
    }
    if (visited != null) {
      this.visited = visited;
    }

    addDateGetterAndSetterToDomainModel(this, "createdAt", createdAt);
    addDateGetterAndSetterToDomainModel(this, "updatedAt", updatedAt);
    addDateGetterAndSetterToDomainModel(this, "originalTimestamp", originalTimestamp);
    addDateGetterAndSetterToDomainModel(this, "deletedAt", deletedAt);

  }

  static toDomainQuaternion(orientation: QuaternionLike): Quaternion {
    if (isApiQuaternion(orientation)) {
      return new Quaternion(orientation.a, orientation.b, orientation.c, orientation.d);
    } else {
      return orientation;
    }
  }

  readonly id: number;
  readonly photoAreaId: number;
  readonly photoSessionId?: number;
  readonly fileId: number;
  minimapCoordinates: Vector2;
  minimapBearing: number;
  rotationType: PhotoRotationType;
  projectionType: PhotoProjectionType;
  cameraWorldMatrix: Matrix4;
  yawOffset: number = 0;
  bimLocation?: Location3d;
  isControlPoint?: boolean;
  originalTimestamp: Date;
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  visited?: boolean;

  static readonly [immerable] = true;

  static bimPositionOrTransformedMinimapCoordinates(location: PhotoLocation, transform: Matrix3, floorElevation: number): Vector3 {
    if (location.bimLocation == null) {
      return PhotoLocation.transformMinimapCoordinates(location, transform, floorElevation);
    }

    return location.bimLocation.position;
  }

  static transformMinimapCoordinates(location: PhotoLocation, transform: Matrix3, floorElevation: number): Vector3 {
    const transformed = transformVectorAs2d(location.minimapCoordinates, transform);
    return new Vector3(transformed.x, transformed.y, floorElevation);
  }

  static fromApi = (apiPhotoLocation: ApiPhotoLocation) => {
    return new PhotoLocation({
      ..._.omit(apiPhotoLocation, "minimapX", "minimapY", "file"),
      fileId: apiPhotoLocation.file.id,
      minimapCoordinates: {
        x: apiPhotoLocation.minimapX,
        y: apiPhotoLocation.minimapY
      }
    });
  };

  public toApi = (): ApiPhotoLocation => {
    return new ApiPhotoLocation({});
  };
}
