import Avvir from "avvir";
import checkFetchStatus from "../deprecated_gateway_api/check_fetch_status";
import { httpGetHeaders } from "../deprecated_gateway_api/request_headers";
import queryString from "query-string";

export interface OAuth2AccessTokenResponse {
  accessToken: string,
  tokenType: string,
  expiresIn: number,
  refreshToken: string,
}

export default class HxAuthApi {
  static getLoginUrl(realmName: string, state: string) {
    realmName = realmName.toLowerCase();
    return `${Avvir.api.other.baseUrl()}/hxauth/login?realmName=${realmName}&state=${state}`;
  }

  static createAccessToken(realmName: string, authorizationCode: string): Promise<OAuth2AccessTokenResponse> {
    realmName = realmName.toLowerCase();
    const params = queryString.stringify({
      realm: realmName,
      code: authorizationCode,
      redirectUri: `${window.location.origin}/sign-in/sso`
    });
    return (fetch(`${Avvir.api.other.baseUrl()}/hxauth/access-token?${params}`,
      {
        method: "GET",
        headers: httpGetHeaders,
      })
      .then(checkFetchStatus) as Promise<OAuth2AccessTokenResponse>);
  }

  static refreshAccessToken(realmName: string, refreshToken: string): Promise<OAuth2AccessTokenResponse> {
    realmName = realmName.toLowerCase();
    const params = queryString.stringify({
      realmName,
      refreshToken,
    });
    return (fetch(`${Avvir.api.other.baseUrl()}/hxauth/refresh-token?${params}`,
      {
        method: "GET",
        headers: httpGetHeaders,
      })
      .then(checkFetchStatus) as Promise<OAuth2AccessTokenResponse>);
  }
}
