import { Box2, Box3, Matrix3, Matrix4, Vector2, Vector3 } from "three";

import { isVector2Like, isVector3 } from "../../custom_type_guards";

import type { Vector2Like, Vector3Like } from "type_aliases";

export function getTranslationMatrix(x: number, y: number): Matrix3
export function getTranslationMatrix(x: number, y: number, z: number): Matrix4
export function getTranslationMatrix(vector2: Vector2): Matrix3
export function getTranslationMatrix(vector3: Vector3): Matrix4
export function getTranslationMatrix(x: number | Vector2 | Vector3, y?: number, z?: number): Matrix3 | Matrix4 {
  if (typeof x === "number") {
    if (z == null) {
      return new Matrix3().translate(x, y);
    } else {
      return new Matrix4().setPosition(x, y, z);
    }
  } else if ((x as Vector2).isVector2) {
    return new Matrix3().translate(x.x, x.y);
  } else {
    return new Matrix4().setPosition(x.x, x.y, (x as Vector3).z);
  }
}

export function extractRotationMatrix(matrix4: Matrix4): Matrix3 {
  return new Matrix3().setFromMatrix4(matrix4)
}

export function invertMatrix(matrix3: Matrix3): Matrix3
export function invertMatrix(matrix4: Matrix4): Matrix4
export function invertMatrix(matrix: Matrix3 | Matrix4): Matrix3 | Matrix4 {
  if (matrix instanceof Matrix3) {
    return new Matrix3().getInverse(matrix);
  } else if (matrix) {
    return new Matrix4().getInverse(matrix as Matrix4);
  }
}

export function transformBox(transform: Matrix3, box: Box2): Box2
export function transformBox(transform: Matrix4, box: Box3): Box3
export function transformBox(transform: Matrix3 | Matrix4, box: Box2 | Box3): Box2 | Box3 {
  if (transform instanceof Matrix3) {
    return new Box2((box as Box2).min.clone().applyMatrix3(transform), (box as Box2).max.clone().applyMatrix3(transform));
  } else {
    return new Box3((box as Box3).min.clone().applyMatrix4(transform), (box as Box3).max.clone().applyMatrix4(transform));
  }
}

export const composeSimilarityTransform = (scale: number, rotationAngle: number, tx: number, ty: number): Matrix3 => {
  const cosT = Math.cos(rotationAngle);
  const sinT = Math.sin(rotationAngle);
  return new Matrix3().set(cosT, -sinT, tx,
    sinT, cosT, ty,
    0.0, 0.0, 1.0 / scale
  );
};

export function transformVector(point: Vector2, transform: Matrix3): Vector2
export function transformVector(point: Vector3, transform: Matrix3): Vector3
export function transformVector(point: Vector3, transform: Matrix4): Vector3
export function transformVector(point: Vector2 | Vector3, transform: Matrix3 | Matrix4): Vector2 | Vector3 {
  if (isVector2Like(point) && transform instanceof Matrix3) {
    const homogenizedPoint = new Vector3(point.x, point.y, 1.0);
    homogenizedPoint.applyMatrix3(transform);
    homogenizedPoint.multiplyScalar(1.0 / homogenizedPoint.z);
    return new Vector2(homogenizedPoint.x, homogenizedPoint.y);
  } else if (isVector3(point) && transform instanceof Matrix3) {
    const transformedPoint = point.clone().applyMatrix3(transform);
    return transformedPoint.multiplyScalar(1.0 / transformedPoint.z);
  } else if (isVector3(point) && transform instanceof Matrix4) {
    return point.clone().applyMatrix4(transform);
  }
}

export function transformVectorAs2d(point: Vector3Like | Vector2Like, transform: Matrix3): Vector2 {
  const homogenizedPoint = new Vector3(point.x, point.y, 1.0);
  homogenizedPoint.applyMatrix3(transform);
  homogenizedPoint.multiplyScalar(1.0 / homogenizedPoint.z);
  return new Vector2(homogenizedPoint.x, homogenizedPoint.y);
}

export function rotateVector(point: Vector3, rotation: Matrix3): Vector3
export function rotateVector(point: Vector3, rotation: Matrix4): Vector3
export function rotateVector(point: Vector3, rotation: Matrix3 | Matrix4): Vector3 {
  if (rotation instanceof Matrix3) {
    return point.clone().applyMatrix3(rotation);
  } else if (rotation instanceof Matrix4) {
    return point.clone().applyMatrix4(rotation);
  }
}

export const homogenize = (point: Vector2Like): Vector3 => new Vector3(point.x, point.y, 1.0);

const isVector2Array = (array: Vector2[] | Vector3[]): array is Vector2[] => Array.isArray(array) && array[0] instanceof Vector2;

export function sumVectors(vectors: Vector2[]): Vector2
export function sumVectors(vectors: Vector3[]): Vector3
export function sumVectors(vectors: Vector2[] | Vector3[]): Vector2 | Vector3 {
  if (isVector2Array(vectors)) {
    return vectors.reduce((sum, vector) => sum.add(vector), new Vector2());
  } else {
    return vectors.reduce((prev, curr) => prev.add(curr), new Vector3());
  }
}

export function normalizeVector(vector: Vector2): Vector2
export function normalizeVector(vector: Vector3): Vector3
export function normalizeVector(vector: Vector2 | Vector3): Vector2 | Vector3 {
  return vector.clone().divideScalar(vector.length());
}

export function minSafeVector2(): Vector2 {
  return new Vector2(Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);
}

export function maxSafeVector2(): Vector2 {
  return new Vector2(Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER);
}
