import { AvvirEvent } from "type_aliases";

export interface MasterformatProgressFilter {
  issuesOnly: boolean;
  inProgress: boolean;
  notStarted: boolean;
  completed: boolean;
}

export type MasterformatFilterSelectedEvent = AvvirEvent<typeof MASTERFORMAT_FILTER_SELECTED, Partial<MasterformatProgressFilter>>

const masterformatFilterSelected = (filter: Partial<MasterformatProgressFilter>): MasterformatFilterSelectedEvent => ({
  type: MASTERFORMAT_FILTER_SELECTED,
  payload: filter
});

export const MASTERFORMAT_FILTER_SELECTED = "masterformat_filter_selected";

export default masterformatFilterSelected;
