import { ApiMasterformatProgress, AssociationIds } from "avvir";

import { AvvirEvent } from "type_aliases";

export interface FloorMasterformatProgressByType {
  scanned: ApiMasterformatProgress[],
  baseline: ApiMasterformatProgress[],
  current: ApiMasterformatProgress[]
}

type FloorMasterformatProgressEventPayload = FloorMasterformatProgressByType & {
  floorId: string
  projectId: string
}

export type FloorMasterformatProgressLoadedEvent = AvvirEvent<typeof FLOOR_MASTERFORMAT_PROGRESS_LOADED, FloorMasterformatProgressEventPayload>

const floorMasterformatProgressLoaded = ({ floorId, projectId }: AssociationIds, masterformatProgressByType: FloorMasterformatProgressByType): FloorMasterformatProgressLoadedEvent => ({
  type: FLOOR_MASTERFORMAT_PROGRESS_LOADED,
  payload: {
    floorId,
    projectId,
    ...masterformatProgressByType
  }
});

export const FLOOR_MASTERFORMAT_PROGRESS_LOADED = "floor_masterformat_progress_loaded";

export default floorMasterformatProgressLoaded;
