import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";
import { immerable } from "immer";

type RecipeStepArgument = Partial<RecipeStep>;

export default class RecipeStep {
  constructor({ id, recipeId, name, globalRecipeStepId, code, ordinal, partialProgressPercent }: RecipeStepArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id, recipeId });
    this.name = name;
    this.globalRecipeStepId = globalRecipeStepId;
    this.code = code;
    this.ordinal = ordinal;
    this.partialProgressPercent = partialProgressPercent;
  }

  readonly id: number;
  readonly recipeId: number;
  globalRecipeStepId: number;
  name: string;
  code: string;
  ordinal: number;
  partialProgressPercent: number;
  static [immerable] = true
}
