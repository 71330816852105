import { AvvirEvent } from "type_aliases";

export type BimtrackProjectsLoadedEvent = AvvirEvent<typeof BIMTRACK_PROJECTS_LOADED, { hubId: any, projects: any[] }>

const bimtrackProjectsLoaded = (hubId: any, projects: any[]): BimtrackProjectsLoadedEvent => ({
  type: BIMTRACK_PROJECTS_LOADED,
  payload: { hubId, projects }
});

export const BIMTRACK_PROJECTS_LOADED = "bimtrack_projects_loaded";

export default bimtrackProjectsLoaded;
