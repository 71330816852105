import { createSelector } from "reselect";
import getLocationMetadata from "../base_getters/get_location_metadata";

const getQueryParams = createSelector([
  getLocationMetadata
], (locationMetadata) => {
  return locationMetadata.queryParams;
});

export default getQueryParams;
