import { AvvirRoutingEvent } from "type_aliases";

export type ToSuperadminProjectEvent = AvvirRoutingEvent<typeof TO_SUPERADMIN_PROJECT, {
  organizationId: string
  projectId: string
}>

const toSuperadminProject = (organizationId: string, projectId: string): ToSuperadminProjectEvent => ({
  type: TO_SUPERADMIN_PROJECT,
  payload: { organizationId, projectId }
});

export const TO_SUPERADMIN_PROJECT = "to_superadmin_project";

export default toSuperadminProject;
