import { AvvirEvent } from "type_aliases";
import { ApiCloudFile } from "avvir";

export type PhotoAreaFilesLoadedEvent = AvvirEvent<typeof PHOTO_AREA_FILES_LOADED, {
  photoAreaId: number,
  files: ApiCloudFile[]
}>

const photoAreaFilesLoaded = (photoAreaId: number, files: ApiCloudFile[]): PhotoAreaFilesLoadedEvent => ({
  type: PHOTO_AREA_FILES_LOADED,
  payload: {
    photoAreaId,
    files
  }
});

export const PHOTO_AREA_FILES_LOADED = "photo_area_files_loaded";

export default photoAreaFilesLoaded;
