import PageNames from "./page_names";
import { IJsonModel } from "flexlayout-react";
import getProjectSummaryPanelsLayout from "../../../services/get_project_summary_panels_layout";
import getPanelsLayout from "../../../services/get_panels_layout";
import { doPanelFlagsMatch, PanelFlags } from "./panel_flag";
import { Predicate } from "avvir/source/typings/underscore";
import { isBoolean, isFunction } from "underscore";

import { PanelStateModel } from "../../../services/panels/panel_state_model";

export const PANELS_PAGE_REVISIONS_LOCAL_STORAGE_KEY = "panelsPageRevisions";
export const PANELS_MODEL_BY_PAGE = "panelsModelByPage";
export const PANEL_TYPES_SEEN = "panelTypesSeen";

export enum PanelType {
  ACTION_HISTORY = "action_history",
  COMMENTS = "comments",
  ELEMENT_GROUPS = "element_groups",
  ELEMENT_INSPECTOR = "element_inspector",
  INSPECT_PANEL = "inspect_panel",
  INSPECT_REPORTS = "inspect_reports",
  MINIMAP = "minimap",
  PHOTO_VIEWER = "photo_viewer",
  PROJECT_AREA_SUMMARY = "project_area_summary",
  PROJECT_AREAS_MODEL_VIEWER = "project_areas_model_viewer",
  PROJECT_FILES = "project_files",
  VIEWER = "viewer",
  CUSTOM_REPORTS_PANEL = "custom_reports_panel", //JDC: this probably needs to be deleted.
  PROGRESS_4D_REPORT_PANEL = "progress_4d_report_panel",
  PROJECT_METRICS_PANEL = "project_metrics_panel"
}

export type PanelFlagPredicate = boolean | PanelFlags | Predicate<PanelFlags>;

export interface PanelDefinition {
  name: string,
  pages: {
    [key in PageNames]?: {
      enabled?: PanelFlagPredicate
      inDefaultLayout?: PanelFlagPredicate
    }
  },
  locationPreference?: {
    siblingPanelTypes: PanelType[],
    splitSibling?: boolean
  },
  shouldAdd?: (state: PanelStateModel, page: PageNames, flags: PanelFlags) => boolean
}

export interface PanelNode extends PanelDefinition {
  component: PanelType,
  type: string
}

export interface PanelTypeToggle {
  enabled: boolean
  inDefaultLayout: boolean
}

export type PanelTypeToggles = {
  [panelType in PanelType]?: PanelTypeToggle
};

export type PagePanelModel = { [pageName in PageNames]?: IJsonModel };
export type PanelNodeByType = { [panelType in PanelType]: PanelDefinition };

const PanelNodesByPanelType: PanelNodeByType = {
  [PanelType.INSPECT_PANEL]: {
    name: "Mode",
    pages: {
      [PageNames.PROJECT_VIEWER]: {
        enabled: true,
        inDefaultLayout: {
          photosOnly: false
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.ELEMENT_GROUPS]
    }
  },

  [PanelType.MINIMAP]: {
    name: "Map",
    pages: {
      [PageNames.PROJECT_VIEWER]: {
        enabled: true
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.ACTION_HISTORY]
    }
  },

  [PanelType.ELEMENT_INSPECTOR]: {
    name: "Inspector",
    pages: {
      [PageNames.PROJECT_VIEWER]: {
        enabled: true,
        inDefaultLayout: {
          photosOnly: false
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.COMMENTS]
    }
  },

  [PanelType.COMMENTS]: {
    name: "Comments",
    pages: {
      [PageNames.PROJECT_VIEWER]: {
        enabled: true,
        inDefaultLayout: {
          photosOnly: false
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.ELEMENT_INSPECTOR]
    }
  },

  [PanelType.VIEWER]: {
    name: "3D Model",
    pages: {
      [PageNames.PROJECT_VIEWER]: {
        enabled: true,
        inDefaultLayout: {
          photosOnly: false
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.PHOTO_VIEWER]
    }
  },

  [PanelType.INSPECT_REPORTS]: {
    name: "Inspect Reports",
    pages: {
      [PageNames.PROJECT_SUMMARY]: {
        enabled: {
          customReportsPanel: true
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.PROJECT_AREAS_MODEL_VIEWER]
    }
  },

  [PanelType.PROGRESS_4D_REPORT_PANEL]: {
    name: "Schedule vs Progress",
    pages: {
      [PageNames.PROJECT_SUMMARY]: {
        enabled: {
          showProgressOnSummary: true,
          progressReportTab: true,
          // showAllReportsOnSummary: true
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.INSPECT_REPORTS, PanelType.CUSTOM_REPORTS_PANEL]
    }
  },

  [PanelType.CUSTOM_REPORTS_PANEL]: { //JDC: Delete if not needed
    name: "Custom Reports",
    pages: {
      [PageNames.PROJECT_SUMMARY]: {
        enabled: {
          showCustomReportsOnSummary: true,
          // showAllReportsOnSummary: true
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.PROGRESS_4D_REPORT_PANEL, PanelType.INSPECT_PANEL]
    }
  },

  [PanelType.PROJECT_AREA_SUMMARY]: {
    name: "Area Summary",
    pages: {
      [PageNames.PROJECT_SUMMARY]: {
        enabled: {
          projectAreaSummaryPanel: true
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.PROJECT_FILES]
    }
  },

  [PanelType.PROJECT_AREAS_MODEL_VIEWER]: {
    name: "Summary View",
    pages: {
      [PageNames.PROJECT_SUMMARY]: {
        enabled: {
          projectAreasModelViewerPanel: true,
          desktopOnly: true
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.INSPECT_REPORTS]
    }
  },

  [PanelType.PROJECT_FILES]: {
    name: "Files",
    pages: {
      [PageNames.PROJECT_SUMMARY]: {
        enabled: true
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.PROJECT_AREA_SUMMARY]
    }
  },

  [PanelType.ELEMENT_GROUPS]: {
    name: "Groups",
    pages: {
      [PageNames.PROJECT_VIEWER]: {
        enabled: {
          groupsPanel: true
        },
        inDefaultLayout: {
          groupsPanel: true,
          photosOnly: false
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.INSPECT_PANEL]
    }
  },

  [PanelType.ACTION_HISTORY]: {
    name: "History",
    pages: {
      [PageNames.PROJECT_VIEWER]: {
        enabled: {
          actionHistoryPanel: true
        },
        inDefaultLayout: {
          actionHistoryPanel: true,
          photosOnly: false
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.MINIMAP]
    }
  },

  [PanelType.PHOTO_VIEWER]: {
    name: "Photo Viewer",
    pages: {
      [PageNames.PROJECT_VIEWER]: {
        enabled: true,
        inDefaultLayout: {
          photosOnly: true
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.VIEWER],
      splitSibling: true
    }
  },

  [PanelType.PROJECT_METRICS_PANEL]: {
    name: "Metrics",
    pages: {
      [PageNames.PROJECT_SUMMARY]: {
        enabled: {
          projectMetrics: true
        }
      }
    },
    locationPreference: {
      siblingPanelTypes: [PanelType.INSPECT_REPORTS, PanelType.PROGRESS_4D_REPORT_PANEL]
    }
  }
};

export function getPanelNodesByPanelType(panelType: PanelType): PanelNode {
  return {
    ...PanelNodesByPanelType[panelType],
    component: panelType,
    type: "tab"
  };
}

export function getDefaultPanelLayout(pageName: PageNames, flags: PanelFlags) {
  if (pageName === PageNames.PROJECT_SUMMARY) {
    return getProjectSummaryPanelsLayout(flags);
  } else {
    return getPanelsLayout(flags);
  }
}

export function getPanelTypeTogglesByPageAndFlags(page: PageNames, flags: PanelFlags): PanelTypeToggles {
  const toggles: PanelTypeToggles = {};

  for (let typeKey in PanelNodesByPanelType) {
    const panelType = typeKey as PanelType;
    const definition = PanelNodesByPanelType[panelType];
    const pageDef = definition.pages[page];
    if (pageDef == null || (pageDef.inDefaultLayout == null && pageDef.enabled == null)) {
      continue;
    }

    let toggle = toggles[panelType];
    if (toggle == null) {
      toggle = {
        enabled: false,
        inDefaultLayout: false
      };
      toggles[panelType] = toggle;
    }

    const inDefaultLayoutPredicate = pageDef.inDefaultLayout;
    const enabledPredicate = pageDef.enabled;

    const inDefaultLayout = testPanelFlag(inDefaultLayoutPredicate, flags);
    const enabled = testPanelFlag(enabledPredicate, flags);

    if (inDefaultLayout == null) {
      if (enabled != null) {
        toggle.inDefaultLayout = enabled;
      }
    } else {
      toggle.inDefaultLayout = inDefaultLayout;
    }

    if (enabled == null) {
      if (inDefaultLayout != null) {
        toggle.enabled = inDefaultLayout;
      }
    } else {
      toggle.enabled = enabled;
    }
  }

  return toggles;
}

export function testPanelFlag(enabled: PanelFlagPredicate, flags: PanelFlags): boolean | null {
  if (enabled == null) {
    return null;
  }

  if (isBoolean(enabled)) {
    return enabled;
  } else if (isFunction(enabled)) {
    return enabled(flags)
  }

  return doPanelFlagsMatch(enabled, flags);
}

export function getEnabledPanelTypesByPageAndFlags(page: PageNames, flags: PanelFlags): PanelType[] {
  const toggles = getPanelTypeTogglesByPageAndFlags(page, flags);
  const enabledTypes: PanelType[] = [];

  for (let typeKey in toggles) {
    const toggle = toggles[typeKey];
    if (toggle != null && toggle.enabled) {
      enabledTypes.push(typeKey as PanelType);
    }
  }

  return enabledTypes;
}

export default PanelType;
