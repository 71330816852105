import { Action } from "redux";

export type ReviewModeToggledOffEvent = Action<typeof REVIEW_MODE_TOGGLED_OFF>

const reviewModeToggledOff = (): ReviewModeToggledOffEvent => ({
  type: REVIEW_MODE_TOGGLED_OFF,
});

export const REVIEW_MODE_TOGGLED_OFF = "review_mode_toggled_off";

export default reviewModeToggledOff;
