import { AvvirEvent } from "type_aliases";
import { MasterformatFilter } from "../../../services/reducers/filter/reduce_filter";

export type MasterformatFilterUpdatedEvent = AvvirEvent<typeof MASTERFORMAT_FILTER_UPDATED, { masterformatFilter: MasterformatFilter }>

const masterformatFilterUpdated = (masterformatFilter: MasterformatFilter): MasterformatFilterUpdatedEvent => ({
  type: MASTERFORMAT_FILTER_UPDATED,
  payload: {
    masterformatFilter
  }
});

export const MASTERFORMAT_FILTER_UPDATED = "masterformat_filter_updated";

export default masterformatFilterUpdated;
