import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";
import RecipeStep from "./recipe_step";

import type { ApiRecipeStep } from "avvir";
import type { ModifyPartial } from "type_aliases";
import { immerable } from "immer";

type RecipeArgument = ModifyPartial<Recipe, {
  steps: RecipeStep[] | ApiRecipeStep[]
}>;
export default class Recipe {
  constructor({ id, firebaseProjectId, name, isEnabled, modeledCode, steps }: RecipeArgument = {}) {
    addReadOnlyPropertiesToModel(this, { id, firebaseProjectId });
    this.name = name;
    this.isEnabled = isEnabled;
    this.modeledCode = modeledCode;
    if (steps) {
      this.steps = steps.map(step => new RecipeStep(step));
    }
  }

  readonly id: number;
  readonly firebaseProjectId: string;
  name: string;
  isEnabled: boolean;
  modeledCode?: string;
  steps?: RecipeStep[];
  static [immerable] = true
}
