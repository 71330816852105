import _ from "underscore";
import Avvir, { ApiPlannedElement } from "avvir";

import elementsVerifiedUpdated, { ElementsVerifiedUpdatedEvent } from "../../../events/viewer/elements_verified_updated";
import getFloorId from "../../../services/getters/floor_getters/get_floor_id";
import getProjectId from "../../../services/getters/project_getters/get_project_id";
import getUser from "../../../services/getters/base_getters/get_user";
import makeEventfulAction, { AvvirThunk } from "../../make_eventful_action";
import PlannedBuildingElement from "../../../models/domain/planned_building_element";
import { ApiFailureEvent } from "../../../events/notifications/failures/api_failure";

function setElementsVerified(elements: PlannedBuildingElement[], verified: boolean): AvvirThunk<Promise<ApiPlannedElement[]>, ElementsVerifiedUpdatedEvent | ApiFailureEvent> {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = getProjectId(state, {});
    const floorId = getFloorId(state, {});
    const user = getUser(state, {});
    const apiElements = elements.map(element => new ApiPlannedElement({
      ...element,
      verified,
      ...(element.deviation && { deviation: { ...element.deviation, clashing: false } }),
      ...(element.fixedDeviation && { fixedDeviation: { ...element.fixedDeviation, clashing: false } })
    }));
    return Avvir.api.elements.updatePlannedBuildingElementsForViewerUndo({ projectId, floorId }, apiElements, user)
      .then((resp) => {
        dispatch(elementsVerifiedUpdated(floorId, _.pluck(resp, "globalId"), verified));
        return resp;
      });
  };
}

export default makeEventfulAction("setElementsVerified", setElementsVerified);
