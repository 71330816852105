import { Action } from "redux";

export type SectionPointCloudToggledOffEvent = Action<typeof SECTION_POINT_CLOUD_TOGGLED_OFF>

const sectionPointCloudToggledOff = (): SectionPointCloudToggledOffEvent => ({
  type: SECTION_POINT_CLOUD_TOGGLED_OFF,
});

export const SECTION_POINT_CLOUD_TOGGLED_OFF = "section_point_cloud_toggled_off";

export default sectionPointCloudToggledOff;
