import { AvvirEvent } from "type_aliases";

export type MapLayerHiddenEvent = AvvirEvent<typeof MAP_LAYER_HIDDEN, { layerId: string }>

const mapLayerHidden = (layerId: string): MapLayerHiddenEvent => ({
  type: MAP_LAYER_HIDDEN,
  payload: {
    layerId
  }
});

export const MAP_LAYER_HIDDEN = "map_layer_hidden";

export default mapLayerHidden;
