import { Container, Graphics, IPointData } from "pixi.js";
import { PhotoSpriteStyles } from "../photo_sprite_styles";
import { FixedSizeSprite } from "../../layers_shared/fixed_size_sprite";
import { RotationEventListener } from "../photo_selection_rectangle";
import { PixiScalable } from "../../../../../../services/utilities/pixi/pixi_scale";

export class PhotoRotationHandle extends Container implements PixiScalable {
  private readonly _graphics: Graphics;
  private readonly _button: FixedSizeSprite;
  private _styles: PhotoSpriteStyles;
  private _zoom: number = 1;
  public handleLength: number = 20;
  public lineWidth: number = 2;

  constructor(parent: RotationEventListener, styles: PhotoSpriteStyles) {
    super();
    this.visible = true;
    this._graphics = new Graphics();
    this._button = new FixedSizeSprite();

    this._button.on("pointerdown", () => {
      parent.onRotationStart();
    });

    this._button.on("pointerup", () => {
      parent.onRotationEnd();
    });

    this._styles = styles;
    this.addChild(this._graphics);
    this.addChild(this._button);
    this._graphics.zIndex = 0;
    this._button.zIndex = 1;
    this.sortChildren();
  }

  redraw() {
    const g = this._graphics;
    g.clear();

    const lineWidth = this.lineWidth * this._zoom;
    const handleLength = this.handleLength * this._zoom;
    const style = this._styles.fenceStyle;
    g.lineStyle(lineWidth, style.lineColor);
    g.moveTo(0, 0);
    g.lineTo(0, -handleLength);
    g.beginFill(style.fillColor, style.fillAlpha);

    const b = this._button;
    if (this._styles.handleTexture) {
      b.texture = this._styles.handleTexture;
    }
    b.position.x = 0;
    b.position.y = - (handleLength + (b.height/2));
    b.interactive = true;
    b.buttonMode = true;
    b.cursor = "move";
  }

  rescale(newScale: IPointData) {
    this._button.rescale(newScale);
    this._zoom = newScale.x;
    this.redraw();
  }

}
