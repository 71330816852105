import { AssociationIds, AvvirEvent } from "type_aliases";

export type PushPdfToProcoreStartedEvent = AvvirEvent<typeof PUSH_PDF_TO_PROCORE_STARTED, {
  processName: string
  scanDatasetId: string
  floorId: string
  projectId: string
  pdfType: string
  startTime: Date
}>


const pushPdfToProcoreStarted = ({ projectId, floorId, scanDatasetId }: AssociationIds, processName: string, pdfType: string, startTime: Date): PushPdfToProcoreStartedEvent => ({
  type: PUSH_PDF_TO_PROCORE_STARTED,
  payload: {
    processName,
    scanDatasetId,
    floorId,
    projectId,
    pdfType,
    startTime
  }
});

export const PUSH_PDF_TO_PROCORE_STARTED = "push_pdf_to_procore_started";

export default pushPdfToProcoreStarted;
