import { ApiFloor } from "avvir";

import { AvvirEvent } from "type_aliases";

export type FloorsForProjectLoadedEvent = AvvirEvent<typeof FLOORS_FOR_PROJECT_LOADED, ApiFloor[]>

const floorsForProjectLoaded = (apiFloors: ApiFloor[]): FloorsForProjectLoadedEvent => ({
  type: FLOORS_FOR_PROJECT_LOADED,
  payload: apiFloors
});

export const FLOORS_FOR_PROJECT_LOADED = "floors_for_project_loaded";

export default floorsForProjectLoaded;
