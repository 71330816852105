import { Action } from "redux";

export type PhotoViewerDisposedEvent = Action<typeof PHOTO_VIEWER_DISPOSED>

const photoViewerDisposed = (): PhotoViewerDisposedEvent => ({
  type: PHOTO_VIEWER_DISPOSED
});

export const PHOTO_VIEWER_DISPOSED = "photo_viewer_disposed";

export default photoViewerDisposed;
