import Avvir from "avvir";

import getScanDataset from "../../../services/getters/scan_dataset_getters/get_scan_dataset";
import scanDatasetLoaded, { ScanDatasetLoadedEvent } from "../../../events/loaded/scan_datasets/scan_dataset_loaded";
import { AvvirAsyncThunk } from "../../make_eventful_action";

const loadScanDataset = (associationIds: { projectId: string, floorId: string, scanDatasetId: string }): AvvirAsyncThunk<typeof getScanDataset, ScanDatasetLoadedEvent> => {
  return (dispatch, getState) => {
    const user = getState().user;
    return Avvir.api.scanDatasets.getScanDataset(associationIds, user).then(apiScanDataset => {
      dispatch(scanDatasetLoaded(apiScanDataset));
      return getScanDataset(getState(), { scanDatasetId: associationIds.scanDatasetId });
    });
  };
};

export default loadScanDataset;
