import { Vector2, Vector3 } from "three";

import CameraControls from "../camera_controls/camera_controls";
import { isVector3 } from "../utilities/custom_type_guards";

const moveCameraToPositionWithSameOrientation = (cameraControls: CameraControls, position: Vector2 | Vector3) => {
  const lookAtDirection = cameraControls.camera.position.clone().sub(cameraControls.target);
  if (isVector3(position)) {
    cameraControls.camera.position.copy(position);
  } else {
    cameraControls.camera.position.setX(position.x).setY(position.y);
  }
  cameraControls.target.copy(cameraControls.camera.position.clone().sub(lookAtDirection));
};

export default moveCameraToPositionWithSameOrientation;
