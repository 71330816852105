import { DisplayObject, IPointData } from "pixi.js";

export interface PixiScalable {
  rescale: (newScale: IPointData) => void
}

/**
 * Use this function to get the scale to multiply an object by to keep it at a constant size on the screen.
 */
export function calculateScreenSpaceScale(pixiObject: DisplayObject, skipUpdate: boolean = false): IPointData {
  const origin = pixiObject.toLocal({ x: 0, y: 0 }, null, null, skipUpdate);
  const end = pixiObject.toLocal({ x: 1, y: 1 }, null, null, skipUpdate);
  return { x: Math.abs(end.x - origin.x), y: Math.abs(end.y - origin.y) };
}
