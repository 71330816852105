import { Container, IPointData } from "pixi.js";
import clamp from "../general/clamp";

const DEFAULT_STEP = -0.001;
const DEFAULT_MIN = 1;
const DEFAULT_MAX = 10;

export type PixiZoomOptions = {
  step?: number
  min?: number
  max?: number
}

export interface PixiZoomable {
  zoom: (zoom: number) => boolean
}

/*
The terminology in the PIXI docs was a little confusing at first so another way to think of a container is that it
represents a matrix transform applied to everything inside it. By splitting this into a dedicated container we can
ensure that this scale operation is done in the right order.
*/

/**
 * This container is separate from others so that zooming isn't mixed with any other transforms. It will clamp the zoom
 * to a min and max (default 1x - 8x) and the default step is calibrated for a mouse wheel.
 */
export class PixiZoomContainer extends Container {
  public zoomStep: number = DEFAULT_STEP;
  public minZoom: number = DEFAULT_MIN;
  public maxZoom: number = DEFAULT_MAX;

  constructor(opts?: PixiZoomOptions) {
    super();
    if (opts) {
      this.zoomStep = opts.step ? opts.step : DEFAULT_STEP;
      this.minZoom = opts.min ? opts.min : DEFAULT_MIN;
      this.maxZoom = opts.max ? opts.max : DEFAULT_MAX;
    }
  }

  /**
   * @param zoomDelta Amount to change the zoom factor, typically deltaY from a mouse wheel event.
   * @param screenPoint Point relative to the origin of the canvas element to zoom into.
   * @returns local position difference after zooming or undefined if no change
   */
  zoom(zoomDelta: number,
       screenPoint: IPointData = { x: 0, y: 0 }): IPointData | undefined {
    const zoomScale = 1 + (zoomDelta * this.zoomStep);
    return this.zoomTo(this.scale.x * zoomScale, screenPoint);
  }

  /**
   * @param zoomLevel Absolute zoom level to change to.
   * @param screenPoint Point relative to the origin of the canvas element to zoom into.
   * @returns local position difference after zooming or undefined if no change
   */
  zoomTo(zoomLevel: number, screenPoint: IPointData = { x: 0, y: 0 }): IPointData | undefined {
    const previousZoomLevel = this.scale.x;
    const newZoomLevel = clamp(this.minZoom, zoomLevel, this.maxZoom);
    if (newZoomLevel === previousZoomLevel) {
      return null;
    }

    const preZoomPoint = this.toLocal(screenPoint);

    this.scale.set(newZoomLevel);

    const postZoomPoint = this.toLocal(screenPoint);

    return {
      x: postZoomPoint.x - preZoomPoint.x,
      y: postZoomPoint.y - preZoomPoint.y
    };
  }

  reset() {
    this.zoomTo(1)
  }

}
