import { PhotoSprite } from "../photo_sprite";
import { Vector2 } from "three";
import { PixiMinimapTransforms } from "../../pixi_minimap_app";
import { IPointData } from "pixi.js";
import { PhotoSpriteInterpolator } from "../photo_sprite_interpolator";
import { ControlPointNode, LinearControlPointLocator } from "../linear_control_point_locator";
import { PhotoSelectionRectangle } from "../photo_selection_rectangle";
import { PhotoSpriteSupplier } from "../photo_sprite_container";
import { MovedPhotoLocation } from "../../../../../../events/viewer/photo_locations_moved";

export function bisectVectors(previousVector: Vector2 | null, nextVector: Vector2 | null): Vector2 {
  if (previousVector == null) {
    return nextVector;
  } else if (nextVector == null) {
    return previousVector;
  }

  return previousVector.clone().add(nextVector).normalize();
}

export interface AdjustPointsControlOptions {
  sprites: PhotoSpriteSupplier;
  selectionRectangle: PhotoSelectionRectangle;
  nextSpritesInterpolator: PhotoSpriteInterpolator;
  previousSpritesInterpolator: PhotoSpriteInterpolator;
  controlPointLocator: LinearControlPointLocator;
}

export class AdjustPointsControl {
  private readonly selectionRectangle: PhotoSelectionRectangle;
  private readonly sprites: PhotoSpriteSupplier;
  private readonly nextSpritesInterpolator: PhotoSpriteInterpolator;
  private readonly previousSpritesInterpolator: PhotoSpriteInterpolator;
  private readonly controlPointLocator: LinearControlPointLocator;
  private adjustingDots: PhotoSprite[] = [];
  private adjustStartPosition: IPointData;
  private controlPointsRoot: ControlPointNode;
  public readonly isControlPointById: Set<number> = new Set<number>();
  public needsControlPointRefresh: boolean = true;

  constructor(args: AdjustPointsControlOptions) {
    this.sprites = args.sprites;
    this.selectionRectangle = args.selectionRectangle;
    this.nextSpritesInterpolator = args.nextSpritesInterpolator;
    this.previousSpritesInterpolator = args.previousSpritesInterpolator;
    this.controlPointLocator = args.controlPointLocator;
  }

  clear() {
    this.adjustingDots = [];
    this.nextSpritesInterpolator.clear();
    this.previousSpritesInterpolator.clear();
    this.controlPointsRoot = null;
    this.needsControlPointRefresh = false;
    this.isControlPointById.clear();
  }

  isControlPoint(photo: PhotoSprite): boolean {
    return this.isControlPointById.has(photo.id);
  }

  isAdjusting() {
    return this.adjustingDots.length > 0;
  }

  refreshControlPoints(allSprites: PhotoSprite[]) {
    this.isControlPointById.clear();
    this.controlPointsRoot = this.controlPointLocator.findNodes(allSprites.slice(1), allSprites[0]);
    let node = this.controlPointsRoot;

    while (node != null) {
      if (node.point) {
        node.point.isControlPoint = true;
        this.isControlPointById.add(node.point.id);
      }

      if (node.previousPoints.length > 0) {
        node.previousPoints.forEach(p => { p.isControlPoint = false; });
      }
      node = node.next;
    }

    this.needsControlPointRefresh = false;
  }

  update() {
    if (this.needsControlPointRefresh) {
      this.refreshControlPoints(this.sprites.sprites);
    }
  }

  onAdjustStart(selectedSprites: PhotoSprite[], startPosition: IPointData) {
    this.adjustStartPosition = startPosition;
    this.adjustingDots = selectedSprites;

    if (selectedSprites.length === 1) {
      const selectedSprite = selectedSprites[0];
      selectedSprite.startDragging();
      selectedSprite.isControlPoint = true;
      selectedSprite.visited = true;

      const controlPoint = this.controlPointsRoot.insertControlPoint(selectedSprite);
      this.previousSpritesInterpolator.setDescendingFromNode(controlPoint, selectedSprite);
      this.nextSpritesInterpolator.setAscendingFromNode(controlPoint, selectedSprite);
    } else {
      this.previousSpritesInterpolator.clear();
      this.nextSpritesInterpolator.clear();
      this.selectionRectangle.startDragging();

      if (selectedSprites.length > 1) {
        selectedSprites.forEach(dot => { dot.startDragging();});
      }
    }
  }

  onMinimapDrag(transforms: PixiMinimapTransforms, position: IPointData) {
    if (this.adjustingDots.length === 1) {
      const draggingDot = this.adjustingDots[0];
      draggingDot.updateFromDragPosition(transforms, position);

      const previousVector = this.previousSpritesInterpolator.onDrag(transforms, draggingDot);
      const nextVector = this.nextSpritesInterpolator.onDrag(transforms, draggingDot);
      if (!(previousVector == null && nextVector == null)) {
        draggingDot.setOrientationFromVector(bisectVectors(previousVector, nextVector));
      }
    } else if (this.selectionRectangle.isRotating) {
      const rotation = this.selectionRectangle.rotateFromDragStartPosition(position);
      const center = this.selectionRectangle.centerPoint;
      this.adjustingDots.forEach(dot => {
        dot.rotateFromSelectionCenter(transforms, center, rotation);
      });
    } else {
      this.selectionRectangle.translateFromDragStartPosition(position, this.adjustStartPosition);
      this.adjustingDots.forEach(dot => {
        dot.translateFromDragStartPosition(transforms, position, this.adjustStartPosition);
      });
    }
  }

  onAdjustEnd(transforms: PixiMinimapTransforms): MovedPhotoLocation[] {
    let payload: MovedPhotoLocation[] = null;
    if (this.adjustingDots.length > 0) {

      if (this.adjustingDots.length === 1) {
        const dot = this.adjustingDots[0];
        dot.visited = true;
        dot.isControlPoint = true;
        const points = [dot];
        points.push(...this.previousSpritesInterpolator.toSavePoints());
        points.push(...this.nextSpritesInterpolator.toSavePoints());
        payload = this.toPointUpdatePayload(transforms, points);
      } else {
        payload = this.toPointUpdatePayload(transforms, this.adjustingDots);
      }

      this.adjustingDots.forEach(dot => {
        dot.stopDragging();
      });
      this.previousSpritesInterpolator.clear();
      this.nextSpritesInterpolator.clear();
      this.selectionRectangle.stopDragging();
      this.needsControlPointRefresh = true;
      this.adjustingDots = [];
    }

    return payload;
  }

  toPointUpdatePayload(transforms: PixiMinimapTransforms, items: PhotoSprite[]): MovedPhotoLocation[] {
    return items.map(item => item.toPointUpdatePayload(transforms));
  }

  static create(sprites: PhotoSpriteSupplier, selectionRectangle: PhotoSelectionRectangle) {
    return new AdjustPointsControl({
      sprites,
      selectionRectangle,
      nextSpritesInterpolator: new PhotoSpriteInterpolator(),
      previousSpritesInterpolator: new PhotoSpriteInterpolator(),
      controlPointLocator: new LinearControlPointLocator()
    });
  }

}
