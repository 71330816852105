import { AvvirEvent } from "type_aliases";
import { ApiPhotoSession } from "avvir";

export type PhotoSessionsLoadedEvent = AvvirEvent<typeof PHOTO_SESSIONS_LOADED, { photoSessions: ApiPhotoSession[] }>

const photoSessionsLoaded = (photoSessions: ApiPhotoSession[]): PhotoSessionsLoadedEvent => ({
  type: PHOTO_SESSIONS_LOADED,
  payload: { photoSessions }
});

export const PHOTO_SESSIONS_LOADED = "photo_sessions_loaded";

export default photoSessionsLoaded;
