import { Action } from "redux";

export type ReviewModeToggledOnEvent = Action<typeof REVIEW_MODE_TOGGLED_ON>

const reviewModeToggledOn = (): ReviewModeToggledOnEvent => ({
  type: REVIEW_MODE_TOGGLED_ON
});

export const REVIEW_MODE_TOGGLED_ON = "review_mode_toggled_on";

export default reviewModeToggledOn;
