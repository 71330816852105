import { createSelector } from "reselect";

import getQueryParams from "./get_query_params";
import { toBoolean } from "../../utilities/general";

const getQueryParamIsEmbedded = createSelector([
  getQueryParams
], (queryParams) => toBoolean(queryParams?.embed));

export default getQueryParamIsEmbedded
