import { AvvirEvent } from "type_aliases";
import { ApiCloudFile, PhotoAreaPurposeType } from "avvir";

export type PhotoAreaFileUploadedEvent = AvvirEvent<typeof PHOTO_AREA_FILE_UPLOADED, {
  photoAreaId: number,
  purposeType: PhotoAreaPurposeType,
  file: ApiCloudFile
}>

const photoAreaFileUploaded = (photoAreaId: number, purposeType: PhotoAreaPurposeType, file: ApiCloudFile): PhotoAreaFileUploadedEvent => ({
  type: PHOTO_AREA_FILE_UPLOADED,
  payload: {
    photoAreaId,
    purposeType,
    file
  }
});

export const PHOTO_AREA_FILE_UPLOADED = "photo_area_file_uploaded";

export default photoAreaFileUploaded;
