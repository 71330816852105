import { Action } from "redux";

export type BimAndPhotoCameraUnlinkedEvent = Action<typeof BIM_AND_PHOTO_CAMERA_UNLINKED>

const bimAndPhotoCameraUnlinked = (): BimAndPhotoCameraUnlinkedEvent => ({
  type: BIM_AND_PHOTO_CAMERA_UNLINKED
});

export const BIM_AND_PHOTO_CAMERA_UNLINKED = "bim_and_photo_camera_unlinked";

export default bimAndPhotoCameraUnlinked;
