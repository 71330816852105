import { AvvirEvent } from "type_aliases";
import DetailedElement from "../../../models/domain/detailed_element";

export type DetailedElementsLoadedEvent = AvvirEvent<typeof DETAILED_ELEMENTS_LOADED, {
  elements: DetailedElement[]
  floorId: string
  scanDatasetId: string
}>

const detailedElementsLoaded = ({ elements, scanDatasetId, floorId }: { elements: DetailedElement[], scanDatasetId: string, floorId: string }): DetailedElementsLoadedEvent => ({
  type: DETAILED_ELEMENTS_LOADED,
  payload: {
    floorId,
    scanDatasetId,
    elements
  }
});

export const DETAILED_ELEMENTS_LOADED = "detailed_elements_loaded";

export default detailedElementsLoaded;
