import { Container } from "pixi.js";
import { PixiMinimapApp } from "../pixi_minimap_app";
import { FloorPlanLayer } from "../floor_plan_layer/floor_plan_layer";

// TODO: Move more pixi_minimap_app code here

export class PhotoMinimapStage extends Container {
  private app: PixiMinimapApp;
  floorPlanLayer: FloorPlanLayer;

  init(app: PixiMinimapApp, floorPlanLayer: FloorPlanLayer) {
    this.interactive = true;
    this.hitArea = app.screen;
    this.app = app;
    this.floorPlanLayer = floorPlanLayer;

    this.on("pointerdown", (event) => {
      const orig = event.data.originalEvent;
      floorPlanLayer.startDragging({ x: orig.offsetX, y: orig.offsetY });
      this.cursor = "default";
    });

    this.on("pointerup", this.onUpOrOut.bind(this));

    this.on("pointerout", this.onUpOrOut.bind(this));

    this.on("pointermove", (event) => {
      const orig = event.data.originalEvent;
      floorPlanLayer.updateDragPosition(app, {x: orig.offsetX, y: orig.offsetY});
    });
  }

  onUpOrOut(event) {
    let wasMouseDown = !! this.floorPlanLayer.startDragTime;
    if(!wasMouseDown){
      return;
    }
    let now = new Date();
    let timeSinceMouseDown = now.valueOf() - this.floorPlanLayer.startDragTime.valueOf();
    if (timeSinceMouseDown > 250) {
      this.onDragEnd();
    } else {
      this.onClick();
    }
  }

  onDragEnd(){
    this.floorPlanLayer.stopDragging();
    this.cursor = "default";
    this.app.photosLayer.onPointerUpOrOut(this.app);
  }

  onClick(){
    if (window.Avvir.featureFlags.flagsReadOnly.mapNavigation) {
      this.app.moveBimCameraToMapLocation(this.floorPlanLayer.startPos);
    }
    this.floorPlanLayer.stopDragging();
  }
}
