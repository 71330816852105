import { Reducer } from "redux";

import CommentThread from "../../../models/domain/comment_thread";
import makeUpdateModel from "./make_update_model";
import { COMMENT_CREATED, CommentCreatedEvent } from "../../../events/comments/comment_created";
import { COMMENT_THREAD_CREATED, CommentThreadCreatedEvent } from "../../../events/comments/comment_thread_created";
import { COMMENT_THREADS_LOADED, CommentThreadsLoadedEvent } from "../../../events/comments/comment_threads_loaded";

type CommentThreadEvent =
  CommentThreadCreatedEvent
  | CommentThreadsLoadedEvent
  | CommentCreatedEvent;

export type CommentThreadsStore = {
  byId: {
    [id: number]: CommentThread
  },
  idsByProjectId: {
    [projectId: string]: number[]
  }
}

const updateCommentThread = makeUpdateModel<CommentThread>(new CommentThread());

function addToProjectIds(commentThreads: CommentThreadsStore, projectId: string, threadId: number) {
  let threadsIdsForProject = commentThreads.idsByProjectId[projectId];
  if (threadsIdsForProject == null) {
    threadsIdsForProject = [];
  } else {
    threadsIdsForProject = [...threadsIdsForProject];
  }

  if (threadsIdsForProject.indexOf(threadId) < 0) {
    threadsIdsForProject.push(threadId);
  }

  return {
    ...commentThreads,
    idsByProjectId: {
      ...commentThreads.idsByProjectId,
      [projectId]: threadsIdsForProject
    }
  }
}

const reduceCommentThreads: Reducer<CommentThreadsStore, CommentThreadEvent> = (
  commentThreadStore: CommentThreadsStore = { byId: {}, idsByProjectId: {} },
  event
) => {
  let commentThreads = commentThreadStore?.byId ? commentThreadStore : { byId: {}, idsByProjectId: {} };

  switch (event.type) {
    case COMMENT_THREAD_CREATED: {
      commentThreads = updateCommentThread(commentThreads, event.payload.commentThread.id, event.payload.commentThread);
      return addToProjectIds(commentThreads, event.payload.projectId, event.payload.commentThread.id);
    }
    case COMMENT_THREADS_LOADED: {
      if (event.payload?.commentThreads) {
        if (event.payload.commentThreads.length === 0) {
          return {
            byId: {},
            idsByProjectId: {}
          };
        } else {
          return event.payload.commentThreads.reduce((commentThreadsSoFar, commentThread) => {
            return addToProjectIds(updateCommentThread(commentThreadsSoFar, commentThread.id, commentThread), event.payload.projectId, commentThread.id);
          }, commentThreads);
        }
      } else {
        return commentThreads;
      }
    }
    case COMMENT_CREATED: {
      return updateCommentThread(commentThreads, event.payload.commentThreadId, (commentThread) => {
        return {
          ...commentThread,
          comments: [
            event.payload.comment,
            ...commentThread.comments
          ]
        };
      });
    }
    default: {
      return commentThreads;
    }
  }
};

export default reduceCommentThreads;
