import { Vector3 } from "three";
import { AvvirEvent } from "type_aliases";

export type RotationLockedAroundPointEvent = AvvirEvent<typeof ROTATION_LOCKED_AROUND_POINT, { point: Vector3 }>

const rotationLockedAroundPoint = (point: Vector3): RotationLockedAroundPointEvent => ({
  type: ROTATION_LOCKED_AROUND_POINT,
  payload: {
    point
  }
});

export const ROTATION_LOCKED_AROUND_POINT = "rotation_locked_around_point";

export default rotationLockedAroundPoint;
