import { createSelector } from "reselect";
import _ from "underscore";

import getGroupsForFloor from "../group_getters/get_groups_for_floor";
import getGroupMembers from "./get_group_members";
import { ByDatabaseId } from "type_aliases";
import { ApiGroupMember } from "avvir";

const empty: ByDatabaseId<ApiGroupMember> = {};

const getGroupMembersForFloor = createSelector([
  getGroupMembers,
  getGroupsForFloor
], (groupMembers, groupsForFloor) => {
  const memberIds = _.chain(groupsForFloor)
    .pluck("members")
    .flatten()
    .value();

  if (memberIds.length) {
    return _.pick(groupMembers.byId, memberIds);
  } else {
    return empty;
  }
});

export default getGroupMembersForFloor;
