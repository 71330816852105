import { ApiMasterformatProgress } from "avvir";

import { AvvirEvent } from "type_aliases";

export type ScannedProjectMasterformatProgressLoadedEvent = AvvirEvent<typeof SCANNED_PROJECT_MASTERFORMAT_PROGRESS_LOADED, {
  id: string,
  scanned: ApiMasterformatProgress[]
}>

const scannedProjectMasterformatProgressLoaded = (projectId: string, scannedMasterformatProgress: ApiMasterformatProgress[]): ScannedProjectMasterformatProgressLoadedEvent => ({
  type: SCANNED_PROJECT_MASTERFORMAT_PROGRESS_LOADED,
  payload: {
    id: projectId,
    scanned: scannedMasterformatProgress
  }
});

export const SCANNED_PROJECT_MASTERFORMAT_PROGRESS_LOADED = "scanned_project_masterformat_progress_loaded";

export default scannedProjectMasterformatProgressLoaded;
