import { Action } from "redux";

export type Mixpanel4DUpsellSalesContactedEvent = Action<typeof MIXPANEL_4D_UPSELL_SALES_CONTACTED>

const mixpanel4dUpsellSalesContacted = (): Mixpanel4DUpsellSalesContactedEvent => ({
  type: MIXPANEL_4D_UPSELL_SALES_CONTACTED,
});


export const MIXPANEL_4D_UPSELL_SALES_CONTACTED = "4d_upsell_sales_contacted";

export default mixpanel4dUpsellSalesContacted;
