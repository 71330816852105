import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import UserRole from "./enums/user_role";
import { DateLike, ModifyPartial } from "type_aliases";

type InvitationArgs = ModifyPartial<Invitation, { expiry: DateLike }>

export default class Invitation {
  constructor({
    resourceName,
    userEmail,
    role,
    token,
    expiry,
    acceptUrl
  }: InvitationArgs = {}) {
    addDateGetterAndSetterToDomainModel(this, "expiry");

    this.resourceName = resourceName;
    this.userEmail = userEmail;
    this.role = role;
    this.token = token;
    // @ts-ignore
    this.expiry = expiry;
    this.acceptUrl = acceptUrl;
  }

  resourceName: string;
  userEmail: string;
  role: UserRole;
  token: string;
  expiry: Date;
  acceptUrl: string;
}
