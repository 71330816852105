import { PhotoSprite } from "./photo_sprite";
import { Euler, Quaternion, Vector2, Vector3 } from "three";
//import { bisectVectors } from "./adjust_points_control";
import { ControlPointNode } from "./linear_control_point_locator";
import { PixiMinimapTransforms } from "../pixi_minimap_app";

export function vectorToYaw(vector: Vector2) {
  const angle = vector.angle();
  return angle - Math.PI / 2;
}

export function vectorToEulerYaw(vector: Vector2): Euler {
  return new Euler(0, 0, vectorToYaw(vector));
}

export function vectorToQuaternionYaw(vector: Vector2): Quaternion {
  return new Quaternion().setFromEuler(vectorToEulerYaw(vector));
}

export function toPhotoLocationVector(pointA: PhotoSprite, pointB: PhotoSprite): Vector2 {
  const pointAPos = new Vector2(pointA.x, pointA.y);
  const pointBPos = new Vector2(pointB.x, pointB.y);

  return (pointA.zIndex < pointB.zIndex ? pointAPos.sub(pointBPos) : pointBPos.sub(pointAPos)).normalize();
}

type DragPoint = {
  photoSprite: PhotoSprite
  alpha: number
}

export class PhotoSpriteInterpolator {
  controlPoint: PhotoSprite;
  startYaw: number;

  // To calculate orientation of the control point itself we need the next control point in the sequence or null if the
  // control point is at the end.
  nextControlPoint: PhotoSprite;
  draggingPoints: DragPoint[] = [];

  clear() {
    this.controlPoint = null;
    this.nextControlPoint = null;
    this.draggingPoints = null;
  }

  toSavePoints(): PhotoSprite[] {
    if (this.controlPoint == null) {
      return [];
    }

    const points = [this.controlPoint];

    if (this.draggingPoints == null || this.draggingPoints.length === 0) {
      return points;
    }

    this.draggingPoints.forEach((dragPoint) => {
      const point = dragPoint.photoSprite;
      point.isControlPoint = false;
      points.push(point);
    });

    return points;
  }

  toRelativeYaw(vector: Vector2): Quaternion {
    return new Quaternion().setFromEuler(new Euler(0, 0, vectorToYaw(vector) - this.startYaw));
  }

  saveStartOrientation(dragSprite: PhotoSprite) {
    this.startYaw = vectorToYaw(toPhotoLocationVector(dragSprite, this.controlPoint));
  }

  setAscendingFromNode(node: ControlPointNode, dragSprite: PhotoSprite) {
    const next = node.next;
    if (next) {
      this.controlPoint = next.point;
      this.draggingPoints = PhotoSpriteInterpolator.toDraggingPoints(next.previousPoints, dragSprite.bimPosition, next.point.bimPosition);
      if (next.next) {
        this.nextControlPoint = next.next.point;
      }
      this.saveStartOrientation(dragSprite);
    } else {
      this.clear();
    }
  }

  setDescendingFromNode(node: ControlPointNode, dragSprite: PhotoSprite) {
    const previous = node.previous;
    if (previous) {
      this.controlPoint = previous.point;
      this.draggingPoints = PhotoSpriteInterpolator.toDraggingPoints(node.previousPoints.slice().reverse(), dragSprite.bimPosition, previous.point.bimPosition);
      if (previous.previous) {
        this.nextControlPoint = previous.previous.point;
      }
      this.saveStartOrientation(dragSprite);
    } else {
      this.clear();
    }
  }

  onDrag(transforms: PixiMinimapTransforms, draggingSprite: PhotoSprite) {
    if (this.controlPoint == null) {
      return null;
    }

    const vectorToDraggingSprite = toPhotoLocationVector(draggingSprite, this.controlPoint);
    const angleToDraggingSprite = this.toRelativeYaw(vectorToDraggingSprite);
    if (this.nextControlPoint == null) {
      this.controlPoint.addToOrientation(angleToDraggingSprite);
    } else {
      //const nextVector = toPhotoLocationVector(this.controlPoint, this.nextControlPoint);
      this.nextControlPoint.isControlPoint = true;
      this.controlPoint.addToOrientation(angleToDraggingSprite);
      //this.controlPoint.addToOrientation(this.toRelativeYaw(bisectVectors(vectorToDraggingSprite, nextVector)));
    }

    const position = draggingSprite.position;
    const startPos = new Vector2(position.x, position.y);
    const endPos = new Vector2(this.controlPoint.position.x, this.controlPoint.position.y);

    this.draggingPoints.forEach((dragPoint) => {
      const point = dragPoint.photoSprite;
      const interpolated = startPos.clone().lerp(endPos, dragPoint.alpha);
      point.position.x = interpolated.x;
      point.position.y = interpolated.y;
      point.isControlPoint = false;
      point.recalculateBimFromPosition(transforms);
      point.addToOrientation(angleToDraggingSprite);
    });

    this.controlPoint.isControlPoint = true;

    return vectorToDraggingSprite;
  }

  static toDraggingPoints(points: PhotoSprite[], startBim: Vector3, endBim: Vector3): DragPoint[] {
    const startPos = new Vector2(startBim.x, startBim.y)
    const distance = startPos.distanceTo(new Vector2(endBim.x, endBim.y));
    return points.map((photoSprite) => {
      const bimPos = photoSprite.bimPosition;
      const thisDistance = startPos.distanceTo(new Vector2(bimPos.x, bimPos.y));
      return {
        photoSprite,
        alpha: thisDistance / distance
      }
    });
  }
}
