import { createSelector } from "reselect";

import getGroupMemberGlobalIdsForFloor from "../group_member_getters/get_group_member_global_ids_for_floor";
import getProps from "../base_getters/get_props";
import { GetPropsType } from "type_aliases";

type Selectors = [
  typeof getGroupMemberGlobalIdsForFloor,
  GetPropsType<{ globalId: string }>
]
const getIsElementInGroup = createSelector<Selectors, boolean>([
  getGroupMemberGlobalIdsForFloor, getProps
], (allGlobalIdsInGroups, props) => {
  return !!allGlobalIdsInGroups?.has(props?.globalId);
});

export default getIsElementInGroup;
