import { ApiScanDataset } from "avvir";
import { AvvirEvent } from "type_aliases";

export type ScanDatasetUpdatedEvent = AvvirEvent<typeof SCAN_DATASET_UPDATED, ApiScanDataset>

const scanDatasetUpdated = (apiScanDataset: ApiScanDataset): ScanDatasetUpdatedEvent => ({
  type: SCAN_DATASET_UPDATED,
  payload: {
    ...apiScanDataset
  }
});

export const SCAN_DATASET_UPDATED = "scan_dataset_updated";

export default scanDatasetUpdated;
