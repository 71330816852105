import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";

import { snakeCase } from "../services/converters/snake_case";
import { ReduxStore } from "../services/reducers/root_reducer";

type ThenArgRecursive<T> = T extends PromiseLike<infer U>
                           ? { 0: ThenArgRecursive<U>; 1: U }[T extends PromiseLike<any> ? 0 : 1]
                           : T

interface AvvirThunkAction<Return, State, Actions extends Action> extends ThunkAction<Return, State, unknown, Actions> {
  (dispatch: ThunkDispatch<State, unknown, Actions>, getState?: () => State): Return
}

export type AvvirThunk<Return, Event extends Action> = AvvirThunkAction<Return, ReduxStore, Event>
export type AvvirAsyncThunk<Selector extends (...args: any) => any, Event extends Action> = AvvirThunk<Promise<ReturnType<Selector>>, Event>
export type AvvirActionCreator<Return = any, Event extends Action = Action> = (...args: any) => AvvirThunk<Return, Event>
type MakeEventfulActionReturn<Return, Event extends Action, Creator extends AvvirActionCreator<Return, Event>> =
  (...args: Parameters<Creator>) => AvvirThunk<Promise<ThenArgRecursive<Return>>, Event | Action>

const makeEventfulAction = <Return, Event extends Action, Creator extends AvvirActionCreator<Return, Event>>(actionName: string, actionFactory: Creator): MakeEventfulActionReturn<Return, Event, Creator> => {
  return (...args: Parameters<Creator>) => {
    return (dispatch, getState) => {
      dispatch({ type: `${snakeCase(actionName)}_started` });
      const action = actionFactory(...args);

      return (Promise.resolve()
        .then(action.bind(null, dispatch, getState))
        .then((...args) => {
          let payload;
          if (args?.length > 1) {
            payload = args;
          } else {
            payload = args?.[0];
          }
          dispatch({ type: `${snakeCase(actionName)}_done`, payload });
          return payload;
        }) as Promise<ThenArgRecursive<Return>>)
        .catch((error) => {
          dispatch({ type: `${snakeCase(actionName)}_failed`, payload: error });
          throw error;
        });
    };
  };
};

export default makeEventfulAction;
