import { AvvirEvent } from "type_aliases";

export type AnnotationTitleFilterChangedEvent = AvvirEvent<typeof ANNOTATION_TITLE_FILTER_CHANGED, string>

const annotationTitleFilterChanged = (newFilter: string): AnnotationTitleFilterChangedEvent => ({
  type: ANNOTATION_TITLE_FILTER_CHANGED,
  payload: newFilter
});

export const ANNOTATION_TITLE_FILTER_CHANGED = "annotation_title_filter_changed";

export default annotationTitleFilterChanged;
