import { Action } from "redux";

export type BimtrackAssociationDialogDismissedEvent = Action<typeof BIMTRACK_ASSOCIATION_DIALOG_DISMISSED>

const bimtrackAssociationDialogDismissed = (): BimtrackAssociationDialogDismissedEvent => ({
  type: BIMTRACK_ASSOCIATION_DIALOG_DISMISSED
});

export const BIMTRACK_ASSOCIATION_DIALOG_DISMISSED = "bimtrack_association_dialog_dismissed";

export default bimtrackAssociationDialogDismissed;
