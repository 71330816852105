import { createSelector } from "reselect";

import getUserPermissions from "./get_user_permissions";
import { createIsUserPermitted } from "../../security/user_permission_service";

const getIsUserPermitted = createSelector([
  getUserPermissions
], (permissions) => {
  return createIsUserPermitted(permissions);
});

export default getIsUserPermitted;
