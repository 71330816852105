import { ApiView } from "avvir";
import { AvvirEvent } from "type_aliases";

export type ViewsLoadedEvent = AvvirEvent<typeof VIEWS_LOADED, ApiView[]>

const viewsLoaded = (views: ApiView[]): ViewsLoadedEvent => ({
  type: VIEWS_LOADED,
  payload: views
});

export const VIEWS_LOADED = "views_loaded";

export default viewsLoaded;
