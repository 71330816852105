import { AvvirEvent } from "type_aliases";
import TeamMember from "../../models/domain/team_member";

export type TeamMembersUpdatedEvent = AvvirEvent<typeof TEAM_MEMBERS_UPDATED, { projectId: string, teamMembers: TeamMember[] }>

const teamMembersUpdated = (projectId: string, teamMembers: TeamMember[]): TeamMembersUpdatedEvent => ({
  type: TEAM_MEMBERS_UPDATED,
  payload: {
    projectId,
    teamMembers
  }
});

export const TEAM_MEMBERS_UPDATED = "team_members_updated";

export default teamMembersUpdated;
