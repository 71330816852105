import {AvvirPayloadlessRoutingEvent} from "type_aliases";

export type ToPasswordResetConfirmationPageEvent = AvvirPayloadlessRoutingEvent<typeof TO_PASSWORD_RESET_CONFIRMATION_PAGE>

const toPasswordResetConfirmationPage = (): ToPasswordResetConfirmationPageEvent => ({
  type: TO_PASSWORD_RESET_CONFIRMATION_PAGE
});

export const TO_PASSWORD_RESET_CONFIRMATION_PAGE = "to_password_reset_confirmation_page";

export default toPasswordResetConfirmationPage;
