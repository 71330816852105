import { Camera, Euler, Matrix3, Quaternion, Vector2, Vector3 } from "three";
import { Container, Graphics, Rectangle } from "pixi.js";
import UnitConverter from "../../../../../services/converters/unit_converter";
import { PixiApp, PixiUpdatable } from "../../../../../services/utilities/pixi/pixi_updateable";
import { PixiZoomable } from "../../../../../services/utilities/pixi/pixi_zoom_container";
import { transformVector } from "../../../../../services/utilities/threejs_utilities/transform_utilities";

export function getCameraMinimapPosition(width: number, height: number, cameraPosition: Vector3, viewerToPhotoAreaTransform: Matrix3) {
  const pos = transformVector(new Vector2(cameraPosition.x, cameraPosition.y), viewerToPhotoAreaTransform);
  return new Vector2(pos.x * width, pos.y * height);
}

export function getCameraMinimapYaw(orientation: Quaternion): number {
  const q = orientation.clone();
  q.x = 0;
  q.y = 0;
  q.z *= -1;
  q.normalize();

  return new Euler().setFromQuaternion(q).z;
}

export function getCameraMinimapYawRadians(floorYaw: number, orientation: Quaternion) {
  return getCameraMinimapYaw(getFloorCameraMinimapYaw(floorYaw, orientation));
}

export function getFloorCameraMinimapYaw(floorYaw: number, cameraOrientation: Quaternion): Quaternion {
  return new Quaternion().setFromEuler(new Euler(0, 0, floorYaw)).multiply(cameraOrientation)
}

const DEFAULT_COLOR = 0x5c8edc;
const DEFAULT_FOV = Math.PI / 2;
const DEFAULT_ARC_RADIUS = 30;
const DEFAULT_DOT_RADIUS = 4;
const DEFAULT_FILL_OPACITY = 0.6;
const DEFAULT_LINE_WIDTH = 1.5;

export interface FovMarkerApp extends PixiApp {
  viewerToPhotoAreaTransform: Matrix3
  mapBounds: Rectangle
  offsetYaw: number
}

export type PixiFovMarkerOptions = {
  getCamera: () => Camera
  graphics?: Graphics
  fillOpacity?: number
  arcRadius?: number
  dotRadius?: number
  fov?: number
  fovOffset?: number
  color?: number
  lineWidth?: number
  zoomDelta?: number
};

export class CameraFovMarker extends Container implements PixiUpdatable, PixiZoomable {
  private readonly getCamera: () => Camera;
  private readonly graphics: Graphics;
  private readonly lineWidth: number;
  public fillOpacity: number;
  public color: number;
  public fov: number;
  public dotRadius: number;
  public arcRadius: number;
  public fovOffset: number;

  constructor(opts: PixiFovMarkerOptions) {
    super();
    this.getCamera = opts.getCamera;

    this.color = opts.color ? opts.color : DEFAULT_COLOR;
    this.fov = opts.fov ? opts.fov : DEFAULT_FOV;
    this.fovOffset = opts.fovOffset ? opts.fovOffset : 0;
    this.arcRadius = opts.arcRadius ? opts.arcRadius : DEFAULT_ARC_RADIUS;
    this.dotRadius = opts.dotRadius ? opts.dotRadius : DEFAULT_DOT_RADIUS;
    this.fillOpacity = opts.fillOpacity ? opts.fillOpacity : DEFAULT_FILL_OPACITY;
    this.lineWidth = opts.lineWidth ? opts.lineWidth : DEFAULT_LINE_WIDTH;
    this.graphics = opts.graphics == null ? new Graphics() : opts.graphics;
    this.addChild(this.graphics);
    this.drawMarker();
  }

  drawMarker(): void {
    const g = this.graphics;
    const arcRadius = this.arcRadius;
    const halfFov = (this.fov + this.fovOffset) / 2;
    const centerAngle = Math.PI/-2;
    const startAngle = centerAngle - halfFov;
    const endAngle = centerAngle + halfFov;
    const startX = Math.cos(startAngle) * arcRadius;
    const startY = Math.sin(startAngle) * arcRadius;

    g.clear();
    g.lineStyle(this.lineWidth, this.color);
    g.beginFill(this.color, this.fillOpacity);
    g.drawCircle(0, 0, this.dotRadius);
    g.moveTo(0, 0);
    g.lineTo(startX, startY);
    g.arc(0, 0, arcRadius, startAngle, endAngle);
    g.lineTo(0, 0);
    g.endFill();
  }

  update(app: FovMarkerApp) {
    const camera = this.getCamera();
    if (camera == null) {
      this.visible = false;
      return false;
    } else {
      this.visible = true;
    }

    this.updateRotation(app, camera);
    this.updatePosition(app, camera);
    this.updateFov(app, camera);
  }

  updateRotation(app: FovMarkerApp, camera: Camera) {
    const yaw = getCameraMinimapYawRadians(app.offsetYaw, camera.quaternion);
    if (this.rotation !== yaw) {
      this.rotation = yaw;
      app.requestRender();
    }
  }

  updatePosition(app: FovMarkerApp, camera: Camera) {
    const bounds = app.mapBounds;
    const position = getCameraMinimapPosition(bounds.width, bounds.height, camera.position, app.viewerToPhotoAreaTransform);
    if (!(this.x === position.x && this.y === position.y)) {
      this.x = position.x
      this.y = position.y
      app.requestRender();
    }
  }

  updateFov(app: FovMarkerApp, camera: Camera) {
    const inputFov = camera["fov"];
    if (inputFov) {
      // Sometimes the FOV coming from the Forge viewer is a huge number like 2578 degrees and even though the viewer looks fine the minimap needs the input degrees to be
      // something more reasonable like 60 or 90. See https://www.pivotaltracker.com/story/show/186242575.
      const cameraFov = UnitConverter.fromDegreesToRadians(inputFov % 360);
      if (cameraFov !== this.fov) {
        this.fov = cameraFov;
        this.drawMarker();
        app.requestRender();
      }
    }
  }

  zoom(zoom: number): boolean {
    if (this.scale.x !== zoom
        && this.scale.y !== zoom) {
      this.scale.set(zoom);
      return true;
    }

    return false;
  }

}
