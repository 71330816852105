import { createSelector } from "reselect";

import getOrganizationId from "./get_organization_id";
import getOrganizationsByFirebaseId from "./get_organizations_by_firebase_id";

const getOrganization = createSelector([
  getOrganizationsByFirebaseId,
  getOrganizationId,
], (organizationsByFirebaseId, organizationId) => {
  if (organizationId) {
    return organizationsByFirebaseId[organizationId] || null;
  } else {
    return null;
  }
});

export default getOrganization;
