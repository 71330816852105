import { Action } from "redux";

export type IncludeInAnalysisElementsShownEvent = Action<typeof INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN>

const includeInAnalysisElementsShown = (): IncludeInAnalysisElementsShownEvent => ({
  type: INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN,
});

export const INCLUDE_IN_ANALYSIS_ELEMENTS_SHOWN = "include_in_analysis_elements_shown";

export default includeInAnalysisElementsShown;
