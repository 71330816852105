import { AvvirEvent } from "type_aliases";
import { ApiOrganization } from "avvir";

export type OrganizationLoadedEvent = AvvirEvent<typeof ORGANIZATION_LOADED, ApiOrganization>

const organizationLoaded = (organization: ApiOrganization): OrganizationLoadedEvent => ({
  type: ORGANIZATION_LOADED,
  payload: { ...organization }
});

export const ORGANIZATION_LOADED = "organization_loaded";

export default organizationLoaded;
