import { AvvirRoutingEvent } from "type_aliases";

export type ToProjectIntegrationsPageEvent = AvvirRoutingEvent<typeof TO_PROJECT_INTEGRATIONS_PAGE, { projectId: string }>

const toProjectIntegrationsPage = (projectId: string): ToProjectIntegrationsPageEvent => ({
  type: TO_PROJECT_INTEGRATIONS_PAGE,
  payload: { projectId }
});

export const TO_PROJECT_INTEGRATIONS_PAGE = "to_integrations_page";

export default toProjectIntegrationsPage;
