import _ from "underscore";

const empty = new Set<string>();

function getSetOfGlobalIds<Element extends { globalId: string }>(elements: Element[] | { [globalId: string]: Element }): Set<string> {
  if ((Array.isArray(elements) && elements.length) || Object.keys(elements).length) {
    return new Set(_(elements).pluck("globalId"));
  } else {
    return empty;
  }
}

export function getGlobalId(dbId: string | number) {
  return `Obj${dbId}`;
}

export default getSetOfGlobalIds;
