import type { KeysOfType } from "type_aliases";

export { clamp } from "./clamp";
export { difference } from "./difference";
export { reorderList } from "./reorder_list";
export { setIsEqual } from "./set_is_equal";
export { stripUndefinedsFromObject } from "./strip_undefineds_from_object";
export { triggerFileDownload, triggerMultiFileDownload } from "./trigger_file_download";
export { without } from "./without";
export { withPromise } from "./with_promise";

export function toBoolean(object: any): boolean {
  if (typeof object === "string") {
    return object.toLowerCase().indexOf("t") > -1;
  }

  return !!object;
}

export function convertToInt(number: string | number) {
  if (typeof number === "string") {
    return parseInt(number, 10) || null;
  } else {
    return number || null;
  }
}

export function makeMatcher<Type>(field: keyof Type | (keyof Type)[]) {
  if (Array.isArray(field)) {
    return (a: Type, b: Type): boolean => field.every(k => a?.[k] === b?.[k]);
  } else {
    return (a: Type, b: Type) => a?.[field] === b?.[field];
  }
}

export function makeGetter<K extends string>(field: K): <T extends Record<K, any>>(obj: T) => T[K] | "" {
  return (obj) => obj?.[field] || "";
}

export function isUuid(input: string): boolean {
  const anyHex = "[0-9a-fA-F]";
  const matcher = `^${anyHex}{8}-${anyHex}{4}-${anyHex}{4}-${anyHex}{4}-${anyHex}{12}$`;
  return !!input.match(matcher);
}

export function fieldAggregator<Type>(key: KeysOfType<Type, number>) {
  return (acc: number, object: Type): number => {
    if (object[key]) {
      return acc + (object[key] as unknown as number || 0);
    } else {
      return acc;
    }
  };
}
