export function setIsEqual<Type>(setA: Set<Type> | Array<Type>, setB: Set<Type> | Array<Type>) {
  if (Array.isArray(setA)) {
    setA = new Set(setA);
  }
  if (Array.isArray(setB)) {
    setB = new Set(setB);
  }

  if (setA.size !== setB.size) {
    return false;
  } else {
    const b = setB; // required for type safety for some reason...
    return Array.from(setA).every(value => {
      return b.has(value);
    });
  }

}

export default setIsEqual;
