import type { ActionHistoryEntry } from "../../services/reducers/reduce_action_history";
import type { AvvirEvent } from "type_aliases";

export type UndoableActionUndoneEvent = AvvirEvent<typeof UNDOABLE_ACTION_UNDONE, ActionHistoryEntry>

const undoableActionUndone = (action: ActionHistoryEntry): UndoableActionUndoneEvent => ({
  type: UNDOABLE_ACTION_UNDONE,
  payload: action
});

export const UNDOABLE_ACTION_UNDONE = "undoable_action_undone";

export default undoableActionUndone;
