import ApiTradeCost from "../models/api/api_trade_cost";
import { AvvirEvent } from "type_aliases";

export type ReportedQuantityUpdatedEvent = AvvirEvent<typeof REPORTED_QUANTITY_UPDATED, { projectId: string, tradeCost: ApiTradeCost }>

const reportedQuantityUpdated = (projectId: string, tradeCost: ApiTradeCost): ReportedQuantityUpdatedEvent => ({
  type: REPORTED_QUANTITY_UPDATED,
  payload: {
    projectId,
    tradeCost
  }
});

export const REPORTED_QUANTITY_UPDATED = "reported_quantity_updated";

export default reportedQuantityUpdated;
