import { AvvirEvent } from "type_aliases";

export type AnnotationUnhoveredEvent = AvvirEvent<typeof ANNOTATION_UNHOVERED, string>

const annotationUnHovered = (globalId: string): AnnotationUnhoveredEvent => ({
  type: ANNOTATION_UNHOVERED,
  payload: globalId
});

export const ANNOTATION_UNHOVERED = "annotation_unhovered";

export default annotationUnHovered;
