import { AvvirPayloadlessRoutingEvent } from "type_aliases";

export type ToSuperadminOrganizationsEvent = AvvirPayloadlessRoutingEvent<typeof TO_SUPERADMIN_ORGANIZATIONS>

const toSuperadminOrganizations = (): ToSuperadminOrganizationsEvent => ({
  type: TO_SUPERADMIN_ORGANIZATIONS
});

export const TO_SUPERADMIN_ORGANIZATIONS = "to_superadmin_organizations";

export default toSuperadminOrganizations;
