import { AvvirEvent } from "type_aliases";
import { ApiInspectReportEntry } from "avvir";

export type InspectReportEntriesLoadedEvent = AvvirEvent<typeof INSPECT_REPORT_ENTRIES_LOADED, {
  projectId: string,
  inspectReportId: number,
  inspectReportEntries: ApiInspectReportEntry[]
}>

const inspectReportEntriesLoaded = (projectId: string, inspectReportId: number, inspectReportEntries: ApiInspectReportEntry[]) =>({
  type: INSPECT_REPORT_ENTRIES_LOADED,
  payload: {
    projectId,
    inspectReportId,
    inspectReportEntries
  }
})

export const INSPECT_REPORT_ENTRIES_LOADED = "inspect_reports_entries_loaded";

export default inspectReportEntriesLoaded;
