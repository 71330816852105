class NavigationService {
  static navigate(location) {
    window.location = location;
  };

  static reload() {
    window.location.reload();
  }
}

export default NavigationService;
