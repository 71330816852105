import moment, { Moment } from "moment";

import DateConverter from "../../converters/date_converter";

import type { DateLike } from "type_aliases";

function addDateGetterAndSetterToDomainModel(target: object, propertyName: string, constructorValue?: Moment | DateLike) {
  let dateVal;
  Object.defineProperty(target, propertyName, {
    get() {
      return dateVal;
    },
    set(val) {
      if (typeof val === "string") {
        if (DateConverter.isValidDateString(val)) {
          dateVal = DateConverter.localDateToDate(val);
        } else {
          dateVal = moment(val, "MMM D, YYYY").toDate();
        }
      } else if (val instanceof Date) {
        dateVal = val;
      } else if (moment.isMoment(val)) {
        val.toDate();
      } else if (typeof val === "number") {
        dateVal = DateConverter.instantToDate(val) || DateConverter.millisecondsToDate(val) || null;
      } else {
        dateVal = null;
      }
    },
    enumerable: true,
    configurable: true
  });

  target[propertyName] = constructorValue;
}

export default addDateGetterAndSetterToDomainModel;
