import { AvvirEvent } from "type_aliases";

export type ProjectRecipeRemovedEvent = AvvirEvent<typeof PROJECT_RECIPE_REMOVED, { id: number, projectId: string }>

const projectRecipeRemoved = ({ id, projectId }: { id: number, projectId: string } ): ProjectRecipeRemovedEvent => ({
  type: PROJECT_RECIPE_REMOVED,
  payload: {
    id,
    projectId
  }
});

export const PROJECT_RECIPE_REMOVED = "project_recipe_removed";

export default projectRecipeRemoved;
