import { ApiCloudFile } from "avvir";

import { AvvirEvent } from "type_aliases";

export type ScanDatasetFileUploadedEvent = AvvirEvent<typeof SCAN_DATASET_FILE_UPLOADED, {
  scanDatasetId: string,
  file: ApiCloudFile
}>

const scanDatasetFileUploaded = (scanDatasetId: string, file: ApiCloudFile): ScanDatasetFileUploadedEvent => ({
  type: SCAN_DATASET_FILE_UPLOADED,
  payload: {
    scanDatasetId,
    file
  }
});

export const SCAN_DATASET_FILE_UPLOADED = "scan_dataset_file_uploaded";

export default scanDatasetFileUploaded;
