export function withPromise<Type>(): [(result?: Type) => void, (err) => void, Promise<Type>] {
  let onSuccess: (result: Type) => void;
  let onError: (err) => void;
  const completingPromise = new Promise<Type>((success, failure) => {
    onSuccess = success;
    onError = failure;
  });

  return [onSuccess, onError, completingPromise];
}

export default withPromise;
