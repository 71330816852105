import { ApiRecipe } from "avvir";
import { AvvirEvent } from "type_aliases";

export type ProjectRecipesLoadedEvent = AvvirEvent<typeof PROJECT_RECIPES_LOADED, {
  id: string
  recipes: ApiRecipe[]
}>

const projectRecipesLoaded = (projectId: string, recipes: ApiRecipe[]): ProjectRecipesLoadedEvent => ({
  type: PROJECT_RECIPES_LOADED,
  payload: {
    id: projectId,
    recipes
  }
});

export const PROJECT_RECIPES_LOADED = "project_recipes_loaded";

export default projectRecipesLoaded;
