import { IPointData } from "pixi.js";

export interface MapLocation {
  id: number;
  visited: boolean;
  rescale: (scale: IPointData) => void;
  position: IPointData;
}

export default class MapLocationContainer<T extends MapLocation> {
  public mapLocationsById: Map<number, T>;
  public onLocationSelected: (mapLocation: T) => void;
  _selectedId: number;

  constructor(options?: Partial<MapLocationContainer<T>>) {
    const opts = options == null ? {} : options;
    this.mapLocationsById = opts.mapLocationsById || new Map();
    this.onLocationSelected = opts.onLocationSelected;
  }

  addLocation(location: T) {
    this.mapLocationsById.set(location.id, location);
  }

  delete(id: number) {
    this.mapLocationsById.delete(id);
  }

  getById(id: number): T {
    return this.mapLocationsById.get(id);
  }

  update() {

  }

  rescale(zoom: number) {
    this.mapLocationsById.forEach(sprite => {
      sprite.rescale({ x: zoom, y: zoom });
    });
  }

  get selectedId() {
    return this._selectedId;
  }

  set selectedId(id: number) {
    if (id !== this._selectedId) {
      this._selectedId = id;
      const sprite = this.getById(id);
      if (sprite) {
        sprite.visited = true;
      }

      this._selectedId = id;
      if (sprite) {
        this.onLocationSelected(sprite);
      }
    }
  }

  clear() {
    this.mapLocationsById.clear();
  }
}
