import { createSelector } from "reselect";

import getScanDate from "../../scan_dataset_getters/get_scan_date";
import getSelectedElements from "./get_selected_elements";
import PlannedBuildingElement from "../../../../models/domain/planned_building_element";

const empty: PlannedBuildingElement[] = [];

const getSelectedBuiltElements = createSelector([
  getSelectedElements, getScanDate
], (selectedElements, scanDate) => {
  if (selectedElements?.length) {
    const selectedBuiltElements = selectedElements.filter(element => {
      return PlannedBuildingElement.isBuilt(element, scanDate);
    });
    if (selectedBuiltElements.length) {
      return selectedBuiltElements;
    } else {
      return empty;
    }
  } else {
    return empty;
  }
});

export default getSelectedBuiltElements;
