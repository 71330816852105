import { Action } from "redux";

export type ExcludeFromAnalysisElementsShownEvent = Action<typeof EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN>

const excludeFromAnalysisElementsShown = (): ExcludeFromAnalysisElementsShownEvent => ({
  type: EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN,
});

export const EXCLUDE_FROM_ANALYSIS_ELEMENTS_SHOWN = "exclude_from_analysis_elements_shown";

export default excludeFromAnalysisElementsShown;
