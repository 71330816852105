import { Reducer } from "redux";
import _ from "underscore";

import FileInformationConverter from "../../converters/file_information_converter";
import makeUpdateModel from "./make_update_model";
import PhotoArea from "../../../models/domain/photos/photo_area";
import { PHOTO_AREA_FILE_UPLOADED, PhotoAreaFileUploadedEvent } from "../../../events/files/upload/photo_area_file_uploaded";
import { PHOTO_AREA_FILES_LOADED, PhotoAreaFilesLoadedEvent } from "../../../events/loaded/photos/photo_area_files_loaded";
import { PHOTO_AREAS_LOADED, PhotoAreasLoadedEvent } from "../../../events/loaded/photos/photo_areas_loaded";

type PhotoAreasEvents =
    | PhotoAreasLoadedEvent
    | PhotoAreaFileUploadedEvent
    | PhotoAreaFilesLoadedEvent;

export type PhotoAreasStore = {
  byId: {
    [id: number]: PhotoArea
  }
}

const updatePhotoArea = makeUpdateModel<PhotoArea>(new PhotoArea());

const reducePhotoAreas: Reducer<PhotoAreasStore, PhotoAreasEvents> = (photoAreas: PhotoAreasStore = { byId: {} }, event) => {
  switch (event?.type) {
    case PHOTO_AREAS_LOADED: {
      return event.payload.photoAreas.reduce((photoAreasSoFar, photoArea) => {
        return updatePhotoArea(photoAreasSoFar, photoArea.id, {
          ...photoArea,
          firebaseProjectId: event.payload.projectId
        });
      }, photoAreas);
    }
    case PHOTO_AREA_FILE_UPLOADED: {
      return updatePhotoArea(photoAreas, event.payload.photoAreaId, (photoArea) => {
        return {
          id: event.payload.photoAreaId,
          files: {
            ...photoArea.files,
            [event.payload.purposeType]: _.union(photoArea.files?.[event.payload.purposeType], [event.payload.file.id])
          }
        };
      });
    }
    case PHOTO_AREA_FILES_LOADED: {
      return updatePhotoArea(photoAreas, event.payload.photoAreaId, (photoArea) => ({
        ...photoArea,
        id: event.payload.photoAreaId,
        files: {
          ...photoArea.files,
          ...FileInformationConverter.fileIdsFromApi(event.payload)
        }
      }));
    }
    default: {
      return photoAreas;
    }
  }
};

export default reducePhotoAreas;

