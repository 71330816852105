import { combineReducers, Reducer } from "redux";
import { LocationState } from "redux-first-router";
import _ from "underscore";

import domainReducer, { DomainStore } from "./domain/domain_reducer";
import reduceActionHistory, { ActionHistoryEntry, ActionHistoryStore } from "./reduce_action_history";
import reduceBimtrackAssociationDialog, { BimtrackAssociationDialogStore } from "./reduce_bimtrack_association_dialog";
import reduceBimtrackIssueCreationDialog, { BimtrackIssueCreationDialogStore } from "./reduce_bimtrack_issue_dialog";
import reduceElementSelection, { ElementSelection } from "./reduce_element_selection";
import reduceFilter, { FilterStore } from "./filter/reduce_filter";
import reduceInvitation, { InvitationStore } from "./reduce_invitation";
import reduceLocationMetadata, { LocationMetadataStore } from "./reduce_location_metadata";
import reducePushToProcoreDialog, { PushToProcoreDialogStore } from "./reduce_push_to_procore_dialog";
import reduceRunningProcesses, { RunningProcessesStore } from "./reduce_running_processes";
import reduceSelection, { SelectionStore } from "./reduce_selection";
import reduceToastNotification, { ToastNotificationStore } from "./reduce_toast_notification";
import reduceUserSession, { UserSessionStore } from "./reduce_user_session";
import reduceViewer, { ViewerStore } from "./reduce_viewer";
import { reduceLocation } from "../../router_redux_config";
import { UNDOABLE_ACTION_REDONE, UndoableActionRedoneEvent } from "../../events/undo/undoable_action_redone";
import { UNDOABLE_ACTION_UNDONE, UndoableActionUndoneEvent } from "../../events/undo/undoable_action_undone";
import { NestedKeyOf, UndoableReduxStore } from "type_aliases";

export interface ReduxStore {
  domain: DomainStore;

  selection: SelectionStore;
  elementSelection: ElementSelection;
  actionHistory: ActionHistoryStore;
  filter: FilterStore;
  viewer: ViewerStore;

  pushToProcoreDialog: PushToProcoreDialogStore;
  bimtrackAssociationDialog: BimtrackAssociationDialogStore;
  bimtrackIssueCreationDialog: BimtrackIssueCreationDialogStore;
  runningProcesses: RunningProcessesStore;
  location: LocationState;
  locationMetadata: LocationMetadataStore;
  toastNotification: ToastNotificationStore | null;
  invitation: InvitationStore;
  user: UserSessionStore;
}

const rootReducer: Reducer<ReduxStore> = combineReducers({
  domain: domainReducer,

  selection: reduceSelection,
  elementSelection: reduceElementSelection,
  actionHistory: reduceActionHistory,
  filter: reduceFilter,
  viewer: reduceViewer,

  pushToProcoreDialog: reducePushToProcoreDialog,
  bimtrackAssociationDialog: reduceBimtrackAssociationDialog,
  bimtrackIssueCreationDialog: reduceBimtrackIssueCreationDialog,
  runningProcesses: reduceRunningProcesses,
  location: reduceLocation,
  locationMetadata: reduceLocationMetadata,
  toastNotification: reduceToastNotification,
  invitation: reduceInvitation,
  user: reduceUserSession
});

const replaceSliceAtLocation = (store: ReduxStore, location: NestedKeyOf<UndoableReduxStore>, slice: any) => {
  const storeAtLocation = location.slice(0, -1).reduce((locationSoFar, location) => {
    locationSoFar[location] = { ...locationSoFar[location] };
    return locationSoFar[location];
  }, store);

  storeAtLocation[_.last(location)] = slice;
  return { ...store };
};

export const redoEvent = (store: ReduxStore, event: ActionHistoryEntry): ReduxStore => {
  return replaceSliceAtLocation(store, event.action.location, event.nextState);
};

export const undoEvent = (store: ReduxStore, event: ActionHistoryEntry): ReduxStore => {
  return replaceSliceAtLocation(store, event.action.location, event.previousState);
};

const undoableRootReducer: Reducer<ReduxStore, UndoableActionUndoneEvent | UndoableActionRedoneEvent> = (store, event) => {
  switch (event.type) {
    case UNDOABLE_ACTION_UNDONE: {
      store = undoEvent(store, event.payload);
      break;
    }
    case UNDOABLE_ACTION_REDONE: {
      store = redoEvent(store, event.payload);
      break;
    }
  }

  return rootReducer(store, event);
};

export default undoableRootReducer;
