import { AvvirEvent } from "type_aliases";
import { ApiInspectReport } from "avvir";

export type InspectReportUpdatedEvent = AvvirEvent<typeof INSPECT_REPORT_UPDATED, { inspectReport: ApiInspectReport }>

const inspectReportDeleted = (inspectReport: ApiInspectReport): InspectReportUpdatedEvent =>({
  type: INSPECT_REPORT_UPDATED,
  payload: {
    inspectReport
  }
})

export const INSPECT_REPORT_UPDATED = "inspect_report_updated";

export default inspectReportDeleted;
