import { AvvirEvent } from "type_aliases";
import { ApiRecipe } from "avvir";

export type RecipeUpdatedEvent = AvvirEvent<typeof RECIPE_UPDATED, { recipe: ApiRecipe }>

const recipeUpdated = ( recipe: ApiRecipe ): RecipeUpdatedEvent => ({
  type: RECIPE_UPDATED,
  payload: {
    recipe
  }
});

export const RECIPE_UPDATED = "recipe_updated";

export default recipeUpdated;
