import { AvvirEvent } from "type_aliases";
import ProjectReport from "../../../models/domain/project_report";

export type ProjectReportUpdatedEvent = AvvirEvent<typeof PROJECT_REPORT_UPDATED, {projectId: string, report: ProjectReport}>

const projectReportUpdated = (projectId: string, projectReport : ProjectReport): ProjectReportUpdatedEvent => ({
  type: PROJECT_REPORT_UPDATED,
  payload: {
    projectId,
    report: projectReport
  }
});

export const PROJECT_REPORT_UPDATED = "project_report_updated";

export default projectReportUpdated;
