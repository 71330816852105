import { AvvirEvent } from "type_aliases";

export type FileDeletedEvent = AvvirEvent<typeof FILE_DELETED, { projectId: string, fileId: number }>

const fileDeleted = (projectId: string, fileId: number): FileDeletedEvent => ({
  type: FILE_DELETED,
  payload: {
    projectId,
    fileId
  }
});

export const FILE_DELETED = "file_deleted";

export default fileDeleted;
