import { AvvirEvent } from "type_aliases";

export type MasterformatSelection = {
  masterformat?: string,
  recipeId?: number
  recipeStepId?: number
};

export type MasterformatSelectedEvent = AvvirEvent<typeof MASTERFORMAT_SELECTED, MasterformatSelection>

const masterformatSelected = (selection: MasterformatSelection): MasterformatSelectedEvent => ({
  type: MASTERFORMAT_SELECTED,
  payload: selection
});

export const MASTERFORMAT_SELECTED = "masterformat_selected";

export default masterformatSelected;
