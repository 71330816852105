import { Action } from "redux";

export type DetectedObstructingElementsHiddenEvent = Action<typeof DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN>

const detectedObstructingElementsHidden = (): DetectedObstructingElementsHiddenEvent => ({
  type: DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN,
});

export const DETECTED_OBSTRUCTING_ELEMENTS_HIDDEN = "detected_clashes_hidden";

export default detectedObstructingElementsHidden;
