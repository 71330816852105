import { Reducer } from "redux";

import BimtrackProject from "../../models/api/bimtrack_project";
import PlannedBuildingElement from "../../models/domain/planned_building_element";
import { BIMTRACK_ISSUE_CREATION_DIALOG_DISMISSED, BimtrackIssueCreationDialogDismissedEvent } from "../../events/viewer/bimtrack_issue_creation_dialog_dismissed";
import { BIMTRACK_ISSUE_CREATION_DIALOG_SHOWN, BimtrackIssueCreationDialogShownEvent } from "../../events/viewer/bimtrack_issue_creation_dialog_shown";
import { BIMTRACK_PROJECT_LOADED, BimtrackProjectLoadedEvent } from "../../events/loaded/bimtrack_project_loaded";
import { isRoutingEvent } from "../routing_events";


export type BimtrackIssueCreationDialogStore = {
  open: boolean
  project?: BimtrackProject
  selectedElement?: PlannedBuildingElement
  globalId?: string
}

type Events =
    | BimtrackIssueCreationDialogShownEvent
    | BimtrackIssueCreationDialogDismissedEvent
    | BimtrackProjectLoadedEvent

const reduceBimtrackIssueCreationDialog: Reducer<BimtrackIssueCreationDialogStore, Events> = (bimtrackIssueCreationDialog = { open: false }, event) => {
  if (isRoutingEvent(event)) {
    return { open: false };
  }
  switch (event.type) {
    case BIMTRACK_ISSUE_CREATION_DIALOG_SHOWN: {
      return {
        ...bimtrackIssueCreationDialog,
        open: true,
        globalId: event.payload.globalId,
        selectedElement: event.payload.selectedElement
      };
    }
    case BIMTRACK_ISSUE_CREATION_DIALOG_DISMISSED: {
      return {
        ...bimtrackIssueCreationDialog,
        open: false,
      };
    }
    case BIMTRACK_PROJECT_LOADED: {
      return {
        ...bimtrackIssueCreationDialog,
        project: event.payload
      }
    }

    default: {
      return bimtrackIssueCreationDialog;
    }
  }
};

export default reduceBimtrackIssueCreationDialog;
