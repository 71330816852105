import { Box2, Matrix3, Vector2 } from "three";
import ColorLib from "tinycolor2";

import { isCanvasFontStyle } from "./custom_type_guards";

type ImageArgument = CanvasImageSource & { width: number, height: number };

export type CanvasFontStyle = {
  color: ColorLib.ColorInput,
  size: number,
  name: string
  weight: number | "normal" | "bold"
}

export default class CanvasHelper {
  constructor(canvas?: HTMLCanvasElement) {
    if (canvas) {
      this.canvas = canvas;
    } else {
      this.canvas = document.createElement("canvas");
    }
    this.context = this.canvas.getContext("2d");
    this.transform = new Matrix3().identity();
    this.setTextStyle("#000", 10, "sans-serif");

    this.getActualCanvasSize();
  }

  /**
   * Sizes the canvas so that its internal pixel resolution = css resolution * devicePixelRatio (for e.g. retina screens)
   */
  private getActualCanvasSize() {
    const ratio = window.devicePixelRatio || 1;
    const rect = this.canvas.getBoundingClientRect();
    // These should be read only everywhere outside of this function
    // @ts-ignore
    this.width = rect?.width || 1;
    // @ts-ignore
    this.height = rect?.height || 1;
    this.canvas.width = this.width * ratio;
    this.canvas.height = this.height * ratio;
    this.scale = ratio;
    this.transform.copy(new Matrix3().identity()).scale(ratio, ratio);
    // This ensures that 1 pixel in the canvas = 1 css pixel
    this.context.scale(ratio, ratio);
  };

  /**
   * Resizes the canvas to the provided width and height. Doesn't transform what the canvas is rendering.
   * Doesn't change the canvas css (so size on page may remain unchanged.)
   */
  resize(width: number, height: number) {
    this.canvas.width = width * this.scale;
    this.canvas.height = height * this.scale;
  }

  /**
   * Clears the canvas and draws the given image.
   *
   * Sizes the canvas to be the same size as the given image.
   */
  setCanvasImage(image: ImageArgument) {
    this.clear();
    this.canvas.width = image.width;
    this.canvas.height = image.height;
    this.drawImage(image, 0, 0);
  };

  /** Draws the given image full sized and at the given x and y coordinates. */
  drawImage(image: ImageArgument, x: number, y: number) {
    this.context.drawImage(image, x, y);
  }

  /**
   * Draws an image at the provided coordinates.
   *
   * Note that if the imageBox has a different aspect ratio than the image, the image will be distorted.
   */
  drawImageInBox(image: ImageArgument, imageBox: Box2) {
    this.context.drawImage(image,
      0,
      0,
      image.width,
      image.height,
      imageBox.min.x,
      imageBox.min.y,
      imageBox.max.x - imageBox.min.x,
      imageBox.max.y - imageBox.min.y);
  };

  drawZoomedImageInBox(image: ImageArgument, imageBox: Box2, anchor: Vector2, zoom: number = 4) {
    const imageWidth = image.width;
    const imageHeight = image.height;
    const dWidth = imageBox.max.x - imageBox.min.x;
    const dHeight = imageBox.max.y - imageBox.min.y;
    const widthRatio = imageWidth / dWidth;
    const heightRatio = imageHeight / dHeight;
    const sWidth = (dWidth * widthRatio) / zoom;
    const sHeight = (dHeight * heightRatio) / zoom;
    const sx = Math.max((anchor.x * widthRatio) - (sWidth / 2), 0);
    const sy = Math.max((anchor.y * heightRatio) - (sHeight / 2), 0);

    this.context.drawImage(image,
      sx,
      sy,
      sWidth,
      sHeight,
      imageBox.min.x,
      imageBox.min.y,
      dWidth,
      dHeight);
  };

  /**
   *
   */
  drawImageInCroppedBox(image: ImageArgument, anchor:Vector2, imageBox: Box2) {
    //drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
    let width = imageBox.max.x - imageBox.min.x;
    let height = imageBox.max.y - imageBox.min.y;
    let sx = Math.max(anchor.x - Math.floor(width/2), 0);
    let sy = Math.max(anchor.y - Math.floor(height/2), 0);
    this.context.drawImage(
      image,
      sx,
      sy,
      width,
      height,
      imageBox.min.x,
      imageBox.min.y,
      width,
      height
    )
  }

  clear() {
    this.context.save();
    // reset the transform so that the rect we're clearing doesn't get transformed as well;
    this.setTransform();
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.restore();
  }

  /**
   * Returns a data url for the current content of the canvas
   * @param type - defaults to "image/png"
   */
  getDataUrl(type?: string) {
    return this.canvas.toDataURL(type || "image/png");
  }

  /**
   * Calculate the lines of text given the words and the max allowed width of the text
   * @param text The text to line wrap (breaks on whitespace)
   * @param maxLineWidth The max allowed width of the text in canvas pixels. The canvas' current transform will
   *                     be applied to the text during this calculation.
   * @returns An array of the lines of text, such that each line is as long as possible without exceeding the max width.
   */
  wrapText(text: string, maxLineWidth: number): string[] {
    let wrappedLines = [];

    let currentLineWords = [];
    for (let word of text.split(" ")) {
      currentLineWords.push(word);
      let currentLineWidth = this.context.measureText(currentLineWords.join(" ")).width;
      if (currentLineWidth >= maxLineWidth) {
        let lineLeftovers = currentLineWords.pop();
        wrappedLines.push(currentLineWords.join(" "));
        currentLineWords = [lineLeftovers];
      }
    }

    // Add whatever is left in the last line to the result
    if (currentLineWords.length) {
      wrappedLines.push(currentLineWords.join(" "));
    }

    return wrappedLines;
  }

  setTextStyle(style: CanvasFontStyle)
  setTextStyle(color: ColorLib.ColorInput, size: number, font: string, weight?: number | "bold" | "normal")
  setTextStyle(color: ColorLib.ColorInput | CanvasFontStyle, size?: number, font?: string, weight?: number | "bold" | "normal") {
    if (isCanvasFontStyle(color)) {
      size = color.size;
      font = color.name;
      weight = color.weight;
      color = color.color;
    }
    if (color != null) {
      this.textColor = new ColorLib(color);
    }
    if (size != null) {
      this.textSize = size;
    }
    if (font != null) {
      this.textFont = font;
    }
    if (weight != null) {
      this.textWeight = weight || null;
    }
    if (size != null || font != null || weight != null) {
      this.context.font = this.buildFontString(this.textSize, this.textFont, this.textWeight);
    }
  }

  writeText(text: string, positionX: number, positionY: number, alignment: CanvasTextBaseline = "alphabetic", justification: CanvasTextAlign = "start", color?: ColorLib.ColorInput, size?: number, font?: string, weight?: number | "bold" | "normal", stroke?: ColorLib.ColorInput) {
    this.context.save();
    if (alignment != null) {
      this.context.textBaseline = alignment;
    }
    if (justification != null) {
      this.context.textAlign = justification;
    }
    this.drawText(text, positionX, positionY, color, size, font, weight, stroke);
    this.context.restore();
  }

  private drawText(text, x: number, y: number, color: ColorLib.ColorInput, size: number, font: string, weight: number | "bold" | "normal", stroke: ColorLib.ColorInput) {
    this.setTextStyle(color, size, font, weight);
    if (this.textColor != null) {
      this.context.fillStyle = this.textColor.toRgbString();
    } else {
      this.context.fillStyle = new ColorLib("#000").toRgbString();
    }
    this.context.fillText(text, x, y);
    if (stroke != null) {
      this.context.strokeStyle = new ColorLib(stroke).toRgbString();
      this.context.strokeText(text, x, y);
    }
  }

  private buildFontString(size: number, font: string, weight: number | "normal" | "bold") {
    let fontString = "";
    if (weight != null) {
      fontString = `${weight} `;
    } else if (this.textWeight != null) {
      fontString = `${this.textWeight} `;
    }
    if (size != null) {
      fontString += `${size}px `;
    } else {
      fontString += `${this.textSize}px `;
    }
    if (font != null) {
      fontString += font;
    } else {
      fontString += this.textFont;
    }

    return fontString;
  }

  drawCircle(x: number, y: number, radius: number, fill?: ColorLib.ColorInput, stroke?: ColorLib.ColorInput, lineWidth?: number) {
    this.context.save();
    this.context.beginPath();
    this.context.ellipse(x, y, radius, radius, 0, 0, Math.PI * 2);
    this.draw(fill, stroke, lineWidth);
    this.context.restore();
  }

  drawRect(x: number, y: number, width: number, height: number, radius?: number, fill?: ColorLib.ColorInput, stroke?: ColorLib.ColorInput, lineWidth?: number) {
    this.context.save();
    this.context.beginPath();
    if (radius) {
      this.context.roundRect(x, y, width, height, radius);
    } else {
      this.context.rect(x, y, width, height);
    }
    this.draw(fill, stroke, lineWidth);
    this.context.restore();
  }

  drawLine(startX, startY, endX, endY, color?: ColorLib.ColorInput, lineWidth?: number) {
    let strokeColor: ColorLib.ColorInput;
    if (color != null) {
      strokeColor = color;
    } else {
      strokeColor = "#000";
    }
    this.context.save();
    this.context.beginPath();
    this.context.moveTo(startX, startY);
    this.context.lineTo(endX, endY);
    this.draw(null, strokeColor, lineWidth);
    this.context.restore();
  }

  private draw(fill?: ColorLib.ColorInput, stroke?: ColorLib.ColorInput, lineWidth?: number) {
    if (fill != null) {
      this.context.fillStyle = new ColorLib(fill).toRgbString();
      this.context.fill();
    }
    if (stroke != null) {
      if (lineWidth != null) {
        this.context.lineWidth = lineWidth;
      } else {
        this.context.lineWidth = 1;
      }
      this.context.strokeStyle = new ColorLib(stroke).toRgbString();
      this.context.stroke();
    }
  }

  /**
   * Sets the internal transform of the canvas.
   * This transform is applied to anything drawn in the canvas space before it gets rendered in the screen space.
   */
  setTransform(matrix?: Matrix3) {
    // Make sure that the scale of the canvas matches its pixel ratio
    this.getActualCanvasSize();
    if (matrix) {
      this.transform.multiply(matrix);
    }
    // NOTE(dlb): You can thank Canvas2D's ridiculous transform API for this nonsense
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/transform
    let [m11, m12, , m21, m22, , dx, dy, w] = this.transform.elements;

    m11 = m11 / w;
    m12 = m12 / w;
    m21 = m21 / w;
    m22 = m22 / w;
    dx = dx / w;
    dy = dy / w;
    this.context.setTransform(m11, m12, m21, m22, dx, dy);
  }

  async toBlob(type: string = "image/png"): Promise<Blob> {
    return new Promise((resolve) => {
      this.canvas.toBlob(resolve, type);
    });
  }

  readonly canvas: HTMLCanvasElement;
  readonly context: CanvasRenderingContext2D;
  /** the width of the canvas in css pixels */
  readonly width: number;
  /** the height of the canvas in css pixels */
  readonly height: number;
  private textColor: ColorLib.Instance;
  private textFont: string;
  private textSize: number;
  private textWeight: number | "bold" | "normal";
  private readonly transform: Matrix3;
  private scale: number;
}
