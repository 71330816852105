import { AvvirRoutingEvent } from "type_aliases";

export type ToHxAuthLoginPageEvent = AvvirRoutingEvent<typeof TO_HX_AUTH_LOGIN_PAGE, {query: {[key: string]: string}}>;

const toHxAuthLoginPage = (query: {[key: string]: string}): ToHxAuthLoginPageEvent => ({
  type: TO_HX_AUTH_LOGIN_PAGE,
  payload: { query }
});

export const TO_HX_AUTH_LOGIN_PAGE = "to_hx_auth_login_page";

export default toHxAuthLoginPage;
