import { Action, connectRoutes, redirect } from "redux-first-router";
import { createBrowserHistory as createHistory } from "history";
import queryString from "query-string";

import routes from "./routes";

const routesReduxConfig = connectRoutes(routes, {
  title: (state) => state.locationMetadata.pageTitle || "Portal",
  querySerializer: queryString,
  createHistory,
  onBeforeChange: (dispatch, getState, { action }) => {
    if (action.type.includes("_deprecated")) {
      let notDeprecated = action.type.substr(0, action.type.length - "_deprecated".length);
      dispatch(redirect({
        ...action,
        type: notDeprecated
      } as Action));
    }
  }
});
export const reduceLocation = routesReduxConfig.reducer;
export const routesMiddleware = routesReduxConfig.middleware;
export const routesEnhancer = routesReduxConfig.enhancer;
