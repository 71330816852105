import { Action } from "redux";

export type IncludedElementsShownEvent = Action<typeof INCLUDED_ELEMENTS_SHOWN>

const includedElementsShown = (): IncludedElementsShownEvent => ({
  type: INCLUDED_ELEMENTS_SHOWN,
});

export const INCLUDED_ELEMENTS_SHOWN = "included_elements_shown";

export default includedElementsShown;