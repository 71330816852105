import BimtrackAssociation from "../../models/domain/bimtrack/bimtrack_association";
import { AvvirEvent } from "type_aliases";

export type BimtrackAssociationCreatedEvent = AvvirEvent<typeof BIMTRACK_ASSOCIATION_CREATED, BimtrackAssociation>

const bimtrackAssociationCreated = (association: BimtrackAssociation): BimtrackAssociationCreatedEvent => ({
  type: BIMTRACK_ASSOCIATION_CREATED,
  payload: association
});

export const BIMTRACK_ASSOCIATION_CREATED = "bimtrack_association_created";

export default bimtrackAssociationCreated;
