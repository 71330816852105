import useQueryWithState from "../use_query_with_state";
import loadScanDatasetsForFloor from "../../actions/react_query/scan_datasets/load_scan_datasets_for_floor";
import getScanDatasetsForFloorByFirebaseId from "../../services/getters/scan_dataset_getters/get_scan_datasets_for_floor_by_firebase_id";

const useScanDatasetsForFloor = (projectId: string, floorId: string) => {
  return useQueryWithState(["useScanDatasetsForFloor", [projectId, floorId]],
    loadScanDatasetsForFloor,
    getScanDatasetsForFloorByFirebaseId,
    { enabled: !!floorId && !!projectId },
    { projectId, floorId },
    { projectId, floorId });
};

export default useScanDatasetsForFloor;
