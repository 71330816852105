import { AvvirEvent } from "type_aliases";

export type PhotoLocationSelectedEvent = AvvirEvent<typeof PHOTO_LOCATION_SELECTED, { photoLocationId: number, fenceSelectedPhotoLocationIds?: number[] }>

const photoLocationSelected = (photoLocationId: number, fenceSelectedPhotoLocationIds?: number[]): PhotoLocationSelectedEvent => ({
  type: PHOTO_LOCATION_SELECTED,
  payload: {
    photoLocationId,
    fenceSelectedPhotoLocationIds
  }
});

export const PHOTO_LOCATION_SELECTED = "photo_location_selected";

export default photoLocationSelected;
