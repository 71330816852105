import { Matrix3, Vector2 } from "three";
import { immerable } from "immer";
import { ApiConstructionGrid, ApiMasterformatProgress, ApiPipeline, ApiUserAction, FloorPurposeType } from "avvir";

import addDateGetterAndSetterToDomainModel from "../../services/utilities/mixins/add_date_getter_and_setter_to_domain_model";
import addPropertiesIfDefined from "../../services/utilities/mixins/add_properties_if_defined";
import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";
import { groupProgressesByMasterformatCodeAndSortByDate } from "./progress/masterformat_progress";

import type { AvvirApiFiles, AvvirFileIds, AvvirFiles, DateLike, Matrix3Like, Modify, Vector2Like } from "type_aliases";
import type { MasterformatProgressDictionary } from "./progress";

export type FloorArgument = Partial<Modify<Floor, {
  scanDate?: DateLike
  files?: AvvirFiles<FloorPurposeType> | AvvirApiFiles<FloorPurposeType> | AvvirFileIds<FloorPurposeType>
  offset?: Vector2Like
  photoAreaMinimapPixelToBimMinimapPixel?: Matrix3Like
  bimMinimapToWorld?: Matrix3Like,
  scannedProjectMasterformatProgresses?: ApiMasterformatProgress[] | null,
  baselineProjectMasterformatProgresses?: ApiMasterformatProgress[] | null,
  currentProjectMasterformatProgresses?: ApiMasterformatProgress[] | null,
}>>

export default class Floor {
  constructor({
    id,
    firebaseId,
    firebaseProjectId,
    floorNumber,
    ordinal,
    defaultFirebaseScanDatasetId,
    scanDate,
    firebaseScanDatasetIds,
    plannedElementsExist,
    constructionGrid,
    files,
    photoAreaId,
    elementTradeCodesInBim,
    offset,
    photoAreaMinimapPixelToBimMinimapPixel,
    bimMinimapToWorld,
    floorElevation,
    globalOffsetYaw,
    pipelines,
    elementActionsByGlobalId,
    obstructions,
    scannedProjectMasterformatProgresses,
    baselineProjectMasterformatProgresses,
    currentProjectMasterformatProgresses
  }: FloorArgument = {}) {
    addDateGetterAndSetterToDomainModel(this, "scanDate");
    addReadOnlyPropertiesToModel(this, { id, firebaseId, firebaseProjectId, plannedElementsExist });
    let offsetVal: Vector2, photoMinimapToBimMinimapTransformVal: Matrix3, bimMinimapToWorldTransformVal: Matrix3;
    Object.defineProperties(this, {
      offset: {
        get() {
          return offsetVal;
        },
        set(val: Vector2Like) {
          if (val instanceof Vector2) {
            offsetVal = val;
          } else if (val) {
            offsetVal = new Vector2(val.x, val.y);
          } else {
            offsetVal = null;
          }
        },
        enumerable: true
      },
      photoAreaMinimapPixelToBimMinimapPixel: {
        get() {
          return photoMinimapToBimMinimapTransformVal;
        },
        set(val: Matrix3Like) {
          if (val instanceof Matrix3) {
            photoMinimapToBimMinimapTransformVal = val;
          } else if (val) {
            photoMinimapToBimMinimapTransformVal = new Matrix3().set(val.x1, val.x2, val.x3,
              val.y1, val.y2, val.y3,
              val.z1, val.z2, val.z3
            );
          } else {
            photoMinimapToBimMinimapTransformVal = null;
          }
        }
      },
      bimMinimapToWorld: {
        get() {
          return bimMinimapToWorldTransformVal;
        },
        set(val: Matrix3Like) {
          if (val instanceof Matrix3) {
            bimMinimapToWorldTransformVal = val;
          } else if (val) {
            bimMinimapToWorldTransformVal = new Matrix3().set(
              val.x1, val.x2, val.x3,
              val.y1, val.y2, val.y3,
              val.z1, val.z2, val.z3
            );
          } else {
            bimMinimapToWorldTransformVal = null;
          }
        }
      }
    });

    this.ordinal = ordinal || 1;
    this.floorNumber = floorNumber || "";
    // @ts-ignore
    this.scanDate = scanDate;
    this.firebaseScanDatasetIds = firebaseScanDatasetIds || [];
    // @ts-ignore
    this.files = files || {};
    // @ts-ignore
    this.offset = offset;
    // @ts-ignore
    this.photoAreaMinimapPixelToBimMinimapPixel = photoAreaMinimapPixelToBimMinimapPixel;
    // @ts-ignore
    this.bimMinimapToWorld = bimMinimapToWorld;
    this.elementActionsByGlobalId = elementActionsByGlobalId || {};
    this.masterformatProgress.scanned = groupProgressesByMasterformatCodeAndSortByDate(scannedProjectMasterformatProgresses || []);
    this.masterformatProgress.baseline = groupProgressesByMasterformatCodeAndSortByDate(baselineProjectMasterformatProgresses || []);
    this.masterformatProgress.current = groupProgressesByMasterformatCodeAndSortByDate(currentProjectMasterformatProgresses || []);

    if (elementTradeCodesInBim == null) {
      this.elementTradeCodesInBim = {};
    } else {
      this.elementTradeCodesInBim = elementTradeCodesInBim;
    }

    addPropertiesIfDefined<Floor>(this, {
      defaultFirebaseScanDatasetId,
      constructionGrid,
      photoAreaId,
      floorElevation,
      globalOffsetYaw,
      obstructions,
      pipelines
    });
  }

  readonly id: number;
  readonly firebaseId: string;
  readonly firebaseProjectId: string;
  floorNumber: string;
  ordinal: number;
  defaultFirebaseScanDatasetId: string;
  scanDate: Date;
  firebaseScanDatasetIds: string[];
  readonly plannedElementsExist: boolean;
  constructionGrid: ApiConstructionGrid;
  files: AvvirFileIds<FloorPurposeType>;
  photoAreaId: number;

  elementTradeCodesInBim: {[code:string]: boolean}
  readonly offset: Vector2;
  photoAreaMinimapPixelToBimMinimapPixel: Matrix3;
  bimMinimapToWorld: Matrix3;
  floorElevation: number;
  globalOffsetYaw?: number;
  pipelines?: ApiPipeline[];
  elementActionsByGlobalId: { [globalId: string]: ApiUserAction[] };
  obstructions: {
    byObstructingElementGlobalId: {
      [globalId: string]: string[]
    }
  };

  masterformatProgress: MasterformatProgressDictionary = { scanned: {}, baseline: {}, current: {} };

  static readonly [immerable] = true;
}
