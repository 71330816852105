import ProjectListing from "../../../models/domain/project_listing";
import { AvvirEvent } from "type_aliases";

export type ProjectListingsLoadedEvent = AvvirEvent<typeof PROJECT_LISTINGS_LOADED, { projectListings: ProjectListing[] }>
export const PROJECT_LISTINGS_LOADED = "project_listings_loaded";

const projectListingsLoaded = (projectListings: ProjectListing[]): ProjectListingsLoadedEvent => ({
  type: PROJECT_LISTINGS_LOADED,
  payload: {
    projectListings: projectListings
  }
});
export default projectListingsLoaded;