import { immerable } from "immer";

import addReadOnlyPropertiesToModel from "../../services/utilities/mixins/add_read_only_properties_to_model";

export default class ApiTrade {
  constructor({ code, parentCode, title, workPackageId, childCodes }: Partial<ApiTrade> = {}) {
    addReadOnlyPropertiesToModel(this, { code });
    if (parentCode) {
      this.parentCode = parentCode;
    }
    this.title = title;
    this.workPackageId = workPackageId;
    this.childCodes = childCodes || [];
  }

  readonly code: string;
  parentCode: string;
  title: string;
  workPackageId: number;
  childCodes: string[];

  static readonly [immerable] = true;
}
