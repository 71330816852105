import { Action } from "redux";

export type InPlaceElementsShownEvent = Action<typeof IN_PLACE_ELEMENTS_SHOWN>

const inPlaceElementsShown = (): InPlaceElementsShownEvent => ({
  type: IN_PLACE_ELEMENTS_SHOWN,
});

export const IN_PLACE_ELEMENTS_SHOWN = "in_place_elements_shown";

export default inPlaceElementsShown;