import { FixedSizeSprite } from "../layers_shared/fixed_size_sprite";
import { MapLocation } from "../layers_shared/map_location_container";
import { calculatePhotoLocationCoords } from "../photos_layer/photo_sprite";
import { PixiMinimapTransforms } from "../pixi_minimap_app";
import PlannedBuildingElement from "../../../../../models/domain/planned_building_element";
import { ClashingElementStyles } from "./clashing_element_styles";
import ModelExplorer from "../../../../../services/autodesk_forge_services/model_explorer";
import { Vector3 } from "three";
import { getDbId } from "../../../../../services/utilities/get_set_of_db_ids";

export default class ClashingElementSprite extends FixedSizeSprite implements MapLocation {
  public id: number;
  public visited: boolean;
  element: PlannedBuildingElement;
  onSelect: (element: PlannedBuildingElement) => void;

  static spriteStyles: ClashingElementStyles = new ClashingElementStyles(); // beware, textures need rendering

  constructor(element: PlannedBuildingElement, transforms: PixiMinimapTransforms, onSelect: (element: PlannedBuildingElement) => void) {
    super();
    this.element = element;
    this.texture = ClashingElementSprite.spriteStyles.defaultTexture || ClashingElementSprite.spriteStyles.textureFailure;
    this.updateFromElement(element, transforms);
    this.onSelect = onSelect;
    this.interactive = true;
    this.buttonMode = true;

    this.on("pointerup", () => {
      this.onSelect(this.element)
    })
  }

  updateFromElement(element: PlannedBuildingElement, transforms: PixiMinimapTransforms) {
    this.id = getDbId(element.globalId);
    this.visited = false;
    this.element = element;

    if (window.Avvir.ForgeViewer?.autodeskViewer?.model?.getInstanceTree()) {
      const modelExplorer = new ModelExplorer(window.Avvir.ForgeViewer.autodeskViewer);
      const box = modelExplorer.getBoundingBoxForMesh(window.Avvir.ForgeViewer.autodeskViewer.model, this.id);
      const point = new Vector3();
      box.getCenter(point);
      const coords = calculatePhotoLocationCoords(point, transforms.mapBounds, transforms.viewerToPhotoAreaTransform);
      this.position.set(coords.x, coords.y);
    }
  }
}
