import { createSelector } from "reselect";
import getSelection from "../base_getters/get_selection";

const getPhotoSessionIds = createSelector([
  getSelection
], (selection) => {
  return selection.photoSessions;
});

export default getPhotoSessionIds;
