import { AvvirEvent } from "type_aliases";
import PanelType from "../../models/domain/enums/panel_type";

export type DisplayCustomSuccessMessageEvent = AvvirEvent<typeof DISPLAY_CUSTOM_SUCCESS_MESSAGE, {message: string, manualClose: boolean, link?: string, panel: PanelType}>

const displayCustomSuccessMessage = (message: string, manualClose: boolean = false, link: string = null, panel: PanelType = null): DisplayCustomSuccessMessageEvent => ({
  type: DISPLAY_CUSTOM_SUCCESS_MESSAGE,
  payload: {
    message,
    link,
    manualClose,
    panel
  }
});

export const DISPLAY_CUSTOM_SUCCESS_MESSAGE = "display_custom_success_message";

export default displayCustomSuccessMessage;
