import { Reducer } from "redux";
import {ApiUserForOrganization} from "avvir";
import { ORGANIZATION_USERS_LOADED } from "../../../events/loaded/organizations/organazation_users_loaded";
export type OrganizationUserStore = {users: ApiUserForOrganization[] }
const reduceOrganizationUsers: Reducer<OrganizationUserStore> = (usersStore = { users: []  }, event) => {
  switch (event?.type) {
    case ORGANIZATION_USERS_LOADED: {
      return {
        ...usersStore,
        users: event.payload.users
      }
    }
    default: return usersStore;
  }}

export default reduceOrganizationUsers;
