import { AvvirEvent } from "type_aliases";

export type FileUploadProgressedEvent = AvvirEvent<typeof FILE_UPLOAD_PROGRESSED, {
  fileTitle: string,
  isUploading: boolean,
  progress: number
}>

const fileUploadProgressed = (fileTitle: string, isUploading: boolean, progress: number): FileUploadProgressedEvent => ({
  type: FILE_UPLOAD_PROGRESSED,
  payload: {
    fileTitle,
    isUploading,
    progress
  }
});

export const FILE_UPLOAD_PROGRESSED = "file_upload_progressed";

export default fileUploadProgressed;
