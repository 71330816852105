import { AvvirRoutingEvent } from "type_aliases";

export type ToProjectTeamPageEvent = AvvirRoutingEvent<typeof TO_PROJECT_TEAM_PAGE, { projectId: string }>

const toProjectTeamPage = (projectId: string): ToProjectTeamPageEvent => ({
  type: TO_PROJECT_TEAM_PAGE,
  payload: { projectId }
});

export const TO_PROJECT_TEAM_PAGE = "to_project_team_page";

export default toProjectTeamPage;
