import { AvvirEvent } from "type_aliases";

export type ProjectUnarchivedEvent = AvvirEvent<typeof PROJECT_UNARCHIVED, { projectId: string }>

const projectUnarchived = (projectId: string): ProjectUnarchivedEvent => ({
  type: PROJECT_UNARCHIVED,
  payload: {
    projectId
  }
});

export const PROJECT_UNARCHIVED = "project_unarchived";

export default projectUnarchived;
