import { AvvirErrorEvent, ErrorLike } from "type_aliases";

export type ApiFailureEvent = AvvirErrorEvent<typeof API_FAILURE> & {payload: {userMessage?: string}}

const apiFailure = (error: ErrorLike, userMessage?: string): ApiFailureEvent => ({
  type: API_FAILURE,
  payload: { error, userMessage }
});

export const API_FAILURE = "api_failure";

export default apiFailure;
