import React, { useCallback } from "react";
import Button from "@mui/material/Button";

import "../../../css/other_pages/oops_page.css";
import NavigationService from "../../services/utilities/navigation_service";

function OopsPage() {
  const reloadPage = useCallback(() => {NavigationService.reload()}, []);
  return <div className="OopsPage">
    <h1 className="OopsPage-pageTitle">Oops! Something went wrong.</h1>
    <div className="OopsPage-copyContainer">
      <p className="OopsPage-explanation">It was probably our fault. We're looking into it.</p>
      <Button
        color="primary"
        variant="contained"
        onClick={reloadPage}
      >
        Refresh
      </Button>
    </div>
  </div>;
}

export default React.memo(OopsPage);
