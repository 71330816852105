import { AvvirEvent } from "type_aliases";
import {ApiUserForOrganization} from "avvir";

export type OrganizationUsersLoadedEvent = AvvirEvent<typeof ORGANIZATION_USERS_LOADED, {users: ApiUserForOrganization[] }>

const organizationUsersLoaded = (users: ApiUserForOrganization[]): OrganizationUsersLoadedEvent => ({
  type: ORGANIZATION_USERS_LOADED,
  payload: { users: users }
});

export const ORGANIZATION_USERS_LOADED = "organization_users_loaded";

export default organizationUsersLoaded;
