import { createTheme } from "@mui/material/styles";
import { accordionClasses, accordionSummaryClasses, buttonClasses, formLabelClasses, inputClasses, inputLabelClasses, toggleButtonClasses } from "@mui/material";
import { blue } from "@mui/material/colors";

import globalStyles from "./global_styles";
import overrides from "./global_overrides";

const palette = {
  secondary: {
    main: globalStyles.gray,
    light: globalStyles.mediumGray,
    dark: globalStyles.avvirDarkGray,
    contrastText: globalStyles.black,
  },
  error: {
    main: globalStyles.failurePink,
  },
  text: {
    primary: globalStyles.gray,
    secondary: globalStyles.mediumGray,
    disabled: globalStyles.disabled,
    hint: globalStyles.disabled,
  },
  background: {
    paper: "#fff",
    default: globalStyles.offWhite,
  }
};

// noinspection JSSuspiciousNameCombination
const newTheme = createTheme(overrides, {
  palette,
  typography: {
    fontFamily: "Roboto, sans-serif",
    h5: {
      fontSize: globalStyles.mediumLargeFontSize,
      fontWeight: "normal"
    },
    h6: {
      fontSize: globalStyles.mediumFontSize,
      fontWeight: "normal"
    },
    body2: {
      fontSize: globalStyles.smallMediumFontSize,
      fontWeight: "normal",
      color: globalStyles.gray
    },
    caption: {
      fontSize: globalStyles.smallFontSize,
      fontWeight: "normal"
    },
    subtitle2: {
      fontSize: globalStyles.smallMediumFontSize
    },
    label: {
      fontSize: globalStyles.smallFontSize,
      color: globalStyles.black30,
      fontWeight: "normal",
      letterSpacing: 0.15
    },
    value: {
      fontSize: globalStyles.smallMediumFontSize,
      color: globalStyles.black,
      fontWeight: 300,
      letterSpacing: 0.15,
      lineHeight: 1
    }
  },
  shape: {
    borderRadius: 3
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          [`&.${accordionClasses.expanded}`]: {
            margin: 0
          }
        }
      }
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          padding: 0,
          [`&.${accordionSummaryClasses.disabled}`]: {
            color: "black"
          }
        },
        content: {
          margin: globalStyles.space2x,
          [`&.${accordionSummaryClasses.expanded}`]: {
            margin: globalStyles.space2x,
            padding: 0
          }
        },
        expandIconWrapper: {
          [`&.${accordionSummaryClasses.expanded}`]: {
            transform: "rotate(90deg)"
          }
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: "center"
        },
        message: {
          fontWeight: "normal"
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: globalStyles.extraLightGray,
          color: globalStyles.lightGray
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          [`&.${buttonClasses.disabled}`]: {
            color: globalStyles.disabled,
            "&:hover": {
              color: globalStyles.disabled
            }
          },
          minHeight: 30,
          fontSize: globalStyles.smallFontSize,
          fontWeight: 500,
          lineHeight: "22px",
          transitionProperty: "all",
          color: globalStyles.white
        },
        containedPrimary: {
          "&:hover": {
            color: globalStyles.white,
          }
        },
        containedSecondary: {
          background: globalStyles.avvirGray,
          color: globalStyles.black80,
          "&:hover": {
            background: globalStyles.secondaryContainedHoverBackground,
            color: globalStyles.black80,
          },
        },
        outlinedPrimary: {
          color: globalStyles.avvirGold
        },
        text: {
          color: globalStyles.avvirGold,
          "&:hover": {
            background: globalStyles.avvirGold8,
            color: globalStyles.avvirGold,
          },
        },
        textPrimary: {
          "&:hover": {
            background: globalStyles.avvirGold8,
          }
        },
        textSecondary: {
          color: globalStyles.black80,
          "&:hover": {
            background: globalStyles.avvirLightGray,
            color: globalStyles.black80,
          },
        },
      },
      defaultProps: {
        size: "small"
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          background: globalStyles.avvirGray,
          color: globalStyles.black80,
          "&:hover": {
            background: globalStyles.secondaryContainedHoverBackground,
          },
          [`&.${toggleButtonClasses.selected}`]: {
            background: globalStyles.avvirGold,
            color: globalStyles.white,
            "&:hover": {
              background: globalStyles.primaryContainedHoverBackground,
            },
          }
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          paddingLeft: globalStyles.space4x,
          paddingRight: globalStyles.space4x,
          marginTop: "0.67em"
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          paddingTop: globalStyles.space2x,
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
          "@media (min-width: 600px)": {
            paddingTop: globalStyles.space2x,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0
          }
        },
        title: {
          fontSize: globalStyles.mediumFontSize,
          fontWeight: 600
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          paddingBottom: globalStyles.space2x,
          paddingRight: 0,
          paddingLeft: 0,
          color: globalStyles.lightGray,
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          "@media (min-width: 600px)": {
            paddingTop: 0,
            paddingBottom: globalStyles.space2x,
            paddingRight: 0,
            paddingLeft: 0
          }
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingTop: globalStyles.space,
          paddingRight: globalStyles.space,
          paddingLeft: 0,
          paddingBottom: globalStyles.space4x,
          "@media (min-width: 600px)": {
            paddingTop: globalStyles.space,
            paddingRight: globalStyles.space,
            paddingLeft: 0,
            paddingBottom: globalStyles.space4x
          }
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          padding: globalStyles.space2x
        }
      }
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense"
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        labelPlacementStart: {
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 0,
          marginRight: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: globalStyles.lightGray,
          [`&.${formLabelClasses.focused}`]: { color: globalStyles.avvirGold }
        },
      }
    },
    MuiIcon: {
      styleOverrides: {
        colorDisabled: {
          color: globalStyles.disabled
        },
        colorError: {
          color: globalStyles.failurePink
        },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {}
      }
    },
    MuiInput: {
      defaultProps: {
        margin: "dense" as const
      },
      styleOverrides: {
        root: {
          marginRight: globalStyles.space,
          [`&.${inputClasses.focused}`]: {
            [`&.${inputClasses.underline}`]: {
              [`&:not(.${inputClasses.disabled}):not(.${inputClasses.error})`]: {
                "&:after": {
                  borderBottomColor: globalStyles.avvirGold
                },
                "&:hover": {
                  "&:after": {
                    borderBottomColor: globalStyles.avvirGold
                  }
                }
              }
            }
          },
        },
        underline: {
          paddingBottom: 7,
          [`&:not(.${inputClasses.disabled}):not(.${inputClasses.focused}):not(.${inputClasses.error})`]: {
            "&:before": {
              borderBottomColor: globalStyles.extraLightGray
            },
            "&:hover": {
              "&:before": {
                borderBottomColor: globalStyles.extraLightGray,
                borderBottomWidth: 2
              }
            }
          }
        }
      }
    },
    MuiInputBase: {
      defaultProps: {
        size: "small"
      },
      styleOverrides: {
        input: {
          paddingBottom: 0
        }
      }
    },
    MuiFilledInput: {
      defaultProps: {
        margin: "dense"
      },
      styleOverrides: {
        root: {
          marginRight: globalStyles.space
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: globalStyles.lightGray,
          [`&.${inputLabelClasses.focused}`]: { color: globalStyles.avvirGold }
        },
        outlined: {
          transform: "translate(14px, 8px) scale(1)",
          [`&.${inputLabelClasses.shrink}`]: { transform: "translate(14px, -9px) scale(0.75)" }
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 2
        },
        colorPrimary: {
          backgroundColor: globalStyles.extraLightGray
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: globalStyles.avvirGold,
          "&:hover": {
            color: globalStyles.avvirDarkGold
          }
        }
      }
    },
    MuiList: {
      defaultProps: {
        disablePadding: true
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto, sans-serif"
        }
      }
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: "body1",
          color: "textPrimary"
        }
        ,
        secondaryTypographyProps: {
          variant: "caption",
          color: "inherit",
          display: "block"
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          fontFamily: "Roboto, sans-serif"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto, sans-serif"
        }
      }
    },
    MuiOutlinedInput: {
      defaultProps: {
        margin: "dense"
      }
    },
    MuiSlider: {
      styleOverrides: {
        track: {
          backgroundColor: globalStyles.lightGray,
          opacity: 1,
          borderWidth: 0,
          height: 2
        },
        rail: {
          backgroundColor: globalStyles.avvirGold,
          opacity: 1,
          height: 2
        }
      }
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          padding: 0,
          position: "fixed",
          top: "calc(var(--nav-bar-height)) !important",
          width: "100%",
        },
      }
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent !important",
          padding: 0,
          minWidth: "fit-content",
          width: "100%",
          color: "black",
        },
        message: {
          color: "black",
          textAlign: "center",
          width: "95%",
        },
        action: {
          marginRight: "8px"
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeLarge: {
          fontSize: 30
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        head: {
          backgroundColor: globalStyles.gray,
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: globalStyles.space2x,
          height: 44,
        },
        head: {
          color: globalStyles.offWhite,
          fontSize: globalStyles.smallFontSize,
          fontWeight: 600
        },
        stickyHeader: {
          backgroundColor: globalStyles.gray
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: "off"
      }
    },
    MuiTreeItem: {
      styleOverrides: {
        label: {
          "&:hover": {
            backgroundColor: globalStyles.avvirGold8
          }
        }
      }
    },
  },
});

export const blueButtonTheme = createTheme({
  palette: {
    primary: {
      main: "rgb(83, 126, 214)",
      dark: blue["A400"],
      contrastText: globalStyles.white
    },
    secondary: {
      main: globalStyles.mediumGray,
      dark: globalStyles.darkGray,
      contrastText: globalStyles.white
    },
  },
});

export default newTheme;
