// noinspection JSUnusedGlobalSymbols,JSSuspiciousNameCombination
import { alpha, createTheme, Theme } from "@mui/material/styles";
import { buttonClasses } from "@mui/material";
import { grey } from "@mui/material/colors";
import "@mui/styles";

import globalStyles from "./global_styles";

declare module "@mui/styles" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type ColorOverrides = {
  "BIM/Clash": true;
  "BIM/Deviation": true;
  "BIM/Built": true;
  "BIM/Not Built": true;
  "BIM/Included": true;
  "BIM/Obstructed": true;
  "BIM/Verified": true;
  "BIM/Ignored": true;
  "BIM/As Built": true;
  "Status/Not Started": true;
  "Status/In Progress": true;
  "Status/Completed": true;
}

declare module "@mui/material/styles" {
  interface Palette {
    "BIM/Clash": Palette["primary"];
    "BIM/Deviation": Palette["primary"];
    "BIM/Built": Palette["primary"];
    "BIM/Not Built": Palette["primary"];
    "BIM/Included": Palette["primary"];
    "BIM/Obstructed": Palette["primary"];
    "BIM/Verified": Palette["primary"];
    "BIM/Ignored": Palette["primary"];
    "BIM/As Built": Palette["primary"];
    "Status/Not Started": Palette["primary"];
    "Status/In Progress": Palette["primary"];
    "Status/Completed": Palette["primary"];
  }

  interface PaletteOptions {
    "BIM/Clash"?: PaletteOptions["primary"];
    "BIM/Deviation"?: PaletteOptions["primary"];
    "BIM/Built"?: PaletteOptions["primary"];
    "BIM/Not Built"?: PaletteOptions["primary"];
    "BIM/Included"?: PaletteOptions["primary"];
    "BIM/Obstructed"?: PaletteOptions["primary"];
    "BIM/Verified"?: PaletteOptions["primary"];
    "BIM/Ignored"?: PaletteOptions["primary"];
    "BIM/As Built"?: PaletteOptions["primary"];
    "Status/Not Started"?: Palette["primary"];
    "Status/In Progress"?: Palette["primary"];
    "Status/Completed"?: Palette["primary"];
  }

  interface TypographyVariants {  // TODO: Fix these once the design team comes up with a list of different typography variants
    label: React.CSSProperties;
    value: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
    value?: React.CSSProperties;
  }
}

declare module "@mui/material/Chip" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ChipPropsColorOverrides extends ColorOverrides {

  }

  interface ChipPropsSizeOverrides {
    tiny: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides extends ColorOverrides {
    grey: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides extends ColorOverrides {
    grey: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
    value: true;
  }
}

declare module "@mui/material" {
  interface Color {
    main: string;
    dark: string;
  }
}

const theme = createTheme({
  palette: {
    // mode: "dark",
    grey: {
      main: grey[300],
      dark: grey[400]
    }
  }
});

export default createTheme(theme, {
  palette: {
    primary: theme.palette.augmentColor({
      color: { main: globalStyles.avvirGold },
      name: "primary"
    }),
    "BIM/Clash": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/Clash"] },
      name: "BIM/Clash",
    }),
    "BIM/Deviation": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/Deviation"] },
      name: "BIM/Deviation",
    }),
    "BIM/Built": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/Built"] },
      name: "BIM/Built",
    }),
    "BIM/Not Built": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/Not Built"] },
      name: "BIM/Not Built",
    }),
    "BIM/Included": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/Included"] },
      name: "BIM/Included",
    }),
    "BIM/Obstructed": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/Obstructed"] },
      name: "BIM/Obstructed",
    }),
    "BIM/Verified": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/Verified"] },
      name: "BIM/Verified",
    }),
    "BIM/Ignored": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/Ignored"] },
      name: "BIM/Ignored",
    }),
    "BIM/As Built": theme.palette.augmentColor({
      color: { main: globalStyles["BIM/As Built"] },
      name: "BIM/As Built",
    }),
    "Status/Not Started": theme.palette.augmentColor({
      color: { main: globalStyles["Status/Not Started"] },
      name: "Status/Not Started",
    }),
    "Status/In Progress": theme.palette.augmentColor({
      color: { main: globalStyles["Status/In Progress"] },
      name: "Status/In Progress",
    }),
    "Status/Completed": theme.palette.augmentColor({
      color: { main: globalStyles["Status/Completed"] },
      name: "Status/Completed",
    }),
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: "grey"
      },
      variants: [{
        props: { variant: "contained", color: "grey" },
        style: {
          color: theme.palette.getContrastText(theme.palette.grey[300]),
          backgroundColor: theme.palette.grey[300],
          "&:hover": {
            backgroundColor: theme.palette.grey[400]
          }
        }
      }, {
        props: { variant: "outlined", color: "grey" },
        style: {
          color: theme.palette.text.primary,
          borderColor:
            theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.23)"
            : "rgba(255, 255, 255, 0.23)",
          [`&.${buttonClasses.disabled}`]: {
            border: `1px solid ${theme.palette.action.disabledBackground}`
          },
          "&:hover": {
            borderColor:
              theme.palette.mode === "light"
              ? "rgba(0, 0, 0, 0.23)"
              : "rgba(255, 255, 255, 0.23)",
            backgroundColor: alpha(
              theme.palette.text.primary,
              theme.palette.action.hoverOpacity
            )
          }
        }
      }, {
        props: { color: "grey", variant: "text" },
        style: {
          color: theme.palette.text.primary,
          "&:hover": {
            backgroundColor: alpha(
              theme.palette.text.primary,
              theme.palette.action.hoverOpacity
            )
          }
        }
      }]
    },
    MuiChip: {
      styleOverrides: {
        label: ({ ownerState, theme }) => ({
          ...(ownerState.size === "tiny" && {
            paddingLeft: globalStyles.halfSpace,
            paddingRight: globalStyles.halfSpace
          })
        })
      },
      variants: [{
        props: { size: "tiny" },
        style: {
          maxHeight: globalStyles.space2x,
          fontSize: globalStyles.smallFontSize,
        }
      }]
    }
  }
});
