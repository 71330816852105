import { AvvirEvent } from "type_aliases";

export type SignInUserStartedEvent = AvvirEvent<typeof SIGN_IN_USER_STARTED, { email: string }>

const signInUserStarted = (email: string): SignInUserStartedEvent => ({
  type: SIGN_IN_USER_STARTED,
  payload: { email }
});

export const SIGN_IN_USER_STARTED = "sign_in_user_started";

export default signInUserStarted;
